<template>
  <div class="completed">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion i-フィルター</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li class="stay">i-フィルター</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="i-フィルター サービス利用規約" />i-フィルター　サービス利用規約</h2>

        <div>
          <p>
            <a :href="`${eMansionUrl}/pdf/service/ifilterkiyaku.pdf`" class="link" target="i-filter-kiyaku">i-フィルター　サービス利用規約</a>
          </p>
        </div>

        <div class="template">
          <label>
            <input type="checkbox" v-model="isAgreeTerms" />
            <span class="condition">i-フィルター サービス利用規約に同意します</span>
          </label>
        </div>
      </div>

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn05 bs" v-on:click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs sp-margin" v-on:click="onNext()" type="button" :disabled="isDisabledButton">
            規約に同意して申し込む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { MountedCheckService } from '@/shared/services/mounted-check-service';

export default defineComponent({
  name: 'platform-terms-i-filter',
  components: {
    LoadingComponent,
  },
  data(): {
    // 会員情報
    member: Member | null;
    // 会員ステータス
    memberStatus: MemberStatus | null;
    isAgreeTerms: boolean;
    isLoading: boolean;
    eMansionUrl: string;
  } {
    return {
      member: null,
      memberStatus: null,
      isAgreeTerms: false,
      isLoading: true,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    };
  },
  //ログインチェック、物件チェック、ほか、確かめる内容を追加
  async mounted(): Promise<void> {
    this.isLoading = true;
    /** ログインしているか否かの情報を取得 */
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
    this.isLoading = false;
    return;
  },
  methods: {
    async onNext(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      if (!this.isAgreeTerms) {
        return;
      }
      await this.$router.push('/e-mansion/i-filter/input').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
      return;
    },
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/e-mansion').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
      return;
    },
  },
  computed: {
    // チェックボックスによる「申し込む」ボタンの判定
    isDisabledButton(): boolean {
      if (this.isAgreeTerms) {
        return false;
      }
      return true;
    },
  },
});
</script>

<style lang="scss" scoped>
.condition {
  margin: 0 0 5px;
  padding-left: 15px;
}
div.template {
  margin-top: 30px;
}
.btn:disabled {
  opacity: 0.5;
}
.sp-margin {
  margin-top: 10px;
}
</style>
