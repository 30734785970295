/** ご利用状況表示用　 契約基本情報取得API.オプション利用状況.i-フィルター　DTO*/
export class UsageifilterDTO {
  /** 契約番号 */
  public contractID!: string;
  /** ご利用状況 */
  public usageStatus!: string;
  /** インストールコンピューター先 */
  public isComputer!: string[];
  /** ダウンロードURL */
  public downloadUrl!: string;
  /** 解約日 */
  public cancelDate?: string;
  /** 契約日 */
  public entryDate?: string;
  /** シリアルID */
  public cerealId!: string;

  constructor(partial: Partial<UsageifilterDTO>) {
    Object.assign(this, partial);
  }
}
