<template>
  <div>
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/e-mansion">e-mansion 会員専用トップ</a></li>
        <li>UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <div class="blc">
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="番号通知（無料）設定" />番号通知（無料）設定</h2>
      </div>
      <div class="blc-spacing">
        <p>番号通知を相手に通知する・通知しないを選択し「変更」をクリックしてください。</p>

        <table class="table-type2">
          <tr>
            <td>
              <label><input type="radio" value="1" v-model="isNotifyNumber" /> 相手に通知する</label>
              <label><input type="radio" value="0" v-model="isNotifyNumber" /> 相手に通知しない</label>
            </td>
          </tr>
        </table>
      </div>
      <!-- ボタン -->
      <div class="btn-area">
        <button class="btn btn05 bs" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
        <button class="btn btn01 bs" type="button" @click="onChange()" :disabled="isDisabledButton">変更<i class="material-icons link link-icon">east</i></button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
div.btn-area {
  position: relative;
  padding-top: 72px;
  text-align: center;
}
.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}
span.red {
  color: #cf1225;
}
button.btn {
  margin-top: 10px;
}
</style>

<script lang="ts">
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-account-accessor';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionHikariPhoneUpdateRequestOptionalDataDto } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-option-dto';
import { eMansionNotifyMailAddressCheckRequest } from '@/shared/classes/spf-api/mail/model/account/e-mansnion-notify-mailaddress-check-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { SpfApiHikariPhoneAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-hikari-phone-accessor';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { UCOM_HIKARI_PHONE_STATUS_ON_MANSION } from '@/shared/const/e-mansion';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';

/** Completed コンポーネント */
export default defineComponent({
  name: 'hikari-phone-notification-setting',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /**会員情報 */
    member: null as Member | null,
    /** e-mansion契約基本情報 */
    customer: null as EMansionCustomer | null,
    /** 物件情報 */
    property: null as Property | null,
    /** 通知先メールチェック */
    isNotifyEmail: false,
    /** 番号通知設定 */
    isNotifyNumber: '' as string,
    // 「変更」ボタンの活性・非活性制御
    isDisabledButton: true,
  }),
  async mounted() {
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    try {
      this.member = await this.$store.getters['memberStore/member'];
      this.property = this.$store.getters['propertyStore/property'];

      await StoreExternalApiResponse.main();

      const property: Property = this.$store.getters['propertyStore/property'];
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      /** 契約基本情報を取得 */
      this.customer = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: this.member!.primaryKeyMye,
        query: findCustomerByIdQueryDto,
      });

      if (this.customer instanceof EMansionCustomer) {
        // 顧客情報に物件情報が紐付けられていなければエラー、契約基本情報取得API.オプション利用状況.UCOM光電話.番号通知がなければエラー

        if (!this.customer.property_id || this.customer.op.hikariphone[0].notification_flag === null || this.customer.op.hikariphone[0].notification_flag === undefined) {
          // データ不整合エラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
        }

        // UCOM光電話本体のstatusが'12'でない場合、 UCOM光電話利用状況画面へ遷移
        if (this.customer.op.hikariphone[0]?.status !== String(UCOM_HIKARI_PHONE_STATUS_ON_MANSION.IN_USE)) {
          await this.$router.push('/e-mansion/hikari-phone/usage').catch((error) => {
            checkRouterError(error);
          });
          this.isLoading = false;
          return;
        }

        // 契約基本情報取得API.オプション利用状況.UCOM光電話.番号通知 を取得
        this.isNotifyNumber = String(this.customer.op.hikariphone[0].notification_flag);

        //通知先メールチェック
        const primaryKeyMye = this.member!.primaryKeyMye;
        const apartmentId = this.property!.apartmentId;
        const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
        const uaType = this.property!.uaType;
        const chechNotifyAccount = await SpfApiAccountAccessor.getEMansionAccountNotifyEmail(
          new eMansionNotifyMailAddressCheckRequest(primaryKeyMye!, apartmentId!, servicePlanType!, uaType!)
        );
        if (chechNotifyAccount) {
          this.errorMessages.push(String(chechNotifyAccount.messages));
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        } else {
          // もし通知先メールチェックAPIエラーがなければボタン有効化
          this.isDisabledButton = false;
        }
        this.isNotifyEmail = true;
      }
      this.isLoading = false;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }
  },
  methods: {
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion/hikari-phone/usage')
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onChange() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      try {
        // 一旦エラーメッセージをリセットする
        this.errorMessages = [];

        // 申し込みのエラーチェックを実施する
        const result = await this.radioButtonValidate();
        if (result) {
          // 現在と同じ値を選択していない場合

          // e-mansionを退会・退会申請していないか確認
          if (await this.isAccountDeactivated()) {
            await this.$router.push('/platform').catch((error: any) => {
              checkRouterError(error);
            });
            return;
          }

          // (外部)サービス変更API（UCOM光電話）実行
          const eMansionMemberId = this.member!.primaryKeyMye ?? '';
          const eMansionPropertyId = this.customer!.property_id;
          const servicePlanType = await this.$store.dispatch('servicePlanTypeStore/servicePlanType');
          if (this.customer instanceof EMansionCustomer) {
            // サービス更新APIに進む前のデータ取得
            const optionalData = new EMansionHikariPhoneUpdateRequestOptionalDataDto({
              Hikariphone: {
                notificationFlag: this.isNotifyNumber,
                numberDisplay: '',
                catch: '',
                catchNumberDisplay: '',
                optionPack: '',
              },
            });

            // サービス更新API(UCOM光電話) を呼び出す
            await SpfApiHikariPhoneAccessor.numberNotificationHikariPhone(eMansionMemberId, eMansionPropertyId, servicePlanType, optionalData, '0');
          } else {
            // データ不整合エラー
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
          }

          // 再表示のために変更後の契約基本情報取得API.オプション利用状況.UCOM光電話.番号通知 を取得
          await StoreExternalApiResponse.main();
          this.customer = this.$store.getters['eMansionCommonStore/customer'];
          this.isNotifyNumber = String(this.customer!.op.hikariphone[0].notification_flag);

          // ページを再読み込み
          await this.$router
            .push('/e-mansion/hikari-phone/notification-settings')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              checkRouterError(error);
            })
            .finally(() => {
              this.isSubmitting = false;
              return;
            });
        } else {
          // 変更なしの場合はエラーメッセージを表示せずにページを再読み込み(既存仕様に踏襲)
          await this.$router
            .push('/e-mansion/hikari-phone/notification-settings')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              checkRouterError(error);
            })
            .finally(() => {
              this.isSubmitting = false;
              return;
            });
        }
      } catch (error: any) {
        await VueErrorHandler.handle(error, '');
        await this.$router
          .push('/e-mansion/error')
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
            return;
          });
      }
    },
    /** 申し込み前のチェックボックス確認
     * 　・ラジオボタンの値があるか
     *   ・現在と同じ値を選択している場合
     * 選択している値に変更なし:false, 変更あり：true
     */
    async radioButtonValidate() {
      if (!this.isNotifyNumber) {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
      }
      if (this.isNotifyNumber === String(this.customer!.op.hikariphone[0].notification_flag)) {
        // 変更なしの場合はエラーメッセージを表示せずにページを再読み込み(既存仕様に踏襲)
        return false;
      }
      return true;
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      if (this.customer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        const ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, this.customer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
  },
});
</script>
