<template>
  <div class="gip-application">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion 固定グローバルIPアドレス</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li>固定グローバルIPアドレス</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">お申し込み</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="固定グローバルIPアドレス お申し込み" />固定グローバルIPアドレス お申し込み</h2>
      </div>
      <div>
        <p>固定グローバルIPアドレスのお申し込みお手続きを行います。</p>
        <p>
          <span class="red">
            e-mansion
            インターネット接続サービスをご利用開始後に、設定いただく固定グローバルIPアドレスを発行し書面にてお知らせします。書面発行日が請求開始の起算日となります。キャンペーン等が設定されている場合はそちらが適用されます。
          </span>
        </p>
      </div>
      <br />

      <div class="sblc">
        <div class="sblc">
          <h3 class="service-h3">固定グローバルIPアドレスお申し込み</h3>
          <div v-if="!isApplyConnectix">
            <p>固定グローバルIPアドレスを割り当てる通信機器のMACアドレスを入力してください。</p>
            <p>ルータをご利用されていて、ルータに割当てを希望される場合には、<span class="red">「ルータのWAN側」</span>のMACアドレスを入力してください。</p>
            <p>MACアドレスは<span class="red">ハイフン区切り</span> または <span class="red">コロン区切り</span> で入力してください。</p>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th><b>MACアドレス</b><span class="req">必須</span></th>
                  <td>
                    <p>【半角英数字】</p>
                    <p>※-（ハイフン）も入力してください。</p>
                    <p>※:（コロン）も入力してください。</p>
                    <input
                      type="text"
                      v-model="requestMacAddress"
                      @input="v$.requestMacAddress.$touch()"
                      class="text middle"
                      placeholder="例）00-1E-CC-41-7A-E2, 00:1E:CC:41:7A:E2"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <ul>
            <li>
              お申し込み後、固定グローバルIPアドレスの登録が完了するまでに、20日程お時間をいただいております。（マンション内ネットワークの都合上、それ以上お時間をいただく場合がございますので予めご了承ください。）
            </li>
            <li>登録が完了次第、郵送にてご連絡差し上げます。</li>
            <li>ご入居前のお申し込みの場合は、鍵のお引渡し日から数日間お時間をいただく場合がございます。</li>
            <li>固定グローバルIPアドレスは変更できません。</li>
          </ul>
        </div>
      </div>
      <div class="sblc">
        <p class="form-btn-txt">ご確認の上、よろしければ「申し込む」をクリックしてください。</p>
        <div class="btn-area">
          <div>
            <button class="btn btn05 bs" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
            <button class="btn btn01 bs sp-margin" type="button" @click="onApply()" :disabled="isDisabledButton">申し込む <i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
div.btn-area {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
input.text.middle {
  width: 320px;
}
/* 検索ボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
span.red {
  color: #cf1225;
}
span.gray {
  color: gray;
}

@media (max-width: 767px) {
  .sp-margin {
    margin-top: 10px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { required, macAddress } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { AuthService } from '@/shared/services/auth/auth-service';
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-account-accessor';
import { eMansionNotifyMailAddressCheckRequest } from '@/shared/classes/spf-api/mail/model/account/e-mansnion-notify-mailaddress-check-request';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
/** Completed コンポーネント */
export default defineComponent({
  name: 'static-global-ip-application',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },

  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** MACアドレス */
    requestMacAddress: '',
    /** Connectix対象物件
     * 対象:true, 非対象:false
     */
    isApplyConnectix: false,
    /** 申し込むボタンが押せるかどうか */
    isDisabledButton: true,
  }),
  /** バリデーション定義 */
  validations: {
    requestMacAddress: {
      required,
      macAddressHyphen: macAddress('-'),
      macAddressColon: macAddress(':'),
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted() {
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.$store.commit('eMansionGIPStore/from', null);
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      /** 物件基本情報をStoreから取得 */
      const eMansionProperty: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      this.isApplyConnectix = this.checkApplyConnectix(eMansionProperty);

      /** 固定GIP申し込み確認画面 申し込むボタンクリック時、MACアドレスが以下のどれかの場合エラーメッセージを表示する
       * (確認画面もしくは本人認証結果取得画面で(外部)サービス登録API（固定GIP）のエラーコードがAPIS010またはAPIS011の時設定)
       */
      const beforeVue = this.$store.getters['eMansionGIPStore/from'];
      if (beforeVue === 'confirm') {
        const errorCode = this.$store.getters['eMansionGIPStore/errorCode'];
        if (errorCode) {
          if (errorCode === 'APIS010') {
            this.errorMessages.push('既にご登録いただいているMACアドレスはお申し込みいただけません。');
            this.showErrorMessage;
          }
          if (errorCode === 'APIS011') {
            this.errorMessages.push('MACアドレスは既に使用されておりますのでお申し込みいただけません。');
            this.showErrorMessage;
          }
        }
      }

      /**通知先メールチェックAPIを呼び出し、エラーの場合はエラーを表示する
       * このAPIでエラーの場合は申し込むボタンを無効化する
       */
      // 通知メールアドレスチェックAPIを実行
      const member = this.$store.getters['memberStore/member'];
      const primaryKeyMye = member.primaryKeyMye;
      // 物件情報を取得する
      const property = this.$store.getters['propertyStore/property'];
      const apartmentId = property.apartmentId;
      const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      const uaType = property.uaType;
      const chechNotifyAccount = await SpfApiAccountAccessor.getEMansionAccountNotifyEmail(
        new eMansionNotifyMailAddressCheckRequest(primaryKeyMye!, apartmentId!, servicePlanType!, uaType!)
      );
      if (chechNotifyAccount) {
        this.errorMessages.push(String(chechNotifyAccount.messages));
        this.showErrorMessage();
        return;
      } else {
        // もし通知先メールチェックAPIエラーがなければボタン有効化
        this.isDisabledButton = false;
      }
      // 初期表示するMACアドレスをストアからとる
      this.requestMacAddress = this.$store.getters['eMansionMacAddressStore/macAddress'];
      this.isLoading = false;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }
  },
  methods: {
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;

      await this.$router
        .push('/e-mansion/static-gip/terms')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          // 確認画面もしくは本人認証結果取得画面のサービス登録APIで登録されたエラーメッセージをクリアする
          this.$store.commit('eMansionGIPStore/errorCode', null);
          this.$store.commit('eMansionGIPStore/from', null);
          this.$store.commit('eMansionMacAddressStore/macAddress', null);

          this.isSubmitting = false;
        });
    },
    async onApply() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      // 非Connectix導入物件の場合はMACアドレスのバリデーションチェック
      if (!this.isApplyConnectix) {
        // バリデーションチェック
        const isvalidrequestMacAddress = this.validate();
        if (!isvalidrequestMacAddress) {
          this.showErrorMessage();
          return;
        }
        // ストアにMACアドレスをコミットする
        this.$store.commit('eMansionMacAddressStore/macAddress', this.requestMacAddress);
      }
      await this.$router
        .push('/e-mansion/static-gip/confirm')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.$store.commit('eMansionGIPStore/from', null);
          this.isSubmitting = false;
        });
    },
    /**Connectix対象物件かを判定 */
    checkApplyConnectix(property: EMansionProperty): boolean {
      /**
       * ・Connectix対象物件であること：
       * 物件情報取得APIの「SD-WANフラグ（op_sdwan_flag）」が"1"
       */
      if (property.op.sdwan.flag === '1') {
        return true;
      }
      return false;
    },
    /** フォームのバリデーション */
    validate(): boolean {
      this.errorMessages = [];
      // 必須チェック
      if (this.v$.requestMacAddress?.required.$invalid) {
        this.errorMessages.push(`「MACアドレス」を入力してください。`);
        return false;
      }
      // 形式チェック
      if (this.v$.requestMacAddress?.macAddressHyphen.$invalid && this.v$.requestMacAddress?.macAddressColon.$invalid) {
        this.errorMessages.push(`「MACアドレス」を正しく入力してください。`);
        return false;
      }
      return true;
    },
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      this.isLoading = false;
      this.isSubmitting = false;
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      return;
    },
  },
});
</script>
