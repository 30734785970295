import { JudgeError } from '@/infra/accessor/spf/common/judge-error';

import { BaseApiService } from '@/shared/services/api/base-api-service';
import { UcomMailPackInfo } from '@/shared/classes/spf-api/model/ucom/ucom-mail-pack-info';
import { UcomMailPackUpdateInfo } from '@/shared/classes/spf-api/model/ucom/ucom-mail-pack-update-info';

const api = BaseApiService.api();

/** UCOMに関わる 内部 API をコールする */
export class SpfApiMailPackAccessor {
  public static async getUcomMailPackInfo(memberId: number): Promise<UcomMailPackInfo> {
    const response = await api.get<UcomMailPackInfo>(`/mail-pack/ucom/nyuuryoku/${memberId}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return this.convertUcomMailPackInfoDto(response);
  }
  private static async convertUcomMailPackInfoDto(response: any | undefined): Promise<UcomMailPackInfo> {
    if (!response) {
      throw new Error('convertUcomMailPackInfoDtoでエラーが発生しました');
    }
    return new UcomMailPackInfo({
      _memberId: response.data.memberId,
      _contractStatus: response.data.contractStatus,
      _paymentStatus: response.data.paymentStatus,
      _errorMessage: response.data.errorMessage,
      _isMax: response.data.isMax,
      _maxMessage: response.data.maxMessage,
      _monthlyFee: response.data.price,
      _serviceDescription: response.data.serviceDescription,
      _productName: response.data.productName,
    });
  }

  public static async updateUcomMailPackInfo(
    memberId: number,
    ucomAccountId: number,
    subscriptionOn: string,
    monthlyFee: string,
    productName: string,
    serviceDescription: string
  ): Promise<UcomMailPackUpdateInfo> {
    const response = await api
      .post<UcomMailPackUpdateInfo>(`/mail-pack/ucom/kakunin`, {
        memberId: memberId,
        ucomAccountId: ucomAccountId,
        subscriptionOn: subscriptionOn,
        monthlyFee: monthlyFee,
        productName: productName,
        serviceDescription: serviceDescription,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return this.convertUcomMailPackUpdateInfoDto(response);
  }
  private static async convertUcomMailPackUpdateInfoDto(response: any | undefined): Promise<UcomMailPackUpdateInfo> {
    if (!response) {
      throw new Error('convertUcomMailPackUpdateInfoDtoでエラーが発生しました');
    }
    return new UcomMailPackUpdateInfo({
      _mailAddress: response.data.mailAddress,
      _message: response.data.message,
    });
  }
  /**日付をyyyy/mm/ddの形式にする
   * @param date Date型の日付
   * @return yyyy/mm/ddの形式の文字列
   */
  private static formatDate(date: Date): string {
    const y = date.getFullYear();
    const m = ('00' + (date.getMonth() + 1)).slice(-2);
    const d = ('00' + date.getDate()).slice(-2);
    return y + '/' + m + '/' + d;
  }
}
