export class EMansionErrorDisplayInfo {
  private readonly _propertyType: string;
  private readonly _contactTel1: string;
  private readonly _contactTel2: string;
  private readonly _contactUrl: string;
  private readonly _contactOfficeHours: string;

  constructor(PropertyType: string, contactTel1: string, contactTel2: string, contactUrl: string, contactOfficeHours: string) {
    this._propertyType = PropertyType;
    this._contactTel1 = contactTel1;
    this._contactTel2 = contactTel2;
    this._contactUrl = contactUrl;
    this._contactOfficeHours = contactOfficeHours;
  }

  get propertyType(): string {
    return this._propertyType;
  }

  get contactTel1(): string {
    return this._contactTel1;
  }

  get contactTel2(): string {
    return this._contactTel2;
  }

  get contactUrl(): string {
    return this._contactUrl;
  }

  get contactOfficeHours(): string {
    return this._contactOfficeHours;
  }
}
