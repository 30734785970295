<template>
  <div class="e-mansion-hikari-phone-option-cancel-confirm">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li class="stay">UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- stepnavi -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>オプションお申し込み・解約</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- stepnavi -->

      <!-- 本文 -->
      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="付加サービス（有料）ご解約確認" />付加サービス（有料）ご解約確認</h2>
        <p>お手続き内容をご確認ください。</p>

        <h3 class="service-h3">お手続き内容</h3>
        <p class="red">解約する付加サービスをご確認ください。</p>

        <table class="table-type2">
          <tbody>
            <tr>
              <th>付加サービス</th>
              <td>{{ serviceName }}</td>
            </tr>
          </tbody>
        </table>

        <div class="sblc">
          <h3 class="service-h3">付加サービス（有料）のご解約に関する注意事項</h3>
          <p class="red">付加サービスのご解約お手続きを進めます。</p>
          <p class="red">「UCOM光電話」サービスを解約される場合は、別途「UCOM光電話」サービスの解約お手続きが必要です。</p>

          <ul>
            <li class="bold">付加サービスのサービス終了日について</li>
            <p>
              1日から20日迄(21日午前0時より翌月扱い)にご解約のお手統きをされた場合、当月の末日をもって付加サービスが無効となります。21日から月末にご解約のお手続きをされた場合、翌月の末日をもって付加サービスが無効となります。
            </p>
          </ul>
        </div>
        <!-- ボタン -->

        <div class="blc">
          <p class="form-btn-txt">ご確認の上、よろしければ「解約する」をクリックしてください。</p>
          <div class="btn-area">
            <button class="btn btn05 bs" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
            <button class="btn btn01 bs" type="button" :disabled="isDisabledButton" @click="onApply()">
              解約する
              <i class="material-icons link link-icon">east</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.table-type2 th {
  border-bottom: 1px solid #d3d3d3;
  width: 100%;
  display: block; /* セルを縦に */
}
.table-type2 td {
  font-size: 15px; /* フォントは15px */
  width: 100%;
  display: block; /* セルを縦に */
}
li.bold {
  font-weight: bold;
}
button.btn {
  margin-top: 10px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { HikariphoneOption } from '../../../../shared/classes/external-api/e-mansion/customer-response';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { SpfApiHikariPhoneAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-hikari-phone-accessor';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import {
  EMansionHikariPhoneUpdateRequestOptionalDataDto,
  UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO,
} from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-option-dto';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UCOM_HIKARI_PHONE_OPTION_ON_EMANSION, UCOM_HIKARI_PHONE_STATUS_ON_MANSION } from '@/shared/const/e-mansion';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** Completed コンポーネント */
export default defineComponent({
  name: 'e-mansion-hikari-phone-option-cancel-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** e-mansion物件情報 */
    eMansionProperty: null as EMansionProperty | null,
    /** オプションサービス 表示用 */
    optionArrayForDisplay: [] as UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO[],
    /** 解約対象のオプション番号 */
    optionalNumber: null as null | number,
    /** 解約対象のオプション名 */
    serviceName: '' as string | undefined,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
    /** e-mansion 会員ID */
    eMansionMemberId: '' as string,
    /** 物件ID */
    eMansionPropertyId: '' as string,
    /** サービス登録API（UCOM光電話）の返却エラーコード */
    errorCode: '' as string | undefined,
    /** カード登録フラグ 0:登録なし 1:登録あり */
    creditCardRegistFlag: 0 as number,
    /** 番号通知フラグ 0:通知しない 1:通知する */
    notificationFlag: 0 as number,
    /** 解約するボタン押下時の最新情報利用状況 */
    optionList: [] as HikariphoneOption[],
  }),
  computed: {
    /** ボタン非活性判定 */
    isDisabledButton(): boolean {
      // 処理中
      if (this.isLoading || this.isSubmitting) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    try {
      if (!(await MountedCheckService.canReadEMansionScreen())) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      /** 物件基本情報をStoreから取得 */
      await StoreExternalApiResponse.main();
      this.eMansionProperty = this.$store.getters['eMansionCommonStore/property'];

      // 会員情報をStoreから取得(Mye会員Idを取得)
      const member: Member = this.$store.getters['memberStore/member'];
      this.eMansionMemberId = member.primaryKeyMye ?? '';
      if (!this.eMansionMemberId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // サービス名称を取得
      this.optionalNumber = this.$store.getters['eMansionUcomHikariPhoneOptionCancelStore/optionNumber'];
      if (!this.optionalNumber) {
        // データ不整合エラー(前画面からの情報がない)
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      this.serviceName = this.getDisplayServiceName(this.optionalNumber);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
    this.isLoading = false;
  },
  methods: {
    getDisplayServiceName(id: number): string {
      /** オプション解約：OptionNumberからサービス名称（表示用）を取得 */
      for (const v of Object.values(UCOM_HIKARI_PHONE_OPTION_ON_EMANSION)) {
        if (v.number == id) {
          return v.name;
        }
      }
      // fail safe: program error
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
    },
    getServiceName(id: number): string {
      /** オプション解約：OptionNumberからサービス名（インターフェース名）を取得 */
      for (const v of Object.values(UCOM_HIKARI_PHONE_OPTION_ON_EMANSION)) {
        if (v.number == id) {
          return v.service_name;
        }
      }
      // fail safe: program error
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
    },
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      this.isLoading = false;
      this.isSubmitting = false;
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      return;
    },

    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion/hikari-phone/option/manage')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onApply() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      // 一旦エラーメッセージをリセットする
      this.errorMessages = [];

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      /** 契約基本情報をStoreから取得 */
      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];

      // サービス更新前に、最新の情報を取得
      if (customer instanceof EMansionCustomer) {
        // 物件ID
        this.eMansionPropertyId = customer.property_id;

        this.optionList = customer.op.hikariphone[0].hikariphone_option;

        // 解約対象のサービスのステータスが解約可能な状態であるのかを確認（12:利用中のみ解約可能 それ以外だとステータス不正になるのでISP共通エラー画面に遷移）
        const serviceName = this.getServiceName(this.optionalNumber!);
        if (this.optionList.find((e) => e.name === serviceName)?.status != String(UCOM_HIKARI_PHONE_STATUS_ON_MANSION.IN_USE)) {
          await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.CUSTOMER_INFO_API), '');
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          this.isSubmitting = false;
          return;
        }

        // 解約対象以外のパラメータの作りこみ
        //  ・解約対象オプションの場合、固定で'0':申込まないに設定
        //  ・それ以外は''に設定
        const numberDisplayParam = this.assembleParam(UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.NUMBER_DISPLAY.number);
        const catchParam = this.assembleParam(UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH.number);
        const catchNumberDisplayParam = this.assembleParam(UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH_NUMBER_DISPLAY.number);
        const optionPackParam = this.assembleParam(UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.OPTION_PACK.number);

        const optionalData = new EMansionHikariPhoneUpdateRequestOptionalDataDto({
          Hikariphone: {
            notificationFlag: '',
            numberDisplay: numberDisplayParam,
            catch: catchParam,
            catchNumberDisplay: catchNumberDisplayParam,
            optionPack: optionPackParam,
          },
        });

        const servicePlanType = await this.$store.dispatch('servicePlanTypeStore/servicePlanType');

        try {
          // サービス更新API(UCOM光電話) を呼び出す
          const updateHikariPhoneInfo = await SpfApiHikariPhoneAccessor.cancelHikariPhoneOption(
            this.eMansionMemberId,
            this.eMansionPropertyId,
            servicePlanType,
            optionalData,
            this.creditCardRegistFlag,
            this.optionalNumber!
          );
          this.$store.commit('eMansionUcomHikariPhoneOptionEntriedInfoStore/info', updateHikariPhoneInfo);
        } catch (error: any) {
          this.$store.commit('eMansionUcomHikariPhoneOptionEntriedInfoStore/info', null);
          await VueErrorHandler.handle(error, '');
          // 上記以外のエラーの場合はe-mansion共通エラー画面に遷移
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          this.isSubmitting = false;
          return;
        }

        await this.$router
          .push('/e-mansion/hikari-phone/option/cancel-completed')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: this.eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });
      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
    /** 解約オプション番号 利用状況に応じたパラメータを取得 */
    assembleParam(id: number): string {
      if (id == this.optionalNumber) {
        return '0'; // 0:申し込まない
      } else {
        return '';
      }
    },
  },
});
</script>
