import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionGipRegisterResponseDto } from '@/shared/classes/spf-api/e-mansion/e-mansion-gip';
import { BaseApiService } from '@/shared/services/api/base-api-service';

const api = BaseApiService.api();

/** 内部 API をコールするサービス */
export class SpfApiGipAccessor {
  /**
   * e-mansion 固定グローバルIPアドレス　サービス登録API
   * @return e-mansion 会員ID, apartmentId,macAddress
   */
  public static async registerGip(
    primaryKeyMye: string,
    apartmentId: string,
    macAddress: string | undefined,
    creditCardRegistFlag: string
  ): Promise<EMansionGipRegisterResponseDto> {
    const response = await api
      .post<EMansionGipRegisterResponseDto>(`/global-ip/e-mansion/touroku`, {
        primaryKeyMye,
        apartmentId,
        macAddress,
        creditCardRegistFlag,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return response.data;
  } // registerGIP
}
