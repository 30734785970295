<template>
  <div class="e-mansion-ip-phone-apply">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion IPフォン</h1>
    </main>

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion 会員専用トップ</router-link>
        </li>
        <li>e-mansion IPフォン</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">お申し込み</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc -->
      <div class="blc">
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <!-- サブタイトル：IPフォン申し込み -->
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansion IPフォン お申し込み" />e-mansion IPフォン　お申し込み</h2>

        <p>e-mansion IPフォンのお申し込みお手続きを行います。</p>
        <br />
        <p>
          <span class="red">
            ご契約のマンション住所にテレフォニーアダプター（レンタル機器）をお送りします。e-mansion
            インターネット接続サービスご利用開始後に、テレフォニーアダプターを接続してください。050から始まるIPフォン電話番号を取得し設定することで、ご利用いただけます。
          </span>
        </p>

        <!-- sblc 申込内容 -->
        <div class="sblc">
          <h3 class="service-h3">e-mansion IPフォンお申し込み</h3>
          <p>e-mansion IPフォンのご利用には、専用のテレフォニーアダプター（接続機器）が必要です。</p>
          <br />
          <p>
            テレフォニーアダプターのお届け日時をご指定ください。指定されたお届け日時がご請求開始の起算日となります。ただし、e-mansion
            インターネット接続サービス提供前の場合は、接続サービス提供開始日が起算日となります。キャンペーン等がある場合はそちらが適用されます。
          </p>

          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>ご希望のお届け日時</b><span class="req">必須</span></th>
                <td>
                  <p>
                    <span class="red">※{{ displayAvailableDeliveryDate }}以降、ご指定可能です。</span>
                  </p>
                  <select name="year" class="form-select" v-model="delivery_year">
                    <option v-for="n in 2" :key="n" v-bind:value="currentYear + n - 1">{{ currentYear + n - 1 }}</option>
                  </select>
                  年&nbsp;&nbsp;
                  <select name="month" class="form-select" v-model="delivery_month">
                    <option v-for="n in 12" :key="n" v-bind:value="n">{{ n }}</option>
                  </select>
                  月&nbsp;&nbsp;
                  <select name="day" class="form-select" v-model="delivery_date">
                    <option v-for="n in 31" :key="n" v-bind:value="n">{{ n }}</option>
                  </select>
                  日&nbsp;&nbsp;
                  <select name="schedule delivery" class="form-select" v-model="delivery_time_zone">
                    <option v-for="deliveryTimeZone in deliveryTimeZoneList" :key="deliveryTimeZone.key" v-bind:value="deliveryTimeZone.value">
                      {{ deliveryTimeZone.name }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="att black mt15">※テレフォニーアダプターは、ご契約マンションの住所にお送りします。受取が可能な日時をご指定ください。</p>
          <p class="att red mt15">
            ※テレフォニーアダプターはレンタル品のため、お客様の故意または過失による故障や破損の場合、端末機器損害金20,900円（課税対象外）を請求いたします。
          </p>
          <p class="att black mt15">
            ※ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」の5営業日より前にお申し込みいただくと、鍵のお引渡し日よりご利用可能となります。ただし、鍵のお引渡し日が休日の場合は、5営業日前の前日までにお申し込みいただくと、鍵のお引渡し日よりご利用可能となります。
          </p>
          <!-- 営業日 -->
          <ul>
            <li>土曜日</li>
            <li>日曜日</li>
            <li>国民の祝日および国民の休日</li>
            <li>年末年始(12月29日～1月3日)</li>
          </ul>
          <p>上記以外の平日が、e-mansionの営業日となります。</p>
        </div>
        <!-- /sblc -->
      </div>
      <!-- /blc -->

      <!-- blc ボタン-->
      <div class="blc">
        <p class="form-btn-txt">ご確認の上、よろしければ「申し込む」をクリックしてください。</p>
        <div class="btn-area">
          <div>
            <button class="btn btn05 bs" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
            &nbsp;
            <button class="btn btn01 bs" type="button" @click="onApply()" :disabled="isDisabledButton">申し込む <i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

p.form-btn-txt {
  text-align: center;
  font-weight: bold;
}

div.btn-area {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
input.text.middle {
  width: 320px;
}
/* 検索ボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
span.red {
  color: #cf1225;
}
span.black {
  color: black;
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { defineComponent } from 'vue';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-account-accessor';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { BusinessdaysUtilDTOArray } from '@/shared/classes/spf-api/businessdays-util-dto';
import { eMansionNotifyMailAddressCheckRequest } from '@/shared/classes/spf-api/mail/model/account/e-mansnion-notify-mailaddress-check-request';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { processYearNumber } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { EMansionIPPhoneInputApplyForm } from '@/store/e-mansion/e-mansion-ip-phone-store';

/** Apply コンポーネント */
export default defineComponent({
  name: 'e-mansion-ip-phone-apply',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },

  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],

    /** 物件基本情報 */
    eMansionProperty: null as EMansionProperty | null,

    /** 通知先メールチェック結果 true:エラー（初期値） */
    isErrorNotifyEmail: false as boolean,

    /** 配達可能日の年欄の入力データ(当年) */
    currentYear: 0,
    /** Sin日(serviceIn) */
    sinDate: null as Date | null,
    /** 指定可能日時（5営業日後またはSIN日の大きいほう） */
    availableDeliveryDate: null as Date | null,
    /** 表示用：指定可能日時（5営業日後またはSIN日の大きいほう） */
    displayAvailableDeliveryDate: null as string | null,
    /** 配達日／時間帯指定 */
    deliveryDay: null as Date | null,
    delivery_year: undefined as undefined | number,
    delivery_month: undefined as undefined | number,
    delivery_date: undefined as undefined | number,
    delivery_time_zone: undefined as undefined | string,
    deliveryTimeZoneList: [
      { name: '午前中', value: '0', key: '0' },
      { name: '14時 ～ 16時', value: '2', key: '2' },
      { name: '16時 ～ 18時', value: '3', key: '3' },
      { name: '18時 ～ 20時', value: '4', key: '4' },
      { name: '19時 ～ 21時', value: '5', key: '5' },
    ],
  }),
  /** バリデーション定義 */
  validations: {
    delivery_year: {
      required,
    },
    delivery_month: {
      required,
    },
    delivery_day: {
      required,
    },
    delivery_time_zone: {
      required,
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted() {
    try {
      if (!(await MountedCheckService.canReadEMansionScreen())) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      /** Storeから取得  CME00-005 通知先メールチェックAPI呼び出しに利用 */
      const member = this.$store.getters['memberStore/member']; // 会員取得
      const property: Property | null = this.$store.getters['propertyStore/property']; // 物件情報
      // !member , !property：MountedCheckService.canReadEMansionScreenでチェック済み
      if (!member.primaryKeyMye) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }
      // 現在年を取得（配達希望日 年欄選択肢）
      this.currentYear = processYearNumber();

      // 営業日マスタ取得API呼び出し（5営業日後）
      // 取得の日付のタイムゾーン合わせ
      // DB上の情報は日本の日付が前提になるが、Date型にpartialされた時点でシステム日時になっている
      // 取得時の表示文字列の形式をYYYYMMDDで受け取ることでem_sin_timeと共通のロジックでDate型データを取得
      const businessdaysUtilDtoResponse = await this.getBusinessdaysForIpPhone();
      const businessDay5 = this.getDateFromStringYYYYMMMDD(businessdaysUtilDtoResponse[0].displayBusinessday);
      if (!businessDay5) {
        // ５営業日後の日付が取得できなかった場合、共通エラー画面に遷移
        throw Error('businessday error');
      }

      // Sin日 Date型
      this.eMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      const eMSinDate = this.getDateFromStringYYYYMMMDD(this.eMansionProperty?.em_sin_time);
      if (!eMSinDate) {
        // Sin日が取得できなかった場合、共通エラー画面に遷移
        throw Error('em_sin_time error');
      }

      // 指定可能日時(現在の日付の5営業日後またはSin日の大きい方)の判定
      if (eMSinDate.getTime() > businessDay5.getTime()) {
        this.availableDeliveryDate = eMSinDate;
        this.displayAvailableDeliveryDate = this.getDisplayDate(eMSinDate);
      } else {
        this.availableDeliveryDate = businessDay5;
        this.displayAvailableDeliveryDate = this.getDisplayDate(businessDay5);
      }

      // 初期表示：希望配達日時 ストアにデータがあった場合は表示に反映（確認画面からの戻り）
      const inputApplyForm = await this.$store.getters['eMansionIPPhoneStore/inputApplyForm'];
      if (inputApplyForm != null) {
        this.delivery_year = Number(inputApplyForm.deliveryDay.slice(0, 4));
        this.delivery_month = Number(inputApplyForm.deliveryDay.slice(5, 7));
        this.delivery_date = Number(inputApplyForm.deliveryDay.slice(8, 10));
        this.delivery_time_zone = inputApplyForm.deliveryTimeZone.value;
        // ひとまずリストアしたデータthis.deliveryDay（Date型データ）に変換（無効値の場合、this.deliveryDayにはnullが設定される）
        this.validateDeliveryDate();
      } else {
        this.deliveryDay = null;
      }

      // ストアにデータがない（またはエラー）の場合、配達希望日時に配達可能日時が適用されていること
      if (!this.deliveryDay) {
        this.delivery_year = this.availableDeliveryDate.getFullYear();
        this.delivery_month = this.availableDeliveryDate.getMonth() + 1;
        this.delivery_date = this.availableDeliveryDate.getDate();
        this.delivery_time_zone = this.deliveryTimeZoneList.find((e) => e.key === '0')!.value; // '午前中'
      }

      // CME00-005_通知先メールチェックのチェック結果が「1:エラー」
      const primaryKeyMye = member.primaryKeyMye;
      const apartmentId = property!.apartmentId;
      const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      const uaType = property!.uaType;
      const checkNotifyAccount = await SpfApiAccountAccessor.getEMansionAccountNotifyEmail(
        new eMansionNotifyMailAddressCheckRequest(primaryKeyMye!, apartmentId!, servicePlanType, uaType!)
      );
      // WIP:テスト時は戻り値をコード上で修正
      // checkNotifyAccount.errors = 'failure';
      if (checkNotifyAccount) {
        this.isErrorNotifyEmail = true;
        this.errorMessages.push(String(checkNotifyAccount.messages));
        this.showErrorMessage();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      // ISP共通エラー画面に遷移
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
    this.isLoading = false;
  },
  computed: {
    /** ボタン非活性チェック
     * CME00-005_通知先メールチェックのチェック結果が「1:エラー」 or ローディング中
     */
    isDisabledButton(): boolean {
      if (this.isLoading || this.isErrorNotifyEmail) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      // IPフォン周りのstoreデータはrouterで初期化する

      await this.$router
        .push('/e-mansion/ip-phone/terms')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          // 確認画面もしくは本人認証結果取得画面のサービス登録APIで登録されたエラーメッセージをクリアする
          this.$store.commit('eMansionIPPhoneStore/from', null);
          this.isSubmitting = false;
        });
    },
    /** 申込むボタン押下時の処理 */
    async onApply() {
      // WIP:チェックを行う
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      // バリデーションチェック(入力チェック、配達可能日チェック)
      const isAvailableSend = this.validate();
      if (!isAvailableSend) {
        this.showErrorMessage();
        return;
      }

      // ストアに次画面で利用する情報：配達希望日、配達希望時間帯をコミットする
      const inputDeliveryday = dayjs(this.deliveryDay).tz('Asia/Tokyo').format('YYYY/MM/DD');
      const inputApplyForm = new EMansionIPPhoneInputApplyForm({
        deliveryDay: inputDeliveryday,
        deliveryTimeZone: this.deliveryTimeZoneList.find((e) => e.value === this.delivery_time_zone),
      });
      this.$store.commit('eMansionIPPhoneStore/inputApplyForm', inputApplyForm);

      // 確認画面に遷移
      await this.$router
        .push('/e-mansion/ip-phone/confirm')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.$store.commit('eMansionIPPhoneStore/from', null);
          this.isSubmitting = false;
        });
    },
    /** フォームのバリデーション */
    validate(): boolean {
      this.errorMessages = [];
      // 配達希望日時が取得できていない場合エラー WIP プログラム上あり得ない
      if (this.availableDeliveryDate == null) {
        return false;
      }

      // 必須チェック
      // 希望配達日入力チェック(日付文字列:'yyyy/mm/dd')
      this.validateDeliveryDate();
      if (!this.deliveryDay) {
        this.errorMessages.push(`「ご希望のお届け日時」をご確認の上、正しく入力してください。`);
        return false;
      }

      // 希望日時と配達可能日時の比較 希望日が配達可能日時より前を指定
      if (this.availableDeliveryDate.getTime() > this.deliveryDay.getTime()) {
        this.errorMessages.push(`「ご希望のお届け日時」をご確認の上、正しく入力してください。`);
        return false;
      }

      return true;
    },
    /** 初期表示：IPフォンの指定可能日判定用営業日取得（５営業日後の日付を返却） */
    async getBusinessdaysForIpPhone(): Promise<BusinessdaysUtilDTOArray> {
      const baseDateUTC = dayjs(new Date()).tz('UTC');
      const baseDateJP = dayjs(new Date()).tz('Asia/Tokyo');
      const baseDate = baseDateUTC.toDate();

      return await SpfApiService.getBusinessdaysUtil('1', baseDate, 5, 1, 'YYYYMMDD', true);
    },
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      this.isLoading = false;
      this.isSubmitting = false;
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      return;
    },
    /** 指定可能日時の表示形式に成形 */
    getDisplayDate(date: Date) {
      if (!date) {
        return null;
      }
      return dayjs(date).tz().format('YYYY年MM月DD日');
    },
    /** yyyymmdd日付(数値文字列)string -> Date変換 */
    getDateFromStringYYYYMMMDD(date: string | undefined): Date | null {
      if (!date) {
        return null;
      }
      const sinYear = parseInt(date.slice(0, 4), 10);
      const sinMonth = parseInt(date.slice(4, 6), 10) - 1; // 0～11の値
      const sinDay = parseInt(date.slice(6, 8), 10);
      return new Date(sinYear, sinMonth, sinDay);
    },
    /** 配達希望日入力チェック */
    validateDeliveryDate(): boolean {
      if (!this.delivery_year || !this.delivery_month || !this.delivery_date || !this.delivery_time_zone) {
        // 初期値（または申込確認画面からの戻り値）が設定される・選択外の値がプルダウンに存在しないので、このパスは本来通らない
        this.deliveryDay = null;
        return false;
      }
      this.deliveryDay = new Date(this.delivery_year, this.delivery_month - 1, this.delivery_date);

      const month = this.deliveryDay.getMonth() + 1;
      const deliveryMonth = this.delivery_month ? Number(this.delivery_month) : -1;
      if (month !== deliveryMonth) {
        this.deliveryDay = null;
        return false;
      }

      return true;
    },
  },
});
</script>
