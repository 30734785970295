<template>
  <div class="five-a-entry-confirm">
    <LoadingComponent v-if="!isMounted || isSubmitting" />
    <main class="underlayer-main">
      <h1>Connectix お申し込み内容の確認</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A Connectix お申し込み</li>
        <li>お申し込み内容の確認</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お客様情報入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="入力内容の確認" />入力内容の確認</h2>
        <div class="sblc">
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
          <h3 class="service-h3">お客様情報</h3>
          <button class="sbtn fix-btn" v-on:click="onEdit()"><i class="material-icons link">west</i>修正</button>
          <p>お客様情報に修正がありましたら「修正」ボタンをクリックして、Portasの会員情報を修正してください。</p>
          <div v-if="customer" class="course-list">
            <div class="grid pc-grid2 sp-grid1">
              <dl>
                <dt>建物名</dt>
                <dd>{{ propertyName }}</dd>
              </dl>
              <dl>
                <dt>お部屋番号</dt>
                <dd>{{ customer.roomNumber }}</dd>
              </dl>
              <dl>
                <dt>お名前</dt>
                <dd>{{ customer.nameSurname }}&nbsp;&nbsp;{{ customer.nameFirstName }}</dd>
              </dl>
              <dl>
                <dt>フリガナ</dt>
                <dd>{{ customer.kanaSurname }}&nbsp;&nbsp;{{ customer.kanaFirstName }}</dd>
              </dl>
              <dl>
                <dt>生年月日</dt>
                <dd>{{ dispBirthday }}</dd>
              </dl>
              <dl>
                <dt>ご連絡先お電話番号１</dt>
                <dd>{{ customer.phoneNumber }}</dd>
              </dl>
              <dl>
                <dt>ご連絡先お電話番号２</dt>
                <dd>{{ customer.emergencyPhoneNumber }}</dd>
              </dl>
              <dl>
                <dt>ご連絡先メールアドレス</dt>
                <dd>{{ customer.emailAddress }}</dd>
              </dl>
            </div>
            <div class="grid pc-grid1 sp-grid1">
              <dl>
                <dt>ご住所</dt>
                <dd>〒{{ customer.destinationZipcode }} {{ customer.destinationAddress1 }}</dd>
              </dl>
              <dl>
                <dt>ご入居予定日</dt>
                <dd>{{ customer.scheduledMoveInOn }}</dd>
              </dl>
            </div>
          </div>
        </div>

        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th>サービス名</th>
              <td>Connectix</td>
            </tr>
            <tr>
              <th>料金（月額）</th>
              <td>
                <div v-if="connectixUsageFeeCp">
                  <div style="color: red">キャンペーン中</div>
                  <div>{{ connectixUsageFee }} 円</div>
                  <div style="color: red">→ {{ connectixUsageFeeCp }} 円</div>
                </div>
                <div v-else>
                  <div>{{ connectixUsageFee }} 円</div>
                </div>
              </td>
            </tr>
          </table>
          <p class="att grey">※価格は全て税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
          <p class="att grey">※本サービスはベストエフォートの為、時間帯や状況などによって異なることがあり、保証されるものではございません。</p>
        </div>

        <div class="sblc">
          <h3 class="service-h3">お支払い方法</h3>
          <div>
            <credit-card-component
              v-if="isMounted"
              :key="resetCounter"
              :reset-counter="resetCounter"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispName="ispName"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-on:portasCardHolderName="getCardHolderNameFromPortas"
              v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
              v-on:onPostCreditCard="getCreditCardToken"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:getTokenExpireDate="getTokenExpireDate"
              v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
              v-on:change="isAgreedCopyCardToIsp = $event"
              v-on:cardholderNameFormForAuthorizePortasCard="getCardholderNameFormForAuthorizePortasCard"
            />
          </div>
        </div>

        <div class="sblc">
          <p class="border-grey bg-grey pd20 mt40 ml40">
            ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
            ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
          </p>
          <p class="form-btn-txt mt40">ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。修正がある場合は、「戻る」ボタンをクリックしてください。</p>
          <p class="red form-btn-txt">
            「お申し込みを確定」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
          </p>
        </div>
        <div class="btn-area">
          <div>
            <button class="btn-height btn btn05 bs" @click="onBack"><i class="material-icons link link-icon">west</i>戻る</button>
          </div>
          <div class="has-checkbox">
            <button class="btn-height btn btn01 bs" type="button" @click="onNext" :disabled="isDisabledButton">
              お申し込みを確定する<i class="material-icons link link-icon">east</i>
            </button>
            <div class="my-checkbox-outer">
              <input id="isAgreedPrivacyPolicy" v-model="isAgreedPrivacyPolicy" type="checkbox" name="isAgreedPrivacyPolicy" :disabled="isExpiredPortasCreditCard" />
              <p class="isAgreedPrivacyPolicy">
                <label for="isAgreedPrivacyPolicy">各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* course-list
---------------------------------------------------- */
.course-list {
  margin: 10px 0;
  border-top: 1px solid #d3d3d3;
}

.course-list dl {
  padding: 10px 20px;
  margin: 0;
  border-bottom: 1px solid #d3d3d3;
  word-break: break-all;
}

.course-list dl.line {
  background-color: #fbfbfb;
}

.course-list dl dt {
  font-weight: bold;
  float: left;
}

.course-list dl dd {
  padding-left: 180px;
}

.course-list .pc-grid2 dl:nth-child(odd) {
  border-right: 1px solid #d3d3d3;
}

/* sbtn
---------------------------------------------------- */
.sbtn {
  min-width: 80px;
  line-height: 26px;
  text-align: center;
  font-size: 13px;
  color: #cf1225;
  border: 1px solid #cf1225;
  border-radius: 2px;
  margin-top: -40px;
  padding: 0 10px;
  background-color: #ffffff;
}

.sbtn:hover {
  opacity: 0.7;
}

.fix-btn {
  float: right;
  color: #939393;
  border: 1px solid #939393;
  margin-top: -40px;
}

.btn01:disabled {
  opacity: 0.5;
}

div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}

/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}

@media only screen and (max-width: 767px) {
  /* course-list
  ---------------------------------------------------- */
  .course-list dl {
    padding: 10px 10px;
  }

  .course-list dl dt {
    width: 100px;
  }

  .course-list dl dd {
    padding-left: 120px;
    margin: 0;
  }

  .course-list .pc-grid2 dl:nth-child(odd) {
    border-right: none;
  }
}

div.btn-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  text-align: center;

  & div.has-checkbox {
    margin-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 16px;
      left: 0;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  div.btn-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 64px;

    & div.has-checkbox {
      padding-top: 0;

      & div.my-checkbox-outer {
        top: 0;
        left: 50%;
        text-align: left;

        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { FiveAProperty } from '../../../shared/classes/external-api/five-a/property-response';
import { FiveAConnectixFee, FiveAEntryInputForm } from '../entry/classes/entry-input-form';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { LinkServiceWithFiveARequest } from '@/shared/classes/spf-api/link-service-with-five-a';
import { Member } from '@/shared/classes/spf-api/member';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
/** クレジットカード用コンポーネント */
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
/** Entity five-a Connectix API : エラー時のレスポンス */
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** Confirm コンポーネント */
export default defineComponent({
  name: 'confirm',
  components: {
    ErrorMessagesComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
    LoadingComponent,
  },
  data: () => ({
    customer: null as FiveAEntryInputForm | null,
    fiveAProperty: null as FiveAProperty | null,
    member: null as Member | null,
    property: null as Property | null,
    /** 生年月日（表示用） */
    dispBirthday: '' as string,
    /** Connectix利用金額 */
    connectixUsageFee: '' as string,
    /** Connectixキャンペーン利用金額 */
    connectixUsageFeeCp: '' as string,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    // クレジットカード情報必要可否フラグ
    needCreditCard: false as boolean,
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '' as string,
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '' as string,
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    /** APIトークンキー */
    apiTokenKey: '' as string,
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込みを確定する」ボタンをクリックしてください。',
    /** ボタン押下判定 */
    isSubmitting: false as boolean,
    /** 建物名 */
    propertyName: '' as string,
    /** 会員ID */
    memberId: 0 as number,
    /** クレジットカード名義人 */
    cardHolderNameOnPortas: '',
    // mounted()実行中かどうか
    isMounted: false as boolean,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面ではFive.A固定) */
    ispName: 'Five.A',
    /** ユーザーがPortasからカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    resetCounter: 0,
    /** Portasで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizePortasCard: '' as string | undefined,
    oemsMaster: [] as Array<GetOemsMasterResponse>, // OEMマスタ
    domainName: '', // 「こちら」リンク用
    apid: '',
  }),

  async mounted(): Promise<void> {
    try {
      if (!(await MountedCheckService.canReadFiveAEntryScreen())) {
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      // 会員情報取得処理
      this.member = this.$store.getters['memberStore/member'];
      // 物件情報の取得
      this.property = this.$store.getters['propertyStore/property'];
      if (this.property) {
        this.propertyName = this.property.dispApartmentName;
      }

      // Five.A会員情報取得処理
      this.customer = this.$store.getters['fiveAEntryStore/entryInputForm'];
      // customer情報が無い場合、会員規約の同意をしてないことになるため、エラー画面にする
      if (!this.customer) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }

      // !this.member は、ほぼ生じえない
      // マイページ編集後に限定して処理した方がより良いが、煩瑣になるため一旦はこの形としておく
      if (this.$store.getters['platformEditStore/beforePath'] === '/5a/entry/confirm' || !this.member) {
        this.$store.commit('platformEditStore/beforePath', null);
        this.member = this.$store.getters['memberStore/member']; // beforeEach により、変更後の会員情報が取得される
        if (!this.member || !this.member.propertyId) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_MEMBER);
        }
        // 修正後の member データで更新（紐づけ物件の修正は生じえない）
        this.customer.buildingId = this.member.buildingNumber ?? '';
        this.customer.nameSurname = this.member.firstName;
        this.customer.nameFirstName = this.member.givenName;
        this.customer.kanaSurname = this.member.firstNameKatakana;
        this.customer.kanaFirstName = this.member.givenNameKatakana;
        this.customer.birthday = this.member.birthdate?.replace(/-/g, '/') ?? '';
        this.customer.emailAddress = this.member.emailAddress;
        if (this.member.phoneNumber) {
          this.customer.phoneNumber = this.member.phoneNumber;
        }
      }

      this.dispBirthday = this.getBirthday(this.customer.birthday);

      // 物件基本情報を取得
      const params = {
        propertyId: this.property!.apartmentId,
        uaType: UA_TYPE.FIVE_A,
      };
      const propertyInfo: FiveAProperty | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', params);
      if (propertyInfo instanceof FiveAProperty) {
        this.fiveAProperty = propertyInfo;
        // Connectix利用金額
        this.connectixUsageFee = this.fiveAProperty.op.sdwan.fee;
        this.connectixUsageFeeCp = this.fiveAProperty.op.sdwan.fee_cp;
        const fiveAConnectixFee: FiveAConnectixFee = {
          connectixUsageFee: this.connectixUsageFee,
          connectixUsageFeeCp: this.connectixUsageFeeCp,
        };
        this.$store.commit('fiveAEntryStore/fiveAConnectixFee', fiveAConnectixFee);
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
      }

      // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
      }

      // APIトークンキーを設定
      this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION;

      if (this.member.veritransAccountId) {
        this.portasVeriTransId = this.member.veritransAccountId;
      }

      // 会員情報をStoreから取得
      const member: Member = await this.$store.dispatch('memberStore/member');
      // 物件情報をStoreから取得
      const property: Property = await this.$store.dispatch('propertyStore/property', member.propertyId);
      try {
        //ドメイン名の取得 */
        if (property.oemType) {
          this.oemsMaster = await this.$store.dispatch('oemsStore/oems');
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const filtered = this.oemsMaster.filter((oem: any) => oem.oemType === String(property.oemType));
          if (filtered.length >= 1) {
            let latestOem = filtered[0];
            if (filtered.length >= 2) {
              for (let i = 1; i < filtered.length; i++) {
                if (latestOem.startAt < filtered[i].startAt) {
                  latestOem = filtered[i];
                }
              }
            }
            this.domainName = latestOem.domainForOsumai;
          }
        } else {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
        }
      } catch (error: any) {
        // 共通エラーに遷移
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      // apartment_id → APID に変換
      this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
    } catch (error: any) {
      await VueErrorHandler.handle(error, 'mounted hook');
      await this.$router.push('/5a/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    } finally {
      this.isMounted = true;
    }
  },
  methods: {
    /** 表示用生年月日を取得する */
    getBirthday(birthday: string) {
      const date = birthday.split('/');
      const birthdayYear = Number(date[0]);
      const birthdayMonth = Number(date[1]);
      const birthdayDate = Number(date[2]);
      return String(birthdayYear) + '年 ' + String(birthdayMonth) + '月 ' + String(birthdayDate) + '日';
    },
    /** クレジット関連関数 ここから */
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /** クレジット関連関数 ここまで */
    /** 修正ボタン押下時：入力に戻る */
    async modCustomerInfo() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/my-page/member-edit').catch((error: any) => {
        checkRouterError(error);
      });
    },
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    /** 支払方法が登録されている場合のカード保有者名を受け取る */
    getCardHolderNameFromPortas(cardHolderName: string) {
      this.cardHolderNameOnPortas = cardHolderName;
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    /** 申込内容一時保存API の実行 */
    async temporarySavingApplicationData(applicationData: TemporarySavingApplicationDataRequest) {
      return await SpfApiService.temporarySavingApplicationData(applicationData);
    },
    /** 次へボタン押下時： 完了画面に遷移する */
    async onNext() {
      if (!this.customer) {
        return;
      }

      try {
        // ボタン押下中は何もしない
        if (this.isSubmitting) {
          return;
        }
        // ボタン押下中扱いとする
        this.isSubmitting = true;
        // エラーメッセージ格納配列初期化
        this.errorMessages = [];
        if (
          // Portasからカード情報をコピーせず、新規登録する場合
          (this.creditCardAccessToken === '' && !this.isAgreedCopyCardToIsp) ||
          // Portasからカード情報をコピーする場合
          (this.isAgreedCopyCardToIsp &&
            !(
              this.cardHolderNameOnPortas ||
              (this.cardholderNameFormForAuthorizePortasCard &&
                !(this.cardholderNameFormForAuthorizePortasCard.length < 2) &&
                !(this.cardholderNameFormForAuthorizePortasCard.length > 45))
            ))
        ) {
          // クレジットカードが登録されておらず、かつAPIキーが発行されていない、かつカードコピーに同意していない場合はエラー
          this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
          // エラーメッセージを見せるために画面最上部にスクロール
          this.showErrorMessage();
          return;
        }

        // PORTAS会員IDの取得
        const member: Member | null = this.$store.getters['memberStore/member'];
        if (member) {
          this.memberId = member.id;
        }

        /** クレジットカードの情報を追加 */
        if (this.creditCardAccessToken) this.customer.creditCardToken = this.creditCardAccessToken;
        if (this.creditTokenExpireDate) this.customer.tokenExpireDate = this.creditTokenExpireDate;

        /** 郵便番号を数値のみに修正（ハイフンを削除） */
        this.customer.destinationZipcode = this.customer.destinationZipcode.replaceAll(/-/g, '');
        if (!this.customer.buildingId) {
          this.customer.buildingId = '0000';
        }

        const linkServiceWithFiveARequest: LinkServiceWithFiveARequest = new LinkServiceWithFiveARequest(this.customer);

        if (linkServiceWithFiveARequest.faxNumber === '') {
          linkServiceWithFiveARequest.faxNumber = undefined;
        }
        linkServiceWithFiveARequest.hikariphone = '0';

        // linkServiceWithFiveARequestのcard_register_flagに値を入れる（1:登録あり）
        // クレカコピーでも登録でもどちらも1になる
        linkServiceWithFiveARequest.creditCardRegistFlag = '1';

        /** VeriTransの3Dセキュア本人認証開始URLへ遷移する */
        if ((this.isAgreedCopyCardToIsp && this.portasVeriTransId) || (this.creditCardAccessToken && this.cardTokenFor3dSecureAuthorize && this.isAgreedPrivacyPolicy)) {
          await this.start3DSecureProcess(linkServiceWithFiveARequest);
        }
      } catch (error: any) {
        await VueErrorHandler.handle(error, 'native event handler');
        this.$router.push('/5a/error').catch((error) => {
          checkRouterError(error);
        });
        this.isSubmitting = false;
        return;
      }
    },
    async start3DSecureProcess(request: LinkServiceWithFiveARequest) {
      // uuidを発行
      const uuidForTemporarySavingApplicationData = uuidv4();

      /** Portasからのカードコピー同意時、カード会員 IDを渡す */
      if (this.isAgreedCopyCardToIsp && this.portasVeriTransId) {
        request.cardAccountId = this.portasVeriTransId;
        /** 入力したクレジットカード登録時、カードトークンとカードトークン有効期限を渡す */
      } else if (this.creditCardAccessToken && this.cardTokenFor3dSecureAuthorize && this.isAgreedPrivacyPolicy) {
        request.creditCardToken = this.creditCardAccessToken;
        request.tokenExpireDate = this.creditTokenExpireDate;
      }

      const paymentMethodRegisterApplicationDataJson = JSON.stringify({ request: request, member: this.member, property: this.property, propertyInfo: this.fiveAProperty });

      const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
        uuid: uuidForTemporarySavingApplicationData,
        subsequentProcess: 'five-a-apply',
        applicationDataJson: paymentMethodRegisterApplicationDataJson,
      });

      try {
        await this.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
      } catch {
        await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA), 'native event handler');
        await this.$router.push('/5a/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      // uuidをローカルストレージへ保存
      localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

      let cardHolderNameForAuthorize: string;

      if (this.cardHolderNameOnPortas) {
        cardHolderNameForAuthorize = this.cardHolderNameOnPortas;
      } else {
        cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizePortasCard!;
      }

      // Portasに登録されているクレジットカード情報を Five.A へコピーする場合
      if (this.isAgreedCopyCardToIsp && this.isAgreedPrivacyPolicy && this.portasVeriTransId) {
        // VeriTrans_本人認証(VeriTrans会員ID使用)APIへ渡すデータ
        const dddSecureAuthStartInfoWithVeritransAccountId = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
          uuid: uuidForTemporarySavingApplicationData,
          externalVeritransAccountId: this.portasVeriTransId,
          cardholderName: cardHolderNameForAuthorize,
        });

        // VeriTrans_本人認証(VeriTrans会員ID使用)API の実行
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartInfoWithVeritransAccountId);

          // コンポーネントの破棄・再描画のために key値 を変更する
          this.addResetCounter();

          location.href = authStartURL;
          this.isSubmitting = false;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(VeriTrans会員ID使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`
            );

            //チェックボックス非表示＆チェックを外す
            this.isAgreedCopyCardToIsp = false;
            this.showErrorMessage();
            return;
          } else {
            await VueErrorHandler.handle(error, 'native event handler');
            await this.$router.push('/5a/error').catch((error) => {
              checkRouterError(error);
            });
            this.isSubmitting = false;
            return;
          }
        }
        // Portasに登録された支払方法を Five.A へコピーしない場合、またはFive.AとPortasの両方でクレジットカード情報が未登録の場合
      } else if (this.creditCardAccessToken && this.cardTokenFor3dSecureAuthorize && this.isAgreedPrivacyPolicy) {
        // VeriTrans_本人認証(トークン使用)APIへ渡すデータ
        const dddSecureAuthStartInfoWithToken = new Create3dSecureAuthStartInfoWithCardTokenRequest({
          uuid: uuidForTemporarySavingApplicationData,
          creditCardToken: this.cardTokenFor3dSecureAuthorize,
          serviceProviderIsp: SERVICE_PROVIDER.MYE,
        });

        // VeriTrans_本人認証(トークン使用)API の実行
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartInfoWithToken);

          location.href = authStartURL;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(VeriTrans会員ID使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`
            );

            // コンポーネントの破棄・再描画のために key値 を変更する
            this.addResetCounter();

            // クレジットカードトークンとトークン有効期限を初期化
            this.creditCardAccessToken = '';
            this.creditTokenExpireDate = '';

            //チェックボックスのチェックを外す
            this.isAgreedPrivacyPolicy = false;
            this.showErrorMessage();

            return;
          } else {
            await VueErrorHandler.handle(error, 'native event handler');
            await this.$router.push('/5a/error').catch((error) => {
              checkRouterError(error);
            });
            this.isSubmitting = false;
            return;
          }
        }
        this.isSubmitting = false;
      }
    },

    async onEdit() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/platform/my-page/member-edit')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;
      // 入力ページに遷移
      await this.$router
        .push('/5a/entry/input')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /** クレジットカードコンポーネントのフォームに入力された、Portas登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizePortasCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizePortasCard = cardHolderName;
    },
  },
  computed: {
    isDisabledButton(): boolean {
      if (this.creditCardAccessToken && this.cardTokenFor3dSecureAuthorize && this.isAgreedPrivacyPolicy) {
        return false;
      } else if (!this.isExpiredPortasCreditCard && this.isAgreedCopyCardToIsp && this.isAgreedPrivacyPolicy) {
        return false;
      } else {
        return true;
      }
    },
  },
});
</script>
