<template>
  <div class="e-mansion-entry-confirm">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion ご入会お申し込み</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion ご入会お申し込み</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid3 gap10">
        <li>お客様情報入力</li>
        <li>接続コース選択</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <!-- blc:ご入会お申し込み（接続コース選択） -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="確認" />確認</h2>
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <div class="sblc">
          <h3 class="service-h3">お客様情報</h3>
          <table class="table-type2">
            <tbody>
              <tr>
                <th>マンション名</th>
                <td>{{ customer.property_name }}</td>
              </tr>
              <tr v-if="customer.building_name">
                <th>棟名</th>
                <td>{{ customer.building_name }}</td>
              </tr>
              <tr>
                <th>お部屋番号</th>
                <td>{{ customer.room_number }}</td>
              </tr>
              <tr>
                <th>お名前</th>
                <td>{{ customer.name_surname }}&nbsp;&nbsp;{{ customer.name_first_name }}</td>
              </tr>
              <tr>
                <th>フリガナ</th>
                <td>{{ customer.kana_surname }}&nbsp;&nbsp;{{ customer.kana_first_name }}</td>
              </tr>
              <tr>
                <th>生年月日</th>
                <td>{{ dispBirthday }}</td>
              </tr>
              <tr>
                <th>ご連絡先お電話番号</th>
                <td>{{ customer.phone_number }}</td>
              </tr>
              <tr>
                <th>e-mansionからのご連絡先メールアドレス</th>
                <td>{{ customer.email_address }}</td>
              </tr>

              <tr>
                <th>ご入居予定日</th>
                <td>{{ dispScheduledMoveInOn }}</td>
              </tr>
              <tr v-if="!isSlimPlan">
                <th>書類のご希望送付先</th>
                <td>
                  <span v-if="customer.destination_zipcode">〒</span>{{ customer.destination_zipcode }}<br />
                  {{ customer.destination_address1 }} {{ customer.destination_address2 }} {{ customer.destination_telephone }}
                </td>
              </tr>
            </tbody>
          </table>

          <div class="sblc" v-if="selectedCourse.course_id">
            <h3 class="service-h3">インターネット接続サービス</h3>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th>速度（タイプ）</th>
                  <td>{{ selectedCourse.speed }}{{ convertType(selectedCourse.type) }}</td>
                </tr>
                <tr v-if="isNeedAdapter()">
                  <th>接続機器のご利用形態</th>
                  <td>レンタル</td>
                </tr>
              </tbody>
            </table>
            <p>
              ※本サービスはベストエフォート式のサービスのため、実使用速度を保証するものではありません。速度は技術規格上での最大値であり、通信速度は時間帯・状況等により異なる場合があります。
            </p>
            <p>
              ※住戸内に設置されている機器（スイッチングハブなど）または接続する端末、ケーブル等により技術規格上の最大通信速度が変わるため、上記の通信速度(ベストエフォート)の規格にてご利用になりたい場合、機器、端末、ケーブル等をお客様にて変更いただくケースがございます。
            </p>
            <p>※住戸内設置の機器（建物所有者様、区分所有者様の所有物）の仕様については建物によって異なりますので居住者様にてご確認ください。</p>
          </div>
          <div class="sblc" v-if="property.op && ((property.op.ipp.flag == '1' && property.op.mplusphone.flag == '0') || property.op.ift.flag == '1')">
            <h3 class="service-h3">お申し込みオプションサービス</h3>
            <table class="table-type2">
              <tbody>
                <tr v-if="property.op.ipp.flag == '1' && property.op.mplusphone.flag == '0'">
                  <td><b>e-mansion IPフォン</b></td>
                  <td v-if="customer.ipphone_flag">
                    <i class="material-icons link link-icon">check_circle_outline</i>お申し込みあり<br />
                    （TAお届け日:&nbsp;&nbsp;{{ deliveryDate }} {{ getDeliveryTimeZoneDisp(customer.delivery_time_zone ?? '') }}）
                  </td>
                  <td v-else>お申し込みなし</td>
                </tr>
                <tr v-if="property.op.ift.flag == '1'">
                  <td><b>i-フィルター(有害サイトブロック)</b></td>
                  <td v-if="customer.ifilter_flag"><i class="material-icons link link-icon">check_circle_outline</i>お申し込みあり</td>
                  <td v-else>お申し込みなし</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="sblc">
            <h3 class="service-h3">料金等について</h3>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th :rowspan="internetRowSpan">光ファイバーインターネットサービス</th>
                  <td class="campaign-info" colspan="2" v-if="property.cp_info_message && property.cp_info_message[0] && property.cp_info_message[0].campaign_entry_course">
                    {{ removeLineBreakTags(property.cp_info_message[0].campaign_entry_course) }}
                  </td>
                </tr>

                <tr v-if="property.cp_info_message && property.cp_info_message[0] && property.cp_info_message[0].campaign_entry_other">
                  <td class="campaign-info" colspan="2">{{ removeLineBreakTags(property.cp_info_message[0].campaign_entry_other) }}</td>
                </tr>
                <tr>
                  <td>月額基本料</td>
                  <td v-if="property.em_payir_flag == '0'">管理組合様にお問合せください。</td>
                  <td v-else-if="selectedCourse.charge == '0'">管理費とあわせてお支払いいただきます。なお、賃貸居住の方はオーナー様よりお支払いいただいております。</td>
                  <td v-else>
                    月額 {{ selectedCourse.charge }}円<span v-if="selectedCourse.charge_campaign"
                      >（キャンペーン適用時：<b class="red">{{ selectedCourse.charge_campaign }}円</b>）</span
                    >
                  </td>
                </tr>
                <tr v-if="property.em_fee_flag === '1'">
                  <td>事務手数料（初回のみ）</td>
                  <td>
                    {{ property.em_fee }}円<span v-if="property.em_fee_cp"
                      >（キャンペーン適用時：<b class="red">{{ property.em_fee_cp }}円</b>）</span
                    >
                  </td>
                </tr>
                <tr v-if="property.em_adm_construction_flag === '1'">
                  <td>工事費（初回のみ）</td>
                  <td>
                    {{ property.em_adm_construction }}円<span v-if="property.em_adm_construction_cp"
                      >（キャンペーン適用時：<b class="red">{{ property.em_adm_construction_cp }}円</b>）</span
                    >
                  </td>
                </tr>
                <tr v-if="isNeedAdapter()">
                  <td v-if="selectedCourse.type !== '06'">モデムレンタル料（月額）</td>
                  <td v-else>メディアコンバーターレンタル料（月額）</td>
                  <td v-if="selectedCourse.adapter_info?.equipmentcontract == '04'">0円</td>
                  <td v-else-if="selectedCourse.adapter_info?.charge_campaign" class="red">{{ selectedCourse.adapter_info?.charge_campaign }}円</td>
                  <td v-else>{{ selectedCourse.adapter_info?.charge }}円</td>
                </tr>

                <!-- ここからIPフォンの欄 -->
                <tr v-if="customer.ipphone_flag">
                  <th :rowspan="ipPhoneRowSpan">
                    e-mansion IPフォン<br />
                    <a target="_blank" class="link" :href="`${eMansionUrl}/${apid}/service/ipdenwa/e-ipphone.html`">(通話料は別途発生いたします。)</a>
                  </th>
                  <td class="campaign-info" colspan="2" v-if="property.cp_info_message && property.cp_info_message[0] && property.cp_info_message[0].campaign_ipphone">
                    {{ removeLineBreakTags(property.cp_info_message[0].campaign_ipphone) }}
                  </td>
                </tr>
                <tr v-if="customer.ipphone_flag && property.op.ipp.jimu_fee">
                  <td>事務手数料（初回のみ）</td>
                  <td>
                    {{ property.op.ipp.jimu_fee }}円
                    <span v-if="property.op.ipp.jimu_fee_cp">
                      （キャンペーン適用時：<b class="red">{{ property.op.ipp.jimu_fee_cp }}円</b>）</span
                    >
                  </td>
                </tr>
                <tr v-if="customer.ipphone_flag && property.op.ipp.basic_fee">
                  <td>基本料（月額）</td>
                  <td>
                    {{ property.op.ipp.basic_fee }}円
                    <span v-if="property.op.ipp.basic_fee_cp">
                      （キャンペーン適用時：<b class="red">{{ property.op.ipp.basic_fee_cp }}円</b>）</span
                    >
                  </td>
                </tr>
                <tr v-if="customer.ipphone_flag && property.op.ipp.rental_charge">
                  <td>テレフォニーアダプターレンタル料（月額）</td>
                  <td>
                    {{ property.op.ipp.rental_charge }}円
                    <span v-if="property.op.ipp.rental_charge_cp">
                      （キャンペーン適用時：<b class="red">{{ property.op.ipp.rental_charge_cp }}円</b>）</span
                    >
                  </td>
                </tr>
                <tr v-if="customer.ipphone_flag && property.op.ipp.uni_charge_tax_inc">
                  <td>ユニバーサルサービス料（月額）</td>
                  <td>
                    <p>
                      {{ property.op.ipp.uni_charge_tax_inc }}円
                      <span v-if="property.op.ipp.uni_charge_cp">
                        （キャンペーン適用時：<b class="red">{{ property.op.ipp.uni_charge_cp }}円</b>）</span
                      >
                    </p>
                    <p class="red">※ユニバーサルサービス制度にともない改定されることがあります。</p>
                  </td>
                </tr>
                <!-- ここまでIPフォンの欄 -->

                <!-- ここからi-フィルターの欄 -->
                <tr v-if="customer.ifilter_flag">
                  <th :rowspan="iFilterRowSpan"><b>i-フィルター</b></th>
                  <td class="campaign-info" colspan="2" v-if="property.cp_info_message && property.cp_info_message[0] && property.cp_info_message[0].campaign_i_filter">
                    {{ removeLineBreakTags(property.cp_info_message[0].campaign_i_filter) }}
                  </td>
                </tr>
                <tr v-if="customer.ifilter_flag && property.op.ift.fee">
                  <td>月額基本料</td>
                  <td>
                    {{ property.op.ift.fee }}円
                    <span v-if="property.op.ift.fee_cp">
                      （キャンペーン適用時：<b class="red">{{ property.op.ift.fee_cp }}円</b>）</span
                    >
                  </td>
                </tr>
                <!-- ここまでi-フィルターの欄 -->

                <tr v-if="isShowTotalCost()">
                  <th>総額</th>
                  <td colspan="2">
                    <p>初回:{{ dispTotalFirstMonthCost }}円　/　次回以降:{{ dispTotalMonthlyCost }}円</p>
                    <p class="red" v-if="isShowCampaignText()">※「次回以降」の料金は、キャンペーンが適用されない通常時における総額を表示しています。</p>
                    <p class="red" v-if="customer.ipphone_flag">※「e-mansion IPフォン」の通話料は別途発生いたします。</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>※価格は全て税込み表示 (消費税10%) です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
          </div>
          <div class="sblc" v-if="needCreditCard">
            <h3 class="service-h3">お支払い方法</h3>
            <!-- クレジットカード情報時に表示する -->
            <credit-card-component
              v-if="isMounted"
              :key="resetCounter"
              :reset-counter="resetCounter"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispName="ispName"
              v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
              v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-bind:cardHolderNameFromIsp="cardHolderNameFromIsp"
              v-on:onPostCreditCard="getCreditCardToken"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:getTokenExpireDate="getTokenExpireDate"
              v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
              v-on:change="isAgreedCopyCardToIsp = $event"
              v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
              v-on:portasCardHolderName="getCardHolderNameFromPortas"
              v-on:cardholderNameFormForAuthorizeIspCard="getCardholderNameFormForAuthorizeIspCard"
              v-on:cardholderNameFormForAuthorizePortasCard="getCardholderNameFormForAuthorizePortasCard"
            />
          </div>
          <div class="blc">
            <p v-if="needCreditCard" class="border-grey bg-grey pd20 mt40 ml40">
              ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
              ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
            </p>
          </div>
          <div class="sblc">
            <e-mansion-entry-send-documents-component
              :isEntryEMansion="true"
              :inputDeliveryData="inputDeliveryData"
              @error-messages="getErrorMessage"
              @input-data="getInputDeliveryData"
              v-if="isMounted && isContractContentIssueMethodFieldIsVisible"
            ></e-mansion-entry-send-documents-component>
          </div>

          <div class="sblc">
            <p class="form-btn-txt">
              ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」ボタンをクリックしてください。
            </p>
            <p class="red form-btn-txt">
              「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
            </p>
            <div class="btn-area">
              <div class="my-checkbox-outer" v-if="needCreditCard">
                <input id="checkbox-to-agree" type="checkbox" v-model="isAgreedPrivacyPolicy" :disabled="isExpiredPortasCreditCard" />
                <p class="my-checkbox-text"><label for="checkbox-to-agree">クレジットカード会社での本人認証のための情報提供に同意してお申し込みを確定する</label></p>
              </div>
              <div class="has-checkbox">
                <button class="btn-height btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
                <button class="btn-height mt15 btn btn01 bs" type="button" :disabled="isDisabledButton" v-on:click="onNext()">
                  お申し込みを確定する<i class="material-icons link link-icon">east</i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /blc -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.btn-height {
  height: 50px;
}
.table-type2 {
  border-collapse: separate;
  border-spacing: 0;
}
.table-type2 td,
.table-type2 th {
  border-bottom: 1px solid #d3d3d3;
}
@media only screen and (max-width: 767px) {
  .table-type2 tr td:first-child,
  .table-type2 tr th:first-child {
    /* 最初の <td> にのみ適用されるスタイル */
    border-bottom: none;
  }
}
.fix-btn {
  float: right;
  color: #939393;
  border: 1px solid #939393;
  margin-top: -40px;
}
.link {
  font-weight: normal;
}
.sbtn {
  min-width: 80px;
  line-height: 26px;
  text-align: center;
  font-size: 13px;
  border-radius: 2px;
  padding: 0 10px;
  background-color: #ffffff;
}

.sbtn:hover {
  opacity: 0.7;
}

.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}
.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}
.campaign-info {
  white-space: pre-wrap;
}
div.btn-area {
  position: relative;
  text-align: center;
}
/* お申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
div.my-checkbox-outer {
  display: flex;
  justify-content: center;
}
div.my-checkbox-outer > input {
  margin-right: 10px;
}
.has-checkbox {
  margin-top: 20px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
/** クレジットカード用コンポーネント */
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { Course, EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { LinkServiceWithEMansionRequest } from '@/shared/classes/spf-api/link-service-with-e-mansion';
import { Member } from '@/shared/classes/spf-api/member';
import EMansionEntrySendDocumentsComponent from '@/shared/components/e-mansion/e-mansion-entry-send-documents-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import { E_MANSION_MAIN_ACCOUNT_PLAN_TYPE } from '@/shared/const/e-mansion/e-mansion-main-account-plan-type';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import useVuelidate from '@vuelidate/core';
import { v4 as uuidv4 } from 'uuid';
import { EMansionEntryInputForm } from './classes/entry-input-form';
interface MyCourse {
  course_id: string;
  type: string | null;
  speed: string;
  charge: string;
  charge_campaign: string;
  ins_kind: string;
  adapter_info: {
    equipmentcontract: string;
    charge: string;
    charge_campaign: string;
  };
}

interface MyAdapter {
  equipmentcontract: string;
  charge: string;
  charge_campaign: string;
}

/** Confirm コンポーネント */
export default defineComponent({
  name: 'confirm',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
    LoadingComponent,
    EMansionEntrySendDocumentsComponent,
  },
  data: () => ({
    customer: {} as EMansionEntryInputForm,
    property: {} as EMansionProperty,
    // IPフォンテレフォニーアダプターはヤマト運輸の着荷指定(時間帯)に従った選択肢となっている
    // NOTE: https://faq.kuronekoyamato.co.jp/app/answers/detail/a_id/1249/~/%E9%85%8D%E9%81%94%E3%81%AE%E6%99%82%E9%96%93%E5%B8%AF%E3%81%A8%E3%80%81%E5%8F%97%E4%BB%98%E7%B7%A0%E5%88%87%E6%99%82%E9%96%93%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82%E6%9B%B4%E3%81%AB%E7%B4%B0%E3%81%8B%E3%81%AA%E6%99%82%E9%96%93%E3%81%AE%E6%8C%87%E5%AE%9A%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F
    yamatoDeliveryTimeZoneList: [
      { name: '午前中', value: '0', key: '0' },
      { name: '14:00 ～ 16:00', value: '2', key: '2' },
      { name: '16:00 ～ 18:00', value: '3', key: '3' },
      { name: '18:00 ～ 20:00', value: '4', key: '4' },
      { name: '19:00 ～ 21:00', value: '5', key: '5' },
    ],
    /** 生年月日（表示用） */
    dispBirthday: '',
    totalInitialCost: 0,
    totalMonthlyCost: 0,
    dispTotalFirstMonthCost: '0',
    dispTotalMonthlyCost: '0',
    inputDeliveryData: {
      selectedRadio: '',
      inputMailAddress: '',
      inputMailAddressConfirm: '',
      deliveryZipcodeFormer: '',
      deliveryZipcodeLatter: '',
      destinationAddress1: '',
      destinationAddress2: '',
    },
    selectedCourse: {} as MyCourse,
    selectedAdapter: {} as MyAdapter,
    deliveryDate: '',
    //月額基本料が必ず表示ありなのでIPフォンとi-フィルターより初期値が1大きい
    internetRowSpan: 2,
    ipPhoneRowSpan: 1,
    iFilterRowSpan: 1,
    dispScheduledMoveInOn: '',
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    // クレジットカード情報必要可否フラグ
    needCreditCard: false,
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** APIトークンキー */
    apiTokenKey: '',
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「次へ」をクリックしてください。',
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** Portasから取得したカード名義人 */
    cardHolderNameOnPortas: '' as string | undefined,
    /** ISPから取得したカード名義人 */
    cardHolderNameFromIsp: '' as string | undefined,
    /** ISPで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizeIspCard: '' as string | undefined,
    /** Portasで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizePortasCard: '' as string | undefined,
    /** 本人認証処理を実行するため、ブラウザのトラッキング防止機能を無効化する必要があるかどうか */
    isNeedDisableTrackingBlockFunction: true,
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    /** ユーザーがe-mansionへカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    resetCounter: 0,
    /** e-mansionから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** e-mansionから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面ではe-mansion固定) */
    ispName: 'e-mansion',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ボタン押下判定 */
    isLoading: true,
    /** 西暦の入力欄を組み立てるための現在年 */
    currentYear: 0,
    /** 電子交付を希望（登録いただいたメールアドレス「」へ送信 に チェックを付ける  */
    checkedElectronicdelivery: false,
    /** 「ご契約内容の交付方法の選択」の表示 */
    isContractContentIssueMethodFieldIsVisible: true,
    isSlimPlan: false,
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    isMounted: false,
  }),
  setup: () => ({ v$: useVuelidate() }),
  async mounted() {
    // ログイン中のお客様情報が表示される
    try {
      // 会員情報取得処理
      const customer: EMansionEntryInputForm = this.$store.getters['eMansionEntryStore/entryInputForm'];
      this.customer = customer;
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      this.property = property;
      // customer情報が無い場合、総合トップ
      if (!(await MountedCheckService.canReadEMansionEntryScreen()) || !this.customer || !this.property) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.isSlimPlan = this.checkSlimPlan(this.property);

      // 表示用生年月日取得
      this.dispBirthday = this.getDispDate(this.customer.birthday);
      // 表示用移転予定日取得
      if (this.customer.scheduled_move_in_on) {
        this.dispScheduledMoveInOn = this.getDispDate(this.customer.scheduled_move_in_on);
      }

      // 選択コースの設定
      this.selectedCourse = this.getSelectedCourse();

      // TAお届け日の設定
      this.deliveryDate = this.getDeliveryDate();
      //料金の表の表示設定
      this.calcRowSpan();
      // 総額の設定

      this.totalInitialCost = this.getTotalInitialCost() + this.getTotalMonthlyCostWithCampaign();
      this.totalMonthlyCost = this.getTotalMonthlyCost();
      // 表示用総額の設定
      this.dispTotalFirstMonthCost = new Intl.NumberFormat('ja').format(this.totalInitialCost);
      this.dispTotalMonthlyCost = new Intl.NumberFormat('ja').format(this.totalMonthlyCost);
      //ご契約内容のご案内を表示するか
      this.isContractContentIssueMethodFieldIsVisible = this.isContractContentIssueMethodFieldIsVisibleFunc();
      //スリムプラン以外の場合、契約内容の交付方法に表示する住所は書類のご希望送付先と同じ
      if (!this.isSlimPlan) {
        this.inputDeliveryData.deliveryZipcodeFormer = this.customer.destination_zipcode ? this.customer.destination_zipcode.split('-')[0] : '';
        this.inputDeliveryData.deliveryZipcodeLatter = this.customer.destination_zipcode ? this.customer.destination_zipcode.split('-')[1] : '';
        this.inputDeliveryData.destinationAddress1 = this.customer.destination_address1 || '';
        this.inputDeliveryData.destinationAddress2 = this.customer.destination_address2 || '';
      }
      //エラー等でこの画面に戻ってきたときに入力内容を再表示する
      if (this.isContractContentIssueMethodFieldIsVisible && this.customer.contract_destination_kind) {
        this.inputDeliveryData.selectedRadio = this.createSelectedRadio(this.customer.contract_destination_kind);
        if (this.inputDeliveryData.selectedRadio == '2') {
          this.inputDeliveryData.inputMailAddress = this.customer.contract_destination_mailaddress ? this.customer.contract_destination_mailaddress : '';
          this.inputDeliveryData.inputMailAddressConfirm = this.customer.contract_destination_mailaddress_confirm ? this.customer.contract_destination_mailaddress_confirm : '';
        }
        if (this.inputDeliveryData.selectedRadio == '3' && this.isSlimPlan) {
          //スリムプランの場合は住所に入力値がある
          this.inputDeliveryData.deliveryZipcodeFormer = this.customer.contract_destination_zipcode_former ? this.customer.contract_destination_zipcode_former : '';
          this.inputDeliveryData.deliveryZipcodeLatter = this.customer.contract_destination_zipcode_latter ? this.customer.contract_destination_zipcode_latter : '';
          this.inputDeliveryData.destinationAddress1 = this.customer.contract_destination_address1 ? this.customer.contract_destination_address1 : '';
          this.inputDeliveryData.destinationAddress2 = this.customer.contract_destination_address2 ? this.customer.contract_destination_address2 : '';
        }
      }
      // クレジットカード情報の必要有無を設定
      this.needCreditCard = this.getNeedCreditCard();
      // APIトークンキーを設定
      this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION;
      const member: Member = this.$store.getters['memberStore/member'];

      if (member.veritransAccountId) {
        this.portasVeriTransId = member.veritransAccountId;
      }
      // computed() では西暦が正しく取得できない・mounted() で処理する必要あり
      this.currentYear = new Date().getFullYear();

      // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];

        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      await VueErrorHandler.handle(e, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
    this.isMounted = true;
    this.isLoading = false;
  },
  methods: {
    getDeliveryDate() {
      if (!this.customer?.ipphone_flag) {
        return '';
      }
      return `${this.customer?.delivery_year}年${this.customer?.delivery_month}月${this.customer?.delivery_day}日`;
    },
    /**
     * 接続機器が必要→true
     */
    isNeedAdapter() {
      return !(this.selectedCourse.type == '05' || (this.selectedCourse.type == '06' && this.selectedCourse.ins_kind == '01'));
    },
    /**初回のみの料金 */
    getTotalInitialCost() {
      let totalInitialCost = 0;
      // 接続コース

      // 事務手数料
      if (this.property?.em_fee_flag === '1') {
        const em_fee: string = this.property?.em_fee_cp ? this.property?.em_fee_cp : this.property?.em_fee;
        totalInitialCost += Number(em_fee.replace(',', ''));
      }
      // 工事費
      if (this.property?.em_adm_construction_flag === '1') {
        const em_adm_construction: string = this.property?.em_adm_construction_cp ? this.property?.em_adm_construction_cp : this.property?.em_adm_construction;
        totalInitialCost += Number(em_adm_construction.replace(',', ''));
      }
      // IPフォン
      if (this.customer?.ipphone_flag) {
        // 事務手数料
        const ipp_jimu_fee: string = this.property?.op.ipp.jimu_fee_cp ? this.property?.op.ipp.jimu_fee_cp : this.property?.op.ipp.jimu_fee;
        totalInitialCost += Number(ipp_jimu_fee.replace(',', ''));
      }

      if (Number.isNaN(totalInitialCost)) {
        return 0;
      }

      return totalInitialCost;
    },
    /**キャンペーン価格の月額料金 */
    getTotalMonthlyCostWithCampaign() {
      let totalMonthlyCost = 0;

      // 接続コース
      if (this.selectedCourse.course_id) {
        if (this.property.em_payir_flag !== '0') {
          const charge: string = this.selectedCourse.charge_campaign ? this.selectedCourse.charge_campaign : this.selectedCourse.charge;
          totalMonthlyCost += Number(charge.replace(',', ''));
        }
      }
      // 接続機器（レンタル）
      if (this.isNeedAdapter()) {
        const adapter_charge: string = this.selectedCourse.adapter_info.charge_campaign
          ? this.selectedCourse.adapter_info.charge_campaign
          : this.selectedCourse.adapter_info.charge;
        totalMonthlyCost += Number(adapter_charge.replace(',', ''));
      }
      // IPフォン
      if (this.customer?.ipphone_flag) {
        // 基本料金
        const ipp_basic_fee: string = this.property?.op.ipp.basic_fee_cp ? this.property?.op.ipp.basic_fee_cp : this.property?.op.ipp.basic_fee;
        totalMonthlyCost += Number(ipp_basic_fee.replace(',', ''));
        // TAレンタル料
        const ipp_rental_charge: string = this.property?.op.ipp.rental_charge_cp ? this.property?.op.ipp.rental_charge_cp : this.property?.op.ipp.rental_charge;
        totalMonthlyCost += Number(ipp_rental_charge.replace(',', ''));
        // ユニバーサルサービス料
        const ipp_uni_charge: string = this.property?.op.ipp.uni_charge_cp ? this.property?.op.ipp.uni_charge_cp : this.property?.op.ipp.uni_charge_tax_inc;
        totalMonthlyCost += parseInt(ipp_uni_charge.replace(',', ''));
      }
      // i-フィルター
      if (this.customer?.ifilter_flag) {
        const ift_fee: string = this.property?.op.ift.fee_cp ? this.property?.op.ift.fee_cp : this.property?.op.ift.fee;
        totalMonthlyCost += Number(ift_fee.replace(',', ''));
      }
      if (Number.isNaN(totalMonthlyCost)) {
        return 0;
      }

      return totalMonthlyCost;
    },
    /**モデム・MC以外通常価格での月額料金 */
    getTotalMonthlyCost() {
      let totalMonthlyCost = 0;

      // 接続コース
      if (this.selectedCourse.course_id) {
        if (this.property.em_payir_flag !== '0') {
          const charge: string = this.selectedCourse.charge;
          totalMonthlyCost += Number(charge.replace(',', ''));
        }
      }
      // 接続機器（レンタル）
      if (this.isNeedAdapter()) {
        const adapter_charge: string = this.selectedCourse.adapter_info.charge_campaign
          ? this.selectedCourse.adapter_info.charge_campaign
          : this.selectedCourse.adapter_info.charge;
        totalMonthlyCost += Number(adapter_charge.replace(',', ''));
      }

      // IPフォン
      if (this.customer?.ipphone_flag) {
        // 基本料金
        const ipp_basic_fee: string = this.property?.op.ipp.basic_fee;
        totalMonthlyCost += Number(ipp_basic_fee.replace(',', ''));
        // TAレンタル料
        const ipp_rental_charge: string = this.property?.op.ipp.rental_charge;
        totalMonthlyCost += Number(ipp_rental_charge.replace(',', ''));
        // ユニバーサルサービス料
        const ipp_uni_charge: string = this.property?.op.ipp.uni_charge_tax_inc;
        totalMonthlyCost += parseInt(ipp_uni_charge.replace(',', ''));
      }

      // i-フィルター
      if (this.customer?.ifilter_flag) {
        const ift_fee: string = this.property?.op.ift.fee;
        totalMonthlyCost += Number(ift_fee.replace(',', ''));
      }

      if (Number.isNaN(totalMonthlyCost)) {
        return 0;
      }

      return totalMonthlyCost;
    },
    /**モデム・MC以外でキャンペーン有の場合、下記文言を表示 */
    isShowCampaignText() {
      if (this.selectedCourse.course_id) {
        if (this.selectedCourse.charge_campaign) {
          return true;
        }
        if (this.property.em_fee_cp) {
          return true;
        }
        if (this.property.em_adm_construction_cp) {
          return true;
        }
      }
      //IPフォン
      if (this.customer.ipphone_flag) {
        if (this.property.op.ipp.jimu_fee_cp) {
          return true;
        }
        if (this.property.op.ipp.basic_fee_cp) {
          return true;
        }
        if (this.property.op.ipp.rental_charge_cp) {
          return true;
        }
        if (this.property.op.ipp.uni_charge_cp) {
          return true;
        }
      }
      //i-フィルター
      if (this.customer.ifilter_flag) {
        if (this.property.op.ift.fee_cp) {
          return true;
        }
      }
      return false;
    },
    isShowTotalCost() {
      return Number(this.dispTotalFirstMonthCost.replace(',', '')) > 0 || Number(this.dispTotalMonthlyCost.replace(',', '')) > 0;
    },
    calcRowSpan() {
      //接続コース
      if (this.property.cp_info_message && this.property.cp_info_message[0] && this.property.cp_info_message[0].campaign_entry_other) {
        ++this.internetRowSpan;
      }
      if (this.property.em_fee_flag === '1') {
        ++this.internetRowSpan;
      }
      if (this.property.em_adm_construction_flag === '1') {
        ++this.internetRowSpan;
      }
      if (this.isNeedAdapter()) {
        ++this.internetRowSpan;
      }
      //IPフォン
      if (this.property.op.ipp.jimu_fee) {
        ++this.ipPhoneRowSpan;
      }
      if (this.property.op.ipp.basic_fee) {
        ++this.ipPhoneRowSpan;
      }
      if (this.property.op.ipp.uni_charge_tax_inc) {
        ++this.ipPhoneRowSpan;
      }
      if (this.property.op.ipp.rental_charge) {
        ++this.ipPhoneRowSpan;
      }
      //i-フィルター
      if (this.property.op.ift.fee) {
        ++this.iFilterRowSpan;
      }
    },
    getSelectedCourse(): MyCourse {
      if (this.property?.cs !== null && this.customer?.course_id !== null) {
        const course = this.property.cs.find((cs: Course) => {
          return cs.course_id === this.customer?.course_id;
        });
        if (course) {
          if (course.adapter_info.length == 1) {
            return {
              course_id: course.course_id,
              type: course.type ? course.type : '',
              ins_kind: course.ins_kind,
              speed: course.speed,
              charge: course.charge,
              charge_campaign: course.charge_campaign,
              adapter_info: course.adapter_info[0],
            };
          }
        }
      }
      // 最低限必要な項目のみ空設定で返す（これがないとエラーになるため）
      return {
        course_id: '',
        type: '',
        ins_kind: '',
        speed: '',
        charge: '',
        charge_campaign: '',
        adapter_info: { equipmentcontract: '', charge: '', charge_campaign: '' },
      };
    },
    /**支払方法の欄を表示するか */
    getNeedCreditCard() {
      if (this.customer.ipphone_flag) {
        return true;
      }
      if (this.totalInitialCost > 0 || this.totalMonthlyCost > 0) {
        return true;
      }
      return false;
    },
    getInputDeliveryData(data: any) {
      this.inputDeliveryData = data;
    },
    getErrorMessage(data: any) {
      this.errorMessages = [];
      this.errorMessages.push(data);
      window.scrollTo(0, 0);
      return;
    },
    getDeliveryTimeZoneDisp(key: string): string | undefined {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.yamatoDeliveryTimeZoneList.find((timeZone: any) => {
        return timeZone.key === key;
      })?.name;
    },
    /**日付をリクエストの形式(yyyy/mm/dd)に変換 */
    getScheduledMoveInOn(date: string): string {
      return date.split('-')[0] + '/' + date.split('-')[1] + '/' + date.split('-')[2];
    },

    getContractDestinationZipCode(): string | undefined {
      if (this.inputDeliveryData.deliveryZipcodeFormer && this.inputDeliveryData.deliveryZipcodeLatter) {
        return this.inputDeliveryData.deliveryZipcodeFormer + this.inputDeliveryData.deliveryZipcodeLatter;
      }
      return undefined;
    },
    getRequest(): LinkServiceWithEMansionRequest {
      const request = new LinkServiceWithEMansionRequest({
        uaType: this.customer.ua_type,
        propertyId: convert6DigitIdTo8DigitId(String(this.customer.property_id)),
        buildingId: this.customer.building_id ? String(this.customer.building_id) : '0000',
        roomNumber: this.customer.room_number,
        nameSurname: this.customer.name_surname,
        nameFirstName: this.customer.name_first_name,
        kanaSurname: this.customer.kana_surname,
        kanaFirstName: this.customer.kana_first_name,
        birthday: this.customer.birthday,
        phoneNumber: this.customer.phone_number ?? undefined,
        emailAddress: this.customer.email_address,
        scheduledMoveInOn: this.customer.scheduled_move_in_on ? this.getScheduledMoveInOn(this.customer.scheduled_move_in_on) : undefined,
        creditCardRegistFlag: this.getNeedCreditCard() ? '1' : '0',
        eTncMemberId: '', //本人認証実施後に発行
        courseId: this.customer.course_id,
        destinationKind: this.isSlimPlan ? '' : this.customer.destination_kind,
        destinationZipcode: this.customer.destination_kind === '9' ? this.customer.destination_zipcode?.replace('-', '') : '',
        destinationAddress1: this.customer.destination_kind === '9' ? this.customer.destination_address1! : '',
        destinationAddress2: this.customer.destination_kind === '9' ? this.customer.destination_address2! : '',
        contractDestinationKind: this.createDestinationRequest(this.inputDeliveryData.selectedRadio),
        contractDestinationMailaddress:
          this.isContractContentIssueMethodFieldIsVisible && this.createDestinationRequest(this.inputDeliveryData.selectedRadio) === '4'
            ? this.inputDeliveryData.inputMailAddress
            : '',
        contractDestinationZipcode:
          this.isContractContentIssueMethodFieldIsVisible && this.createDestinationRequest(this.inputDeliveryData.selectedRadio) === '1' && this.isSlimPlan
            ? this.getContractDestinationZipCode()
            : '',
        contractDestinationAddress1:
          this.isContractContentIssueMethodFieldIsVisible && this.createDestinationRequest(this.inputDeliveryData.selectedRadio) === '1' && this.isSlimPlan
            ? this.inputDeliveryData.destinationAddress1
            : '',
        contractDestinationAddress2:
          this.isContractContentIssueMethodFieldIsVisible && this.createDestinationRequest(this.inputDeliveryData.selectedRadio) === '1' && this.isSlimPlan
            ? this.inputDeliveryData.destinationAddress2
            : '',
        ifilterFlag: this.customer.ifilter_flag ? '1' : '0',
        ipphoneFlag: this.customer.ipphone_flag ? '1' : '0',
        ipphone: {
          deliveryTime: this.customer.delivery_date ?? undefined,
          deliveryTimeZone: this.customer.delivery_time_zone ?? undefined,
        },
      });
      return request;
    },
    checkSlimPlan(property: EMansionProperty): boolean {
      const flags = property.apartment_special_type_flag[0];
      if (flags[E_MANSION_MAIN_ACCOUNT_PLAN_TYPE.SLIM_PLAN] === '1') {
        return true;
      }
      return false;
    },
    /**接続方式を変換 */
    convertType(type: string | null) {
      if (type === '01' || type === '02' || type === '03' || type === '04') {
        return '（VDSL）';
      }
      if (type === '05') {
        return '（LAN）';
      }
      if (type === '06') {
        return '（FTTH）';
      }
      return '';
    },
    /**ご契約内容の交付方法の選択を表示するか */
    isContractContentIssueMethodFieldIsVisibleFunc() {
      if (this.property.em_actype != '1') {
        return true;
      }
      if (this.customer.ipphone_flag) {
        return true;
      }
      return false;
    },

    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isLoading = false;
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** 3Dセキュア本人認証用カード名義人を受け取る */
    getCardHolderNameFromPortas(cardHolderName: string) {
      this.cardHolderNameOnPortas = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、ISP登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizeIspCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizeIspCard = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、Portas登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizePortasCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizePortasCard = cardHolderName;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    removeLineBreakTags(text: string) {
      if (!text) return '';
      return text.replace(/<br \/?>/g, '');
    },
    /**
     * ストアに保存するための値、this.customerに情報をセットしてストアに保存する
     */
    setCustomerStore() {
      //契約内容のご案内
      this.customer.contract_destination_kind = this.createDestinationRequest(this.inputDeliveryData.selectedRadio);
      this.customer.contract_destination_zipcode = this.getContractDestinationZipCode();
      this.customer.contract_destination_zipcode_former = this.inputDeliveryData.deliveryZipcodeFormer;
      this.customer.contract_destination_zipcode_latter = this.inputDeliveryData.deliveryZipcodeLatter;
      this.customer.contract_destination_address1 = this.inputDeliveryData.destinationAddress1;
      this.customer.contract_destination_address2 = this.inputDeliveryData.destinationAddress2;
      this.customer.contract_destination_mailaddress = this.inputDeliveryData.inputMailAddress;
      this.customer.contract_destination_mailaddress_confirm = this.inputDeliveryData.inputMailAddressConfirm;

      //接続コース情報
      this.customer.course_info = {
        bandspeed_name: this.selectedCourse.speed,
        access_network_name: this.selectedCourse.type ?? undefined,
        ins_kind: this.selectedCourse.ins_kind,
        price: this.selectedCourse.charge,
        jimu_fee: this.property.em_fee,
        construction_fee: this.property.em_adm_construction,
        rental_fee: this.selectedCourse.adapter_info?.charge,
        price_cp: this.selectedCourse.charge_campaign,
        jimu_fee_cp: this.property.em_fee_cp,
        construction_fee_cp: this.property.em_adm_construction_cp,
        rental_fee_cp: this.selectedCourse.adapter_info?.charge_campaign,
      };
      //IPフォン情報
      this.customer.ipphone_info = {
        ipphone_fee_price: this.property.op.ipp.jimu_fee,
        ipphone_monthly_price: this.property.op.ipp.basic_fee,
        ipphone_tarental_price: this.property.op.ipp.rental_charge,
        ipphone_universalservice_price: this.property.op.ipp.uni_charge_tax_inc,
        ipphone_fee_price_cp: this.property.op.ipp.jimu_fee_cp,
        ipphone_monthly_price_cp: this.property.op.ipp.basic_fee_cp,
        ipphone_tarental_price_cp: this.property.op.ipp.rental_charge_cp,
        ipphone_universalservice_price_cp: this.property.op.ipp.uni_charge_cp,
      };
      //i-フィルター情報
      this.customer.ifilter_info = {
        price: this.property.op.ift.fee,
        price_cp: this.property.op.ift.fee_cp,
      };
      this.$store.commit('eMansionEntryStore/entryInputForm', this.customer);
    },
    /** 次へボタン押下時： 完了画面に遷移する */
    async onNext() {
      // ボタン押下中は何もしない
      if (this.isLoading) {
        return;
      }

      // 途中でエラーが生じるとスピナーが止まらないため、try-catch でくくる
      try {
        // ボタン押下中扱いとする
        this.isLoading = true;
        // エラーメッセージ格納配列初期化
        this.errorMessages = [];

        if (this.needCreditCard && !this.isAgreedCopyCardToIsp && this.creditCardAccessToken === '') {
          // クレジットカードが登録されておらず、かつAPIキーが発行されていない場合はエラー
          this.errorMessages.push(`カード番号、有効期限を正しく入力してください。`);
        }

        // 電子交付を希望（ご指定のメールアドレスへ送信）の場合
        const regexpEMail = /^[\w\.!"#$%&'\(\)\*\+-\.\/:;<=>\?\[\]\^_`\{\|\}~]+@[^@\s,\\]+$/i;
        if (this.inputDeliveryData.selectedRadio === '2') {
          // メールアドレス入力チェック
          if (this.inputDeliveryData.inputMailAddress === '' || this.inputDeliveryData.inputMailAddressConfirm === '') {
            this.errorMessages.push(`「「ご契約内容の交付方法」ご希望メールアドレス」を半角英数字・記号で入力してください。`);
          }
          // メールアドレス形式チェック
          else if (!regexpEMail.test(this.inputDeliveryData.inputMailAddress) || !regexpEMail.test(this.inputDeliveryData.inputMailAddressConfirm)) {
            this.errorMessages.push(`「「ご契約内容の交付方法」ご希望メールアドレス」を半角英数字・記号で正しく入力してください。`);
          } else if (this.inputDeliveryData.inputMailAddress.length > 48 || this.inputDeliveryData.inputMailAddressConfirm.length > 48) {
            this.errorMessages.push(`「「ご契約内容の交付方法」ご希望メールアドレス」を半角英数字・記号48文字以内で入力してください。`);
          }
          // メールアドレスWチェック
          else if (this.inputDeliveryData.inputMailAddress !== this.inputDeliveryData.inputMailAddressConfirm) {
            this.errorMessages.push(`「「ご契約内容の交付方法」ご希望メールアドレス」の入力と、確認用に入力された内容が一致しません。もう一度新たに入力しなおしてください。`);
          }
        }
        const regexZipCode = /^[0-9]{7}$/i;
        const zipcode = this.getContractDestinationZipCode();
        // 電子交付を希望しない場合
        if (this.inputDeliveryData.selectedRadio === '3') {
          if (this.inputDeliveryData.deliveryZipcodeFormer === '' || this.inputDeliveryData.deliveryZipcodeLatter === '' || !zipcode) {
            // 必須チェック
            this.errorMessages.push(`「「ご契約内容の交付方法」郵便番号」を半角数字で入力してください。`);
          } else if (!regexZipCode.test(zipcode)) {
            // 形式チェック
            this.errorMessages.push(`「「ご契約内容の交付方法」郵便番号」は半角数字7文字で入力してください。`);
          }

          if (this.inputDeliveryData.destinationAddress1 === '') {
            // 必須チェック
            this.errorMessages.push(`「「ご契約内容の交付方法」ご住所(1)」を全角で入力してください。`);
          } else if (this.inputDeliveryData.destinationAddress1.length > 30) {
            // 形式チェック
            this.errorMessages.push(`「「ご契約内容の交付方法」ご住所(1)」を全角30文字以内で入力してください。`);
          } else if (this.inputDeliveryData.destinationAddress2.length > 60) {
            this.errorMessages.push(`「「ご契約内容の交付方法」ご住所(2)」を全角60文字以内で入力してください。`);
          }
        }

        if (this.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          this.showErrorMessage();
          return;
        }

        const request = this.getRequest();
        //次の画面に渡すための情報
        this.setCustomerStore();
        // 登録処理
        if (this.needCreditCard) {
          //支払方法登録あり→本人認証を実施
          if (this.isAgreedCopyCardToIsp && this.portasVeriTransId) {
            //Portasからカードをコピーする場合
            const uuidForTemporarySavingApplicationData = uuidv4();
            const eMansionEntryApplicationDataJson = JSON.stringify({
              request: request,
              customer: this.customer,
              veriTransAccountId: this.portasVeriTransId,
              isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
              beforePath: this.$store.getters['eMansionEntryStore/beforePathConfirm'],
              member: await this.$store.getters['memberStore/member'],
              property: await this.$store.getters['propertyStore/property'],
              eMansionproperty: this.property,
            });
            const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
              uuid: uuidForTemporarySavingApplicationData,
              applicationDataJson: eMansionEntryApplicationDataJson,
              subsequentProcess: 'e-mansion-entry',
            });
            // 申込内容一時保存を実行するAPI
            try {
              await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
              // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
            }

            // 3dセキュア対応用 uuid localStorageに保存
            localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
            let cardHolderNameForAuthorize: string;
            if (this.cardHolderNameOnPortas) {
              cardHolderNameForAuthorize = this.cardHolderNameOnPortas;
            } else {
              cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
            }
            const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
              uuid: uuidForTemporarySavingApplicationData,
              externalVeritransAccountId: this.portasVeriTransId,
              cardholderName: cardHolderNameForAuthorize,
            });
            try {
              const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
              location.href = authStartURL;
            } catch (error: any) {
              if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                this.errorMessages.push(
                  `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
                );
                this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

                // コンポーネントの破棄・再描画のために key値 を変更する
                this.addResetCounter();
                this.isAgreedPrivacyPolicy = false;

                this.showErrorMessage();
                return;
              } else {
                throw error;
              }
            }
          } else {
            //カードを新規登録する場合
            const uuidForTemporarySavingApplicationData = uuidv4();
            const eMansionEntryApplicationDataJson = JSON.stringify({
              request: request,
              customer: this.customer,
              creditCardToken: this.creditCardAccessToken,
              tokenExpireDate: this.creditTokenExpireDate,
              isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
              beforePath: this.$store.getters['eMansionEntryStore/beforePathConfirm'],
              member: await this.$store.getters['memberStore/member'],
              property: await this.$store.getters['propertyStore/property'],
              eMansionproperty: this.property,
            });
            const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
              uuid: uuidForTemporarySavingApplicationData,
              applicationDataJson: eMansionEntryApplicationDataJson,
              subsequentProcess: 'e-mansion-entry',
            });
            try {
              await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
              // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
            }
            // 3dセキュア対応用 uuid localStorageに保存
            localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

            // ページ遷移時に beforeEach で会員情報取得できるように null にする
            this.$store.commit('memberStore/member', null);
            const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
              uuid: uuidForTemporarySavingApplicationData,
              creditCardToken: this.cardTokenFor3dSecureAuthorize,
              serviceProviderIsp: SERVICE_PROVIDER.MYE,
            });
            try {
              const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
              location.href = authStartURL;
            } catch (error: any) {
              if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                this.errorMessages.push(
                  `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
                );
                this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

                // コンポーネントの破棄・再描画のために key値 を変更する
                this.addResetCounter();
                this.isAgreedPrivacyPolicy = false;
                this.showErrorMessage();
                return;
              } else {
                throw error;
              }
            }
          }
        } else {
          //支払方法登録なし→この画面で入会を実施
          const member = this.$store.getters['memberStore/member'];
          try {
            //ID発番API実行
            const eTncMemberId = await SpfApiService.createMyeId(this.property.apartment_id, UA_TYPE.E_MANSION, '1');
            //入会申し込みAPI実行
            request.eTncMemberId = eTncMemberId;
            const resultEntry = await SpfApiService.linkServiceWithEMansion(member.id, request);

            if (resultEntry.status === 'success') {
              // 画面遷移
              await AuthService.refresh();

              // 完了ページに渡す新規会員登録APIのレスポンスを詰める処理
              this.$store.commit('eMansionEntryStore/entryInputForm', this.customer);
              this.$store.commit('eMansionEntryStore/result', resultEntry);
              //画面表示更新のため会員情報を取り直す
              await AuthService.refresh();
              await this.$store.dispatch('memberStore/member');
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              await this.$router.push('/e-mansion/entry/completed').catch((error: any) => {
                checkRouterError(error);
              });
            } else {
              // エラーコードがAPIN001の場合エラーメッセージ表示
              if (resultEntry.code === 'APIN001') {
                this.errorMessages.push('e-mansionご入会のお申し込みは受け付けております。<br><a href="/platform" class="link">Portasトップページへ</a>');
                this.showErrorMessage();
                return;
              } else {
                await VueErrorHandler.handle(new Error(`status:${resultEntry.status}  message:${resultEntry.message}`), '');
                await this.$router.push('/e-mansion/error').catch((error) => {
                  checkRouterError(error);
                });
              }
              return;
            }
          } catch (error: any) {
            await VueErrorHandler.handle(error, '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
          }
        }
      } catch (error: any) {
        // 取得エラーでエラー画面に遷移
        await VueErrorHandler.handle(error, '');
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
    },
    async onBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.setCustomerStore();
      const beforePath = this.$store.getters['eMansionEntryStore/beforePathConfirm'];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return await this.$router.push(beforePath).catch((error: any) => {
        checkRouterError(error);
      });
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    async getCreditCardToken(token: string) {
      try {
        // エラーメッセージ格納配列初期化
        this.errorMessages = [];
        this.creditCardAccessToken = token;
      } catch (error: any) {
        await VueErrorHandler.handle(error, '');
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    async getTokenExpireDate(creditTokenExpireDate: string) {
      try {
        this.creditTokenExpireDate = creditTokenExpireDate;
      } catch (error: any) {
        await VueErrorHandler.handle(error, '');
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
    },
    /** クレジットカードエラーが発生していた場合 */
    async executeCreditCardError(errorMessage: string) {
      try {
        this.errorMessages = [errorMessage];
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
      } catch (error: any) {
        await VueErrorHandler.handle(error, '');
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
    },

    /** 表示用日付を取得する */
    getDispDate(dateStr: string) {
      if (dateStr) {
        const date = new Date(dateStr);
        return date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日';
      }
      return '';
    },
    /**契約内容の交付方法の種別をリクエスト用の値へ変換する
     * 1:郵送 3:電子交付（登録メールアドレス） 4:電子交付（指定）
     */
    createDestinationRequest(selectedRadio: string) {
      if (!this.isContractContentIssueMethodFieldIsVisible) {
        return '';
      }
      switch (selectedRadio) {
        case '2':
          return '4';
        case '3':
          return '1';
        case '1':
          return '3';
        default:
          return '';
      }
    },
    /**契約内容の交付方法の種別を画面表示用の値へ変換する
     * 1:電子交付（登録メールアドレス）2:電子交付（指定）3:郵送
     */
    createSelectedRadio(destinationKind: string) {
      if (!destinationKind) {
        return '';
      }
      switch (destinationKind) {
        case '4':
          return '2';
        case '1':
          return '3';
        case '3':
          return '1';
        default:
          return '';
      }
    },
  },
  computed: {
    apid() {
      if (this.$data.property) {
        return this.$data.property.IN_APID;
      } else {
        return '';
      }
    },
    /**ボタンを非活性にするか */
    isDisabledButton(): boolean {
      if (!this.needCreditCard) {
        //カード不要
        return false;
      } else if (
        !this.isExpiredPortasCreditCard &&
        (this.cardholderNameFormForAuthorizeIspCard || this.cardHolderNameOnPortas) &&
        this.isAgreedCopyCardToIsp &&
        this.isAgreedPrivacyPolicy
      ) {
        //カードコピーに同意している
        return false;
      } else {
        return this.creditCardAccessToken === '' || !this.isAgreedPrivacyPolicy;
      }
    },
  },
});
</script>
