<template>
  <div class="hikari-phone-confirm">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/e-mansion">e-mansion会員専用トップ</a></li>
        <li>UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" />UCOM光電話　お申し込み受付完了</h2>
        <p>UCOM光電話のお申し込みありがとうございます。</p>
        <div class="sblc">
          <p>通話料明細は下記サイトよりご確認いただけます。</p>
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>通話料明細WEB</b></th>
                <td>
                  <p>
                    <a class="link" :href="`${hikariPhoneBillingDetailsURL}`" target="_blank"> {{ hikariPhoneBillingDetailsURL }}</a>
                  </p>
                </td>
              </tr>
              <tr>
                <th><b>ログインID</b></th>
                <td>{{ loginId }}</td>
              </tr>
              <tr>
                <th><b>パスワード</b></th>
                <td>{{ password }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sblc">
          <h3 class="service-h3 cf">お申し込み内容</h3>
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>ご利用希望日</b></th>
                <td>{{ preferredDay }}</td>
              </tr>
              <tr>
                <th><b>電話番号取得方法</b></th>
                <td>
                  <span v-if="inputFormData.selectedGetPhoneMethod === '1'">新しく取得</span>
                  <span v-else-if="inputFormData.selectedGetPhoneMethod === '2'">番号ポータビリティ</span>
                </td>
              </tr>
              <tr v-if="inputFormData.selectedOptionalService.length > 0">
                <th><b>付加サービス利用料（有料）</b></th>
                <td>
                  <p v-if="inputFormData.selectedOptionalService.includes('toggle01')">・着信番号表示：月額{{ formatMoney(numberDisplayFee) }}&nbsp;円</p>
                  <p v-if="inputFormData.selectedOptionalService.includes('toggle02')">・キャッチ通話：月額{{ formatMoney(callWaitingFee) }}&nbsp;円</p>
                  <p v-if="inputFormData.selectedOptionalService.includes('toggle03')">・キャッチ通話番号表示：月額{{ formatMoney(callWaitingNumberDisplayFee) }}&nbsp;円</p>
                  <p v-if="inputFormData.selectedOptionalService.includes('toggle04')">・オプションサービスパック：月額{{ formatMoney(optionalServicePackFee) }}&nbsp;円</p>
                </td>
              </tr>
              <tr>
                <th><b>番号通知（無料）</b></th>
                <td>
                  <span v-if="inputFormData.isNotifyNumber === '1'">相手に通知する</span>
                  <span v-else-if="inputFormData.isNotifyNumber === '2'">相手に通知しない</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sblc" v-if="inputFormData.selectedGetPhoneMethod === '2'">
          <h3 class="service-h3 cf">番号ポータビリティ項目</h3>
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>引継ぎ電話番号</b></th>
                <td>{{ inputFormData.takeOverPhoneNumber }}</td>
              </tr>
              <tr>
                <th><b>名義人区分</b></th>
                <td>
                  <span v-if="inputFormData.relationshipType === '0'">本人</span>
                  <span v-else-if="inputFormData.relationshipType === '1'">本人以外</span>
                </td>
              </tr>
              <tr>
                <th><b>名義人</b></th>
                <td>{{ inputFormData.holderName }}</td>
              </tr>
              <tr>
                <th><b>名義人カナ</b></th>
                <td>{{ inputFormData.holderNameKana }}</td>
              </tr>
              <tr>
                <th><b>ご利用中の電話会社</b></th>
                <td>{{ inputFormData.telephoneCompany }}</td>
              </tr>
              <tr>
                <th><b>引継電話番号の登録住所</b></th>
                <td>
                  <p v-if="inputFormData.zipcode">〒{{ zipcode1 }}-{{ zipcode2 }}</p>
                  <p>{{ inputFormData.address }} {{ inputFormData.apartment }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="sblc">
        <h3 class="service-h3 cf">料金</h3>
        <table class="table-type2">
          <tbody>
            <tr>
              <th><b>事務手数料（初回のみ）</b></th>
              <td>{{ formatMoney(officeHandlingFee) }}&nbsp;円</td>
            </tr>
            <tr v-if="inputFormData.selectedGetPhoneMethod === '2'">
              <th><b>番号ポータビリティ手数料（初回のみ）</b></th>
              <td>{{ formatMoney(numberPortabilityFee) }}&nbsp;円</td>
            </tr>
            <tr></tr>
            <tr>
              <th><b>基本利用料（月額）</b></th>
              <td>{{ formatMoney(basicFee) }}&nbsp;円</td>
            </tr>
            <tr>
              <th><b>専用アダプタレンタル料（月額）</b></th>
              <td>{{ formatMoney(AdapterRentalFee) }}&nbsp;円</td>
            </tr>
            <tr>
              <th><b>ユニバーサルサービス料（月額）</b></th>
              <td>
                {{ formatMoney(universalServiceFee) }}&nbsp;円<br />
                <p>※ユニバーサルサービス制度にともない改定されることがあります。</p>
              </td>
            </tr>
            <tr v-if="inputFormData.selectedOptionalService.includes('toggle01')">
              <th><b>着信番号表示（月額）</b></th>
              <td>{{ formatMoney(numberDisplayFee) }}&nbsp;円</td>
            </tr>
            <tr v-if="inputFormData.selectedOptionalService.includes('toggle02')">
              <th><b>キャッチ通話（月額）</b></th>
              <td>{{ formatMoney(callWaitingFee) }}&nbsp;円</td>
            </tr>
            <tr v-if="inputFormData.selectedOptionalService.includes('toggle03')">
              <th><b>キャッチ通話番号表示（月額）</b></th>
              <td>{{ formatMoney(callWaitingNumberDisplayFee) }}&nbsp;円</td>
            </tr>
            <tr v-if="inputFormData.selectedOptionalService.includes('toggle04')">
              <th><b>オプションサービスパック（月額）</b></th>
              <td>{{ formatMoney(optionalServicePackFee) }}&nbsp;円</td>
            </tr>
            <tr>
              <th><b>UCOM光電話　総額</b></th>
              <td>
                <p>初回: {{ formatMoney(totalAmount) }}&nbsp;円 / 次回以降: {{ formatMoney(monthlyChargeFromNext) }}&nbsp;円</p>
                <a class="link" :href="`${eMansionUrl}/${apid}/service/ipdenwa/hikaridenwa.html`" target="_blank">※ 通話料は別途発生いたします。</a>
              </td>
            </tr>
          </tbody>
        </table>
        <p>価格は全て新税率に基づく税込表示（消費税10%）です。</p>
        <p>今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
      </div>

      <!-- ご請求内容の確認方法-->
      <ContractDetailsConfirmMethod />

      <div class="btn-area">
        <button class="btn btn01 bs" v-on:click="onNext()" type="button">e-mansion会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.condition {
  margin: 0 0 5px;
  border-left: 3px solid #cf1225;
  padding-left: 15px;
  margin-left: 10px;
}
div.template {
  margin-top: 30px;
}

div.blc-spacing {
  margin-top: 80px;
}

.underlayer-main {
  background-image: url('../../../images/main.png');
}
div.btn-area {
  position: relative;
  padding-top: 72px;
  text-align: center;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { EMansionCustomer, HikariphoneOption } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { UcomHikariPhoneOptionOnEmansionForDisplayDTO } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-option-dto';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import ContractDetailsConfirmMethod from '@/shared/components/e-mansion/contract-details-confirm-method.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { EMansionHikariPhoneRegisterResponseDto } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-register-dto';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { VueErrorHandler } from '@/handler/error/vue-error-handler';

/** UCOM光電話 確認 コンポーネント */
export default defineComponent({
  name: 'hikari-phone-completed',
  components: {
    LoadingComponent,
    ContractDetailsConfirmMethod,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** e-mantion My の URL */
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    /** 追加申し込みボタンの表示
     * 表示:ture,非表示:false */
    isDisplayAdditionalApply: false,
    /** UCOM の 通話料明細WEB URL */
    hikariPhoneBillingDetailsURL: process.env.VUE_APP_UCOM_HIKARIPHONE_BILLING_DETAILS_WEB_URL,
    // e-mansion サイト URL
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    /** 物件情報 */
    property: null as EMansionProperty | null,
    // e-mansion契約基本情報
    customer: null as EMansionCustomer | null,
    /**ランダムで設定する9桁の数字を当てはめる */
    rd: '',
    /**UCOM光電話 利用状況が「利用中」の場合
     * true:利用中、false:それ以外
     */
    isInUseHikariPhone: false,
    /**番号通知するか
     * true:する、false:しない
     */
    isNoticeNumber: false,
    /** 契約基本情報取得API.オプション利用状況.UCOM光電話.付加サービス */
    optionArray: [] as HikariphoneOption[],
    /** 契約基本情報取得API.オプション利用状況.UCOM光電話.付加サービス 表示用*/
    optionArrayForDisplay: [] as UcomHikariPhoneOptionOnEmansionForDisplayDTO[],
    /** 契約基本情報取得API.オプション利用状況.UCOM光電話.利用状況　表示用 */
    statusForDisplay: null as string | null,
    loginId: null as string | null,
    password: null as string | null,
    inputFormData: {
      /** ご利用希望日 */
      preferredDate: '',
      /** 電話番号取得方法 */
      selectedGetPhoneMethod: '',
      /** 番号通知 */
      isNotifyNumber: '',
      /** 引継ぎ電話番号 */
      takeOverPhoneNumber: '',
      /** 名義人 */
      holderName: '',
      /** 名義人カナ */
      holderNameKana: '',
      /** 利用中の電話会社 */
      telephoneCompany: '',
      /** 郵便番号 */
      zipcode: '',
      /** 住所 */
      address: '',
      /** マンション名や部屋番号等 */
      apartment: '',
      /** 名義人区分 */
      relationshipType: '',
      /** 付加オプションサービス */
      selectedOptionalService: [] as string[],
      /** 利用中の電話会社 */
      telephoneCompanyType: '',
      /** その他の電話会社キャリア */
      otherCompanyName: '',
    },
    /** ご利用希望日 */
    preferredDay: '',
    /** 事務手数料 */
    officeHandlingFee: '',
    /** 番号ポータビリティ手数料 */
    numberPortabilityFee: '',
    /** 基本手数料 */
    basicFee: '',
    /** 専用アダプタレンタル料 */
    AdapterRentalFee: '',
    /** ユニバーサルサービス料 */
    universalServiceFee: '',
    /** 着信番号表示料金 */
    numberDisplayFee: '',
    /** キャッチ通話料金 */
    callWaitingFee: '',
    /** キャッチ通話番号表示料金 */
    callWaitingNumberDisplayFee: '',
    /** オプションサービスパック料金 */
    optionalServicePackFee: '',
    /** 総額 */
    totalAmount: '',
    /** 次回以降のお支払い */
    monthlyChargeFromNext: '',
    /** e-mansion 会員ID */
    eMansionMemberId: '' as string,
    /** apid */
    apid: '',
    /** 郵便番号1 */
    zipcode1: '',
    /** 郵便番号2 */
    zipcode2: '',
  }),
  //ログインチェック、物件チェック、ほか、確かめる内容を追加
  async mounted(): Promise<void> {
    this.isLoading = true;

    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    try {
      // 画面入力情報取得
      this.inputFormData = this.$store.getters['eMansionHikariphoneStore/inputData'];
      this.zipcode1 = this.inputFormData.zipcode ? this.inputFormData.zipcode.slice(0, 3) : '';
      this.zipcode2 = this.inputFormData.zipcode ? this.inputFormData.zipcode.slice(-4) : '';
      this.preferredDay = this.formatDateToJapanese(new Date(this.inputFormData.preferredDate));
      if (this.inputFormData == null) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }

      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      this.eMansionMemberId = member.primaryKeyMye ?? '';

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];

      if (property?.apartmentId) {
        this.apid = convert6DigitIdTo8DigitId(property?.apartmentId);
      }

      // サービス登録APIの返却結果
      const registerHikariPhoneInfo = this.$store.getters['eMansionUcomHikariPhoneRegisterAPIResponseStore/info'];

      this.officeHandlingFee = this.removeComma(registerHikariPhoneInfo.optional_data.Hikariphone.billing_data.jimu_fee);
      this.numberPortabilityFee = this.removeComma(registerHikariPhoneInfo.optional_data.Hikariphone.billing_data?.numberportability_fee);
      this.basicFee = this.removeComma(registerHikariPhoneInfo.optional_data.Hikariphone.billing_data.basic_fee);
      this.AdapterRentalFee = this.removeComma(registerHikariPhoneInfo.optional_data.Hikariphone.billing_data.rental_charge);
      this.universalServiceFee = this.removeComma(registerHikariPhoneInfo.optional_data.Hikariphone.billing_data.uni_charge_tax_inc);
      this.numberDisplayFee = this.removeComma(registerHikariPhoneInfo.optional_data.Hikariphone.billing_data?.numberdisplay_fee);
      this.callWaitingFee = this.removeComma(registerHikariPhoneInfo.optional_data.Hikariphone.billing_data?.catch_fee);
      this.callWaitingNumberDisplayFee = this.removeComma(registerHikariPhoneInfo.optional_data.Hikariphone.billing_data?.catch_display_fee);
      this.optionalServicePackFee = this.removeComma(registerHikariPhoneInfo.optional_data.Hikariphone.billing_data?.servicepack_fee);

      // 初回の合計金額を算出
      this.totalAmount = (
        parseInt(this.officeHandlingFee) +
        (this.inputFormData.selectedGetPhoneMethod === '2' ? parseInt(this.numberPortabilityFee) : 0) +
        parseInt(this.basicFee) +
        parseInt(this.AdapterRentalFee) +
        parseInt(this.universalServiceFee) +
        (this.inputFormData.selectedOptionalService.includes('toggle01') ? parseInt(this.numberDisplayFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle02') ? parseInt(this.callWaitingFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle03') ? parseInt(this.callWaitingNumberDisplayFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle04') ? parseInt(this.optionalServicePackFee) : 0)
      ).toString();

      // 次回以降の月額の合計金額を算出
      this.monthlyChargeFromNext = (
        parseInt(this.basicFee) +
        parseInt(this.AdapterRentalFee) +
        parseInt(this.universalServiceFee) +
        (this.inputFormData.selectedOptionalService.includes('toggle01') ? parseInt(this.numberDisplayFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle02') ? parseInt(this.callWaitingFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle03') ? parseInt(this.callWaitingNumberDisplayFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle04') ? parseInt(this.optionalServicePackFee) : 0)
      ).toString();

      // ログインId/パスワード取得
      await this.getLoginIdAndPassword(registerHikariPhoneInfo);

      this.isLoading = false;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }
  },
  methods: {
    async onNext(): Promise<void> {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      // e-mansion会員専用トップページへの遷移
      await this.$router.push('/e-mansion').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
    },

    formatDateToJapanese(date: Date | null): string {
      if (date === null) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      const year = date.getFullYear(); // 年を取得
      const month = date.getMonth() + 1; // 月を取得 (0から始まるため +1)
      const day = date.getDate(); // 日を取得

      return `${year}年${month}月${day}日`;
    },
    /**
     * @param billing 表示する金額
     * @return カンマをなくした金額文字列（例：xx,xxx）
     */
    removeComma(billing: string): string {
      var removed = billing.replace(/,/g, '');
      return removed;
    },
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: string): string {
      const formatter = new Intl.NumberFormat('en-US');
      const formattedValue = formatter.format(Number(billing));
      return formattedValue;
    },
    /**
     * 表示用ログインIDとPWを取得
     * PWは難読化されているため復号
     */
    async getLoginIdAndPassword(hikariPhoneInfo: EMansionHikariPhoneRegisterResponseDto): Promise<void> {
      if (hikariPhoneInfo.optional_data?.Hikariphone?.web_login_id) {
        this.loginId = hikariPhoneInfo.optional_data?.Hikariphone?.web_login_id;
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
      }
      if (hikariPhoneInfo.optional_data?.Hikariphone?.web_login_password_decrypted) {
        // backendで復号＆難読化されたパスワードが返るので難読化解読する
        const decoredWebLoginPass = ObfuscationService.decode(hikariPhoneInfo.optional_data?.Hikariphone?.web_login_password_decrypted);
        this.password = decoredWebLoginPass;
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
      }
    },
  },
});
</script>
