import axios, { AxiosResponse } from 'axios';

import store from '../../../store/index';
import { ExternalSearchPropertiesSearchQuasiNormalResponseDto } from '../../classes/external-api/search-properties/external-search-properties-quasi-normal-response-dto';
import { ExternalSearchPropertiesRequestDto } from '../../classes/external-api/search-properties/external-search-properties-request-dto';
import { ExternalSearchPropertiesSearchSuccessResponseDto } from '../../classes/external-api/search-properties/external-search-properties-search-success-response-dto';
import { Address } from '../../classes/spf-api/address';
import { Building } from '../../classes/spf-api/building';
import { Member } from '../../classes/spf-api/member';
import { MemberStatus } from '../../classes/spf-api/member-status';
import { Property, SearchPropertyDetail, SearchResultOver } from '../../classes/spf-api/property';
import { convert6DigitIdTo8DigitId } from '../../util/convert';
import { AuthService } from '../auth/auth-service';

import { ContactsDto } from '@/pages/platform/contacts/classes/contacts-dto';
import router from '@/router';
import { ApplicationContentTemporaryStorageResponse } from '@/shared/classes/platform/application-content-temporary-storage-response';
import { ContractFromCopyPaymentMethodRequest } from '@/shared/classes/platform/contract-from-copy-payment-method-request';
import { ContractFromCreatePaymentMethodRequest } from '@/shared/classes/platform/contract-from-create-payment-method-request';
import { ContractFromEntryPortasRequest } from '@/shared/classes/platform/contract-from-entry-portas-request';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PlatformPaymentMethodEditRequest } from '@/shared/classes/platform/edit-payment-method-request';
import { inputDataTemporarySaveRequest } from '@/shared/classes/platform/input-data-temporary-save-request';
import { PaymentMethodCopyForIspResponse } from '@/shared/classes/platform/payment-method-copy-For-Isp-response';
import { PaymentMethodCopyResponse } from '@/shared/classes/platform/payment-method-copy-response';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { PaymentMethodListResponse } from '@/shared/classes/platform/payment-method-list-response';
import { PlatformPaymentMethodRegisterResponse } from '@/shared/classes/platform/platform-payment-method-register-response';
import { RegisterPaymentMethodInfoResponse } from '@/shared/classes/platform/register-payment-method-info-response';
import { PlatformPaymentMethodRegisterRequest } from '@/shared/classes/platform/register-payment-method-request';
import { RemainingWorkReceptionRecord } from '@/shared/classes/platform/remaining-work-reception-record';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { BusinessdayMasterDTO, BusinessdayMasterDTOArray } from '@/shared/classes/spf-api/available-business-days-dto';
import { BatchSituationDto } from '@/shared/classes/spf-api/batch-situation-dto';
import { BBSSServiceInfo } from '@/shared/classes/spf-api/bbss-service-info';
import { BusinessdaysUtilDTO, BusinessdaysUtilDTOArray } from '@/shared/classes/spf-api/businessdays-util-dto';
import { CancellationInfo } from '@/shared/classes/spf-api/cancellation-info';
import { FrontErrorRequest } from '@/shared/classes/spf-api/front-error-request';
import { InvoiceHistoryListDto } from '@/shared/classes/spf-api/invoice-history-list-dto';
import { LinkServiceWithEMansionRequest, LinkServiceWithEMansionResponse } from '@/shared/classes/spf-api/link-service-with-e-mansion';
import { LinkServiceWithFiveARequest, LinkServiceWithFiveAResponse } from '@/shared/classes/spf-api/link-service-with-five-a';
import { LinkServiceWithUcomErrorResponse, LinkServiceWithUcomRequest } from '@/shared/classes/spf-api/link-service-with-ucom';
import { MScreenInfo } from '@/shared/classes/spf-api/m-screen';
import { MaintenanceScreenInfo } from '@/shared/classes/spf-api/maintenance-screen';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { ParameterDecisionProperty } from '@/shared/classes/spf-api/parameter-decision-property';
import { ProductIsContractableDto } from '@/shared/classes/spf-api/portas-subscription/product-isContractable-dto';
import { ProductsDto } from '@/shared/classes/spf-api/portas-subscription/products-dto';
import { SubscriptionDetailsArray } from '@/shared/classes/spf-api/portas-subscription/subscription-details-dto';
import { SubscriptionDetailsRequestDto } from '@/shared/classes/spf-api/portas-subscription/subscription-details-request-dto';
import { RegisterIspCardResponseDto } from '@/shared/classes/spf-api/registerIspCardResponseDto';
import { RemainingWorkConfirmDto } from '@/shared/classes/spf-api/remaining-work-confirm-dto';
import { TsunaguOnlineProductHtmlRequestDto, TsunaguOnlineProductHtmlResponseDto } from '@/shared/classes/spf-api/tsunagu-online-product-html';
import { UNextAccountInfo } from '@/shared/classes/spf-api/u-next/u-next-account-info';
import { ImportFormId } from '@/shared/classes/ucom/input-form-info';
import { BaseApiService } from './base-api-service';

const api = BaseApiService.api();
const rawApi = BaseApiService.rawApi();

/** 内部 API をコールするサービス */
export class SpfApiService {
  /**
   * mcloudとアカウント連携を行う
   * @param memberId
   * @param loginInformation
   */
  public static async createMcloudLinkService(memberId: number, loginInformation: { loginId: string; password: string }) {
    await api.post(`/auth0-management/mcloud/linking/${memberId}`, loginInformation).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
  }

  /**
   * 会員情報を取得する
   *
   * @return 会員情報
   */
  public static async findMember(): Promise<Member> {
    const id = await AuthService.getMemberId();
    const response = await api.get<Member>(`/members/${id}`, { params: { nocache: new Date().getTime() } }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * 会員情報を新規登録する
   *
   * @param member 会員情報
   * @return 作成された会員情報
   */
  public static async createMember(member: Omit<Member, 'id'>): Promise<Member> {
    const response = await api.post<Member>('/members', member).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * 会員情報を削除する
   *
   * @param memberId 会員ID
   */
  public static async deleteMember(memberId: number): Promise<void> {
    await api.delete(`members/${memberId}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
  }

  /**
   * 会員情報を更新する・正常レスポンスは 204 No Content
   *
   * @param id 会員 ID
   * @param member 会員情報
   */
  public static async updateMember(id: string, member: Omit<Member, 'id'>): Promise<void> {
    await api.patch<Member>(`/members/${id}`, member).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
  }
  /**
   * 支払方法一覧取得 API を実行する
   *
   * @param {number} memberId Portas会員 ID
   */
  public static async getPaymentMethodList(memberId: number): Promise<PaymentMethodListResponse[]> {
    const response = await api.get<PaymentMethodListResponse[]>(`/payment-methods?id=${memberId}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * VeriTransの３Dセキュア本人認証（トークン使用） API を実行する
   *
   * @param {Create3dSecureAuthStartInfoWithCardTokenRequest} dddSecureAuthStartWithCardTokenRequest
   * @return url
   */
  public static async create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest: Create3dSecureAuthStartInfoWithCardTokenRequest): Promise<string> {
    const response = await api.post(`/payment-methods/3d-secure/auth-start-info-with-card-token`, dddSecureAuthStartWithCardTokenRequest).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * VeriTransの３Dセキュア本人認証（VeriTrans会員ID使用） API を実行する
   *
   * @param {Create3dSecureAuthStartInfoWithVeritransAccountIdRequest} dddSecureAuthStartWithVeritransAccountIdRequest
   * @returns url
   */
  public static async create3dSecureAuthStartInfoWithVeritransAccountId(
    dddSecureAuthStartWithVeritransAccountIdRequest: Create3dSecureAuthStartInfoWithVeritransAccountIdRequest
  ): Promise<string> {
    const response = await api
      .post(`/payment-methods/3d-secure/auth-start-info-with-veritrans-account-id`, dddSecureAuthStartWithVeritransAccountIdRequest)
      .catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });
    return response.data;
  }

  /**
   * 申込内容一時保存 API を実行する
   *
   * @param {TemporarySavingApplicationDataRequest} temporarySavingApplicationDataRequest 申込内容一時保存API リクエストパラメータ
   * @return url
   */
  public static async temporarySavingApplicationData(temporarySavingApplicationDataRequest: TemporarySavingApplicationDataRequest): Promise<string> {
    const response = await api.post(`/application-data`, temporarySavingApplicationDataRequest).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }
  /**
   * 申込内容一時保存データ取得API
   * @param uuid
   * @returns
   */
  public static async getApplicationContentTemporaryStorage(uuid: string): Promise<ApplicationContentTemporaryStorageResponse> {
    const response = await api.get<ApplicationContentTemporaryStorageResponse>(`/application-data`, { params: { uuid: uuid } }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * VeriTransカード情報取得 API を実行する
   *
   * @param id Portas会員 ID
   * @param paymentMethodNo 支払方法 No
   */
  public static async getPaymentMethodDetail(memberId: number, paymentMethodNo: number): Promise<PaymentMethodDetailResponse> {
    const response = await api.get<PaymentMethodDetailResponse>(`/payment-methods/${paymentMethodNo}/members/${memberId}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * 支払方法登録 API を実行する
   *
   * @param id Portas会員 ID
   * @param creditCardToken クレジットカードトークン
   * @param tokenExpireDate クレジットカードトークン有効期限
   */
  public static async createPaymentMethod(memberId: number, paymentMethodRegisterRequest: PlatformPaymentMethodRegisterRequest): Promise<PlatformPaymentMethodRegisterResponse> {
    const response = await api.post(`/payment-methods/${memberId}`, paymentMethodRegisterRequest).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });

    return new PlatformPaymentMethodRegisterResponse(response.data);
  }

  /** 支払方法編集 API を実行する
   *
   * @param id Portas会員 ID
   * @param paymentMethodNo Portas会員 ID
   * @param creditCardToken クレジットカードトークン
   * @param tokenExpireDate クレジットカードトークン有効期限
   */
  public static async updatePaymentMethod(paymentMethodEditRequest: PlatformPaymentMethodEditRequest): Promise<void> {
    await api.patch('/payment-methods', paymentMethodEditRequest).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
  }

  /**
   * 支払方法削除 API を実行する
   *
   * @param id Portas会員 ID
   * @param paymentMethodNo 支払方法 No
   */
  public static async deletePaymentMethod(memberId: number, paymentMethodNo: number): Promise<void> {
    await api.delete(`/payment-methods/${memberId}`, { params: { paymentMethodNo: paymentMethodNo } }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
  }

  /**
   * 支払方法コピー API を実行する
   *
   * @param id Portas会員 ID
   * @param uaType ISP種別
   * @param sourceVeritransAccountId VeriTrans 元会員ID
   */
  public static async copyPaymentMethodFromIsp(id: number, uaType: string, sourceVeritransAccountId: string): Promise<PaymentMethodCopyResponse> {
    const response = await api.post(`/payment-methods/copy/${id}`, { uaType: uaType, originalAccountId: sourceVeritransAccountId }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * VeriTransカード情報取得 API を実行する
   *
   * @param vid VeriTrans 会員 ID
   */
  public static async getDefaultPaymentMethod(vid: string): Promise<PaymentMethodDetailResponse> {
    const response = await api.get<PaymentMethodDetailResponse>(`/credit-info/${vid}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }
  /**
   * VeriTransの支払い方法登録内容一時保存 API を実行する
   *
   * @param secureAuthRequest
   * @return url
   */
  public static async inputDataTemporarySave(inputData: inputDataTemporarySaveRequest): Promise<string> {
    const response = await api.post(`/payment-methods/store-download-url/members`, inputData).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });

    const authStartURL = response.data;
    return authStartURL;
  }

  /**
   * 物件情報を1件取得する
   * @param id 物件 ID
   * @return 物件情報
   */
  public static async findPropertyById(id: number): Promise<Property> {
    const response = await api.get<Property>(`/properties/${id}`, { params: { nocache: new Date().getTime() } }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   *  物件検索API
   * @param type 検索タイプ (1:物件名, 2:住所, 3:e-mansion_物件ID, 4:UCOM_物件ID, 5:Mcloud_物件ID)
   * @param key 検索キー
   * @return 物件情報[]
   */
  public static async searchProperties(type: number, key: string, isPerfectMatch: boolean): Promise<SearchPropertyDetail[] | SearchResultOver> {
    try {
      let data: { searchWord: string; searchType: number; exactMatch?: number; nocache: number };

      if (type === 1) {
        data = { searchWord: key, searchType: type, exactMatch: isPerfectMatch ? 1 : 0, nocache: new Date().getTime() };
      } else {
        data = { searchWord: key, searchType: type, nocache: new Date().getTime() };
      }
      const isLogin = await AuthService.isLoggedIn();
      if (isLogin) {
        const response = await api.get<SearchPropertyDetail[] | SearchResultOver>('/search/search-properties', { params: data }).catch(async (error) => {
          await this.judgeError(error);
          throw error;
        });
        if (Array.isArray(response.data)) {
          return response.data.map((value) => new SearchPropertyDetail(value));
        } else {
          return new SearchResultOver(response.data);
        }
      } else {
        const response = await rawApi.get<SearchPropertyDetail[] | SearchResultOver>('/search/search-properties-exp', { params: data }).catch(async (error) => {
          await this.judgeError(error);
          throw error;
        });
        if (Array.isArray(response.data)) {
          return response.data.map((value) => new SearchPropertyDetail(value));
        } else {
          return new SearchResultOver(response.data);
        }
      }
    } catch (e: any) {
      throw e;
    }
  }
  /**apartment_idから物件を検索する */
  public static async searchPropertiesByApartmentId(apartmentId: string): Promise<Property> {
    const response = await api.get<Property>(`/properties/apartment/${apartmentId}`, { params: { nocache: new Date().getTime() } }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * 物件棟検索API
   * @param zipcode 郵便番号
   * @return 住所情報
   */
  public static async searchBuildings(apartmentId: string): Promise<Building[]> {
    try {
      const data = { apartmentId: apartmentId, nocache: new Date().getTime() };
      const response = await api.get<Building[]>('/search/search-buildings', { params: data }).catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });
      return response.data;
    } catch (e: any) {
      throw e;
    }
  }

  /**
   * 住所検索API
   * @param zipcode 郵便番号
   * @return 住所情報
   */
  public static async searchAddresses(zipcode: string): Promise<Address[]> {
    try {
      const data = { zipcode: zipcode.split('-').join(''), nocache: new Date().getTime() };
      const response = await api.get<Address[]>('/search/search-addresses', { params: data }).catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });
      return response.data;
    } catch (e: any) {
      throw e;
    }
  }

  public static async getImportFormId(propertyId: number, roomNumber: string): Promise<ImportFormId> {
    const response = await api.get<ImportFormId>(`/external/ucom/properties/rooms/${propertyId}?roomNumber=${roomNumber}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * パラメータ判定テーブルから該当物件を1件取得して返す
   * @param transitionSourceServiceId 遷移元サービス物件ID
   * @return パラメータ判定テーブルデータ
   */
  public static async findParameterDecisionPropertyById(transitionSourceServiceId: string): Promise<ParameterDecisionProperty | undefined> {
    const response = await api
      .get<ParameterDecisionProperty>(`/parameter-decision-properties/${transitionSourceServiceId}`, { params: { nocache: new Date().getTime() } })
      .then((response) => {
        return new ParameterDecisionProperty(response.data);
      })
      .catch(async (error) => {
        if (error.response?.status === 404) {
          return undefined;
        } else {
          await this.judgeError(error);
          throw error;
        }
      });
    return response;
  }

  /**
   * 会員ステータス情報を1件取得する
   * @param primaryKeyAuth0 PortasテナントのAuth0 ID
   * @return 会員ステータス情報
   */
  public static async findMemberStatusByPrimaryKeyAuth0(primaryKeyAuth0: string): Promise<MemberStatus> {
    const response = await api.get<MemberStatus>(`/member-statuses/${primaryKeyAuth0}`, { params: { nocache: new Date().getTime() } }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }
  /**
   * GW顧客番号で会員ステータス情報を取得する
   * @param primaryKeyAuth0 PortasテナントのAuth0 ID
   * @return 会員ステータス情報
   */
  public static async findMemberStatusByPrimaryKeyGw(primaryKeyGw: string): Promise<MemberStatus | undefined> {
    try {
      const escapedGwid = encodeURIComponent(primaryKeyGw.replace(/\s/g, '+'));
      const response = await api.get<MemberStatus>(`/member-statuses/game-with-hikari/${escapedGwid}`);
      return response!.data;
    } catch (error: any) {
      if (error.response?.status == '404') {
        //404のときはエラー画面には遷移させずundefinedを返す
        return undefined;
      }
      await this.judgeError(error);
      throw error;
    }
  }
  /**
   * 会員ステータスを登録する
   * @param memberStatus 会員ステータス
   * @return 作成された会員ステータス情報
   */
  public static async createMemberStatus(memberStatus: MemberStatus): Promise<MemberStatus> {
    const response = await api.post<MemberStatus>('/member-statuses', memberStatus).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * 会員ステータスを更新する
   * @param memberStatus 会員ステータス
   * @param primaryKeyAuth0 PortasテナントのAuth0 ID
   */
  public static async updateMemberStatus(primaryKeyAuth0: string, memberStatus: MemberStatus): Promise<MemberStatus> {
    try {
      const response = await api.patch<MemberStatus>(`/member-statuses/${primaryKeyAuth0}`, memberStatus).catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });
      return response.data;
    } catch (e: any) {
      throw e;
    }
  }

  /**
   * UCOMの同時お申し込みを行う
   * @param id 会員ID
   * @param linkServiceWithUcomRequest 同時お申し込み情報
   * @return 200 空
   * @throws LinkServiceWithUcomErrorResponse
   */
  public static async linkServiceWithUcom(id: number, linkServiceWithUcomRequest: LinkServiceWithUcomRequest): Promise<any> {
    const response = await api.post(`/auth0-management/ucom/link-service/${id}`, linkServiceWithUcomRequest).catch(async (e) => {
      await this.judgeError(e);
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 400) {
          throw new LinkServiceWithUcomErrorResponse(e.response.data);
        }
      }
      throw e;
    });
    return response.data;
  }

  /**
   * e-mansionの同時お申し込みを行う
   * @param id 会員ID
   * @param linkServiceWithEMansionRequest 同時お申し込み情報
   * @return 作成された会員ステータス情報
   */
  public static async linkServiceWithEMansion(id: number, linkServiceWithEmansionRequest: LinkServiceWithEMansionRequest): Promise<LinkServiceWithEMansionResponse> {
    const response = await api.post<LinkServiceWithEMansionResponse>(`/auth0-management/e-mansion/link-service/${id}`, linkServiceWithEmansionRequest).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * five-aの同時お申し込みを行う
   * @param id 会員ID
   * @param linkServiceWithFiveARequest 同時お申し込み情報
   * @return 作成された会員ステータス情報
   */
  public static async linkServiceWithFiveA(id: number, linkServiceWithFiveARequest: LinkServiceWithFiveARequest, apartmentId: string): Promise<LinkServiceWithFiveAResponse> {
    linkServiceWithFiveARequest.propertyId = convert6DigitIdTo8DigitId(apartmentId);
    const response = await api.post<LinkServiceWithFiveAResponse>(`/auth0-management/five-a/link-service/${id}`, linkServiceWithFiveARequest).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * お問い合わせ
   */
  public static async contacts(contactsDto: ContactsDto): Promise<AxiosResponse<string>> {
    if (contactsDto.isLogin) {
      // ログイン済み
      return await api.post<string>('/contacts', contactsDto).catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });
    } else {
      // 未ログイン
      // Auth0認証をしないのでトークン未設定
      return await rawApi.post<string>('/contacts/not-login', contactsDto).catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });
    }
  }

  /**
   * 確認メール送信API
   * @param emailAddress メールアドレス
   * @return
   */
  public static async createToken(emailAddress: string): Promise<void> {
    try {
      const response = await api.post(`/token/create`, { emailAddress: emailAddress }).catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });
      if (!response.status || !(response.status >= 200) || !(response.status <= 299)) {
        throw new Error();
      }
      return;
    } catch (error: any) {
      throw error;
    }
  }

  /**
   * OEMマスタ一覧取得API
   * @return OEMマスタ
   */
  public static async getOemsMaster(): Promise<GetOemsMasterResponse[]> {
    const response = await rawApi.get<GetOemsMasterResponse[]>(`/oems`, { params: { nocache: new Date().getTime() } }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * フロントエンドエラーロギングAPI
   *
   * @param frontErrorInfo エラー情報
   */
  public static async postReportFrontError(frontErrorInfo: FrontErrorRequest): Promise<void> {
    await rawApi.post(`/front-error`, frontErrorInfo, { params: { nocache: new Date().getTime() } }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
  }

  private static async judgeError(error: any) {
    if (error.response?.status === 503 && error.response.data) {
      store.dispatch('maintenanceStore/maintenance', error.response.data);
      await router.push('/maintenance');
    }
  }

  public static async getTaxRate(): Promise<string> {
    const response = await api.get<string>('tax-rate').catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * 物件検索API
   *
   * @param requestDto 検索パラメータ
   * @returns 検索結果
   * @returns undefined
   */
  public static async externalSearchProperties(
    requestDto: ExternalSearchPropertiesRequestDto
  ): Promise<ExternalSearchPropertiesSearchSuccessResponseDto | ExternalSearchPropertiesSearchQuasiNormalResponseDto | undefined> {
    const response = await api
      .get<ExternalSearchPropertiesSearchSuccessResponseDto | ExternalSearchPropertiesSearchQuasiNormalResponseDto>(`/external/e-mansion/apartment/search`, {
        params: requestDto,
      })
      .catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });

    if (response.data.status === 'success') {
      return new ExternalSearchPropertiesSearchSuccessResponseDto(response.data);
    }

    if (response.data.status === 'fail') {
      return new ExternalSearchPropertiesSearchQuasiNormalResponseDto(response.data);
    }

    return undefined;
  }

  /**
   * つなぐオンラインショップの商品ページをスクレイピング処理したPC用HTMLを取得する
   */
  public static async fetchTsunaguOnlineProductHtmlDisplayOnPc(requestDto: TsunaguOnlineProductHtmlRequestDto): Promise<TsunaguOnlineProductHtmlResponseDto> {
    const response = await rawApi
      .post<TsunaguOnlineProductHtmlResponseDto>('/tnos/product-html/display-on-pc', requestDto)
      .then((response) => {
        return new TsunaguOnlineProductHtmlResponseDto(response.data);
      })
      .catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });

    return response;
  }

  /**
   * つなぐオンラインショップの商品ページをスクレイピング処理したスマホ用HTMLを取得する
   */
  public static async fetchTsunaguOnlineProductHtmlDisplayOnSp(requestDto: TsunaguOnlineProductHtmlRequestDto): Promise<TsunaguOnlineProductHtmlResponseDto> {
    const response = await rawApi
      .post<TsunaguOnlineProductHtmlResponseDto>('/tnos/product-html/display-on-sp', requestDto)
      .then((response) => {
        return new TsunaguOnlineProductHtmlResponseDto(response.data);
      })
      .catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });

    return response;
  }

  /**
   * サブスク商品の契約処理を実行するAPI
   * @param memberId 会員 ID
   * @param paymentMethodNo 支払方法 No
   * @param contractIdArray 契約 IDの配列
   * @param productNameArray 契約したい商品名のリスト
   * @param cancellationAppliedAt 解約申込日時 YYYY-MM-DDTHH:mm:ss[+-]HH:mm
   * @return 解約情報の配列
   */
  public static async subscriptionCreateRequest(
    memberId: string,
    paymentMethodNo: string,
    productIdArray: Array<string>,
    productNameArray: string[],
    contractAppliedAt: string
  ): Promise<number[]> {
    const response = await api
      .post<number[]>(`/subscriptions`, {
        memberId: memberId,
        paymentMethodNo: paymentMethodNo,
        productIdArray: productIdArray,
        productNameArray: productNameArray,
        contractAppliedAt: contractAppliedAt,
      })
      .catch(async (error) => {
        await this.judgeError(error);
        throw error;
      });
    return response.data;
  }

  /**
   * 新規会員登録・サービス同時申し込みAPIを実行する
   *
   * @return 申込が完了した契約IDの配列
   */
  public static async contractFromEntryPortas(request: ContractFromEntryPortasRequest): Promise<number[]> {
    const response = await api.post<number[]>('/portas-subscription/contract/from-entry-portas', request).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * 支払方法登録・サービス同時申し込みAPIを実行する
   *
   * @param memberId 会員 ID
   * @return 申込が完了した契約IDの配列
   */
  public static async contractFromCreatePaymentMethod(memberId: number, paymentMethodRegisterRequest: ContractFromCreatePaymentMethodRequest): Promise<number[]> {
    const response = await api.post<number[]>(`/portas-subscription/contract/from-create-payment-method/${memberId}`, paymentMethodRegisterRequest).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * 支払方法コピー・サービス同時申し込みAPIを実行する
   *
   * @param memberId 会員 ID
   * @return 申込が完了した契約IDの配列
   */
  public static async contractFromCopyPaymentMethod(memberId: number, contractFromCopyPaymentMethodRequest: ContractFromCopyPaymentMethodRequest): Promise<number[]> {
    const response = await api.post<number[]>(`/portas-subscription/contract/from-copy-payment-method/${memberId}`, contractFromCopyPaymentMethodRequest).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * サブスク商品の解約受付処理を実行するAPI
   * @param memberId 会員 ID
   * @param contractIdArray 契約 IDの配列
   * @param cancellationAppliedAt 解約申込日時 YYYY-MM-DDTHH:mm:ss[+-]HH:mm
   * @param productNameArray 商品名配列
   * @return 解約情報の配列
   */
  public static async subscriptionCancellationRequest(
    memberId: string,
    contractArray: SubscriptionDetailsRequestDto[],
    cancellationAppliedAt: string,
    productNameArray: string[]
  ): Promise<CancellationInfo[]> {
    try {
      const requestBody = { memberId, contractArray, cancellationAppliedAt, productNameArray };
      const config = { data: requestBody };
      const response = await api.delete<CancellationInfo[]>(`/subscriptions`, config);
      return response.data;
    } catch (error: any) {
      await this.judgeError(error);
      throw error;
    }
  }

  /*
   * 請求明細を取得する
   * @param member 会員 ID
   * @param targetMonth 対象年月
   * @return 請求明細
   */
  public static async getInvoiceHistoryList(member: number, targetMonth: number): Promise<InvoiceHistoryListDto> {
    const response = await api.get<InvoiceHistoryListDto>(`/members/${member}/invoices`, { params: { targetMonth: targetMonth } }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * サブスクリプション一覧取得API
   *
   * @param member 会員ID
   * @returns サブスクリプション一覧
   */
  public static async getSubscriptionList(member: number): Promise<SubscriptionDetailsArray> {
    const response = await api.get<SubscriptionDetailsArray>(`/members/${member}/subscriptions`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return new SubscriptionDetailsArray(response.data);
  }

  /**
   * 商品詳細取得API
   *
   * @param productId 商品ID
   * @returns 商品詳細
   */
  public static async getProductDetail(productId: number): Promise<ProductsDto> {
    const response = await api.get<ProductsDto>(`/products/${productId}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /**
   * 商品契約可否確認API
   *
   * @param member 会員ID
   * @param productIdList[] 商品ID配列
   * @returns isContractable 購入可否
   */
  public static async isProductsContractable(member: string, productIdList: number[]): Promise<boolean> {
    const response = await api.post<ProductIsContractableDto>(`products/contracts-possibility`, { memberId: member, productIdList: productIdList }).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data.isContractable;
  }

  /**
   * Portas画面マスタ情報を取得する
   *
   * @return Portas画面マスタ情報
   */
  public static async getMScreen(toPath: string): Promise<MScreenInfo> {
    try {
      const data = { toPath: toPath, nocache: new Date().getTime() };
      const response = await rawApi.get<MScreenInfo>(`/master/m-screen`, { params: data });
      return response.data;
    } catch (error: any) {
      await this.judgeError(error);
      throw error;
    }
  }

  /**
   * メンテナンス画面情報を取得する
   *
   * @return メンテナンス情報
   */
  public static async getMaintenanceScreen(screenId: string): Promise<MaintenanceScreenInfo> {
    try {
      const data = { screenId: screenId, nocache: new Date().getTime() };
      const response = await rawApi.get<MaintenanceScreenInfo>(`/maintenance`, { params: data });
      return response.data;
    } catch (error: any) {
      await this.judgeError(error);
      throw error;
    }
  }

  /**
   * バッチ状況テーブルから情報を1件取得する
   * @param batchId バッチ ID
   * @return バッチ状況情報
   */
  public static async findBatchSituationByBatchId(batchId: string): Promise<BatchSituationDto> {
    const response = await api.get<BatchSituationDto>(`/batch-situation/${batchId}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }

  /** U-NEXT_アカウント情報取得API
   *
   * @param memberId 会員ID
   * @returns U-NEXT_アカウント情報
   */
  public static async getUNextAccountInfo(memberId: number): Promise<UNextAccountInfo> {
    const response = await api.get<UNextAccountInfo>(`/u-next/accountInfo/${memberId}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return new UNextAccountInfo(response.data);
  }

  /** BBSS_サービス情報取得 API
   *
   * @param contractId 契約ID
   * @returns BBSS_サービス情報
   */
  public static async getBBSSServiceInfo(contractId: number): Promise<BBSSServiceInfo> {
    const response = await api.get<BBSSServiceInfo>(`/bbss/service-info/${contractId}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return new BBSSServiceInfo(response.data);
  }

  /** 選べる希望日取得 API
   *
   */
  public static async getBusinessdayList(): Promise<BusinessdayMasterDTOArray> {
    const response = await api.get<BusinessdayMasterDTOArray>(`/available-business-days`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data.map((item) => new BusinessdayMasterDTO(item));
  }
  /**
   * 残工事受付記録登録API
   *
   * @param remainingWorkReceptionRecord 残工事受付情報
   * @param remainingWorkStatus ステータス(１：受付 / ２：済み)
   */
  public static async remainingWorkReceptionRecord(remainingWorkReceptionRecord: RemainingWorkReceptionRecord): Promise<void> {
    await api.post(`/remaining-work-reception-record`, remainingWorkReceptionRecord).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
  }

  /** 残工事受付記録取得 API
   *
   * @param memberId 会員ID
   * @returns 残工事受付記録
   */
  public static async getRemainingWorkReceptionRecord(memberId: number): Promise<RemainingWorkReceptionRecord | null> {
    const response = await api.get<RemainingWorkReceptionRecord>(`/remaining-work-reception-record/members/${memberId}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    if (!response.data) return null;
    return new RemainingWorkReceptionRecord(response.data);
  }

  /** 残工事有無確認・受付ステータス更新 API
   *
   * @param uaType UA種別
   * @param propertyId 物件ID
   * @param roomNumber 部屋番号
   * @param isLogin ログイン状態
   * @returns 残工事の有無
   */
  public static async remainingWorkConfirm(uaType: string, propertyId: string, roomNumber: string, isLogin: boolean): Promise<RemainingWorkConfirmDto> {
    const data = { uaType: uaType, propertyId: propertyId, roomNumber: roomNumber, isLogin: isLogin };
    if (isLogin) {
      const response = await api.post<RemainingWorkConfirmDto>(`/remaining-work/confirm`, data).catch(async (error) => {
        await this.judgeError(error);
        console.log(error);
        throw error;
      });
      return response.data;
    } else {
      const response = await rawApi.post<RemainingWorkConfirmDto>(`/remaining-work/confirm-exp`, data).catch(async (error) => {
        await this.judgeError(error);
        console.log(error);
        throw error;
      });
      return response.data;
    }
  }
  /**
   * ISPクレジットカード情報登録API
   * @param uaType
   * @param veriTransMemberId
   * @param creditCardToken
   * @param tokenExpireDate
   */
  public static async registerIspCard(
    uaType: string,
    veriTransMemberId: string,
    creditCardToken: string,
    tokenExpireDate: string,
    serviceProviderIsp: string
  ): Promise<RegisterIspCardResponseDto> {
    const data = {
      uaType: uaType,
      creditCardToken: creditCardToken,
      tokenExpireDate: tokenExpireDate,
      serviceProviderIsp: serviceProviderIsp,
    };
    const response = await api.post<RegisterIspCardResponseDto>(`payment-methods/isp/${veriTransMemberId}`, data).catch(async (error) => {
      await this.judgeError(error);
      console.log(error);
      throw error;
    });
    return response.data;
  }

  /**
   * ISPクレジットカードコピーAPI(Portas→ISP)
   * @param uaType
   * @param veriTransMemberId
   * @param originalAccountId
   */
  public static async copyToIspCard(uaType: string, veriTransMemberId: string, originalAccountId: string, serviceProviderIsp: string): Promise<PaymentMethodCopyForIspResponse> {
    const data = {
      uaType: uaType,
      originalAccountId: originalAccountId,
      serviceProviderIsp: serviceProviderIsp,
    };
    const response = await api.post<PaymentMethodCopyForIspResponse>(`payment-methods/copy/isp/${veriTransMemberId}`, data).catch(async (error) => {
      await this.judgeError(error);
      console.log(error);
      throw error;
    });
    return response.data;
  }
  /**
   * ISPカード削除API
   * @param uaType
   * @param veriTransMemberId
   */
  public static async deleteIspCard(uaType: string, veriTransMemberId: string, serviceProviderIsp: string): Promise<void> {
    const data = {
      uaType: uaType,
      serviceProviderIsp: serviceProviderIsp,
    };
    const config = { data: data };
    const response = await api.delete(`payment-methods/isp/${veriTransMemberId}`, config).catch(async (error) => {
      await this.judgeError(error);
      console.log(error);
      throw error;
    });
  }
  /**
   * クレジットカード情報登録 API
   *
   * @param memberId
   * @param apartmentId
   * @param primaryKeyMye
   * @param uaType
   */
  public static async registerPaymentMethodInfo(
    memberId: string,
    myeMemberName: string,
    myeMailAddress: string,
    uaType: string,
    apartmentId: string,
    primaryKeyMye: string,
    servicePlanType: string
  ): Promise<RegisterPaymentMethodInfoResponse> {
    const config = { uaType: uaType, myeMemberName: myeMemberName, myeMailAddress: myeMailAddress, servicePlanType: servicePlanType };
    const response = await api.patch(`payment-methods/isp/${memberId}/${apartmentId}/${primaryKeyMye}`, config).catch(async (error) => {
      await this.judgeError(error);
      console.log(error);
      throw error;
    });
    return response.data;
  }

  /**
   * 営業日マスタ取得API（翌営業日基準※基準日当日を含めない）
   * @param businessdayKind 種別（0:TNC, 1:IPフォン, 2:SMBC, 3:残工事, 4:UCOM光電話）
   * @param baseDate 基準日
   * @param offset 開始オフセット（n営業日 n=1～）
   * @param range 期間（日数） 取得件数指定
   * @param displayFormat 日付文字列のフォーマット exp.YYYY年MM月DD日(ddd)
   * @param isLocaleJa 曜日を日本語で表示する場合true
   * @returns 営業日情報（営業日、表示文字列）リスト
   */
  public static async getBusinessdaysUtil(
    businessdayKind?: string,
    baseDate?: Date,
    offset?: number,
    range?: number,
    displayFormat?: string,
    isLocaleJp?: boolean
  ): Promise<BusinessdaysUtilDTOArray> {
    const data = {
      businessdayKind: businessdayKind ? businessdayKind : '0',
      baseDate: baseDate ? baseDate : new Date(),
      offset: offset == null ? 1 : offset,
      range: range == null ? 1 : range,
      displayFormat: displayFormat ? displayFormat : 'YYYY/MM/DD',
      isLocaleJp: isLocaleJp == null ? true : isLocaleJp,
    };
    console.log(`基準日:${baseDate?.toString()}`);
    const response = await api.get<BusinessdaysUtilDTOArray>(`/businessdays-util`, { params: data }).catch(async (error) => {
      await this.judgeError(error);
      console.log(error);
      throw error;
    });
    return response.data.map((item) => new BusinessdaysUtilDTO(item));
  }
  /**
   * Mye ID発番API
   * @param apartmentId 6桁の物件ID
   * @param uaType
   * @param kiknd
   */
  public static async createMyeId(apartmentId: string, uaType: string, kind: string): Promise<string> {
    const response = await api.get<string>(`/external/e-mansion/customers/${apartmentId}/id?ua_type=${uaType}&kind=${kind}`).catch(async (error) => {
      await this.judgeError(error);
      throw error;
    });
    return response.data;
  }
}
