<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>メールボックス容量変更 確認</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>メールボックス容量変更</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールボックス容量" />メールボックス容量</h2>
        <p>お申し込み内容をご確認いただき、問題ない場合は「申し込む」ボタンを押してください。</p>
      </div>
      <!-- /blc -->

      <div class="blc">
        <error-messages-component :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" />
      </div>
      <!-- /blc -->

      <div class="sblc">
        <table class="table-type2">
          <tbody>
            <tr>
              <th>アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr>
              <th>現在の容量</th>
              <td>{{ mailBoxCapacity }}.0GB</td>
            </tr>
            <tr>
              <th>追加／縮小容量</th>
              <td>
                <b>{{ changeCapacityLabel }}</b>
                <input type="hidden" :value="changeCapacityValue" />
              </td>
            </tr>
            <tr>
              <th>月額利用料</th>
              <td>9.0GB 毎に月額{{ price }}円（税込）<br />最大10.0GBまでご利用いただけます。</td>
            </tr>
            <tr>
              <th>お申し込み日</th>
              <td>{{ effectiveDate }}</td>
            </tr>
            <tr>
              <th>サービス説明</th>
              <td v-html="serviceDescription"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- /sblc -->
      <h3 v-if="is3dsecure" class="service-h3">UCOM光 レジデンスサービス お支払い方法</h3>
      <div v-if="is3dsecure">
        <div class="pd10 ml10">
          <p>UCOM光 レジデンスにご登録されている以下のお支払い方法により、お支払いいただきます。</p>
          <h3>クレジットカード</h3>
          <table v-if="is3dsecure" class="table-type2">
            <tbody>
              <tr>
                <th class="va-middle">カード番号</th>
                <td>{{ maskedCardNumberFromIsp }}</td>
              </tr>
              <tr>
                <th class="va-middle">カード期限</th>
                <td>{{ cardExpiredMonthOnPortas }}月 ／ {{ cardExpiredYearOnPortas }}年</td>
              </tr>
              <tr v-if="!isIspCardHolderName">
                <td>
                  <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
                </td>
                <td>
                  <div class="mt10">
                    <input
                      type="text"
                      class="text card-name"
                      v-model.trim="cardholderNameFormForAuthorizeIspCard"
                      required
                      minlength="2"
                      maxlength="45"
                      @change="inputCardholderNameFormForAuthorizeIspCard"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="blc">
        <p v-if="is3dsecure" class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
        </p>
        <div v-if="is3dsecure" class="my-button-area">
          <div class="has-checkbox">
            <div class="my-checkbox-outer">
              <input id="checkbox-to-agree" type="checkbox" v-model="isAgreeTerms" />
              <p class="my-checkbox-text"><label for="checkbox-to-agree">各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label></p>
            </div>
          </div>
        </div>
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="onBack()">
            <i class="material-icons link link-icon">west</i>
            戻る
          </button>
          <button v-if="is3dsecure" class="btn btn01 bs" :class="[isDisabledButton ? 'btn05' : 'btn01']" :disabled="isDisabledButton" v-on:click="onApply()">
            申し込む
            <i class="material-icons link link-icon">east</i>
          </button>
          <button v-if="!is3dsecure" class="btn btn01 bs" v-on:click="onApply()">
            申し込む
            <i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<style lang="scss" scoped>
.p-margin-top {
  margin-top: 10px;
}

.card-name {
  width: 100%;
  max-width: 360px;
}

div.my-button-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  text-align: center;
  padding-top: 35px;

  & div.has-checkbox {
    padding-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 10px;
      left: 0;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  div.my-button-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    margin-top: 16px;

    & div.has-checkbox {
      padding-top: 0;

      & div.my-checkbox-outer {
        top: 0;
        left: 50%;
        text-align: left;

        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1275px) {
  div.my-button-area {
    padding-top: 16px;
  }
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { UcomErrorResponse } from '../classes/external-api/error-response';
import { UcomPaymentStatusResponse } from '../classes/external-api/payment-status-response';

import { SpfApiMailboxCapacityAccessor } from '@/infra/accessor/spf/ucom/spf-api-mailbox-capacity-accessor';
import { UpdateUcomMbCapacityRequest } from '@/infra/request/spf/ucom/mailbox-capacity/update-ucom-mb-capacity-request';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { UcomMailboxCapacityConfirmInfo } from '@/shared/classes/spf-api/mail/model/ucom/mailbox-capacity/ucom-mailbox-capacity-confirm-info';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UCOM_PAYMENT_METHOD_ID } from '@/shared/const/ucom/ucom-payment-method-id';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomExternalApiService } from '@/shared/services/external-api/ucom-external-api-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { inputEventConvertToUpper } from '@/shared/util/inputEventConvertToUpper';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { Property } from '@/shared/classes/spf-api/property';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';

export default defineComponent({
  name: 'mailbox-capacity-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    /** UCOM_アカウントメールID */
    ucomAccountMailId: undefined as undefined | number,
    /** アカウントID */
    accountName: '',
    /** メールアドレス */
    mailAddress: '',
    /** メールボックス容量 */
    mailBoxCapacity: '',
    /** 追加／縮小容量ラベル */
    changeCapacityLabel: '',
    /** 追加／縮小容量バリュー */
    changeCapacityValue: '',
    /** 単価 */
    price: '',
    /** 申込日 */
    effectiveDate: '',
    /** サービス説明 */
    serviceDescription: '',
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部タイトル */
    errorMessageTitle: '',
    /** ローディング */
    isLoading: false,
    /** クレジットカードの判定 */
    is3dsecure: false,
    /** ISPにカード名義人名が登録されているか */
    isIspCardHolderName: false,
    /** カード名義人名 */
    cardholderName: '',
    /** 規約に同意する */
    isAgreeTerms: false,
    /** 連携先ISPで登録されたクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** Portasで登録されたクレジットカードの有効期限(月) */
    cardExpiredMonthOnPortas: '',
    /** Portasで登録されたクレジットカードの有効期限(年) */
    cardExpiredYearOnPortas: '',
    /** カード会員ID */
    paymentCardAccountId: '',
    cardholderNameFormForAuthorizeIspCard: '',
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    apid: '',
  }),
  async mounted() {
    this.isLoading = true;

    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 物件基本情報の物件IDを取得してapidへ変換
    const property: Property | null = this.$store.getters['propertyStore/property'];
    if (property?.apartmentId) {
      this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
    }

    try {
      // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
      }
      // 前画面からの情報をStoreから取得し画面データをセット
      await this.setDataByStore();

      // 3dセキュアの対象となるかの判定
      await this.checkPaymentInfo();

      // 戻るボタン押下時にstoreのucomAccountMailIdに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', undefined);
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },

    // storeから画面項目データをセット
    async setDataByStore(): Promise<void> {
      this.ucomAccountMailId = this.$store.getters['ucomMailboxCapacityStore/ucomAccountMailId'];
      this.accountName = this.$store.getters['ucomMailboxCapacityStore/accountName'];
      this.mailAddress = this.$store.getters['ucomMailboxCapacityStore/mailAddress'];
      this.mailBoxCapacity = this.$store.getters['ucomMailboxCapacityStore/mailBoxCapacity'];
      this.changeCapacityLabel = this.$store.getters['ucomMailboxCapacityStore/changeCapacityLabel'];
      this.changeCapacityValue = this.$store.getters['ucomMailboxCapacityStore/changeCapacityValue'];
      this.price = this.$store.getters['ucomMailboxCapacityStore/price'];
      this.effectiveDate = this.$store.getters['ucomMailboxCapacityStore/effectiveDate'];
      this.serviceDescription = this.$store.getters['ucomMailboxCapacityStore/serviceDescription'];
    },
    // 申し込むボタンクリック
    async onApply(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // エラーメッセージ初期化
      this.errorMessages = [];
      this.errorMessageTitle = '';

      // 会員情報取得
      const member = this.$store.getters['memberStore/member'];

      // ログイン確認処理
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 3dセキュアを実施する場合
      if (this.is3dsecure) {
        const member: Member = this.$store.getters['memberStore/member'];
        if (
          !this.isIspCardHolderName &&
          (!this.cardholderNameFormForAuthorizeIspCard || this.cardholderNameFormForAuthorizeIspCard?.length < 2 || this.cardholderNameFormForAuthorizeIspCard?.length > 45)
        ) {
          this.errorMessages.push('カード名義人を正しく入力してください。');
          this.isLoading = false;
          this.isAgreeTerms = false;
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }
        // 申込内容一時保存用 UUIDを生成
        const uuidForTemporarySavingApplicationData = uuidv4();
        const paymentMethodApplicationData = {
          memberId: member.id,
          ucomAccountMailId: this.ucomAccountMailId,
          changeCapacityLabel: this.changeCapacityLabel,
          changeCapacityValue: this.changeCapacityValue,
          effectiveDate: this.effectiveDate,
          mailAddress: this.mailAddress,
          accountName: this.accountName,
          mailBoxCapacity: this.mailBoxCapacity,
          price: this.price,
          serviceDescription: this.serviceDescription,
        };
        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'ucom-mail-box-capacity-change',
        });
        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
        }
        // 3dセキュア対応用 uuid localStorageに保存
        localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

        if (member.primaryKeyUcom) {
          if (uuidForTemporarySavingApplicationData !== null && this.paymentCardAccountId) {
            let cardHolderNameForAuthorize: string;
            if (this.cardholderName) {
              cardHolderNameForAuthorize = this.cardholderName;
            } else {
              cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
            }

            // VeriTrans_本人認証用のデータ格納
            const dddSecureAuthStartWithVeriTransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
              uuid: uuidForTemporarySavingApplicationData,
              externalVeritransAccountId: this.paymentCardAccountId,
              cardholderName: cardHolderNameForAuthorize,
            });
            // VeriTrans_本人認証(VeriTrans会員ID使用) API
            try {
              const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeriTransAccountIdRequest);
              location.href = authStartURL;
            } catch (error: any) {
              if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                this.errorMessages.push(
                  `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.ucomUrl}/${this.apid}/" target="_blank">こちら</a>から、サポートセンターまでお問い合わせください。`
                );
                this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
                this.isLoading = false;
                this.isAgreeTerms = false;
                // エラーメッセージを見せるために画面最上部にスクロール
                window.scrollTo(0, 0);
                return;
              } else {
                throw error;
              }
            }
          }
        }
        // 3dセキュアを実施しない場合（支払方法がクレジットカードではないか、容量減の場合） 容量変更処理を実施する
      } else {
        // 確認_更新API
        try {
          const requestDto = new UpdateUcomMbCapacityRequest(member.id, this.ucomAccountMailId!, this.changeCapacityLabel, this.changeCapacityValue, this.effectiveDate);
          const response = await SpfApiMailboxCapacityAccessor.updateUcomMbCapacity(requestDto);
          // エラー処理
          if (response.errorMessages && response.errorMessages.length > 0) {
            this.errorMessages = response.errorMessages;
            this.errorMessageTitle = '恐れ入りますが、内容をもう一度ご確認ください。';
            // エラーメッセージを見せるために画面最上部にスクロール
            this.scrollPageTop();
            this.isLoading = false;
            return;
          }
          // 確認更新API レスポンスをstoreに保存
          this.saveStore(response);

          // 完了画面へ遷移
          await this.$router.push('/ucom/mailbox-capacity/completed').catch((error: any) => {
            checkRouterError(error);
          });
          this.isLoading = false;
        } catch (e) {
          await this.$router.push('/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      }
    },
    // storeに保存
    saveStore(response: UcomMailboxCapacityConfirmInfo): void {
      this.$store.commit('ucomMailboxCapacityStore/notifyMailAddress', response.notifyMailAddress);
      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', this.ucomAccountMailId);
      this.$store.commit('ucomMailboxCapacityStore/accountName', this.accountName);
      this.$store.commit('ucomMailboxCapacityStore/mailBoxCapacity', response.mailCapacity);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityLabel', response.changeCapacityLabel);
      this.$store.commit('ucomMailboxCapacityStore/price', response.price);
      this.$store.commit('ucomMailboxCapacityStore/subscriptionDate', response.subscriptionDate);
      this.$store.commit('ucomMailboxCapacityStore/serviceDescription', this.serviceDescription);
    },
    // 戻るボタンクリック
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', this.ucomAccountMailId);
      await this.$router.push('/ucom/mailbox-capacity/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    inputCardholderNameFormForAuthorizeIspCard(event: Event) {
      this.cardholderNameFormForAuthorizeIspCard = inputEventConvertToUpper(event);
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },
    async checkPaymentInfo() {
      const member: Member = this.$store.getters['memberStore/member'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);

      // 容量増かどうかの確認
      const capacityIncrease = this.changeCapacityValue.includes('-') ? false : true;

      // 契約基本情報取得APIよりクレカの登録があった場合
      if (customer.is_register_credit_card && member.primaryKeyUcom) {
        // (外部 UCOM) お支払い情報登録状態取得 API
        const paymentStatus: UcomPaymentStatusResponse | UcomErrorResponse = await UcomExternalApiService.getPaymentStatus(member.primaryKeyUcom);
        // お支払方法がクレカであり、容量増の場合
        if (paymentStatus instanceof UcomPaymentStatusResponse && paymentStatus.payment_method_id === UCOM_PAYMENT_METHOD_ID.CREDIT_CARD && capacityIncrease) {
          this.paymentCardAccountId = paymentStatus.card_account_id;
          this.is3dsecure = true;
          if (paymentStatus.card_account_id) {
            const cardInfo = await this.getPaymentMethodFromExternalIsp(paymentStatus.card_account_id);
            this.maskedCardNumberFromIsp = this.addHyphenToCardNumber(cardInfo.cardNumber);
            this.cardholderName = cardInfo.cardholderName ?? '';
            // クレジットカードの年月取得
            this.cardExpiredMonthOnPortas = cardInfo.cardExpire.slice(0, 2);
            this.cardExpiredYearOnPortas = cardInfo.cardExpire.slice(-2);
            // カード名義人判定
            if (cardInfo.cardholderName) {
              this.isIspCardHolderName = true;
            }
          }
        }
      }
    },
    // ページトップへスクロール
    scrollPageTop(): void {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    // チェックボックスによる「申し込む」ボタンの判定
    isDisabledButton(): boolean {
      if (this.isAgreeTerms) {
        return false;
      }
      return true;
    },
  },
  watch: {
    cardholderNameFormForAuthorizeIspCard: {
      handler: function (newVal, oldVal) {
        if (newVal && !newVal.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.cardholderNameFormForAuthorizeIspCard = oldVal;
        }
      },
    },
  },
});
</script>
