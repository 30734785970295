<template>
  <div class="test">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion i-フィルター</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion 会員専用トップ</router-link>
        </li>
        <li>i-フィルター</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="i-フィルター　ご利用状況" />i-フィルター ご利用状況</h2>
      </div>
      <table v-if="isBeforeSIN" class="table-type2 usage-div">
        <tbody>
          <tr>
            <th class="va-middle"><b>ご利用状況</b></th>
            <th class="va-middle"><b>インストール先コンピューター名</b></th>
            <th class="va-middle"><b>お申し込み日</b></th>
          </tr>

          <tr v-for="(ift, index) in iftArrayForDisplay" :key="index">
            <!-- ご利用状況 -->
            <td data-label="ご利用状況">登録申請中</td>

            <!-- インストール先コンピューター名 -->
            <td data-label="インストール先コンピューター名" v-if="ift.isComputer && ift.isComputer.length">
              <div v-if="ift.isComputer">
                <div v-for="(computer, index) in ift.isComputer" :key="index">
                  {{ computer }}
                </div>
              </div>
            </td>
            <td data-label="インストール先コンピューター名" v-else>　</td>

            <!-- ご利用状況 -->
            <td data-label="お申し込み日" v-if="ift.entryDate">
              <span>
                {{ formatDate(ift.entryDate) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table v-else class="table-type2 usage-div">
        <tbody>
          <tr>
            <th class="va-middle"><b>契約番号</b></th>
            <th class="va-middle"><b>ご利用状況</b></th>
            <th class="va-middle"><b>インストール先コンピューター名</b></th>
            <th class="va-middle"><b>ダウンロード</b></th>
            <th class="va-middle"><b>お申し込み日</b></th>
          </tr>

          <!-- データ行 -->
          <tr v-for="(ift, index) in iftArrayForDisplay" :key="index">
            <!-- 契約番号 -->
            <td data-label="契約番号" v-if="ift.contractID">{{ ift.contractID }}</td>
            <td data-label="契約番号" v-else>　</td>

            <!-- ご利用状況 -->
            <td data-label="ご利用状況">
              <span v-if="ift.usageStatus === '10'">登録申請中</span>
              <span v-else-if="ift.usageStatus === '12'">ご利用中</span>
              <span v-else-if="ift.usageStatus === '20'">
                解約登録中 サービス終了日: <span v-if="ift.cancelDate">{{ ift.cancelDate }}</span>
              </span>
            </td>

            <!-- インストール先コンピューター名 -->
            <td data-label="インストール先コンピューター名" v-if="ift.isComputer && ift.isComputer.length">
              <div v-if="ift.isComputer">
                <div v-for="(computer, index) in ift.isComputer" :key="index">
                  {{ computer }}
                </div>
              </div>
            </td>
            <td data-label="インストール先コンピューター名" v-else>　</td>

            <td data-label="ダウンロード">
              <div v-if="ift.usageStatus === '20' || ift.usageStatus === '12'">
                <p>{{ ift.cerealId }}</p>
                <p>→<a :href="ift.downloadUrl" class="link" target="downloadUrl">ダウンロード</a></p>
              </div>
            </td>

            <td data-label="お申し込み日" v-if="ift.entryDate">
              <span>
                {{ formatDate(ift.entryDate) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="iftArrayForDisplay.find((ift) => ift.usageStatus === '20' || ift.usageStatus === '12') && !isBeforeSIN">
        <div class="specific-container">
          <table class="table-type2">
            <tr>
              <td colspan="2" class="terms-for-support-title">
                <h3>プログラムのインストールなど技術的なお問合せ：デジタルアーツ株式会社サポートセンター</h3>
              </td>
            </tr>
            <tr>
              <td>電話番号</td>
              <td class="adjust-button-margin">0570-00-1334 （10:00～18:00）※年末年始を除く</td>
            </tr>
            <tr>
              <td>e-mail</td>
              <td class="adjust-button-margin">ifmonsc@daj.co.jp</td>
            </tr>
            <tr>
              <td colspan="2">
                <p>※お問合せ時に、「シリアルID」が必要となります。</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="btn-area">
        <button class="btn btn01 bs" type="button" @click="onAdditionalApply">追加のお申し込み<i class="material-icons link link-icon">east</i></button>
        <button class="btn btn01 bs" type="button" v-if="isBeforeSIN">
          <a :href="eMansionIftUrl" class="link" target="_blank">インストール先コンピューター名の変更・キャンセル</a>
          <i class="material-icons link link-icon">east</i>
        </button>
        <button class="btn btn01 bs" type="button" v-else>
          <a :href="eMansionIftUrl" class="link" target="_blank">インストール先コンピューター名の変更・解約</a>
          <i class="material-icons link link-icon">east</i>
        </button>

        <div>
          <button v-if="isBeforeSIN" class="btn btn05 bs back-button-margin-sin" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button v-else class="btn btn05 bs back-button-margin" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
.btn-area {
  text-align: center;
  button {
    margin-top: 10px;
  }
  a,
  .link:hover {
    text-decoration: none;
    color: #ffffff;
  }
}

/* デフォルトスタイル */
.table-type2 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

/* テーブル全体と各セルに縦線を追加 */
.table-type2 th,
.table-type2 td {
  border-right: 1px solid #ddd;
}

/* 最後の列の縦線を消す */
.table-type2 th:last-child,
.table-type2 td:last-child {
  border-right: none;
}

.usage-div {
  border-left: 0;
  border-right: 0;
  margin-top: 50px;
}

.adjust-padding-td {
  padding: 10px;
  text-align: left;
  background-color: #ffffff;
}

.adjust-button-margin {
  margin-top: 10px;
}

div.template {
  margin-top: 10px;
}

.back-button-margin-sin {
  margin-right: 190px;
}
.back-button-margin {
  margin-right: 190px;
}
/* モバイル用スタイル */
@media (max-width: 767px) {
  /* .specific-container の内側を除外する */
  .table-type2:not(.specific-container .table-type2),
  .table-type2:not(.specific-container .table-type2) thead,
  .table-type2:not(.specific-container .table-type2) tbody,
  .table-type2:not(.specific-container .table-type2) th,
  .table-type2:not(.specific-container .table-type2) td,
  .table-type2:not(.specific-container .table-type2) tr {
    border-left: 0 !important;
    border-right: 0 !important;
  }

  .table-type2:not(.specific-container .table-type2) tr {
    margin-bottom: 15px;
  }

  .table-type2:not(.specific-container .table-type2) th {
    display: none;
  }

  .table-type2:not(.specific-container .table-type2) td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ddd;
  }

  .table-type2:not(.specific-container .table-type2) td:before {
    content: attr(data-label);
    font-weight: bold;
    width: 50%;
  }
}

@media screen and (max-width: 984px) {
  .back-button-margin {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 1028px) {
  .back-button-margin-sin {
    margin-right: 0 !important;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import store from '@/store';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import dayjs from 'dayjs';
import { UsageifilterDTO } from '@/shared/classes/spf-api/e-mansion-usage-i-filter-address-dto';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { MountedCheckService } from '@/shared/services/mounted-check-service';

export default defineComponent({
  name: 'i-filter-usage',
  components: {
    LoadingComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** 契約基本情報取得API.オプション利用状況 */
    iftArrayForDisplay: [] as UsageifilterDTO[],
    /** 追加申し込みボタンの表示
    /** e-mantion My の URL */
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    property: null,
    /**追加のお申込みのURLで使用するapid */
    apid: '',
    /**追加のお申込みのURL */
    eMansionIftUrl: '',
    /**ランダムで設定する9桁の数字を当てはめる */
    rd: '',
    // 契約基本情報取得API.オプション利用状況.i-フィルター.利用状況 に値が存在するかどうか
    isFindOption: false,
    envConfirmUrl: '',
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    downloadUrl: [] as UsageifilterDTO[],
    // 会員ステータス
    memberStatus: MemberStatus,
    // 会員情報
    member: Member,
    // SIN前かどうか
    isBeforeSIN: false,
  }),
  async mounted() {
    this.isLoading = true;
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    // /** 物件基本情報をStoreから取得 */
    const eMansionProperty: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];

    // 物件のサービスイン日が未来日時の場合はSIN前の利用画面
    const emSinTime = eMansionProperty.em_sin_time;
    if (new Date(parseInt(emSinTime.slice(0, 4), 10), parseInt(emSinTime.slice(4, 6), 10) - 1, parseInt(emSinTime.slice(6, 8), 10)) > new Date()) {
      this.isBeforeSIN = true;
    }

    /** 契約基本情報をStoreから取得 */
    const eMansionCustomer: EMansionCustomer = await store.getters['eMansionCommonStore/customer'];

    /* 契約基本情報取得API.オプション利用状況.i-フィルターに要素が存在しない場合、
     i-フィルター 規約画面へリダイレクトさせる */
    const hasStatus = eMansionCustomer.op.ifilter.some((filter) => filter.status);
    if (!hasStatus) {
      this.$router.push('/e-mansion/i-filter/terms').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      if (eMansionCustomer) {
        if (eMansionCustomer.op.ifilter.length !== 0) {
          for (const getIftinfo of eMansionCustomer.op.ifilter) {
            //解約日時のフォーマット
            let cancelDate = '';
            if (getIftinfo.end_time !== '' && getIftinfo.end_time !== null && getIftinfo.end_time !== undefined) {
              cancelDate = dayjs(getIftinfo.end_time).format('YYYY/MM/DD');
            }

            const formattedIftArray = new UsageifilterDTO({
              //i-フィルター契約番号
              contractID: getIftinfo.subscription_id ?? '',
              //i-フィルターご利用状況
              usageStatus: getIftinfo.status,
              //i-フィルターインストール先コンピューター
              isComputer: getIftinfo.service_memo,
              //i-フィルターダウンロードURL
              downloadUrl: getIftinfo.download_url ?? '',
              //i-フィルター利用日、解約日
              cancelDate: cancelDate,
              //i-フィルター利用日、契約日時
              entryDate: getIftinfo.request_time ?? '',
              //i-フィルター シリアルナンバー
              cerealId: getIftinfo.ticket ?? '',
            });
            this.iftArrayForDisplay.push(formattedIftArray);
          }
        }
      }
      // 9桁のランダムな数字を取得
      this.rd = this.createRd();
      // apid追加
      this.apid = eMansionProperty.IN_APID;
      // 追加のお申込みのURLを定義
      this.eMansionIftUrl = `${this.eMansionMyUrl}/login/login.php?function=I_FILTER&apid=${this.apid}&rd=${this.rd}`;
      //ソフトの動作環境の確認のURL
      this.envConfirmUrl = `${this.eMansionUrl}/${this.apid}/service/security/ifilter.html`;
      //ローディングの判定
      this.isLoading = false;
    } catch (error: any) {
      checkRouterError(error);
    }

    this.isLoading = false;
  },
  methods: {
    async onBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion')
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
      this.isLoading = false;
      return;
    },
    async onAdditionalApply() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion/i-filter/terms')
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });

      this.isLoading = false;
      return;
    },

    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    formatDate(dateString: string) {
      const date = new Date(dateString);
      return date.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' });
    },
  },
});
</script>
