<template>
  <div class="hikariphone-input">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/e-mansion">e-mansion 会員専用トップ</a></li>
        <li>UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">お申し込み</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <div class="my-error-messages-component-wrapper">
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOM光電話 お申し込み" />UCOM光電話 お申し込み</h2>

        <p>UCOM光電話のお申し込みお手続きを行います。</p>
        <div class="blc-spacing">
          <h3 class="service-h3">専用アダプタについて</h3>
          <p>ご契約のマンション住所に専用アダプタをお送りします。</p>
          <p>インターネット接続サービスご利用開始後に、専用アダプタを接続して下さい。</p>
        </div>
        <div class="blc-spacing">
          <h3 class="service-h3">ご利用開始日について</h3>
          <p>お申し込み受け付け後、「開通通知書」をお送りします。</p>
          <p>開通通知書に記載の「利用開始日」がご請求開始の起算日となります。</p>
          <div>
            <p class="">＜利用開始日の目安＞</p>
            <ul class="custom-list-spacing">
              <li>新規番号の場合：お申し込みから約1～2週間</li>
              <li>番号ポータビリティの場合：お申し込みから約2～3週間</li>
            </ul>
          </div>
          <div>
            <p><b>ご契約住所で専用アダプタをお受け取りになれる日にち以降でご利用希望日を選択してください。</b></p>
            <p>ご利用希望日は利用開始日と必ずしも一致いたしません。あらかじめご了承ください。</p>
          </div>
          <div>
            <table class="table-type2">
              <tr>
                <th>
                  <b>ご利用希望日</b>
                  <span class="req">必須</span>
                </th>
                <td>
                  <p>※新規番号の場合：{{ serviceStartDateForRegistration }}以降、ご指定可能です。</p>
                  <p>※番号ポータビリティの場合：{{ serviceStartDateForNumberPortability }}以降、ご指定可能です。</p>
                  <input
                    type="date"
                    class="text middle display-date"
                    name="preferredDate"
                    v-model="inputData.preferredDate"
                    v-bind:min="preferredDateMin"
                    v-bind:max="preferredDateMax"
                  />
                </td>
              </tr>
            </table>
            <p>※ご利用希望日は、ご契約マンションの「鍵のお引渡し日」以降で選択いただけます。</p>
            <p>※番号ポータビリティの場合、年末年始は受け付けられない場合があります。</p>
          </div>
        </div>
        <div class="blc-spacing">
          <h3 class="service-h3">電話番号取得方法</h3>
          <table class="table-type2">
            <tr>
              <th>
                <b>電話番号取得方法</b>
                <span class="req">必須</span>
              </th>
              <td>
                <label
                  ><p><input type="radio" value="1" v-model="inputData.selectedGetPhoneMethod" /> 新しく取得（発行手数料無料）</p></label
                >
                <label
                  ><p>
                    <input type="radio" value="2" v-model="inputData.selectedGetPhoneMethod" /> 番号ポータビリティ（切り替え手数料 {{ formatMoney(numberPortabilityFee) }}円）
                  </p></label
                >
              </td>
            </tr>
          </table>
          <div v-if="displayNumberPortabilityItem">
            <h3 class="service-h3">番号ポータビリティ必要項目</h3>
            <table class="table-type2">
              <tr>
                <th>
                  <b>引継ぎ電話番号</b>
                  <span class="req">必須</span>
                </th>
                <td>
                  <input type="text" class="text middle" placeholder="例) 03-1234-5678" v-model="inputData.takeOverPhoneNumber" />
                </td>
              </tr>
              <tr>
                <th>
                  <b>名義人区分</b>
                  <span class="req">必須</span>
                </th>
                <td>
                  <label><input class="radiobutton" type="radio" value="0" v-model="relationshipType" />本人</label>
                  <label><input class="radiobutton" type="radio" value="1" v-model="relationshipType" />本人以外</label>
                </td>
              </tr>
              <tr>
                <th>
                  <b>名義人</b>
                  <span class="req">必須</span>
                </th>
                <td>
                  <input type="text" class="text middle" placeholder="例）山田次郎" v-model="inputData.holderName" />
                </td>
              </tr>
              <tr>
                <th>
                  <b>名義人カナ</b>
                  <span class="req">必須</span>
                </th>
                <td>
                  <input type="text" class="text middle" placeholder="例）ヤマダジロウ" v-model="inputData.holderNameKana" />
                </td>
              </tr>
              <tr>
                <th>
                  <b>ご利用中の電話会社</b>
                  <span class="req">必須</span>
                </th>
                <td>
                  <span class="i">
                    <select class="form-select" v-model="inputData.telephoneCompanyType">
                      <option disabled value="00">選択してください</option>
                      <option v-for="carrier in carrierList" :key="carrier.value" :value="carrier.value">{{ carrier.name }}</option>
                    </select>
                  </span>
                  <p>「その他」を選択した場合は会社名をご入力ください</p>
                  <input type="text" class="text middle" v-model="inputData.otherCompanyName" />
                </td>
              </tr>
              <tr>
                <th>
                  <b>引継電話番号の登録住所</b>
                </th>
                <td>
                  <p>引き継ぎたい電話番号の登録住所がお引越し前の住所の場合は必ずご入力ください</p>
                  <p class="billing-address-td">
                    〒
                    <input type="text" class="text short" placeholder="000" maxlength="3" v-model="zipcode1" />
                    <span> - </span>
                    <input type="text" class="text short" placeholder="0000" maxlength="4" v-model="zipcode2" />
                    <button @click="searchAddressByZipcode()" class="apartment-search-button">住所検索</button>
                  </p>
                  <p class="va-middle">ご住所（市町村名や番地まで入力してください）</p>
                  <p class="billing-address-td">
                    <input type="text" class="text large" placeholder="例）東京都千代田区大手町２－２－１（全角３０文字以内）" v-model="address" />
                  </p>
                  <p class="va-middle">ご住所（マンション名やお部屋番号などあれば入力してください）</p>
                  <p class="billing-address-td">
                    <input type="text" class="text large" placeholder="例）つなぐマンション６２０号室（全角６０文字以内）" v-model="inputData.apartment" />
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="blc-spacing">
          <h3 class="service-h3">付加サービス（有料）</h3>
          <label
            ><p><input type="checkbox" class="toggle" value="toggle01" v-model="selectedOptionalService" /> (1)着信番号表示：月額 {{ formatMoney(displayNumberFee) }}円</p></label
          >
          <label
            ><p><input type="checkbox" class="toggle" value="toggle02" v-model="selectedOptionalService" /> (2)キャッチ通話：月額 {{ formatMoney(callWaitingFee) }}円</p></label
          >
          <label
            ><p>
              <input type="checkbox" class="toggle" value="toggle03" v-model="selectedOptionalService" /> (3)キャッチ通話番号表示：月額
              {{ formatMoney(displayNumberForCallWaitingFee) }}円
            </p></label
          >
          <label
            ><p>
              <input type="checkbox" class="toggle" value="toggle04" v-model="selectedOptionalService" /> (4)オプションサービスパック：月額
              {{ formatMoney(optionalServicePackFee) }}円
            </p></label
          >
          <p>※「(3)キャッチ通話番号表示」は「(2)キャッチ通話」とセットでお申し込み/ご利用ください。</p>
          <p>※「(4)オプションサービスパック」は(1)、(2)、(3)をすべて含んだパックメニューとなります。</p>
        </div>
        <div class="blc-spacing">
          <h3 class="service-h3">番号通知（無料）</h3>
          <table class="table-type2">
            <tr>
              <th>
                <b>番号通知</b>
                <span class="req">必須</span>
              </th>
              <td>
                <label><input type="radio" value="1" v-model="inputData.isNotifyNumber" /> 相手に通知する</label>
                <label><input type="radio" value="2" v-model="inputData.isNotifyNumber" /> 相手に通知しない</label>
              </td>
            </tr>
          </table>
          <p>価格は全て新税率に基づく税込み表示（消費税10%）です。</p>
          <p>今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
        </div>
      </div>

      <div class="blc">
        <p class="form-btn-txt"><b>ご確認の上、よろしければ「申し込む」をクリックしてください。</b></p>
        <div class="btn-area">
          <button class="btn btn05 bs" v-on:click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs sp-margin" :disabled="isDisabledButton" v-on:click="onNext()">申し込む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.text-align-center {
  text-align: center;
}
.condition {
  margin: 0 0 5px;
  border-left: 3px solid #cf1225;
  padding-left: 15px;
  margin-left: 10px;
}
div.template {
  margin-top: 30px;
}
div.blc-spacing {
  margin-top: 60px;
}
.custom-list-spacing li {
  margin: 10px 0px;
}

.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}
.apartment-search-button {
  appearance: none;
  border: 0;
  border-radius: 2px;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
  border: 2px solid #cf1225;
  padding: 10px 25px;
  line-height: 14px;
  font-size: 14px;
  margin: 0 5px;
  top: 2px;
  box-sizing: border-box;
  position: relative;
}
input.display-date {
  width: 150px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #ffffff;
}
select.form-select {
  min-width: 190px;
}
button.btn {
  margin-top: 10px;
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, required } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-account-accessor';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { BusinessdayMasterDTOArray } from '@/shared/classes/spf-api/available-business-days-dto';
import { eMansionNotifyMailAddressCheckRequest } from '@/shared/classes/spf-api/mail/model/account/e-mansnion-notify-mailaddress-check-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { fullKanaAndBlank, fullWidth, halfWidth, halfWidthNum, phoneStyle } from '@/shared/util/validators';
import { PHONE_USE_COMPANY } from '@/shared/const/ucom/phone-use-company';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { convertKanaAndAscii } from '@/shared/util/change-to-full-width-characters';

/** UCOM光電話 お申し込み コンポーネント */
export default defineComponent({
  name: 'hikari-phone-input',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    // ロード中
    isLoading: true,
    // 会員情報
    member: null as Member | null,
    // 会員ステータス
    memberStatus: null as MemberStatus | null,
    // 営業日
    businessDayList: null as BusinessdayMasterDTOArray | null,
    // 新規番号用ご利用開始日
    serviceStartDateForRegistration: '',
    // 番号ポータビリティ用ご利用開始日
    serviceStartDateForNumberPortability: '',
    //SIN日文字列
    sinDateStr:'',
    // 切り替え手数料
    numberPortabilityFee: '',
    // 電話会社キャリア
    carrierList: PHONE_USE_COMPANY,
    // 郵便番号入力用
    zipcode1: '',
    zipcode2: '',
    // 住所
    address: '',
    // 着信番号表示利用料
    displayNumberFee: '',
    // キャッチ通話利用料
    callWaitingFee: '',
    // キャッチ通話番号表示利用料
    displayNumberForCallWaitingFee: '',
    // オプションサービスパック利用料
    optionalServicePackFee: '',
    // e-mansion サイト URL
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    // 8桁APID
    apid: '',
    // 物件情報
    property: null as Property | null,
    // サービスイン日
    sin_time: '',
    // 「次へ」ボタンの活性・非活性制御
    isDisabledButton: true,
    // 名義人区分
    relationshipType: null as string | null,
    // 付加オプションサービス
    selectedOptionalService: [] as string[],
    // フォームの入力内容
    inputData: {
      // ご利用希望日
      preferredDate: null as Date | null,
      // 電話番号取得方法
      selectedGetPhoneMethod: null as string | null,
      // 番号通知
      isNotifyNumber: '',
      // 引継ぎ電話番号
      takeOverPhoneNumber: '',
      // 名義人
      holderName: '',
      // 名義人カナ
      holderNameKana: '',
      // 利用中の電話会社
      telephoneCompany: '',
      // 郵便番号
      zipcode: '',
      // 住所
      address: '',
      // マンション名や部屋番号等
      apartment: '',
      // 名義人区分
      relationshipType: null as string | null,
      // 付加オプションサービス
      selectedOptionalService: [] as string[],
      // 利用中の電話会社
      telephoneCompanyType: '',
      // その他の電話会社キャリア
      otherCompanyName: '',
    },
    // ご利用希望日の選択可能日時min
    preferredDateMin: '',
    // ご利用希望日の選択可能日時max
    preferredDateMax: '',
  }),
  /** バリデーション定義 */
  validations() {
    // YYYY-DD-MM形式の正規表現バリデーション
    const isCorrectFormat = (value: string) => /^\d{4}\-\d{2}\-\d{2}$/.test(value);
    // 文字数指定
    const exactLength = (length: number) => helpers.withParams({ length }, (value: string) => value.length === length);

    return {
      relationshipType: {
        required,
      },
      inputData: {
        preferredDate: {
          required,
          isCorrectFormat,
        },
        selectedGetPhoneMethod: {
          required,
        },
        isNotifyNumber: {
          required,
        },
        takeOverPhoneNumber: {
          required,
          phoneStyle,
        },

        holderName: {
          required,
          maxLength: maxLength(30),
          fullWidth,
        },
        holderNameKana: {
          required,
          maxLength: maxLength(30),
          fullKanaAndBlank,
        },
        telephoneCompany: '',
        zipcode: {
          halfWidthNum,
          exactLength: exactLength(7),
        },
        address: {
          required,
          maxLength: maxLength(30),
        },
        apartment: {
          maxLength: maxLength(60),
        },
        telephoneCompanyType: {
          required,
        },
        otherCompanyName: {
          required,
          maxLength: maxLength(32),
          fullWidth,
        },
      },
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  //ログインチェック、物件チェック、ほか、確かめる内容を追加
  async mounted(): Promise<void> {
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    this.member = this.$store.getters['memberStore/member'];
    this.memberStatus = this.$store.getters['memberStore/memberStatus'];
    if (!this.memberStatus) {
      // 会員情報が取得できない場合、総合トップに遷移
      this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    // 物件情報を取得する
    const property = this.$store.getters['propertyStore/property'];

    try {
      if (property?.apartmentId) {
        this.apid = convert6DigitIdTo8DigitId(property?.apartmentId);
      }
      await StoreExternalApiResponse.main();
      /** 物件基本情報をStoreから取得 */
      const eMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        this.numberPortabilityFee = this.removeComma(eMansionProperty.op.hikariphone.numberportability_fee);
        this.displayNumberFee = this.removeComma(eMansionProperty.op.hikariphone.numberdisplay_fee);
        this.callWaitingFee = this.removeComma(eMansionProperty.op.hikariphone.catch_fee);
        this.displayNumberForCallWaitingFee = this.removeComma(eMansionProperty.op.hikariphone.catch_display_fee);
        this.optionalServicePackFee = this.removeComma(eMansionProperty.op.hikariphone.servicepack_fee);
        this.sin_time = eMansionProperty.em_sin_time;
      } else {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.E_MANSION_API_PROPERTY_INFO);
      }

      // 入力内容があればStoreから取得
      const isFromServiceTerms = this.$store.getters['eMansionHikariphoneStore/from'];
      if (this.$store.getters['eMansionHikariphoneStore/inputData'] && !isFromServiceTerms) {
        this.inputData = this.$store.getters['eMansionHikariphoneStore/inputData'];
        this.address = this.inputData.address;
        if (this.inputData.zipcode) {
          this.zipcode1 = this.inputData.zipcode.slice(0, 3);
          this.zipcode2 = this.inputData.zipcode.slice(-4, 7);
        }
        this.relationshipType = this.inputData.relationshipType;
        this.selectedOptionalService = this.inputData.selectedOptionalService;
      }

      // 指定可能なご利用開始日を取得
      await this.getAvailableServiceStartDate();

      // 通知メールアドレスチェックAPIを実行
      const primaryKeyMye = this.member!.primaryKeyMye;
      const apartmentId = property.apartmentId;
      const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      const uaType = property.uaType;
      const chechNotifyAccount = await SpfApiAccountAccessor.getEMansionAccountNotifyEmail(
        new eMansionNotifyMailAddressCheckRequest(primaryKeyMye!, apartmentId!, servicePlanType!, uaType!)
      );
      if (chechNotifyAccount) {
        this.errorMessages.push(String(chechNotifyAccount.messages));
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      } else {
        // もし通知先メールチェックAPIエラーがなければボタン有効化
        this.isDisabledButton = false;
      }

      this.isLoading = false;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }
  },
  methods: {
    async validate(): Promise<boolean> {
      if (this.v$.inputData.preferredDate?.required.$invalid || this.v$.inputData.preferredDate?.isCorrectFormat.$invalid) {
        this.errorMessages.push('正しい「ご利用希望日」を入力してください。');
      } else if (await this.isBeforeAvailableServiceStartDate()) {
        this.errorMessages.push('ご利用希望日が指定可能な日付より前になっています。');
      } else if (this.inputData.selectedGetPhoneMethod === '2' && !(await this.isBusinessDay())) {
        this.errorMessages.push('番号ポータビリティの場合「ご利用希望日」は営業日を指定してください。');
      }
      if (this.v$.inputData.selectedGetPhoneMethod?.required.$invalid) {
        this.errorMessages.push('「電話番号取得方法」を選択してください。');
      }

      this.inputData.selectedOptionalService = this.selectedOptionalService;
      const OpServiceWithout04 = ['toggle01', 'toggle02', 'toggle03'];
      if (this.inputData.selectedOptionalService.includes('toggle03') && !this.inputData.selectedOptionalService.includes('toggle02')) {
        this.errorMessages.push('(3)キャッチ通話番号表示をお申し込みの場合は、(2)キャッチ電話もあわせてお申し込みください。');
      }
      if (this.inputData.selectedOptionalService.includes('toggle04') && this.inputData.selectedOptionalService.some((service) => OpServiceWithout04.includes(service))) {
        this.errorMessages.push('(4)オプションサービスパックをお申し込みの場合は、他の付加サービスを選択しないでください。');
      }
      if (OpServiceWithout04.every((service) => this.inputData.selectedOptionalService.includes(service))) {
        this.errorMessages.push('(1)(2)(3)の同時申し込みはできません。(4)オプションサービスパックをお申し込みください。');
      }
      if (this.v$.inputData.isNotifyNumber?.required.$invalid) {
        this.errorMessages.push('「番号通知」を選択してください。');
      }
      if (this.inputData.selectedGetPhoneMethod === '2') {
        if (this.v$.inputData.takeOverPhoneNumber?.required.$invalid) {
          this.errorMessages.push('「引継ぎ電話番号」を入力してください。');
        } else if (this.v$.inputData.takeOverPhoneNumber?.phoneStyle.$invalid) {
          this.errorMessages.push('「引継ぎ電話番号」は半角数字で正しく入力してください。');
        }

        this.inputData.relationshipType = this.relationshipType;
        if (this.v$.relationshipType?.required.$invalid) {
          this.errorMessages.push('「名義人区分」を選択してください。');
        }
        if (this.v$.inputData.holderName?.required.$invalid) {
          this.errorMessages.push('「名義人」を入力してください。');
        } else if (this.v$.inputData.holderName?.maxLength.$invalid) {
          this.errorMessages.push('「名義人」を全角30文字以内で入力してください。');
        } else if (this.v$.inputData.holderName?.fullWidth.$invalid) {
          this.errorMessages.push('「名義人」を全角30文字以内で入力してください。');
        }
        if (this.v$.inputData.holderNameKana?.required.$invalid) {
          this.errorMessages.push('「名義人カナ」を入力してください。');
        } else if (this.v$.inputData.holderNameKana?.maxLength.$invalid) {
          this.errorMessages.push('「名義人カナ」を全角30文字以内で入力してください。');
        } else if (this.v$.inputData.holderNameKana?.fullKanaAndBlank.$invalid) {
          this.errorMessages.push('「名義人カナ」を全角30文字以内で入力してください。');
        }
        if (this.v$.inputData.telephoneCompanyType?.required.$invalid) {
          this.errorMessages.push('「ご利用中の電話会社」を選択してください。');
        }
        if (this.inputData.telephoneCompanyType === '98') {
          if (this.v$.inputData.otherCompanyName?.required.$invalid) {
            this.errorMessages.push('「ご利用中の電話会社「会社名」」を入力してください。');
          } else if (this.v$.inputData.otherCompanyName?.maxLength.$invalid) {
            this.errorMessages.push('「ご利用中の電話会社「会社名」」を全角32文字以内で入力してください。');
          } else if (this.v$.inputData.otherCompanyName?.fullWidth.$invalid) {
            this.errorMessages.push('「ご利用中の電話会社「会社名」」を全角32文字以内で入力してください。');
          }
        }

        // 引継電話番号の登録住所がある場合かつ、郵便番号がどれかorどっちも入力されていない場合
        if ((this.inputData.address && !(this.zipcode1 && this.zipcode2)) || (this.zipcode1 && !this.zipcode2) || (!this.zipcode1 && this.zipcode2)) {
          this.errorMessages.push('「「引継電話番号の登録住所」郵便番号」を入力してください。');
          // 郵便番号がどちらも入力されている場合かつ郵便番号の桁数が不正、または半角数字ではない場合
        } else if (this.zipcode1 && this.zipcode2) {
          this.inputData.zipcode = `${this.zipcode1}${this.zipcode2}`;
          if (this.v$.inputData.zipcode?.exactLength.$invalid || this.v$.inputData.zipcode?.halfWidthNum.$invalid) {
            this.errorMessages.push('「「引継電話番号の登録住所」郵便番号」は半角数字7文字で入力してください。');
          }
        }
        // 郵便番号がどちらも入力されている場合かつ住所が入力されていない場合
        if ((this.zipcode1 || this.zipcode2) && this.v$.inputData.address.required.$invalid) {
          this.errorMessages.push('「「引継電話番号の登録住所」ご住所（1）」を入力してください。');
        } else if (this.inputData.address) {
          if (this.v$.inputData.address?.maxLength.$invalid) {
            this.errorMessages.push('「「引継電話番号の登録住所」ご住所（1）」は全角30文字以内で入力してください。');
          }
        }

        if (this.v$.inputData.apartment?.maxLength.$invalid) {
          this.errorMessages.push('「「引継電話番号の登録住所」ご住所（2）」は全角60文字以内で入力してください。');
        }
      }

      if (this.errorMessages.length > 0) {
        return false;
      }
      return true;
    },
    // 5営業日または物件のSIN日の遅い方の日付を新規番号のご利用開始日、9営業日または物件のSIN日の遅い方の日付を番号ポータビリティのご利用開始日とする
    async getAvailableServiceStartDate(): Promise<void> {
      // 営業日マスタAPIに送る値はUTC
      const today = new Date();

      /** 営業日マスタから営業日を取得 */
      try {
        //営業日マスタから営業日（UCOM光電話）の5営業日後を取得
        const after5Date = new Date((await SpfApiService.getBusinessdaysUtil('0', today, 5, 1, 'YYYY/MM/DD', false))[0].businessDate);

        //営業日マスタから営業日（UCOM光電話）の9営業日後を取得
        const after9Date = new Date((await SpfApiService.getBusinessdaysUtil('0', today, 9, 1, 'YYYY/MM/DD', false))[0].businessDate);

        // SIN日
        const splitSinDateString = this.sin_time.split(' ');
        const sinYear = splitSinDateString[0].slice(0, 4);
        const sinMonth = splitSinDateString[0].slice(4, 6);
        const sinDay = splitSinDateString[0].slice(6, 8);
        const sinDate = new Date(`${sinYear}/${sinMonth}/${sinDay}`);
        this.sinDateStr = this.formatDate(sinDate);
        this.serviceStartDateForRegistration = after5Date.getTime() >= sinDate.getTime() ? this.formatDate(after5Date) : this.formatDate(sinDate);
        this.serviceStartDateForNumberPortability = after9Date.getTime() >= sinDate.getTime() ? this.formatDate(after9Date) : this.formatDate(sinDate);

        this.$store.commit('eMansionHikariphoneStore/serviceStartDateForRegistration', this.serviceStartDateForRegistration);
        this.$store.commit('eMansionHikariphoneStore/serviceStartDateForNumberPortability', this.serviceStartDateForNumberPortability);

        this.preferredDateMin = this.serviceStartDateForRegistration.replace(/\//g, '-');
        this.preferredDateMax =
          String(Number(this.serviceStartDateForRegistration.slice(0, 4)) + 1) +
          '-' +
          this.serviceStartDateForRegistration.slice(5, 7) +
          '-' +
          this.serviceStartDateForRegistration.slice(8);

        return;
      } catch (error: any) {
        checkRouterError(error);
      }
    },
    // 入力されたご利用希望日が指定可能な日付より前かどうかを判定する
    async isBeforeAvailableServiceStartDate(): Promise<boolean> {
      //最短お申込み日の条件に関わらず、必ずSIN日より後でなければならない
      if(this.inputData.preferredDate&& this.formatDate(new Date(this.inputData.preferredDate)) < this.formatDate(new Date(`${this.sinDateStr}`))){
        return true;
      }
      if (this.inputData.selectedGetPhoneMethod === '1' && this.serviceStartDateForRegistration && this.inputData.preferredDate) {
        return this.formatDate(new Date(this.inputData.preferredDate)) < this.formatDate(new Date(`${this.serviceStartDateForRegistration}`));
      } else if (this.inputData.selectedGetPhoneMethod === '2' && this.serviceStartDateForNumberPortability && this.inputData.preferredDate) {
        return this.formatDate(new Date(this.inputData.preferredDate)) < this.formatDate(new Date(`${this.serviceStartDateForNumberPortability}`));
      } else {
        return false;
      }
    },
    async isBusinessDay(): Promise<boolean> {
      const today = new Date();
      //営業日マスタから営業日（UCOM光電話）の9営業日後を取得
      const businessDayList = await SpfApiService.getBusinessdaysUtil('4', today, 9, 1000, 'YYYY/MM/DD', false);
      return businessDayList.some((businessDay) => {
        // 日付を比較するために、時刻部分を切り捨てる
        const businessDateOnly = this.formatDate(new Date(businessDay.businessDate));
        const preferredDateOnly = this.formatDate(new Date(this.inputData.preferredDate!));
        return businessDateOnly === preferredDateOnly;
      });
    },
    /**
     * 住所検索
     */
    async searchAddressByZipcode() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      this.inputData.zipcode = `${this.zipcode1}-${this.zipcode2}`;
      this.errorMessages = [];
      const ZIP_CODE_REGEX = /^[0-9]{3}-[0-9]{4}$/;
      // 形式チェック
      if (!ZIP_CODE_REGEX.test(this.inputData.zipcode)) {
        this.errorMessages.push('「「引継電話番号の登録住所」郵便番号」は半角数字7文字で入力してください。');
      }
      if (this.errorMessages.length > 0) {
        this.isLoading = false;
        window.scrollTo(0, 0);
        return;
      }
      try {
        const resultList = await SpfApiService.searchAddresses(this.inputData.zipcode);
        if (resultList.length > 0) {
          const result = resultList[0];
          this.inputData.address = `${result.address1}${result.address2}${result.address3}`;
          this.address = this.inputData.address;
        } else {
          this.errorMessages.push('「「引継電話番号の登録住所」郵便番号」が正しくありません。');
          window.scrollTo(0, 0);
        }
      } catch (e) {
        // エラーのときは何もしない
      }
      this.isLoading = false;
    },
    async onNext(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      this.errorMessages = [];

      // 番号ポータビリティの場合、電話会社名、住所の値を全角に変換
      // バリデーションを行うため、郵便番号と住所にスペースや改行のみの入力値がある場合空白に変換する
      if (this.inputData.selectedGetPhoneMethod === '2') {
        if (this.inputData.otherCompanyName) {
          this.inputData.otherCompanyName = convertKanaAndAscii(this.inputData.otherCompanyName);
        }
        if (this.address) {
          this.inputData.address = convertKanaAndAscii(this.address);
          if (!this.inputData.address.match(/\S/g)) {
            this.inputData.address = '';
          }
          this.address = this.inputData.address; // 表示反映のために必要
        }
        if (this.inputData.apartment) {
          this.inputData.apartment = convertKanaAndAscii(this.inputData.apartment);
          if (!this.inputData.apartment.match(/\S/g)) {
            this.inputData.apartment = '';
          }
        }
        if (this.zipcode1) {
          if (!this.zipcode1.match(/\S/g)) {
            this.zipcode1 = '';
          }
        }
        if (this.zipcode2) {
          if (!this.zipcode2.match(/\S/g)) {
            this.zipcode2 = '';
          }
        }
      }

      // バリデーションを実行
      await this.validate();

      if (this.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      // 選択されたキャリア、もしくはその他のキャリアを文字列として取得
      if (this.inputData.telephoneCompanyType === '98' && this.inputData.otherCompanyName) {
        this.inputData.telephoneCompany = this.inputData.otherCompanyName;
      } else if (!(this.inputData.telephoneCompanyType === '98') && this.inputData.telephoneCompanyType) {
        this.inputData.telephoneCompany = this.carrierList.find((carrier) => carrier.value === this.inputData.telephoneCompanyType)!.name;
      }

      // 入力内容をストアに保存
      this.$store.commit('eMansionHikariphoneStore/inputData', this.inputData);

      await this.$router.push('/e-mansion/hikari-phone/confirm').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
    },
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/e-mansion/hikari-phone/service-terms').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
    },
    formatDate(date: Date): string {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}/${month}/${day}`;
    },
    /**
     * @param billing 表示する金額
     * @return カンマをなくした金額文字列（例：xx,xxx）
     */
    removeComma(billing: string): string {
      var removed = billing.replace(/,/g, '');
      return removed;
    },
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: string): string {
      const formatter = new Intl.NumberFormat('en-US');
      const formattedValue = formatter.format(Number(billing));
      return formattedValue;
    },
  },
  computed: {
    // 名義人区分が本人の場合、名義人欄を自動記入の判定
    updateHolderName(): string {
      if (this.relationshipType === '0') {
        return `${this.member?.firstName}${this.member?.givenName}`;
      } else if (this.relationshipType === '1') {
        return this.inputData.holderName;
      }
      return this.inputData.holderName; // その他の場合のフォールバック
    },
    // 名義人区分が本人の場合、名義人欄を自動記入の判定
    updateHolderNameKatakana(): string {
      if (this.relationshipType === '0') {
        return `${this.member?.firstNameKatakana}${this.member?.givenNameKatakana}`;
      } else if (this.relationshipType === '1') {
        return this.inputData.holderNameKana;
      }
      return this.inputData.holderNameKana; // その他の場合のフォールバック
    },
    // ラジオボタンの選択によってdisplayNumberPortabilityItemを制御
    displayNumberPortabilityItem(): boolean {
      return this.inputData.selectedGetPhoneMethod === '2';
    },
  },
  watch: {
    // relationshipType が変更されたら holderName に自動的に反映
    relationshipType() {
      this.inputData.holderName = this.updateHolderName;
      this.inputData.holderNameKana = this.updateHolderNameKatakana;
    },
  },
});
</script>
