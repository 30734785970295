import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionIPPhoneRegisterResponse } from '@/shared/classes/spf-api/e-mansion/e-mansion-ip-phone';
import { BaseApiService } from '@/shared/services/api/base-api-service';

const api = BaseApiService.api();

/** 内部 API をコールするサービス */
export class SpfApiIPPoneAccessor {
  /**
   * e-mansion IPフォン申込API
   * @param primaryKeyMye  e-mansion 会員ID
   * @param apartmentId 物件ID(6桁)
   * @param deliveryDate 着荷予定日(YYYY/MM/DD)
   * @param deliveryTimeZone 指定時間(DELIVERY_TIME_ZONE)
   * @param contractDestinationKind 契約内容の交付方法
   * @param contractDestinationMailAddress 希望送付先メールアドレス
   * @param contractDestinationZipcode 希望送付先郵便番号
   * @param contractDestinationAddress1 希望送付先住所１（番地まで）
   * @param contractDestinationAddress2 希望送付先住所２（マンション名、棟／部屋番号）
   * @param creditCardRegistFlag カード登録フラグ
   * @returns
   */
  public static async registerIPPhone(
    primaryKeyMye: string,
    apartmentId: string,
    deliveryDate: string,
    deliveryTimeZone: string,
    contractDestinationKind: string,
    contractDestinationMailAddress: string | undefined,
    contractDestinationZipcode: string | undefined,
    contractDestinationAddress1: string | undefined,
    contractDestinationAddress2: string | undefined,
    creditCardRegistFlag: string
  ): Promise<EMansionIPPhoneRegisterResponse> {
    const response = await api
      .post<EMansionIPPhoneRegisterResponse>(`/ip-phone/e-mansion/register`, {
        primaryKeyMye,
        apartmentId,
        deliveryDate,
        deliveryTimeZone,
        contractDestinationKind,
        contractDestinationMailAddress,
        contractDestinationZipcode,
        contractDestinationAddress1,
        contractDestinationAddress2,
        creditCardRegistFlag,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return response.data;
  } // registerIPPhone
}
