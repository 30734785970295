<template>
  <div class="error">
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1 v-if="isSpecialErrorTitle()">{{ specialTitle }}</h1>
      <h1 v-else>エラーが発生しました</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- blc -->
      <div class="blc" v-if="isSpecialErrorMessage()">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="申し訳ございません。エラーが発生しました。" />大変申し訳ございません。</h2>
        <p class="pre" v-html="specialMessage"></p>
      </div>
      <div class="blc" v-else>
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="申し訳ございません。エラーが発生しました。" />大変申し訳ございません。</h2>
        <p>ご迷惑をおかけし申し訳ございません。<br />大変お手数をおかけしますが、トップページから再度お手続きをしていただきますようお願いいたします。</p>
      </div>
      <!-- /blc -->

      <!-- sblc -->
      <div class="sblc">
        <h3 class="service-h3">e-mansionサポートセンター　お問合せ先</h3>
        <div v-if="eMansionErrorDisplayInfoListOnDisplay">
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>固定電話、携帯、PHSから</b></th>
                <td>{{ eMansionErrorDisplayInfoListOnDisplay.contactTel1 }}</td>
              </tr>
              <tr v-if="!isSlimPlan">
                <th><b>e-mansion IPフォンから</b></th>
                <td>{{ eMansionErrorDisplayInfoListOnDisplay.contactTel2 }}</td>
              </tr>
              <tr>
                <th><b>営業時間</b></th>
                <td>
                  {{ eMansionErrorDisplayInfoListOnDisplay.contactOfficeHours }}
                </td>
              </tr>
              <tr>
                <th class="contact"><b>フォームでのお問合せ</b></th>
                <td class="btn">
                  <button class="btn btn01 bs" type="button">
                    <a :href="eMansionErrorDisplayInfoListOnDisplayUrl" class="link" target="_blank">お問合せフォームへ</a>
                    <i class="material-icons link link-icon">east</i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- /sblc -->
    </div>
    <!-- /contents -->
  </div>
</template>
<style scoped>
.pre {
  white-space: pre-line;
}
a,
a:hover {
  text-decoration: none;
  color: #ffffff;
  text-align: center;
}
.link {
  text-align: center;
}
.contact {
  vertical-align: middle;
}

.btn {
  line-height: 24px;
}
</style>
<script lang="ts">
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionErrorDisplayInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-error-display-info';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { E_MANSION_MAIN_ACCOUNT_PLAN_TYPE } from '@/shared/const/e-mansion/e-mansion-main-account-plan-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

export const E_MANSION_SUPPORT_CONTACT = {
  TOWA: 0,
  NORMAL: 1,
  SLIM: 2,
} as const;
export const E_MANSION_PROPERTY_TYPE = {
  TOWA: 'TOWA',
  NORMAL: 'NORMAL',
  SLIM: 'SLIM',
} as const;

/** エラー画面 */
export default defineComponent({
  name: 'e-mansion-error',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoggedIn: false,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    contactInfo: E_MANSION_SUPPORT_CONTACT,
    isTowaApartment: false,
    isSlimPlan: false,
    isNormal: false,
    eMansionErrorDisplayInfoList: null as Array<EMansionErrorDisplayInfo> | null,
    eMansionErrorDisplayInfoListOnDisplay: null as EMansionErrorDisplayInfo | null,
    specialTitle: '',
    specialMessage: '',
    /** e-mantion My の URL */
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    eMansionErrorDisplayInfoListOnDisplayUrl: '',
  }),
  async mounted() {
    this.$data.isLoggedIn = await AuthService.isLoggedIn();
    try {
      await StoreExternalApiResponse.main(); // 物件基本情報取得APIをdispatchしている
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        this.isLoading = false;
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      //storeから取得
      this.specialTitle = this.$store.getters['errorStore/title'];
      this.specialMessage = this.$store.getters['errorStore/messages'];
      //Storeをリセット
      this.$store.commit('errorStore/title', undefined);
      this.$store.commit('errorStore/messages', undefined);

      // 物件タイプの取得
      await this.getPropertyPlanType();

      // エラー画面情報を取得
      this.eMansionErrorDisplayInfoList = await this.$store.dispatch('eMansionErrorDisplayInfoStore/eMansionErrorDisplayInfoList');
      this.isLoading = false;

      // エラー画面情報が取得できない場合は共通エラー画面に飛ばす
      if (!this.eMansionErrorDisplayInfoList) {
        this.isLoading = false;
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      // 表示用のお問合せ先情報取得
      await this.getErrorDisplayInfo();
    } catch (e) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    isSpecialErrorMessage(): boolean {
      if (!this.specialMessage) {
        return false;
      }
      if (this.specialMessage.length > 0) {
        return true;
      }
      return false;
    },
    isSpecialErrorTitle(): boolean {
      if (!this.specialTitle) {
        return false;
      }
      if (this.specialTitle.length > 0) {
        return true;
      }
      return false;
    },
    /**
     * e-mansionのメインアカウントの会員種別取得
     * @returns SERVICE_PLAN_TYPE 会員種別
     */
    async getPropertyPlanType(): Promise<void> {
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];

      const FLAG_TRUE = '1';

      if (!property.apartment_special_type_flag) {
        this.isNormal = true;
      }

      const flags = property.apartment_special_type_flag[0];

      if (flags[E_MANSION_MAIN_ACCOUNT_PLAN_TYPE.SLIM_PLAN] === FLAG_TRUE) {
        this.isSlimPlan = true;
      } else if (flags[E_MANSION_MAIN_ACCOUNT_PLAN_TYPE.TOWA] === FLAG_TRUE) {
        this.isTowaApartment = true;
      } else {
        this.isNormal = true;
      }
      return;
    },
    async getErrorDisplayInfo(): Promise<void> {
      if (this.eMansionErrorDisplayInfoList) {
        if (this.isTowaApartment) {
          for (const eMansionErrorDisplayInfo of this.eMansionErrorDisplayInfoList) {
            if (eMansionErrorDisplayInfo.propertyType === E_MANSION_PROPERTY_TYPE.TOWA) {
              this.eMansionErrorDisplayInfoListOnDisplay = eMansionErrorDisplayInfo;
              this.eMansionErrorDisplayInfoListOnDisplayUrl = `${this.eMansionMyUrl}` + this.eMansionErrorDisplayInfoListOnDisplay.contactUrl;
            }
          }
        } else if (this.isSlimPlan) {
          for (const eMansionErrorDisplayInfo of this.eMansionErrorDisplayInfoList) {
            if (eMansionErrorDisplayInfo.propertyType === E_MANSION_PROPERTY_TYPE.SLIM) {
              this.eMansionErrorDisplayInfoListOnDisplay = eMansionErrorDisplayInfo;
              this.eMansionErrorDisplayInfoListOnDisplayUrl = `${this.eMansionMyUrl}` + this.eMansionErrorDisplayInfoListOnDisplay.contactUrl;
            }
          }
        } else {
          for (const eMansionErrorDisplayInfo of this.eMansionErrorDisplayInfoList) {
            if (eMansionErrorDisplayInfo.propertyType === E_MANSION_PROPERTY_TYPE.NORMAL) {
              this.eMansionErrorDisplayInfoListOnDisplay = eMansionErrorDisplayInfo;
              this.eMansionErrorDisplayInfoListOnDisplayUrl = `${this.eMansionMyUrl}` + this.eMansionErrorDisplayInfoListOnDisplay.contactUrl;
            }
          }
        }
      }
    },
  },
});
</script>
