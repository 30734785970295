<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ホームページ容量 確認</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ホームページ容量</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li>ご利用状況</li>
        <li>変更</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ホームページ容量 変更 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ホームページ容量確認" />ホームページ容量確認</h2>
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <div class="sblc">
            <p>お申し込み内容をご確認ください。</p>
            <h3 class="service-h3">変更内容の確認</h3>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th>変更対象 FTPアカウント</th>
                  <td>
                    <b>{{ ftpAccount }}</b>
                  </td>
                </tr>
                <tr>
                  <th>変更後のホームページ容量</th>
                  <td class="red">
                    <b>{{ eMansionHomepageCapacitySelectedValue }}</b>
                  </td>
                </tr>
                <tr>
                  <th>月額利用料</th>
                  <td>{{ myNumberWithDelimiter(monthlyFee) }}円</td>
                </tr>
                <tr>
                  <th>事務手数料</th>
                  <td>{{ jimFee }}円</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <p>※価格は全て税込表示（消費税{{ tax }}％）です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
      <!-- blc -->
      <h3 v-if="is3dsecure" class="service-h3">e-mansionサービス お支払い方法</h3>
      <div v-if="is3dsecure">
        <div class="pd10 ml10">
          <p>e-mansionにご登録されている以下のお支払い方法により、お支払いいただきます。</p>
          <h3>クレジットカード</h3>
          <table v-if="is3dsecure" class="table-type2">
            <tbody>
              <tr>
                <th class="va-middle">カード番号</th>
                <td>{{ maskedCardNumberFromIsp }}</td>
              </tr>
              <tr>
                <th class="va-middle">カード期限</th>
                <td>{{ cardExpiredMonthOnPortas }}月 ／ {{ cardExpiredYearOnPortas }}年</td>
              </tr>
              <tr v-if="!isIspCardHolderName">
                <td>
                  <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
                </td>
                <td>
                  <div class="mt10">
                    <input
                      type="text"
                      class="text card-name"
                      v-model.trim="cardholderNameFormForAuthorizeIspCard"
                      required
                      minlength="2"
                      maxlength="45"
                      @change="inputCardholderNameFormForAuthorizeIspCard"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="blc">
        <p v-if="is3dsecure" class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
        </p>
        <p class="red" v-if="canApply && isNeedPayment && !is3dsecure">ご登録のお支払い方法により、お支払いいただきます。</p>
        <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt">ご確認の上、よろしければ「申し込む」をクリックしてください。</p>
        <div v-if="is3dsecure" class="my-button-area">
          <div class="has-checkbox">
            <div class="my-checkbox-outer">
              <input id="checkbox-to-agree" type="checkbox" v-model="isAgreeTerms" />
              <p class="my-checkbox-text"><label for="checkbox-to-agree">各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label></p>
            </div>
          </div>
        </div>
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button v-if="is3dsecure" class="btn bs" :class="[isDisabledButton ? 'btn05' : 'btn01']" :disabled="isDisabledButton" @click="goEntry">
            申し込む<i class="material-icons link link-icon">east</i>
          </button>
          <button v-if="!is3dsecure" class="btn bs" :class="[canApply ? 'btn01' : 'btn05']" :disabled="!canApply" @click="goEntry">
            申し込む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>

      <!-- /blc -->
    </div>
  </div>
  <!-- /contents -->
  <!-- /main-contents -->
</template>

<style lang="scss" scoped>
.p-margin-top {
  margin-top: 10px;
}

.card-name {
  width: 100%;
  max-width: 360px;
}

div.my-button-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  text-align: center;
  padding-top: 35px;

  & div.has-checkbox {
    padding-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 10px;
      left: 0;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  div.my-button-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    margin-top: 16px;

    & div.has-checkbox {
      padding-top: 0;

      & div.my-checkbox-outer {
        top: 0;
        left: 50%;
        text-align: left;

        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1275px) {
  div.my-button-area {
    padding-top: 16px;
  }
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { numberWithDelimiter } from '../../../shared/util/vue-filters';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiHomepageCapacityAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-capacity-accessor';
import { SpfApiPaymentStatusAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-payment-status-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { EMansionPaymentStatusInfo } from '@/shared/classes/spf-api/mail/model/common/e-mansion-payment-status';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { E_MANSION_PAYMENT_STATUS } from '@/shared/const/e-mansion/e-mansion-payment-status';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { inputEventConvertToUpper } from '@/shared/util/inputEventConvertToUpper';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'homepageCapacityConfirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    ftpAccount: '', // FTPアカウント
    eMansionHomepageCapacity: '', // 現在のホームページ容量
    /** 選択したホームページ容量 */
    eMansionHomepageCapacitySelected: undefined as { key: string; value: string } | undefined,
    eMansionHomepageCapacitySelectedValue: '', // 選択したホームページ容量
    homepageCapacityListCount: 0, // ホームページ容量追加要素数
    homepageCapacityList: [], // ホームページ容量配列
    eMansionAccountId: -1, // e-mansion アカウントID
    eMansionHomepageId: -1, // e-mansion ホームページID
    fee: '', // 月額利用料 (50MBごと)
    monthlyFee: 0, // 表示する月額利用料
    jimFee: '', // 事務手数料
    maxQuota: '', // 最大容量(MB)
    hpQuota: '',
    tax: '', // 消費税率
    paymentInfo: null as EMansionPaymentStatusInfo | null,
    errorMessages: new Array<string>(), // エラーメッセージを格納する配列
    errorMessageTitle: '', // エラーメッセージ部に表示するタイトル
    isChangeBtn: false, //変更ボタン活性化するか
    isSameMonthChange: false, //同月内変更有無
    isLoading: false, //ローディング
    canApply: false, //お支払情報登録有無
    canApplyErrorCheck: false, // エラー出力するかの判断
    is3dsecure: false, // クレジットカードの判定
    isIspCardHolderName: false, // ISPにカード名義人名が登録されているか
    cardholderName: '', // カード名義人名
    isCampaign: false,
    isNeedPayment: true,
    member: null as Member | null,
    CANT_APPLY_MESSAGE: 'お支払情報のご登録が必要です',
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
    /** 規約に同意する */
    isAgreeTerms: false,
    /** 連携先ISPで登録されたクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** Portasで登録されたクレジットカードの有効期限(月) */
    cardExpiredMonthOnPortas: '',
    /** Portasで登録されたクレジットカードの有効期限(年) */
    cardExpiredYearOnPortas: '',
    cardholderNameFormForAuthorizeIspCard: '',
    /** e-mantion の URL */
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    /** apid */
    apid: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    this.isLoading = true;

    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
      }

      // 前画面からの情報をStoreから取得
      if (!this.$store.getters['eMansionHomepageCapacityStore/eMansionAccountId']) {
        // e-mansionアカウントID(Store)を取得できない場合、前画面へ戻る
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/homepage-capacity/list').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      // 会員情報をStoreから取得
      this.member = this.$store.getters['memberStore/member'];

      // 前画面情報の取得
      this.eMansionAccountId = this.$store.getters['eMansionHomepageCapacityStore/eMansionAccountId'];
      this.eMansionHomepageId = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageId'];
      this.ftpAccount = this.$store.getters['eMansionHomepageCapacityStore/ftpAccount'];
      this.eMansionHomepageCapacity = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageCapacity'];
      this.eMansionHomepageCapacitySelected = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageCapacitySelected'];
      this.eMansionHomepageCapacitySelectedValue = this.eMansionHomepageCapacitySelected?.value ?? '';
      this.fee = this.$store.getters['eMansionHomepageCapacityStore/fee'];
      this.jimFee = this.$store.getters['eMansionHomepageCapacityStore/jimFee'];
      this.maxQuota = this.$store.getters['eMansionHomepageCapacityStore/maxQuota'];
      this.hpQuota = this.$store.getters['eMansionHomepageCapacityStore/hpQuota'];
      this.isCampaign = this.$store.getters['eMansionHomepageCapacityStore/isCampaign'];
      this.tax = this.$store.getters['taxStore/tax'];

      // お支払い情報登録状態を取得
      this.paymentInfo = await SpfApiPaymentStatusAccessor.getPaymentStatus(this.member?.id ?? -1);
      if (!this.paymentInfo) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
      // お支払い情報登録状態チェック
      await this.checkPaymentInfo(this.paymentInfo.status);

      // お支払い情報登録状態が未登録の場合のエラーメッセージ
      if (!this.canApplyErrorCheck) {
        this.errorMessages.push(this.CANT_APPLY_MESSAGE);
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        this.isLoading = false;
        return;
      }

      // 表示する月額利用料の計算
      const unitFee = Number(this.fee.replace(',', ''));
      const selectedCapacity = Number(this.eMansionHomepageCapacitySelectedValue.replace('MB', ''));
      this.monthlyFee = unitFee * ((selectedCapacity - Number(this.hpQuota)) / 50);

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    // 戻るボタンクリック
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ホームページ容量情報をstoreに保存する
      this.$store.commit('eMansionHomepageCapacityStore/eMansionAccountId', this.eMansionAccountId);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.eMansionHomepageId);
      this.$store.commit('eMansionHomepageCapacityStore/ftpAccount', this.ftpAccount);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacity', this.eMansionHomepageCapacity);
      this.$store.commit('eMansionHomepageCapacityStore/fee', this.fee);
      this.$store.commit('eMansionHomepageCapacityStore/jimFee', this.jimFee);

      this.$store.commit('eMansionHomepageCapacityStore/maxQuota', this.maxQuota);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/homepage-capacity/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // 申し込みボタンクリック
    async goEntry() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.errorMessageTitle = '';

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      try {
        // ホームページ容量情報をstoreに保存する
        this.$store.commit('eMansionHomepageCapacityStore/eMansionAccountId', this.eMansionAccountId);
        this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.eMansionHomepageId);
        this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacitySelected', this.eMansionHomepageCapacitySelected?.key);

        // 現在日付の取得
        const processOn = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

        // 3dセキュアを実施する場合
        if (this.is3dsecure) {
          if (
            !this.isIspCardHolderName &&
            (!this.cardholderNameFormForAuthorizeIspCard || this.cardholderNameFormForAuthorizeIspCard?.length < 2 || this.cardholderNameFormForAuthorizeIspCard?.length > 45)
          ) {
            this.errorMessages.push('カード名義人を正しく入力してください。');
            this.isLoading = false;
            this.isAgreeTerms = false;
            // エラーメッセージを見せるために画面最上部にスクロール
            window.scrollTo(0, 0);
            return;
          }
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();

          const paymentMethodApplicationData = {
            eMansionAccountId: this.eMansionAccountId,
            eMansionHomepageId: this.eMansionHomepageId,
            eMansionHomepageCapacitySelected: this.eMansionHomepageCapacitySelected,
            processOn: processOn,
            ftpAccount: this.ftpAccount,
            eMansionHomepageCapacity: this.eMansionHomepageCapacity,
            eMansionHomepageCapacitySelectedValue: this.eMansionHomepageCapacitySelectedValue,
            fee: this.fee,
            jimFee: this.jimFee,
            maxQuota: this.maxQuota,
            hpQuota: this.hpQuota,
            isCampaign: this.isCampaign,
            tax: this.tax,
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-homepage-capacity-change',
          });
          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

          const member: Member = this.$store.getters['memberStore/member'];
          if (uuidForTemporarySavingApplicationData !== null) {
            let cardHolderNameForAuthorize: string;
            if (this.cardholderName) {
              cardHolderNameForAuthorize = this.cardholderName;
            } else {
              cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
            }

            // VeriTrans_本人認証用のデータ格納
            const dddSecureAuthStartWithVeriTransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
              uuid: uuidForTemporarySavingApplicationData,
              externalVeritransAccountId: member.eTncMemberId,
              cardholderName: cardHolderNameForAuthorize,
            });
            // VeriTrans_本人認証(VeriTrans会員ID使用) API
            try {
              const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeriTransAccountIdRequest);
              location.href = authStartURL;
            } catch (error: any) {
              if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                this.errorMessages.push(
                  `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
                );
                this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
                this.isLoading = false;
                this.isAgreeTerms = false;
                this.isAgreeTerms = false;

                // エラーメッセージを見せるために画面最上部にスクロール
                window.scrollTo(0, 0);
                return;
              } else {
                throw error;
              }
            }
          }
          // 3dセキュアを実施しない場合（支払方法がクレジットカードではないか、容量減の場合）
        } else {
          // ホームページ容量を更新する
          const response = await SpfApiHomepageCapacityAccessor.updateEMansionHomepageCapacity(
            this.eMansionAccountId,
            this.eMansionHomepageId,
            this.eMansionHomepageCapacitySelected?.key ?? '',
            processOn
          );

          // エラーメッセージがあれば画面に表示する
          if (response.errorMessages.length > 0) {
            this.errorMessages = response.errorMessages;
            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
            window.scrollTo(0, 0);
            this.isLoading = false;
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/e-mansion/homepage-capacity/complete').catch((error: any) => {
            checkRouterError(error);
          });
          this.isLoading = false;
        }
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
    },
    inputCardholderNameFormForAuthorizeIspCard(event: Event) {
      this.cardholderNameFormForAuthorizeIspCard = inputEventConvertToUpper(event);
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },
    async checkPaymentInfo(paymentInfoStatus: E_MANSION_PAYMENT_STATUS): Promise<boolean> {
      // クレカのお支払情報があった場合の処理
      const property: Property = this.$store.getters['propertyStore/property'];

      if (property?.apartmentId) {
        this.apid = convert6DigitIdTo8DigitId(property?.apartmentId);
      }

      const member: Member | null = this.$store.getters['memberStore/member'];
      if (!member || !member.primaryKeyMye) {
        return true;
      }
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });
      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });
      // 現在の容量
      const currentCapacitythis = Number(this.eMansionHomepageCapacity.replace('MB', ''));
      // 変更後の容量
      const capacityAfterChange = Number(this.eMansionHomepageCapacitySelectedValue.replace('MB', ''));

      // 支払方法がクレジットカードであり、変更後の容量が増えている場合
      if (eMansionCustomer instanceof EMansionCustomer && eMansionCustomer.payment_id === '8' && currentCapacitythis < capacityAfterChange) {
        this.canApplyErrorCheck = true;
        this.is3dsecure = true;
        if (member.eTncMemberId) {
          const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
          this.maskedCardNumberFromIsp = this.addHyphenToCardNumber(cardInfo.cardNumber);
          this.cardholderName = cardInfo.cardholderName ?? '';
          // クレジットカードの年月取得
          this.cardExpiredMonthOnPortas = cardInfo.cardExpire.slice(0, 2);
          this.cardExpiredYearOnPortas = cardInfo.cardExpire.slice(-2);
          // カード名義人判定
          if (cardInfo.cardholderName) {
            this.isIspCardHolderName = true;
          }
        }
      } else {
        //支払方法がクレジットカードでないか、変更後の容量が減っている場合
        this.canApply = paymentInfoStatus == E_MANSION_PAYMENT_STATUS.REGISTERED;
        if (this.canApply) {
          this.canApplyErrorCheck = true;
        }
      }

      if (Number(this.fee) === 0 && Number(this.jimFee) === 0 && !this.isCampaign) {
        //利用料・事務手数料の料金（定価）がともに0円の場合は支払情報の登録は不要のため、trueを登録
        this.canApply = true;
        this.isNeedPayment = false;
        this.canApplyErrorCheck = true;
      }
      // お支払情報登録有無の保持
      this.$store.commit('eMansionHomepageCapacityStore/canApply', this.canApply);

      return false;
    },

    myNumberWithDelimiter(value: number) {
      return numberWithDelimiter(value);
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
  },
  computed: {
    // チェックボックスによる「申し込む」ボタンの判定
    isDisabledButton(): boolean {
      if (this.isAgreeTerms) {
        return false;
      }
      return true;
    },
  },
  watch: {
    cardholderNameFormForAuthorizeIspCard: {
      handler: function (newVal, oldVal) {
        if (newVal && !newVal.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.cardholderNameFormForAuthorizeIspCard = oldVal;
        }
      },
    },
  },
});
</script>
