<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/e-mansion">e-mansion 会員専用トップ</a></li>
        <li>UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <div class="my-error-messages-component-wrapper">
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOM光電話 お申し込み確認" />UCOM光電話 お申し込み確認</h2>

        お申し込み内容をご確認ください。
        <div class="sblc">
          <h3 class="service-h3 cf">お申し込み内容</h3>
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>ご利用希望日</b></th>
                <td>{{ preferredDay }}</td>
              </tr>
              <tr>
                <th><b>電話番号取得方法</b></th>
                <td>
                  <span v-if="inputFormData.selectedGetPhoneMethod === '1'">新しく取得</span>
                  <span v-else-if="inputFormData.selectedGetPhoneMethod === '2'">番号ポータビリティ</span>
                </td>
              </tr>
              <tr v-if="inputFormData.selectedOptionalService.length > 0">
                <th><b>付加サービス（有料）</b></th>
                <td>
                  <p v-if="inputFormData.selectedOptionalService.includes('toggle01')">・着信番号表示：月額{{ formatMoney(numberDisplayFee) }}&nbsp;円</p>
                  <p v-if="inputFormData.selectedOptionalService.includes('toggle02')">・キャッチ通話：月額{{ formatMoney(callWaitingFee) }}&nbsp;円</p>
                  <p v-if="inputFormData.selectedOptionalService.includes('toggle03')">・キャッチ通話番号表示：月額{{ formatMoney(callWaitingNumberDisplayFee) }}&nbsp;円</p>
                  <p v-if="inputFormData.selectedOptionalService.includes('toggle04')">・オプションサービスパック：月額{{ formatMoney(optionalServicePackFee) }}&nbsp;円</p>
                </td>
              </tr>
              <tr>
                <th><b>番号通知（無料）</b></th>
                <td>
                  <span v-if="inputFormData.isNotifyNumber === '1'">相手に通知する</span>
                  <span v-else-if="inputFormData.isNotifyNumber === '2'">相手に通知しない</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="inputFormData.selectedGetPhoneMethod === '2'">
          <div class="sblc">
            <h3 class="service-h3 cf">番号ポータビリティ項目</h3>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th><b>引継ぎ電話番号</b></th>
                  <td>{{ inputFormData.takeOverPhoneNumber }}</td>
                </tr>
                <tr>
                  <th><b>名義人区分</b></th>
                  <td>
                    <span v-if="inputFormData.relationshipType === '0'">本人</span>
                    <span v-else-if="inputFormData.relationshipType === '1'">本人以外</span>
                  </td>
                </tr>
                <tr>
                  <th><b>名義人</b></th>
                  <td>{{ inputFormData.holderName }}</td>
                </tr>
                <tr>
                  <th><b>名義人カナ</b></th>
                  <td>{{ inputFormData.holderNameKana }}</td>
                </tr>
                <tr>
                  <th><b>ご利用中の電話会社</b></th>
                  <td>{{ inputFormData.telephoneCompany }}</td>
                </tr>
                <tr>
                  <th><b>引継電話番号の登録住所</b></th>
                  <td>
                    <p v-if="inputFormData.zipcode">〒{{ zipcode1 }}-{{ zipcode2 }}</p>
                    <p>{{ inputFormData.address }} {{ inputFormData.apartment }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="sblc">
          <h3 class="service-h3 cf">料金</h3>
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>事務手数料（初回のみ）</b></th>
                <td>{{ formatMoney(officeHandlingFee) }}&nbsp;円</td>
              </tr>
              <tr v-if="inputFormData.selectedGetPhoneMethod === '2'">
                <th><b>番号ポータビリティ手数料（初回のみ）</b></th>
                <td>{{ formatMoney(numberPortabilityFee) }}&nbsp;円</td>
              </tr>
              <tr></tr>
              <tr>
                <th><b>基本利用料（月額）</b></th>
                <td>{{ formatMoney(basicFee) }}&nbsp;円</td>
              </tr>
              <tr>
                <th><b>専用アダプタレンタル料（月額）</b></th>
                <td>{{ formatMoney(AdapterRentalFee) }}&nbsp;円</td>
              </tr>
              <tr>
                <th><b>ユニバーサルサービス料（月額）</b></th>
                <td>
                  {{ formatMoney(universalServiceFee) }}&nbsp;円<br />
                  <p>※ユニバーサルサービス制度にともない改定されることがあります。</p>
                </td>
              </tr>
              <tr v-if="inputFormData.selectedOptionalService.includes('toggle01')">
                <th><b>着信番号表示（月額）</b></th>
                <td>{{ formatMoney(numberDisplayFee) }}&nbsp;円</td>
              </tr>
              <tr v-if="inputFormData.selectedOptionalService.includes('toggle02')">
                <th><b>キャッチ通話（月額）</b></th>
                <td>{{ formatMoney(callWaitingFee) }}&nbsp;円</td>
              </tr>
              <tr v-if="inputFormData.selectedOptionalService.includes('toggle03')">
                <th><b>キャッチ通話番号表示（月額）</b></th>
                <td>{{ formatMoney(callWaitingNumberDisplayFee) }}&nbsp;円</td>
              </tr>
              <tr v-if="inputFormData.selectedOptionalService.includes('toggle04')">
                <th><b>オプションサービスパック（月額）</b></th>
                <td>{{ formatMoney(optionalServicePackFee) }}&nbsp;円</td>
              </tr>
              <tr>
                <th><b>UCOM光電話　総額</b></th>
                <td>
                  <p>初回: {{ formatMoney(totalAmount) }}&nbsp;円 / 次回以降: {{ formatMoney(monthlyChargeFromNext) }}&nbsp;円</p>
                  <a class="link" :href="`${eMansionUrl}/${apid}/service/ipdenwa/hikaridenwa.html`" target="_blank">※ 通話料は別途発生いたします。</a>
                </td>
              </tr>
            </tbody>
          </table>
          <p>価格は全て新税率に基づく税込表示（消費税10%）です。</p>
          <p>今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
        </div>

        <div>
          <h3 class="service-h3">お支払い方法</h3>
          <div v-if="noDisplayCreditCardForm">
            <p>ご登録のお支払い方法により、お支払いいただきます。</p>
          </div>
          <div v-else>
            <credit-card-component
              v-if="isMounted"
              :key="resetCounter"
              :reset-counter="resetCounter"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispName="ispName"
              v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
              v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-bind:cardHolderNameFromIsp="cardHolderNameFromIsp"
              v-on:onPostCreditCard="getCreditCardToken"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:getTokenExpireDate="getTokenExpireDate"
              v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
              v-on:change="isAgreedCopyCardToIsp = $event"
              v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
              v-on:portasCardHolderName="getCardHolderNameFromPortas"
              v-on:cardholderNameFormForAuthorizeIspCard="getCardholderNameFormForAuthorizeIspCard"
              v-on:cardholderNameFormForAuthorizePortasCard="getCardholderNameFormForAuthorizePortasCard"
            />
          </div>
        </div>
      </div>

      <div class="blc">
        <p v-if="isNeedDisableTrackingBlockFunction" class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
        </p>
        <p class="form-btn-txt mt40">
          ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」ボタンをクリックしてください。
        </p>
        <p class="red form-btn-txt">
          「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
        </p>
      </div>

      <div class="btn-area">
        <div class="has-checkbox">
          <div class="my-checkbox-outer" v-if="!noDisplayCreditCardForm">
            <input id="checkbox-to-agree" type="checkbox" v-model="isAgreedPrivacyPolicy" :disabled="isExpiredPortasCreditCard" />
            <label for="checkbox-to-agree">クレジットカード会社での本人認証のための情報提供に同意してお申し込みを確定する</label>
          </div>
          <button class="btn-height btn btn05 bs sp-margin" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn-height btn btn01 bs sp-margin" type="button" :disabled="isDisabledButton" v-on:click="onApply()">
            お申し込みを確定する<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
div.my-checkbox-outer {
  margin: 16px;
}
div.my-checkbox-outer > input {
  margin-right: 10px;
}
.sp-margin {
  margin-top: 10px;
}
.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}
/* 申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
button.btn {
  margin-top: 10px;
}
p.red {
  color: #cf1225;
}
b.red {
  color: #cf1225;
}
</style>

<script lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { SpfApiHikariPhoneAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-hikari-phone-accessor';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { EMansionHikariPhoneRegisterRequestOptionalDataDto } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-register-dto';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { ISP_MEMBER_STATUS, UA_TYPE, UaTypeValue } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { formatDate } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'hikari-phone-confirm',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    /** ローディングコンポーネント */
    LoadingComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
  },
  data: () => ({
    isLoading: true,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
    /** e-mansion 会員ID */
    eMansionMemberId: '' as string,
    /** 物件ID */
    eMansionPropertyId: '',
    /** UA種別 */
    uaType: '',
    /** 支払い方法の登録状態によってクレジットカードコンポーネントの表示/非表示を切り替える */
    noDisplayCreditCardForm: false,
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: false,
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** APIトークンキー */
    apiTokenKey: process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION,
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込みを確定する」ボタンをクリックしてください。',
    /** ボタン押下判定 */
    isSubmitting: false,
    /** ユーザーがe-mansionへカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面ではe-mansion固定) */
    ispName: 'e-mansion',
    /** e-mansionから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** e-mansionから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** e-mansion に登録済のVeriTransAccountId */
    emansionVeriTransAccountId: '',
    /** ISPで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizeIspCard: '' as string | undefined,
    /** Portasで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizePortasCard: '' as string | undefined,
    /** ISPから取得したカード名義人 */
    cardHolderNameFromIsp: '' as string | undefined,
    /** Portasから取得したカード名義人 */
    cardHolderNameOnPortas: '' as string | undefined,
    /** リセット回数 */
    resetCounter: 0,
    /** フォームの入力内容 */
    inputFormData: {
      /** ご利用希望日 */
      preferredDate: '',
      /** 電話番号取得方法 */
      selectedGetPhoneMethod: '',
      /** 番号通知 */
      isNotifyNumber: '',
      /** 引継ぎ電話番号 */
      takeOverPhoneNumber: '',
      /** 名義人 */
      holderName: '',
      /** 名義人カナ */
      holderNameKana: '',
      /** 利用中の電話会社 */
      telephoneCompany: '',
      /** 郵便番号 */
      zipcode: '',
      /** 住所 */
      address: '',
      /** マンション名や部屋番号等 */
      apartment: '',
      /** 名義人区分 */
      relationshipType: '',
      /** 付加オプションサービス */
      selectedOptionalService: [] as string[],
      /** 利用中の電話会社 */
      telephoneCompanyType: '',
      /** その他の電話会社キャリア */
      otherCompanyName: '',
    },
    /** ご利用希望日 */
    preferredDay: '',
    /** 事務手数料 */
    officeHandlingFee: '',
    /** 番号ポータビリティ手数料 */
    numberPortabilityFee: '',
    /** 基本手数料 */
    basicFee: '',
    /** 専用アダプタレンタル料 */
    AdapterRentalFee: '',
    /** ユニバーサルサービス料 */
    universalServiceFee: '',
    /** 着信番号表示料金 */
    numberDisplayFee: '',
    /** キャッチ通話料金 */
    callWaitingFee: '',
    /** キャッチ通話番号表示料金 */
    callWaitingNumberDisplayFee: '',
    /** オプションサービスパック料金 */
    optionalServicePackFee: '',
    /** 総額 */
    totalAmount: '',
    /** 次回以降のお支払い */
    monthlyChargeFromNext: '',
    /** e-mantion の URL */
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    /** apid */
    apid: '',
    /** 利用規約への同意 */
    isAgreedPrivacyPolicy: false,
    /** 本人認証処理を実行するため、ブラウザのトラッキング防止機能を無効化する必要があるかどうか */
    isNeedDisableTrackingBlockFunction: true,
    // 新規番号用ご利用開始日
    serviceStartDateForRegistration: '',
    // 番号ポータビリティ用ご利用開始日
    serviceStartDateForNumberPortability: '',
    /** 郵便番号1 */
    zipcode1: '',
    /** 郵便番号2 */
    zipcode2: '',
  }),
  computed: {
    // チェックボックスによる「申し込む」ボタンの判定
    isDisabledButton(): boolean {
      if (this.noDisplayCreditCardForm) {
        return false;
      } else if (
        this.emansionVeriTransAccountId &&
        ((this.cardHolderNameFromIsp && this.isAgreedPrivacyPolicy) ||
          (this.cardholderNameFormForAuthorizeIspCard && this.cardholderNameFormForAuthorizeIspCard.length > 1 && this.isAgreedPrivacyPolicy))
      ) {
        return false;
      } else if (
        !this.isExpiredPortasCreditCard &&
        (this.cardHolderNameOnPortas || this.cardholderNameFormForAuthorizePortasCard) &&
        this.isAgreedCopyCardToIsp &&
        this.isAgreedPrivacyPolicy
      ) {
        return false;
      } else {
        return this.creditCardAccessToken === '' || !this.isAgreedPrivacyPolicy;
      }
    },
  },
  /** バリデーション定義 */
  validations() {
    // YYYY-DD-MM形式の正規表現バリデーション
    const isCorrectFormat = (value: string) => /^\d{4}\-\d{2}\-\d{2}$/.test(value);

    return {
      inputFormData: {
        preferredDate: {
          required,
          isCorrectFormat,
        },
      },
    };
  },

  setup: () => ({ v$: useVuelidate() }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    this.isLoading = true;
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    try {
      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      this.eMansionMemberId = member.primaryKeyMye ?? '';

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property.uaType;
      const apartmentId: string | undefined = property.apartmentId;

      if (property?.apartmentId) {
        this.apid = convert6DigitIdTo8DigitId(property?.apartmentId);
      }

      await StoreExternalApiResponse.main();
      /** 物件基本情報をStoreから取得 */
      const eMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        this.officeHandlingFee = this.removeComma(eMansionProperty.op.hikariphone.jimu_fee);
        this.numberPortabilityFee = this.removeComma(eMansionProperty.op.hikariphone.numberportability_fee);
        this.basicFee = this.removeComma(eMansionProperty.op.hikariphone.basic_fee);
        this.AdapterRentalFee = this.removeComma(eMansionProperty.op.hikariphone.rental_charge);
        this.universalServiceFee = this.removeComma(eMansionProperty.op.hikariphone.uni_charge_tax_inc);
        this.numberDisplayFee = this.removeComma(eMansionProperty.op.hikariphone.numberdisplay_fee);
        this.callWaitingFee = this.removeComma(eMansionProperty.op.hikariphone.catch_fee);
        this.callWaitingNumberDisplayFee = this.removeComma(eMansionProperty.op.hikariphone.catch_display_fee);
        this.optionalServicePackFee = this.removeComma(eMansionProperty.op.hikariphone.servicepack_fee);
      } else {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.E_MANSION_API_PROPERTY_INFO);
      }

      // 入力画面で入力した情報をStoreから取得
      this.inputFormData = this.$store.getters['eMansionHikariphoneStore/inputData'];
      this.zipcode1 = this.inputFormData.zipcode ? this.inputFormData.zipcode.slice(0, 3) : '';
      this.zipcode2 = this.inputFormData.zipcode ? this.inputFormData.zipcode.slice(-4) : '';

      this.serviceStartDateForRegistration = this.$store.getters['eMansionHikariphoneStore/serviceStartDateForRegistration'];
      this.serviceStartDateForNumberPortability = this.$store.getters['eMansionHikariphoneStore/serviceStartDateForNumberPortability'];

      // 初回の合計金額を算出
      this.totalAmount = (
        parseInt(this.officeHandlingFee) +
        (this.inputFormData.selectedGetPhoneMethod === '2' ? parseInt(this.numberPortabilityFee) : 0) +
        parseInt(this.basicFee) +
        parseInt(this.AdapterRentalFee) +
        parseInt(this.universalServiceFee) +
        (this.inputFormData.selectedOptionalService.includes('toggle01') ? parseInt(this.numberDisplayFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle02') ? parseInt(this.callWaitingFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle03') ? parseInt(this.callWaitingNumberDisplayFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle04') ? parseInt(this.optionalServicePackFee) : 0)
      ).toString();

      // 次回以降の月額の合計金額を算出
      this.monthlyChargeFromNext = (
        parseInt(this.basicFee) +
        parseInt(this.AdapterRentalFee) +
        parseInt(this.universalServiceFee) +
        (this.inputFormData.selectedOptionalService.includes('toggle01') ? parseInt(this.numberDisplayFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle02') ? parseInt(this.callWaitingFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle03') ? parseInt(this.callWaitingNumberDisplayFee) : 0) +
        (this.inputFormData.selectedOptionalService.includes('toggle04') ? parseInt(this.optionalServicePackFee) : 0)
      ).toString();

      // 必要な物件情報を取得できなかった場合はエラー画面に遷移
      if (!uaType || !apartmentId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];
      if (customer instanceof EMansionCustomer) {
        // 物件ID
        this.eMansionPropertyId = customer.property_id;
        // 登録している支払方法がクレジットカード以外の以下いずれかの場合、クレジットカード申請フォームおよびe-mansionで登録されたカード情報を表示しない
        /**
         * 0: 口座振替
         * 1: 振込
         * 4: 口振（請求書）
         */
        const paymentIdsWithoutCreditCard = ['0', '1', '4'];
        if (paymentIdsWithoutCreditCard.some((paymentIdsWithoutCreditCard) => paymentIdsWithoutCreditCard === customer.payment_id)) {
          this.noDisplayCreditCardForm = true;
          this.isNeedDisableTrackingBlockFunction = false;
        }
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      this.preferredDay = this.formatDateToJapanese(new Date(this.inputFormData.preferredDate));
      if (this.inputFormData == null) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }

      if (customer instanceof EMansionCustomer && customer.payment_id === '8' && member.eTncMemberId) {
        const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
        this.cardExpiredFromIsp = cardInfo.cardExpire;
        this.maskedCardNumberFromIsp = cardInfo.cardNumber;
        this.cardHolderNameFromIsp = cardInfo.cardholderName;
        this.emansionVeriTransAccountId = member.eTncMemberId;
      } else if (member.veritransAccountId) {
        this.portasVeriTransId = member.veritransAccountId;
      }
      // VeriTrans 本人認証失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
        this.creditCardAccessToken = '';
        this.creditTokenExpireDate = '';
      }
      this.isLoading = false;
      this.isMounted = true;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }
  },
  methods: {
    async onApply() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      // 一旦エラーメッセージをリセットする
      this.errorMessages = [];

      // バリデーションを実行
      await this.validate();

      if (this.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        this.showErrorMessage();
        return;
      }

      // クレジットカードトークンがない かつ e-mansionに登録された支払方法が存在しない かつ Portasからカード情報をコピーして支払方法を登録しない場合はエラー
      if (this.creditCardAccessToken === '' && !(this.cardExpiredFromIsp && this.maskedCardNumberFromIsp) && !this.isAgreedCopyCardToIsp && !this.noDisplayCreditCardForm) {
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        // エラーメッセージを見せるために画面最上部にスクロール
        this.showErrorMessage();
        return;
      }
      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      /** 契約基本情報をStoreから取得 */
      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];

      if (customer instanceof EMansionCustomer) {
        // サービス登録APIを呼ぶための準備処理
        const optionalData = new EMansionHikariPhoneRegisterRequestOptionalDataDto({
          Hikariphone: {
            preferredDate: formatDate(this.inputFormData.preferredDate, 'YYYY/MM/DD'),
            numberPortabilityFlag: String(this.inputFormData.selectedGetPhoneMethod) === '1' ? '0' : '1',
            portabilityNumber: this.inputFormData.takeOverPhoneNumber,
            relationshipType: this.inputFormData.relationshipType,
            name: this.inputFormData.holderName,
            kana: this.inputFormData.holderNameKana,
            telephoneCompanyType: this.inputFormData.telephoneCompanyType,
            telephoneCompanyOther: this.inputFormData.otherCompanyName,
            destinationZipcode: this.inputFormData.zipcode,
            destinationAddress1: this.inputFormData.address,
            destinationAddress2: this.inputFormData.apartment,
            notificationFlag: String(this.inputFormData.isNotifyNumber) === '1' ? '1' : '0',
            numberDisplay: this.inputFormData.selectedOptionalService.includes('toggle01') ? '1' : '0',
            catch: this.inputFormData.selectedOptionalService.includes('toggle02') ? '1' : '0',
            catchNumberDisplay: this.inputFormData.selectedOptionalService.includes('toggle03') ? '1' : '0',
            optionPack: this.inputFormData.selectedOptionalService.includes('toggle04') ? '1' : '0',
          },
        });

        this.$store.commit('eMansionHikariphoneStore/optionalData', optionalData);
        const servicePlanType = await this.$store.dispatch('servicePlanTypeStore/servicePlanType');

        // 支払方法が登録されている場合
        if (this.noDisplayCreditCardForm) {
          try {
            // サービス登録API(UCOM光電話) を呼び出す
            const registerHikariPhoneInfo = await SpfApiHikariPhoneAccessor.registerHikariPhone(this.eMansionMemberId, this.eMansionPropertyId, servicePlanType, optionalData, '0');
            this.$store.commit('eMansionUcomHikariPhoneRegisterAPIResponseStore/info', registerHikariPhoneInfo);
          } catch (error: any) {
            this.$store.commit('eMansionUcomHikariPhoneRegisterAPIResponseStore/info', null);
            // 上記以外のエラーの場合はe-mansion共通エラー画面に遷移
            await VueErrorHandler.handle(error, '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }
          // お申し込み完了画面に遷移
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/e-mansion/hikari-phone/completed').catch((error: any) => {
            checkRouterError(error);
          });
          return;
          // クレジットカードが e-mansion に登録されている場合の申し込み処理
        } else if (this.emansionVeriTransAccountId) {
          // 申込内容一時保存処理

          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            veriTransAccountId: this.emansionVeriTransAccountId,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            optionalData: optionalData,
            creditCardRegistFlag: 0,
            inputData: this.inputFormData,
          };

          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);

          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-hikari-phone-register',
          });

          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }

          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

          let cardHolderNameForAuthorize: string;
          if (this.cardHolderNameFromIsp) {
            cardHolderNameForAuthorize = this.cardHolderNameFromIsp;
          } else {
            cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
          }

          const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
            uuid: uuidForTemporarySavingApplicationData,
            externalVeritransAccountId: this.emansionVeriTransAccountId,
            cardholderName: cardHolderNameForAuthorize,
          });

          // VeriTrans_本人認証(VeriTrans会員ID使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              this.isSubmitting = false;
              this.isLoading = false;

              // エラーメッセージを見せるために画面最上部にスクロール
              this.showErrorMessage();
              return;
            } else {
              await VueErrorHandler.handle(error, '');
              await this.$router.push('/e-mansion/error').catch((error) => {
                checkRouterError(error);
              });
              this.isLoading = false;
              return;
            }
          }
          return;
        } else if (
          // PortasからISPにカード情報をコピーする場合
          this.isAgreedCopyCardToIsp &&
          this.portasVeriTransId
        ) {
          // 申込内容一時保存処理

          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            veriTransAccountId: this.portasVeriTransId,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            optionalData: optionalData,
            creditCardRegistFlag: 1,
            inputData: this.inputFormData,
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-hikari-phone-register',
          });

          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA), '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            this.isLoading = false;
            return;
          } // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          let cardHolderNameForAuthorize: string;
          if (this.cardHolderNameOnPortas) {
            cardHolderNameForAuthorize = this.cardHolderNameOnPortas;
          } else {
            cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizePortasCard!;
          }

          const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
            uuid: uuidForTemporarySavingApplicationData,
            externalVeritransAccountId: this.portasVeriTransId,
            cardholderName: cardHolderNameForAuthorize,
          });

          // VeriTrans_本人認証(VeriTrans会員ID使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              this.isSubmitting = false;
              this.isLoading = false;

              // エラーメッセージを見せるために画面最上部にスクロール
              this.showErrorMessage();
              return;
            } else {
              await VueErrorHandler.handle(error, '');
              await this.$router.push('/e-mansion/error').catch((error) => {
                checkRouterError(error);
              });
              this.isLoading = false;
              return;
            }
          }
          return;
        } else {
          /**Portas,e-mansionにカードが未登録の場合 */
          // 申込内容一時保存処理
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            creditCardToken: this.creditCardAccessToken,
            tokenExpireDate: this.creditTokenExpireDate,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            optionalData: optionalData,
            creditCardRegistFlag: 1,
            inputData: this.inputFormData,
          };

          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-hikari-phone-register',
          });

          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA), '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            this.isLoading = false;
            return;
          }

          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          // ページ遷移時に beforeEach で会員情報取得できるように null にする
          this.$store.commit('memberStore/member', null);
          const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
            uuid: uuidForTemporarySavingApplicationData,
            creditCardToken: this.cardTokenFor3dSecureAuthorize,
            serviceProviderIsp: SERVICE_PROVIDER.MYE,
          });

          // VeriTrans_本人認証(トークン使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              this.isSubmitting = false;
              this.isLoading = false;

              // エラーメッセージを見せるために画面最上部にスクロール
              this.showErrorMessage();
              return;
            } else {
              await VueErrorHandler.handle(error, '');
              await this.$router.push('/e-mansion/error').catch((error) => {
                checkRouterError(error);
              });
              this.isLoading = false;
              return;
            }
          }
        }
      }
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO), '');
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
      return false;
    },
    async onBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/hikari-phone/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    formatDateToJapanese(date: Date | null): string {
      if (date === null) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      const year = date.getFullYear(); // 年を取得
      const month = date.getMonth() + 1; // 月を取得 (0から始まるため +1)
      const day = date.getDate(); // 日を取得

      return `${year}年${month}月${day}日`;
    },
    /** クレジットカードコンポーネントが再描画されるように、 key値 を更新する */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      this.showErrorMessage();
      return;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** 3Dセキュア本人認証用カード名義人を受け取る */
    getCardHolderNameFromPortas(cardHolderName: string) {
      this.cardHolderNameOnPortas = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、ISP登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizeIspCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizeIspCard = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、Portas登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizePortasCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizePortasCard = cardHolderName;
    },
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      this.isLoading = false;
      this.isSubmitting = false;
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      return;
    },
    /**
     * @param billing 表示する金額
     * @return カンマをなくした金額文字列（例：xx,xxx）
     */
    removeComma(billing: string): string {
      let removed = billing.replace(/,/g, '');
      return removed;
    },
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: string): string {
      const formatter = new Intl.NumberFormat('en-US');
      const formattedValue = formatter.format(Number(billing));
      return formattedValue;
    },
    async validate(): Promise<boolean> {
      if (this.v$.inputFormData.preferredDate?.required.$invalid || this.v$.inputFormData.preferredDate?.isCorrectFormat.$invalid) {
        this.errorMessages.push('正しい「ご利用希望日」を入力してください。');
      } else if (await this.isBeforeAvailableServiceStartDate()) {
        this.errorMessages.push('ご利用希望日が指定可能な日付より前になっています。');
      } else if (this.inputFormData.selectedGetPhoneMethod === '2' && !(await this.isBusinessDay())) {
        this.errorMessages.push('番号ポータビリティの場合「ご利用希望日」は営業日を指定してください。');
      }
      if (this.errorMessages.length > 0) {
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        return false;
      }
      return true;
    },
    // 入力されたご利用希望日が指定可能な日付より前かどうかを判定する
    async isBeforeAvailableServiceStartDate(): Promise<boolean> {
      if (this.inputFormData.selectedGetPhoneMethod === '1' && this.serviceStartDateForRegistration && this.inputFormData.preferredDate) {
        return this.formatDate(new Date(this.inputFormData.preferredDate)) < this.formatDate(new Date(`${this.serviceStartDateForRegistration}`));
      } else if (this.inputFormData.selectedGetPhoneMethod === '2' && this.serviceStartDateForNumberPortability && this.inputFormData.preferredDate) {
        return this.formatDate(new Date(this.inputFormData.preferredDate)) < this.formatDate(new Date(`${this.serviceStartDateForNumberPortability}`));
      } else {
        return false;
      }
    },
    formatDate(date: Date): string {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}/${month}/${day}`;
    },
    async isBusinessDay(): Promise<boolean> {
      const today = new Date();
      //営業日マスタから営業日（UCOM光電話）の9営業日後を取得
      const businessDayList = await SpfApiService.getBusinessdaysUtil('4', today, 9, 1000, 'YYYY/MM/DD', false);
      return businessDayList.some((businessDay) => {
        // 日付を比較するために、時刻部分を切り捨てる
        const businessDateOnly = this.formatDate(new Date(businessDay.businessDate));
        const preferredDateOnly = this.formatDate(new Date(this.inputFormData.preferredDate!));
        return businessDateOnly === preferredDateOnly;
      });
    },
  },
});
</script>
