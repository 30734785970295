<template>
  <div class="e-mansion-ip-phone-terms">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion IPフォン</h1>
    </main>

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion 会員専用トップ</router-link>
        </li>
        <li>e-mansion IPフォン</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
      <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

      <!-- 電気通信事業法に基づく重要事項 -->
      <div class="blc">
        <p>お申し込み前に必ず以下をお読みください。</p>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="電気通信事業法に基づく重要事項" />電気通信事業法に基づく重要事項</h2>
        <p class="mb15">
          <b><span class="red mb10">電気通信事業法に基づく重要事項をご説明します。</span></b><br />
          <p>　</p>
          <a :href="`${eMansionMyUrl}/instructions/downloadPdf.php?function=instructions&apid=${apid}&kind=02`" class="link" target="_blank">こちら</a>をご確認ください。
        </p>
      </div>

      <!-- IPフォン サービス利用規約 -->
      <div class="blc">
        <!-- WIP:PDFのURL後日決まるのでその時更新 -->
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansion IPフォン サービス利用規約" />e-mansion IPフォン サービス利用規約</h2>
        <div class="mb15">
          <a v-if="isTtp" :href="`${eMansionUrl}/pdf/ttp/kiyaku.pdf`" class="link" target="_blank">e-mansion IPフォンサービス利用規約</a>
          <a v-else :href="`${eMansionUrl}/pdf/service/e-ipphonekiyaku.pdf`" class="link" target="_blank">e-mansion IPフォンサービス利用規約</a>
        </div>
      </div>

      <!-- btn-area -->
      <div class="blc">
        <div class="my-checkbox-outer">
          <label>
            <input id="checkbox-to-agree" type="checkbox" v-model="isAgreeTerms" />
            <span for="checkbox-to-agree">電気通信事業法に基づく重要事項およびe-mansion IPフォンサービス利用規約に同意します</span>
          </label>
        </div>
        <div class="btn-area">
          <button type="button" class="btn btn05 bs" v-on:click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          &nbsp;
          <button type="button" class="btn btn01 bs sp-margin" :disabled="!isDisabledButton" v-on:click="onApply()">
            規約に同意して申し込む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- btn-area -->
    </div>
    <!-- contents -->
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
.terms-box {
  height: 240px;
  overflow: auto;
}
.btn-height {
  height: 50px;
}

/* 検索ボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}
.btn {
  cursor: pointer;
}
.my-checkbox-outer > label > input {
  margin-right: 10px;
}
.checkbox-text {
  padding-left: 10px;
  border-left: 3px solid #cf1225;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { E_MANSION_MAIN_ACCOUNT_PLAN_TYPE } from '@/shared/const/e-mansion/e-mansion-main-account-plan-type';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import store from '@/store';
/** Completed コンポーネント */
export default defineComponent({
  name: 'e-mansion-ip-phone-terms',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** サービス利用規約に同意する */
    isAgreeTerms: false,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** e-mansion 物件基本情報  */
    eMansionProperty: null as EMansionProperty | null,
    /** e-mansion のサイト MY URL */
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    /** e-mansion のサイト URL */
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    /** TTP物件 */
    isTtp: true as boolean,
  }),
  computed: {
    /** APIDを取得 */
    apid(): string {
      if (this.eMansionProperty) {
        return this.eMansionProperty.IN_APID;
      } else {
        return '';
      }
    },
    /** 9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    isDisabledButton(): boolean {
      if (!this.isAgreeTerms || this.errorMessages.length > 0 || this.isLoading) {
        return false;
      }
      return true;
    },
    buttonColorSet(): { [key: string]: boolean } {
      return {
        'btn btn04 bs': !this.isAgreeTerms,
        'btn btn01 bs': this.isAgreeTerms,
      };
    },
  },
  async mounted() {
    try {
      // 未ログイン、会員・物件が無い、UA種別がe-mansionでない場合は総合TOPに遷移
      if (!(await MountedCheckService.canReadEMansionScreen())) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 契約/物件 情報取得(dispatch)
      await StoreExternalApiResponse.main();
      // 物件基本情報.APID URLに利用
      this.eMansionProperty = store.getters['eMansionCommonStore/property'];

      // TTP物件情報取得
      this.isTtp = this.isPropertyTypeTtp();

      /**
       * 契約基本情報取得API.オプション利用状況.IPフォンに要素が存在する（＝契約中）の場合、
       * IPフォン ご利用状況画面へリダイレクト（Portas離脱）させる
       */
      const eMansionCustomer = store.getters['eMansionCommonStore/customer'];
      if (!eMansionCustomer || !eMansionCustomer.op_ipp_contract_status) {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
      }
      if (eMansionCustomer.op_ipp_contract_status === '1') {
        window.location.href = `${process.env.VUE_APP_E_MANSION_MY_URL}/login/login.php?function=IPPHONE&apid=${this.apid}&rd=${this.createRd}`;
        // isLoadingはそのまま
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      // ISP共通エラー画面に遷移
      return await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
    window.scrollTo(0, 0);
    this.isLoading = false;
  },
  methods: {
    isPropertyTypeTtp() {
      // TTP物件の場合にTrueと判断
      if (this.eMansionProperty instanceof EMansionProperty) {
        const apartmentSpecialTypeFlag = this.eMansionProperty.apartment_special_type_flag;
        if (!apartmentSpecialTypeFlag) {
          return false;
        }
        if (!apartmentSpecialTypeFlag[0]) {
          return false;
        }
        const flags = apartmentSpecialTypeFlag[0];
        const FLAG_TRUE = '1';
        return flags[E_MANSION_MAIN_ACCOUNT_PLAN_TYPE.TTP] === FLAG_TRUE;
      }
      return false;
    },
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.$store.commit('eMansionIPPhoneStore/from', null);
          this.isSubmitting = false;
        });
    },
    async onApply() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      if (this.isAgreeTerms) {
        await this.$router
          .push('/e-mansion/ip-phone/apply')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.$store.commit('eMansionIPPhoneStore/from', null);
            this.isSubmitting = false;
          });
      }
    },
  },
});
</script>
