/** five-a Connectix API : エラー時のレスポンス */
export class FiveAConnectixErrorResponse {
  /** エラーメッセージ */
  public errorInfo!: Array<string>;
  public status?: string;
  public code?: string;
  public information?: string;

  constructor(partial: Partial<FiveAConnectixErrorResponse>) {
    Object.assign(this, partial);
  }
}
