<template>
  <div class="hikariphone-service-terms">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/e-mansion">e-mansion会員専用トップ</a></li>
        <li>UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <div class="blc">
        <p>お申し込み前に必ず以下をお読みください。</p>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="電気通信事業法に基づく重要事項" />電気通信事業法に基づく重要事項</h2>
        <p class="mb15">
          <b><span class="red mb10">電気通信事業法に基づく重要事項をご説明します。</span></b>
        </p>
        <p><a class="link" :href="`${eMansionMyUrl}/instructions/downloadPdf.php?function=instructions&apid=${apid}&kind=05`" target="_blank">こちら</a>をご確認ください。</p>
      </div>

      <div class="blc blc-spacing">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOM光電話 サービス利用規約" />UCOM光電話　サービス利用規約</h2>
        <a class="link" :href="`${eMansionUrl}/pdf/bud01/hikaridenwakiyaku.pdf`" target="_blank"> UCOM光電話サービス利用規約 </a>
      </div>

      <div class="blc">
        <div class="my-checkbox-outer">
          <label>
            <input id="checkbox-to-agree" type="checkbox" v-model="isAgreeTerms" />
            <span for="checkbox-to-agree">電気通信事業法に基づく重要事項およびUCOM光電話サービス利用規約に同意します</span>
          </label>
        </div>
        <div class="btn-area">
          <button class="btn btn05 bs" v-on:click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn-height btn btn01 bs" v-on:click="onNext()" type="button" :disabled="isDisabledButton">
            規約に同意して申し込む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.condition {
  margin: 0 0 5px;
  border-left: 3px solid #cf1225;
  padding-left: 15px;
  margin-left: 10px;
}
div.template {
  margin-top: 30px;
}

div.blc-spacing {
  margin-top: 80px;
}
button.btn {
  margin-top: 10px;
}
.my-checkbox-outer > label > input {
  margin-right: 10px;
}
/* 検索ボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
a.biling-link {
  color: #cf1225;
  text-decoration: underline;
}
a.biling-link:hover {
  color: #0060ff;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { VueErrorHandler } from '@/handler/error/vue-error-handler';

/** UCOM光電話 サービス規約 コンポーネント */
export default defineComponent({
  name: 'hikariphone-service-terms',
  components: {
    LoadingComponent,
  },
  data(): {
    // ロード中
    isLoading: boolean;
    // 会員情報
    member: Member | null;
    // 会員ステータス
    memberStatus: MemberStatus | null;
    // 規約に同意するか
    isAgreeTerms: boolean;
    // e-mansionのMyページURL
    eMansionMyUrl: string;
    // e-mansionのURL
    eMansionUrl: string;
    // 物件ID
    apid: string;
  } {
    return {
      member: null,
      memberStatus: null,
      isAgreeTerms: false,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
      apid: '',
      isLoading: true,
    };
  },
  computed: {
    // チェックボックスによる「申し込む」ボタンの判定
    isDisabledButton(): boolean {
      if (this.isAgreeTerms) {
        return false;
      }
      return true;
    },
  },
  //ログインチェック、物件チェック、ほか、確かめる内容を追加
  async mounted(): Promise<void> {
    try {
      if (!(await MountedCheckService.canReadEMansionScreen())) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      this.member = this.$store.getters['memberStore/member'];
      const eMansionMemberId = this.member?.primaryKeyMye ?? '';
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];
      if (!this.memberStatus) {
        // 会員情報が取得できない場合、総合トップに遷移
        this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      // 物件情報を取得する
      const property = this.$store.getters['propertyStore/property'];

      if (property?.apartmentId) {
        this.apid = convert6DigitIdTo8DigitId(property?.apartmentId);
      }

      // 契約基本情報取得 API 用のクエリ
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property?.uaType,
        apartment_id: property.apartmentId,
      });

      const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });
      if (customer instanceof EMansionCustomer) {
        if (customer.op.hikariphone.length !== 0) {
          //正常ケースの場合、既に UCOM光電話 を契約中の場合は UCOM光電話ご利用状況 画面へリダイレクトする
          await this.$router.push('/e-mansion/hikari-phone/usage').catch((e) => {
            checkRouterError(e);
          });
        }
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }

    this.isLoading = false;
  },
  methods: {
    async onNext(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // UCOM光電話 お申込み画面への遷移
      if (this.isAgreeTerms) {
        await this.$router.push('/e-mansion/hikari-phone/input').catch((e) => {
          checkRouterError(e);
        });
      }
      this.isLoading = false;
    },
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // e-mansion会員専用トップへの遷移
      await this.$router.push('/e-mansion').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
    },
  },
});
</script>
