<template>
  <div class="error">
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1 v-if="isSpecialErrorTitle()">{{ specialTitle }}</h1>
      <h1 v-else>エラーが発生しました</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- blc -->
      <div class="blc" v-if="isSpecialErrorMessage()">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="申し訳ございません。エラーが発生しました。" />大変申し訳ございません。</h2>
        <p class="pre">{{ specialMessage }}</p>
      </div>
      <div class="blc" v-else>
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="申し訳ございません。エラーが発生しました。" />大変申し訳ございません。</h2>
        <p>ご迷惑をおかけし申し訳ございません。<br />大変お手数をおかけしますが、トップページから再度お手続きをしていただきますようお願いいたします。</p>
      </div>
      <!-- /blc -->

      <!-- sblc -->
      <div class="sblc">
        <h3 class="service-h3">サポートセンター お問合せ先</h3>
        <div>
          <p>建物専用ホームページ「<a class="link" :href="`${domainName}/${apid}/ft5/`" target="_blank">よくあるご質問</a>」からご連絡ください</p>
        </div>
      </div>
      <!-- /sblc -->
    </div>
    <!-- /contents -->
  </div>
</template>
<style scoped>
.pre {
  white-space: pre-line;
}
.link {
  text-align: center;
}
.btn {
  line-height: 24px;
}
</style>
<script lang="ts">
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { Member } from '@/shared/classes/spf-api/member';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { Property, SearchPropertyDetail, SearchResultOver } from '@/shared/classes/spf-api/property';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { PROPERTY_SEARCH_TYPE, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert6DigitIdTo8DigitId, convert8DigitIdTo6digitId } from '@/shared/util/convert';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

/** エラー画面 */
export default defineComponent({
  name: 'five-a-error',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoggedIn: false,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    specialTitle: '',
    specialMessage: '',
    /** e-mantion My の URL */
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    apid: null as string | null, // apid
    uaType: '',
    transitionSourceServiceId: '',
    oemsMaster: [] as Array<GetOemsMasterResponse>, // OEMマスタ
    domainName: '',
    memberStatus: '',
  }),
  async mounted() {
    this.$data.isLoggedIn = await AuthService.isLoggedIn();
    try {
      //storeから取得
      this.specialTitle = this.$store.getters['errorStore/title'];
      this.specialMessage = this.$store.getters['errorStore/messages'];
      //Storeをリセット
      this.$store.commit('errorStore/title', undefined);
      this.$store.commit('errorStore/messages', undefined);

      /** 会員情報を取得 */
      const member: Member = this.$store.getters['memberStore/member'];
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];

      // 顧客情報に物件情報が紐づけられていない場合
      if (!member || !member.propertyId || !this.memberStatus) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // 物件情報を取り出す
      const property: Property = this.$store.getters['propertyStore/property'];

      if (!property?.apartmentId) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 問い合わせ先URLをOEM考慮して取得
      if (property.apartmentId) {
        this.uaType = UA_TYPE.FIVE_A;
        // APID → apartment_idに変換
        this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
      }

      // 物件検索API実行
      const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(PROPERTY_SEARCH_TYPE.E_MANSION, String(property.apartmentId), false);

      //ドメイン名の取得
      if (propertyList instanceof SearchResultOver || propertyList.length === 0 || propertyList.length >= 2) {
        // 検索結果が11件以上 || 取得件数がl件でない場合 でありえないエラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      } else {
        if (this.uaType === UA_TYPE.FIVE_A && propertyList[0].oemType) {
          this.oemsMaster = await this.$store.dispatch('oemsStore/oems');
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const filtered = this.oemsMaster.filter((oem: any) => oem.oemType === String(propertyList[0].oemType));
          if (filtered.length >= 1) {
            let latestOem = filtered[0];
            if (filtered.length >= 2) {
              for (let i = 1; i < filtered.length; i++) {
                if (latestOem.startAt < filtered[i].startAt) {
                  latestOem = filtered[i];
                }
              }
            }
            this.domainName = latestOem.domainForOsumai;
          }
        } else {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
        }
      }
      this.isLoading = false;
    } catch (e) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }
  },
  methods: {
    isSpecialErrorMessage(): boolean {
      if (!this.specialMessage) {
        return false;
      }
      if (this.specialMessage.length > 0) {
        return true;
      }
      return false;
    },
    isSpecialErrorTitle(): boolean {
      if (!this.specialTitle) {
        return false;
      }
      if (this.specialTitle.length > 0) {
        return true;
      }
      return false;
    },
  },
});
</script>
