<template>
  <div class="completed">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion 接続コース変更</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li class="stay">接続コース変更</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>接続コース変更</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>

      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="接続コース変更　完了" />接続コース変更　お申し込み受付完了</h2>

        <div class="sblc">
          <p><b class="red">接続コース変更のお申し込みを受け付けました。</b></p>
          <p class="title-margin">お手続きありがとうございました。</p>
          <p class="title-margin"><li class="course-complete-att course-margin">コース変更お申し込み後、31日間は再変更できませんので予めご了承ください。</li></p>
          <p>
            <li class="course-complete-att course-margin">
              変更後コースでのお支払いは、「コース変更工事完了日」が1日～20日までの場合は翌月1日から、21日～月末までの場合は翌々月1日からとなります。
            </li>
          </p>
        </div>

        <div class="sblc">
          <h3 class="service-h3">接続コース変更のタイミングについて</h3>
        </div>

        <div class="sblc" v-if="showTpl === '01' || showTpl === '02' || showTpl === '03' || showTpl === '04'">
          <table class="table-type2 billing-table">
            <tr v-if="showTpl === '01'">
              <td class="tableGray constructionCompletion"><b>コース変更工事完了日</b></td>
              <td v-if="apartmentIndicator" class="commonPaddingTd switch_apartment_indicator" v-text="apartmentIndicator"></td>
              <td v-else class="commonPaddingTd">鍵のお引渡し日</td>
            </tr>
            <tr v-if="showTpl === '02'">
              <td class="tableGray constructionCompletion"><b>コース変更工事完了日</b></td>
              <td v-if="apartmentIndicator" class="commonPaddingTd switch_apartment_indicator" v-text="apartmentIndicator"></td>
              <td v-else class="commonPaddingTd">{{ formatDate(dispConstructionCompleteDate) }}</td>
            </tr>
            <tr v-if="showTpl === '03'">
              <td class="tableGray constructionCompletion"><b>コース変更工事日</b></td>
              <td v-if="apartmentIndicator" class="commonPaddingTd switch_apartment_indicator" v-text="apartmentIndicator"></td>
              <td v-else class="commonPaddingTd">
                およそ2～3週間程度で接続コース変更の工事が行われます。<br />
                工事日が決定次第、郵送でお知らせしますので、しばらくお待ちください。<br />
                ※お客様のお部屋内での工事は発生しません。
              </td>
            </tr>
            <tr v-if="showTpl === '04'">
              <td class="tableGray constructionCompletion"><b>コース変更工事日</b></td>
              <td v-if="apartmentIndicator" class="commonPaddingTd switch_apartment_indicator" v-text="apartmentIndicator"></td>
              <td v-else class="commonPaddingTd">
                お客様のご連絡先お電話番号宛てに、弊社指定業者より「接続コース変更工事」の実施日について最長2週間程度でご連絡します。工事日のご希望に関しては、その際に直接お申し付けください。
              </td>
            </tr>
            <tr v-if="showTpl === '04'">
              <td class="tableGray constructionCompletion"><b>工事手順</b></td>
              <td class="commonPaddingTd">数日中に、ご案内資料をお送りします。</td>
            </tr>
          </table>
          <p v-if="showTpl === '04'">※現地調査の結果、コース変更工事が実施できない場合がございます。予めご了承ください。</p>
        </div>

        <div class="sblc">
          <h3 class="service-h3">変更後のインターネット接続サービスについて</h3>
        </div>
        <div class="sblc">
          <table class="table-type2 billing-table">
            <tr>
              <td class="tableGray otherCourseService"><b>速度（タイプ）</b></td>
              <td class="commonPaddingTd">{{ storeSelectedSpeed }} ({{ mySpeedType }})</td>
            </tr>
            <tr v-if="!checkMyCurrentCourseId">
              <td class="tableGray otherCourseService"><b>接続機器のご利用形態</b></td>
              <td class="commonPaddingTd">レンタル</td>
            </tr>
          </table>
        </div>
        <div class="sblc">
          <p>
            ※
            本サービスはベストエフォート式のサービスのため、実使用速度を保証するものではありません。速度は技術規格上での最大値であり、通信速度は時間帯・状況等により異なる場合があります。
          </p>
          <p>
            ※
            住戸内に設置されている機器（スイッチングハブなど）または接続する端末、ケーブル等により技術規格上の最大通信速度が変わるため、上記の通信速度（ベストエフォート）の規格にてご利用になりたい場合、機器、端末、ケーブル等をお客様にて変更いただくケースがございます。
          </p>
          <p>※ 住戸内設置の機器（建物所有者様、区分所有者様の所有物）の仕様については建物によって異なりますので居住者様にてご確認ください。</p>
          <p v-if="damagechargeFlag">※ 接続機器はレンタル品のため、お客様の故意または過失による故障や破損の場合、端末機器損害金21,780円（課税対象外）を請求いたします。</p>
        </div>

        <div class="sblc">
          <h3 class="service-h3">インターネット接続サービス ご契約内容のご案内</h3>
        </div>
      </div>
      <div class="text-margin"></div>
      <table class="table-type2 billing-table">
        <tbody>
          <tr>
            <td v-if="campaignCoursechgCourse" colspan="2" class="switch_apartment_indicator" v-text="campaignCoursechgCourse"></td>
          </tr>
          <tr>
            <td v-if="campaignCoursechgOther" colspan="2" class="switch_apartment_indicator" v-text="campaignCoursechgOther"></td>
          </tr>
          <tr>
            <th class="va-middle tableGray contractDetails">月額基本料</th>
            <td v-if="basicFeeText" class="commonPaddingTd">
              {{ basicFeeText }}
            </td>
            <td v-else-if="basicFee === '0'" class="commonPaddingTd">
              {{ '管理費とあわせてお支払いいただきます。なお、賃貸居住の方はオーナー様よりお支払いいただいております。' }}
            </td>
            <td v-else class="commonPaddingTd">
              {{ formatCurrency(basicFee) }} 円
              <span v-if="basicFeeCp && basicFeeCp != '0'"
                >（キャンペーン適用時： <b class="red">{{ formatCurrency(basicFeeCp) }} 円</b>）</span
              >
            </td>
          </tr>
          <tr v-if="tesuryoFee">
            <th class="va-middle tableGray contractDetails">コース変更手数料（初回のみ）</th>
            <td v-if="tesuryoFee" class="commonPaddingTd">
              {{ formatCurrency(tesuryoFee) }} 円
              <span v-if="tesuryoFeeCp && tesuryoFeeCp != '0'"
                >（キャンペーン適用時： <b class="red">{{ formatCurrency(tesuryoFeeCp) }} 円</b>）</span
              >
            </td>
          </tr>
          <tr v-if="constructionFee">
            <th class="va-middle tableGray contractDetails">工事費（初回のみ）</th>
            <td class="commonPaddingTd">
              {{ formatCurrency(constructionFee) }} 円<span v-if="constructionFeeCp && constructionFeeCp != '0'"
                >（キャンペーン適用時： <b class="red">{{ formatCurrency(constructionFeeCp) }} 円</b>）</span
              >
            </td>
          </tr>
          <tr v-if="mySpeedType === 'VDSL' && (myFeeModemCp || myFeeModem) && !checkMyCurrentCourseId">
            <th class="va-middle tableGray contractDetails">モデムレンタル料（月額）</th>
            <td v-if="myFeeModemCp" class="commonPaddingTd">
              <b class="red">{{ formatCurrency(myFeeModemCp) }} 円</b>
            </td>
            <td v-else class="commonPaddingTd">{{ formatCurrency(myFeeModem) }} 円</td>
          </tr>
          <tr v-if="mySpeedType === 'FTTH' && (myFeeModemCp || myFeeModem) && !checkMyCurrentCourseId">
            <th class="va-middle tableGray contractDetails">メディアコンバーターレンタル料（月額）</th>
            <td v-if="myFeeModemCp" class="commonPaddingTd">
              <b class="red">{{ formatCurrency(myFeeModemCp) }} 円</b>
            </td>
            <td v-else class="commonPaddingTd">{{ formatCurrency(myFeeModem) }} 円</td>
          </tr>
          <tr v-if="!freeTotalFee">
            <th class="va-middle tableGray contractDetails">総額</th>
            <td class="commonPaddingTd">
              初回： {{ campaignFee }} 円 / 次回以降： {{ RegularFee }} 円<br />
              {{ isContainCpFee }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!freeTotalFee">
        <p>価格は全て税込表示（消費税10%）です。今後消費税が改訂された場合は、改定後の税率による価格に変更となります。</p>
      </div>
      <!-- ご契約内容の交付方法 -->
      <table class="table-type2">
        <tbody>
          <tr>
            <th>ご契約内容の交付方法</th>
            <td>
              <span v-if="destinationKind == '1'">
                <p>ご登録いただいた以下住所に郵送いたします。</p>
                <br />
                〒{{ formatPostalCode(destinationZipcode) }}<br />
                {{ destinationAddress1 }}　{{ destinationAddress2 }}
              </span>
              <span v-else> ご登録いただいたメールアドレス「{{ destinationMailAddress }}」へ送信いたします。 </span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <p>「ご契約内容のご案内」は、e-mansion会員専用トップページ「契約内容のご案内」で、受付日から30日間ご確認いただけます。</p>
            </td>
          </tr>
        </tbody>
      </table>

      <ContractDetailsConfirmMethod />

      <div class="btn-area-course">
        <button class="btn btn01 bs" @click="onNext">
          e-mansion会員専用トップページへ
          <i class="material-icons link link-icon">east</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import ContractDetailsConfirmMethod from '@/shared/components/e-mansion/contract-details-confirm-method.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { EMansionEntryInputForm } from '../entry/classes/entry-input-form';

export default defineComponent({
  name: 'platform-terms-i-filter',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
    CreditCardComponent,
    ContractDetailsConfirmMethod,
  },
  data(): {
    isNotifyEmail: boolean;
    // e-mansion サイト URL
    eMansionUrl: string;
    // 8桁APIDE
    apid: string;
    /** エラーメッセージを格納する配列 */
    errorMessages: string[];
    errorMessageTitle: string;
    eMansionMyUrl: string;
    eMansionCourseUrl: string;

    // ここから本体に必要な変数
    downgFee: string;
    chgConstructionCp: string;
    storeSelectedSpeed: string;
    storeSelectedCharge: string;
    storeEquipmentcontract: string;
    constructionFee: string;
    constructionFeeCp: string;
    campaignCoursechgCourse: string;
    campaignCoursechgOther: string;
    currentGrade: string;
    storeGrade: string;
    // 初回申し込みの合計金額
    limitedFirstFee: string;
    // 通常の合計価格
    RegularFee: string;
    // 支払情報の中にキャンペーン価格を含んでいるかどうかのフラグ
    isContainCpFee: string;
    storetype: string;
    chargeCampaign: string;
    mySpeedType: string;
    customer: any;
    showTpl: string;
    dispConstructionCompleteDate: string;
    basicFee: string;
    basicFeeCp: string;
    tesuryoFee: string;
    tesuryoFeeCp: string;
    adapterCharge: string;
    adapterChargeCp: string;
    myFeeModem: string;
    myFeeModemCp: string;
    myFeeMedia: string;
    campaignFee: string;
    apartmentIndicator: string;
    checkMyCurrentCourseId: boolean;
    damagechargeFlag: boolean;
    basicFeeText: string;
    isLoading: boolean;
    destinationKind: string | undefined;
    destinationMailAddress: string | undefined;
    destinationZipcode: string | undefined;
    destinationAddress1: string | undefined;
    destinationAddress2: string | undefined;
    freeTotalFee: boolean;
    isContainCpFeeFlag: boolean;
    rentalFee: number;
    rentalFeeCp: number;
  } {
    return {
      isNotifyEmail: false,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      apid: '',
      errorMessages: [] as string[],
      /** エラーメッセージ部に表示するタイトル */
      errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
      eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
      eMansionCourseUrl: '',
      downgFee: '',
      chgConstructionCp: '',
      storeSelectedSpeed: '',
      storeSelectedCharge: '',
      storeEquipmentcontract: '',
      constructionFee: '',
      constructionFeeCp: '',
      campaignCoursechgCourse: '',
      campaignCoursechgOther: '',
      currentGrade: '',
      storeGrade: '',
      limitedFirstFee: '',
      RegularFee: '',
      isContainCpFee: '',
      storetype: '',
      chargeCampaign: '',
      mySpeedType: '',
      customer: {} as EMansionEntryInputForm,
      showTpl: '',
      dispConstructionCompleteDate: '',
      basicFee: '',
      basicFeeCp: '',
      tesuryoFee: '',
      tesuryoFeeCp: '',
      adapterCharge: '',
      adapterChargeCp: '',
      myFeeModem: '',
      myFeeModemCp: '',
      myFeeMedia: '',
      campaignFee: '',
      apartmentIndicator: '',
      checkMyCurrentCourseId: false,
      damagechargeFlag: false,
      basicFeeText: '',
      isLoading: false,
      destinationKind: '' as string,
      destinationMailAddress: '' as string | undefined,
      destinationZipcode: '' as string | undefined,
      destinationAddress1: '' as string | undefined,
      destinationAddress2: '' as string | undefined,
      freeTotalFee: false,
      isContainCpFeeFlag: false,
      rentalFee: 0,
      rentalFeeCp: 0,
    };
  },
  //ログインチェック、物件チェック、ほか、確かめる内容を追加
  async mounted(): Promise<void> {
    this.isLoading = true;
    /** ログインしているか否かの情報を取得 */
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];

    if (property?.IN_APID) {
      this.apid = property?.IN_APID;
    }

    // apid追加
    this.apid = property.IN_APID;
    this.eMansionCourseUrl = ` ${this.eMansionMyUrl}/instructions/downloadPdf.php?function=instructions&apid=${this.apid}&kind=03`;

    //確認画面から受けたストア
    this.showTpl = this.$store.getters['selectedCourseConfirmStore/showTpl'];
    this.dispConstructionCompleteDate = this.$store.getters['selectedCourseConfirmStore/dispConstructionCompleteDate'];

    // 確認画面と、ベリトランスで受けたストア
    this.basicFee = await this.$store.getters['selectedCourseConfirmStore/basicFee'];
    this.basicFeeCp = await this.$store.getters['selectedCourseConfirmStore/basicFeeCp'];
    this.tesuryoFee = await this.$store.getters['selectedCourseConfirmStore/tesuryoFee'];
    this.tesuryoFeeCp = await this.$store.getters['selectedCourseConfirmStore/tesuryoFeeCp'];
    this.constructionFee = await this.$store.getters['selectedCourseConfirmStore/chgConstruction'];
    this.constructionFeeCp = await this.$store.getters['selectedCourseConfirmStore/chgConstructionCp'];
    this.adapterCharge = await this.$store.getters['selectedCourseConfirmStore/adapterCharge'];
    this.adapterChargeCp = await this.$store.getters['selectedCourseConfirmStore/adapterChargeCp'];
    this.isContainCpFeeFlag = await this.$store.getters['selectedCourseStore/isContainCpFee'];

    //入力画面から受け取ったストア
    this.storeSelectedSpeed = await this.$store.getters['selectedCourseStore/speed'];
    this.storeSelectedCharge = await this.$store.getters['selectedCourseStore/charge'];
    this.storeEquipmentcontract = await this.$store.getters['selectedCourseStore/equipmentcontract'];
    this.storetype = await this.$store.getters['selectedCourseStore/type'];

    // 交付方法のセッション情報
    this.destinationKind = await this.$store.getters['selectedCourseStore/contractDestinationKind'];
    this.destinationZipcode = await this.$store.getters['selectedCourseStore/contractDestinationZipcode'];
    this.destinationAddress1 = await this.$store.getters['selectedCourseStore/contractDestinationAddress1'];
    this.destinationAddress2 = await this.$store.getters['selectedCourseStore/contractDestinationAddress2'];

    if (this.destinationKind == '3') {
      this.destinationMailAddress = await this.$store.getters['selectedCourseStore/emailaddress'];
    } else {
      this.destinationMailAddress = await this.$store.getters['selectedCourseStore/contractDestinationMailaddress'];
    }

    // 接続機器のご利用形態のフラグ
    this.checkMyCurrentCourseId = await this.$store.getters['selectedCourseStore/checkMyCurrentCourseId'];

    // 支払方法がイレギュラーの場合、課金額が０円の場合は文言を表示
    if (property.em_payir_flag === '0') {
      this.basicFeeText = '管理組合様にお問合せください。';
      this.basicFee = '';
    }

    //物件基本情報取得API.切り替え物件回線切り替え目安
    this.importApartmentIndicator();

    if (!this.checkMyCurrentCourseId && property.damagecharge_flag == '1') {
      this.damagechargeFlag = true;
    }

    if (property.cp_info_message && property.cp_info_message[0] && property.cp_info_message[0].campaign_coursechg_course) {
      this.campaignCoursechgCourse = this.formatNewLines(property.cp_info_message[0].campaign_coursechg_course);
    }

    if (property.cp_info_message && property.cp_info_message[0] && property.cp_info_message[0].campaign_coursechg_other) {
      this.campaignCoursechgOther = this.formatNewLines(property.cp_info_message[0].campaign_coursechg_other);
    }

    // モデムやメディアコンバーターがある際の表示
    if (this.storetype === '01' || this.storetype === '02' || this.storetype === '03' || this.storetype === '04') {
      this.mySpeedType = 'VDSL';
      if (this.adapterChargeCp != '0' && this.adapterChargeCp && !this.checkMyCurrentCourseId) {
        this.myFeeModemCp = this.adapterChargeCp;
      } else if (this.adapterCharge && !this.checkMyCurrentCourseId) {
        this.myFeeModem = this.adapterCharge;
      }
    }

    if (this.storetype === '06') {
      this.mySpeedType = 'FTTH';
      if (this.adapterChargeCp != '0' && this.adapterChargeCp && !this.checkMyCurrentCourseId) {
        this.myFeeModemCp = this.adapterChargeCp;
      } else if (this.adapterCharge && !this.checkMyCurrentCourseId) {
        this.myFeeModem = this.adapterCharge;
      }
    }

    if (this.storetype === '05') {
      this.mySpeedType = 'LAN';
    }

    if (this.isContainCpFeeFlag) {
      this.isContainCpFee = '※「次回以降」の料金は、キャンペーンが適用されない通常時における総額を表示しています。';
    }

    this.calculationRegularFee();
    this.calculationCampaignFee();

    if ((!this.RegularFee || this.RegularFee === '0') && (!this.campaignFee || this.campaignFee === '0')) {
      this.freeTotalFee = true;
    }

    this.isLoading = false;
    return;
  },
  methods: {
    async onNext(): Promise<void> {
      if (this.isLoading) {
        return;
      }

      this.$store.commit('selectedCourseStore/contractDestinationKind', null);
      this.$store.commit('selectedCourseStore/contractDestinationMailaddress', null);
      this.$store.commit('selectedCourseStore/contractDestinationMailaddressConfirm', null);
      this.$store.commit('selectedCourseStore/contractDestinationZipcode', null);
      this.$store.commit('selectedCourseStore/contractDestinationAddress1', null);
      this.$store.commit('selectedCourseStore/contractDestinationAddress2', null);
      this.$store.commit('selectedCourseStore/courseId', undefined);
      this.$store.commit('selectedCourseStore/zipcodeFormer', null);
      this.$store.commit('selectedCourseStore/zipcodeLatter', null);

      this.isLoading = true;
      this.errorMessages = [];
      await this.$router.push('/e-mansion').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
      return;
    },

    formatPostalCode(postalCode: string | undefined): string {
      if (!postalCode) {
        return '';
      }
      // 正規表現でハイフンを追加
      return postalCode.replace(/^(\d{3})(\d{4})$/, '$1-$2');
    },

    calculationRegularFee(): void {
      if (!this.basicFeeText) {
        if (this.basicFee != '0' && this.basicFee) {
          this.rentalFee = parseFloat(this.basicFee?.replace(',', '') || '0');
        }
      }
      const adapterChargeCp = parseFloat(this.adapterChargeCp?.replace(',', '') || '0');
      const adapterCharge = parseFloat(this.adapterCharge?.replace(',', '') || '0');

      if (adapterChargeCp) {
        const totalFee = this.rentalFee + (adapterChargeCp || 0);
        this.RegularFee = totalFee.toLocaleString();
        return;
      } else if (adapterCharge) {
        const totalFee = this.rentalFee + (adapterCharge || 0);
        this.RegularFee = totalFee.toLocaleString();
        return;
      } else {
        const totalFee = this.rentalFee;
        this.RegularFee = totalFee.toLocaleString();
        return;
      }
    },

    calculationCampaignFee(): void {
      if (!this.basicFeeText) {
        if (this.basicFee && this.basicFee != '0') {
          this.rentalFeeCp = parseFloat(this.basicFee?.replace(',', '') || '0');
        }
        if (this.basicFeeCp && this.basicFeeCp != '0') {
          this.rentalFeeCp = parseFloat(this.basicFeeCp?.replace(',', '') || '0');
        }
      }
      const tesuryoFeeCp = parseFloat(this.tesuryoFeeCp?.replace(',', '') || this.tesuryoFee?.replace(',', '') || '0');
      const constructionFeeCp = parseFloat(this.constructionFeeCp?.replace(',', '') || this.constructionFee?.replace(',', '') || '0');
      const adapterChargeCp = parseFloat(this.adapterChargeCp?.replace(',', '') || this.adapterCharge?.replace(',', '') || '0');
      const totalFee = this.rentalFeeCp + (tesuryoFeeCp || 0) + (constructionFeeCp || 0) + (adapterChargeCp || 0);
      this.campaignFee = totalFee.toLocaleString();
      return;
    },

    formatCurrency(value: any) {
      const num = parseInt(value.replace(/,/g, ''), 10);
      return num.toLocaleString();
    },

    formatDate(dateString: string): string {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}年${month}月${day}日`;
    },

    //物件基本情報取得API.切り替え物件回線切り替え目安がhtml
    async importApartmentIndicator() {
      const property = this.$store.getters['eMansionCommonStore/property'];
      if (property.switch_apartment_flag === '1') {
        this.apartmentIndicator = this.formatNewLines(property.switch_apartment_indicator);
      }
    },

    //物件基本情報取得API.切り替え物件回線切り替え目安フォーマット
    formatNewLines(text: string) {
      if (!text) return '';
      return text.replace(/<br \/?>/g, '');
    },

    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
  },
});
</script>

<style scoped>
.title-margin {
  margin-top: 10px !important;
}
.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}
.centered-text {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
.centered-text b {
  display: inline-block;
  text-align: left;
  margin: 0 auto;
}
.text-margin {
  margin-bottom: 20px;
}
.table {
  table-layout: fixed;
  width: 100%;
}

.table-type2 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.table-type2 th,
.table-type2 td {
  border-right: 1px solid #ddd;
}

.tableGray {
  background-color: #e6e6e6 !important;
}

.temp-css {
  min-width: 130px;
}

.btn-area-course {
  margin: 100px auto 0;
  text-align: center;
}

.btn-area-course {
  margin: 20px auto 0;
  text-align: center;
}

.constructionCompletion {
  width: 240px;
  padding: 15px 10px;
}

.otherCourseService {
  width: 240px;
  padding: 15px 10px;
}

.contractDetails {
  width: 240px;
  padding: 15px 10px;
}
p.course-att li {
  font-size: 13px !important;
  padding-left: 1.5em !important;
  text-indent: -1.5em !important;
}
body p.course-att li {
  font-size: 13px !important;
}

/* Firefox専用のCSS */
@-moz-document url-prefix() {
  p.course-att li {
    font-size: 13px !important;
    padding-left: 1.5em !important;
    text-indent: -1.5em !important;
    margin-left: 13px;
  }
  .course-margin {
    margin-left: 15px;
  }
}

.switch_apartment_indicator {
  white-space: pre-wrap;
}
.campaign-text {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
  margin-left: 0.5rem;
}
@media only screen and (max-width: 767px) {
  .constructionCompletion {
    padding: 15px 10px;
    display: block;
    width: inherit;
  }
  .otherCourseService {
    padding: 15px 10px;
    display: block;
    width: inherit;
  }
  .contractDetails {
    padding: 15px 10px;
    display: block;
    width: inherit;
  }
  .commonPaddingTd {
    padding: 15px 10px !important;
  }
}
</style>
