/** ご利用状況表示用　 契約基本情報取得API.オプション利用状況.固定GIP　DTO*/
export class GipForDispDTO {
  /** MACアドレス */
  public macaddress!: string;
  /** ご利用状況 */
  public status!: string;
  /** 解約日 */
  public end_time?: string;
  constructor(partial: Partial<GipForDispDTO>) {
    Object.assign(this, partial);
  }
}
