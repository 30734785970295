// e-mansion UCOM光電話用DTO

/** e-mansion UCOM光電話 サービス登録API リクエスト 個別パラメータ DTO */
export class EMansionHikariPhoneRegisterRequestOptionalDataDto {
  readonly Hikariphone: HikariphoneRequest | undefined;

  constructor(partial: Partial<EMansionHikariPhoneRegisterRequestOptionalDataDto>) {
    Object.assign(this, partial);
  }
}

/** e-mansion UCOM光電話 サービス登録API リクエスト 個別パラメータ DTO */
export class HikariphoneRequest {
  /** ご利用開始日(YYYY/MM/DD) */
  readonly preferredDate: string | undefined;
  /** 電話番号取得方法 0:新しく取得 1:番号ポータビリティ */
  readonly numberPortabilityFlag!: string | undefined;
  /** 引継ぎ電話番号 番ポの場合必須 ^[0-9]{2,4}-[0-9]{2,4}-[0-9]{4}$ の12-13桁 */
  readonly portabilityNumber: string | undefined;
  /** 名義人区分 番ポの場合必須 0:本人 1:本人以外 */
  readonly relationshipType: string | undefined;
  /** 名義人 番ポの場合必須 全角30文字以内 */
  readonly name: string | undefined;
  /** 名義人カナ 番ポの場合必須 全角30文字以内 */
  readonly kana: string | undefined;
  /** ご利用中の電話会社 番ポの場合必須 */
  readonly telephoneCompanyType: string | undefined;
  /** ご利用中の電話会社 その他 番ポかつ電話会社が98(その他)の場合必須 全角32文字以内 */
  readonly telephoneCompanyOther: string | undefined;
  /** 郵便番号 番ポの場合必須 ^[0-9]{7}$ */
  readonly destinationZipcode: string | undefined;
  /** 住所１ 番ポの場合必須 全角30文字以内 */
  readonly destinationAddress1: string | undefined;
  /** 住所２ 全角60文字以内 */
  readonly destinationAddress2: string | undefined;
  /** 番号通知 0:相手に通知しない 1:相手に通知する */
  readonly notificationFlag: string | undefined;
  /** 着信番号表示 0:申し込まない 1:申し込む */
  readonly numberDisplay: string | undefined;
  /** キャッチ通話 0:申し込まない 1:申し込む */
  readonly catch: string | undefined;
  /** キャッチ通話番号表示 0:申し込まない 1:申し込む */
  readonly catchNumberDisplay: string | undefined;
  /** 番号通知 0:相手に通知しない 1:相手に通知する */
  readonly optionPack: string | undefined;

  constructor(partial: Partial<HikariphoneRequest>) {
    Object.assign(this, partial);
  }
}

/** e-mansion_UCOM光電話 サービス登録のレスポンスDTO */
export class EMansionHikariPhoneRegisterResponseDto {
  // 処理結果 処理成功：success, 処理失敗:fail
  readonly status: string | undefined;
  // 対象の会員ID
  readonly member_id: string | undefined;
  // 登録したサービス
  readonly service_name: string | undefined;
  // オプションサービスID
  readonly option_service_id: string | undefined;
  // 課金開始月(YYYYMM)
  readonly billing_start_month?: string;
  // 受付日時(YYYY/MM/DD hh:mm:ss)
  readonly receive_time: string | undefined;
  // 個別パラメータ
  readonly optional_data: OptionalData | undefined;
  // エラーメッセージ (処理ステータス: fail のときのみ存在する)
  readonly message?: string | undefined;
  // エラーコード (処理ステータス: fail のときのみ存在する)
  readonly code?: string | undefined;

  constructor(partial: Partial<EMansionHikariPhoneRegisterResponseDto>) {
    Object.assign(this, partial);
  }
}

/** e-mansion_UCOM光電話 サービス登録 個別パラメータのレスポンスDTO */
export class OptionalData {
  // サービスがHikariphoneの場合のみ必須
  readonly Hikariphone: HikariPhone | undefined;

  constructor(partial: Partial<OptionalData>) {
    Object.assign(this, partial);
  }
}

/** e-mansion_UCOM光電話 サービス登録 個別パラメータ UCOM光電話のレスポンスDTO */
export class HikariPhone {
  // ログインID
  readonly web_login_id: string | undefined;
  // パスワード 共通キーで暗号化
  readonly web_login_password: string | undefined;
  /**パスワード（復号化＆難読化された値） */
  readonly web_login_password_decrypted: string | undefined;
  // 課金情報
  readonly billing_data: BillingData | undefined;

  constructor(partial: Partial<HikariPhone>) {
    Object.assign(this, partial);
  }
}

/** e-mansion_UCOM光電話 サービス登録 個別パラメータ UCOM光電話　課金情報のレスポンスDTO */
export class BillingData {
  // 基本料
  readonly basic_fee: string | undefined;
  // TAレンタル料
  readonly rental_charge: string | undefined;
  // ユニバーサルサービス料（税込み）
  readonly uni_charge_tax_inc: string | undefined;
  // 番号ポータビリティ手数料 番ポ時必須
  readonly numberportability_fee: string | undefined;
  // 事務手数料
  readonly jimu_fee: string | undefined;
  // 着信番号表示料金 オプション選択時必須
  readonly numberdisplay_fee: string | undefined;
  // キャッチ通話料金 オプション選択時必須
  readonly catch_fee: string | undefined;
  // キャッチ通話番号表示料金 オプション選択時必須
  readonly catch_display_fee: string | undefined;
  // オプションサービスパック料金 オプション選択時必須
  readonly servicepack_fee: string | undefined;

  constructor(partial: Partial<BillingData>) {
    Object.assign(this, partial);
  }
}
