/**
 * e-mansion 共通利用 API : 物件基本情報取得 : レスポンス
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class EMansionPropertyResponse {
  public result!: {
    PARAM: EMansionProperty;
  };

  constructor(partial: Partial<EMansionPropertyResponse>) {
    Object.assign(this, partial);
  }
}

/**
 * e-mansion 共通利用 API : 物件基本情報取得 : レスポンス本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class EMansionProperty {
  /**物件ID */
  public readonly apartment_id!: string;
  /**APID */
  public readonly IN_APID!: string;
  /**入会表示フラグ */
  public readonly em_adm_flag!: string;
  /**コース変更表示不可フラグ */
  public readonly em_coursechg_flag!: string;
  /**お知らせ表示（物件TOP） */
  public readonly em_info_top!: string;
  /**お知らせ表示（下層） */
  public readonly em_info_layer!: string;
  /**メンテナンス・障害表示 */
  public readonly em_mtn!: string | null;
  /**自社バナー */
  public readonly em_bn!: {
    em_bn_id: Array<string> | null;
  };
  /**24時間サポートフラグ */
  public readonly em_24sp_flag!: string;
  /**物件名称 */
  public readonly em_ap_name!: string;
  /**物件名称（検索結果一覧） */
  public readonly em_ap_name_list!: string;
  /**OEM種別 */
  public readonly oem_type!: string;
  /**棟名 */
  public readonly em_bl_name!: string;
  /**課金形態 */
  public readonly em_actype!: string;
  /**サービスイン日 */
  public readonly em_sin_time!: string;
  /**営業日情報 */
  public readonly em_workday_ary!: Array<string>;
  /**独自ドメインフラグ */
  public readonly em_op_mail_flag!: string;
  /**MCP導入 */
  public readonly em_ap_mcp!: string;
  /**物件ステータス */
  public readonly em_ap_status!: string;
  /**都道府県コード */
  public readonly em_pf_id!: string;
  /**天気予報地域ID */
  public readonly em_tenki_id!: string;
  /**天気予報地域名称 */
  public readonly em_tenki_name!: string;
  /**支払い方法イレギュラーフラグ */
  public readonly em_payir_flag!: string;
  /**GIPイレギュラーフラグ */
  public readonly em_gipir_flag!: string;
  /**Cominfoフラグ */
  public readonly em_cominfo_flag!: string;
  /**Chorocobiフラグ */
  public readonly em_chorocobi_flag!: string;
  /**SCOOPフラグ */
  public readonly em_scoop_flag!: string;
  /**MCPフラグ */
  public readonly em_mcp_flag!: string;
  /**メール認証キーフラグ */
  public readonly em_mkey_flag!: string;
  /**おサイフケータイオプション導入フラグ */
  public readonly em_osaifu_keitai_flag!: string;
  /**撤去工事のみフラグ */
  public readonly em_mc_whdr_only_flag!: string;
  /**廉価版フラグ */
  public readonly slim_plan_flag!: string;
  /**Mcloud導入 */
  public readonly em_ap_mck!: string;
  /**MCKイレギュラーフラグ */
  public readonly em_mck_flag!: string;
  /**専有部無線LANフラグ */
  public readonly owned_area_wifi_flag!: string;
  /**専有部無線LAN種別 */
  public readonly owned_area_wifi_kind!: string;
  /**auフェムトセル･フラグ */
  public readonly au_femtocell_flag!: string;
  /**LOLセット販売(NIS,ISW)･フラグ */
  public readonly bundle_md_isw_flag!: string;
  /**端末機器損害金フラグ */
  public readonly damagecharge_flag!: string;
  /**切替物件フラグ */
  public readonly switch_apartment_flag!: string;
  /**切替物件回線切替目安 */
  public readonly switch_apartment_indicator!: string;
  /**SD-WANサポートフラグ */
  public readonly sdwan_flag!: string;
  /**戸数 */
  public readonly number_of_units!: string;
  /**共用部無線LAN表示 */
  public readonly common_area_wifi!: string | null;
  /**メール認証キー説明表示 */
  public readonly em_mkey!: string;
  /**メインメール提供中フラグ */
  public readonly main_mailaddress_provide_flag!: string;
  /**契約書面フラグ */
  public readonly contract_document_flag!: string;
  /**オプション */
  public readonly op!: Option;
  public readonly em_course_count!: number;
  public readonly em_course_type!: Array<string | null>;
  public readonly em_fee_flag!: string;
  public readonly em_fee!: string;
  public readonly em_fee_cp!: string;
  public readonly em_adm_construction_flag!: string;
  public readonly em_adm_construction!: string;
  public readonly em_adm_construction_cp!: string;
  public readonly em_chg_construction_flag!: string;
  public readonly em_chg_construction!: string;
  public readonly em_chg_construction_cp!: string;
  public readonly em_upg_flag!: string;
  public readonly em_upg_fee!: string;
  public readonly em_upg_fee_cp!: string;
  public readonly em_downg_flag!: string;
  public readonly em_downg_fee!: string;
  public readonly em_downg_fee_cp!: string;
  public readonly em_mc_whdr_flag!: string;
  public readonly em_mc_whdr_fee!: string;
  public readonly em_mc_whdr_fee_cp!: string;
  public readonly em_equipmentcontract_flag!: number;
  /** 接続コース一覧情報 */
  public readonly cs!: Course[];
  /** 共用部無線LAN機器情報 */
  public readonly cnmar_wifi!: CnmarWifi[];
  /** エラーメッセージ（データ型不明かつ未使用のためコメントアウト） */
  // public readonly err_msg_ary!: string[];
  /** メール認証キーURL */
  public readonly mailauth_url!: string | null;
  /** uri */
  public readonly uri!: string | null;
  /** お知らせメッセージ（サービスTOP用） */
  public readonly em_info_message!: Array<EmInfoMessage>;
  /** お知らせメッセージ（マイページ用） */
  public readonly mypage_info_message!: Array<MypageInfoMessage>;
  /** キャンペーンお知らせ情報 */
  public readonly cp_info_message?: CpInfo[];
  /** 独自ドメイン名（無い場合は空文字）*/
  public readonly original_domain_name!: string;
  /** 特殊物件情報 */
  public readonly apartment_special_type_flag!: Array<Array<string>>;

  constructor(partial: Partial<EMansionProperty>) {
    Object.assign(this, partial);
  }
}

/** オプション */
export class Option {
  /**マンションプラス電話 */
  public readonly mplusphone!: Mplusphone;
  /**IPフォン */
  public readonly ipp!: Ipp;
  /**マルチデバイスN360 */
  public readonly md360!: Fee;
  /**UCOM光電話 */
  public readonly hikariphone!: Hikariphone;
  /** Internet Sagi Wall */
  public readonly isw!: Fee;
  /** Norton360 */
  public readonly '360'!: Fee;
  /** Norton Internet Security */
  public readonly nis!: Fee;
  /** i-フィルター */
  public readonly ift!: Fee;
  /** i-フィルター */
  public readonly ifilter!: ifilter;
  /** ウィルスチェック */
  public readonly vck!: Fee;
  /** 有料メール */
  public readonly mailplus!: Fee;
  /** ローミング */
  public readonly rom!: JimuFee;
  /** メールボックス */
  public readonly mbx!: Mbx;
  /** メールボックス容量追加 */
  public readonly mbx_quota!: Quota;
  /** ホームページ */
  public readonly hp!: Hp;
  /** ホームページ容量追加 */
  public readonly hp_quota!: Quota;
  /** GIP */
  public readonly gip!: Gip;
  /** SD-WAN */
  public readonly sdwan!: Fee;

  constructor(partial: Partial<Option>) {
    Object.assign(this, partial);
  }
}

/**マンションプラス電話 */
export class Mplusphone {
  /**マンションプラス電話フラグ */
  public readonly flag!: string;

  constructor(partial: Partial<Mplusphone>) {
    Object.assign(this, partial);
  }
}

/** IPフォン */
export class Ipp {
  /**IPフォンフラグ */
  public readonly flag!: string;
  /**IPフォン基本料 */
  public readonly basic_fee!: string;
  /**IPフォンTAレンタル料 */
  public readonly rental_charge!: string;
  /**IPフォンユニバーサルサービス料 */
  public readonly uni_charge!: string;
  /**IPフォンユニバーサルサービス料（税込み） */
  public readonly uni_charge_tax_inc!: string;
  /**IPフォン事務手数料 */
  public readonly jimu_fee!: string;
  /**IPフォンキャンペーン */
  public readonly cp!: string;
  /**IPフォン基本料キャンペーン価格 */
  public readonly basic_fee_cp!: string;
  /**IPフォンTAレンタル料キャンペーン価格 */
  public readonly rental_charge_cp!: string;
  /**IPフォンユニバーサルサービス料キャンペーン価格 */
  public readonly uni_charge_cp!: string;
  /**IPフォン事務手数料キャンペーン価格 */
  public readonly jimu_fee_cp!: string;

  constructor(partial: Partial<Ipp>) {
    Object.assign(this, partial);
  }
}

/** UCOM光電話 */
export class Hikariphone {
  /**UCOM光電話フラグ */
  public readonly flag!: string;
  /**UCOM光電話基本料 */
  public readonly basic_fee!: string;
  /**UCOM光電話基本料 CP */
  public readonly basic_fee_cp!: string;
  /**UCOM光電話機器レンタル料 */
  public readonly rental_charge!: string;
  /**UCOM光電話機器レンタル料 CP */
  public readonly rental_charge_cp!: string;
  /**UCOM光電話ユニバーサルサービス料 */
  public readonly uni_charge!: string;
  /**UCOM光電話ユニバーサルサービス料(税込み) */
  public readonly uni_charge_tax_inc!: string;
  /**UCOM光電話事務手数料 */
  public readonly jimu_fee!: string;
  /**UCOM光電話事務手数料 */
  public readonly jimu_fee_cp!: string;
  /**UCOM光電話番号ポータビリティ手数料 */
  public readonly numberportability_fee!: string;
  /**UCOM光電話着信電話表示料金 */
  public readonly numberdisplay_fee!: string;
  /**UCOM光電話キャッチ通話料金 */
  public readonly catch_fee!: string;
  /**UCOM光電話キャッチ通話番号表示料金 */
  public readonly catch_display_fee!: string;
  /**UCOM光電話オプションサービスパック料金 */
  public readonly servicepack_fee!: string;

  constructor(partial: Partial<Hikariphone>) {
    Object.assign(this, partial);
  }
}

/** 価格共通クラス */
export class Fee {
  /** フラグ */
  public readonly flag!: string;
  /** 価格 */
  public readonly fee!: string;
  /** キャンペーン */
  public readonly cp!: string;
  /** キャンペーン価格 */
  public readonly fee_cp!: string;

  constructor(partial: Partial<Fee>) {
    Object.assign(this, partial);
  }
}

/** 事務手数料クラス */
export class JimuFee {
  /** フラグ */
  public readonly flag!: string;
  /** 事務手数料 */
  public readonly jimu_fee!: string;
  /** キャンペーン */
  public readonly cp!: string;
  /** 事務手数料キャンペーン価格 */
  public readonly jimu_fee_cp!: string;

  constructor(partial: Partial<JimuFee>) {
    Object.assign(this, partial);
  }
}

/** メールボックス */
export class Mbx {
  /** メールボックスフラグ */
  public readonly flag!: string;
  /** メールボックス基本容量 */
  public readonly mbx_quota!: string | null;

  constructor(partial: Partial<Mbx>) {
    Object.assign(this, partial);
  }
}

/** 容量追加共通クラス */
export class Quota {
  /** 容量追加フラグ */
  public readonly flag!: string;
  /** 容量追加価格 */
  public readonly fee!: string;
  /** 容量追加最大容量 */
  public readonly max_quota!: string | null;
  /** 容量追加事務手数料 */
  public readonly jimu_fee!: string;
  /** 容量追加キャンペーン */
  public readonly cp!: string;
  /** 容量追加キャンペーン価格 */
  public readonly fee_cp!: string;
  /** 容量追加事務手数料キャンペーン価格 */
  public readonly jimu_fee_cp!: string;

  constructor(partial: Partial<Quota>) {
    Object.assign(this, partial);
  }
}

/** ホームページ */
export class Hp {
  public readonly flag!: string;
  public readonly hp_quota!: string | null;

  constructor(partial: Partial<Hp>) {
    Object.assign(this, partial);
  }
}

/** GIP */
export class Gip {
  public readonly flag!: string;
  public readonly fee!: string;
  public readonly max!: string;
  public readonly jimu_fee!: string;
  public readonly cp!: string;
  public readonly fee_cp!: string;
  public readonly jimu_fee_cp!: string;

  constructor(partial: Partial<Gip>) {
    Object.assign(this, partial);
  }
}

/** 接続コース一覧情報 */
export interface Course {
  /** 接続速度 */
  speed: string;
  /** 接続速度画像 */
  speed_image: string | null;
  /** 接続方式 */
  type: string | null;
  /** 接続方式画像 */
  type_image: string | null;
  /** 接続料金 */
  charge: string;
  /** 接続料金キャンペーン価格 */
  charge_campaign: string;
  /** 接続機器契約フラグ */
  cs_is_adapter: string;
  /** アダプタ */
  adapter: string;
  /** アダプタの情報 */
  adapter_info: AdapterInfo[];
  /** 新規工事種別 */
  ins_kind: string;
  /** 変更工事種別 */
  chg_kind: string;
  /** 退会工事種別 */
  cnl_kind: string;
  /** グレード */
  grade: string;
  /** 接続コースID */
  course_id: string;
}

/** お知らせメッセージ（サービスTOP用） */
export class EmInfoMessage {
  message_page!: string;
  message_kind!: string;
  message_date!: string;
  message_label!: string;
  message_link!: string;
}

/** お知らせメッセージ（マイページ用） */
export class MypageInfoMessage {
  message_page!: string;
  message_date!: string;
  message_label!: string;
  message!: string;
}

export class CpInfo {
  /**IPフォン */
  campaign_ipphone?: string;
  /**i-フィルター */
  campaign_i_filter?: string;
  /**入会（接続コース） */
  campaign_entry_course?: string;
  /**入会（その他） */
  campaign_entry_other?: string;
  /**コース変更（接続コース） */
  campaign_coursechg_course?: string;
  /** コース変更（その他）*/
  campaign_coursechg_other?: string;
}

/** アダプタの情報 */
export class AdapterInfo {
  /** アダプタの契約種別（接続機器契約） */
  public readonly equipmentcontract!: string;
  /** アダプタの料金 */
  public readonly charge!: string;
  /** アダプタのキャンペーン適用料金アダプタ */
  public readonly charge_campaign!: string;

  constructor(partial: Partial<AdapterInfo>) {
    Object.assign(this, partial);
  }
}

/** 共用部無線LAN機器情報 */
export class CnmarWifi {
  /** 設置場所 */
  public readonly ap!: string;
  /** SSID（2.4GHz） */
  public readonly 'ssid2.4'!: string;
  /** SSID（5.0GHz） */
  public readonly 'ssid5.0'!: string;
  /** 暗号キー */
  public readonly key!: string;
  /** 暗号キー（ルビ） */
  public readonly ruby!: string;
  /** 暗号方式 */
  public readonly enc!: string;
  /** 暗号キー最終更新日 */
  public readonly upd!: string;

  constructor(partial: Partial<CnmarWifi>) {
    Object.assign(this, partial);
  }
}

/** ifilter */
export class ifilter {
  public readonly status!: number;
  public readonly request_time!: string;
  public readonly start_time?: string;
  public readonly cancel_time?: string;
  public readonly end_time?: string;
  public readonly subscription_id?: string;
  public readonly ticket?: string;
  public readonly download_url?: string;
  public readonly service_memo?: string[];

  constructor(partial: Partial<ifilter>) {
    Object.assign(this, partial);
  }
}
