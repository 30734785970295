<template>
  <div class="e-mansion-entry-completed">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion ご入会お申し込み</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion ご入会お申し込み</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid3 gap10">
        <li>お客様情報入力</li>
        <li>接続コース選択</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ご入会お申し込み（接続コース選択） -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="完了" />完了</h2>

        <div class="sblc">
          <h3 class="service-h3">ご入会ありがとうございます。</h3>
          <p>お申し込みいただいた内容は以下のとおりです。</p>
          <table class="table-type2">
            <tbody>
              <tr>
                <th>マンション名</th>
                <td>{{ customer.property_name }}</td>
              </tr>
              <tr v-if="customer.building_name">
                <th>棟名</th>
                <td>{{ customer.building_name }}</td>
              </tr>
              <tr>
                <th>お部屋番号</th>
                <td>{{ customer.room_number }}</td>
              </tr>
              <tr>
                <th>お名前</th>
                <td>{{ customer.name_surname }}&nbsp;&nbsp;{{ customer.name_first_name }}</td>
              </tr>
              <tr>
                <th>フリガナ</th>
                <td>{{ customer.kana_surname }}&nbsp;&nbsp;{{ customer.kana_first_name }}</td>
              </tr>
              <tr>
                <th>生年月日</th>
                <td>{{ formattedDateWithSlash(customer.birthday) }}</td>
              </tr>
              <tr>
                <th>ご連絡先お電話番号</th>
                <td>{{ customer.phone_number }}</td>
              </tr>
              <tr>
                <th>e-mansionからの<br />ご連絡先メールアドレス</th>
                <td>{{ customer.email_address }}</td>
              </tr>
              <tr>
                <th>ご入居予定日</th>
                <td>{{ formettedDateWithHyphen(customer.scheduled_move_in_on) }}</td>
              </tr>
              <tr v-if="!isSlimPlan">
                <th>書類のご希望送付先</th>
                <td>
                  <span v-if="customer.destination_zipcode">〒</span>{{ customer.destination_zipcode }}<br />
                  {{ customer.destination_address1 }} {{ customer.destination_address2 }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="sblc" v-if="customer.course_info">
          <h3 class="service-h3">インターネット接続サービス</h3>

          <table class="table-type2">
            <tbody>
              <tr>
                <th>速度（タイプ）</th>
                <td>{{ customer.course_info.bandspeed_name }} {{ convertType(customer.course_info.access_network_name) }}</td>
              </tr>
              <tr v-if="result.result?.show_tpl !== '00'">
                <th>サービス開始日</th>
                <td v-if="result.result?.show_tpl === '01'"><p>鍵のお渡し日</p></td>
                <td v-if="result.result?.show_tpl === '02'">
                  <p>{{ formattedDateWithSlash(result.result.service_start_date) }}</p>
                </td>
                <td v-if="result.result?.show_tpl === '03'">
                  <p>
                    およそ2～3週刊程度でインターネット接続工事が行われます。<br />
                    工事日が決定次第、郵送でお知らせいたしますので、しばらくお待ちくださいませ。
                  </p>
                  <p class="red">※お客様のお部屋内での工事は発生いたしません。</p>
                </td>
                <td v-if="result.result?.show_tpl === '04'">
                  <p>弊社指定業者より、「インターネット接続工事」の実施日についてお電話にてご連絡いたします。</p>
                  <p class="red">
                    ※一週間程度で別途ご案内資料をお送りさせていただきます。<br />※現地調査の結果、インターネット接続工事が実施できない場合がございます。予めご了承ください。
                  </p>
                </td>
                <td v-if="result.result?.show_tpl === '05'"><p class="switch_apartment_indicator" v-text="removeLineBreakTags(property.switch_apartment_indicator)"></p></td>
              </tr>
              <tr v-if="result.result?.show_tpl === '01' || result.result?.show_tpl === '02'">
                <th>請求開始日</th>
                <td v-if="result.result?.show_tpl === '01'">
                  <ul>
                    <li>サービス開始日が1日～20日までの時は、翌月1日</li>
                    <li>サービス開始日が21日～月末までのときは、翌々月1日</li>
                  </ul>
                  <p class="red">※キャンペーン期間中は、そのキャンペーン条件に準じます。</p>
                </td>
                <td v-if="result.result?.show_tpl === '02'">
                  <p>{{ getInvoiceStartDate(result.result.service_start_date) }}</p>
                  <p class="red">※キャンペーン期間中は、そのキャンペーン条件に準じます。</p>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="att red" v-if="isdamagechargeFlag()">
            ※接続機器はレンタル品のため、お客様の故意または過失による故障や破損の場合、端末機器損害金21,780円（課税対象外）を請求いたします。
          </p>
          <p>
            ※本サービスはベストエフォート式のサービスのため、実使用速度を保証するものではありません。速度は技術規格上での最大値であり、通信速度は時間帯・状況等により異なる場合があります。
          </p>
          <p>
            ※住戸内に設置されている機器（スイッチングハブなど）または接続する端末、ケーブルなどにより技術規格上の最大通信速度が変わるため、上記の通信速度（ベストエフォート）の規格にてご利用になりたい場合、機器、端末、ケーブル等をお客様にて変更いただくケースがございます。
          </p>
          <p>※住戸内設置の機器（建物所有者様、区分所有者様の所有物）の仕様については建物によって異なりますので居住者様にてご確認ください。</p>
        </div>
        <div class="sblc" v-if="property.op && ((property.op.ipp.flag == '1' && property.op.mplusphone.flag == '0') || property.op.ift.flag == '1')">
          <h3 class="service-h3">お申し込みオプションサービス</h3>
          <table class="table-type2">
            <tbody>
              <tr v-if="property.op && property.op.ipp.flag == '1' && property.op.mplusphone.flag == '0'">
                <td><b>e-mansion IPフォン</b></td>
                <td v-if="customer.ipphone_flag">
                  <i class="material-icons link link-icon">check_circle_outline</i>お申し込みあり<br />
                  （TAお届け日:&nbsp;&nbsp;{{ deliveryDate }} {{ getDeliveryTimeZoneDisp(customer.delivery_time_zone ?? '') }}）
                </td>
                <td v-else>お申し込みなし</td>
              </tr>
              <tr v-if="property.op && property.op.ift.flag == '1'">
                <td><b>i-フィルター(有害サイトブロック)</b></td>
                <td v-if="customer.ifilter_flag"><i class="material-icons link link-icon">check_circle_outline</i>お申し込みあり</td>
                <td v-else>お申し込みなし</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="sblc">
          <h3 class="service-h3">料金等について</h3>
          <table class="table-type2">
            <tbody>
              <tr>
                <th :rowspan="internetRowSpan">光ファイバーインターネットサービス</th>
                <td class="campaign-info" colspan="2" v-if="property.cp_info_message && property.cp_info_message[0] && property.cp_info_message[0].campaign_entry_course">
                  {{ removeLineBreakTags(property.cp_info_message[0].campaign_entry_course) }}
                </td>
              </tr>

              <tr v-if="property.cp_info_message && property.cp_info_message[0] && property.cp_info_message[0].campaign_entry_other">
                <td class="campaign-info" colspan="2">{{ removeLineBreakTags(property.cp_info_message[0].campaign_entry_other) }}</td>
              </tr>
              <tr>
                <td>月額基本料</td>
                <td v-if="property.em_payir_flag == '0'">管理組合様にお問合せください。</td>
                <td v-else-if="result.result?.billing_data_internet.basic_fee == '0'">
                  管理費とあわせてお支払いいただきます。なお、賃貸居住の方はオーナー様よりお支払いいただいております。
                </td>
                <td v-else>
                  月額 {{ formatMoney(result.result?.billing_data_internet.basic_fee) }}円<span v-if="result.result?.billing_data_internet.basic_fee_cp"
                    >（キャンペーン適用時：<b class="red">{{ formatMoney(result.result?.billing_data_internet.basic_fee_cp) }}円</b>）</span
                  >
                </td>
              </tr>
              <tr v-if="result.result?.billing_data_internet.em_fee">
                <td>事務手数料（初回のみ）</td>
                <td>
                  {{ formatMoney(result.result?.billing_data_internet.em_fee) }}円<span v-if="result.result?.billing_data_internet.em_fee_cp"
                    >（キャンペーン適用時：<b class="red">{{ formatMoney(result.result?.billing_data_internet.em_fee_cp) }}円</b>）</span
                  >
                </td>
              </tr>
              <tr v-if="result.result?.billing_data_internet.em_adm_construction">
                <td>工事費（初回のみ）</td>
                <td>
                  {{ formatMoney(result.result?.billing_data_internet.em_adm_construction) }}円<span v-if="result.result?.billing_data_internet.em_adm_construction_cp"
                    >（キャンペーン適用時：<b class="red">{{ formatMoney(result.result?.billing_data_internet.em_adm_construction_cp) }}円</b>）</span
                  >
                </td>
              </tr>
              <tr v-if="isNeedAdapter() && result.result?.billing_data_internet.adapter_charge">
                <td v-if="customer.course_info?.access_network_name !== '06'">モデムレンタル料（月額）</td>
                <td v-else>メディアコンバーターレンタル料（月額）</td>
                <td v-if="result.result?.billing_data_internet.adapter_charge_cp" class="red">
                  <b>{{ formatMoney(result.result?.billing_data_internet.adapter_charge_cp) }}円</b>
                </td>
                <td v-else>{{ formatMoney(result.result?.billing_data_internet.adapter_charge) }}円</td>
              </tr>

              <!-- ここからIPフォンの欄 -->
              <tr v-if="customer.ipphone_flag">
                <th :rowspan="ipPhoneRowSpan">
                  e-mansion IPフォン<br />
                  <a target="_blank" class="link" :href="`${eMansionUrl}/${apid}/service/ipdenwa/e-ipphone.html`">(通話料は別途発生いたします。)</a>
                </th>
                <td class="campaign-info" colspan="2" v-if="property.cp_info_message && property.cp_info_message[0] && property.cp_info_message[0].campaign_ipphone">
                  {{ removeLineBreakTags(property.cp_info_message[0].campaign_ipphone) }}
                </td>
              </tr>
              <tr v-if="customer.ipphone_flag && result.result?.billing_data_ipphone?.jimu_fee">
                <td>事務手数料（初回のみ）</td>
                <td>
                  {{ formatMoney(result.result?.billing_data_ipphone?.jimu_fee) }}円
                  <span v-if="result.result?.billing_data_ipphone?.jimu_fee_cp">
                    （キャンペーン適用時：<b class="red">{{ formatMoney(result.result?.billing_data_ipphone?.jimu_fee_cp) }}円</b>）</span
                  >
                </td>
              </tr>
              <tr v-if="customer.ipphone_flag && result.result?.billing_data_ipphone?.basic_fee">
                <td>基本料（月額）</td>
                <td>
                  {{ formatMoney(result.result?.billing_data_ipphone?.basic_fee) }}円
                  <span v-if="result.result?.billing_data_ipphone?.basic_fee_cp">
                    （キャンペーン適用時：<b class="red">{{ formatMoney(result.result?.billing_data_ipphone?.basic_fee_cp) }}円</b>）</span
                  >
                </td>
              </tr>
              <tr v-if="customer.ipphone_flag && result.result?.billing_data_ipphone?.rental_charge">
                <td>テレフォニーアダプターレンタル料（月額）</td>
                <td>
                  {{ formatMoney(result.result?.billing_data_ipphone?.rental_charge) }}円
                  <span v-if="result.result?.billing_data_ipphone?.rental_charge_cp">
                    （キャンペーン適用時：<b class="red">{{ formatMoney(result.result?.billing_data_ipphone?.rental_charge_cp) }}円</b>）</span
                  >
                </td>
              </tr>
              <tr v-if="customer.ipphone_flag && result.result?.billing_data_ipphone?.uni_charge_tax_inc">
                <td>ユニバーサルサービス料（月額）</td>
                <td>
                  <p>
                    {{ formatMoney(result.result?.billing_data_ipphone?.uni_charge_tax_inc) }}円
                    <span v-if="result.result?.billing_data_ipphone?.uni_charge_cp">
                      （キャンペーン適用時：<b class="red">{{ formatMoney(result.result?.billing_data_ipphone?.uni_charge_cp) }}円</b>）</span
                    >
                  </p>
                  <p class="red">※ユニバーサルサービス制度にともない改定されることがあります。</p>
                </td>
              </tr>
              <!-- ここまでIPフォンの欄 -->

              <!-- ここからi-フィルターの欄 -->
              <tr v-if="customer.ifilter_flag">
                <th :rowspan="iFilterRowSpan"><b>i-フィルター</b></th>
                <td class="campaign-info" colspan="2" v-if="property.cp_info_message && property.cp_info_message[0] && property.cp_info_message[0].campaign_i_filter">
                  {{ removeLineBreakTags(property.cp_info_message[0].campaign_i_filter) }}
                </td>
              </tr>
              <tr v-if="customer.ifilter_flag && result.result?.billing_data_ifilter?.basic_fee">
                <td>月額基本料</td>
                <td>
                  {{ formatMoney(result.result?.billing_data_ifilter?.basic_fee) }}円
                  <span v-if="result.result?.billing_data_ifilter?.basic_fee_cp">
                    （キャンペーン適用時：<b class="red">{{ formatMoney(result.result?.billing_data_ifilter?.basic_fee_cp) }}円</b>）</span
                  >
                </td>
              </tr>
              <!-- ここまでi-フィルターの欄 -->

              <tr v-if="isShowTotalCost()">
                <th>総額</th>
                <td colspan="2">
                  <p>初回:{{ dispFirstMonthCost }}円 / 次回以降:{{ dispMonthlyCost }}円</p>
                  <p class="red" v-if="isShowCampaignText()">※「次回以降」の料金は、キャンペーンが適用されない通常時における総額を表示しています。</p>
                  <p class="red" v-if="customer.ipphone_flag">※「e-mansion IPフォン」の通話料は別途発生いたします。</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>※価格は全て税込表示（消費税10%）です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
        </div>
        <div v-if="!(property.em_actype == '1' && !customer.ipphone_flag)">
          <h3 class="service-h3">インターネット接続サービス　ご契約内容のご案内</h3>
          <table class="table-type2">
            <tbody>
              <tr>
                <th>ご契約内容の交付方法</th>
                <td v-if="customer.contract_destination_kind === '1'">
                  <p>ご登録いただいた以下住所に郵送いたします。</p>
                  <p>〒{{ formatPostalCode(customer.contract_destination_zipcode) }}</p>
                  <p>{{ customer.contract_destination_address1 }}　{{ customer.contract_destination_address2 }}</p>
                </td>
                <td v-if="customer.contract_destination_kind === '3'">
                  <p>ご登録いただいたメールアドレス「{{ customer.email_address }}」へ送信いたします。</p>
                </td>
                <td v-if="customer.contract_destination_kind === '4'">
                  <p>ご登録いただいたメールアドレス「{{ customer.contract_destination_mailaddress }}」へ送信いたします。</p>
                </td>
              </tr>
              <tr>
                <td colspan="2">「ご契約内容のご案内」は、e-mansion会員専用トップページ「契約内容のご案内」で、受付日から30日間ご確認いただけます。</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sblc">
          <div class="btn-area">
            <button type="button" class="btn-height btn btn01 bs" v-on:click="onNext()">Portasトップページへ<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
.table-type2 {
  border-collapse: separate;
  border-spacing: 0;
}
.table-type2 td,
.table-type2 th {
  border-bottom: 1px solid #d3d3d3;
}
.campaign-info {
  white-space: pre-wrap;
}
@media only screen and (max-width: 767px) {
  .table-type2 tr td:first-child,
  .table-type2 tr th:first-child {
    /* 最初の <td> にのみ適用されるスタイル */
    border-bottom: none;
  }
}
.new-line {
  white-space: pre-wrap;
}

.btn-height {
  height: 50px;
}

.agreement-btn {
  float: right;
  margin-top: -40px;
}

.sbtn {
  min-width: 80px;
  line-height: 26px;
  text-align: center;
  font-size: 13px;
  color: #cf1225;
  border: 1px solid #cf1225;
  border-radius: 2px;
  padding: 0 10px;
  background-color: #ffffff;
}
.link {
  font-weight: normal;
}
.sbtn:hover {
  opacity: 0.7;
}
.switch_apartment_indicator {
  white-space: pre-wrap;
}
.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}
</style>

<script lang="ts">
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { LinkServiceWithEMansionResponse } from '@/shared/classes/spf-api/link-service-with-e-mansion';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { E_MANSION_MAIN_ACCOUNT_PLAN_TYPE } from '@/shared/const/e-mansion/e-mansion-main-account-plan-type';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { EMansionEntryInputForm } from './classes/entry-input-form';

/** Completed コンポーネント */
export default defineComponent({
  name: 'completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    customer: {} as EMansionEntryInputForm,
    result: {} as LinkServiceWithEMansionResponse,
    property: {} as EMansionProperty,
    dispFirstMonthCost: '0',
    dispMonthlyCost: '0',
    isLoading: true, // onNext()実行中かどうか
    deliveryDate: '',
    // IPフォンテレフォニーアダプターはヤマト運輸の着荷指定(時間帯)に従った選択肢となっている
    // NOTE: https://faq.kuronekoyamato.co.jp/app/answers/detail/a_id/1249/~/%E9%85%8D%E9%81%94%E3%81%AE%E6%99%82%E9%96%93%E5%B8%AF%E3%81%A8%E3%80%81%E5%8F%97%E4%BB%98%E7%B7%A0%E5%88%87%E6%99%82%E9%96%93%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82%E6%9B%B4%E3%81%AB%E7%B4%B0%E3%81%8B%E3%81%AA%E6%99%82%E9%96%93%E3%81%AE%E6%8C%87%E5%AE%9A%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F
    yamatoDeliveryTimeZoneList: [
      { name: '午前中', value: '0', key: '0' },
      { name: '14:00 ～ 16:00', value: '2', key: '2' },
      { name: '16:00 ～ 18:00', value: '3', key: '3' },
      { name: '18:00 ～ 20:00', value: '4', key: '4' },
      { name: '19:00 ～ 21:00', value: '5', key: '5' },
    ],
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    internetRowSpan: 2,
    ipPhoneRowSpan: 1,
    iFilterRowSpan: 1,
    isSlimPlan: false,
  }),
  async mounted() {
    // ログイン中のお客様情報が表示される
    try {
      // 会員情報取得処理
      const customer: EMansionEntryInputForm = this.$store.getters['eMansionEntryStore/entryInputForm'];
      this.customer = customer;
      // 物件情報の取得
      const eMansionProperty: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      this.property = eMansionProperty;
      //レスポンスの取得
      this.result = this.$store.getters['eMansionEntryStore/result'];

      if (!(await MountedCheckService.canReadEMansionScreen()) || !this.customer || !this.property || !this.result) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 表示用総額の設定
      this.dispFirstMonthCost = Intl.NumberFormat('ja').format(this.getTotalInitialCost() + this.getTotalMonthlyCostWithCampaign());
      this.dispMonthlyCost = Intl.NumberFormat('ja').format(this.getTotalMonthlyCost());
      this.isSlimPlan = this.checkSlimPlan(this.property);
      // TAお届け日の設定
      this.deliveryDate = this.getDeliveryDate();
      //料金の表の表示設定
      this.calcRowSpan();
      this.isLoading = false;
    } catch (e: any) {
      await VueErrorHandler.handle(e, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
  },
  methods: {
    /** Portasトップ画面に遷移する */
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      await this.$router
        .push('/platform')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    isShowCampaignText() {
      if (this.result.result?.billing_data_internet.basic_fee_cp) {
        return true;
      }
      if (this.result.result?.billing_data_internet.em_fee_cp) {
        return true;
      }
      if (this.result.result?.billing_data_internet.em_adm_construction_cp) {
        return true;
      }

      //IPフォン
      if (this.customer.ipphone_flag) {
        if (this.result.result?.billing_data_ipphone?.jimu_fee_cp) {
          return true;
        }
        if (this.result.result?.billing_data_ipphone?.basic_fee_cp) {
          return true;
        }
        if (this.result.result?.billing_data_ipphone?.rental_charge_cp) {
          return true;
        }
        if (this.result.result?.billing_data_ipphone?.uni_charge_cp) {
          return true;
        }
      }
      //i-フィルター
      if (this.customer.ifilter_flag) {
        if (this.result.result?.billing_data_ifilter?.basic_fee_cp) {
          return true;
        }
      }
      return false;
    },
    isdamagechargeFlag() {
      if (this.property.damagecharge_flag == '0') {
        return false;
      }
      if (this.customer.course_info?.access_network_name == '05') {
        return false;
      }
      if (this.customer.course_info?.access_network_name == '06' && this.customer.course_info?.ins_kind == '01') {
        return false;
      }
      return true;
    },
    getDeliveryTimeZoneDisp(key: string): string | undefined {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.yamatoDeliveryTimeZoneList.find((timeZone: any) => {
        return timeZone.key === key;
      })?.name;
    },
    getDeliveryDate() {
      if (!this.customer?.ipphone_flag) {
        return '';
      }
      return `${this.customer?.delivery_year}年${this.customer?.delivery_month}月${this.customer?.delivery_day}日`;
    },
    /**yyyy/mm/dd形式→yyyy年mm月dd日　に変換 */
    formattedDateWithSlash(date: string | undefined) {
      if (!date) {
        return '';
      }
      const dateArray = date.split('/');
      return `${dateArray[0]}年${dateArray[1]}月${dateArray[2]}日`;
    },
    /**yyyy-mm-dd形式→yyyy年mm月dd日　に変換 */
    formettedDateWithHyphen(date: string | null) {
      if (!date) {
        return '';
      }
      const dateArray = date.split('-');
      return `${dateArray[0]}年${dateArray[1]}月${dateArray[2]}日`;
    },
    checkSlimPlan(property: EMansionProperty): boolean {
      const flags = property.apartment_special_type_flag[0];
      if (flags[E_MANSION_MAIN_ACCOUNT_PLAN_TYPE.SLIM_PLAN] === '1') {
        return true;
      }
      return false;
    },
    /**請求開始日の計算 */
    getInvoiceStartDate(dateStr: string | undefined) {
      if (!dateStr) {
        return '';
      }
      const date = new Date(dateStr);
      if (date.getDate() < 21) {
        //1日～20日→翌月1日
        date.setMonth(date.getMonth() + 1);
      } else {
        //21日～→翌々月1日
        date.setMonth(date.getMonth() + 2);
      }
      return `${date.getFullYear()}年${date.getMonth() + 1}月01日`;
    },
    getTotalInitialCost() {
      let totalInitialCost = 0;
      // 接続コース

      // 事務手数料

      if (this.result.result?.billing_data_internet.em_fee) {
        const em_fee: string = this.result.result?.billing_data_internet.em_fee_cp
          ? this.result.result?.billing_data_internet.em_fee_cp
          : this.result.result?.billing_data_internet.em_fee;

        totalInitialCost += Number(em_fee.replace(',', ''));
      }

      // 工事費
      if (this.result.result?.billing_data_internet.em_adm_construction) {
        const em_adm_construction: string = this.result.result?.billing_data_internet.em_adm_construction_cp
          ? this.result.result?.billing_data_internet.em_adm_construction_cp
          : this.result.result?.billing_data_internet.em_adm_construction;
        totalInitialCost += Number(em_adm_construction.replace(',', ''));
      }

      // IPフォン
      if (this.customer?.ipphone_flag && this.result.result?.billing_data_ipphone?.jimu_fee) {
        // 事務手数料
        const ipp_jimu_fee: string = this.result.result?.billing_data_ipphone?.jimu_fee_cp
          ? this.result.result?.billing_data_ipphone?.jimu_fee_cp
          : this.result.result?.billing_data_ipphone?.jimu_fee;
        totalInitialCost += Number(ipp_jimu_fee.replace(',', ''));
      }

      if (Number.isNaN(totalInitialCost)) {
        return 0;
      }

      return totalInitialCost;
    },
    /**
     * 接続機器が必要→true
     */
    isNeedAdapter() {
      return !(this.customer.course_info?.access_network_name === '05' || (this.customer.course_info?.access_network_name === '06' && this.customer.course_info.ins_kind === '01'));
    },
    /**接続方式を変換 */
    convertType(type: string | undefined) {
      if (type === '01' || type === '02' || type === '03' || type === '04') {
        return '（VDSL）';
      }
      if (type === '05') {
        return '（LAN）';
      }
      if (type === '06') {
        return '（FTTH）';
      }
      return '';
    },
    formatPostalCode(postalCode: string | undefined): string {
      if (!postalCode) {
        return '';
      }
      // 正規表現でハイフンを追加
      return postalCode.replace(/^(\d{3})(\d{4})$/, '$1-$2');
    },
    getTotalMonthlyCostWithCampaign() {
      let totalMonthlyCost = 0;
      // 接続コース月額基本料
      if (this.property.em_payir_flag !== '0') {
        if (this.result.result?.billing_data_internet.basic_fee) {
          const basic_fee: string = this.result.result?.billing_data_internet.basic_fee_cp
            ? this.result.result?.billing_data_internet.basic_fee_cp
            : this.result.result?.billing_data_internet.basic_fee;
          totalMonthlyCost += Number(basic_fee.replace(',', ''));
        }
      }
      // 接続コースレンタル料
      if (this.isNeedAdapter() && this.result.result?.billing_data_internet.adapter_charge) {
        const charge: string = this.result.result?.billing_data_internet.adapter_charge_cp
          ? this.result.result?.billing_data_internet.adapter_charge_cp
          : this.result.result?.billing_data_internet.adapter_charge;
        totalMonthlyCost += Number(charge.replace(',', ''));
      }

      // IPフォン
      if (this.customer?.ipphone_flag) {
        // 基本料金
        if (this.result.result?.billing_data_ipphone?.basic_fee) {
          const ipp_basic_fee: string = this.result.result?.billing_data_ipphone?.basic_fee_cp
            ? this.result.result?.billing_data_ipphone?.basic_fee_cp
            : this.result.result?.billing_data_ipphone?.basic_fee;
          totalMonthlyCost += Number(ipp_basic_fee.replace(',', ''));
        }

        // TAレンタル料
        if (this.result.result?.billing_data_ipphone?.rental_charge) {
          const ipp_rental_charge: string = this.result.result?.billing_data_ipphone?.rental_charge_cp
            ? this.result.result?.billing_data_ipphone?.rental_charge_cp
            : this.result.result?.billing_data_ipphone?.rental_charge;
          totalMonthlyCost += Number(ipp_rental_charge.replace(',', ''));
        }

        // ユニバーサルサービス料
        if (this.result.result?.billing_data_ipphone?.uni_charge_tax_inc) {
          const ipp_uni_charge: string = this.result.result?.billing_data_ipphone?.uni_charge_cp
            ? this.result.result?.billing_data_ipphone?.uni_charge_cp
            : this.result.result?.billing_data_ipphone?.uni_charge_tax_inc;
          totalMonthlyCost += parseInt(ipp_uni_charge.replace(',', ''));
        }
      }

      // i-フィルター
      if (this.customer?.ifilter_flag && this.result.result?.billing_data_ifilter?.basic_fee) {
        const ift_fee: string = this.result.result?.billing_data_ifilter?.basic_fee_cp
          ? this.result.result?.billing_data_ifilter?.basic_fee_cp
          : this.result.result?.billing_data_ifilter?.basic_fee;
        totalMonthlyCost += Number(ift_fee.replace(',', ''));
      }

      if (Number.isNaN(totalMonthlyCost)) {
        return 0;
      }

      return totalMonthlyCost;
    },
    getTotalMonthlyCost() {
      let totalMonthlyCost = 0;
      // 接続コース月額基本料
      if (this.property.em_payir_flag !== '0') {
        if (this.result.result?.billing_data_internet.basic_fee) {
          const basic_fee: string = this.result.result?.billing_data_internet.basic_fee;

          totalMonthlyCost += Number(basic_fee.replace(',', ''));
        }
      }
      // 接続コースレンタル料（これだけ月額でもキャンペーン価格を適用する）
      if (this.isNeedAdapter() && this.result.result?.billing_data_internet.adapter_charge) {
        const charge: string = this.result.result?.billing_data_internet.adapter_charge_cp
          ? this.result.result?.billing_data_internet.adapter_charge_cp
          : this.result.result?.billing_data_internet.adapter_charge;
        totalMonthlyCost += Number(charge.replace(',', ''));
      }

      // IPフォン
      if (this.customer?.ipphone_flag) {
        // 基本料金
        if (this.result.result?.billing_data_ipphone?.basic_fee) {
          const ipp_basic_fee: string = this.result.result?.billing_data_ipphone?.basic_fee;

          totalMonthlyCost += Number(ipp_basic_fee.replace(',', ''));
        }

        // TAレンタル料
        if (this.result.result?.billing_data_ipphone?.rental_charge) {
          const ipp_rental_charge: string = this.result.result?.billing_data_ipphone?.rental_charge;

          totalMonthlyCost += Number(ipp_rental_charge.replace(',', ''));
        }

        // ユニバーサルサービス料
        if (this.result.result?.billing_data_ipphone?.uni_charge_tax_inc) {
          const ipp_uni_charge: string = this.result.result?.billing_data_ipphone?.uni_charge_tax_inc;

          totalMonthlyCost += parseInt(ipp_uni_charge.replace(',', ''));
        }
      }

      // i-フィルター
      if (this.customer?.ifilter_flag && this.result.result?.billing_data_ifilter?.basic_fee) {
        const ift_fee: string = this.result.result?.billing_data_ifilter?.basic_fee;

        totalMonthlyCost += Number(ift_fee.replace(',', ''));
      }

      if (Number.isNaN(totalMonthlyCost)) {
        return 0;
      }

      return totalMonthlyCost;
    },
    calcRowSpan() {
      //接続コース
      if (this.property.cp_info_message && this.property.cp_info_message[0] && this.property.cp_info_message[0].campaign_entry_other) {
        ++this.internetRowSpan;
      }
      if (this.result.result?.billing_data_internet.em_fee) {
        ++this.internetRowSpan;
      }
      if (this.result.result?.billing_data_internet.em_adm_construction) {
        ++this.internetRowSpan;
      }
      if (this.isNeedAdapter() && this.result.result?.billing_data_internet.adapter_charge) {
        ++this.internetRowSpan;
      }
      //IPフォン

      if (this.result.result?.billing_data_ipphone?.jimu_fee) {
        ++this.ipPhoneRowSpan;
      }
      if (this.result.result?.billing_data_ipphone?.basic_fee) {
        ++this.ipPhoneRowSpan;
      }
      if (this.result.result?.billing_data_ipphone?.uni_charge_tax_inc) {
        ++this.ipPhoneRowSpan;
      }
      if (this.result.result?.billing_data_ipphone?.rental_charge) {
        ++this.ipPhoneRowSpan;
      }
      //i-フィルター

      if (this.result.result?.billing_data_ifilter?.basic_fee) {
        ++this.iFilterRowSpan;
      }
    },
    isShowTotalCost() {
      return Number(this.dispFirstMonthCost.replace(',', '')) > 0 || Number(this.dispMonthlyCost.replace(',', '')) > 0;
    },
    removeLineBreakTags(text: string) {
      if (!text) return '';
      return text.replace(/<br \/?>/g, '');
    },
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: string | undefined): string {
      if (!billing) {
        return '';
      }
      //念のためカンマを除去
      const billingNumStr = billing.replace(/,/g, '');
      const billingNum = parseFloat(billingNumStr);
      //数値かどうかチェック
      if (isNaN(billingNum)) {
        return '';
      }
      return new Intl.NumberFormat('en-US').format(Number(billingNum));
    },
  },

  computed: {
    apid() {
      if (this.$data.property) {
        return this.$data.property.IN_APID;
      } else {
        return '';
      }
    },
  },
});
</script>
