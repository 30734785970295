<template>
  <div class="e-mansion-ip-phone-confirm">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion IPフォン</h1>
    </main>

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion 会員専用トップ</router-link>
        </li>
        <li>e-mansion IPフォン</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc -->
      <div class="blc">
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <!-- sblc 申込内容（配達希望日時） -->
        <div class="sblc">
          <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansion IPフォン　お申し込み確認" />e-mansion IPフォン　お申し込み確認</h2>
          <p>お申し込み内容をご確認ください。</p>

          <h3 class="service-h3">お申し込み内容</h3>

          <p>テレフォニーアダプターのお届け日時をご確認ください。</p>

          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>ご希望のお届け日時</b></th>
                <td>
                  <b class="red">{{ displayDeliveryYear }}年{{ displayDeliveryMonth }}月{{ displayDeliveryDate }}日&nbsp;&nbsp;{{ displayDeliveryTimeZone.name }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- sblc -->

        <!-- blc 支払方法表示 IPフォンでは支払必須 -->
        <div class="sblc">
          <div class="sblc">
            <h3 class="service-h3">お支払い方法</h3>
          </div>

          <!-- sblc クレジットカード入力コンポーネント -->
          <div class="sblc">
            <!-- 支払方法をこれから登録の場合 or e-mansionでクレジットカード登録済みの場合 -->
            <div v-if="isNeedDisableTrackingBlockFunction">
              <credit-card-component
                v-if="isMounted"
                :key="resetCounter"
                :reset-counter="resetCounter"
                v-bind:apiTokenKey="apiTokenKey"
                v-bind:successMessage="successMessage"
                v-bind:ispName="ispName"
                v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
                v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
                v-bind:portasVeriTransId="portasVeriTransId"
                v-bind:cardHolderNameFromIsp="cardHolderNameFromIsp"
                v-on:onPostCreditCard="getCreditCardToken"
                v-on:onPostCreditCardError="executeCreditCardError"
                v-on:getTokenExpireDate="getTokenExpireDate"
                v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
                v-on:change="isAgreedCopyCardToIsp = $event"
                v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
                v-on:portasCardHolderName="getCardHolderNameFromPortas"
                v-on:cardholderNameFormForAuthorizeIspCard="getCardholderNameFormForAuthorizeIspCard"
                v-on:cardholderNameFormForAuthorizePortasCard="getCardholderNameFormForAuthorizePortasCard"
              />

              <!-- sblc トラッキング防止解除 -->
              <div class="sblc">
                <p class="border-grey bg-grey pd20 mt40 ml40">
                  ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
                  ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
                </p>
                <p class="form-btn-txt mt40">
                  ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」ボタンをクリックしてください。
                </p>
                <p class="red form-btn-txt">
                  「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
                </p>
              </div>
            </div>
            <div v-else>
              <p>ご登録のお支払い方法により、お支払いいただきます。</p>
            </div>
            <!-- /blc -->
          </div>
          <!-- sblc -->
        </div>

        <!-- sblc 申込内容（料金表示） -->
        <div class="sblc">
          <h3 class="service-h3">ご契約内容のご案内について</h3>

          <table class="table-type2">
            <tbody>
              <tr v-if="displayIPPCampaign">
                <td class="campaign-info" colspan="2">
                  <p>{{ displayIPPCampaign }}</p>
                </td>
              </tr>
              <tr>
                <th>事務手数料（初回のみ）</th>
                <td>
                  {{ displayJimuFee }}&nbsp;円
                  <span v-if="displayJimuFeeCp">
                    &nbsp;（キャンペーン適用時：&nbsp;<b class="red"> {{ displayJimuFeeCp }} </b>&nbsp;円）
                  </span>
                </td>
              </tr>
              <tr>
                <th>基本料（月額）</th>
                <td>
                  {{ displayBasicFee }}&nbsp;円
                  <span v-if="displayBasicFeeCp">
                    &nbsp;（キャンペーン適用時：&nbsp;<b class="red"> {{ displayBasicFeeCp }} </b>&nbsp;円）
                  </span>
                </td>
              </tr>
              <tr>
                <th>テレフォニーアタプターレンタル料（月額）</th>
                <td>
                  {{ displayRentalCharge }}&nbsp;円
                  <span v-if="displayRentalChargeCp">
                    &nbsp;（キャンペーン適用時：&nbsp;<b class="red"> {{ displayRentalChargeCp }} </b>&nbsp;円）
                  </span>
                </td>
              </tr>
              <tr>
                <th><b>ユニバーサルサービス料（月額）</b></th>
                <td>
                  {{ displayUniChargeTaxInc }}&nbsp;円
                  <span v-if="displayUniChargeCp">
                    &nbsp;（キャンペーン適用時：&nbsp;<b class="red"> {{ displayUniChargeCp }} </b>&nbsp;円）
                  </span>
                  <br />
                  <span class="att">※ユニバーサルサービス制度にともない改定されることがあります。</span>
                </td>
              </tr>
              <tr>
                <th>e-mansion IPフォン 総額</th>
                <td>
                  <p>初回：&nbsp;{{ displayTotalFirstMonthCost }}&nbsp;円&nbsp;&nbsp;/&nbsp;&nbsp;次回以降：&nbsp;{{ displayTotalMonthlyCost }}&nbsp;円</p>
                  <p class="red" v-if="isCampaignCost">※「次回以降」の金額は、キャンペーンが適用されない通常時における総額を表示しています。</p>
                  <p class="att"><a :href="`${eMansionUrl}/${apid}/service/ipdenwa/e-ipphone.html`" class="link" target="_blank">※通話料は別途発生いたします。</a></p>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="att grey">※価格は全て新税率に基づく税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>

          <br />
        </div>
        <!-- blc -->

        <!-- sblc 共通テンプレート ご契約内容の交付方法 -->
        <div class="sblc">
          <e-mansion-entry-send-documents-component
            @error-messages="getErrorMessage"
            @input-data="getInputDestinationInfo"
            :isDisplayHeadline="false"
            :isEntryEMansion="false"
            :inputDeliveryData="inputDestinationInfo"
          ></e-mansion-entry-send-documents-component>
        </div>
        <!-- sblc -->
      </div>
      <!-- blc -->

      <!-- btn-area -->
      <div class="btn-area">
        <div class="has-checkbox">
          <div class="my-checkbox-outer" v-if="!noDisplayCreditCardForm">
            <input id="checkbox-to-agree" type="checkbox" v-model="isAgreedPrivacyPolicy" :disabled="isExpiredPortasCreditCard" />
            <label for="checkbox-to-agree">クレジットカード会社での本人認証のための情報提供に同意してお申し込みを確定する</label>
          </div>
          <button class="btn-height btn btn05 bs sp-margin" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          &nbsp;
          <button class="btn-height btn btn01 bs sp-margin" type="button" :disabled="isDisabledButton" v-on:click="onApply()">
            お申し込みを確定する<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /btn-area -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
div.my-checkbox-outer > input {
  margin-right: 10px;
}
.campaign-info {
  white-space: pre-wrap;
}
.div.btn-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  text-align: center;
  & div.has-checkbox {
    padding-top: 16px;
    & div.my-checkbox-outer {
      position: absolute;
      top: 16px;
      left: 0;
      & input[type='checkbox'] {
        top: 4px;
        left: 8px;
      }
      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}
.sp-margin {
  margin-top: 10px;
}

.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}
/* 申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}

div.btn-area {
  & button.btn01 {
    margin-top: 16px;
  }
}

// @media only screen and (min-width: 767px) {
//   div.btn-area {
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     padding-top: 64px;
//     & div.has-checkbox {
//       padding-top: 0;
//       & div.my-checkbox-outer {
//         top: 0;
//         left: 50%;
//         text-align: left;
//         & input[type='checkbox'] {
//           left: 0;
//         }
//       }
//     }
//   }
// }
p.red {
  color: #cf1225;
}
b.red {
  color: #cf1225;
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { SpfApiIPPoneAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-ip-phone-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { EMansionIPPhoneRegisterResponse } from '@/shared/classes/spf-api/e-mansion/e-mansion-ip-phone';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import EMansionEntrySendDocumentsComponent from '@/shared/components/e-mansion/e-mansion-entry-send-documents-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { EMansionIPPhoneInputApplyForm, EMansionIPPhoneInputDestinationForm } from '@/store/e-mansion/e-mansion-ip-phone-store';

/** Completed コンポーネント */
export default defineComponent({
  name: 'e-mansion-ip-phone-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
    CreditCardComponent,
    EMansionEntrySendDocumentsComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],

    /** 配達日／時間帯指定 */
    inputApplyForm: {} as EMansionIPPhoneInputApplyForm,
    displayDeliveryYear: 0 as number,
    displayDeliveryMonth: 0 as number,
    displayDeliveryDate: 0 as number,
    displayDeliveryTimeZone: {} as { key: string; value: string; name: string },

    /** e-mansion 物件基本情報  */
    eMansionProperty: null as EMansionProperty | null,
    /** e-mansion のサイト URL */
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,

    /** 契約基本情報取得API.支払い方法種別によってクレジットカードコンポーネントの表示/非表示を切り替える */
    noDisplayCreditCardForm: false,

    // 契約基本情報取得API.支払種別が[0:口座振替,1:振込,4:口振(請求書),8:クレジットカード]以外
    // クレカ入力欄（共通コンポーネント）で制御
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** APIトークンキー */
    apiTokenKey: '',
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込みを確定する」ボタンをクリックしてください。',
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
    /** ユーザーがe-mansionへカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面ではe-mansion固定) */
    ispName: 'e-mansion',
    /** e-mansionから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** e-mansionから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** e-mansion に登録済のVeriTransAccountId */
    emansionVeriTransAccountId: '',
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: false,
    /** Portasから取得したカード名義人 */
    cardHolderNameOnPortas: '' as string | undefined,
    /** ISPから取得したカード名義人 */
    cardHolderNameFromIsp: '' as string | undefined,
    /** ISPで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizeIspCard: '' as string | undefined,
    /** Portasで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizePortasCard: '' as string | undefined,
    /** 本人認証処理を実行するため、ブラウザのトラッキング防止機能を無効化する必要があるかどうか */
    isNeedDisableTrackingBlockFunction: true,
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    /** 再入力のための画面リセット回数をカウント（リトライ防止） */
    resetCounter: 0,

    /** e-mansion 会員ID */
    eMansionMemberId: '' as string,
    /** 物件ID */
    eMansionPropertyId: '' as string,
    /** サービス登録APIの返却エラーコード */
    errorCode: '' as string | undefined,

    /** お支払内容の表示判定 true:キャンペーン適用/false:通常価格 */
    isCampaign: false as boolean,
    displayIPPCampaign: undefined as undefined | string,
    /** 支払方法（表示用数値文字列 ３桁区切り） */
    isCampaignCost: false as boolean,
    displayJimuFee: undefined as undefined | string,
    displayJimuFeeCp: undefined as undefined | string,
    displayBasicFee: undefined as undefined | string,
    displayBasicFeeCp: undefined as undefined | string,
    displayRentalCharge: undefined as undefined | string,
    displayRentalChargeCp: undefined as undefined | string,
    displayUniChargeTaxInc: undefined as undefined | string,
    displayUniChargeCp: undefined as undefined | string,
    displayTotalFirstMonthCost: undefined as undefined | string,
    displayTotalMonthlyCost: undefined as undefined | string,

    /** 契約内容の交付方法 */
    inputDestinationInfo: {} as any | undefined,

    destinationInfo: null as null | EMansionIPPhoneInputDestinationForm,
  }),
  computed: {
    /** APIDを取得 */
    apid(): string {
      if (this.eMansionProperty) {
        return this.eMansionProperty.IN_APID;
      } else {
        return '';
      }
    },
    isDisabledButton(): boolean {
      if (this.noDisplayCreditCardForm) {
        return false;
      } else if (
        this.emansionVeriTransAccountId &&
        ((this.cardHolderNameFromIsp && this.isAgreedPrivacyPolicy) ||
          (this.cardholderNameFormForAuthorizeIspCard && this.cardholderNameFormForAuthorizeIspCard.length > 1 && this.isAgreedPrivacyPolicy))
      ) {
        return false;
      } else if (
        !this.isExpiredPortasCreditCard &&
        (this.cardHolderNameOnPortas || this.cardholderNameFormForAuthorizePortasCard) &&
        this.isAgreedCopyCardToIsp &&
        this.isAgreedPrivacyPolicy
      ) {
        return false;
      } else {
        return this.creditCardAccessToken === '' || !this.isAgreedPrivacyPolicy;
      }
    },
  },
  /** 画面初期表示時の処理 */
  async mounted() {
    // eslint-disable-next-line no-useless-catch
    try {
      if (!(await MountedCheckService.canReadEMansionScreen())) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // APIトークンキーを設定
      this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION;

      /** Storeから取得 */
      // Portas 会員情報
      const member: Member = this.$store.getters['memberStore/member'];
      //!member : MountedCheckService.canReadEMansionScreen()にてチェック済み
      this.eMansionMemberId = member.primaryKeyMye ?? '';
      if (!this.eMansionMemberId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // 物件基本情報取得API.キャンペーンお知らせ情報.各サービス（IPPhone）
      // キャンペーン適用判定、表示料金情報を取得
      this.eMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      this.getDisplayBillingData();

      /** 契約基本情報をStoreから取得 */
      // 契約基本情報取得API.支払い方法種別 の値が「0:口座振替, 1:振込, 4:口振(請求書), 8:クレジットカード」いずれかの場合
      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];
      if (customer instanceof EMansionCustomer) {
        // 物件ID
        this.eMansionPropertyId = customer.property_id;

        // 登録している支払方法が以下いずれかの場合、クレジットカード申請フォームを表示しない
        /**
         * 0: 口座振替
         * 1: 振込
         * 4: 口振（請求書）
         * 8: クレジットカード
         */
        const paymentIdsWithoutCreditCard = ['0', '1', '4'];

        if (paymentIdsWithoutCreditCard.some((paymentIdsWithoutCreditCard) => paymentIdsWithoutCreditCard === customer.payment_id)) {
          this.noDisplayCreditCardForm = true;
          this.isNeedDisableTrackingBlockFunction = false;
        } else {
          this.noDisplayCreditCardForm = false;
        }
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      // 入力画面で入力した情報をStoreから取得
      this.inputApplyForm = this.$store.getters['eMansionIPPhoneStore/inputApplyForm'];
      if (!this.inputApplyForm) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      } else {
        this.displayDeliveryYear = Number(this.inputApplyForm.deliveryDay.slice(0, 4));
        this.displayDeliveryMonth = Number(this.inputApplyForm.deliveryDay.slice(5, 7));
        this.displayDeliveryDate = Number(this.inputApplyForm.deliveryDay.slice(8, 10));
        this.displayDeliveryTimeZone = this.inputApplyForm.deliveryTimeZone;
      }

      // 確認画面で入力済みの情報をStoreから取得(本人認証エラーで戻ってきた場合)
      const inputDestinationForm = this.$store.getters['eMansionIPPhoneStore/inputDestinationForm'] as EMansionIPPhoneInputDestinationForm;
      if (inputDestinationForm) {
        switch (inputDestinationForm.contractDestinationKind) {
          // 郵送
          case '1':
            this.inputDestinationInfo.selectedRadio = '3';
            break; // 共通コンポーネントでは３番目の選択
          // 電子交付（登録メールアドレス）
          case '3':
            this.inputDestinationInfo.selectedRadio = '1';
            break; // 共通コンポーネントでは１番目の選択
          // 電子交付（指定）
          case '4':
            this.inputDestinationInfo.selectedRadio = '2';
            break; // 共通コンポーネントでは２番目の選択
          default:
            // 何もしない
            break;
        }
        // セレクトボタンに寄らず前の画面のデータをリストアする
        this.inputDestinationInfo.inputMailAddress = inputDestinationForm.contractDestinationMailAddress;
        this.inputDestinationInfo.inputMailAddressConfirm = inputDestinationForm.contractDestinationMailAddressConfirm;
        this.inputDestinationInfo.deliveryZipcodeFormer = inputDestinationForm.contractDestinationZipCodeFormer;
        this.inputDestinationInfo.deliveryZipcodeLatter = inputDestinationForm.contractDestinationZipCodeLatter;
        this.inputDestinationInfo.destinationAddress1 = inputDestinationForm.contractDestinationAddress1;
        this.inputDestinationInfo.destinationAddress2 = inputDestinationForm.contractDestinationAddress2;
      }

      // e-mansionに登録された支払方法が「クレジットカード」の場合
      // ベリトランスにカード情報を問い合わせる
      if (customer instanceof EMansionCustomer && customer.payment_id === '8' && member.eTncMemberId) {
        const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
        this.cardExpiredFromIsp = cardInfo.cardExpire;
        this.maskedCardNumberFromIsp = cardInfo.cardNumber;
        this.cardHolderNameFromIsp = cardInfo.cardholderName;
        this.emansionVeriTransAccountId = member.eTncMemberId;
      } else if (member.veritransAccountId) {
        this.portasVeriTransId = member.veritransAccountId;
      }
      // VeriTrans 本人認証失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
        this.creditCardAccessToken = '';
        this.creditTokenExpireDate = '';
      }
      this.isLoading = false;
      this.isMounted = true;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      // ISP共通エラー画面に遷移
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
    return;
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      this.isLoading = false;
      this.isSubmitting = false;
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      return;
    },
    /** 前画面に戻る（現在の入力情報を保持） */
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;

      // 途中まで入力された状態を保持（画面間遷移）
      this.createDestinationRequest();
      this.$store.commit('eMansionIPPhoneStore/inputDestinationForm', this.destinationInfo);

      await this.$router
        .push('/e-mansion/ip-phone/apply')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onApply() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      // ご契約内容の交付方法（共通コンポーネント）の入力結果取得
      if (!this.validateDestinationInfo()) {
        this.showErrorMessage();
        return;
      }
      this.createDestinationRequest();

      /**クレカ処理-------------------------------ここから */

      // クレジットカードトークンがない かつ e-mansionに登録された支払方法が存在しない かつ Portasからカード情報をコピーして支払方法を登録しない場合はエラー
      if (this.creditCardAccessToken === '' && !(this.cardExpiredFromIsp && this.maskedCardNumberFromIsp) && !this.isAgreedCopyCardToIsp && !this.noDisplayCreditCardForm) {
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        // エラーメッセージを見せるために画面最上部にスクロール
      }

      if (this.errorMessages.length > 0) {
        // エラーが表示されているので
        this.showErrorMessage();
        return;
      }
      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      /** 契約基本情報をStoreから取得 */
      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];

      if (customer instanceof EMansionCustomer) {
        // 支払方法が登録されている場合(0:口座振替 or 1:振込 or 4:口振(請求書))
        if (this.noDisplayCreditCardForm) {
          // サービス登録APIに進む
          try {
            // サービス登録API(IPフォン) を呼び出す
            const ippResponse = (await SpfApiIPPoneAccessor.registerIPPhone(
              this.eMansionMemberId,
              this.eMansionPropertyId,
              this.inputApplyForm.deliveryDay,
              this.inputApplyForm.deliveryTimeZone!.value,
              this.destinationInfo!.contractDestinationKind,
              this.destinationInfo!.contractDestinationKind === '4' ? this.destinationInfo!.contractDestinationMailAddress : '',
              this.destinationInfo!.contractDestinationKind === '1' ? this.destinationInfo!.contractDestinationZipCode : '',
              this.destinationInfo!.contractDestinationKind === '1' ? this.destinationInfo!.contractDestinationAddress1 : '',
              this.destinationInfo!.contractDestinationKind === '1' ? this.destinationInfo!.contractDestinationAddress2 : '',
              '0' //creditCardRegistFlag
            )) as EMansionIPPhoneRegisterResponse;

            // APIが正常応答の場合、完了画面に表示する情報をストアに保存する
            this.$store.commit('eMansionIPPhoneStore/registerResult', ippResponse);
            this.$store.commit('eMansionIPPhoneStore/inputDestinationForm', this.destinationInfo);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            await VueErrorHandler.handle(error, '');
            // 上記以外のエラーの場合はe-mansion共通エラー画面に遷移
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            this.isSubmitting = false;
            return;
          }

          await this.$router
            .push('/e-mansion/ip-phone/completed')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              checkRouterError(error);
            })
            .finally(() => {
              this.isSubmitting = false;
            });
          return;
          // クレジットカードが e-mansion に登録されている場合の申し込み処理
        } else if (this.emansionVeriTransAccountId) {
          // 支払方法が登録されている(8:クレジットカード)かつベリトランスから正常に情報が取得できている場合

          // 申込内容一時保存処理
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            inputApplyForm: this.inputApplyForm,
            inputDestinationForm: this.destinationInfo,
            veriTransAccountId: this.emansionVeriTransAccountId,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            creditCardRegistFlag: '0',
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-ip-phone-apply',
          });
          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          let cardHolderNameForAuthorize: string;
          if (this.cardHolderNameFromIsp) {
            cardHolderNameForAuthorize = this.cardHolderNameFromIsp;
          } else {
            cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
          }
          const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
            uuid: uuidForTemporarySavingApplicationData,
            externalVeritransAccountId: this.emansionVeriTransAccountId,
            cardholderName: cardHolderNameForAuthorize,
          });
          // VeriTrans_本人認証(VeriTrans会員ID使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              // エラーメッセージを見せるために画面最上部にスクロール(フラグ制御)
              this.showErrorMessage();
              return;
            } else {
              await VueErrorHandler.handle(error, '');
              // ISP共通エラー画面に遷移
              await this.$router.push('/e-mansion/error').catch((error) => {
                checkRouterError(error);
              });
              this.isSubmitting = false;
              return;
            }
          }
        } else if (this.isAgreedCopyCardToIsp && this.portasVeriTransId) {
          // PortasからISPにカード情報をコピーする場合
          // 申込内容一時保存処理
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            inputApplyForm: this.inputApplyForm,
            inputDestinationForm: this.destinationInfo,
            veriTransAccountId: this.portasVeriTransId,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            creditCardRegistFlag: '1',
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-ip-phone-apply',
          });
          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          let cardHolderNameForAuthorize: string;
          if (this.cardHolderNameOnPortas) {
            cardHolderNameForAuthorize = this.cardHolderNameOnPortas;
          } else {
            cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizePortasCard!;
          }
          const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
            uuid: uuidForTemporarySavingApplicationData,
            externalVeritransAccountId: this.portasVeriTransId,
            cardholderName: cardHolderNameForAuthorize,
          });
          // VeriTrans_本人認証(VeriTrans会員ID使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              // エラーメッセージを見せるために画面最上部にスクロール(フラグ制御)
              this.showErrorMessage();
              return;
            } else {
              await VueErrorHandler.handle(error, '');
              // ISP共通エラー画面に遷移
              await this.$router.push('/e-mansion/error').catch((error) => {
                checkRouterError(error);
              });
              this.isSubmitting = false;
              return;
            }
          }
          return;
        } else {
          /**Portas,e-mansionにカードが未登録の場合 */
          // 申込内容一時保存処理
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            inputApplyForm: this.inputApplyForm,
            inputDestinationForm: this.destinationInfo,
            creditCardToken: this.creditCardAccessToken,
            tokenExpireDate: this.creditTokenExpireDate,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            creditCardRegistFlag: '1',
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-ip-phone-apply',
          });
          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          // ページ遷移時に beforeEach で会員情報取得できるように null にする
          this.$store.commit('memberStore/member', null);
          const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
            uuid: uuidForTemporarySavingApplicationData,
            creditCardToken: this.cardTokenFor3dSecureAuthorize,
            serviceProviderIsp: SERVICE_PROVIDER.MYE,
          });
          // VeriTrans_本人認証(トークン使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              // エラーメッセージを見せるために画面最上部にスクロール(フラグ制御)
              this.showErrorMessage();
              return;
            } else {
              await VueErrorHandler.handle(error, '');
              // ISP共通エラー画面に遷移
              await this.$router.push('/e-mansion/error').catch((error) => {
                checkRouterError(error);
              });
              this.isSubmitting = false;
              return;
            }
          }
        }
      }
    },
    /** 表示料金取得 */
    getDisplayBillingData() {
      // キャンペーン文言の有無で、キャンペーン適用有無を判断
      if (this.eMansionProperty instanceof EMansionProperty) {
        this.displayIPPCampaign =
          this.eMansionProperty.cp_info_message && this.eMansionProperty.cp_info_message[0]
            ? this.removeLineBreakTags(this.eMansionProperty.cp_info_message[0].campaign_ipphone)
            : '';
        this.isCampaign = this.displayIPPCampaign != null;
      }
      // 通常の価格
      this.displayJimuFee = this.formatMoney(this.eMansionProperty?.op.ipp.jimu_fee);
      this.displayBasicFee = this.formatMoney(this.eMansionProperty?.op.ipp.basic_fee);
      this.displayRentalCharge = this.formatMoney(this.eMansionProperty?.op.ipp.rental_charge);
      this.displayUniChargeTaxInc = this.formatMoney(this.eMansionProperty?.op.ipp.uni_charge_tax_inc);
      // キャンペーン適用価格
      this.displayJimuFeeCp = this.formatMoney(this.eMansionProperty?.op.ipp.jimu_fee_cp);
      this.displayBasicFeeCp = this.formatMoney(this.eMansionProperty?.op.ipp.basic_fee_cp);
      this.displayRentalChargeCp = this.formatMoney(this.eMansionProperty?.op.ipp.rental_charge_cp);
      this.displayUniChargeCp = this.formatMoney(this.eMansionProperty?.op.ipp.uni_charge_cp);
      if (!this.displayJimuFeeCp && !this.displayBasicFeeCp && !this.displayRentalChargeCp && !this.displayUniChargeCp) {
        // 金額のいずれか1つでもキャンペーン価格適用の場合、指定文を表示
        this.isCampaignCost = false;
      } else {
        this.isCampaignCost = true;
      }

      this.displayTotalFirstMonthCost = new Intl.NumberFormat('ja').format(this.getTotalFirstMonthCost());
      this.displayTotalMonthlyCost = new Intl.NumberFormat('ja').format(this.getTotalMonthlyCost());
    },
    /** 初回総額 */
    getTotalFirstMonthCost(): number {
      // キャンペーン適用時はキャンペーン金額の合計
      let totalFirstMonthCost = 0;

      const jimu_fee = this.displayJimuFeeCp ? this.displayJimuFeeCp : this.displayJimuFee;
      totalFirstMonthCost += Number(jimu_fee!.replace(',', ''));
      const basic_fee = this.displayBasicFeeCp ? this.displayBasicFeeCp : this.displayBasicFee;
      totalFirstMonthCost += Number(basic_fee!.replace(',', ''));
      const rental_fee = this.displayRentalChargeCp ? this.displayRentalChargeCp : this.displayRentalCharge;
      totalFirstMonthCost += Number(rental_fee!.replace(',', ''));
      const uni_charge = this.displayUniChargeCp ? this.displayUniChargeCp : this.displayUniChargeTaxInc;
      totalFirstMonthCost += Number(uni_charge!.replace(',', ''));
      if (Number.isNaN(totalFirstMonthCost)) {
        return 0;
      }

      return Math.floor(totalFirstMonthCost);
    },
    /** 次回以降の総額 */
    getTotalMonthlyCost(): number {
      // キャンペーンの適用有無に寄らず、通常金額で合算する（事務手数料は対象外）
      let totalMonthlyCost = 0;

      totalMonthlyCost += Number(this.displayBasicFee?.replace(',', ''));
      totalMonthlyCost += Number(this.displayRentalCharge?.replace(',', ''));
      totalMonthlyCost += Number(this.displayUniChargeTaxInc?.replace(',', ''));
      if (Number.isNaN(totalMonthlyCost)) {
        return 0;
      }

      return Math.floor(totalMonthlyCost);
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    removeLineBreakTags(text: string | undefined) {
      if (!text) return '';
      return text.replace(/<br \/?>/g, '');
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: this.eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });
      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** 3Dセキュア本人認証用カード名義人を受け取る */
    getCardHolderNameFromPortas(cardHolderName: string) {
      this.cardHolderNameOnPortas = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、ISP登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizeIspCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizeIspCard = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、Portas登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizePortasCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizePortasCard = cardHolderName;
    },
    /** ご契約内容の交付方法の選択 */
    getInputDestinationInfo(data: any) {
      this.inputDestinationInfo = data;
    },
    validateDestinationInfo(): boolean {
      const VALIDATE_EMAIL_LENGTH = 48;
      let isValid = true;
      if (this.inputDestinationInfo.selectedRadio === '1') {
        // 電子交付を希望（登録いただいたメールアドレス「＊＊＊」へ送信
        // 他のパラメータは必須ではない
      } else if (this.inputDestinationInfo.selectedRadio === '2') {
        // 電子交付を希望（ご指定のメールアドレスへ送信）
        if (!this.inputDestinationInfo.inputMailAddress) {
          // 未入力
          this.errorMessages.push('「「ご契約内容の交付方法」ご希望メールアドレス」を半角英数字・記号で入力してください。');
          isValid = false;
        } else {
          // 文字数オーバー
          if (this.inputDestinationInfo.inputMailAddress.length > VALIDATE_EMAIL_LENGTH) {
            this.errorMessages.push('「「ご契約内容の交付方法」ご希望メールアドレス」を半角英数字・記号48文字以内で入力してください。');
            isValid = false;
          }
          // 書式エラー
          // eslint-disable-next-line no-useless-escape
          const regexpEMail = /^[\w\.!"#$%&'\(\)\*\+-\.\/:;<=>\?\[\]\^_`\{\|\}~]+@[^@\s,\\]+$/i;
          if (!regexpEMail.test(this.inputDestinationInfo.inputMailAddress)) {
            this.errorMessages.push('「「ご契約内容の交付方法」ご希望メールアドレス」を半角英数字・記号で入力してください。');
            isValid = false;
          }
        }
        // 確認用メールアドレスとの不一致
        if (this.inputDestinationInfo.inputMailAddress != this.inputDestinationInfo.inputMailAddressConfirm) {
          this.errorMessages.push('「「ご契約内容の交付方法」ご希望メールアドレス」の入力と、確認用に入力された内容が一致しません。もう一度新たに入力しなおしてください。');
          isValid = false;
        }
      } else if (this.inputDestinationInfo.selectedRadio === '3') {
        // 電子交付を希望しない
        // 郵便番号未入力
        if (!this.inputDestinationInfo.deliveryZipcodeFormer || !this.inputDestinationInfo.deliveryZipcodeLatter) {
          this.errorMessages.push('「「ご契約内容の交付方法」郵便番号」を半角数字で入力してください。');
          isValid = false;
        } else {
          // 郵便番号書式エラー
          const regexZipCode = /^[0-9]{7}$/i;
          const zipcode = this.inputDestinationInfo.deliveryZipcodeFormer + this.inputDestinationInfo.deliveryZipcodeLatter;
          if (!regexZipCode.test(zipcode)) {
            this.errorMessages.push('「「ご契約内容の交付方法」郵便番号」は半角数字7文字で入力してください。');
            isValid = false;
          }
        }
        // 住所（１）未入力
        // eslint-disable-next-line no-control-regex
        const regexAddress1 = /^[^\x01-\x7E\uFF61-\uFF9F]{1,30}$/i;
        if (!this.inputDestinationInfo.destinationAddress1) {
          this.errorMessages.push('「「ご契約内容の交付方法」ご住所(1)」を全角で入力してください。');
          isValid = false;
        } else if (!regexAddress1.test(this.inputDestinationInfo.destinationAddress1)) {
          // 文字数オーバー（文字種チェックを兼ねる）
          this.errorMessages.push('「「ご契約内容の交付方法」ご住所(1)」を全角30文字以内で入力してください。');
          isValid = false;
        }

        // 住所（２）入力時のみ確認
        // eslint-disable-next-line no-control-regex
        const regexAddress2 = /^[^\x01-\x7E\uFF61-\uFF9F]{1,60}$/i;
        if (this.inputDestinationInfo.destinationAddress2 && !regexAddress2.test(this.inputDestinationInfo.destinationAddress2)) {
          this.errorMessages.push('「「ご契約内容の交付方法」ご住所(2)」を全角60文字以内で入力してください。');
          isValid = false;
        }
      } else {
        // 通常あり得ない（ラジオボタンはどこかが選択状態のはず）
        isValid = false;
      }

      if (!isValid) {
        // 共通コンポーネント仕様に従い、メッセージタイトルを表示
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
      }
      return isValid;
    },
    createDestinationRequest() {
      let kind = '0';
      switch (this.inputDestinationInfo.selectedRadio) {
        case '2':
          kind = '4';
          break;
        case '3':
          kind = '1';
          break;
        default:
        case '1':
          kind = '3';
          break;
      }

      this.destinationInfo = new EMansionIPPhoneInputDestinationForm({
        contractDestinationKind: kind,
        SettingEMailAddress: kind === '3' ? this.inputDestinationInfo.emailAddress : '',
        contractDestinationMailAddress: kind === '4' ? this.inputDestinationInfo.inputMailAddress : '',
        contractDestinationMailAddressConfirm: kind === '4' ? this.inputDestinationInfo.inputMailAddressConfirm : '',
        contractDestinationZipCode: kind === '1' ? this.inputDestinationInfo.deliveryZipcodeFormer + this.inputDestinationInfo.deliveryZipcodeLatter : '', // ハイフンなし7桁
        contractDestinationZipCodeFormer: kind === '1' ? this.inputDestinationInfo.deliveryZipcodeFormer : '',
        contractDestinationZipCodeLatter: kind === '1' ? this.inputDestinationInfo.deliveryZipcodeLatter : '',
        contractDestinationAddress1: kind === '1' ? this.inputDestinationInfo.destinationAddress1 : '',
        contractDestinationAddress2: kind === '1' ? this.inputDestinationInfo.destinationAddress2 : '',
      });
    },
    getErrorMessage(data: any) {
      this.errorMessages = [];
      this.errorMessages.push(data);
      this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: string | undefined): string {
      if (!billing) {
        return '';
      }
      //念のためカンマを除去
      const billingNumStr = billing.replace(/,/g, '');
      const billingNum = parseFloat(billingNumStr);
      //数値かどうかチェック
      if (isNaN(billingNum)) {
        return '';
      }
      return new Intl.NumberFormat('en-US').format(Number(billingNum));
    },
  },
});
</script>
