import { Module } from 'vuex';
import { RootState } from '../index';

/** e-mansion UCOM光電話 オプションお申し込み State */
const eMansionUcomHikariPhoneOptionEntryState: {
  option_1: boolean;
  option_2: boolean;
  option_3: boolean;
  option_4: boolean;
} = {
  option_1: false,
  option_2: false,
  option_3: false,
  option_4: false,
};

/** State の型 */
type eMansionUcomHikariPhoneOptionEntryState = typeof eMansionUcomHikariPhoneOptionEntryState;

/** e-mansion UCOM光電話 オプションお申し込み Store */
export const eMansionUcomHikariPhoneOptionEntryStore: Module<eMansionUcomHikariPhoneOptionEntryState, RootState> = {
  namespaced: true,
  state: eMansionUcomHikariPhoneOptionEntryState,
  actions: {},
  mutations: {
    /** 着信番号表示 */
    option_1: (state, value: boolean) => (state.option_1 = value),
    /** キャッチ通話 */
    option_2: (state, value: boolean) => (state.option_2 = value),
    /** キャッチ通話番号表示 */
    option_3: (state, value: boolean) => (state.option_3 = value),
    /** オプションパック */
    option_4: (state, value: boolean) => (state.option_4 = value),
  },
  getters: {
    /** 着信番号表示 */
    option_1: (state): boolean => state.option_1,
    /** キャッチ通話 */
    option_2: (state): boolean => state.option_2,
    /** キャッチ通話番号表示 */
    option_3: (state): boolean => state.option_3,
    /** オプションパック */
    option_4: (state): boolean => state.option_4,
  },
};
