<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ウイルスチェック</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/platform">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>ウイルスチェック</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ウイルスチェック購入／解約確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ウイルスチェック購入解約確認" />ウイルスチェック購入／解約確認</h2>
        <p>お申し込み内容をご確認いただき、問題ない場合は「決定」ボタンを押してください。</p>

        <error-messages-component :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <table class="table-type2">
            <tr>
              <th class="va-middle">お申し込み内容</th>
              <td>
                <b>{{ subscriptionTypeText }}</b>
              </td>
            </tr>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>
                <span v-if="purchaseState">{{ purchaseState.accountName }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>
                <span v-if="purchaseState">{{ purchaseState.mailAddress }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>
                <span class="o" v-if="purchaseState">月額 {{ myNumberWithDelimiter(purchaseState.monthlyFee) }} 円 （税込）</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>
                <span class="o" id="current_date" v-if="purchaseState">{{ purchaseState.subscriptionDate }}</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td>
                <span v-if="purchaseState" v-html="purchaseState.serviceDescriptionBeforePurchase"></span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- /blc -->
      <h3 v-if="is3dsecure" class="service-h3">UCOM光 レジデンスサービス お支払い方法</h3>
      <div v-if="is3dsecure">
        <div class="pd10 ml10">
          <p>UCOM光 レジデンスにご登録されている以下のお支払い方法により、お支払いいただきます。</p>
          <h3>クレジットカード</h3>
          <table class="table-type2">
            <tbody>
              <tr>
                <th class="va-middle">カード番号</th>
                <td>{{ maskedCardNumberFromIsp }}</td>
              </tr>
              <tr>
                <th class="va-middle">カード期限</th>
                <td>{{ cardExpiredMonthOnPortas }}月 ／ {{ cardExpiredYearOnPortas }}年</td>
              </tr>
              <tr v-if="!isIspCardHolderName">
                <td>
                  <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
                </td>
                <td>
                  <div class="mt10">
                    <input
                      type="text"
                      class="text card-name"
                      v-model.trim="cardholderNameFormForAuthorizeIspCard"
                      required
                      minlength="2"
                      maxlength="45"
                      @change="inputCardholderNameFormForAuthorizeIspCard"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- blc -->
      <div class="blc">
        <p v-if="is3dsecure" class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
        </p>
        <p class="form-btn-txt mt40">「決定」を押してください。完了画面へ進みます。</p>
        <div>
          <div class="btn-area">
            <div>
              <button class="btn btn04 bs" @click="goBack"><i class="material-icons link link-icon">west</i>戻る</button>
            </div>
            <div class="has-checkbox">
              <button class="btn btn01 bs" type="button" :class="[isDisabledButton ? 'btn05' : 'btn01']" :disabled="isDisabledButton" @click="apply">
                決定<i class="material-icons link link-icon">east</i>
              </button>
              <div v-if="is3dsecure" class="my-checkbox-outer">
                <input id="checkbox-to-agree" type="checkbox" v-model="isAgreeTerms" />
                <p class="my-checkbox-text"><label for="checkbox-to-agree">各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<style lang="scss" scoped>
.card-name {
  width: 100%;
  max-width: 360px;
}

div.btn-area {
  margin: 30px auto 0;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  text-align: center;
  padding-top: 72px;

  & div.has-checkbox {
    padding-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 10px;
      left: 0;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  div.btn-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 64px;
    margin-top: 16px;

    & div.has-checkbox {
      padding-top: 0;

      & div.my-checkbox-outer {
        top: 0;
        left: 50%;
        text-align: left;

        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { SpfApiUcomVirusCheckAccessor } from '../../../infra/accessor/spf/ucom/spf-api-virus-check-accessor';
import { UcomVirusCheckPurchaseState } from '../../../shared/classes/ucom/ucom-virus-check-purchase-state';
import {
  UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE,
  UcomVirusCheckPurchaseSubscriptionType,
  convertUcomVirusCheckPurchaseSubscriptionTypeById,
} from '../../../shared/const/ucom/ucom-virus-check-purchase-subscription-type';
import { numberWithDelimiter } from '../../../shared/util/vue-filters';
import { UcomErrorResponse } from '../classes/external-api/error-response';
import { UcomPaymentStatusResponse } from '../classes/external-api/payment-status-response';

import { UpdateUcomVirusCheckRequest } from '@/infra/request/spf/ucom/virus-check/update-ucom-virus-check-request';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UCOM_PAYMENT_METHOD_ID } from '@/shared/const/ucom/ucom-payment-method-id';
import { UCOM_VIRUS_CHECK_ACTION, UcomVirusCheckAction } from '@/shared/const/ucom/ucom-virus-check-action';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomExternalApiService } from '@/shared/services/external-api/ucom-external-api-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { inputEventConvertToUpper } from '@/shared/util/inputEventConvertToUpper';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { Property } from '@/shared/classes/spf-api/property';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';

export default defineComponent({
  name: 'virus-check-purchase-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data(): {
    member: Member | undefined;
    isMounted: boolean;
    isBusy: boolean;
    errorMessages: string[];
    accountId?: number;
    purchaseState?: UcomVirusCheckPurchaseState;
    paymentId: string;
    is3dsecure: boolean;
    isIspCardHolderName: boolean;
    cardholderName: string | undefined;
    cardholderNameFormForAuthorizeIspCard: string;
    isAgreeTerms: boolean;
    maskedCardNumberFromIsp: string;
    cardExpiredMonthOnPortas: string;
    cardExpiredYearOnPortas: string;
    ucomVeriTransAccountId: string;
    errorMessageTitle: string;
    action: UcomVirusCheckAction | undefined;
    ucomUrl: string;
    apid: string;
  } {
    return {
      member: undefined,
      isMounted: false,
      isBusy: false,
      errorMessages: [],
      /** エラーメッセージ部タイトル */
      errorMessageTitle: '',
      accountId: undefined,
      purchaseState: undefined,
      /** ISPに登録された支払い方法 */
      paymentId: '',
      /** クレジットカードの判定 */
      is3dsecure: false,
      /** ISPにカード名義人名が登録されているか */
      isIspCardHolderName: false,
      /** カード名義人名 */
      cardholderName: '' as string | undefined,
      /** 規約に同意する */
      isAgreeTerms: false,
      /** 連携先ISPで登録されたクレジットカードのマスク済カード番号 */
      maskedCardNumberFromIsp: '',
      /** Portasで登録されたクレジットカードの有効期限(月) */
      cardExpiredMonthOnPortas: '',
      /** Portasで登録されたクレジットカードの有効期限(年) */
      cardExpiredYearOnPortas: '',
      /** UCOM で登録されたクレジットカードのVeriTrans会員ID */
      ucomVeriTransAccountId: '',
      /** 購入か解約か */
      action: undefined,
      cardholderNameFormForAuthorizeIspCard: '',
      ucomUrl: process.env.VUE_APP_UCOM_URL,
      apid: '',
    };
  },
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 物件基本情報の物件IDを取得してapidへ変換
    const property: Property | null = this.$store.getters['propertyStore/property'];
    if (property?.apartmentId) {
      this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
    }

    const ucomAccountId: string = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
    this.accountId = Number(ucomAccountId);

    try {
      // 前画面からの情報を取得する
      this.purchaseState = this.$store.getters['ucomVirusCheckStore/purchaseState'];

      // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
      }

      const member: Member = this.$store.getters['memberStore/member'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      if (this.purchaseState === undefined) {
        throw new Error();
      }
      this.action = this.convertPurchaseAction(this.purchaseState.subscriptionType);

      if (customer.is_register_credit_card && member.primaryKeyUcom && this.action !== UCOM_VIRUS_CHECK_ACTION.CANCEL) {
        try {
          // お支払い情報登録状態を取得
          const paymentMethod: UcomPaymentStatusResponse | UcomErrorResponse = await UcomExternalApiService.getPaymentStatus(String(member.primaryKeyUcom));
          if (paymentMethod instanceof UcomPaymentStatusResponse) {
            this.paymentId = paymentMethod.payment_method_id;
          } else {
            throw new Error();
          }
          if (this.paymentId === UCOM_PAYMENT_METHOD_ID.CREDIT_CARD) {
            try {
              this.ucomVeriTransAccountId = paymentMethod.card_account_id;
              // VeriTransへ登録されているクレジットカード情報を取得する
              const getDefaultPaymentMethodResponse: PaymentMethodDetailResponse = await this.getPaymentMethodFromExternalIsp(this.ucomVeriTransAccountId);

              this.maskedCardNumberFromIsp = this.addHyphenToCardNumber(getDefaultPaymentMethodResponse.cardNumber);
              this.cardholderName = getDefaultPaymentMethodResponse.cardholderName;
              if (this.cardholderName) this.isIspCardHolderName = true;

              // クレジットカードの年月取得
              this.cardExpiredMonthOnPortas = getDefaultPaymentMethodResponse.cardExpire.slice(0, 2);
              this.cardExpiredYearOnPortas = getDefaultPaymentMethodResponse.cardExpire.slice(-2);
              this.is3dsecure = true;
            } catch (error: any) {
              throw error;
            }
          }
        } catch (e: any) {
          await this.$router.push('/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      }

      this.isBusy = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    // 戻るボタン押下時にstoreのsetPauseStateに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
    this.$store.commit('ucomVirusCheckStore/setPurchaseState', undefined);
    this.isMounted = true;
  },
  methods: {
    /** 「戻る」のクリックハンドラ */
    async goBack(): Promise<void> {
      this.$store.commit('ucomVirusCheckStore/setPurchaseState', this.$data.purchaseState);
      await this.$router.push('/ucom/virus-check/input').catch((error) => {
        checkRouterError(error);
      });
    },

    /** 「決定」のクリックハンドラ */
    async apply(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // エラーメッセージ初期化
      this.errorMessages = [];
      this.errorMessageTitle = '';

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 型ガード
      if (this.accountId === undefined) {
        throw new Error();
      }
      if (this.purchaseState === undefined) {
        throw new Error();
      }
      if (this.action === undefined) {
        throw new Error();
      }

      // UCOM にクレジットカード以外の支払い方法が登録済の場合、または解約申込の場合
      if (!this.is3dsecure || this.action === UCOM_VIRUS_CHECK_ACTION.CANCEL) {
        try {
          // ウイルスチェック情報更新 (購入/解約) API 呼出し
          const virusCheckUpdateResult = await SpfApiUcomVirusCheckAccessor.updateUcomVirusCheckPurchase(
            new UpdateUcomVirusCheckRequest(Number(await AuthService.getMemberId()), this.accountId, this.purchaseState.subscriptionDate, this.action)
          );
          // レスポンスにエラーメッセージがあればエラーメッセージを表示して処理を終了する
          if (virusCheckUpdateResult.errorMessage) {
            this.errorMessages = [virusCheckUpdateResult.errorMessage];
            this.errorMessageTitle = '恐れ入りますが、内容をもう一度ご確認ください。';
            // エラーメッセージを見せるために画面最上部にスクロール
            window.scrollTo(0, 0);
            this.isBusy = false;
            return;
          }
          // 購入/解約完了 画面に渡す情報を Store に保存する
          this.$store.commit('ucomVirusCheckStore/setPurchaseState', this.$data.purchaseState);
          this.$store.commit('ucomVirusCheckStore/setNotifyMailAddress', virusCheckUpdateResult.mailAddress);

          // 購入/解約完了 画面に遷移する。catch() はナビゲーションガードでエラー画面へ行くのを防ぐために必要。
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/ucom/virus-check/purchase-complete').catch((e: any) => {
            checkRouterError(e);
          });
          this.isBusy = false;
          return;
        } catch (error) {
          await this.$router.push('/error').catch((error) => {
            checkRouterError(error);
          });
        }
        // UCOM にクレジットカードが登録済の場合
      } else {
        const member: Member = this.$store.getters['memberStore/member'];
        if (
          !this.isIspCardHolderName &&
          (!this.cardholderNameFormForAuthorizeIspCard || this.cardholderNameFormForAuthorizeIspCard?.length < 2 || this.cardholderNameFormForAuthorizeIspCard?.length > 45)
        ) {
          this.errorMessages.push('カード名義人を正しく入力してください。');
          this.isBusy = false;
          this.isAgreeTerms = false;
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }
        // 申込内容一時保存用 UUIDを生成
        const uuidForTemporarySavingApplicationData = uuidv4();
        const paymentMethodApplicationData = {
          memberId: member.id,
          accountId: this.accountId,
          purchaseState: this.purchaseState,
          action: this.action,
        };

        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'ucom-virus-check-apply',
        });

        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
        }
        // 3dセキュア対応用 uuid localStorageに保存
        localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

        if (member.primaryKeyUcom) {
          if (uuidForTemporarySavingApplicationData !== null && this.ucomVeriTransAccountId) {
            let cardHolderNameForAuthorize: string;
            if (this.cardholderName) {
              cardHolderNameForAuthorize = this.cardholderName;
            } else {
              cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
            }

            // VeriTrans_本人認証用のデータ格納
            const dddSecureAuthStartWithVeriTransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
              uuid: uuidForTemporarySavingApplicationData,
              externalVeritransAccountId: this.ucomVeriTransAccountId,
              cardholderName: cardHolderNameForAuthorize,
            });
            // VeriTrans_本人認証(VeriTrans会員ID使用) API
            try {
              const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeriTransAccountIdRequest);
              location.href = authStartURL;
              this.isBusy = false;
            } catch (error: any) {
              if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                this.errorMessages.push(
                  `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.ucomUrl}/${this.apid}/" target="_blank">こちら</a>から、サポートセンターまでお問い合わせください。`
                );
                this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
                this.isBusy = false;
                this.isAgreeTerms = false;
                // エラーメッセージを見せるために画面最上部にスクロール
                window.scrollTo(0, 0);
                return;
              } else {
                throw error;
              }
            }
          }
        }
      }
    },

    /**
     * 「購入」または「解約」の値を API に渡すパラメータに変換する
     */
    convertPurchaseAction(purchaseAction: UcomVirusCheckPurchaseSubscriptionType | undefined): UcomVirusCheckAction | undefined {
      if (purchaseAction === UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.PURCHASE) {
        return UCOM_VIRUS_CHECK_ACTION.PURCHASE;
      }
      if (purchaseAction === UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.CANCEL) {
        return UCOM_VIRUS_CHECK_ACTION.CANCEL;
      }
    },
    myNumberWithDelimiter(value: number) {
      return numberWithDelimiter(value);
    },

    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },
    /** 申込内容一時保存API の実行 */
    async temporarySavingApplicationData(applicationData: TemporarySavingApplicationDataRequest) {
      return await SpfApiService.temporarySavingApplicationData(applicationData);
    },
    inputCardholderNameFormForAuthorizeIspCard(event: Event) {
      this.cardholderNameFormForAuthorizeIspCard = inputEventConvertToUpper(event);
    },
    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
  },
  computed: {
    /** ローダーを表示するかどうか */
    isLoading(): boolean {
      if (!this.isMounted) {
        return true;
      }
      return this.isBusy;
    },
    /** お申し込み内容 に表示するテキスト */
    subscriptionTypeText(): string {
      if (this.purchaseState?.subscriptionType === undefined) {
        return '';
      }
      return convertUcomVirusCheckPurchaseSubscriptionTypeById(this.purchaseState.subscriptionType) ?? '';
    },
    // チェックボックスによる「申し込む」ボタンの判定
    isDisabledButton(): boolean {
      if (!this.is3dsecure) {
        return false;
      }
      if (this.isAgreeTerms) {
        return false;
      }
      return true;
    },
  },
  watch: {
    cardholderNameFormForAuthorizeIspCard: {
      handler: function (newVal, oldVal) {
        if (newVal && !newVal.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.cardholderNameFormForAuthorizeIspCard = oldVal;
        }
      },
    },
  },
});
</script>

<style scoped>
/* 非活性のボタンがマウスオーバーに反応しないように */
button.btn05:hover {
  opacity: 1;
}
</style>
