<template>
  <div class="completed">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion i-フィルター</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li class="stay">i-フィルター</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="i-フィルター お申し込み" />i-フィルター　お申し込み受付完了</h2>
        <p>i-フィルターのお申し込みありがとうございます。</p>
      </div>

      <div class="blc">
        <p><b class="red">※ご契約マンションの「鍵のお引渡し日」以降に、i-フィルター をダウンロードしていただけます。</b></p>
        <p class="title-margin">
          <span class="course-complete-att"
            >「鍵のお引渡し日」以降に、e-mansion会員専用トップページ「利用状況一覧」メニューの「セキュリティ関連」ページからダウンロードが可能です。</span
          >
        </p>
      </div>

      <!-- ご契約内容の交付方法 -->
      <ContractDetailsConfirmMethod />

      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="onNext()" type="button">e-mansion会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import ContractDetailsConfirmMethod from '@/shared/components/e-mansion/contract-details-confirm-method.vue';
import { MountedCheckService } from '@/shared/services/mounted-check-service';

export default defineComponent({
  name: 'platform-terms-i-filter',
  components: {
    LoadingComponent,
    ContractDetailsConfirmMethod,
  },
  data(): {
    isNotifyEmail: boolean;
    // e-mansion サイト URL
    eMansionUrl: string;
    // 8桁APIDE
    apid: string;
    /** 最初はロード中 */
    isLoading: boolean;
  } {
    return {
      isNotifyEmail: false,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      apid: '',
      isLoading: true,
    };
  },
  async mounted(): Promise<void> {
    this.isLoading = true;
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
    this.isLoading = false;
    return;
  },
  methods: {
    async onNext(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/e-mansion').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
      return;
    },
  },
});
</script>

<style lang="scss" scoped>
.condition {
  margin: 0 0 5px;
  border-left: 3px solid #cf1225;
  padding-left: 15px;
  margin-left: 10px;
}
.title-margin {
  margin-top: 0.5px;
}
div.template {
  margin-top: 30px;
}
.course-complete-att {
  font-size: 13px !important;
  padding-left: 0.5em !important;
  text-indent: -1.5em !important;
}
</style>
