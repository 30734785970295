import { Module } from 'vuex';

import { RootState } from '../index';

/** e-mansion UCOM光電話 State */
const eMansionHikariphoneState: {
  inputData: {
    preferredDate: Date | null;
    selectedGetPhoneMethod: number | null;
    isNotifyNumber: string | null;
    takeOverPhoneNumber: string | null;
    relationshipType: number | null;
    holderName: string | null;
    holderNameKana: string | null;
    telephoneCompany: string | null;
    otherCompanyName: string | null;
    zipcode: string | null;
    address: string | null;
    apartment: string | null;
    selectedOptionalService: Array<{ key: number; value: string }> | null;
  };
  optionalData: {
    preferredDate: string | undefined;
    numberPortabilityFlag: string | undefined;
    portabilityNumber: string | undefined;
    relationshipType: string | undefined;
    name: string | undefined;
    kana: string | undefined;
    telephoneCompanyType: string | undefined;
    telephoneCompanyOther: string | undefined;
    destinationZipcode: string | undefined;
    destinationAddress1: string | undefined;
    destinationAddress2: string | undefined;
    notificationFlag: string | undefined;
    numberDisplay: string | undefined;
    catch: string | undefined;
    catchNumberDisplay: string | undefined;
    optionPack: string | undefined;
  };
  // 前の画面がサービス規約の場合はtrue
  from: boolean;
  // 新規番号用ご利用開始日
  serviceStartDateForRegistration: string | undefined;
  // 番号ポータビリティ用ご利用開始日
  serviceStartDateForNumberPortability: string | undefined;
} = {
  inputData: {
    preferredDate: null,
    selectedGetPhoneMethod: null,
    isNotifyNumber: null,
    takeOverPhoneNumber: null,
    relationshipType: null,
    holderName: null,
    holderNameKana: null,
    telephoneCompany: null,
    otherCompanyName: null,
    zipcode: null,
    address: null,
    apartment: null,
    selectedOptionalService: null,
  },
  optionalData: {
    preferredDate: undefined,
    numberPortabilityFlag: undefined,
    portabilityNumber: undefined,
    relationshipType: undefined,
    name: undefined,
    kana: undefined,
    telephoneCompanyType: undefined,
    telephoneCompanyOther: undefined,
    destinationZipcode: undefined,
    destinationAddress1: undefined,
    destinationAddress2: undefined,
    notificationFlag: undefined,
    numberDisplay: undefined,
    catch: undefined,
    catchNumberDisplay: undefined,
    optionPack: undefined,
  },
  from: false,
  serviceStartDateForRegistration: undefined,
  serviceStartDateForNumberPortability: undefined,
};

/** State の型 */
type EMansionHikariphoneState = typeof eMansionHikariphoneState;

/** e-mansion UCOM光電話 Store */
export const eMansionHikariphoneStore: Module<EMansionHikariphoneState, RootState> = {
  namespaced: true,
  state: eMansionHikariphoneState,
  mutations: {
    // 画面入力した値
    inputData: (state, payload: Partial<EMansionHikariphoneState['inputData']>) => {
      state.inputData = { ...state.inputData, ...payload };
    },
    // 前の画面がサービス規約の場合はtrue
    from: (state, value: boolean) => (state.from = value),
    // サービス登録APIで渡す値
    optionalData: (state, payload: Partial<EMansionHikariphoneState['optionalData']>) => {
      state.optionalData = { ...state.optionalData, ...payload };
    },
    // 新規番号用ご利用開始日
    serviceStartDateForRegistration: (state, value: string) => (state.serviceStartDateForRegistration = value),
    // 番号ポータビリティ用ご利用開始日
    serviceStartDateForNumberPortability: (state, value: string) => (state.serviceStartDateForNumberPortability = value),
  },
  getters: {
    inputData: (state): EMansionHikariphoneState['inputData'] => {
      return state.inputData;
    },
    from: (state): boolean => state.from,
    optionalData: (state): EMansionHikariphoneState['optionalData'] => {
      return state.optionalData;
    },
    serviceStartDateForRegistration: (state): string | undefined => state.serviceStartDateForRegistration,
    serviceStartDateForNumberPortability: (state): string | undefined => state.serviceStartDateForNumberPortability,
  },
};
