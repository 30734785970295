<template>
  <div>
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/e-mansion">e-mansion 会員専用トップ</a></li>
        <li>UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>オプションお申し込み・解約</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="付加サービス（有料）お申し込み確認" />付加サービス（有料）お申し込み確認</h2>
        <p>お申し込み内容をご確認ください。</p>
      </div>
      <div>
        <h3 class="service-h3">お申し込み内容</h3>
        <table class="table-type2">
          <tbody>
            <tr>
              <th>付加サービス</th>
              <th>料金</th>
            </tr>
            <tr v-for="(option, index) in optionArrayForDisplay" :key="index">
              <td>{{ option?.name }}</td>
              <td>月額 {{ option?.fee }}円</td>
            </tr>
          </tbody>
        </table>
        <ul>
          <li>お申し込み直後からご利用状況が「ご利用中」と表示されますが、付加サービスが有効となるのはお申し込み日の5営業日後となります。</li>
        </ul>
      </div>
      <div class="sblc">
        <h3 class="service-h3">お支払い方法</h3>
        <div v-if="noDisplayCreditCardForm">
          <p>ご登録のお支払い方法により、お支払いいただきます。</p>
        </div>
        <div v-else>
          <credit-card-component
            v-if="isMounted"
            :key="resetCounter"
            :reset-counter="resetCounter"
            v-bind:apiTokenKey="apiTokenKey"
            v-bind:successMessage="successMessage"
            v-bind:ispName="ispName"
            v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
            v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
            v-bind:portasVeriTransId="portasVeriTransId"
            v-bind:cardHolderNameFromIsp="cardHolderNameFromIsp"
            v-on:onPostCreditCard="getCreditCardToken"
            v-on:onPostCreditCardError="executeCreditCardError"
            v-on:getTokenExpireDate="getTokenExpireDate"
            v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
            v-on:change="isAgreedCopyCardToIsp = $event"
            v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
            v-on:portasCardHolderName="getCardHolderNameFromPortas"
            v-on:cardholderNameFormForAuthorizeIspCard="getCardholderNameFormForAuthorizeIspCard"
            v-on:cardholderNameFormForAuthorizePortasCard="getCardholderNameFormForAuthorizePortasCard"
          />
        </div>
      </div>

      <div class="blc">
        <p v-if="isNeedDisableTrackingBlockFunction" class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
        </p>
        <p class="form-btn-txt mt40">
          ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」ボタンをクリックしてください。
        </p>
        <p class="red form-btn-txt">
          「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
        </p>
      </div>

      <div class="btn-area">
        <div class="has-checkbox">
          <div class="my-checkbox-outer" v-if="!noDisplayCreditCardForm">
            <input id="checkbox-to-agree" type="checkbox" v-model="isAgreedPrivacyPolicy" :disabled="isExpiredPortasCreditCard" />
            <label for="checkbox-to-agree">クレジットカード会社での本人認証のための情報提供に同意してお申し込みを確定する</label>
          </div>
          <button class="btn-height btn btn05 bs sp-margin" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn-height btn btn01 bs sp-margin" type="button" :disabled="isDisabledButton" v-on:click="onApply()">
            お申し込みを確定する<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../../images/main.png');
}
div.my-checkbox-outer {
  margin: 16px;
}
div.my-checkbox-outer > input {
  margin-right: 10px;
}
.sp-margin {
  margin-top: 10px;
}
.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}
/* 申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
button.btn {
  margin-top: 10px;
}

p.red {
  color: #cf1225;
}
b.red {
  color: #cf1225;
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { SpfApiHikariPhoneAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-hikari-phone-accessor';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import {
  EMansionHikariPhoneUpdateRequestOptionalDataDto,
  UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO,
} from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-option-dto';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import { UCOM_HIKARI_PHONE_OPTION_ON_EMANSION } from '@/shared/const/e-mansion';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { ISP_MEMBER_STATUS, UA_TYPE, UaTypeValue } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** Completed コンポーネント */
export default defineComponent({
  name: 'hikari-phone-option-application-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
    CreditCardComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** e-mansion物件情報 */
    eMansionProperty: null as EMansionProperty | null,
    /** オプションサービス 表示用 */
    optionArrayForDisplay: [] as UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO[],
    /** 着信番号表示 */
    option_1: null as null | boolean,
    /** キャッチ通話 */
    option_2: null as null | boolean,
    /** キャッチ通話番号表示 */
    option_3: null as null | boolean,
    /** オプションパック */
    option_4: null as null | boolean,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    isApplyConnectix: false,
    /** 支払い方法の登録状態によってクレジットカードコンポーネントの表示/非表示を切り替える */
    noDisplayCreditCardForm: false,
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** APIトークンキー */
    apiTokenKey: process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION,
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込みを確定する」ボタンをクリックしてください。',
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
    /** ユーザーがe-mansionへカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面ではe-mansion固定) */
    ispName: 'e-mansion',
    /** e-mansionから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** e-mansionから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** e-mansion に登録済のVeriTransAccountId */
    emansionVeriTransAccountId: '',
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: false,
    /** Portasから取得したカード名義人 */
    cardHolderNameOnPortas: '' as string | undefined,
    /** ISPから取得したカード名義人 */
    cardHolderNameFromIsp: '' as string | undefined,
    /** ISPで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizeIspCard: '' as string | undefined,
    /** Portasで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizePortasCard: '' as string | undefined,
    /** 本人認証処理を実行するため、ブラウザのトラッキング防止機能を無効化する必要があるかどうか */
    isNeedDisableTrackingBlockFunction: true,
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    resetCounter: 0,
    /** e-mansion 会員ID */
    eMansionMemberId: '' as string,
    /** 物件ID */
    eMansionPropertyId: '' as string,
    /** e-mansion 物件ID */
    apartmentId: '' as string | undefined,
    /** サービス登録API（UCOM光電話）の返却エラーコード */
    errorCode: '' as string | undefined,
    /** カード登録フラグ 0:登録なし 1:登録あり */
    creditCardRegistFlag: '' as string | undefined,
    /** 番号通知フラグ 0:通知しない 1:通知する */
    notificationFlag: '' as string | undefined,
    /** 一時申込に格納するデータ */
    optionalData: [] as EMansionHikariPhoneUpdateRequestOptionalDataDto[],
    /** e-mantion の URL */
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    apid: '',
  }),
  computed: {
    // チェックボックスによる「申し込む」ボタンの判定
    isDisabledButton(): boolean {
      if (!this.option_1 && !this.option_2 && !this.option_3 && !this.option_4) {
        return false;
      }
      if (this.noDisplayCreditCardForm) {
        return false;
      } else if (
        this.emansionVeriTransAccountId &&
        ((this.cardHolderNameFromIsp && this.isAgreedPrivacyPolicy) ||
          (this.cardholderNameFormForAuthorizeIspCard && this.cardholderNameFormForAuthorizeIspCard.length > 1 && this.isAgreedPrivacyPolicy))
      ) {
        return false;
      } else if (
        !this.isExpiredPortasCreditCard &&
        (this.cardHolderNameOnPortas || this.cardholderNameFormForAuthorizePortasCard) &&
        this.isAgreedCopyCardToIsp &&
        this.isAgreedPrivacyPolicy
      ) {
        return false;
      } else {
        return this.creditCardAccessToken === '' || !this.isAgreedPrivacyPolicy;
      }
    },
  },
  async mounted() {
    this.isLoading = true;

    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    try {
      /** 物件基本情報をStoreから取得 */
      await StoreExternalApiResponse.main();
      this.eMansionProperty = this.$store.getters['eMansionCommonStore/property'];

      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property.uaType;
      const apartmentId: string | undefined = property.apartmentId;

      if (property?.apartmentId) {
        this.apid = convert6DigitIdTo8DigitId(property?.apartmentId);
      }
      // 以降の処理で使うMye会員Idを取得
      this.eMansionMemberId = member.primaryKeyMye ?? '';

      // 必要な物件情報を取得できなかった場合はエラー画面に遷移
      if (!uaType || !apartmentId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      /** 契約基本情報をStoreから取得 */
      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];

      if (customer instanceof EMansionCustomer) {
        // 物件ID
        this.eMansionPropertyId = customer.property_id;
        // 登録している支払方法が以下いずれかの場合、クレジットカード申請フォームを表示しない
        /**
         * 0: 口座振替
         * 1: 振込
         * 4: 口振（請求書）
         */
        const paymentIdsWithoutCreditCard = ['0', '1', '4'];
        if (paymentIdsWithoutCreditCard.some((paymentIdsWithoutCreditCard) => paymentIdsWithoutCreditCard === customer.payment_id)) {
          this.noDisplayCreditCardForm = true;
          this.isNeedDisableTrackingBlockFunction = false;
        }
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      if (customer instanceof EMansionCustomer && customer.payment_id === '8' && member.eTncMemberId) {
        const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
        this.cardExpiredFromIsp = cardInfo.cardExpire;
        this.maskedCardNumberFromIsp = cardInfo.cardNumber;
        this.cardHolderNameFromIsp = cardInfo.cardholderName;
        this.emansionVeriTransAccountId = member.eTncMemberId;
      } else if (member.veritransAccountId) {
        this.portasVeriTransId = member.veritransAccountId;
      }
      // VeriTrans 本人認証失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
        this.creditCardAccessToken = '';
        this.creditTokenExpireDate = '';
      }

      // 各オプションの情報取得
      await this.getCheckedOptionsInfo();

      this.isLoading = false;
      this.isMounted = true;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      this.isMounted = true;
      return;
    }
  },
  methods: {
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: number): string {
      const formatter = new Intl.NumberFormat('en-US');
      const formattedValue = formatter.format(billing);
      return formattedValue;
    },
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      this.isLoading = false;
      this.isSubmitting = false;
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      return;
    },

    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion/hikari-phone/option/manage')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onApply() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      // 一旦エラーメッセージをリセットする
      this.errorMessages = [];

      // クレジットカードトークンがない かつ e-mansionに登録された支払方法が存在しない かつ Portasからカード情報をコピーして支払方法を登録しない場合はエラー
      if (this.creditCardAccessToken === '' && !(this.cardExpiredFromIsp && this.maskedCardNumberFromIsp) && !this.isAgreedCopyCardToIsp && !this.noDisplayCreditCardForm) {
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        // エラーメッセージを見せるために画面最上部にスクロール
        this.showErrorMessage();
        return;
      }
      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      /** 契約基本情報をStoreから取得 */
      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];

      if (customer instanceof EMansionCustomer) {
        // サービス更新APIに進む前のデータ取得
        const optionalData = new EMansionHikariPhoneUpdateRequestOptionalDataDto({
          Hikariphone: {
            notificationFlag: '',
            numberDisplay: this.option_1 ? '1' : '',
            catch: this.option_2 ? '1' : '',
            catchNumberDisplay: this.option_3 ? '1' : '',
            optionPack: this.option_4 ? '1' : '',
          },
        });
        const servicePlanType = await this.$store.dispatch('servicePlanTypeStore/servicePlanType');

        // 支払方法が登録されている場合
        if (this.noDisplayCreditCardForm) {
          try {
            // サービス更新API(UCOM光電話) を呼び出す
            const updateHikariPhoneInfo = await SpfApiHikariPhoneAccessor.updateHikariPhone(this.eMansionMemberId, this.eMansionPropertyId, servicePlanType, optionalData, '0');
            this.$store.commit('eMansionUcomHikariPhoneOptionEntriedInfoStore/info', updateHikariPhoneInfo);
          } catch (error: any) {
            this.$store.commit('eMansionUcomHikariPhoneOptionEntriedInfoStore/info', null);
            // 上記以外のエラーの場合はe-mansion共通エラー画面に遷移
            await VueErrorHandler.handle(error, '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }

          await this.$router
            .push('/e-mansion/hikari-phone/option/application-completed')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              checkRouterError(error);
            })
            .finally(() => {
              this.isSubmitting = false;
            });
          return;
          // クレジットカードが e-mansion に登録されている場合の申し込み処理
        } else if (this.emansionVeriTransAccountId) {
          // 申込内容一時保存処理
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            veriTransAccountId: this.emansionVeriTransAccountId,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            optionalData: optionalData,
            creditCardRegistFlag: '0',
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-hikari-phone-update',
          });
          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          let cardHolderNameForAuthorize: string;
          if (this.cardHolderNameFromIsp) {
            cardHolderNameForAuthorize = this.cardHolderNameFromIsp;
          } else {
            cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
          }
          const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
            uuid: uuidForTemporarySavingApplicationData,
            externalVeritransAccountId: this.emansionVeriTransAccountId,
            cardholderName: cardHolderNameForAuthorize,
          });
          // VeriTrans_本人認証(VeriTrans会員ID使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              this.isSubmitting = false;
              // エラーメッセージを見せるために画面最上部にスクロール
              window.scrollTo(0, 0);
              return;
            } else {
              throw error;
            }
          }
        } else if (
          // PortasからISPにカード情報をコピーする場合
          this.isAgreedCopyCardToIsp &&
          this.portasVeriTransId
        ) {
          // 申込内容一時保存処理
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            veriTransAccountId: this.portasVeriTransId,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            optionalData: optionalData,
            creditCardRegistFlag: '1',
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-hikari-phone-update',
          });
          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          let cardHolderNameForAuthorize: string;
          if (this.cardHolderNameOnPortas) {
            cardHolderNameForAuthorize = this.cardHolderNameOnPortas;
          } else {
            cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizePortasCard!;
          }
          const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
            uuid: uuidForTemporarySavingApplicationData,
            externalVeritransAccountId: this.portasVeriTransId,
            cardholderName: cardHolderNameForAuthorize,
          });
          // VeriTrans_本人認証(VeriTrans会員ID使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              this.isSubmitting = false;
              // エラーメッセージを見せるために画面最上部にスクロール
              window.scrollTo(0, 0);
              return;
            } else {
              throw error;
            }
          }
          return;
        } else {
          /**Portas,e-mansionにカードが未登録の場合 */
          // 申込内容一時保存処理
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            creditCardToken: this.creditCardAccessToken,
            tokenExpireDate: this.creditTokenExpireDate,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            optionalData: optionalData,
            creditCardRegistFlag: '1',
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-hikari-phone-update',
          });
          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          // ページ遷移時に beforeEach で会員情報取得できるように null にする
          this.$store.commit('memberStore/member', null);
          const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
            uuid: uuidForTemporarySavingApplicationData,
            creditCardToken: this.cardTokenFor3dSecureAuthorize,
            serviceProviderIsp: SERVICE_PROVIDER.MYE,
          });
          // VeriTrans_本人認証(トークン使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              this.isSubmitting = false;
              // エラーメッセージを見せるために画面最上部にスクロール
              window.scrollTo(0, 0);
              return;
            } else {
              throw error;
            }
          }
        }
      }
    },
    /** 各オプションの情報取得 */
    async getCheckedOptionsInfo() {
      // 前画面で選択した申し込みオプションのストアの値を取得
      this.option_1 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_1'];
      this.option_2 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_2'];
      this.option_3 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_3'];
      this.option_4 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_4'];

      if (this.option_1) {
        // 着信番号表示
        this.optionArrayForDisplay.push(
          new UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO({
            name: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.NUMBER_DISPLAY.name,
            fee: this.formatMoney(Number(this.eMansionProperty?.op.hikariphone.numberdisplay_fee.replace(/,/, ''))),
          })
        );
      }

      if (this.option_2) {
        // キャッチ通話
        this.optionArrayForDisplay?.push(
          new UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO({
            name: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH.name,
            fee: this.formatMoney(Number(this.eMansionProperty?.op.hikariphone.catch_fee.replace(/,/, ''))),
          })
        );
      }

      if (this.option_3) {
        // 着信番号表示
        this.optionArrayForDisplay?.push(
          new UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO({
            name: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH_NUMBER_DISPLAY.name,
            fee: this.formatMoney(Number(this.eMansionProperty?.op.hikariphone.catch_display_fee.replace(/,/, ''))),
          })
        );
      }

      if (this.option_4) {
        // キャッチ通話
        this.optionArrayForDisplay?.push(
          new UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO({
            name: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.OPTION_PACK.name,
            fee: this.formatMoney(Number(this.eMansionProperty?.op.hikariphone.servicepack_fee.replace(/,/, ''))),
          })
        );
      }
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: this.eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });
      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** 3Dセキュア本人認証用カード名義人を受け取る */
    getCardHolderNameFromPortas(cardHolderName: string) {
      this.cardHolderNameOnPortas = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、ISP登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizeIspCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizeIspCard = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、Portas登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizePortasCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizePortasCard = cardHolderName;
    },
  },
});
</script>
