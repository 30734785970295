<template>
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ウイルスチェックあんしんサービス plus</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>ウイルスチェックあんしんサービス Plus</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid4 gap10">
        <li>ご利用状況</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:ウイルスチェックあんしんサービス plus 確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="ウイルスチェックあんしんサービス plus" />お申し込み確認</h2>

        <error-messages-component
          v-if="errorMessages"
          v-bind:errorMessageTitle="errorMessageTitle"
          v-bind:errorMessages="errorMessages"
          :isOneSentence="errorMessages.length == 1"
        />

        <error-messages-component v-if="errorMessagesFor3d" v-bind:errorMessages="errorMessagesFor3d" v-bind:errorMessageTitle="errorMessageTitle" />

        <!-- blc:お支払方法未登録 -->
        <div v-if="getPaymentInfoFor3d?.status === '0'" class="err">
          <p class="red portal-h3">お申し込みの前に、お支払情報のご登録が必要です。</p>
          <ul class="ul-basic01">
            <li>e-mansion会員専用トップ「クレジットカード情報登録変更メニュー」からご登録ください。</li>
          </ul>
        </div>
        <!-- blc:お支払方法未登録 -->

        <div class="sblc">
          <div class="sblc">
            <p>お申し込み内容をご確認ください。</p>
            <h3 class="service-h3">お申し込み内容</h3>
            <p>「ウイルスチェックあんしんサービス plus」をお申し込みいただくメールアドレスをご確認ください。</p>
            <p class="caution">追加メールアドレスにウイルスチェックをお申し込みいただく場合は、必ず追加アカウントでPortas連携を行ってください。</p>
            <table class="table-type2">
              <tbody>
                <tr>
                  <th>お申し込み対象<br />メールアドレス</th>
                  <td>
                    <b>{{ mailAddress }}</b>
                  </td>
                </tr>
                <tr>
                  <th>月額利用料</th>
                  <td>
                    <b>{{ feeInfo.fee }}円</b>
                    <span v-if="feeInfo.feeCp >= 0">
                      ⇒ <b class="font16px">{{ feeInfo.feeCp }}円キャンペーン中！</b>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="va-middle">サービス規約への同意<span class="req">必須</span></th>
                  <td>
                    <p class="o"><a href="https://www.em-net.ne.jp/pdf/rlp01/vcplus.pdf" class="link" target="_blank">ウイルスチェックあんしんサービスplus規約</a></p>
                    <p class="o">お申し込みの前にサービス規約を必ずお読みになり、「サービス規約の内容に同意する」にチェックしてください。</p>
                    <label> <input type="checkbox" v-model="isAgreed" :disabled="getPaymentInfoFor3d?.status === '0'" />サービス規約の内容に同意する </label>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="feeInfo.fee > 0 && getPaymentInfoFor3d?.status === '1'">
              <p class="red">ご登録のお支払い方法により、お支払いいただきます。</p>
              <p>※価格はすべて税込み表示（消費税{{ $data.tax }}%）です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
            </div>

            <h3 v-if="is3dsecure" class="service-h3">e-mansionサービス お支払い方法</h3>
            <div v-if="is3dsecure">
              <div class="pd10">
                <p>e-mansionにご登録されている以下のお支払い方法により、お支払いいただきます。</p>
                <h3>クレジットカード</h3>
                <table v-if="is3dsecure" class="table-type2">
                  <tbody>
                    <tr>
                      <th class="va-middle">カード番号</th>
                      <td>{{ maskedCardNumberFromIsp }}</td>
                    </tr>
                    <tr>
                      <th class="va-middle">カード期限</th>
                      <td>{{ cardExpiredMonthOnPortas }}月 ／ {{ cardExpiredYearOnPortas }}年</td>
                    </tr>
                    <tr v-if="!isCardholderName">
                      <td>
                        <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
                      </td>
                      <td>
                        <div class="mt10">
                          <input
                            type="text"
                            class="credit-card-text"
                            v-model.trim="cardholderNameFormForAuthorizeIspCard"
                            required
                            minlength="2"
                            maxlength="45"
                            @change="inputCardholderNameFormForAuthorizeIspCard"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="is3dsecure" class="sblc">
        <p class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
        </p>
      </div>

      <div>
        <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt">ご確認の上、よろしければ「申し込む」をクリックしてください。</p>
      </div>
      <div class="btn-area" :class="{ pt10: !is3dsecure }">
        <div class="my-checkbox-margin">
          <button class="btn btn04 bs" @click="goBack"><i class="material-icons link link-icon">west</i>戻る</button>
        </div>
        <div class="has-checkbox">
          <button type="button" class="btn btn01 bs" :class="{ oldddisabled: isDisabledButton }" v-on:click="applyVirusCheck" :disabled="isDisabledButton">
            申し込む<i class="material-icons link link-icon">east</i>
          </button>
          <div v-if="is3dsecure" class="my-checkbox-outer">
            <input id="isAgreedPrivacyPolicy" v-model="isAgreedPrivacyPolicy" type="checkbox" name="isAgreedPrivacyPolicy" v-on:click="turnOffAgree3dSecureAuthorize" />
            <p class="my-checkbox-text"><label for="isAgreedPrivacyPolicy"> 各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { SpfApiPaymentStatusAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-payment-status-accessor';
import { SpfApiVirusCheckAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-virus-check-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { VirusCheckFee } from '@/shared/classes/spf-api/e-mansion-mail';
import { EMansionPaymentStatusInfo } from '@/shared/classes/spf-api/mail/model/common/e-mansion-payment-status';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { E_MANSION_PAYMENT_STATUS } from '@/shared/const/e-mansion/e-mansion-payment-status';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { inputEventConvertToUpper } from '@/shared/util/inputEventConvertToUpper';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'virus-check-apply-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },

  data(): {
    isBusy: boolean;
    isAgreed: boolean;
    errorMessageTitle: string;
    errorMessages: string[];
    errorMessagesFor3d: string[];
    tax: string;
    ispMemberStatusEMansion: string;

    /** VeriTrans会員IDが取得できた場合は値を登録する */
    externalVeriTransId: string;
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: string;
    /** 本人認証処理 個人情報提供を許可するかどうか */
    isAgreedPrivacyPolicy: boolean;
    /** 本人認証用カード保有者名 */
    cardholderNameAtCopy: string;

    memberId: number;
    mailAddressId: number;
    applyDate: string;

    /** 連携先ISPで登録されたクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: string;
    /** Portasで登録されたクレジットカードの有効期限(月) */
    cardExpiredMonthOnPortas: string;
    /** Portasで登録されたクレジットカードの有効期限(年) */
    cardExpiredYearOnPortas: string;
    isCardholderName: boolean;
    is3dsecure: boolean;

    canApplyErrorCheck: boolean;
    canApply: boolean;

    cardholderName: string;
    member: Member | null;
    getPaymentInfoFor3d: EMansionPaymentStatusInfo | null;
    CANT_APPLY_MESSAGE: string;
    cardholderNameFormForAuthorizeIspCard: string;
    eMansionUrl: string;
    apid: string;
  } {
    return {
      isBusy: true,
      isAgreed: false,
      errorMessageTitle: '',
      errorMessages: [],
      errorMessagesFor3d: [],
      tax: '',
      ispMemberStatusEMansion: '',

      externalVeriTransId: '',
      cardTokenFor3dSecureAuthorize: '',
      isAgreedPrivacyPolicy: false,
      cardholderNameAtCopy: '',

      memberId: 0,
      mailAddressId: 0,
      applyDate: dayjs(new Date()).format('YYYY/MM/DD'),

      maskedCardNumberFromIsp: '',
      cardExpiredMonthOnPortas: '',
      cardExpiredYearOnPortas: '',
      isCardholderName: false,
      is3dsecure: false,
      cardholderName: '',
      canApplyErrorCheck: false,
      canApply: false,

      member: null as Member | null,
      getPaymentInfoFor3d: null as EMansionPaymentStatusInfo | null,
      CANT_APPLY_MESSAGE: 'お支払情報のご登録が必要です',
      cardholderNameFormForAuthorizeIspCard: '',
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      apid: '',
    };
  },

  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
    if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
      this.errorMessagesFor3d = this.$store.getters['errorMessageStore/errorMessages'];
      this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
      //ストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);
    }

    // 会員情報をStoreから取得
    this.member = this.$store.getters['memberStore/member'];
    this.getPaymentInfoFor3d = await SpfApiPaymentStatusAccessor.getPaymentStatus(this.member?.id ?? -1);

    try {
      // お支払い情報登録状態を取得
      if (!this.getPaymentInfoFor3d) {
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      // お支払い情報登録状態チェック
      await this.checkPaymentInfo(this.getPaymentInfoFor3d.status);
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    this.tax = this.$store.getters['taxStore/tax'];
    this.isBusy = false;
  },
  methods: {
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      if (property?.apartmentId) {
        this.apid = convert6DigitIdTo8DigitId(property?.apartmentId);
      }

      return false;
    },

    inputCardholderNameFormForAuthorizeIspCard(event: Event) {
      this.cardholderNameFormForAuthorizeIspCard = inputEventConvertToUpper(event);
    },

    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },

    /**
     * 「申し込む」のクリックハンドラ
     */
    async applyVirusCheck(): Promise<void> {
      const memberId = this.$store.getters['memberStore/member'].id;
      this.memberId = Number(memberId); //

      this.applyDate = dayjs(new Date()).format('YYYY/MM/DD');
      this.mailAddressId = this.$store.getters['eMansionMailStore/selectedMailAddressId'];

      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      this.errorMessagesFor3d = [];
      this.errorMessageTitle = '';

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 「サービス規約の内容に同意する」がチェックされていない場合、エラー表示
      if (!this.isAgreed || (this.is3dsecure && !this.isAgreedPrivacyPolicy)) {
        this.errorMessages = ['お申し込みの前にサービス規約を必ずお読みになり、「サービス規約の内容に同意する」にチェックしてください。'];
        this.isBusy = false;
        window.scrollTo(0, 0);
        return;
      }

      const paymentMethodApplicationData = {
        emailAddressId: this.mailAddressId,
        id: this.memberId,
        applyDate: this.applyDate,
        feeInfo: this.feeInfo,
        mailAddress: this.mailAddress,
        tax: this.tax,
      };

      if (this.is3dsecure) {
        if (
          !this.isCardholderName &&
          (!this.cardholderNameFormForAuthorizeIspCard || this.cardholderNameFormForAuthorizeIspCard?.length < 2 || this.cardholderNameFormForAuthorizeIspCard?.length > 45)
        ) {
          this.errorMessagesFor3d.push('カード名義人を正しく入力してください。');
          this.isBusy = false;
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }

        // 申込内容一時保存用 UUIDを生成
        const uuidForTemporarySavingApplicationData = uuidv4();

        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'e-mansion-virus-apply',
        });

        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
        }

        // 3dセキュア対応用 uuid localStorageに保存
        localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
        const member: Member = this.$store.getters['memberStore/member'];

        // 申込内容一時保存用uuidを取得
        const getUuidForTemporarySavingApplicationData = localStorage.getItem('uuidForTemporarySavingApplicationData');

        let cardHolderNameForAuthorize: string;
        if (this.cardholderName) {
          cardHolderNameForAuthorize = this.cardholderName;
        } else {
          cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
        }

        const dddSecureAuthStartWithVeriTransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
          uuid: getUuidForTemporarySavingApplicationData!,
          externalVeritransAccountId: member.eTncMemberId,
          cardholderName: cardHolderNameForAuthorize,
        });

        try {
          // VeriTrans_本人認証(VeriTrans ID)API の実行
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeriTransAccountIdRequest);

          // VeriTrans会員IDの登録状況を更新するため、会員ストアの内容を初期化
          this.$store.commit('memberStore/member', null);
          this.cardTokenFor3dSecureAuthorize = '';

          // エラーメッセージ格納配列初期化
          this.errorMessagesFor3d = [];

          location.href = authStartURL;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(VeriTrans ID)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessagesFor3d.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
            );

            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

            //チェックボックス非表示＆チェックを外す
            this.isAgreedPrivacyPolicy = false;
            this.isAgreed = false;
            this.isBusy = false;

            // エラーメッセージを見せるために画面最上部にスクロール
            window.scrollTo(0, 0);

            return;
          } else {
            await this.$router.push('/e-mansion/error').catch((error: any) => {
              checkRouterError(error);
            });
            return;
          }
        }
      } else {
        try {
          // ウィルスチェック申込更新 API を呼び出す
          const eMansionVirusCheckUpdateResponseDto = await SpfApiVirusCheckAccessor.updateVirusCheck(this.mailAddressId, this.memberId, this.applyDate);

          // レスポンスの message に値があればエラーを表示して終了する
          if (eMansionVirusCheckUpdateResponseDto.message) {
            this.errorMessages = [eMansionVirusCheckUpdateResponseDto.message];
            this.isAgreed = false;
            this.isBusy = false;
            window.scrollTo(0, 0);
            return;
          }

          // メールアドレスを Store に保存する
          this.$store.commit('eMansionMailStore/setSelectedMailAddress', this.mailAddress);
          // 完了 (申込) 画面に遷移する
          await this.$router.push('/e-mansion/mail/virus-check-apply-complete').catch((error) => {
            checkRouterError(error);
          });
        } catch (error) {
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
        }
      }
    },

    /** 本人認証取得処理、同意 */
    turnOffAgree3dSecureAuthorize() {
      this.isAgreedPrivacyPolicy = true;
    },

    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },

    /**
     * 「戻る」のクリックハンドラ
     */
    async goBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      await this.$router.push('/e-mansion/mail/usage').catch((error) => {
        checkRouterError(error);
      });

      this.isBusy = false;
    },

    async checkPaymentInfo(paymentInfoStatus: E_MANSION_PAYMENT_STATUS): Promise<boolean> {
      // お支払情報があった場合の処理
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];
      if (!member || !member.primaryKeyMye) {
        return true;
      }
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });
      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      // 支払方法がクレジットカードである場合
      if (eMansionCustomer instanceof EMansionCustomer && eMansionCustomer.payment_id === '8') {
        this.canApplyErrorCheck = true;
        this.is3dsecure = true;

        /**
         * ISP(e-mansion)会員でVeriTransカード情報取得
         */
        if (member.eTncMemberId) {
          // 正しい呼び出し
          const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
          this.maskedCardNumberFromIsp = this.addHyphenToCardNumber(cardInfo.cardNumber);
          this.cardholderName = cardInfo.cardholderName ?? '';
          // クレジットカードの年月取得
          this.cardExpiredMonthOnPortas = cardInfo.cardExpire.slice(0, 2);
          this.cardExpiredYearOnPortas = cardInfo.cardExpire.slice(-2);

          // カード名義人判定
          if (cardInfo.cardholderName) {
            this.isCardholderName = true;
          }
        }
      } else {
        //支払方法がクレジットカードでない場合
        this.canApply = paymentInfoStatus == E_MANSION_PAYMENT_STATUS.REGISTERED;
        if (this.canApply) {
          this.canApplyErrorCheck = true;
        }
      }

      // お支払情報登録有無の保持
      this.$store.commit('eMansionHomepageCapacityStore/canApply', this.canApply);

      return false;
    },
  }, // methods

  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return this.isBusy;
    }, // isLoading

    /** 処理対象のメールアドレスID */
    mailAddressId(): number {
      const mailAddressId = this.$store.getters['eMansionMailStore/selectedMailAddressId'];

      if (!mailAddressId) {
        throw new Error();
      }

      return mailAddressId;
    },

    /** 処理対象のメールアドレス */
    mailAddress(): string {
      const mailAddress = this.$store.getters['eMansionMailStore/selectedMailAddress'];

      if (!mailAddress) {
        throw new Error();
      }

      return mailAddress;
    },

    /** 処理対象の利用料情報 */
    feeInfo(): VirusCheckFee {
      const feeInfo = this.$store.getters['eMansionMailStore/selectedFeeInfo'];
      return feeInfo;
    },

    isDisabledButton(): boolean {
      if (this.is3dsecure) {
        return !(this.isAgreed && this.isAgreedPrivacyPolicy);
      } else {
        return !this.isAgreed;
      }
    },
  }, // computed
  watch: {
    cardholderNameFormForAuthorizeIspCard: {
      handler: function (newVal, oldVal) {
        if (newVal && !newVal.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.cardholderNameFormForAuthorizeIspCard = oldVal;
        }
      },
    },
  },
});
</script>

<style lang="scss" scoped>
/* 非活性のボタンがマウスオーバーに反応しないように */
button.btn05:hover {
  opacity: 1;
}

.caution {
  color: #cf1225;
}

/* my-cautionary-statement-checkbox
---------------------------------------------------- */
.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

.credit-card-text {
  width: 100%;
  height: 38px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 10px 0 10px;
  border: #cacaca solid 1px;
  margin-top: 0;
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  font-family: 'notosansjp';
  border-radius: 2px;
  max-width: 360px;
}

.oldddisabled {
  background-color: #ed7d89;
}

div.btn-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  text-align: center;

  & div.has-checkbox {
    padding-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 8px;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .my-checkbox-margin {
    margin-top: 24px;
  }
}

@media only screen and (min-width: 768px) {
  div.btn-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 64px;

    & div.has-checkbox {
      padding-top: 0;

      & div.my-checkbox-outer {
        top: 0;
        left: 50%;
        text-align: left;

        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1425px) {
  div.btn-area {
    padding-top: 32px;
  }
}
</style>
