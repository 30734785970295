import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionHikariPhoneUpdateResponseDto, EMansionHikariPhoneUpdateRequestOptionalDataDto } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-option-dto';
import {
  EMansionHikariPhoneRegisterRequestOptionalDataDto,
  EMansionHikariPhoneRegisterResponseDto,
} from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-register-dto';
import { BaseApiService } from '@/shared/services/api/base-api-service';

const api = BaseApiService.api();

/** 内部 API をコールするサービス */
export class SpfApiHikariPhoneAccessor {
  /**
   * e-mansion UCOM光電話　サービス更新API
   * @return e-mansion 会員ID, apartmentId, servicePlanType, optionalData, creditCardregistFlag
   */
  public static async updateHikariPhone(
    primaryKeyMye: string,
    apartmentId: string,
    servicePlanType: string,
    optionalData: EMansionHikariPhoneUpdateRequestOptionalDataDto,
    creditCardRegistFlag: string
  ): Promise<EMansionHikariPhoneUpdateResponseDto> {
    const response = await api
      .patch<EMansionHikariPhoneUpdateResponseDto>(`/hikari-phone/e-mansion/henkou`, {
        primaryKeyMye,
        apartmentId,
        optionalData,
        servicePlanType,
        creditCardRegistFlag,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return response.data;
  } // updateHikariPhone

  /**
   * e-mansion UCOM光電話　サービス登録API
   * @return e-mansion 会員ID（primaryKeyMye）, apartmentId, optionalData, servicePlanType, creditCardregistFlag
   */
  public static async registerHikariPhone(
    primaryKeyMye: string,
    apartmentId: string,
    servicePlanType: string,
    optionalData: EMansionHikariPhoneRegisterRequestOptionalDataDto,
    creditCardRegistFlag: string
  ): Promise<EMansionHikariPhoneRegisterResponseDto> {
    const response = await api
      .post<EMansionHikariPhoneRegisterResponseDto>(`/hikari-phone/e-mansion/touroku`, {
        primaryKeyMye,
        apartmentId,
        optionalData,
        servicePlanType,
        creditCardRegistFlag,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return response.data;
  } // registerHikariPhone

  /**
   * e-mansion UCOM光電話 付加オプション解約API呼び出し（e-mansionサービス更新API実行、解約受付メール送信）
   * @param primaryKeyMye
   * @param apartmentId
   * @param servicePlanType
   * @param optionalData  : *cation* UCOM光電話 オプション申込時のパラメータと同じデータクラスを利用
   * @param creditCardRegistFlag
   * @param cancelOptionalNumber
   * @returns
   */
  public static async cancelHikariPhoneOption(
    primaryKeyMye: string,
    apartmentId: string,
    servicePlanType: string,
    optionalData: EMansionHikariPhoneUpdateRequestOptionalDataDto,
    creditCardRegistFlag: number,
    cancelOptionalNumber: number
  ): Promise<EMansionHikariPhoneUpdateResponseDto> {
    const response = await api
      .patch<EMansionHikariPhoneUpdateResponseDto>(`/hikari-phone/e-mansion/option-kaiyaku`, {
        primaryKeyMye,
        apartmentId,
        optionalData,
        servicePlanType,
        creditCardRegistFlag,
        cancelOptionalNumber,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return response.data;
  } // cancelHikariPhoneOption

  /**
   * e-mansion UCOM光電話 番号通知設定変更API呼び出し（e-mansionサービス更新API実行、UCOM光電話（番号通知）変更メール送信）
   * @return e-mansion 会員ID, apartmentId, servicePlanType, optionalData, creditCardregistFlag
   */
  public static async numberNotificationHikariPhone(
    primaryKeyMye: string,
    apartmentId: string,
    servicePlanType: string,
    optionalData: EMansionHikariPhoneUpdateRequestOptionalDataDto,
    creditCardRegistFlag: string
  ): Promise<EMansionHikariPhoneUpdateResponseDto> {
    const response = await api
      .patch<EMansionHikariPhoneUpdateResponseDto>(`/hikari-phone/e-mansion/number-notification-change`, {
        primaryKeyMye,
        apartmentId,
        optionalData,
        servicePlanType,
        creditCardRegistFlag,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return response.data;
  } // updateHikariPhone
}
