// e-mansion UCOM光電話用DTO

/** 契約基本情報取得API.オプション利用状況.UCOM光電話 DTO*/
export class UcomHikariPhoneOnEmansionDTO {
  /** 利用状況 */
  public status!: string | undefined;
  /** 申込日 */
  public request_time!: string | undefined;
  /** 利用開始日 */
  public start_time?: string | undefined;
  /** 解約申込日 */
  public cancel_time?: string | undefined;
  /** 解約日 */
  public end_time?: string | undefined;
  /** ログインID */
  public web_login_id?: string | undefined;
  /** パスワード */
  public web_login_password?: string | undefined;
  /** 番号通知 */
  public notification_flag?: string | undefined;
  /** 付加サービス */
  public hikariphone_option?: UcomHikariPhoneOptionOnEmansionDTO[] | undefined;

  constructor(partial: Partial<UcomHikariPhoneOptionOnEmansionDTO>) {
    /** 契約基本情報取得API.オプション利用状況.UCOM光電話.付加サービス */ //WIP:後で修正、現在は仕様確定まで仮で値を入れている
    Object.assign(this, partial);
  }
}

/** 契約基本情報取得API.オプション利用状況.UCOM光電話.付加サービス　DTO*/
export class UcomHikariPhoneOptionOnEmansionDTO {
  /** 付加サービス名 */
  public name!: string | undefined;
  /** 利用状況 */
  public status!: string | undefined;
  /** 申込日 */
  public request_time!: string | undefined;
  /** 利用開始日 */
  public start_time?: string | undefined;
  /** 解約申込日 */
  public cancel_time?: string | undefined;
  /** 解約日 */
  public end_time?: string | undefined;
  constructor(partial: Partial<UcomHikariPhoneOptionOnEmansionDTO>) {
    Object.assign(this, partial);
  }
}

/** ご利用状況表示用　 契約基本情報取得API.オプション利用状況.UCOM光電話.付加サービス　DTO*/
export class UcomHikariPhoneOptionOnEmansionForDisplayDTO {
  /** 付加サービス名 */
  public name!: string;
  /** 利用状況 */
  public status!: string;
  /** 表示順 */
  public order_display!: number;
  constructor(partial: Partial<UcomHikariPhoneOptionOnEmansionForDisplayDTO>) {
    Object.assign(this, partial);
  }
}

/** オプションお申し込み/解約表示用　DTO*/
export class UcomHikariPhoneOptionForDisplayOnManageDTO {
  /** 付加サービス名 */
  public name!: string;
  /** 付加サービス名 */
  public fee!: string;
  /** 利用状況 */
  public status!: string;
  /** 識別番号 */
  public optionNumber!: number;

  constructor(partial: Partial<UcomHikariPhoneOptionForDisplayOnManageDTO>) {
    Object.assign(this, partial);
  }
}

/** オプションお申し込み確認表示用　DTO*/
export class UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO {
  /** 付加サービス名 */
  public name!: string;
  /** 付加サービス名 */
  public fee!: string;

  constructor(partial: Partial<UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO>) {
    Object.assign(this, partial);
  }
}

/** e-mansion UCOM光電話 サービス変更API リクエスト 個別パラメータ DTO */
export class EMansionHikariPhoneUpdateRequestOptionalDataDto {
  readonly Hikariphone: HikariphoneRequest | undefined;

  constructor(partial: Partial<EMansionHikariPhoneUpdateRequestOptionalDataDto>) {
    Object.assign(this, partial);
  }
}

/** e-mansion UCOM光電話 サービス変更API リクエスト 個別パラメータ DTO */
export class HikariphoneRequest {
  readonly notificationFlag: string | undefined;
  readonly numberDisplay: string | undefined;
  readonly catch: string | undefined;
  readonly catchNumberDisplay: string | undefined;
  readonly optionPack: string | undefined;

  constructor(partial: Partial<HikariphoneRequest>) {
    Object.assign(this, partial);
  }
}

/** e-mansion_UCOM光電話 サービス更新のレスポンスDTO */
export class EMansionHikariPhoneUpdateResponseDto {
  // 処理結果 処理成功：success, 処理失敗:fail
  readonly status: string | undefined;
  // 対象の会員ID
  readonly member_id: string | undefined;
  // 登録したサービス
  readonly service_name: string | undefined;
  // オプションサービスID
  readonly option_service_id: string | undefined;
  // 受付日時(YYYY/MM/DD hh:mm:ss)
  readonly receive_time: string | undefined;
  // 個別パラメータ
  readonly optional_data: OptionalData | undefined;
  // エラーメッセージ (処理ステータス: fail のときのみ存在する)
  readonly message: string | undefined;
  // エラーコード (処理ステータス: fail のときのみ存在する)
  readonly code: string | undefined;

  constructor(partial: Partial<EMansionHikariPhoneUpdateResponseDto>) {
    Object.assign(this, partial);
  }
}

/** e-mansion_UCOM光電話 サービス更新 個別パラメータのレスポンスDTO */
export class OptionalData {
  // サービスがHikariphoneの場合のみ必須
  readonly Hikariphone: HikariPhone | undefined;

  constructor(partial: Partial<OptionalData>) {
    Object.assign(this, partial);
  }
}

/** e-mansion_UCOM光電話 サービス更新 個別パラメータ UCOM光電話のレスポンスDTO */
export class HikariPhone {
  // 課金情報
  readonly billing_data: BillingData | undefined;

  constructor(partial: Partial<HikariPhone>) {
    Object.assign(this, partial);
  }
}

/** e-mansion_UCOM光電話 サービス更新 個別パラメータ UCOM光電話　課金情報のレスポンスDTO */
export class BillingData {
  // 着信番号表示料金 オプション選択時必須
  readonly numberdisplay_fee: string | undefined;
  // キャッチ通話料金 オプション選択時必須
  readonly catch_fee: string | undefined;
  // キャッチ通話番号表示料金 オプション選択時必須
  readonly catch_display_fee: string | undefined;
  // オプションサービスパック料金 オプション選択時必須
  readonly servicepack_fee: string | undefined;

  constructor(partial: Partial<BillingData>) {
    Object.assign(this, partial);
  }
}
