import { Module } from 'vuex';
import { RootState } from '../index';

/** e-mansion UCOM光電話 オプション解約 State */
const eMansionUcomHikariPhoneOptionCancelState: {
  optionNumber: number | undefined;
} = {
  optionNumber: undefined,
};

/** State の型 */
type eMansionUcomHikariPhoneOptionCancelState = typeof eMansionUcomHikariPhoneOptionCancelState;

/** e-mansion UCOM光電話 オプション解約 Store */
export const eMansionUcomHikariPhoneOptionCancelStore: Module<eMansionUcomHikariPhoneOptionCancelState, RootState> = {
  namespaced: true,
  state: eMansionUcomHikariPhoneOptionCancelState,
  actions: {},
  mutations: {
    optionNumber: (state, value: number) => (state.optionNumber = value),
  },
  getters: {
    optionNumber: (state): number | undefined => state.optionNumber,
  },
};
