<template>
  <div class="e-mansion-ip-phone-completed">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion IPフォン</h1>
    </main>

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link>></li>
        <li>e-mansion IPフォン</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansion IPフォン お申し込み受付完了" />e-mansion IPフォン お申し込み受付完了</h2>
        <p>e-mansion IPフォンのお申し込みありがとうございます。</p>
        <div class="sblc">
          <p>お客様のBoostphoneIDとパスワードはこちらです。</p>
          <table class="table-type2">
            <tbody>
              <tr>
                <th>BoostPhoneID</th>
                <td>{{ displayBoostPhoneID }}</td>
              </tr>
              <tr>
                <th>BoostPhoneパスワード</th>
                <td>{{ displayBoostPhonePassword }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- sblc 営業日 -->
        <div class="sblc">
          <p class="att">※上記IDとパスワードは、第三者に悪用されないよう、ご自身で厳重に管理してください。</p>
          <p class="att">※IPフォン BoostphoneID とパスワードは、e-mansion会員専用トップページのIPフォン「確認·解約」からご確認いただけます。</p>
          <ul>
            <li>ご指定の日時にテレフォニーアダプターをお届けします。</li>
            <li>050で始まるIPフォン番号は、<span class="red">テレフォニーアダプター到着後</span>、お客様自身にて「BoostPhoneサイト」にログインして取得してください。</li>
          </ul>
          <p class="att">
            ※テレフォニーアダプターのお届け日時を変更されたい場合は、ご指定のお届け日時の「5営業日前」までに、e-mansion会員専用トップページのIPフォン「解約・確認」からお客様自身にてご変更ください。お届け日時が休日の場合は、ご指定のお届け日時の「5営業日前の前日」までは変更が可能です。
          </p>
          <p class="red">※テレフォニーアダプターはレンタル品のため、お客様の故意または過失による故障や破損の場合、端末機器損害金20,900円（課税対象外）を請求いたします。</p>

          <!-- sblc 営業日 -->
          <ul>
            <li>土曜日</li>
            <li>日曜日</li>
            <li>国民の祝日および国民の休日</li>
            <li>年末年始(12月29日～1月3日)</li>
          </ul>
          <p>上記以外の平日が、e-mansionの営業日となります。</p>
        </div>
      </div>

      <!-- sblc 申込内容（料金表示） -->
      <div class="sblc">
        <h3 class="service-h3">ご契約内容のご案内</h3>

        <table class="table-type2">
          <tbody>
            <tr>
              <th>事務手数料（初回のみ）</th>
              <td>
                {{ displayJimuFee }}&nbsp;円
                <span v-if="displayJimuFeeCp">
                  &nbsp;（キャンペーン適用時：&nbsp;<b class="red"> {{ displayJimuFeeCp }} </b>&nbsp;円）
                </span>
              </td>
            </tr>
            <tr>
              <th>基本料（月額）</th>
              <td>
                {{ displayBasicFee }}&nbsp;円
                <span v-if="displayBasicFeeCp">
                  &nbsp;（キャンペーン適用時：&nbsp;<b class="red"> {{ displayBasicFeeCp }} </b>&nbsp;円）
                </span>
              </td>
            </tr>
            <tr>
              <th>テレフォニーアタプターレンタル料（月額）</th>
              <td>
                {{ displayRentalCharge }}&nbsp;円
                <span v-if="displayRentalChargeCp">
                  &nbsp;（キャンペーン適用時：&nbsp;<b class="red"> {{ displayRentalChargeCp }} </b>&nbsp;円）
                </span>
              </td>
            </tr>
            <tr>
              <th><b>ユニバーサルサービス料（月額）</b></th>
              <td>
                {{ displayUniChargeTaxInc }}&nbsp;円
                <span v-if="displayUniChargeCp">
                  &nbsp;（キャンペーン適用時：&nbsp;<b class="red"> {{ displayUniChargeCp }} </b>&nbsp;円）
                </span>
                <br />
                <span class="att">※ユニバーサルサービス制度にともない改定されることがあります。</span>
              </td>
            </tr>
            <tr>
              <th>e-mansion IPフォン 総額</th>
              <td>
                初回：&nbsp;{{ displayTotalFirstMonthCost }}&nbsp;円&nbsp;&nbsp;/&nbsp;&nbsp;次回以降：&nbsp;{{ displayTotalMonthlyCost }}&nbsp;円<br />
                <span class="red" v-if="isCampaign">※「次回以降」の金額は、キャンペーンが適用されない通常時における総額を表示しています。</span><br />
                <a :href="`${eMansionUrl}/${apid}/service/ipdenwa/e-ipphone.html`" class="link" target="_blank">※通話料は別途発生いたします。</a>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- ご契約内容の交付方法 -->
        <table class="table-type2">
          <tbody>
            <tr>
              <th>ご契約内容の交付方法</th>
              <td>
                <span v-if="destinationKind == '1'">
                  ご登録いただいた以下住所に郵送いたします。<br />
                  〒{{ destinationZipcode?.slice(0, 3) }}&nbsp;-&nbsp;{{ destinationZipcode?.slice(-4) }}<br />
                  {{ destinationAddress1 }}　{{ destinationAddress2 }}
                </span>
                <span v-else> ご登録いただいたメールアドレス「{{ destinationMailAddress }}」へ送信いたします。 </span>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <p>「ご契約内容のご案内」は、e-mansion会員専用トップページ「契約内容のご案内」で、受付日から30日間ご確認いただけます。</p>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- ご請求内容の確認方法-->
        <ContractDetailsConfirmMethod />
      </div>

      <div class="btn-area">
        <button class="btn btn01 bs" v-on:click="goTop()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
      </div>
    </div>
    <!-- contents -->
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionIPPhoneRegisterResponse } from '@/shared/classes/spf-api/e-mansion/e-mansion-ip-phone';
import ContractDetailsConfirmMethod from '@/shared/components/e-mansion/contract-details-confirm-method.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { E_MANSION_MAIN_ACCOUNT_PLAN_TYPE } from '@/shared/const/e-mansion/e-mansion-main-account-plan-type';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
/** Completed コンポーネント */
export default defineComponent({
  name: 'e-mansion-ip-phone-completed',
  components: {
    LoadingComponent,
    ContractDetailsConfirmMethod,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** サービス登録（IPフォン）の実行結果 */
    displayBoostPhoneID: '' as string,
    displayBoostPhonePassword: '' as string,

    /** e-mansion のサイト URL */
    eMansionProperty: null as EMansionProperty | null,
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,

    /** 金額表示 */
    /** お支払内容の表示判定 true:キャンペーン適用/false:通常価格 */
    isCampaign: false as boolean,
    /** 支払方法（表示用数値文字列 ３桁区切り） */
    displayIPPCampaign: undefined as undefined | null | string,
    displayJimuFee: undefined as undefined | string,
    displayJimuFeeCp: undefined as undefined | null | string,
    displayBasicFee: undefined as undefined | string,
    displayBasicFeeCp: undefined as undefined | null | string,
    displayRentalCharge: undefined as undefined | string,
    displayRentalChargeCp: undefined as undefined | null | string,
    displayUniChargeTaxInc: undefined as undefined | string,
    displayUniChargeCp: undefined as undefined | null | string,
    displayTotalFirstMonthCost: undefined as undefined | string,
    displayTotalMonthlyCost: undefined as undefined | string,

    registerResult: {} as EMansionIPPhoneRegisterResponse,

    destinationKind: '' as string,
    destinationMailAddress: '' as string | undefined,
    destinationZipcode: '' as string | undefined,
    destinationAddress1: '' as string | undefined,
    destinationAddress2: '' as string | undefined,
  }),
  computed: {
    /** APIDを取得 */
    apid(): string {
      if (this.eMansionProperty) {
        return this.eMansionProperty.IN_APID;
      } else {
        return '';
      }
    },
  },
  async mounted() {
    try {
      if (!(await MountedCheckService.canReadEMansionScreen())) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // storeにサービス登録結果がない場合、共通エラー画面に遷移
      this.registerResult = this.$store.getters['eMansionIPPhoneStore/registerResult'];
      if (!this.registerResult) {
        // データ不整合（本来あるはずのデータが受け取れない）
        throw new ApiFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      this.displayBoostPhoneID = this.registerResult.optional_data.Ipphone!.boostphone_id;
      // ※難読化されているため複合が必要
      this.displayBoostPhonePassword = ObfuscationService.decode(this.registerResult.boostphone_password_decrypted!);
      const billingData = this.registerResult.optional_data.Ipphone!.billing_data;
      this.displayBasicFee = this.formatMoney(billingData.basic_fee);
      this.displayBasicFeeCp = this.formatMoney(billingData.basic_fee_cp);
      this.displayRentalCharge = this.formatMoney(billingData.rental_charge);
      this.displayRentalChargeCp = this.formatMoney(billingData.rental_charge_cp);
      this.displayUniChargeTaxInc = this.formatMoney(billingData.uni_charge_tax_inc);
      this.displayUniChargeCp = this.formatMoney(billingData.uni_charge_cp);
      this.displayJimuFee = this.formatMoney(billingData.jimu_fee);
      this.displayJimuFeeCp = this.formatMoney(billingData.jimu_fee_cp);

      if (!this.displayBasicFeeCp && !this.displayRentalChargeCp && !this.displayUniChargeCp && !this.displayJimuFeeCp) {
        this.isCampaign = false;
      } else {
        this.isCampaign = true;
      }

      this.displayTotalFirstMonthCost = new Intl.NumberFormat('ja').format(this.getTotalFirstMonthCost());
      this.displayTotalMonthlyCost = new Intl.NumberFormat('ja').format(this.getTotalMonthlyCost());

      // ご契約内容の交付方法
      const inputDestinationForm = this.$store.getters['eMansionIPPhoneStore/inputDestinationForm'];
      this.destinationKind = inputDestinationForm.contractDestinationKind;
      this.destinationMailAddress = this.destinationKind == '3' ? inputDestinationForm.SettingEMailAddress : inputDestinationForm.contractDestinationMailAddress;
      this.destinationZipcode = inputDestinationForm.contractDestinationZipCode;
      this.destinationAddress1 = inputDestinationForm.contractDestinationAddress1;
      this.destinationAddress2 = inputDestinationForm.contractDestinationAddress2;

      // 前ストアをクリアしておく
      this.$store.commit('eMansionIPPhoneStore/from', null);
      this.$store.commit('eMansionIPPhoneStore/inputDestinationForm', null);

      // e-mansion 契約基本情報/物件基本情報 dispatch
      await StoreExternalApiResponse.main();
      this.eMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
    this.isLoading = false;
  },
  methods: {
    isPropertyTypeTtp() {
      // TTP物件の場合にTrueと判断
      if (this.eMansionProperty instanceof EMansionProperty) {
        const apartmentSpecialTypeFlag = this.eMansionProperty.apartment_special_type_flag;
        if (!apartmentSpecialTypeFlag) {
          return false;
        }
        if (!apartmentSpecialTypeFlag[0]) {
          return false;
        }
        const flags = apartmentSpecialTypeFlag[0];
        const FLAG_TRUE = '1';
        return flags[E_MANSION_MAIN_ACCOUNT_PLAN_TYPE.TTP] === FLAG_TRUE;
      }
      return false;
    },
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /** 初回総額 */
    getTotalFirstMonthCost(): number {
      // キャンペーン適用時はキャンペーン金額の合計
      let totalFirstMonthCost = 0;

      const jimu_fee = this.displayJimuFeeCp ? this.displayJimuFeeCp : this.displayJimuFee;
      totalFirstMonthCost += Number(jimu_fee!.replace(',', ''));
      const basic_fee = this.displayBasicFeeCp ? this.displayBasicFeeCp : this.displayBasicFee;
      totalFirstMonthCost += Number(basic_fee!.replace(',', ''));
      const rental_fee = this.displayRentalChargeCp ? this.displayRentalChargeCp : this.displayRentalCharge;
      totalFirstMonthCost += Number(rental_fee!.replace(',', ''));
      const uni_charge = this.displayUniChargeCp ? this.displayUniChargeCp : this.displayUniChargeTaxInc;
      totalFirstMonthCost += Number(uni_charge!.replace(',', ''));
      if (Number.isNaN(totalFirstMonthCost)) {
        return 0;
      }

      return Math.floor(totalFirstMonthCost);
    },
    /** 次回以降の総額 */
    getTotalMonthlyCost(): number {
      // キャンペーンの適用有無に寄らず、通常金額で合算する（事務手数料は対象外）
      let totalMonthlyCost = 0;

      totalMonthlyCost += Number(this.displayBasicFee?.replace(',', ''));
      totalMonthlyCost += Number(this.displayRentalCharge?.replace(',', ''));
      totalMonthlyCost += Number(this.displayUniChargeTaxInc?.replace(',', ''));
      if (Number.isNaN(totalMonthlyCost)) {
        return 0;
      }

      return Math.floor(totalMonthlyCost);
    },
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: string | undefined): string {
      if (!billing) {
        return '';
      }
      //念のためカンマを除去
      const billingNumStr = billing.replace(/,/g, '');
      const billingNum = parseFloat(billingNumStr);
      //数値かどうかチェック
      if (isNaN(billingNum)) {
        return '';
      }
      return new Intl.NumberFormat('en-US').format(Number(billingNum));
    },
  },
});
</script>
