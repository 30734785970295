<template>
  <div class="e-mansion-entry-room-number">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion ご入会お申し込み</h1>
    </main>

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion ご入会お申し込み</li>
      </ul>
      <!-- /breadcrumb -->
      <div class="blc"><error-messages-component :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" /></div>
      <!-- 部屋番号入力 -->
      <div class="blc">
        <p class="p-bold">お手数ではございますが、お客様の<span v-if="buildingNameList.length > 0">棟名と</span>部屋番号をご入力の上「入力」ボタンをクリックしてください。</p>
        <table class="table-type2">
          <tbody>
            <tr v-if="buildingNameList.length > 0">
              <th class="va-middle">棟名<span class="req">必須</span></th>
              <td>
                <select v-model="buildingId" class="form-select">
                  <option disabled value=""></option>
                  <option v-for="buildingNameItem in buildingNameList" :key="buildingNameItem.key" :value="buildingNameItem.key">
                    {{ buildingNameItem.value }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th class="va-middle">部屋番号<span class="req">必須</span></th>
              <td>
                半角数字でご入力ください。<br />
                <input type="text" class="text short" placeholder="101" maxlength="8" pattern="^([a-zA-Z0-9]{,8})$" v-model="roomNumber" />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="btn-area">
          <button class="btn btn05 bs" @click="onBack">戻る<i class="material-icons link link-icon">west</i></button>
          <button class="btn btn01 bs" @click="onNext" :disabled="!roomNumber || (buildingNameList.length > 0 && (!buildingId || buildingId == '0000'))">
            入力<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
button.btn01:disabled {
  opacity: 0.5;
}
</style>
<script lang="ts">
import { EMansionConnectixErrorResponse } from '@/pages/connectix/e-mansion/classes/external-api/connectix-error-response';
import { EMansionConnectixPropertyRooms, EMansionConnectixPropertyRoomsResponseResult } from '@/pages/connectix/e-mansion/classes/external-api/property-rooms-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { EMansionConnectixRoomsRequest } from '@/shared/classes/external-api/e-mansion/connectix-room-request';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { convertFullToHalf } from '@/shared/util/change-to-half-width-characters';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { halfWidth } from '@/shared/util/validators';
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { EMansionEntryInputForm } from './classes/entry-input-form';
/** 部屋番号入力画面 コンポーネント */
export default defineComponent({
  name: 'room-number',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 画面読み込み中か否か */
    isLoading: true,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '入力に誤りがあります。恐れ入りますが再度お試しください。',
    /** 棟のリスト */
    buildingNameList: new Array<{
      key: string;
      value: string;
    }>(),
    /** 選択された棟名の棟ID */
    buildingId: '',
    /**部屋番号一覧 */
    propertyRooms: new Array<EMansionConnectixPropertyRooms>(),
    /**部屋番号 */
    roomNumber: '',
  }),
  validations: {
    roomNumber: {
      required, //必須
      halfWidth, // 半角英数字
      maxLength: maxLength(8), // 8文字以内
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted(): Promise<void> {
    // 初期表示チェック
    if (!(await MountedCheckService.canReadEMansionEntryScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    const customer = this.$store.getters['eMansionEntryStore/entryInputForm'];
    this.buildingId = customer.building_id;
    this.roomNumber = customer.room_number;
    const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/property'];
    if (eMansionProperty instanceof EMansionSharedErrorResponse) {
      // 外部APIエラー
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
    }
    // 部屋番号一覧をStoreから取得
    const eMansionConnectixRoomsRequest: EMansionConnectixRoomsRequest = {
      propertyId: customer.property_id,
      uaType: UA_TYPE.E_MANSION,
    };
    const propertyRooms: EMansionConnectixPropertyRoomsResponseResult | EMansionConnectixErrorResponse = await this.$store.dispatch(
      'eMansionConnectixEntryStore/propertyRooms',
      eMansionConnectixRoomsRequest
    );

    if (propertyRooms instanceof EMansionConnectixPropertyRoomsResponseResult) {
      // 部屋番号情報を管理していない物件はAPIのroom配列を空でレスポンスされるので、その場合はエラーメッセージを表示する
      if (propertyRooms.result.rooms.length === 0) {
        this.errorMessages.push('物件の部屋番号情報が取得できませんでした。');
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      this.propertyRooms = propertyRooms.result.rooms;
      for (const propertyRoom of this.propertyRooms) {
        const findPropertyArray = {
          key: propertyRoom.building_id,
          value: propertyRoom.building_name,
        };
        // 棟の重複を取り除く、building_id === '0000' の場合は棟名なし（API 定義より）
        if (!this.buildingNameList.some((b: { key: string; value: string }) => b.key === propertyRoom.building_id) && propertyRoom.building_id !== '0000') {
          this.buildingNameList.push(findPropertyArray);
        }
      }

      this.buildingNameList.sort((a, b) => a.key.localeCompare(b.key));
    } else if (propertyRooms instanceof EMansionConnectixErrorResponse) {
      // エラーケースの場合
      // API側から返されたエラーメッセージを表示
      this.errorMessages = propertyRooms.errorInfo;
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      this.isLoading = false;
      return;
    } else {
      // 外部APIエラー
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.ROOM_INFO);
    }
    this.isLoading = false;
  },
  methods: {
    /**エラーメッセージ表示 */
    showErrorMessage() {
      window.scrollTo(0, 0);
      this.isLoading = false;
      return;
    },

    /**お客様情報入力画面へ遷移 */
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.errorMessages = [];
      //バリデーションチェック

      //全角→半角変換

      if (this.v$.roomNumber.required.$invalid || this.v$.roomNumber.maxLength.$invalid) {
        this.errorMessages.push('「お部屋番号」を半角英数字で入力してください。');
      } else {
        this.roomNumber = convertFullToHalf(this.roomNumber);
        if (this.v$.roomNumber.halfWidth.$invalid) {
          this.errorMessages.push('「お部屋番号」を半角英数字で入力してください。');
        } else if (!this.isIncludeRoomNumber(this.roomNumber)) {
          this.errorMessages.push('「お部屋番号」をご確認の上、正しく入力してください');
        }
      }
      if (this.buildingNameList.length > 0 && (!this.buildingId || this.buildingId == '0000')) {
        this.errorMessages.push('「棟名」を選択してください。');
      }
      if (this.errorMessages.length > 0) {
        this.showErrorMessage();
        return;
      }

      const customer: EMansionEntryInputForm = this.$store.getters['eMansionEntryStore/entryInputForm'];

      customer.room_number = this.roomNumber;
      //入力された棟・部屋番号の組み合わせにマッチする部屋番号マスタに「入会物件ID」が設定されている場合
      //以降の画面の処理は元の物件IDではなく「入会物件ID」に読み替えて処理する
        const newPropertyRoom = this.findMatchBuildingNameAndRoomNumber(this.buildingId, this.roomNumber);
     
        if (newPropertyRoom) {
          customer.property_id = newPropertyRoom.property_id;
          customer.building_name = newPropertyRoom.building_name;
          customer.building_id = newPropertyRoom.building_id;
          //PortasDBから置き換える物件の情報を取得
          const newProperty = await SpfApiService.searchPropertiesByApartmentId(customer.property_id);
          //物件情報置き換え
          customer.property_name = newProperty.apartmentName;
          customer.zipcode = newProperty.zipcode ?? '';
          customer.address = newProperty.address1;
        }
      
      this.$store.commit('eMansionEntryStore/entryInputForm', customer);

      await this.$router.push('/e-mansion/entry/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /**前の画面へ戻る */
    async onBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/e-mansion/entry/terms').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /**入力された部屋番号が部屋番号一覧取得APIのレスポンスに含まれているか */
    isIncludeRoomNumber(roomNumber: string): boolean {
      for (const room of this.propertyRooms) {
        if (room.room_number == roomNumber) {
          return true;
        }
      }
      return false;
    },
    /**入力された棟・部屋番号の組み合わせに
     * マッチする部屋番号マスタに
     * 「入会物件ID」が設定されている場合
     * 「入会物件ID」を返却する 
     * ※棟無しの場合は部屋番号のみで比較する
     * */
    findMatchBuildingNameAndRoomNumber(buildingId: string, roomNumber: string): EMansionConnectixPropertyRooms | undefined {
      if (this.buildingNameList.length > 0) {
        for (const room of this.propertyRooms) {
          if (room.building_id === buildingId && room.room_number === roomNumber && room.property_id) {
            return room;
          }
        }
        return undefined;
      }else{
        //棟が無い場合は部屋番号のみで比較
        for (const room of this.propertyRooms) {
          if (room.room_number === roomNumber && room.property_id) {
            return room;
          }
        }
        return undefined;
      }
    }
  },
});
</script>
