import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionErrorDisplayInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-error-display-info';

const api = BaseApiService.api();

export class SpfApiServerCommonUtilAccessor {
  public static async getServerTimeNow(): Promise<Date> {
    const response = await api.get<Date>(`/common/server-common-util/getServerDateTime/now`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });

    if (isNaN(Date.parse(String(response.data)))) {
      throw new Error('Internal Server Error : サーバ側の現在日時取得失敗');
    }
    return new Date(String(response.data));
  }

  public static async getEMansionErrorDisplayInfo(): Promise<Array<EMansionErrorDisplayInfo>> {
    const response = await api.get<Array<EMansionErrorDisplayInfo>>(`/common/server-common-util/getEMansionErrorDisplayInfo`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });

    return response.data.map((list) => this.createEMansionErrorDisplayInfo(list));
  }

  private static createEMansionErrorDisplayInfo(info: EMansionErrorDisplayInfo): EMansionErrorDisplayInfo {
    return new EMansionErrorDisplayInfo(info.propertyType, info.contactTel1, info.contactTel2, info.contactUrl, info.contactOfficeHours);
  }
}
