<template>
  <div class="gip-usage">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion 固定グローバルIPアドレス</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion 会員専用トップ</router-link>
        </li>
        <li>固定グローバルIPアドレス</li>
      </ul>
      <!-- /breadcrumb -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="固定グローバルIP　ご利用状況" />固定グローバルIPアドレス　ご利用状況</h2>
      </div>
      <table class="table-type2" v-if="!isApplyConnectix">
        <tbody>
          <tr>
            <th><b>MACアドレス</b></th>
            <th><b>ご利用状況</b></th>
          </tr>
          <tr v-for="(gip, index) in gipArrayForDisplay" :key="index">
            <td>{{ gip.macaddress }}</td>
            <td v-if="gip.status === GIP_STATUS_ON_MANSION.IN_USE">ご利用中</td>
            <td v-else-if="gip.status === GIP_STATUS_ON_MANSION.CANCEL_APPLY">
              {{ gip.end_time }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table-type2" v-else>
        <tbody>
          <tr>
            <th><b>ご利用状況</b></th>
          </tr>
          <tr v-for="(gip, index) in gipArrayForDisplay" :key="index">
            <td v-if="gip.status === GIP_STATUS_ON_MANSION.IN_USE">ご利用中</td>
            <td v-else-if="gip.status === GIP_STATUS_ON_MANSION.CANCEL_APPLY">{{ gip.end_time }}</td>
          </tr>
        </tbody>
      </table>
      <div class="btn-area">
        <button class="btn btn01 bs" type="button" @click="onAdditionalApply()" v-if="isDisplayAdditionalApply">
          追加のお申し込み<i class="material-icons link link-icon">east</i>
        </button>
        <button class="btn btn01 bs" type="button" v-if="!isApplyConnectix">
          <a :href="eMansionGipUrl" class="link" target="_blank">MACアドレスの変更・解約</a>
          <i class="material-icons link link-icon">east</i>
        </button>
        <button class="btn btn01 bs" type="button" v-else>
          <a :href="eMansionGipUrl" class="link" target="_blank">解約</a>
          <i class="material-icons link link-icon">east</i>
        </button>

        <div>
          <button class="btn btn05 bs" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
.btn-area {
  text-align: center;
  button {
    margin-top: 10px;
  }
}
a,
.link:hover {
  text-decoration: none;
  color: #ffffff;
}
/*スマホ横並び維持*/
@media (max-width: 767px) {
  .table-type2 th,
  .table-type2 td {
    display: inline-flex;
    width: 50%;
    padding: 10px;
  }
}
</style>

<script lang="ts">
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { GipForDispDTO } from '@/shared/classes/spf-api/e-mansion/e-mansion-usage-gip-mac-address-dto';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { GIP_STATUS_ON_MANSION } from '@/shared/const/e-mansion';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import store from '@/store';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
/** Completed コンポーネント */
export default defineComponent({
  name: 'static-global-ip-usage',
  components: {
    LoadingComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** Connectix対象物件
     * 対象:true, 非対象:false
     */
    isApplyConnectix: false,
    /** 契約基本情報取得API.オプション利用状況 */
    gipArray: [] as GipForDispDTO[],
    /** 契約基本情報取得API.オプション利用状況 */
    gipArrayForDisplay: [] as GipForDispDTO[],

    /** 追加申し込みボタンの表示
     * 表示:ture,非表示:false */
    isDisplayAdditionalApply: false,
    /** e-mantion My の URL */
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    property: null,
    /**ランダムで設定する9桁の数字を当てはめる */
    rd: '',
    /**追加のお申込みのURLで使用するapid */
    apid: '',
    /**追加のお申込みのURL */
    eMansionGipUrl: '',
    GIP_STATUS_ON_MANSION: GIP_STATUS_ON_MANSION,
  }),
  async mounted() {
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      await StoreExternalApiResponse.main();
      // Connectix対象物件か確認する(MACアドレスのだし分け)
      /** 物件基本情報をStoreから取得 */
      const eMansionProperty: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        // Connectix対象物件か確認
        this.isApplyConnectix = this.checkApplyConnectix(eMansionProperty);

        /** 契約基本情報をStoreから取得 */
        const eMansionCustomer = store.getters['eMansionCommonStore/customer'];

        this.gipArray = eMansionCustomer.op.globalip;

        // もし契約基本情報取得API.オプション利用状況.固定GIP に要素が存在しない（=契約なし）の場合、固定GIP規約画面へ
        if (this.gipArray.length === 0) {
          await this.$router
            .push('/e-mansion/static-gip/terms')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              checkRouterError(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }

        /* 物件のIPの申し込み可能数を確認する(追加のお申し込みボタンのだし分け)
             1IPのみお申込み可能物件では非表示
             ・複数申込可能物件では表示
              →利用中、解約登録中の合計が申込可能上限数に達しているときは非表示
          **/
        const optionStatusMacAddressLength = this.gipArray.filter(
          (v) => String(v.status) === GIP_STATUS_ON_MANSION.IN_USE || String(v.status) === GIP_STATUS_ON_MANSION.CANCEL_APPLY
        ).length;
        if (Number(eMansionProperty.op.gip.max) > 1) {
          if (Number(eMansionProperty.op.gip.max) > optionStatusMacAddressLength) {
            this.isDisplayAdditionalApply = true;
          }
        }

        /** 表示するMACアドレスのご利用状況.解約日を適切な表示に加工 */
        if (this.gipArray?.length !== 0) {
          for (const v of this.gipArray) {
            if (v.end_time !== '' && v.end_time !== null && v.end_time !== undefined) {
              v.end_time = dayjs(v.end_time).format('YYYY/M/D');
            } else {
              v.end_time = '';
            }

            const formattedGipArray = new GipForDispDTO({
              macaddress: this.insertHyphen(v.macaddress),
              status: v.status,
              end_time: this.createEndTime(v.status, v.end_time),
            });

            this.gipArrayForDisplay.push(formattedGipArray);
          }
        }
      }
      // 9桁のランダムな数字を取得
      this.rd = this.createRd();
      // apid追加
      this.apid = eMansionProperty.IN_APID;
      // 追加のお申込みのURLを定義
      this.eMansionGipUrl = `${this.eMansionMyUrl}/login/login.php?function=GLOBALIP&apid=${this.apid}&rd=${this.rd}`;

      this.isLoading = false;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
  },
  methods: {
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onAdditionalApply() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      /** 物件基本情報をStoreから取得 */
      const eMansionProperty: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];

      /* 物件のIPの申し込み可能数を確認する(追加のお申し込みボタンのだし分けで本来ボタンは表示されてないはずだが、念のため確認)
        1IPのみお申込み可能物件では非表示
        ・複数申込可能物件では表示(なので遷移可能)
        →利用中、解約登録中の合計が申込可能上限数に達しているときは非表示
      **/
      const optionStatusMacAddressLength = this.gipArray.filter(
        (v) => String(v.status) === GIP_STATUS_ON_MANSION.IN_USE || String(v.status) === GIP_STATUS_ON_MANSION.CANCEL_APPLY
      ).length;
      if (Number(eMansionProperty.op.gip.max) > 1) {
        if (Number(eMansionProperty.op.gip.max) > optionStatusMacAddressLength) {
          await this.$router
            .push('/e-mansion/static-gip/terms')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              checkRouterError(error);
            })
            .finally(() => {
              this.isSubmitting = false;
            });
        }
      }

      await this.$router
        .push('/e-mansion/static-gip/terms')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /**Connectix対象物件かを判定 */
    checkApplyConnectix(property: EMansionProperty): boolean {
      /**
       * ・Connectix対象物件であること：
       * 物件情報取得APIの「SD-WANフラグ（op_sdwan_flag）」が"1"
       */
      if (property.op.sdwan.flag === '1') {
        return true;
      }
      return false;
    },
    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    /**Macアドレスを２桁区切りのハイフンにする */
    insertHyphen(macaddress: string): string {
      if (this.isApplyConnectix) {
        return '';
      }
      if (!macaddress || (macaddress && macaddress.length !== 12)) {
        throw new Error('Macアドレスの桁数が不正です');
      }
      return (
        macaddress.slice(0, 2) +
        '-' +
        macaddress.slice(2, 4) +
        '-' +
        macaddress.slice(4, 6) +
        '-' +
        macaddress.slice(6, 8) +
        '-' +
        macaddress.slice(8, 10) +
        '-' +
        macaddress.slice(10, 12)
      );
    },
    /**もしステータスが解約登録中だったら「解約登録中 サービス終了日:」を付ける */
    createEndTime(status: string, end_time: string): string {
      if (status === GIP_STATUS_ON_MANSION.CANCEL_APPLY) {
        return '解約登録中 サービス終了日:' + end_time;
      } else {
        return end_time;
      }
    },
  },
});
</script>
