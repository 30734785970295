/**
 * UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社
 */
export const OPTICAL_PHONE_USE_COMPANY = {
  // その他
  OTHER: '10',
} as const;

/**
 * UCOM光電話 電話番号取得方法 番号ポータビリティ ご利用中の電話会社
 */
export const PHONE_USE_COMPANY = [
  { name: 'NTT固定電話', value: '01' },
  { name: 'NTTひかり電話', value: '02' },
  { name: 'ＫＤＤＩ株式会社（auひかり）', value: '04' },
  { name: 'ソフトバンク株式会社（おとくライン）', value: '05' },
  { name: 'J-COM', value: '06' },
  { name: 'ＮＴＴコミュニケーションズ株式会社', value: '08' },
  { name: '株式会社オプテージ（eo光）', value: '09' },
  { name: '中部テレコミュニケーション株式会社（コミュファ光）', value: '12' },
  { name: '株式会社Qtnet', value: '13' },
  { name: '株式会社ＳＴＮｅｔ（ピカラ）', value: '14' },
  { name: '株式会社エネコム （メガエッグ）', value: '15' },
  { name: '楽天モバイル(楽天コミュニケーションズ) 株式会社', value: '16' },
  { name: '株式会社トークネット', value: '17' },
  { name: 'Coltテクノロジーサービス株式会社', value: '18' },
  { name: 'ZIP Telecom株式会社', value: '19' },
  { name: '株式会社アイ・ピー・エス・プロ', value: '20' },
  { name: '大江戸テレコム株式会社', value: '21' },
  { name: '株式会社三通', value: '22' },
  { name: 'その他', value: '98' },
] as const;
export type PHONE_USE_COMPANY = typeof PHONE_USE_COMPANY[keyof typeof PHONE_USE_COMPANY];
