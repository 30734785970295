<template>
  <LoadingComponent v-if="isLoading" />
  <div class="completed">
    <main class="underlayer-main">
      <h1>e-mansion i-フィルター</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li class="stay">i-フィルター</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">お申し込み</li>
        <li>確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="i-フィルター お申し込み" />i-フィルター　お申し込み</h2>

        <div class="sblc">
          <h3 class="service-h3">ご注意ください</h3>
          <p>※対応OS、CPU、メモリなど<b class="red">ソフトの動作環境</b> を必ずご確認の上お申し込みください</p>
          <div class="template">
            <p>
              <a :href="`${eMansionUrl}/${apid}/service/security/ifilter.html`" class="link" target="i-filter">ソフトの動作環境はこちら→</a>
            </p>
          </div>
        </div>

        <div class="sblc">
          <h3 class="service-h3">i-フィルター お申し込み</h3>
          <div class="template">
            <p><b>１ライセンスお申し込み毎に、端末１台にインストールすることができます。</b></p>
            <p>
              <b><span class="red">ご契約マンションの「鍵のお引渡し日」以降</span>に、i-フィルター をダウンロードしていただけます。</b>
            </p>
          </div>
        </div>
      </div>

      <div class="blc">
        <p class="form-btn-txt"><b>ご確認の上、よろしければ「申し込む」をクリックしてください。</b></p>
        <div class="btn-area">
          <button class="btn btn05 bs" v-on:click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs sp-margin" v-on:click="onNext()" type="button" :disabled="errorMessages.length > 0">
            申し込む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-account-accessor';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { eMansionNotifyMailAddressCheckRequest } from '@/shared/classes/spf-api/mail/model/account/e-mansnion-notify-mailaddress-check-request';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';

export default defineComponent({
  name: 'platform-terms-i-filter',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data(): {
    // 会員情報
    member: Member | null;
    // 会員ステータス
    memberStatus: MemberStatus | null;
    // e-mansion サイト URL
    eMansionUrl: string;
    // 8桁APIDE
    apid: string;
    getFlagDebug: string;
    /** エラーメッセージを格納する配列 */
    errorMessages: string[];
    errorMessageTitle: string;
    isLoading: boolean;
  } {
    return {
      member: null,
      memberStatus: null,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      apid: '',
      getFlagDebug: '',
      errorMessages: [] as string[],
      /** エラーメッセージ部に表示するタイトル */
      errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
      isLoading: true,
    };
  },
  //ログインチェック、物件チェック、ほか、確かめる内容を追加
  async mounted(): Promise<void> {
    this.isLoading = true;
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    const member: Member = await this.$store.dispatch('memberStore/member');
    if (!member.primaryKeyMye) {
      this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 物件情報を取得する
    const property = this.$store.getters['propertyStore/property'];
    const eMansionProperty: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
    // e-mansionと紐付けがあるか確認を行う
    this.apid = eMansionProperty.IN_APID;

    try {
      const primaryKeyMye = member.primaryKeyMye;
      const apartmentId = property.apartmentId;
      const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      const uaType = property.uaType;
      const chechNotifyAccount = await SpfApiAccountAccessor.getEMansionAccountNotifyEmail(
        new eMansionNotifyMailAddressCheckRequest(primaryKeyMye!, apartmentId, servicePlanType, uaType)
      );

      if (chechNotifyAccount) {
        this.errorMessages.push(String(chechNotifyAccount.messages));
      }
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }

    this.isLoading = false;
    return;
  },
  methods: {
    async onNext(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      if (this.errorMessages.length > 0) {
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      this.errorMessages = [];

      await this.$router.push('/e-mansion/i-filter/confirm').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
      return;
    },
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/e-mansion/i-filter/terms').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
      return;
    },
  },
});
</script>

<style lang="scss" scoped>
.text-align-center {
  text-align: center;
}
.condition {
  margin: 0 0 5px;
  border-left: 3px solid #cf1225;
  padding-left: 15px;
  margin-left: 10px;
}
div.template {
  margin-top: 10px;
}
.btn:disabled {
  background-color: #939393 !important;
}
.sp-margin {
  margin-top: 10px;
}
</style>
