<template>
  <div class="five-a-payment-methods-register">
    <LoadingComponent v-if="isSubmitting" />
    <main class="underlayer-main">
      <h1>Five.A お支払い方法</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/5a">Five.A会員専用トップ</router-link></li>
        <li>お支払い方法</li>
      </ul>
      <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
        <li>お支払方法登録</li>
        <li class="stay">完了</li>
      </ul>
      <isp-payment-method-register-completed-component v-bind:serviceName="serviceName"></isp-payment-method-register-completed-component>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import IspPaymentMethodRegisterCompletedComponent from '@/shared/components/isp/payment-method-register-completed.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SERVICE_NAME } from '@/shared/const/service-type';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'five-a-payment-methods-register-completed',
  components: {
    IspPaymentMethodRegisterCompletedComponent,
    LoadingComponent,
  },
  data: () => ({
    isSubmitting: false,
    serviceName: SERVICE_NAME.FIVE_A,
  }),
  async mounted(): Promise<void> {
    if (!(await MountedCheckService.canReadFiveAScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
});
</script>
