import { Module } from 'vuex';
import { RootState } from '../index';

/** selectedCourseのState */
const selectedCourseConfirmState: {
  status: string | undefined;
  memberId: string | undefined;
  courseId: string | undefined;
  receiveTime: string | undefined;
  showTpl: string | undefined;
  dispConstructionCompleteDate: string | undefined;
  basicFee: string | undefined;
  basicFeeCp: string | undefined;
  tesuryoFee: string | undefined;
  tesuryoFeeCp: string | undefined;
  chgConstruction: string | undefined;
  chgConstructionCp: string | undefined;
  adapterCharge: string | undefined;
  adapterChargeCp: string | undefined;
} = {
  status: undefined,
  memberId: undefined,
  courseId: undefined,
  receiveTime: undefined,
  showTpl: undefined,
  dispConstructionCompleteDate: undefined,
  basicFee: undefined,
  basicFeeCp: undefined,
  tesuryoFee: undefined,
  tesuryoFeeCp: undefined,
  chgConstruction: undefined,
  chgConstructionCp: undefined,
  adapterCharge: undefined,
  adapterChargeCp: undefined,
};

/** State の型 */
type SelectedCourseConfirmState = typeof selectedCourseConfirmState;

export const selectedCourseConfirmStore: Module<SelectedCourseConfirmState, RootState> = {
  namespaced: true,
  state: selectedCourseConfirmState,
  actions: {},
  mutations: {
    status: (state, value: string) => {
      state.status = value;
    },
    memberId: (state, value: string) => {
      state.memberId = value;
    },
    courseId: (state, value: string) => {
      state.courseId = value;
    },
    receiveTime: (state, value: string) => {
      state.receiveTime = value;
    },
    showTpl: (state, value: string) => {
      state.showTpl = value;
    },
    dispConstructionCompleteDate: (state, value: string) => {
      state.dispConstructionCompleteDate = value;
    },
    basicFee: (state, value: string) => {
      state.basicFee = value;
    },
    basicFeeCp: (state, value: string) => {
      state.basicFeeCp = value;
    },
    tesuryoFee: (state, value: string) => {
      state.tesuryoFee = value;
    },
    tesuryoFeeCp: (state, value: string) => {
      state.tesuryoFeeCp = value;
    },
    chgConstruction: (state, value: string) => {
      state.chgConstruction = value;
    },
    chgConstructionCp: (state, value: string) => {
      state.chgConstructionCp = value;
    },
    adapterCharge: (state, value: string) => {
      state.adapterCharge = value;
    },
    adapterChargeCp: (state, value: string) => {
      state.adapterChargeCp = value;
    },
  },
  getters: {
    status: (state): string | undefined => state.status,
    memberId: (state): string | undefined => state.memberId,
    courseId: (state): string | undefined => state.courseId,
    receiveTime: (state): string | undefined => state.receiveTime,
    showTpl: (state): string | undefined => state.showTpl,
    dispConstructionCompleteDate: (state): string | undefined => state.dispConstructionCompleteDate,
    basicFee: (state): string | undefined => state.basicFee,
    basicFeeCp: (state): string | undefined => state.basicFeeCp,
    tesuryoFee: (state): string | undefined => state.tesuryoFee,
    tesuryoFeeCp: (state): string | undefined => state.tesuryoFeeCp,
    chgConstruction: (state): string | undefined => state.chgConstruction,
    chgConstructionCp: (state): string | undefined => state.chgConstructionCp,
    adapterCharge: (state): string | undefined => state.adapterCharge,
    adapterChargeCp: (state): string | undefined => state.adapterChargeCp,
  },
};
