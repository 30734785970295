import { UA_TYPE, UaTypeValue } from '@/shared/const/service-type';

/** e-mansion お申し込み 入力フォーム */
export class EMansionEntryInputForm {
  public ua_type: UaTypeValue = UA_TYPE.E_MANSION; // e-mansion の ua_type = '1'
  public property_id!: string;
  public building_id!: string;
  public room_number!: string;

  public name_surname!: string;
  public name_first_name!: string;
  public kana_surname!: string;
  public kana_first_name!: string;
  public birthday!: string;
  public phone_number!: string | null;
  public emergency_phone_number?: string;
  public email_address!: string;
  public scheduled_move_in_on!: string | null;
  /**書類のご希望送付先郵便番号（物件TBLに合わせてハイフンありで保持している） */
  public destination_zipcode!: string | null;
  /**入力値保持用(前半) */
  public destination_zipcode_former!: string | null;
  /**入力値保持用(後半) */
  public destination_zipcode_latter!: string | null;
  public destination_address1!: string | null;
  public destination_address2!: string | null;
  public destination_telephone!: string | null;

  public delivery_year?: string;
  public delivery_month?: string;
  public delivery_day?: string;
  public delivery_date?: string | null;
  public delivery_time_zone?: string;
  public multi_device_security?: string;

  public credit_card_token?: string;
  public token_expire_date?: string;
  public electronicdelivery!: string;
  public electronicdelivery_email_address?: string;
  /**書類送付先種別 */
  public destination_kind?: string;
  /**契約書面送付先種別 */
  public contract_destination_kind?: string;
  /**送付先メールアドレス */
  public contract_destination_mailaddress?: string;
  public contract_destination_mailaddress_confirm?: string;
  /**契約書面送付先郵便番号（Myeのリクエスト形式に合わせてハイフン無しで保持している） */
  public contract_destination_zipcode?: string;
  /**契約書面送付先郵便番号（前半） */
  public contract_destination_zipcode_former?: string;
  /**契約書面送付先郵便番号（後半） */
  public contract_destination_zipcode_latter?: string;
  /**契約書面送付先住所１ */
  public contract_destination_address1?: string;
  /**契約書面送付先住所２ */
  public contract_destination_address2?: string;
  public course_id!: string;
  public adapter?: string | null;
  public password!: string;
  public hikariphone?: string;

  public building_name?: string | null;
  public property_name!: string;
  public zipcode!: string;
  public address!: string;

  public first_month_cost?: number;
  public monthly_cost?: number;
  //接続コース情報(完了画面用)
  public course_info?: CourseInfo;
  public ipphone_flag?: boolean;
  public ipphone_info?: IPPhoneInfo;
  public ifilter_flag?: boolean;
  public ifilter_info?: IFilterInfo;

  constructor(partial: Partial<EMansionEntryInputForm>) {
    Object.assign(this, partial);
  }
}
export class CourseInfo {
  public bandspeed_name?: string;
  public access_network_name?: string;
  public ins_kind?: string;
  public price?: string;
  public jimu_fee?: string;
  public construction_fee?: string;
  public rental_fee?: string;
  public price_cp?: string;
  public jimu_fee_cp?: string;
  public construction_fee_cp?: string;
  public rental_fee_cp?: string;
}
export class IPPhoneInfo {
  public ipphone_fee_price?: string;
  public ipphone_monthly_price?: string;
  public ipphone_tarental_price?: string;
  public ipphone_universalservice_price?: string;
  public ipphone_fee_price_cp?: string;
  public ipphone_monthly_price_cp?: string;
  public ipphone_tarental_price_cp?: string;
  public ipphone_universalservice_price_cp?: string;
}

export class IFilterInfo {
  public price?: string;
  public price_cp?: string;
}
