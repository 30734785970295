<template>
  <div>
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/e-mansion">e-mansion 会員専用トップ</a></li>
        <li>UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">オプションお申し込み・解約</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="付加サービス（有料）ご利用状況/お申し込み" />付加サービス（有料）ご利用状況/お申し込み</h2>
      </div>
      <div>
        <ul>
          <li>お申し込みいただく付加サービスにチェックを入れて「申し込む」をクリックしてください。確認画面に進みます。</li>
          <li>付加サービスを解約される場合は、対象サービスの「解約」をクリックしてください。</li>
        </ul>
        <p>※「(3)キャッチ通話番号表示」は「(2)キャッチ通知」とセットでお申し込み/ご利用ください。</p>
        <p>※「(4)オプションサービスパック」は(1)、(2)、(3)をすべて含んだパックメニューとなります。</p>
        <table class="table-type2">
          <tbody>
            <tr>
              <th>付加サービス</th>
              <th>料金</th>
              <th>ご利用状況</th>
              <th>申し込み/解約</th>
            </tr>
            <tr>
              <td>{{ optionInfoNumberDisplay?.name }}</td>
              <td>月額 {{ optionInfoNumberDisplay?.fee }}円</td>
              <td><span v-if="optionInfoNumberDisplay?.status === '00'">ご利用なし</span> <span v-else class="red">ご利用中</span></td>
              <td class="td-button">
                <span v-if="optionInfoNumberDisplay?.status === '12'">
                  <button class="btn btn05 bs" type="button" @click="onCancel(optionInfoNumberDisplay.optionNumber)" :disabled="!isNotifyEmail">
                    解約<i class="material-icons link link-icon">east</i>
                  </button></span
                >
                <span v-else-if="optionInfoNumberDisplay?.status === '00'"><input type="checkbox" v-model="option_1" /> </span>
              </td>
            </tr>
            <tr>
              <td>{{ optionInfoCatch?.name }}</td>
              <td>月額 {{ optionInfoCatch?.fee }}円</td>
              <td><span v-if="optionInfoCatch?.status === '00'">ご利用なし</span> <span v-else class="red">ご利用中</span></td>
              <td class="td-button">
                <span v-if="optionInfoCatch?.status === '12'">
                  <button class="btn btn05 bs" type="button" @click="onCancel(optionInfoCatch!.optionNumber)" :disabled="!isNotifyEmail">
                    解約<i class="material-icons link link-icon">east</i>
                  </button></span
                >
                <span v-else-if="optionInfoCatch?.status === '00'"><input type="checkbox" v-model="option_2" /></span>
              </td>
            </tr>
            <tr>
              <td>{{ optionInfoCatchNumberDisplay?.name }}</td>
              <td>月額 {{ optionInfoCatchNumberDisplay?.fee }}円</td>
              <td>
                <span v-if="optionInfoCatchNumberDisplay?.status === '00'">ご利用なし</span>
                <span v-else class="red">ご利用中</span>
              </td>
              <td class="td-button">
                <span v-if="optionInfoCatchNumberDisplay?.status === '12'">
                  <button class="btn btn05 bs" type="button" @click="onCancel(optionInfoCatchNumberDisplay!.optionNumber)" :disabled="!isNotifyEmail">
                    解約<i class="material-icons link link-icon">east</i>
                  </button></span
                >
                <span v-else-if="optionInfoCatchNumberDisplay?.status === '00'"><input type="checkbox" v-model="option_3" /> </span>
              </td>
            </tr>
            <tr>
              <td>{{ optionInfoOptionPack?.name }}</td>
              <td>月額 {{ optionInfoOptionPack?.fee }}円</td>
              <td><span v-if="optionInfoOptionPack?.status === '00'">ご利用なし</span> <span v-else class="red">ご利用中</span></td>
              <td class="td-button">
                <span v-if="optionInfoOptionPack?.status === '12'">
                  <button class="btn btn05 bs" type="button" @click="onCancel(optionInfoOptionPack!.optionNumber)" :disabled="!isNotifyEmail">
                    解約<i class="material-icons link link-icon">east</i>
                  </button></span
                >
                <span v-else-if="optionInfoOptionPack?.status === '00'"><input type="checkbox" v-model="option_4" /></span>
              </td>
            </tr>
          </tbody>
        </table>
        <ul>
          <li>お申し込み直後からご利用状況が「ご利用中」と表示されますが、付加サービスが有効となるのはお申し込み日の5営業日後となります。</li>
          <li>付加サービス解約お手続き後、サービス終了後までの間ご利用状況が「ご利用中」と表示されます。</li>
        </ul>
      </div>
      <!-- ボタン -->
      <div class="btn-area">
        <button class="btn btn05 bs" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
        <button class="btn btn01 bs" type="button" :disabled="!isDisabledButton" @click="onApply()">
          申し込む
          <i class="material-icons link link-icon">east</i>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../../images/main.png');
}
.td-button {
  text-align: center;
}
table td button.btn {
  margin-top: 0;
  line-height: unset;
}
.td-button input[type='checkbox'] {
  transform: scale(1.5);
}
div.btn-area {
  position: relative;
  padding-top: 72px;
  text-align: center;
}
.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}
.btn.btn05.bs {
  &:disabled {
    opacity: 0.5;
  }
}
span.red {
  color: #cf1225;
}
button.btn {
  margin-top: 10px;
}
</style>

<script lang="ts">
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-account-accessor';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { EMansionCustomer, HikariphoneOption } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { UcomHikariPhoneOptionForDisplayOnManageDTO } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-option-dto';
import { eMansionNotifyMailAddressCheckRequest } from '@/shared/classes/spf-api/mail/model/account/e-mansnion-notify-mailaddress-check-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { UCOM_HIKARI_PHONE_OPTION_ON_EMANSION, UCOM_HIKARI_PHONE_STATUS_ON_MANSION } from '@/shared/const/e-mansion';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';

/** Completed コンポーネント */
export default defineComponent({
  name: 'hikari-phone-option-manage',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    /** 最初はロード中 */ isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /**会員情報 */
    member: null as Member | null,
    /**会員ステータス */
    memberStatus: null as MemberStatus | null,
    /** e-mansion物件情報 */
    eMansionProperty: null as EMansionProperty | null,
    /** e-mansion契約基本情報 */
    customer: null as EMansionCustomer | null,
    /** 物件情報 */
    property: null as Property | null,
    /** 付加サービス　着信番号表示 表示用 */
    optionInfoNumberDisplay: null as UcomHikariPhoneOptionForDisplayOnManageDTO | null,
    /** 付加サービス　キャッチ通話 表示用 */
    optionInfoCatch: null as UcomHikariPhoneOptionForDisplayOnManageDTO | null,
    /** 付加サービス　キャッチ通話番号表示 表示用 */
    optionInfoCatchNumberDisplay: null as UcomHikariPhoneOptionForDisplayOnManageDTO | null,
    /** 付加サービス　オプションパック 表示用 */
    optionInfoOptionPack: null as UcomHikariPhoneOptionForDisplayOnManageDTO | null,
    /** 着信番号表示 チェックボックス　checked:true */
    option_1: null as null | boolean,
    /** キャッチ通話 チェックボックス　checked:true */
    option_2: null as null | boolean,
    /** キャッチ通話番号表示 チェックボックス　checked:true */
    option_3: null as null | boolean,
    /** オプションパック チェックボックス　checked:true */
    option_4: null as null | boolean,
    /** 通知先メールチェック */
    isNotifyEmail: false,
  }),
  computed: {
    // チェックボックスによる「申し込む」ボタンの判定
    isDisabledButton(): boolean {
      // メール通知チェックAPIでエラーになった場合
      if (!this.isNotifyEmail) {
        return false;
      }
      // 付与サービスが何も選択されてない場合
      if (!this.option_1 && !this.option_2 && !this.option_3 && !this.option_4) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    this.isLoading = true;

    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    try {
      this.member = await this.$store.dispatch('memberStore/member');
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];
      if (!this.memberStatus) {
        // 会員情報が取得できない場合、総合トップに遷移
        this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 物件情報を取得する
      this.property = this.$store.getters['propertyStore/property'];

      await StoreExternalApiResponse.main();
      /** 物件基本情報をStoreから取得 */
      this.eMansionProperty = this.$store.getters['eMansionCommonStore/property'];

      const property: Property = this.$store.getters['propertyStore/property'];
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });
      /** 契約基本情報を取得 */
      this.customer = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: this.member!.primaryKeyMye,
        query: findCustomerByIdQueryDto,
      });

      if (this.customer instanceof EMansionCustomer) {
        // 顧客情報に物件情報が紐付けられていなければエラーとする
        if (!this.customer.property_id) {
          // データ不整合エラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
        }

        // UCOM光電話本体のstatusが'12'でない場合、 UCOM光電話利用状況画面へ遷移
        if (this.customer.op.hikariphone[0]?.status !== String(UCOM_HIKARI_PHONE_STATUS_ON_MANSION.IN_USE)) {
          await this.$router.push('/e-mansion/hikari-phone/usage').catch((error) => {
            checkRouterError(error);
          });
          this.isLoading = false;
          return;
        }

        // 付加サービスの取得
        await this.getOptionInfo();

        // 確認画面から戻るボタン押下時のための処理
        this.option_1 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_1'];
        this.option_2 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_2'];
        this.option_3 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_3'];
        this.option_4 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_4'];

        //通知先メールチェック
        const primaryKeyMye = this.member!.primaryKeyMye;
        const apartmentId = this.property!.apartmentId;
        const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
        const uaType = this.property!.uaType;
        const chechNotifyAccount = await SpfApiAccountAccessor.getEMansionAccountNotifyEmail(
          new eMansionNotifyMailAddressCheckRequest(primaryKeyMye!, apartmentId!, servicePlanType!, uaType!)
        );
        if (chechNotifyAccount) {
          this.errorMessages.push(String(chechNotifyAccount.messages));
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
        this.isNotifyEmail = true;
      }
      this.isLoading = false;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }
  },
  methods: {
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion/hikari-phone/usage')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onApply() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      try {
        // 一旦エラーメッセージをリセットする
        this.errorMessages = [];

        // 申し込みのエラーチェックを実施する
        await this.checkboxValidateOnApply();
        // エラーメッセージがあれば表示して画面を上に移動
        if (this.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isSubmitting = false;
          return;
        }

        // 申し込むオプションをストアに入れて確認画面へ遷移
        this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_1', this.option_1);
        this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_2', this.option_2);
        this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_3', this.option_3);
        this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_4', this.option_4);

        await this.$router
          .push('/e-mansion/hikari-phone/option/application-confirm')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      } catch (error: any) {
        await VueErrorHandler.handle(error, '');
        await this.$router
          .push('/e-mansion/error')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    async onCancel(optionNum: number) {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      try {
        // 一旦エラーメッセージをリセットする
        this.errorMessages = [];

        // 申し込みのエラーチェックを実施する
        await this.checkboxValidateOnCancel(optionNum);
        // エラーメッセージがあれば表示して画面を上に移動
        if (this.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isSubmitting = false;
          return;
        }

        // 解約するオプションの番号をストアに入れる
        this.$store.commit('eMansionUcomHikariPhoneOptionCancelStore/optionNumber', optionNum);

        await this.$router
          .push('/e-mansion/hikari-phone/option/cancel-confirm')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      } catch (error: any) {
        await VueErrorHandler.handle(error, '');
        await this.$router
          .push('/e-mansion/error')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
    async getOptionInfo() {
      // 表示用データ作成
      // 配列は全4サービス分あることを想定
      if (this.customer instanceof EMansionCustomer) {
        const hikariPhoneOption: HikariphoneOption[] = this.customer.op.hikariphone[0]?.hikariphone_option;

        if (hikariPhoneOption.length !== 0) {
          // 着信番号表示
          this.optionInfoNumberDisplay = new UcomHikariPhoneOptionForDisplayOnManageDTO({
            name: '(1)' + UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.NUMBER_DISPLAY.name,
            status: undefined,
            fee: this.formatMoney(Number(this.eMansionProperty?.op.hikariphone.numberdisplay_fee.replace(/,/, ''))),
            optionNumber: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.NUMBER_DISPLAY.number,
          });
          const numberDisplayArray = hikariPhoneOption.filter((option) => option.name === 'number_display');
          if (numberDisplayArray.length === 0) {
            throw new Error('契約基本情報取得API.オプション利用状況.UCOM光電話にnumber_displayのデータがありません');
          }
          this.optionInfoNumberDisplay.status = numberDisplayArray[0].status;

          // キャッチ通話
          this.optionInfoCatch = new UcomHikariPhoneOptionForDisplayOnManageDTO({
            name: '(2)' + UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH.name,
            status: undefined,
            fee: this.formatMoney(Number(this.eMansionProperty?.op.hikariphone.catch_fee.replace(/,/, ''))),
            optionNumber: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH.number,
          });
          const catchArray = hikariPhoneOption.filter((option) => option.name === 'catch');
          if (catchArray.length === 0) {
            throw new Error('契約基本情報取得API.オプション利用状況.UCOM光電話にcatchArrayのデータがありません');
          }
          this.optionInfoCatch.status = catchArray[0].status;

          // キャッチ通話番号表示
          this.optionInfoCatchNumberDisplay = new UcomHikariPhoneOptionForDisplayOnManageDTO({
            name: '(3)' + UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH_NUMBER_DISPLAY.name,
            status: undefined,
            fee: this.formatMoney(Number(this.eMansionProperty?.op.hikariphone.catch_display_fee.replace(/,/, ''))),
            optionNumber: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH_NUMBER_DISPLAY.number,
          });
          const catchNumberDisplayArray = hikariPhoneOption.filter((option) => option.name === 'catch_number_display');
          if (catchNumberDisplayArray.length === 0) {
            throw new Error('契約基本情報取得API.オプション利用状況.UCOM光電話にcatchNumberDisplayArrayのデータがありません');
          }
          this.optionInfoCatchNumberDisplay.status = catchNumberDisplayArray[0].status;

          // オプションサービスパック
          this.optionInfoOptionPack = new UcomHikariPhoneOptionForDisplayOnManageDTO({
            name: '(4)' + UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.OPTION_PACK.name,
            status: undefined,
            fee: this.formatMoney(Number(this.eMansionProperty?.op.hikariphone.servicepack_fee.replace(/,/, ''))),
            optionNumber: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.OPTION_PACK.number,
          });
          const optionPackArray = hikariPhoneOption.filter((option) => option.name === 'option_pack');
          if (optionPackArray.length === 0) {
            throw new Error('契約基本情報取得API.オプション利用状況.UCOM光電話にoptionPackArrayのデータがありません');
          }
          this.optionInfoOptionPack.status = optionPackArray[0].status;
        }
      }
    },
    /** 解約申し込み前のチェックボックス確認
     * 　・契約基本情報取得APIから解約対象オプションのstatusを取得
     * 　   ・12:利用中 以外の場合エラーメッセージ（F12対策/通常発生しない）『お申し込み内容を再度ご確認ください。』
     */
    async checkboxValidateOnCancel(num: number) {
      // 12:利用中 以外の場合エラーメッセージ（F12対策/通常発生しない）『お申し込み内容を再度ご確認ください。』
      if (num === UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.NUMBER_DISPLAY.number) {
        if (this.optionInfoNumberDisplay?.status !== '12') {
          this.errorMessages.push('お申し込み内容を再度ご確認ください。');
        }
      } else if (num === UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH.number) {
        if (this.optionInfoCatch?.status !== '12') {
          this.errorMessages.push('お申し込み内容を再度ご確認ください。');
        }
      } else if (num === UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH_NUMBER_DISPLAY.number) {
        if (this.optionInfoCatchNumberDisplay?.status !== '12') {
          this.errorMessages.push('お申し込み内容を再度ご確認ください。');
        }
      } else if (num === UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.OPTION_PACK.number) {
        if (this.optionInfoOptionPack?.status !== '12') {
          this.errorMessages.push('お申し込み内容を再度ご確認ください。');
        }
      }

      // 解約ボタン押下時と共通処理
      await this.commonValidate('cancel', num);
    },

    /** 申し込み前のチェックボックス確認
     * 　・サービス申し込みの結果、(2)が利用なし、(3)が利用ありになる場合
     * 　・サービス申し込みの結果、(4)と、(1)(2)(3)のいずれかがどちらも利用ありになる場合
     * 　・サービス申し込みの結果、(1)(2)(3)すべてが利用ありになる場合
     * 　・何も選択されていない場合（ボタン無効化のためない想定だが、ツールで実行された場合の防止策）
     *   ・チェックボックスにチェックされたオプションの中にstatusが00:利用なし以外のものがある場合
     */
    async checkboxValidateOnApply() {
      // 何も選択されていない場合 この状態の場合は申し込むボタン無効化になるためありえないが、念のためエラーとする
      if (!this.option_1 && !this.option_2 && !this.option_3 && !this.option_4) {
        this.errorMessages.push('お申し込みいただく付加サービスにチェックを入れてください。');
      }

      // チェックボックスにチェックされたオプションの中にstatusが00:利用なし以外のものがある場合エラーメッセージ表示 ※F12対策/通常発生しない
      if (
        (this.option_1 && this.optionInfoNumberDisplay?.status !== '00') ||
        (this.option_2 && this.optionInfoCatch?.status !== '00') ||
        (this.option_3 && this.optionInfoCatchNumberDisplay?.status !== '00') ||
        (this.option_4 && this.optionInfoOptionPack?.status !== '00')
      ) {
        this.errorMessages.push('お申し込み内容を再度ご確認ください。');
      }

      // 解約ボタン押下時と共通処理
      await this.commonValidate('apply');
    },
    /** お申し込み・解約ボタン押下時の共通バリデーション
     * 　・各オプションについて、契約基本情報取得APIのstatusの値によって利用状況を以下に振り分け
     * 　　　　　・status = 00,20,21　→「利用なし」
     * 　　　　　・status = 10,11,12　→「利用あり」
     * 　・申込/解約対象のオプションについて、①の利用状況を上書き
     * 　　　　　・申込対象　→「利用あり」
     * 　　　　　・解約対象　→「利用なし」
     * 　・利用状況のチェック
     */
    async commonValidate(process: string, num?: number) {
      // 1.(1)(2)(3)(4)各オプションについて、契約基本情報取得APIのstatusの値によって利用状況を以下に振り分け
      // true:利用あり false:利用なし
      const inUseStatus = ['10', '11', '12'];
      let isUsedOnNumberDisplay = inUseStatus.includes(this.optionInfoNumberDisplay?.status!) ? true : false;
      let isUsedOnCatch = inUseStatus.includes(this.optionInfoCatch?.status!) ? true : false;
      let isUsedOnCatchNumberDisplay = inUseStatus.includes(this.optionInfoCatchNumberDisplay?.status!) ? true : false;
      let isUsedOnOptionPack = inUseStatus.includes(this.optionInfoOptionPack?.status!) ? true : false;

      // 2.申込/解約対象のオプションについて、①の利用状況を上書きする
      if (process === 'apply') {
        if (this.option_1) {
          isUsedOnNumberDisplay = true;
        }
        if (this.option_2) {
          isUsedOnCatch = true;
        }
        if (this.option_3) {
          isUsedOnCatchNumberDisplay = true;
        }
        if (this.option_4) {
          isUsedOnOptionPack = true;
        }
      } else if (process === 'cancel') {
        if (num === UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.NUMBER_DISPLAY.number) {
          isUsedOnNumberDisplay = false;
        } else if (num === UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH.number) {
          isUsedOnCatch = false;
        } else if (num === UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH_NUMBER_DISPLAY.number) {
          isUsedOnCatchNumberDisplay = false;
        } else if (num === UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.OPTION_PACK.number) {
          isUsedOnOptionPack = false;
        }
      }
      // サービス申し込み・解約結果、(2)が利用なし、(3)が利用ありになる場合
      if (!isUsedOnCatch && isUsedOnCatchNumberDisplay) {
        this.errorMessages.push(`(3)キャッチ通話番号表示をお申し込みの場合は、(2)キャッチ電話もあわせてお申し込みください。`);
        // サービス申し込みの結果、(4)と、(1)(2)(3)のいずれかがどちらも利用ありになる場合
      } else if (isUsedOnOptionPack && (isUsedOnNumberDisplay || isUsedOnCatch || isUsedOnCatchNumberDisplay)) {
        this.errorMessages.push(`(4)オプションサービスパックをお申し込みの場合は、他の付加サービスを選択しないでください。`);
        // サービス申し込みの結果、(1)(2)(3)すべてが利用ありになる場合
      } else if (isUsedOnNumberDisplay && isUsedOnCatch && isUsedOnCatchNumberDisplay) {
        this.errorMessages.push(`(1)(2)(3)の同時申し込みはできません。(4)オプションサービスパックをお申し込みください。`);
      }
    },

    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: number): string {
      const formatter = new Intl.NumberFormat('en-US');
      const formattedValue = formatter.format(billing);
      return formattedValue;
    },
  },
});
</script>
