<template>
  <div class="e-mansion-entry-connection-course">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion ご入会お申し込み</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion ご入会お申し込み</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid3 gap10">
        <li>お客様情報入力</li>
        <li class="stay">接続コース選択</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:接続コース選択 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="接続コース選択" />接続コース選択</h2>
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <div v-if="isShowInitialCost">
          <h3 class="service-h3">初期費用</h3>
          <table class="table-type2">
            <tbody>
              <tr v-if="property.em_fee_flag === '1'">
                <th>事務手数料</th>
                <td>
                  {{ property.em_fee }}円<span v-if="property.em_fee_cp" class="campaign-container"
                    ><b>→　{{ property.em_fee_cp }}円</b><span class="campaign-text">キャンペーン中！</span></span
                  >
                </td>
              </tr>
              <tr v-if="property.em_adm_construction_flag === '1'">
                <th>工事費</th>
                <td>
                  {{ property.em_adm_construction }}円<span v-if="property.em_adm_construction_cp" class="campaign-container"
                    ><b>→　{{ property.em_adm_construction_cp }}円</b><span class="campaign-text">キャンペーン中！</span></span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 class="service-h3">接続コース</h3>
        <table class="table-type2">
          <!--物件基本情報取得API.接続コース情報の要素数分表示-->
          <tbody v-for="cs in propertyCourse" :key="cs.course_id" class="course-item">
            <tr>
              <th>速度（タイプ）</th>
              <td>
                <label><input type="radio" :value="cs.course_id" v-model="selectCourse" />{{ cs.speed }}{{ convertType(cs.type) }}</label>
              </td>
            </tr>
            <tr>
              <th>接続利用料（月額）</th>
              <td v-if="property.em_payir_flag == '0'">管理組合様にお問合せください。</td>
              <td v-else-if="cs.charge == '0'">管理費とあわせてお支払いいただきます。なお、賃貸居住の方はオーナー様よりお支払いいただいております。</td>
              <td v-else>
                月額　{{ cs.charge }}円<span v-if="cs.charge_campaign" class="campaign-container"
                  ><b>→　{{ cs.charge_campaign }}円</b><span class="campaign-text">キャンペーン中！</span></span
                >
              </td>
            </tr>
            <tr v-if="isNeedAdapter(cs)">
              <th>接続機器のご利用形態</th>
              <td v-if="cs.type == '01' || cs.type == '02' || cs.type == '03' || cs.type == '04'">
                モデム　レンタル料（月額）{{ calcRentalCharge(cs) }}円<br /><br />
                <p>※モデムはご自身でご用意いただくことはできません。</p>
                <p v-if="property.damagecharge_flag == '1'">
                  ※接続機器はレンタル品のため、お客様の故意または過失による故障や破損の場合、端末機器損害金21,780円（課税対象外）を請求いたします。
                </p>
              </td>
              <td v-else-if="cs.type == '06'">
                メディアコンバーター　レンタル料（月額）{{ calcRentalCharge(cs) }} 円<br /><br />
                <p>※メディアコンバーターの取り付けは、宅内工事の際に行われます。<br />※メディアコンバーターはご自身でご用意いただくことはできません。</p>
                <p v-if="property.damagecharge_flag == '1'">
                  ※接続機器はレンタル品のため、お客様の故意または過失による故障や破損の場合、端末機器損害金21,780円（課税対象外）を請求いたします。
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          ※本サービスはベストエフォート式のサービスのため、実使用速度を保証するものではありません。速度は技術規格上での最大値であり、通信速度は時間帯・状況等により異なる場合があります。
        </p>
        <p>
          ※住戸内に設置されている機器（スイッチングハブなど）または接続する端末、ケーブル等により技術規格上の最大通信速度が変わるため、上記の通信速度(ベストエフォート)の規格にてご利用になりたい場合、機器、端末、ケーブル等をお客様にて変更いただくケースがございます。
        </p>
        <p>※住戸内設置の機器（建物所有者様、区分所有者様の所有物）の仕様については建物によって異なりますので居住者様にてご確認ください。</p>
        <p>※価格は全て新税率に基づく税込表示（消費税10％）です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
      </div>
      <div class="sblc">
        <p class="form-btn-txt">ご確認の上、よろしければ「次へ」をクリックしてください。</p>
        <div class="btn-area">
          <button type="button" class="btn-height btn btn05 bs" v-on:click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button type="button" class="btn-height btn btn01 bs" v-on:click="onNext()" :disabled="!selectCourse">次へ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /blc -->
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.new-line {
  white-space: pre-wrap;
}

.btn-height {
  height: 50px;
}

/* お申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}

.course-item {
  border-top: 4px double #d3d3d3;
}
.service-h3 {
  padding-left: 10px;
  border-left: 3px solid #cf1225;
}
.campaign-container {
  color: #ef3333;
  margin-left: 1rem;
}
.campaign-text {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
  margin-left: 0.5rem;
}
</style>

<script lang="ts">
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { Course, EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { EMansionEntryInputForm } from './classes/entry-input-form';

interface MyCourse {
  course_id: string;
  type: string;
  speed: string;
  charge: string;
  charge_campaign: string;
  cs_is_adapter: string;
}

/** ConnectionCourse コンポーネント */
export default defineComponent({
  name: 'connection-course',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    customer: {} as EMansionEntryInputForm,
    property: {} as EMansionProperty,
    isShowInitialCost: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    selectCourse: '',
    isLoading: true,
  }),
  validations: {
    selectCourse: {
      required,
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted() {
    // ログイン中のお客様情報が表示される
    try {
      // 会員情報取得処理
      const customer: EMansionEntryInputForm = this.$store.getters['eMansionEntryStore/entryInputForm'];
      this.customer = customer;
      // 物件情報の取得
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      this.property = property;
      if (!(await MountedCheckService.canReadEMansionEntryScreen()) || !this.customer || !this.property) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.isShowInitialCost = this.isShowInitialcost();

      this.selectCourse = this.isCheckedRadioButton(this.property.cs);
    } catch (e: any) {
      await VueErrorHandler.handle(e, '');
      return await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
    this.isLoading = false;
  },
  methods: {
    /**初期費用を表示するか */
    isShowInitialcost() {
      if (this.property.em_actype === '1' || this.property.em_actype === '3') {
        return false;
      }
      if (this.property.em_fee_flag === '1' || this.property.em_adm_construction_flag === '1') {
        return true;
      }
      return false;
    },
    /**
     * 接続機器が必要→true
     */
    isNeedAdapter(cs: Course) {
      return !(cs.type === '05' || (cs.type === '06' && cs.ins_kind === '01'));
    },
    /**接続コースのラジオボタンの初期値 */
    isCheckedRadioButton(csList: Course[]) {
      //ストアの値がある場合にはそれを保持する
      if (this.customer.course_id) {
        for (const cs of csList) {
          if (this.customer.course_id === cs.course_id) {
            return cs.course_id;
          }
        }
      }
      let grade;
      let course_id;
      if (this.property.em_actype === '1' || this.property.em_actype === '3') {
        for (const cs of csList) {
          if (cs.charge === '0') {
            //複数ある場合があるため、よりグレードの低い方を採用する
            if (!grade) {
              grade = cs.grade;
              course_id = cs.course_id;
            } else if (grade > cs.grade) {
              grade = cs.grade;
              course_id = cs.course_id;
            }
          }
        }
        return course_id || '';
      }
      return '';
    },
    /**利用料金を計算する */
    calcRentalCharge(cs: Course) {
      if (cs.adapter_info.length > 1) {
        this.$router.push('/e-mansion/error');
        return;
      }
      if (cs.adapter_info[0].equipmentcontract == '04') {
        return '0';
      }
      if (cs.adapter_info[0].charge_campaign) {
        return cs.adapter_info[0].charge_campaign;
      }
      return cs.adapter_info[0].charge;
    },
    /**接続方式を変換 */
    convertType(type: string | null) {
      if (type === '01' || type === '02' || type === '03' || type === '04') {
        return '（VDSL）';
      }
      if (type === '05') {
        return '（LAN）';
      }
      if (type === '06') {
        return '（FTTH）';
      }
      return '';
    },
    /** 次へボタン押下 */
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      // 入力チェック
      if (this.v$.selectCourse?.$invalid) {
        // 必須チェック
        if (this.v$.selectCourse?.required.$invalid) {
          this.errorMessages.push(`ご希望の「接続コース」にチェックを入れてください。`);
        }
      }
      if (this.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      // 入力情報をストアに保存
      this.customer.course_id = this.selectCourse;
      this.$store.commit('eMansionEntryStore/entryInputForm', this.customer);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (this.property.op.ift.flag === '1' || (this.property.op.ipp.flag === '1' && this.property.op.mplusphone.flag === '0')) {
        return await this.$router.push('/e-mansion/entry/option').catch((error: any) => {
          checkRouterError(error);
        });
      }
      //確認画面へ遷移
      return await this.$router.push('/e-mansion/entry/confirm').catch((error: any) => {
        checkRouterError(error);
      });
    },
    async onBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // 入力情報をストアに保存
      this.customer.course_id = this.selectCourse;
      this.$store.commit('eMansionEntryStore/entryInputForm', this.customer);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return await this.$router.push('/e-mansion/entry/input').catch((error: any) => {
        checkRouterError(error);
      });
    },
  },
  computed: {
    propertyCourse() {
      if (this.property.cs && this.property.cs.length > 0) {
        return this.property.cs.sort((a, b) => {
          return Number(b.grade) - Number(a.grade);
        });
      } else {
        return [];
      }
    },
  },
});
</script>
