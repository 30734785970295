<template>
  <div class="gip-completed">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion 固定グローバルIPアドレス</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li>固定グローバルIPアドレス</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="固定グローバルIPアドレス お申し込み受付完了" />固定グローバルIPアドレス お申し込み受付完了</h2>
        <p>固定グローバルIPアドレスのお申し込みありがとうございます。</p>
        <ul>
          <div class="red">
            <li>
              e-mansion インターネット接続サービスをご利用開始後に、設定いただく固定グローバルIPアドレスを発行し書面にてお知らせします。
              書面発行日が請求開始の起算日となります。キャンペーンなどが設定されている場合はそちらが適用されます。
            </li>
            <li>
              固定グローバルIPアドレスの登録が完了するまでに、20日程お時間をいただいております。（マンション内ネットワークの都合上、それ以上お時間をいただく場合がございますので予めご了承ください。）
            </li>
          </div>
          <li>ご入居前のお申し込みの場合は、ご契約マンションの「鍵のお引渡し日」から数日間お時間をいただく場合がございます。</li>
          <li>固定グローバルIPアドレスは変更できません。</li>
        </ul>
      </div>
      <!-- ご契約内容の交付方法 -->
      <ContractDetailsConfirmMethod />

      <div class="btn-area">
        <button class="btn btn01 bs" v-on:click="goTop()">e-mansion 会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import ContractDetailsConfirmMethod from '@/shared/components/e-mansion/contract-details-confirm-method.vue';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
/** Completed コンポーネント */
export default defineComponent({
  name: 'static-global-ip-completed',
  components: {
    LoadingComponent,
    ContractDetailsConfirmMethod,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
  }),
  async mounted() {
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    this.isLoading = false;
  },
  methods: {
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
