<template>
  <!-- main-contents -->
  <div class="e-mansion-index">
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>会員専用トップページ</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents" v-if="!isIspCancelled">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion 会員専用トップ</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:e-mansionからのお知らせ -->
      <div class="blc" v-if="property && property.em_info_message ? property.em_info_message.length : false">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="e-mansionからのお知らせ" />e-mansionからのお知らせ</h2>
        <!-- 表示0件の場合、【e-mansionからのお知らせ】一覧を非表示(タイトルも非表示) -->
        <div class="service-news border-grey" v-if="property && property.em_info_message ? property.em_info_message.length : false">
          <ul>
            <li class="list-none" v-for="(message, index) in emInfoMessages" :key="index">
              <span class="noticelist">{{ getKindString(message.message_kind) }}</span>
              <b>{{ message.message_date }} </b>
              <span v-if="message.message_link">
                <a :href="message.message_link" target="My e-mansion" v-html="message.message_label"></a>
              </span>
              <span v-if="!message.message_link" v-html="message.message_label"></span>
            </li>
          </ul>
        </div>
      </div>
      <!-- /blc -->

      <!-- メール会員(有償/無償) 、追加アカウントの場合はインターネット通信速度改善のメニューを提供しない -->
      <div v-if="!isMailMember && !isAdditional">
        <!-- blc:インターネット通信速度にお困りの方へ -->
        <!-- Connectix提供可能物件かつConnectix未契約の場合 -->
        <div class="blc" v-if="isApplyConnectix && !isContractConnectixFlag">
          <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="インターネット通信速度にお困りの方へ" />インターネット通信速度にお困りの方へ</h2>
          <ul class="service-col3 grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
            <li class="border-grey">
              <a href="https://speedtest.tsunagunet.com/e/" target="My e-mansion">
                <img src="../../images/ico01.png" alt="スピードテスト" class="mt20" />
              </a>
              <div class="inner">
                <p class="ttl">自宅の通信環境をチェック</p>
                <p>現在のインターネット回線の通信速度を自社グループネットワーク内の速度測定サーバでワンクリックで簡単計測‼　</p>
                <p class="txt-right">
                  <a href="https://speedtest.tsunagunet.com/e/" target="My e-mansion" class="link">スピードテストはこちら<i class="material-icons link-icon">arrow_right_alt</i></a>
                </p>
              </div>
            </li>
            <li class="border-grey">
              <a href="https://www.tsunagunet.com/internet/kaizen.html" target="My e-mansion">
                <img src="../../images/ico02.png" alt="通信の改善" class="mt20" />
              </a>
              <div class="inner">
                <p class="ttl">接続速度が遅いと感じた場合</p>
                <p>
                  モジュラージャックから接続しているLANケーブルやWi-Fiルーターなどの接続機器や、パソコンなど端末に原因がある場合があります。まずはお使いの機器を確認してみましょう！
                </p>
                <p class="txt-right">
                  <a href="https://www.tsunagunet.com/internet/kaizen.html" target="My e-mansion" class="link">
                    改善方法はこちら<i class="material-icons link-icon">arrow_right_alt</i>
                  </a>
                </p>
              </div>
            </li>
            <li class="border-grey">
              <a :href="connectixLpUrl" target="My e-mansion">
                <img src="../../images/ico03.png" alt="オプション" class="mt20" />
              </a>
              <div class="inner">
                <p class="ttl">オンライン会議や動画などもっと快適なインターネット環境を試したい方は…</p>
                <p>インターネットオプションサービス「Connectix」お申し込みのお客様専用の優先ルートで快適インターネット</p>
                <p class="txt-right">
                  <a :href="connectixLpUrl" class="link" target="My e-mansion">詳しくはこちら<i class="material-icons link-icon">arrow_right_alt</i></a>
                </p>
              </div>
            </li>
          </ul>
        </div>

        <!-- Connectix提供不可物件の場合 -->
        <div class="blc" v-else-if="!isApplyConnectix">
          <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="インターネット通信速度にお困りの方へ" />インターネット通信速度にお困りの方へ</h2>
          <ul class="service-col3 grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
            <li class="border-grey">
              <a href="https://www.tsunagunet.com/internet/kaizen_e.html" target="My e-mansion">
                <img src="../../images/ico02.png" alt="通信の改善" class="mt20" />
              </a>
              <div class="inner">
                <p class="ttl">接続速度が遅いと感じた場合</p>
                <p>
                  モジュラージャックから接続しているLANケーブルやWi-Fiルーターなどの接続機器や、パソコンなど端末に原因がある場合があります。まずはお使いの機器を確認してみましょう！
                </p>
                <p class="txt-right">
                  <a href="https://www.tsunagunet.com/internet/kaizen_e.html" target="My e-mansion" class="link"
                    >改善方法はこちら<i class="material-icons link-icon">arrow_right_alt</i></a
                  >
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /blc -->

      <!-- 各種登録情報の確認・変更 -->
      <div v-if="isRegistrationLinkBlockAvailable" class="blc">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="e-mansion 各種登録情報の確認・変更" />e-mansion　各種登録情報の確認・変更</h2>
        <div class="border-grey">
          <ul>
            <PermissionControledListLink
              v-if="!isWithdrawn"
              title="ご利用状況確認"
              :url="`${eMansionMyUrl}/login/login.php?function=INFO&apid=${apid}&rd=${rd}`"
              :functionId="functionIdLinkUsage"
              :disabled="isDefaulter"
              :tabTarget="tabTarget"
              key="list-link-usage"
            />
            <li v-if="isReadablePaymentMethod">
              <router-link class="link red" to="/e-mansion/payment-methods/register">クレジットカード情報登録変更</router-link>
            </li>
            <li v-else><p class="link disabled">クレジットカード情報登録変更</p></li>
            <PermissionControledListLink
              v-if="!isWithdrawn"
              title="会員情報変更"
              :url="`${eMansionMyUrl}/login/login.php?function=CHANGE_ALL&apid=${apid}&rd=${rd}`"
              :functionId="functionIdLinkMembersInfoChange"
              :disabled="isDefaulter"
              :tabTarget="tabTarget"
              key="list-link-member-info"
            />
            <PermissionControledListLink
              v-if="isContractLinkAvailable"
              title="契約内容のご案内"
              :url="`${eMansionMyUrl}/login/login.php?function=CONTRACT_DOC_INFO&apid=${apid}&rd=${rd}`"
              :functionId="functionIdLinkContractContentGuidance"
              :disabled="isDefaulter"
              :tabTarget="tabTarget"
              key="list-link-contract-content"
            />
            <PermissionControledListLink
              title="ご請求明細・販売証明書表示"
              :url="`${eMansionMyUrl}/login/login.php?function=INVOICE_DETAIL_INFO&apid=${apid}&rd=${rd}`"
              :functionId="functionIdLinkBillingItemSalesCertificateDisplay"
              :disabled="isDefaulter"
              :tabTarget="tabTarget"
              key="list-link-billing"
            />
            <PermissionControledListLink
              title="e-mansion お知らせメールの配信設定"
              :url="`${eMansionMyUrl}/login/login.php?function=INVOICE_NOTICE&apid=${apid}&rd=${rd}`"
              :functionId="functionIdLinkNewsEmailDeliveryPreference"
              :disabled="isDefaulter"
              :tabTarget="tabTarget"
              key="list-link-news-mail"
            />
            <PermissionControledListLink
              v-if="!isWithdrawn"
              title="ISP会員契約の解除"
              :url="`${eMansionMyUrl}/login/login.php?function=DISSOLUTION&apid=${apid}&rd=${rd}`"
              :functionId="functionIdLinkIspMemberContractCancellation"
              :disabled="isDefaulter"
              :tabTarget="tabTarget"
              key="list-link-isp-cancel"
            />
          </ul>
        </div>
      </div>

      <!-- blc: オプションサービスについての説明 -->
      <!-- 以下メインアカウント、追加アカウントの両方で表示 -->
      <!-- <PermissionControledSpanLink
          sentence="e-mansion 各種オプションサービスのお申し込み・変更は "
          linkTitle="こちら"
          alt="オプションサービスのお申し込み・変更"
          :tabTarget="tabTarget"
          :url="optionServiceUrl"
          :functionId="functionIdLinkOptionService"
          :disabled="isDefaulter"
        /> -->
      <!-- /blc -->

      <!-- blc:Connectix お申し込み・解約 -->
      <div v-if="!isAdditional && !isWithdrawn">
        <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="e-mansion オプションサービスのお申し込み・解約" />e-mansion オプションサービスのお申し込み・解約</h2>
        <div class="blc" v-if="isApplyConnectix && !isApplyCancelIsp && !isWithdrawn">
          <h3 class="service-h3" v-if="!isDefaulter">
            Connectix（<a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/connectix/`" class="link" target="_blank">サービス詳細</a>）
          </h3>
          <h3 class="service-h3" v-else-if="isDefaulter">Connectix（<span class="disabled underlined">サービス詳細</span>）</h3>

          <!-- 通常/スリムプランで出し分け -->
          <!-- 通常 -->
          <!-- <ul class="service-col4 grid pc-grid4 sp-grid2 pc-gap30 sp-gap10" v-if="!isSlimPlan">
          <li class="br10 shadow">
            <img src="../../images/e-mansion/ico04.svg" alt="" />
            <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/bbp00/`" target="My e-mansion">インターネット接続<br>コース変更</a></p>
            <div class="service-btn grid grid1 gap10">
              <a :href="`${eMansionMyUrl}/login/login.php?function=COURSECHG&amp;apid=${apid}&amp;rd=${rd}`" target="My e-mansion">お申し込み</a>
            </div>
          </li>
          <li class="br10 shadow">
            <img src="../../images/e-mansion/ico05.svg" alt="迷惑メールブロック" />
            <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/bsp02/`" target="My e-mansion">迷惑メールブロック</a></p>
            <div class="service-btn grid grid1 gap10">
              <a :href="`${eMansionMyUrl}/login/login.php?function=MAILBLOCK&amp;apid=${apid}&amp;rd=${rd}`" target="My e-mansion">設定</a>
            </div>
          </li>
          <li class="br10 shadow">
            <img src="../../images/e-mansion/ico06.svg" alt="追加メールアカウント" />
            <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/bmp00/`" target="My e-mansion">追加メールアカウント</a></p>
            <div class="service-btn grid grid1 gap10">
              <a :href="`${eMansionMyUrl}/login/login.php?function=ACCOUNT&amp;apid=${apid}&amp;rd=${rd}`" target="My e-mansion">お申し込み</a>
            </div>
          </li>
          <li class="br10 shadow">
            <img src="../../images/e-mansion/ico07.svg" alt="メール転送" />
            <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/bmp06/`" target="My e-mansion">メール転送</a></p>
            <div class="service-btn grid grid1 gap10">
              <a :href="`${eMansionMyUrl}/login/login.php?function=MAILFORWARD&amp;apid=${apid}&amp;rd=${rd}`" target="My e-mansion">設定</a>
            </div>
          </li>
          <li class="br10 shadow">
            <img src="../../images/e-mansion/ico08.svg" alt="ホームページ開設" />
            <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/bhp01/`" target="My e-mansion">ホームページ開設</a></p>
            <div class="service-btn grid grid1 gap10">
              <a :href="`${eMansionMyUrl}/login/login.php?function=HOMEPAGE&amp;apid=${apid}&amp;rd=${rd}`" target="My e-mansion">お申し込み</a>
            </div>
          </li>
          <li class="br10 shadow">
            <img src="../../images/e-mansion/ico09.svg" alt="メール自動応答" />
            <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/bmp07/`" target="My e-mansion">メール自動応答</a></p>
            <div class="service-btn grid grid1 gap10">
              <a :href="`${eMansionMyUrl}/login/login.php?function=MAILAUTORESPONSE&amp;apid=${apid}&amp;rd=${rd}`" target="My e-mansion">設定</a>
            </div>
          </li>
        </ul> -->
          <!-- スリムプラン -->
          <!-- <ul class="service-col4 grid pc-grid4 sp-grid2 pc-gap30 sp-gap10" v-if="isSlimPlan">
          <li class="br10 shadow">
            <img src="../../images/e-mansion/ico06.svg" alt="メールパック" />
            <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/sbm00/`" target="My e-mansion">メールパック</a></p>
          </li>
          <li class="br10 shadow">
            <img src="../../images/e-mansion/ico05.svg" alt="迷惑メールブロック" />
            <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/sbm00/#anc07`" target="My e-mansion">迷惑メールブロック</a></p>
            <div class="service-btn grid grid1 gap10">
              <a :href="`${eMansionMyUrl}/login/login.php?function=MAILBLOCK&amp;apid=${apid}&amp;rd=${rd}`" target="My e-mansion">設定</a>
            </div>
          </li>
          <li class="br10 shadow">
            <img src="../../images/e-mansion/ico07.svg" alt="メール転送" />
            <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/sbm00/#anc05`" target="My e-mansion">メール転送</a></p>
            <div class="service-btn grid grid1 gap10">
              <a :href="`${eMansionMyUrl}/login/login.php?function=MAILFORWARD&amp;apid=${apid}&amp;rd=${rd}`" target="My e-mansion">設定</a>
            </div>
          </li>
          <li class="br10 shadow">
            <img src="../../images/e-mansion/ico09.svg" alt="メール自動応答" />
            <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/sbm00/#anc04`" target="My e-mansion">メール自動応答</a></p>
            <div class="service-btn grid grid1 gap10">
              <a :href="`${eMansionMyUrl}/login/login.php?function=MAILAUTORESPONSE&amp;apid=${apid}&amp;rd=${rd}`" target="My e-mansion">設定</a>
            </div>
          </li>
        </ul> -->
          <!-- <div class="sblc"> -->
          <!-- <h3 class="service-h3">オプションサービス</h3> -->
          <!-- 価格表示は桁区切りされたものをAPIから受け取る -->
          <!-- 通常/スリムプランで出し分け -->
          <!-- 通常 -->
          <div class="table-scroll js-scrollable" v-if="!isSlimPlan">
            <table v-if="customer && property" class="table">
              <tr>
                <th colspan="2">サービス</th>
                <th>料金</th>
                <th></th>
              </tr>
              <!-- Connectix表示条件
                Connectix対象物件であること-->
              <tr>
                <td colspan="2">
                  <p>Connectix</p>
                </td>
                <td v-if="!property.op.sdwan.fee_cp">{{ property.op.sdwan.fee }}円/月</td>
                <td v-else>
                  <font color="red">キャンペーン中</font><br />
                  {{ property.op.sdwan.fee }}円/月
                  <font color="red"
                    ><br />
                    → {{ property.op.sdwan.fee_cp }}円／月
                  </font>
                </td>
                <td>
                  <div v-if="!isDefaulter" class="service-btn grid gap10">
                    <!-- Connectix契約/未契約でリンクを出し分ける -->
                    <!-- 契約済み -->
                    <a v-if="isContractConnectixFlag" :href="`${eMansionMyUrl}/login/login.php?function=SDWAN&apid=${apid}&rd=${rd}`" target="My e-mansion">ご利用状況確認</a>
                    <!-- 未契約 -->
                    <router-link v-else to="/connectix/e-mansion/terms" target="My e-mansion">サービス詳細・お申し込み </router-link>
                  </div>
                  <div v-if="isDefaulter" class="service-btn grid gap10">
                    <span v-if="isContractConnectixFlag" class="disabled">ご利用状況確認</span>
                    <span v-else class="disabled">サービス詳細・お申し込み</span>
                  </div>
                </td>
              </tr>
              <!-- ご利用状況確認（リンクがConnectixと同じになっているので要確認 -->
            </table>
            <p class="att grey">
              ※価格は（税抜）の記載がないものは全て、新税率に基づく税込表示（消費税10%）です。今後消費税が改正された場合は、改正後の税率による価格に変更となります。
            </p>
          </div>

          <!-- スリムプラン -->
          <div class="table-scroll js-scrollable" v-else>
            <table v-if="customer && property" class="table">
              <tr>
                <th colspan="2">サービス</th>
                <th>料金</th>
                <th></th>
              </tr>
              <!-- Connectix表示条件
                              Connectix対象物件であること-->
              <tr>
                <td colspan="2">
                  <p>Connectix</p>
                </td>
                <td v-if="!property.op.sdwan.fee_cp">{{ property.op.sdwan.fee }}円/月</td>
                <td v-else>
                  <font color="red">キャンペーン中</font><br />{{ property.op.sdwan.fee }}円/月
                  <font color="red"
                    ><br />
                    → {{ property.op.sdwan.fee_cp }}円／月</font
                  >
                </td>
                <td>
                  <div v-if="!isDefaulter" class="service-btn grid gap10">
                    <!-- Connectix契約/未契約でリンクを出し分ける -->
                    <!-- 契約済み -->
                    <a v-if="isContractConnectixFlag" :href="`${eMansionMyUrl}/login/login.php?function=SDWAN&apid=${apid}&rd=${rd}`" target="My e-mansion">ご利用状況確認</a>
                    <!-- 未契約 -->
                    <router-link v-else to="/connectix/e-mansion/terms" target="My e-mansion"> サービス詳細・お申し込み </router-link>
                  </div>
                  <div v-if="isDefaulter" class="service-btn grid gap10">
                    <span v-if="isContractConnectixFlag" class="disabled">ご利用状況確認</span>
                    <span v-else class="disabled">サービス詳細・お申し込み</span>
                  </div>
                </td>
              </tr>
              <!-- ご利用状況確認（リンクがConnectixと同じになっているので要確認 -->
            </table>
            <p class="att grey">
              ※価格は（税抜）の記載がないものは全て、新税率に基づく税込表示（消費税10%）です。今後消費税が改正された場合は、改正後の税率による価格に変更となります。
            </p>
          </div>
          <!-- </div> -->
        </div>
        <!-- /blc -->
        <!-- blc -->
        <div class="blc">
          <div v-if="isIPP || isHikariPhone || isMPlusPhone">
            <h3 class="service-h3">IP電話</h3>
            <div class="service-menu">
              <ul>
                <li class="list-none" v-if="isIPP && !isDefaulter">
                  e-mansion IPフォン（
                  <a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/ipdenwa/e-ipphone.html`" class="link" target="_blank">サービス詳細</a>
                  / <span v-if="!ippContractStatus"><router-link to="/e-mansion/ip-phone/terms" class="link">お申し込み</router-link></span>
                  <span v-else><a :href="`${eMansionMyUrl}/login/login.php?function=IPPHONE&apid=${apid}&rd=${rd}`" class="link" target="_blank">ご利用状況確認</a></span>
                  ）
                </li>
                <li class="list-none" v-if="isIPP && isDefaulter">
                  e-mansion IPフォン（
                  <span class="disabled underlined">サービス詳細</span>
                  / <span v-if="!ippContractStatus" class="disabled underlined">お申し込み</span>
                  <span v-else class="disabled underlined">ご利用状況確認</span>
                  ）
                </li>

                <li class="list-none" v-if="isHikariPhone && !isDefaulter">
                  UCOM光電話（<a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/ipdenwa/hikaridenwa.html`" class="link" target="_blank"
                    >サービス詳細</a
                  >
                  /
                  <span v-if="!hphoneContractStatus"><router-link to="/e-mansion/hikari-phone/service-terms" class="link">お申し込み</router-link></span>
                  <span v-else><router-link to="/e-mansion/hikari-phone/usage" class="link">ご利用状況確認</router-link></span>
                  ）
                </li>
                <li class="list-none" v-if="isHikariPhone && isDefaulter">
                  UCOM光電話（<span class="disabled underlined">サービス詳細</span>
                  /
                  <span v-if="!hphoneContractStatus" class="disabled underlined">お申し込み</span>
                  <span v-else class="disabled underlined">ご利用状況確認</span>
                  ）
                </li>

                <li class="list-none" v-if="isMPlusPhone && !isDefaulter">
                  マンションプラス電話（<a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/ipdenwa/manplusdenwa/`" class="link" target="_blank"
                    >サービス詳細・お申し込み・解約</a
                  >）
                </li>
                <li class="list-none" v-if="isMPlusPhone && isDefaulter">マンションプラス電話（<span class="disabled underlined">サービス詳細・お申し込み・解約</span>）</li>
              </ul>
            </div>
          </div>

          <div v-if="isGIP">
            <h3 class="service-h3">固定グローバルIP</h3>
            <div class="service-menu">
              <ul>
                <li class="list-none" v-if="isGIP && !isDefaulter">
                  固定グローバルIPアドレス（<a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/gip/`" class="link" target="_blank"
                    >サービス詳細</a
                  >
                  /
                  <span v-if="!gipContractStatus"><router-link to="/e-mansion/static-gip/terms" class="link">お申し込み</router-link></span>
                  <span v-else><router-link to="/e-mansion/static-gip/usage" class="link">ご利用状況確認</router-link></span>
                  ）
                </li>

                <li class="list-none" v-if="isGIP && isDefaulter">
                  固定グローバルIPアドレス（<span class="disabled underlined">サービス詳細</span>
                  /
                  <span v-if="!gipContractStatus" class="disabled underlined">お申し込み</span>
                  <span v-else class="disabled underlined">ご利用状況確認</span>
                  ）
                </li>
              </ul>
            </div>
          </div>

          <div v-if="(isIFT || isISW || isMd360 || isNIS || is360) && !isMailChargeMember">
            <h3 class="service-h3">セキュリティーサービス</h3>
            <div class="service-menu">
              <ul>
                <li class="list-none" v-if="isIFT && !isDefaulter">
                  i-フィルター（<a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/security/ifilter.html`" class="link" target="_blank"
                    >サービス詳細</a
                  >
                  /
                  <span v-if="!iftContractStatus"><router-link to="/e-mansion/i-filter/terms" class="link">お申し込み</router-link></span>
                  <span v-else><router-link to="/e-mansion/i-filter/usage" class="link">ご利用状況確認</router-link></span>
                  ）
                </li>
                <li class="list-none" v-if="isIFT && isDefaulter">
                  i-フィルター（<span class="disabled underlined">サービス詳細</span>
                  /
                  <span v-if="!iftContractStatus" class="disabled underlined">お申し込み</span>
                  <span v-else class="disabled underlined">ご利用状況確認</span>
                  ）
                </li>

                <li class="list-none" v-if="isISW && !isDefaulter">
                  Internet SagiWall for マルチデバイス（<a
                    :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/security/sagiwall.html`"
                    class="link"
                    target="_blank"
                    >サービス詳細</a
                  >
                  / <a :href="`${eMansionMyUrl}/login/login.php?function=ISW&apid=${apid}&rd=${rd}`" class="link" target="_blank">ご利用状況確認</a>）
                </li>
                <li class="list-none" v-if="isISW && isDefaulter">
                  Internet SagiWall for マルチデバイス（<span class="disabled underlined">サービス詳細</span> / <span class="disabled underlined">ご利用状況確認</span>）
                </li>

                <li class="list-none" v-if="isMd360 && !isDefaulter">
                  マルチデバイスセキュリティ with Norton360 Online（<a
                    :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/security/multidevice.html`"
                    class="link"
                    target="_blank"
                    >サービス詳細</a
                  >
                  / <a :href="`${eMansionMyUrl}/login/login.php?function=MD_N360&apid=${apid}&rd=${rd}`" class="link" target="_blank">ご利用状況確認</a>）
                </li>
                <li class="list-none" v-if="isMd360 && isDefaulter">
                  マルチデバイスセキュリティ with Norton360 Online（<span class="disabled underlined">サービス詳細</span> /
                  <span class="disabled underlined">ご利用状況確認</span>）
                </li>

                <li class="list-none" v-if="isNIS && !isDefaulter">
                  Norton Internet Security オンライン（<a
                    :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/security/nis.html`"
                    class="link"
                    target="_blank"
                    >サービス詳細</a
                  >
                  / <a :href="`${eMansionMyUrl}/login/login.php?function=INTERNET_SECURITY&apid=${apid}&rd=${rd}`" class="link" target="_blank">ご利用状況確認</a>）
                </li>
                <li class="list-none" v-if="isNIS && isDefaulter">
                  Norton Internet Security オンライン（<span class="disabled underlined">サービス詳細</span> / <span class="disabled underlined">ご利用状況確認</span>）
                </li>

                <li class="list-none" v-if="is360 && !isDefaulter">
                  Norton 360 オンライン（<a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/security/n360.html`" class="link" target="_blank"
                    >サービス詳細</a
                  >
                  / <a :href="`${eMansionMyUrl}/login/login.php?function=NORTON360&apid=${apid}&rd=${rd}`" class="link" target="_blank">ご利用状況確認</a>）
                </li>
                <li class="list-none" v-if="is360 && isDefaulter">
                  Norton 360 オンライン（<span class="disabled underlined">サービス詳細</span> / <span class="disabled underlined">ご利用状況確認</span>）
                </li>
              </ul>
            </div>
          </div>
          <div v-if="isCourseChange">
            <h3 class="service-h3">インターネット接続サービス</h3>
            <div class="service-menu">
              <ul>
                <li class="list-none" v-if="isCourseChange && !isDefaulter">
                  コース変更（<a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/service/internet.html`" class="link" target="_blank">サービス詳細</a> /
                  <router-link to="/e-mansion/course/input" class="link">お申し込み</router-link>）
                </li>
                <li class="list-none" v-if="isCourseChange && isDefaulter">
                  コース変更（<span class="disabled underlined">サービス詳細</span> / <span class="disabled underlined">お申し込み</span>）
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- /blc -->
      <!-- blc: メールサービスご利用状況 -->
      <div class="blc">
        <!-- スリムプランで退会登録中は表示しない（サブのリンク項目が一つもない）-->
        <h3 class="service-h3" v-if="!(isUserSlimNotSpecialPlan && isWithdrawn)">
          メールサービス（
          <a class="service-detail-link link" v-if="!isDefaulter" :href="mailServiceUrl" target="My e-mansion"><span>サービス詳細</span></a>
          <span v-if="isDefaulter" class="disabled underlined">サービス詳細</span>
          ）
        </h3>
        <!-- 滞納か会員単位.メールアドレス無の場合はグレーアウト -->

        <PermissionControledRouterLink
          v-if="!isWithdrawn"
          class="service-item-link"
          to="/e-mansion/mail/usage"
          title="メールサービスご利用状況"
          :functionId="functionIdMailUsage"
          :disabled="isDefaulter || !hasMailAddressAsMember"
          description="メールサービスご利用状況確認・ウイルスチェックお申し込みはこちらから"
          key="router-link-mail-usage"
        />

        <!-- ログインユーザメールアドレス有無が false なら to を "/e-mansion/main-mailaddress/input" にする -->
        <!-- 滞納はグレーアウト -->

        <PermissionControledRouterLink
          v-if="hasMainMailAddressAsMember && !isWithdrawn"
          class="service-item-link"
          to="/e-mansion/main-mail-address/list"
          title="メインメールアドレスご利用状況"
          :functionId="functionIdMainMail"
          :disabled="isDefaulter"
          description="メインメールアドレスのご利用状況確認・お申し込み・変更・解約・設定情報確認はこちらから"
          key="router-link-main-mail-list"
        />
        <PermissionControledRouterLink
          v-else-if="!hasMainMailAddressAsMember && !isWithdrawn"
          class="service-item-link"
          to="/e-mansion/main-mail-address/input"
          title="メインメールアドレスご利用状況"
          :functionId="functionIdMainMail"
          :disabled="isDefaulter"
          description="メインメールアドレスお申し込み・変更・解約はこちら"
          key="router-link-main-mail-input"
        />

        <!-- 滞納かログインユーザ_メールアドレス無はグレーアウト -->
        <PermissionControledRouterLink
          class="service-item-link"
          to="/e-mansion/mail-password/input"
          title="メールパスワード変更"
          :functionId="functionIdMailPassword"
          :disabled="isDefaulter || !hasMailAddressAsMember || !canChangeMailPassword"
          description="ログイン中アカウントのメールパスワード変更はこちら"
          key="router-link-mail-password"
        />

        <!-- 滞納かログインユーザ_メールアドレス無はグレーアウト -->
        <PermissionControledMenuLink
          class="service-item-link"
          title="WEBメール"
          :functionId="functionIdWebMail"
          :disabled="isDefaulter || !hasMailAddressAsMember || !canUseWebMail"
          tabTarget="_self"
          url="javascript:void(0)"
          @onClick="webMailLogin"
          description="迷惑メールブロック・自動転送などの設定もWEBメール画面で行えます"
          key="router-link-web-mail"
        />
        <div v-if="isUserSlimPlan">
          <!-- メールパックという名前だけど遷移先は追加アカウントと同じ -->
          <PermissionControledRouterLink
            v-if="!isWithdrawn"
            class="service-item-link"
            to="/e-mansion/sub-account/list"
            title="メールパック"
            :functionId="functionIdMailPack"
            :disabled="isDefaulter"
            description="メールパックのお申し込み・解約はこちら"
            key="router-link-mail-pack"
          />
        </div>
        <div v-else>
          <PermissionControledRouterLink
            v-if="!isWithdrawn"
            class="service-item-link"
            to="/e-mansion/sub-account/list"
            title="追加アカウント"
            :functionId="functionIdAddAccount"
            :disabled="isDefaulter"
            description="追加メールアドレスのお申し込み・解約・設定情報確認はこちらから"
            key="router-link-sub-account"
          />
        </div>
      </div>
      <!-- /blc -->

      <!-- blc: ホームページサービスご利用状況 -->
      <div v-if="isHomePageServiceBlockAvailable && !isWithdrawn" class="blc">
        <h3 class="service-h3">
          ホームページサービス （
          <a class="service-detail-link link" v-if="!isDefaulter" :href="homepageServiceUrl" target="My e-mansion"><span>サービス詳細</span></a>
          <span v-if="isDefaulter" class="disabled underlined">サービス詳細</span>
          ）
        </h3>

        <!-- ログインユーザ_ホームページ有無が true なら to を (SE05-005_ホームページ：ご利用状況／ご解約) のパスにする -->
        <!-- ログインユーザ_ホームページ有無が false なら to を (SE05-002_ホームページ：お申し込み) のパスにする -->

        <PermissionControledRouterLink
          v-if="hasHomepageAsLoginUser"
          class="service-item-link"
          to="/e-mansion/homepage/usage"
          title="ホームページサービスご利用状況"
          :functionId="functionIdHp"
          :disabled="isDefaulter"
          description="ご利用状況確認・ホームページ開設お申し込みはこちら"
          key="router-link-homepage-usage"
        />
        <PermissionControledRouterLink
          v-if="!hasHomepageAsLoginUser"
          class="service-item-link"
          to="/e-mansion/homepage/application"
          title="ホームページサービスご利用状況"
          :functionId="functionIdHp"
          :disabled="isDefaulter"
          description="ご利用状況確認・ホームページ開設お申し込みはこちら"
          key="router-link-homepage-application"
        />
        <!-- 滞納中かどうかに加え、ホームページ容量表示有無により disabled を切替える -->

        <PermissionControledRouterLink
          class="service-item-link"
          to="/e-mansion/homepage-capacity/list"
          title="ホームページ容量変更"
          :functionId="functionIdHpVolume"
          :disabled="isDefaulter || !isOpenedHomepage"
          key="router-link-homepage-capacity"
        />
      </div>
      <!-- /blc -->

      <!-- blc:サポート情報 -->
      <!-- <div class="blc">
              <h2 class="portal-h2 cf">
                <img src="../../images/service-h2.svg" alt="サポート情報" />
                サポート情報
              </h2>
              <ul class="service-col3 grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
                <li class="border-grey">
                  <img src="../../images/e-mansion/ico12.png" alt="よくある質問" />
                  <div class="inner">
                    <p class="ttl"><a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/ctp11/`" target="My e-mansion">よくある質問</a></p>
                    <p>FAQが使いやすくなりました。検索機能も充実</p>
                    <p class="txt-right">
                      <a :href="`${eMansionMyUrl}/login/login.php?em_redirect=${eMansionUrl}/${apid}/ctp11/`" target="My e-mansion" class="link"
                        >詳しくはこちら<i class="material-icons link-icon">arrow_right_alt</i></a
                      >
                    </p>
                  </div>
                </li>
              </ul>
              <p class="att grey support-notes">
                ※2022年5月時点ではPortasにログインできるのはe-mansionメインアカウントになります。<br />
                追加アカウントにつきましては、e-mansionサイト（
                <a :href="`${eMansionUrl}`" class="link" target="My e-mansion">{{eMansionUrl}}</a>
                ）からログインをお願いいたします。
              </p>
            </div> -->
      <!-- /blc -->

      <!-- blc:お住まいの建物専用ホームページ -->
      <div class="blc">
        <p>e-mansionインターネットサービスについて、サービス内容やご利用方法、お問い合わせ先は、お住まいの建物専用ホームページをご覧ください。</p>
        <p class="ttl">
          <a :href="apartmentServiceUrl" class="link" target="My e-mansion">お住まいの建物専用ホームページはこちら</a>
        </p>
      </div>
    </div>

    <!-- e-mansion退会お申し込み中の表示 -->
    <div class="contents" v-else>
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion 会員専用トップ</li>
      </ul>
      <!-- /breadcrumb -->

      <div class="blc isp-apply-cancel">
        <p>ご請求明細・販売証明書はこちらからご確認ください</p>
        <p class="description"><a :href="`${billingDetailsURL}?function=INVOICE_DETAIL_INFO&apid=${apid}&rd=${rd}`" target="My e-mansion" class="link">My e-mansion</a></p>
        <p class="description">※ e-mansion退会後、13か月間はPortasにログインし、ご請求明細をご確認いただけます。</p>
      </div>

      <!-- blc:お住まいの建物専用ホームページ -->
      <div class="blc">
        <p>e-mansionインターネットサービスについて、サービス内容やご利用方法、お問い合わせ先は、お住まいの建物専用ホームページをご覧ください。</p>
        <p class="ttl">
          <a v-if="!isDefaulter" :href="apartmentServiceUrl" class="link" target="My e-mansion">お住まいの建物専用ホームページはこちら</a>
          <span v-if="isDefaulter" class="disabled underlined">お住まいの建物専用ホームページはこちら</span>
        </p>
      </div>
    </div>
    <!-- /contents -->

    <footer class="emansion-footer">
      <div class="copy">
        <img src="../../images/tunagunet_logo.png" alt="" class="emansion-footer-logo" />
      </div>
    </footer>
  </div>
  <!-- /main-contents -->
</template>

<style lang="scss" scoped>
/* underlayer-main
---------------------------------------------------- */
.underlayer-main {
  background-image: url('../../images/e-mansion/main.png');
}

.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../images/logo-emansion.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

/* service-news */
.service-news {
  background-color: #fcfcfc;
  padding: 0 30px 20px;
  margin: 0;
}

.service-news ul {
  padding: 0;
}

.service-news ul li {
  padding: 15px 0 15px 20px;
  border-bottom: 1px solid #cf1225;
  position: relative;
}

.service-news ul li::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border: 6px solid transparent;
  border-left: 10px solid #cf1225;
}

.noticelist {
  justify-content: center;
  text-align: center;
  display: inline-block;
  min-width: 100px;
  padding: 5px;
  margin: 0 10px 0 0;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  color: #cf1225;
  background: #fdeaec;
  font-weight: 500;
}

.email-authentication-key-error {
  margin-top: -5px;
}

.portal-h2 a {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  margin-left: 0px;
}

::v-deep(.portal-h2-exclusion) {
  font-size: 24px;
  display: flex;
}

::v-deep(.portal-h2-exclusion img) {
  position: relative;
  top: -0.125em;
  width: auto;
  height: 1.2em;
  margin-right: 0.3em;
}

.arrow-icon {
  position: relative;
  top: -5px;
}

/* service-top
---------------------------------------------------- */

/* col3 */
.service-col3 {
  padding: 0;
  margin: 25px 0 0;
}

.service-col3 li {
  list-style: none;
}

.service-col3 li .inner {
  padding: 10px 30px 20px;
}

.service-col3 li img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.service-col3 li .ttl {
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 10px;
}

/* col4 */
.service-col4 {
  padding: 0;
}

.service-col4 li {
  padding: 20px;
  list-style: none;
}

.service-col4 li img {
  display: block;
  margin: 0 auto 10px;
}

.service-col4 li .ttl {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 10px;
  min-height: 50px;
}

.service-col4 li .service-btn {
  font-size: 0;
}

.service-col4 li .service-btn a {
  font-size: 14px;
}

.support-notes {
  margin-top: 10px;
}

/* table */
.table {
  margin: 40px 0 10px;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #dee2e6;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table th {
  background-color: #fbfbfb;
  text-align: left;
}

.table td .service-btn {
  text-align: right;
  float: right;
  width: 100%;
  font-size: 13px;
}

.table td .service-purchased {
  color: #cf1225;
}

/* authentication-key */
.authentication-key {
  padding: 30px;
  margin: 15px 0 0;
  border: 1px solid #e8e8e8;
  background-color: #fcfcfc;
}

.completed-authentication-key {
  padding: 15px 30px;
  margin: 15px 0 0;
  border: 1px solid #e8e8e8;
  background-color: #fcfcfc;
}

.input {
  margin: 10px 0;
}

.input .text {
  width: 420px;
  margin: -10px 10px 0 0;
}

.input .btn {
  position: relative;
  top: 2px;
}

.div.table-scroll {
  position: relative;
  overflow: auto;
}

.banner-pc {
  display: block;
  width: 100%;
}

.banner-sp {
  display: none;
}

div.blc {
  &.isp-apply-cancel {
    & p.description {
      text-indent: 1em;
    }
  }
}

.service-menu {
  background-color: #fcfcfc;
  padding: 0 30px 20px;
  margin: 0;

  & ul {
    padding: 0;

    & li {
      padding: 5px 0 5px 20px;
      position: relative;
      font-size: 15px;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        content: '';
        box-sizing: border-box;
        width: 4px;
        height: 4px;
        border: 3px solid transparent;
        border-left: 5px solid #cf1225;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  /* underlayer-main
  ---------------------------------------------------- */
  .underlayer-main {
    padding: 49px 20px;
  }

  .underlayer-main h1 {
    padding: 10px 0 10px 120px;
    background-size: 110px auto;
  }

  /* service-top
  ---------------------------------------------------- */

  /* col3 */
  .service-col3 li .inner {
    padding: 10px 15px 20px;
  }

  /* col4 */
  .service-col4 li {
    padding: 10px;
  }

  .service-col4 li img {
    height: 45px;
  }

  .service-col4 li .ttl {
    font-size: 16px;
    line-height: 22px;
  }

  .service-col4 li .service-btn a {
    font-size: 12px;
  }

  /* table */
  .table {
    margin-top: 0;
    width: 100%;
    border-collapse: collapse;
  }

  .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  .table th {
    background-color: #fbfbfb;
    text-align: left;
  }

  .table td .service-btn {
    text-align: right;
    float: right;
    width: 260px;
    font-size: 13px;
  }

  .table-scroll table {
    width: 780px;
  }

  /* authentication-key */
  .authentication-key,
  .completed-authentication-key {
    padding: 15px;
  }

  .input .text {
    width: 60%;
  }

  .input .btn {
    min-width: 25%;
  }

  ::v-deep(.portal-h2-exclusion) {
    font-size: 18px;
    display: block;
  }

  .portal-h2-exclusion img {
    top: 0.1em;
  }

  .banner-pc {
    display: none;
  }

  .banner-sp {
    display: block;
    width: 100%;
  }
}

/* emansion-footer */
.emansion-footer {
  padding: 10px 0 10px;
  border-top: 1px solid #e6e6e6;
}

.emansion-footer ul {
  text-align: center;
  padding: 0;
}

.emansion-footer ul li {
  margin: 0 5px;
  display: inline-block;
}

.emansion-footer ul li a {
  color: #292929;
  text-decoration: none;
}

.emansion-footer ul li a img {
  width: 100%;
  height: 150px;
  padding: 10px 0 20px;
}

.emansion-footer ul li a .material-icons {
  position: relative;
  top: 0.2em;
  font-size: 1.2em;
}

.emansion-footer ul li a:hover {
  color: #cf1225;
  text-decoration: underline;
}

.emansion-footer .copy {
  text-align: center;
  font-size: 10px;
  margin-top: 20px;
  color: #707070;
}

.emansion-footer .copy .emansion-footer-logo {
  width: 250px;
  padding: 10px 0 20px;
}

.service-detail-link {
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .service-detail-link {
    margin-top: 20px;
    position: static;
    left: 0;
    margin: 20px 0.5em 0.5em 0.5em;
  }
}

.service-item-link {
  margin-left: 1em;
}

.disabled {
  color: #bdbdbd;
  text-decoration-color: #bdbdbd;
}

.underlined {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.service-btn span.disabled {
  font-size: 13px;
  border: 1px solid #bdbdbd;
  text-decoration: none;
  text-align: center;
  border-radius: 3px;
}
</style>

<script lang="ts">
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionEmailAuthenticationKeyRequest, EMansionEmailAuthenticationKeyRequestResult } from '@/shared/classes/external-api/e-mansion/customer-request';
import { EMansionCustomer, EMansionEmailAuthenticationKeyResponse } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty, EmInfoMessage } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionRoomsDetail, EMansionRoomsResponseResult } from '@/shared/classes/external-api/e-mansion/rooms-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { EMansionMailAddress } from '@/shared/classes/spf-api/mail/model/mail-address/e-mansion-mail-address';
import { Member } from '@/shared/classes/spf-api/member';
import PermissionControledListLink from '@/shared/components/permission-controled-list-link.vue';
import PermissionControledMenuLink from '@/shared/components/permission-controled-menu-link.vue';
import PermissionControledRouterLink from '@/shared/components/permission-controled-router-link.vue';
import PermissionControledSpanLink from '@/shared/components/permission-controled-span-link.vue';
import { MYE_REGISTER_STATUS } from '@/shared/const/e-mansion';
import { E_MANSION_ACCOUNT_AUTHORITY } from '@/shared/const/e-mansion/e-mansion-account-authority';
import { E_MANSION_DEFAULTER_STATUS } from '@/shared/const/e-mansion/e-mansion-defaulter-status';
import { E_MANSION_ISP_STATUS } from '@/shared/const/e-mansion/e-mansion-isp-status';
import { XMAIL_PASSWORD_REGISTER_STATUS } from '@/shared/const/e-mansion/e-mansion-xmail-password-register-status';
import { XMAIL_REGISTER_STATUS } from '@/shared/const/e-mansion/e-mansion-xmail-register-status';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { FORMAT_DATE } from '@/shared/const/format';
import { SERVICE_ID } from '@/shared/const/service-ids';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { UA_TYPE } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionSharedExternalApiService } from '@/shared/services/external-api/e-mansion-shared-external-api-service';
import { PermissionControlService } from '@/shared/services/permission/permission-control-service';
import { XmailEMansionWebMailLoginService } from '@/shared/services/xmail/xmail-e-mansion-web-mail-login-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { leaveFontRedAndBrTag } from '@/shared/util/func-leave-font-red-and-br-tag';
import { processDate } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { halfWidth } from '@/shared/util/validators';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { Property } from '../../shared/classes/spf-api/property';

type DataType = {
  customer: EMansionCustomer | null;
  property: EMansionProperty | null;
  rooms: Array<EMansionRoomsDetail> | null;
  /** メール認証キーの設定エリア 表示/非表示 */
  isEmailAuthenticationKeySettingDisplay: boolean;
  /** メール認証キー */
  emailAuthenticationKey: string;
  /** メール認証キー設定 成功/失敗 */
  isCompletedEmailAuthenticationKeySetting: boolean;
  /** メール認証キー設定完了メッセージ */
  completedEmailAuthenticationKeySettingMessage: string;
  /** メール認証キー設定エラーメッセージを格納する配列 */
  messageCheckEmailAuthenticationKey: Array<string>;
  displayEmInfoTop: boolean;
  /** 物件情報に掲載するお知らせ */
  emInfoMessages: EmInfoMessage[] | null;
  isSlimPlan?: boolean;
  isApplyConnectix: boolean;
  /**ランダムで設定する9桁の数字を当てはめる */
  rd: string;
  /** フッターに表示するバナー */
  banners: Array<{ url: string; src: string }>;
  /** e-mantion My の URL */
  eMansionMyUrl: string;
  /** e-mantion の URL */
  eMansionUrl: string;
  /** ConnectixLPのURL */
  connectixLpUrl: string;
  // メール認証キーチェック中かどうか
  isCreatingEmailAuthenticationKey: boolean;
  ScrollHint: any;
  // 契約内容案内の表示フラグ
  contractFlag: boolean;
  // Connectix契約/未契約のフラグ true:契約済み,false:未契約
  isContractConnectixFlag: boolean;
  // メールご利用状況 の機能ID
  functionIdMailUsage: string;
  // メインメールアドレス の機能ID
  functionIdMainMail: string;
  // メールパスワード変更 の機能ID
  functionIdMailPassword: string;
  // WEBメール の機能ID
  functionIdWebMail: string;
  // 追加メールアドレス の機能ID
  functionIdAddAccount: string;
  // メールパック の機能ID
  functionIdMailPack: string;
  // ホームページ開設 の機能ID
  functionIdHp: string;
  // ホームページ容量変更 の機能ID
  functionIdHpVolume: string;
  // ご利用状況確認
  functionIdLinkUsage: string;
  // クレジットカード情報登録変更
  functionIdLinkCreditCard: string;
  // 会員情報変更
  functionIdLinkMembersInfoChange: string;
  // 契約内容のご案内
  functionIdLinkContractContentGuidance: string;
  // ご請求明細・販売証明書表示
  functionIdLinkBillingItemSalesCertificateDisplay: string;
  // e-mansion お知らせメールの配信設定
  functionIdLinkNewsEmailDeliveryPreference: string;
  // ISP会員契約の解除
  functionIdLinkIspMemberContractCancellation: string;
  // オプションサービスはこちら
  functionIdLinkOptionService: string;
  // 退会状況
  isWithdrawn: boolean;
  // アカウント種別
  accountType: E_MANSION_ACCOUNT_AUTHORITY | undefined;
  // 会員種別
  planType: SERVICE_PLAN_TYPE | undefined;
  //追加アカウントかどうか
  isAdditional: boolean;
  // 滞納状況
  isDefaulter: boolean;
  // 会員単位_メールアドレス有無
  hasMailAddressAsMember: boolean;
  // 会員単位_メインメールアドレス有無
  hasMainMailAddressAsMember: boolean;
  // ログインユーザ_ホームページ有無
  hasHomepageAsLoginUser: boolean;
  // ホームページ容量リンク表示有無
  isOpenedHomepage: boolean;
  //メールパスワード変更リンク活性
  canChangeMailPassword: boolean;
  //Webメールリンク活性
  canUseWebMail: boolean;
  // WEBメールのリンク URL
  xmailUrl: string;
  // 別タブの名前
  tabTarget: string;
  // ISP退会済みフラグ
  isIspCancelled: boolean;
  // 退会お申し込み中フラグ
  isApplyCancelIsp: boolean;
  // ISP(e-mansion)退会済みログイン可能期間に表示する 請求明細・販売証明書を確認できる 「My e-mansion」 のURL
  billingDetailsURL: string;
  // メール会員か否かのフラグ
  isMailMember: boolean;
  //IPフォンフラグ
  isIPP: boolean;
  //UCOM光電話フラグ
  isHikariPhone: boolean;
  //マンションプラス電話フラグ
  isMPlusPhone: boolean;
  //グローバルIPフラグ
  isGIP: boolean;
  //i-フィルターフラグ
  isIFT: boolean;
  //InternetSagiWallフラグ
  isISW: boolean;
  //マルチデバイスフラグ
  isMd360: boolean;
  //NotonInternetフラグ
  isNIS: boolean;
  //Norton360フラグ
  is360: boolean;
  //コース変更フラグ
  isCourseChange: boolean;
  //ローディングフラグ
  isLoading: boolean;
  isReadablePaymentMethod: boolean;
  //IP電話 契約ステータス
  ippContractStatus: boolean;
  //UCOM光電話 契約ステータス
  hphoneContractStatus: boolean;
  //i-フィルター 契約ステータス
  iftContractStatus: boolean;
  //gip 契約ステータス
  gipContractStatus: boolean;
};

/** 画面要素を表示するかの判定に使う、退会状況・アカウント種別・会員種別の条件パターン */
type MemberConditionPattern = {
  additional: boolean; // 追加アカウントでも表示するかどうか
  normal: boolean; // 通常会員なら表示する
  slim: boolean; // スリムプランなら表示する
  ttp: boolean; // TTP なら表示する
  mailFree: boolean; // メール会員(無償)なら表示する
  mailCharge: boolean; // メール会員(有償)なら表示する
};

/** e-mansion Index コンポーネント */
export default defineComponent({
  name: 'e-mansion-index',
  components: {
    PermissionControledRouterLink,
    PermissionControledListLink,
    PermissionControledSpanLink,
    PermissionControledMenuLink,
  },
  data(): DataType {
    return {
      customer: new EMansionCustomer({}),
      property: null,
      rooms: null,
      isEmailAuthenticationKeySettingDisplay: false,
      emailAuthenticationKey: '',
      isCompletedEmailAuthenticationKeySetting: false,
      completedEmailAuthenticationKeySettingMessage: '',
      messageCheckEmailAuthenticationKey: [],
      displayEmInfoTop: false,
      emInfoMessages: [],
      isSlimPlan: undefined,
      isApplyConnectix: false,
      rd: '',
      banners: [],
      eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      connectixLpUrl: process.env.VUE_APP_E_MANSION_LP_URL,
      isCreatingEmailAuthenticationKey: false,
      ScrollHint: require('../../shared/libraries/scroll-hint/scroll-hint'),
      contractFlag: false,
      isContractConnectixFlag: false,
      isIspCancelled: false,
      isApplyCancelIsp: false,
      billingDetailsURL: process.env.VUE_APP_E_MANSION_BILLING_DETAILS_URL,
      functionIdMailUsage: SERVICE_ID.MAIL_VIRUS_CHECK,
      functionIdMainMail: SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE,
      functionIdMailPassword: SERVICE_ID.MAIL_PASSWORD,
      functionIdWebMail: SERVICE_ID.WEB_MAIL,
      functionIdAddAccount: SERVICE_ID.MAIL_ACCOUNT_ADDITION,
      functionIdMailPack: SERVICE_ID.MAIL_ACCOUNT_ADDITION,
      functionIdHp: SERVICE_ID.HOMEPAGE_USAGE,
      functionIdHpVolume: SERVICE_ID.HOMEPAGE_VOLUME,
      functionIdLinkUsage: SERVICE_ID.E_MANSION.LINK_E_MANSION_USAGE,
      functionIdLinkCreditCard: SERVICE_ID.E_MANSION.LINK_E_MANSION_CREDIT,
      functionIdLinkMembersInfoChange: SERVICE_ID.E_MANSION.LINK_E_MANSION_INFO_CHANGE,
      functionIdLinkContractContentGuidance: SERVICE_ID.E_MANSION.LINK_CONTRACT_CONTENT_GUIDANCE,
      functionIdLinkBillingItemSalesCertificateDisplay: SERVICE_ID.E_MANSION.LINK_BILLING_ITEM_SALES_CERTIFICATE_DISPLAY,
      functionIdLinkNewsEmailDeliveryPreference: SERVICE_ID.E_MANSION.LINK_NEWS_EMAIL_DELIVERY_PREFERENCE,
      functionIdLinkIspMemberContractCancellation: SERVICE_ID.E_MANSION.LINK_ISP_MEMBER_CONTRACT_CANCELLATION,
      functionIdLinkOptionService: SERVICE_ID.E_MANSION.LINK_ISP_OPTION_SERVICE,
      isWithdrawn: false,
      accountType: undefined,
      planType: undefined,
      isAdditional: false,
      isDefaulter: true,
      hasMailAddressAsMember: false,
      hasMainMailAddressAsMember: false,
      hasHomepageAsLoginUser: false,
      isOpenedHomepage: false,
      canChangeMailPassword: false,
      canUseWebMail: false,
      xmailUrl: 'https://xmail.com', // TODO : 動作確認用。削除すること。
      tabTarget: 'My e-mansion',
      isMailMember: false,
      isIPP: false,
      isHikariPhone: false,
      isMPlusPhone: false,
      isGIP: false,
      isIFT: false,
      isISW: false,
      isMd360: false,
      isNIS: false,
      is360: false,
      isCourseChange: false,
      isLoading: false,
      isReadablePaymentMethod: false,
      ippContractStatus: false,
      hphoneContractStatus: false,
      iftContractStatus: false,
      gipContractStatus: false,
    };
  },
  /** バリデーション定義 */
  validations: {
    emailAuthenticationKey: {
      required,
      halfWidth,
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  emits: ['isMemberTopMountingEmansion'],
  async mounted() {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      /** 会員取得 */
      const member: Member = this.$store.getters['memberStore/member'];
      // 会員情報に物件が紐づいていない or e-mansionアカウントが紐づいていなければエラーとする
      if (!member.propertyId || !member.primaryKeyMye) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      /** 物件取得 */
      const property: Property = this.$store.getters['propertyStore/property'];
      // 利用可能なe-mansionのサービスが紐付けられていなければエラーとする
      if (!property.apartmentId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
      }

      await this.getIsReadablePaymentMethod();

      /** 契約基本情報をStoreから取得 */
      const params = {
        memberId: String(member.primaryKeyMye),
        query: { ua_type: UA_TYPE.E_MANSION, apartment_id: property.apartmentId },
      };
      const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', params);

      if (customer instanceof EMansionCustomer) {
        // 顧客情報に物件情報が紐付けられていなければエラーとする
        if (!customer.property_id) {
          // データ不整合エラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
        }
        // 正常ケースの場合
        this.customer = customer;
        // メール認証キーの設定欄の表示/非表示
        if (customer.email_authentication_key_setting_display === '0') {
          this.isEmailAuthenticationKeySettingDisplay = true;
        } else {
          this.isEmailAuthenticationKeySettingDisplay = false;
        }
        if (this.customer.contract_document_flag === '1') {
          this.contractFlag = true;
        } else {
          this.contractFlag = false;
        }
        if (this.customer.op_connectix_contract_status === '1') {
          this.isContractConnectixFlag = true;
        } else {
          this.isContractConnectixFlag = false;
        }
        // 退会申込中・退会済み済み判定 yyyy/mm/dd が来る
        if (customer.quit_date) {
          const formattedToday = processDate(FORMAT_DATE.YYYYMMDD_WITH_SLASH);

          if (customer.quit_date < formattedToday) {
            this.isIspCancelled = true;
          } else {
            if (customer.status === '01') {
              this.isApplyCancelIsp = true;
            }
            if (customer.status === '03') {
              this.isApplyCancelIsp = true;
            }
          }
        }
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      // 物件基本情報をStoreから取得
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        this.property = eMansionProperty;
        this.isSlimPlan = eMansionProperty.slim_plan_flag === '1';
        if (eMansionProperty.em_info_message !== null) {
          this.displayEmInfoTop = true;
          this.emInfoMessages = eMansionProperty.em_info_message.map((message) => {
            message.message_label = leaveFontRedAndBrTag(message.message_label);
            return message;
          });
        }
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
      }

      if (this.customer.op_ipp_contract_status === '1') {
        this.ippContractStatus = true;
      }
      if (this.customer.op_hphone_contract_status === '1') {
        this.hphoneContractStatus = true;
      }
      if (this.customer.op_ift_contract_status === '1') {
        this.iftContractStatus = true;
      }
      if (this.customer.op_gip_contract_status === '1') {
        this.gipContractStatus = true;
      }

      /** 部屋番号一覧をStoreから取得 */
      const propertyRoomParams = { uaType: UA_TYPE.E_MANSION, propertyId: customer.property_id };
      const propertyRooms: EMansionRoomsResponseResult | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/rooms', propertyRoomParams);
      if (propertyRooms instanceof EMansionRoomsResponseResult) {
        this.rooms = propertyRooms.rooms;
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.ROOM_INFO);
      }

      // 9桁のランダムな数字を取得
      this.rd = this.createRd();
      // 会員種別を取得
      this.planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      if (this.planType == SERVICE_PLAN_TYPE.EM_ADDITIONAL) {
        this.isAdditional = true;
      }

      // メール会員(有償/無償)に該当するかのチェック
      if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_FREE) {
        this.isMailMember = true;
      } else if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_CHARGE) {
        this.isMailMember = true;
      } else {
        this.isMailMember = false;
      }

      // Connectixお申し込みの可/不可を取得
      this.isApplyConnectix = this.checkApplyConnectix(eMansionProperty);

      // 退会状況を取得
      this.isWithdrawn = this.isIspStatusUnsubscribe(customer.status);

      // アカウント種別 (メインアカウント/追加アカウント) を取得
      const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      this.accountType = accountInfo.eMansionAccountInfo.accountAuthorization;

      // 滞納状況
      this.isDefaulter = this.isIspDefaulter(this.customer.defaulter_status);

      // 会員単位_メールアドレス有無
      this.hasMailAddressAsMember = accountInfo.hasMailAddressAsMember;

      // 会員単位_メインメールアドレス有無
      this.hasMainMailAddressAsMember = accountInfo.hasMainMailAddressAsMember;

      //ログインユーザーのメールアドレス情報
      const mailAsLoginUserInfo = accountInfo.eMansionMailAddressInfo;
      const date = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
      const sin = new Date(accountInfo.serviceInTime);
      const isBeforeSin = this.isDateBeforeSinDay(date, sin);
      if (mailAsLoginUserInfo) {
        //Webメールリンク表示のためのステータスチェック
        this.$data.canUseWebMail = this.checkCanUseWebMail(isBeforeSin, mailAsLoginUserInfo);
        //パスワード変更リンク表示のためのステータスチェック
        this.$data.canChangeMailPassword = this.checkCanChangeMailPassword(isBeforeSin, mailAsLoginUserInfo);
      }
      // ログインユーザ_ホームページ有無
      this.hasHomepageAsLoginUser = accountInfo.hasHomepageAsLoginUser;
      // ホームページ容量リンク表示有無
      this.isOpenedHomepage = accountInfo.isOpenedHomepage;
      //オプションサービスメニュー表示に使用するフラグをセット
      this.setOptionFlag(eMansionProperty);
    } catch (error) {
      throw error;
    }

    this.banners = this.findBanner();
    this.$nextTick(() => {
      this.jsScrollable();
    });
    this.$emit('isMemberTopMountingEmansion', false); // pages/index.vue宛てにmounted終了を通知
  },
  methods: {
    /** メール認証キーチェック */
    async createEMansionEmailAuthenticationKey(): Promise<void> {
      // createEMansionEmailAuthenticationKey()が同時に1回以上実行されないようにする
      if (this.isCreatingEmailAuthenticationKey) {
        return;
      }
      this.isCreatingEmailAuthenticationKey = true;

      this.messageCheckEmailAuthenticationKey = [];
      if (this.v$.emailAuthenticationKey?.$invalid) {
        if (this.v$.emailAuthenticationKey?.required.$invalid) {
          // 必須エラー
          this.messageCheckEmailAuthenticationKey.push('メール認証キーを入力してください。');
        } else if (this.v$.emailAuthenticationKey?.halfWidth.$invalid) {
          // 形式エラー
          this.messageCheckEmailAuthenticationKey.push('メール認証キーは半角英数字で入力してください。');
        }
        this.isCreatingEmailAuthenticationKey = false;
        return;
      }
      const member = this.$store.getters['memberStore/member'];
      const property = this.$store.getters['propertyStore/property'];
      const request = new EMansionEmailAuthenticationKeyRequest({
        ua_type: UA_TYPE.E_MANSION,
        property_id: convert6DigitIdTo8DigitId(property.apartmentId),
        email_authentication_key: this.emailAuthenticationKey,
      });
      const eMansionEmailAuthenticationKeyRequest = new EMansionEmailAuthenticationKeyRequestResult(request);
      const eMansionEmailAuthenticationKeyResponse: EMansionEmailAuthenticationKeyResponse | EMansionSharedErrorResponse =
        await EMansionSharedExternalApiService.createEMansionEmailAuthenticationKey(member.primaryKeyMye, eMansionEmailAuthenticationKeyRequest);
      if (eMansionEmailAuthenticationKeyResponse instanceof EMansionEmailAuthenticationKeyResponse) {
        if (eMansionEmailAuthenticationKeyResponse.result?.processing_result) {
          // メール認証キー設定成功
          this.isCompletedEmailAuthenticationKeySetting = true;
          this.completedEmailAuthenticationKeySettingMessage = 'メール認証キーを設定しました。';
        } else {
          // メール認証キー設定失敗
          this.isCompletedEmailAuthenticationKeySetting = false;
          this.messageCheckEmailAuthenticationKey.push('メール認証キーが正しくありません。');
        }
      } else {
        // メール認証キー設定失敗
        this.isCompletedEmailAuthenticationKeySetting = false;
        this.messageCheckEmailAuthenticationKey.push('メール認証キーが正しくありません。');
      }
      this.isCreatingEmailAuthenticationKey = false;
    },
    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    /**Connectixお申し込みの可/不可を判定 */
    checkApplyConnectix(property: EMansionProperty): boolean {
      /**
       * ・Connectix対象物件であること
       * 物件情報取得APIの「SD-WANフラグ（op_sdwan_flag）」が"1"
       */

      if (property.op.sdwan.flag === '1') {
        return true;
      }
      return false;
    },
    /**APIで取得したバナーIDでバナーを検索 */
    findBanner() {
      const bannerList = [
        /** フレッツ光 個人お申し込み用（西・光配線のみ） */
        {
          url: `${this.$data.eMansionUrl}/${this.apid}/dfn00/`,
          src: require('../../images/114_bn000114_20200311095931840472.png'),
        },
        /** フレッツ光 物件導入用 */
        {
          url: `${this.$data.eMansionUrl}/${this.apid}/dfl00/`,
          src: require('../../images/138_bn000138_20200311095909425454.png'),
        },
        /** 防災グッズ */
        {
          url: 'http://mg.emlife.jp/lifestyle/bousaigoods_20150901/',
          src: require('../../images/170_bn000170_20160525112619059170.jpg'),
        },
        /** 【Mcloud】引渡し前の施設予約について */
        {
          url: `http://www.em-net.ne.jp/${this.apid}/atp05?function=message&message_id=10824`,
          src: require('../../images/175_bn000175_20170727153849244066.png'),
        },
        /** フレッツ光 個人お申し込み用（東V・西V） */
        {
          url: `${this.$data.eMansionUrl}/${this.apid}/dfk00/`,
          src: require('../../images/31_bn000031_20130401101628707021.gif'),
        },
        /** フレッツ光 個人お申し込み用（東・光配線のみ） */
        {
          url: `${this.$data.eMansionUrl}/${this.apid}/dfm00/`,
          src: require('../../images/32_bn000032_20200311100014711082.png'),
        },
        /** 三菱地所レジデンスクラブ */
        { url: 'https://www.resiclub.com/', src: require('../../images/74_bn000074_20140114193918073611.jpg') },
        /** Ｂｒｉｌｌｉａ Ｒｅｓｉｄｅｎｃｅ六甲アイランド インターネットコンシェルジュサービス */
        {
          url: 'https://www2.concierge-service.jp/members/login/574/',
          src: require('../../images/85_bn000085_20120326115516014461.jpg'),
        },
      ];
      let findBannerResult: { url: string; src: string }[] = [];
      findBannerResult = bannerList.filter((banner) => {
        if (!this.property?.em_bn.em_bn_id) {
          return false;
        }
        return this.property?.em_bn.em_bn_id.find((emBnId: string) => banner.src.includes(emBnId));
      });
      return findBannerResult;
    },
    getKindString(kind: string): string {
      let kindStr = '';
      switch (kind) {
        case '01':
          kindStr = 'お知らせ';
          break;
        case '02':
          kindStr = 'キャンペーン';
          break;
        case '03':
          kindStr = 'メンテナンス';
          break;
        case '04':
        case '05':
        case '06':
          kindStr = '障害';
          break;
        default:
          kindStr = 'お知らせ';
          break;
      }
      return kindStr;
    },
    jsScrollable() {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },

    /** ISPの滞納状況判定 */
    isIspDefaulter(status: string): boolean {
      return status === E_MANSION_DEFAULTER_STATUS.DEFAULTER_CREDIT_CARD_REGISTERED;
    },

    /** ISPの会員ステータス判定 */
    isIspStatusUnsubscribe(status: string): boolean {
      return status === E_MANSION_ISP_STATUS.UNSUBSCRIBE || status === E_MANSION_ISP_STATUS.TRANSFER_UNSUBSCRIBE;
    },

    /**
     * 退会状況・アカウント種別・会員種別が与えられたパターンにマッチするか判定する関数
     * @param: condition 判定に使うパターン
     * @return: 判定結果
     */
    matchPlanWithPattern(condition: MemberConditionPattern): boolean {
      // 追加アカウントならマッチしないパターンで、追加アカウントだった場合
      if (!condition.additional && this.accountType === E_MANSION_ACCOUNT_AUTHORITY.ADDITIONAL_ACCOUNT) {
        return false;
      }
      // 通常会員ならマッチするパターンで、通常会員だった場合
      if (condition.normal && this.planType === SERVICE_PLAN_TYPE.EM_NORMAL) {
        return true;
      }
      // スリムプランならマッチするパターンで、スリムプランだった場合
      if (condition.slim && (this.planType === SERVICE_PLAN_TYPE.EM_SLIM || this.planType === SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL)) {
        return true;
      }
      // TTP ならマッチするパターンで、TTP だった場合
      if (condition.ttp && this.planType === SERVICE_PLAN_TYPE.EM_TTP) {
        return true;
      }
      // メール会員(無償)ならマッチするパターンで、メール会員(無償)だった場合
      if (condition.mailFree && this.planType === SERVICE_PLAN_TYPE.EM_MAIL_FREE) {
        return true;
      }
      // メール会員(有償)ならマッチするパターンで、メール会員(有償)だった場合
      if (condition.mailCharge && this.planType === SERVICE_PLAN_TYPE.EM_MAIL_CHARGE) {
        return true;
      }
      return false;
    },
    /**
     * パスワード変更リンク表示のためのステータスチェック(trueなら活性化)
     * Mye登録ステータス,XMAIL登録ステータス,XMAILパスワード登録ステータス共に(2,3)ならtrue
     * サービスイン日前の場合はMye登録ステータス,XMAIL登録ステータス,XMAILパスワード登録ステータス共に(1,2,3)ならtrue
     */
    checkCanChangeMailPassword(isBeforeSin: boolean, mailInfo: EMansionMailAddress): boolean {
      if (mailInfo.myeRegisterStatus.id == MYE_REGISTER_STATUS.IN_CONTRACT_APPLICATION.id) {
        if (!isBeforeSin) {
          //Sin後かつステータス=1の場合は活性化しない
          return false;
        }
      }
      if (mailInfo.xMailRegisterStatus.id == XMAIL_REGISTER_STATUS.IN_CONTRACT_APPLICATION.id) {
        if (!isBeforeSin) {
          //Sin後かつステータス=1の場合は活性化しない
          return false;
        }
      }
      if (mailInfo.xMailRegisterStatus.id == XMAIL_REGISTER_STATUS.CANCELLED.id) {
        return false;
      }
      if (mailInfo.xMailPasswordRegisterStatus.id == XMAIL_PASSWORD_REGISTER_STATUS.IN_CONTRACT_APPLICATION.id) {
        if (!isBeforeSin) {
          //Sin後かつステータス=1の場合は活性化しない
          return false;
        }
      }
      if (mailInfo.xMailPasswordRegisterStatus.id == XMAIL_PASSWORD_REGISTER_STATUS.CANCELLED.id) {
        return false;
      }
      return true;
    },
    /**
     * Webメールリンク表示のためのステータスチェック(trueなら活性化)
     * Mye登録ステータス IN(2,3,4),XMAIL登録ステータス IN(2,3),XMAILパスワード登録ステータス IN(2,3)ならtrue
     * サービスイン日前の場合はfalseになる
     */
    checkCanUseWebMail(isBeforeSin: boolean, mailInfo: EMansionMailAddress): boolean {
      if (isBeforeSin) {
        return false;
      }
      if (mailInfo.myeRegisterStatus.id == MYE_REGISTER_STATUS.IN_CONTRACT_APPLICATION.id) {
        return false;
      }
      if (mailInfo.xMailRegisterStatus.id == XMAIL_REGISTER_STATUS.IN_CONTRACT_APPLICATION.id) {
        return false;
      }
      if (mailInfo.xMailRegisterStatus.id == XMAIL_REGISTER_STATUS.CANCELLED.id) {
        return false;
      }
      if (mailInfo.xMailPasswordRegisterStatus.id == XMAIL_PASSWORD_REGISTER_STATUS.IN_CONTRACT_APPLICATION.id) {
        return false;
      }
      if (mailInfo.xMailPasswordRegisterStatus.id == XMAIL_PASSWORD_REGISTER_STATUS.CANCELLED.id) {
        return false;
      }
      return true;
    },
    isDateBeforeSinDay(date: Date, sin: Date): boolean {
      const n = process.env.VUE_APP_E_MANSION_DAYS_TO_START_SERVICE;
      if (!n) {
        throw new Error('process.env.VUE_APP_E_MANSION_DAYS_TO_START_SERVICEがありません');
      }
      const marginedDate = new Date(sin.getTime());
      marginedDate.setDate(marginedDate.getDate() - Number(n));

      return date < marginedDate;
    },
    async webMailLogin(): Promise<void> {
      // 多重処理させないように処理中をチェック
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      try {
        const eMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo']; // e-mansion アカウント情報取得
        const response = await XmailEMansionWebMailLoginService.xmailEMansionWebMailLogin(eMansionAccountInfo.eMansionMailAddressInfo.eMansionMailAddressId);

        if (response.errorMessages) {
          this.$store.commit('errorStore/messages', response.errorMessages);
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }

        window.open(response.redirectUrl);
        return;
      } catch (error) {
        // 共通エラー画面に遷移する
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * e-mansionオプションサービス出し分けのために
     * フラグに値をセットする
     * @param eMansionProperty 物件基本情報
     */
    setOptionFlag(eMansionProperty: EMansionProperty): void {
      if (eMansionProperty.op.ipp.flag === '1') {
        this.$data.isIPP = true;
      }
      if (eMansionProperty.op.hikariphone.flag === '1') {
        this.$data.isHikariPhone = true;
      }
      if (eMansionProperty.op.mplusphone.flag === '1') {
        this.$data.isMPlusPhone = true;
      }
      if (eMansionProperty.op.gip.flag === '1') {
        this.$data.isGIP = true;
      }
      if (eMansionProperty.op.ift.flag === '1') {
        this.$data.isIFT = true;
      }
      if (eMansionProperty.op.isw.flag === '1') {
        this.$data.isISW = true;
      }
      if (eMansionProperty.op.md360.flag === '1') {
        this.$data.isMd360 = true;
      }
      if (eMansionProperty.op.nis.flag === '1') {
        this.$data.isNIS = true;
      }
      if (eMansionProperty.op[360].flag === '1') {
        this.$data.is360 = true;
      }
      if (eMansionProperty.em_coursechg_flag === '1' && eMansionProperty.em_course_count > 1) {
        this.$data.isCourseChange = true;
      }
    },
    async getIsReadablePaymentMethod(): Promise<void> {
      const isReadablePaymentMethod = await PermissionControlService.isReadable(this.functionIdLinkCreditCard);

      if (isReadablePaymentMethod) {
        this.isReadablePaymentMethod = true;
      } else {
        this.isReadablePaymentMethod = false;
      }
    },
  },
  computed: {
    apid() {
      if (this.$data.property) {
        return this.$data.property.IN_APID;
      } else {
        return '';
      }
    },

    /**
     * 「各種登録情報の確認・変更」ブロックを表示するかの判定
     *  認可制御外のため用意
     */
    isRegistrationLinkBlockAvailable(): boolean {
      return this.matchPlanWithPattern({
        normal: true,
        slim: true,
        ttp: true,
        mailFree: true,
        mailCharge: true,
        additional: false,
      });
    },

    /**
     *「ホームページサービス」ブロックを表示するかの判定
     * 認可制御外のため用意
     */
    isHomePageServiceBlockAvailable(): boolean {
      return this.matchPlanWithPattern({
        normal: true,
        slim: false,
        ttp: true,
        mailFree: true,
        mailCharge: true,
        additional: false,
      });
    },

    /** スリムプラン判定 */
    isUserSlimPlan(): boolean {
      if (this.planType === SERVICE_PLAN_TYPE.EM_SLIM) {
        return true;
      }

      if (this.planType === SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL) {
        return true;
      }

      return false;
    },

    /** スリムプラン判定（特例を含まない） */
    isUserSlimNotSpecialPlan(): boolean {
      return this.planType === SERVICE_PLAN_TYPE.EM_SLIM;
    },

    /**有料メール会員判定 */
    isMailChargeMember(): boolean {
      if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_CHARGE) {
        return true;
      }
      return false;
    },
    /** 「契約内容のご案内」リンクを表示するかの判定 */
    isContractLinkAvailable(): boolean {
      if (!this.contractFlag) {
        return false;
      }
      return true;
    },
    /** 「e-mansion 各種オプションサービスのお申し込み・変更はこちら」のリンク先 */
    optionServiceUrl(): string {
      // メール会員(無償)
      if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_FREE) {
        return `${this.eMansionUrl}/${this.apid}/`;
      }
      // メール会員(有償)
      if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_CHARGE) {
        return `${this.eMansionUrl}/mmb/`;
      }
      // メール会員(無償)と同じ URL だが、設計書に準じて場合分けしている
      return `${this.eMansionUrl}/${this.apid}/`;
    },
    /**「メールサービス詳細はこちら」のリンク先 */
    mailServiceUrl(): string {
      // スリムプラン
      if (this.planType === SERVICE_PLAN_TYPE.EM_SLIM || this.planType === SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL) {
        return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/${this.apid}/service/mailpack`;
      }
      // メール会員(有償)
      if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_CHARGE) {
        return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/mmb/`;
      }
      return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/${this.apid}/service/mail`;
    },
    homepageServiceUrl(): string {
      //メール会員(有償)
      if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_CHARGE) {
        return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/mmb/`;
      }
      return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/${this.apid}/service/hp`;
    },
    /**「お住まいの建物専用ホームページはこちら」のリンク先 */
    apartmentServiceUrl(): string {
      // メール会員(無償)
      if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_FREE) {
        return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/${this.apid}/`;
      }
      // メール会員(有償)
      if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_CHARGE) {
        return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/mmb/`;
      }
      // メール会員(無償)と同じ URL だが、設計書に準じて場合分けしている
      return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/${this.apid}/`;
    },
  },
});
</script>
