<template>
  <div class="e-mansion-entry-option">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion ご入会お申し込み</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion ご入会お申し込み</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid5 sp-grid3 gap10">
        <li>お客様情報入力</li>
        <li>接続コース選択</li>
        <li class="stay">オプションサービス選択</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:e-mansion 会員規約 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="オプションサービス選択" />オプションサービス選択</h2>
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <p>ご希望のオプションサービスの「お申し込み」欄にチェックを入れてください。</p>
        <p class="red">※お申し込み時、必ず各サービスの規約をご確認ください。</p>
        <table class="table-type2" v-if="property?.op && property.op.ipp && property.op.ipp.flag === '1' && property.op.mplusphone.flag === '0'">
          <tbody>
            <tr>
              <th rowspan="3">
                <p><b>e-mansion IPフォン</b></p>
                <a :href="`${eMansionUrl}/${apid}/service/ipdenwa/e-ipphone.html`" target="_blank" class="link">サービス詳細</a>
                <br />
                <a class="link" target="_blank" :href="ipphoneTermsUrl">サービス規約</a>
              </th>
              <td>
                <div class="mb15">
                  <ul>
                    <li>
                      事務手数料：{{ property.op.ipp.jimu_fee }}円（申し込み時）
                      <span v-if="property.op.ipp.jimu_fee_cp">
                        　⇒　<b class="font16px red">{{ property.op.ipp.jimu_fee_cp }}円<span class="campaign-text">キャンペーン中！</span></b></span
                      >
                    </li>
                    <li>
                      基本料金：{{ property.op.ipp.basic_fee }}円/月<span v-if="property.op.ipp.basic_fee_cp">
                        　⇒　<b class="font16px red">{{ property.op.ipp.basic_fee_cp }}円<span class="campaign-text">キャンペーン中！</span></b></span
                      >
                    </li>
                    <li>
                      TAレンタル料：{{ property.op.ipp.rental_charge }}円/月<span v-if="property.op.ipp.rental_charge_cp">
                        　⇒　<b class="font16px red">{{ property.op.ipp.rental_charge_cp }}円<span class="campaign-text">キャンペーン中！</span></b></span
                      >
                    </li>
                    <li>
                      ユニバーサルサービス料：{{ property.op.ipp.uni_charge_tax_inc }}円/月<span v-if="property.op.ipp.uni_charge_cp">
                        　⇒　<b class="font16px red">{{ property.op.ipp.uni_charge_cp }}円<span class="campaign-text">キャンペーン中！</span></b></span
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p>電気通信事業法に基づく重要事項をご説明します。</p>
                <p class="pdf-link-text">
                  <a class="pdf-link" :href="`${eMansionMyUrl}/instructions/downloadPdf.php?function=instructions&apid=${apid}&kind=02`" target="_blank"
                    ><img src="../../../images/picture_as_pdf.svg" />電気通信事業法に基づく重要事項（PDF）</a
                  >をご確認ください。
                </p>
                <label><input class="checkbox" type="checkbox" v-model="readImportantSubjects" />重要事項を確認</label><br />
                <label><input class="checkbox" type="checkbox" v-model="ip_phone" />規約に同意して申し込む</label>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  テレフォニーアダプターのお届け日時をご指定ください。指定されたお届け日時がご請求開始の起算日となります。
                  ただし、e-mansionインターネット接続サービス提供前の場合は、接続サービス提供開始日が起算日となります。 キャンペーン等がある場合はそちらが適用されます。
                </p>
                <p class="available-date-text">
                  <span class="red">{{ availableDeliveryYear }}</span
                  >年<span class="red">{{ availableDeliveryMonth }}</span
                  >月<span class="red">{{ availableDeliveryDay }}</span
                  >日以降、ご指定可能です。
                </p>
                <select name="year" class="form-select" v-model="delivery_year">
                  <option :value="availableDeliveryYear">{{ availableDeliveryYear }}</option>
                  <option :value="availableDeliveryYear + 1">{{ availableDeliveryYear + 1 }}</option>
                </select>
                年&nbsp;&nbsp;
                <select name="month" class="form-select" v-model="delivery_month">
                  <option value="">-</option>
                  <option v-for="n in 12" :key="n" v-bind:value="n">{{ n }}</option>
                </select>
                月&nbsp;&nbsp;
                <select name="day" class="form-select" v-model="delivery_day">
                  <option value="">-</option>
                  <option v-for="n in 31" :key="n" v-bind:value="n">{{ n }}</option>
                </select>
                日&nbsp;&nbsp;
                <select name="schedule delivery" class="form-select" v-model="delivery_time_zone">
                  <option v-for="yamatoDeliveryTimeZone in yamatoDeliveryTimeZoneList" :key="yamatoDeliveryTimeZone.key" :value="yamatoDeliveryTimeZone.value">
                    {{ yamatoDeliveryTimeZone.name }}
                  </option>
                </select>
                <p class="mt10">
                  ※ご契約のマンション住所にテレフォニーアダプターをお送りします。弊社インターネット接続サービスご利用開始後に、
                  テレフォニーアダプターを接続してください。050から始まるIPフォン電話番号を取得し設定することで、ご利用いただけます。
                </p>
                <p class="red">※テレフォニーアダプターはレンタル品のため、お客様の故意または過失による故障や破損の場合、端末機器損害金20,900円（課税対象外）を請求いたします。</p>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="table-type2" v-if="property?.op && property.op.ift && property.op.ift.flag === '1'">
          <tbody>
            <tr>
              <th rowspan="2">
                <p>i-フィルター（有害サイトブロック）</p>
                <a class="link" target="_blank" :href="`${eMansionUrl}/${apid}/service/security/ifilter.html`">サービス詳細</a>
                <br />
                <a :href="`${eMansionUrl}/pdf/service/ifilterkiyaku.pdf`" class="link" target="_blank">サービス規約</a>
              </th>
              <td>
                <ul>
                  <li>
                    {{ property.op.ift.fee }} 円/月
                    <span v-if="property.op.ift.fee_cp">
                      　⇒　<b class="font16px red">{{ property.op.ift.fee_cp }}円<span class="campaign-text">キャンペーン中！</span></b></span
                    >
                  </li>
                </ul>
                <p class="att red">※1ライセンス1台までご利用可能です。</p>
                <p class="att red">※本日お申し込みいただくと、ご契約マンションの「鍵のお引渡し日」以降に、ソフトをダウンロードしてご利用いただけます。</p>
                <p class="att red">※「サービス詳細」よりソフトの動作環境を必ずご確認ください。</p>
              </td>
            </tr>
            <tr>
              <td>
                <label><input type="checkbox" class="checkbox" v-model="i_filter" /> 規約に同意して申し込む</label>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="att">※価格は全て新税率に基づく税込表示（消費税10％）です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
        <div class="sblc">
          <p class="form-btn-txt">ご確認の上、よろしければ「次へ」をクリックしてください。</p>
          <div class="btn-area">
            <button type="button" class="btn-height btn btn05 bs" v-on:click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
            <button type="button" class="btn-height btn btn01 bs" v-on:click="onNext()">次へ<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.btn-height {
  height: 50px;
}
.link {
  font-weight: normal;
}
.option-btn {
  color: #939393;
  border: 1px solid #939393;
  margin-top: 10px;
}

.sbtn {
  min-width: 80px;
  line-height: 26px;
  text-align: center;
  font-size: 13px;
  border-radius: 2px;
  padding: 0 10px;
  background-color: #ffffff;
}
ul {
  padding-left: 0;
  list-style-position: inside;
}
.sbtn:hover {
  opacity: 0.7;
}
.pdf-link {
  background-color: #cf1225;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
  padding: 5px;
  border-radius: 3px;
  text-decoration: none;
  margin-right: 10px;
}
.pdf-link-text {
  margin: 10px 0;
}
.available-date-text {
  margin: 10px 0;
}
.checkbox {
  transform: scale(1.5);
  margin: 7px;
}
.campaign-text {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
  margin-left: 0.5rem;
}
</style>

<script lang="ts">
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import useVuelidate from '@vuelidate/core';
import { defineComponent } from 'vue';
import { EMansionEntryInputForm } from './classes/entry-input-form';

/** Option コンポーネント */
export default defineComponent({
  name: 'e-mansion-entry-option',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    customer: null as EMansionEntryInputForm | null,
    property: null as EMansionProperty | null,
    isLoading: true,
    // IPフォンテレフォニーアダプターはヤマト運輸の着荷指定(時間帯)に従った選択肢となっている
    // NOTE: https://faq.kuronekoyamato.co.jp/app/answers/detail/a_id/1249/~/%E9%85%8D%E9%81%94%E3%81%AE%E6%99%82%E9%96%93%E5%B8%AF%E3%81%A8%E3%80%81%E5%8F%97%E4%BB%98%E7%B7%A0%E5%88%87%E6%99%82%E9%96%93%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82%E6%9B%B4%E3%81%AB%E7%B4%B0%E3%81%8B%E3%81%AA%E6%99%82%E9%96%93%E3%81%AE%E6%8C%87%E5%AE%9A%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B%EF%BC%9F
    yamatoDeliveryTimeZoneList: [
      { name: '午前中', value: '0', key: '0' },
      { name: '14時～16時', value: '2', key: '2' },
      { name: '16時～18時', value: '3', key: '3' },
      { name: '18時～20時', value: '4', key: '4' },
      { name: '19時～21時', value: '5', key: '5' },
    ],
    ipphoneTermsUrl: '',
    ipPhoneDate: '',
    sinDate: null as Date | null,
    readImportantSubjects: false,
    ip_phone: false,
    delivery_year: undefined as undefined | string,
    delivery_month: undefined as undefined | string,
    delivery_day: undefined as undefined | string,
    delivery_time_zone: '0' as undefined | string,

    i_filter: false as undefined | boolean,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** e-mansion のサイト MY URL */
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    /** e-mansion のサイト URL */
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    availableDeliveryDate: new Date(),
    availableDeliveryYear: 0,
    availableDeliveryMonth: 0,
    availableDeliveryDay: 0,
  }),
  /** バリデーション定義 */
  validations: {},
  setup: () => ({ v$: useVuelidate() }),
  async mounted() {
    // ログイン中のお客様情報が表示される
    try {
      // 会員情報取得処理
      const customer: EMansionEntryInputForm = this.$store.getters['eMansionEntryStore/entryInputForm'];
      this.customer = customer;
      // 物件情報の取得
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      this.property = property;

      if (!(await MountedCheckService.canReadEMansionEntryScreen()) || !this.customer || !this.property) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      await this.isAvailableDeliveryDate();

      // ストアに入っているデータをセットする
      this.ip_phone = this.customer.ipphone_flag ?? false;
      if (this.customer.ipphone_flag) {
        this.readImportantSubjects = true;
        this.delivery_year = this.customer.delivery_year;
        this.delivery_month = this.customer.delivery_month;
        this.delivery_day = this.customer.delivery_day;
        this.delivery_time_zone = this.customer.delivery_time_zone;
      }
      this.ipphoneTermsUrl = this.setIpphoneTermsUrl();
      this.i_filter = this.customer.ifilter_flag;
    } catch (e: any) {
      await VueErrorHandler.handle(e, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
    this.isLoading = false;
  },
  computed: {
    /**9桁のランダムな数字を生成 */
    apid() {
      if (this.property) {
        return this.property.IN_APID;
      } else {
        return '';
      }
    },
  },
  methods: {
    setIpphoneTermsUrl() {
      const planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      if (planType === SERVICE_PLAN_TYPE.EM_TTP) {
        return `${this.eMansionUrl}/pdf/ttp/kiyaku.pdf`;
      } else {
        return `${this.eMansionUrl}/pdf/service/e-ipphonekiyaku.pdf`;
      }
    },
    async isAvailableDeliveryDate() {
      const today = new Date();
      //営業日マスタから営業日（IPフォン）を取得
      const after5Date = new Date((await SpfApiService.getBusinessdaysUtil('1', today, 5, 1, 'YYYY/MM/DD', false))[0].displayBusinessday);
      // SIN日
      const splitSinDateString = this.property!.em_sin_time.split(' ');
      const sinYear = splitSinDateString[0].slice(0, 4);
      const sinMonth = splitSinDateString[0].slice(4, 6);
      const sinDay = splitSinDateString[0].slice(6, 8);
      const sinTime = splitSinDateString[1];
      const sinDate = new Date(`${sinYear}/${sinMonth}/${sinDay}`);
      this.availableDeliveryDate = after5Date.getTime() >= sinDate.getTime() ? after5Date : sinDate;
      this.availableDeliveryYear = this.availableDeliveryDate.getFullYear();
      this.availableDeliveryMonth = this.availableDeliveryDate.getMonth() + 1;
      this.availableDeliveryDay = this.availableDeliveryDate.getDate();
    },
    getDeliveryDate() {
      if (!this.delivery_year || !this.delivery_month || !this.delivery_day) {
        return null;
      }
      return (
        String(this.delivery_year) +
        '/' +
        String(String(this.delivery_month).length < 2 ? `0${this.delivery_month}` : this.delivery_month) +
        '/' +
        String(String(this.delivery_day).length < 2 ? `0${this.delivery_day}` : this.delivery_day)
      );
    },
    getDateNum(year: unknown, month: unknown, day: unknown) {
      return Number(String(year) + String(String(month).length < 2 ? `0${month}` : month) + String(String(day).length < 2 ? `0${day}` : day));
    },
    /**
     * ストアに保存する用の値this.customerに値をセットしてストアに保存する
     */
    setCustomerStore() {
      // 入力情報をストアに保存
      this.customer!.ipphone_flag = this.ip_phone;
      // 選択しない場合は初期値に戻す
      if (!this.customer?.ipphone_flag) {
        this.delivery_year = '';
        this.delivery_month = '';
        this.delivery_day = '';
        this.customer!.delivery_date = '';
        this.delivery_time_zone = '';
      }
      this.customer!.delivery_year = this.delivery_year;
      this.customer!.delivery_month = this.delivery_month;
      this.customer!.delivery_day = this.delivery_day;
      this.customer!.delivery_date = this.getDeliveryDate();
      this.customer!.delivery_time_zone = this.delivery_time_zone;

      this.customer!.ifilter_flag = this.i_filter;

      this.$store.commit('eMansionEntryStore/entryInputForm', this.customer);
    },
    /** 次へボタン押下時： 確認画面に遷移する */
    async onNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      // 入力チェック
      if (this.ip_phone) {
        // お届け日時形式チェック
        const delivelyDate = this.getDeliveryDate();
        if (delivelyDate !== null) {
          const validateDate = new Date(delivelyDate);
          const month = validateDate.getMonth() + 1;
          const deliveryMonth = this.delivery_month ? Number(this.delivery_month) : -1;
          if (month !== deliveryMonth) {
            this.errorMessages.push(`「ご希望のお届け日時」をご確認の上、正しく入力してください。`);
          } else if (!this.delivery_time_zone) {
            this.errorMessages.push(`「ご希望のお届け日時」をご確認の上、正しく入力してください。`);
          } else if (validateDate.getTime() < this.availableDeliveryDate.getTime()) {
            this.errorMessages.push(`「ご希望のお届け日時」をご確認の上、正しく入力してください。`);
          }
        } else {
          this.errorMessages.push(`「ご希望のお届け日時」をご確認の上、正しく入力してください。`);
        }
        if (!this.readImportantSubjects) {
          this.errorMessages.push(`重要事項の確認がチェックされていません。`);
        }
      }

      if (this.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      try {
        this.setCustomerStore();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.$router.push('/e-mansion/entry/confirm').catch((error: any) => {
          checkRouterError(error);
        });
      } catch (error: any) {
        await VueErrorHandler.handle(error, '');
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
      this.isLoading = false;
    },
    async onBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.setCustomerStore();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/entry/connection-course').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
