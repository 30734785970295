<template>
  <div>
    <div class="blc">
      <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お支払い方法の登録完了" />お支払い方法の登録完了</h2>

      <p>クレジットカード情報が正常に登録されました。</p>
      <p>通常、登録をご申請いだたいた月のご請求分より、登録後のクレジットカードへのご請求となります。</p>
      <p>クレジットカード情報を再登録いただいた場合、お申し込みいただいた日付によっては、前月分のご請求より変更が適用される場合がございます。</p>
      <p>予めご了承ください。</p>
    </div>

    <div class="blc">
      <div class="btn-area">
        <button class="btn-height btn btn01 bs" @click="onNext()">{{ serviceName }}会員専用トップページへ<i class="material-icons link link-icon">east</i></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { SERVICE_NAME } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

/** ISP支払方法登録完了コンポーネント */
export default defineComponent({
  name: 'isp-payment-method-register-completed-component',
  data: () => ({
    isSubmitting: false,
  }),
  props: {
    /** サービス名 */
    serviceName: {
      type: String,
      default: '',
    },
  },

  methods: {
    async onNext() {
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      const nextPath = (() => {
        if (this.serviceName === SERVICE_NAME.E_MANSION) {
          return '/e-mansion';
        } else if (this.serviceName === SERVICE_NAME.FIVE_A) {
          return '/5a';
        } else {
          throw new Error();
        }
      })();

      await this.$router
        .push(nextPath)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
});
</script>
