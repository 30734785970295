import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionAccount } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { eMansionNotifyMailAddressCheckRequest } from '@/shared/classes/spf-api/mail/model/account/e-mansnion-notify-mailaddress-check-request';
import { InitEMansionMainAccount } from '@/shared/classes/spf-api/mail/model/account/init-e-mansion-main-account';
import { EMansionMailAddress } from '@/shared/classes/spf-api/mail/model/mail-address/e-mansion-mail-address';
import { convertEMansionAccountAuthority } from '@/shared/const/e-mansion/e-mansion-account-authority';
import { convertXMailPasswordRegisterStatusById } from '@/shared/const/e-mansion/e-mansion-xmail-password-register-status';
import { convertXMailRegisterStatusById } from '@/shared/const/e-mansion/e-mansion-xmail-register-status';
import { convertMyeRegisterStatusById } from '@/shared/const/e-mansion/mye-register-status';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import axios from 'axios';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部 API をコールするサービス */
export class SpfApiAccountAccessor {
  /**
   * 会員情報を新規登録する
   *
   * @param initEMansionMainAccount
   */
  public static async insertInitEMansionMainAccount(initEMansionMainAccount: InitEMansionMainAccount): Promise<void> {
    // TODO Responseの形は一旦void
    const response = await api.post<void>('/account/e-mansion/create/eMansionMainAccount', initEMansionMainAccount).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });

    if (!response.status || !(response.status >= 200) || !(response.status <= 299)) {
      throw new Error();
    }
    return;
  }

  public static async getEMansionAccount(loginId: string): Promise<EMansionAccountInfo> {
    const response = await api.get<any>(`/account/e-mansion/getAccount/${loginId}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });

    return await this.createEMansionAccountInfo(
      await this.convertEMansionAccount(response.data.eMansionAccountResponse),
      await this.convertEMansionMailAddressDto(response.data.eMansionMailAddressResponse),
      response.data.hasMailAddressAsMember,
      response.data.hasMainMailAddressAsMember,
      response.data.hasHomepageAsLoginUser,
      response.data.isOpenedHomepage,
      response.data.serviceInTime
    );
  }

  // 本来はリポジトリ内でやりたい…
  private static async convertEMansionAccount(response: any | undefined): Promise<EMansionAccount> {
    if (!response) {
      throw new Error('アカウント情報無し');
    }

    return new EMansionAccount({
      _eMansionAccountId: response.eMansionAccountId,
      _memberId: response.memberId,
      _primaryKeyMye: response.primaryKeyMye,
      _myeRegisterStatus: convertMyeRegisterStatusById(Number(response.myeRegisterStatus)),
      _primaryKeyAuth0: response.primaryKeyAuth0,
      _accountName: response.accountName,
      _initialAccountPassword: response.initialAccountPassword,
      _accountPassword: response.accountPassword,
      _accountSubDomain: response.accountSubDomain,
      _accountAuthorization: convertEMansionAccountAuthority(Number(response.accountAuthorization)),
      _registerSubscriptionOn: response.registerSubscriptionOn,
      _registerOn: response.registerOn,
      _registerProcessOn: response.registerProcessOn,
      _cancelSubscriptionOn: response.cancelSubscriptionOn,
      _cancelOn: response.cancelOn,
      _cancelProcessOn: response.cancelProcessOn,
      _deleteOn: response.deleteOn,
      _isDelete: response.isDelete,
    });
  }

  private static async convertEMansionMailAddressDto(response: any | undefined): Promise<EMansionMailAddress | undefined> {
    if (!response) {
      return undefined;
    }

    return new EMansionMailAddress({
      _eMansionMailAddressId: response.eMansionMailAddressId,
      _eMansionAccountId: response.eMansionAccountId,
      _xMailUserId: response.xMailUserId,
      _xMailAuthId: response.xMailAuthId,
      _myeRegisterStatus: convertMyeRegisterStatusById(Number(response.myeRegisterStatus)),
      _xMailRegisterStatus: convertXMailRegisterStatusById(Number(response.xMailRegisterStatus)),
      _xMailPasswordRegisterStatus: convertXMailPasswordRegisterStatusById(Number(response.xMailPasswordRegisterStatus)),
      _mailLocalPart: response.mailLocalPart,
      _mailSubdomain: response.mailSubdomain,
      _mailAddress: response.mailAddress,
      _initialMailPassword: response.initialMailPassword,
      _mailPassword: response.mailPassword,
      _mailAddressOriginal: response.mailAddressOriginal,
      _popServer: response.popServer,
      _smtpServer: response.smtpServer,
      _registerSubscriptionOn: response.registerSubscriptionOn,
      _registerOn: response.registerOn,
      _registerProcessOn: response.registerProcessOn,
      _cancelSubscriptionOn: response.cancelSubscriptionOn,
      _cancelOn: response.cancelOn,
      _cancelProcessOn: response.cancelProcessOn,
      _deleteOn: response.deleteOn,
      _isDelete: response.isDelete,
    });
  }

  private static async createEMansionAccountInfo(
    eMansionAccount: EMansionAccount,
    eMansionMailAddress: EMansionMailAddress | undefined,
    hasMailAddressAsMember: boolean,
    hasMainMailAddressAsMember: boolean,
    hasHomepageAsLoginUser: boolean,
    isOpenedHomepage: boolean,
    serviceInTime: Date
  ): Promise<EMansionAccountInfo> {
    return new EMansionAccountInfo(
      eMansionAccount,
      eMansionMailAddress,
      hasMailAddressAsMember,
      hasMainMailAddressAsMember,
      hasHomepageAsLoginUser,
      isOpenedHomepage,
      serviceInTime
    );
  }

  /**
   * e-mansion 通知先メールを取得する
   *
   * @param eMansionAccountNotifyEmail
   */
  public static async getEMansionAccountNotifyEmail(initEMansionMainAccount: eMansionNotifyMailAddressCheckRequest): Promise<any> {
    try {
      const response = await api.get<any>('/common/e-mansion/get-notify-address', { params: initEMansionMainAccount });
      const messages = response.data?.messages?.[0];

      if (messages) {
        const status = response.data.status;
        return { messages, status };
      }
    } catch (error) {
      await JudgeError.judgeError(error);
      throw error;
    }

    return;
  }
}
