<template>
  <div class="blc">
    <h3 class="service-h3">ご請求内容の確認方法</h3>
    <p>ご請求明細はe-mansion会員専用トップページの「ご請求明細・販売証明書表示」メニューからご確認いただけます。</p>
    <p v-if="!isSlimPlan">
      また、毎月のご請求確定時にメールでお知らせすることができます。<br />
      メールの配信を希望される場合は「e-mansion お知らせメールの配信設定」メニューからお申し込みください。
    </p>
    <p class="bold">お問い合わせ先：e-mansionサポートセンター</p>
    <p>会員サポートメニューよりカスタマーサポートまでお問い合わせください。</p>
  </div>
</template>

<style lang="css">
.bold {
  font-weight: bold;
  margin-top: 20px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import useVuelidate from '@vuelidate/core';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
/** エラーメッセージ表示エリアコンポーネント */
export default defineComponent({
  name: 'contract-details-deliver-method',
  components: {
    LoadingComponent,
  },
  data: () => ({
    /** スリムプラン物件かどうか
         *　物件基本情報.apartment_special_type_flag[4]
            スリムプラン:true, スリムプラン以外:false
         */
    isSlimPlan: false,
    /** サービス種別 */
    planType: '',
  }),
  setup: () => ({ v$: useVuelidate() }),
  async mounted() {
    await StoreExternalApiResponse.main();
    // 物件情報を確認(会員種別で確認) 表示項目だし分け
    this.planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
    if (!this.planType) {
      this.planType = await this.$store.dispatch('servicePlanTypeStore/servicePlanType');
    }
    if (this.planType === SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL || this.planType === SERVICE_PLAN_TYPE.EM_SLIM) {
      this.isSlimPlan = true;
    }
  },
});
</script>
