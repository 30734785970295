export class eMansionNotifyMailAddressCheckRequest {
  private readonly _primaryKeyMye!: string;
  private readonly _apartmentId!: string;
  private readonly _servicePlanType!: string;
  private readonly _uaType!: string;

  constructor(primaryKeyMye: string, apartmentId: string, servicePlanType: string, uaType: string) {
    this._primaryKeyMye = primaryKeyMye;
    this._apartmentId = apartmentId;
    this._servicePlanType = servicePlanType;
    this._uaType = uaType;
  }

  get primaryKeyMye(): string {
    return this._primaryKeyMye;
  }

  get apartmentId(): string {
    return this._apartmentId;
  }

  get servicePlanType(): string {
    return this._servicePlanType;
  }

  get uaType(): string {
    return this._uaType;
  }
}
