<template>
  <div class="five-a-payment-methods-register">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>Five.A お支払い方法</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/5a/">Five.A会員専用トップ</router-link></li>
        <li>お支払い方法</li>
      </ul>

      <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
        <li class="stay">お支払い方法登録</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お支払い方法の登録" />お支払い方法の登録</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <div>
            <veritrans-credit-card-component-isp
              v-if="!isLoading"
              :key="resetCounter"
              :reset-counter="resetCounter"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispPaymentMethodType="fiveAPaymentMethodType"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
              v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
              v-bind:cardHolderNameFromIsp="cardHolderNameFromIsp"
              v-bind:ispName="ispName"
              v-on:cardTokenForRegister="getCardTokenForRegister"
              v-on:cardTokenExpireDateForRegister="getCardTokenExpireDateForRegister"
              v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:change="isAgreedCopyCardFromPortas = $event"
              v-on:radioValueRequiringCardCopy="getRadioValueRequiringCardCopy"
            />
          </div>
        </div>

        <div class="blc">
          <p v-if="isRequiredDisplayCreditCardNotice()" class="border-grey bg-grey pd20 mt40 ml40">
            ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
            ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
          </p>
          <p class="form-btn-txt mt40">
            ご確認の上、よろしければ「お支払い方法を保存」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」ボタンをクリックしてください。
          </p>
          <p class="red form-btn-txt">
            「お支払い方法を保存」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
          </p>

          <div class="btn-area">
            <div v-if="isRequiredDisplayCreditCardNotice()">
              <input id="isAgreedPrivacyPolicy" v-model="isAgreedPrivacyPolicy" type="checkbox" name="isAgreedPrivacyPolicy" />
              <label for="isAgreedPrivacyPolicy"> クレジットカード番号および本人認証に必要な情報の提供に同意します。</label>
            </div>
          </div>
          <div class="btn-area">
            <button class="btn-height btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
            <button class="btn-height btn btn01 bs" type="button" :disabled="isDisabledButton" v-on:click="onApply()">
              お支払い方法を保存<i class="material-icons link link-icon">east</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.error-input {
  background-color: #ed7d89;
}

.btn-height {
  height: 50px;
}

.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}

div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { PaymentMethodCopyForIspResponse } from '@/shared/classes/platform/payment-method-copy-For-Isp-response';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { PaymentMethodListResponse } from '@/shared/classes/platform/payment-method-list-response';
import { RegisterPaymentMethodInfoResponse } from '@/shared/classes/platform/register-payment-method-info-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import VeritransCreditCardComponentIsp from '@/shared/components/veritrans-credit-card-component-for-isp.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { SERVICE_NAME, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { isCreditCardExpired } from '@/shared/util/func-is-credit-card-expired';
import { processYearNumber } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';

interface PaymentMethodInfoForDisplay extends PaymentMethodListResponse {
  isExpired: boolean;
}

export default defineComponent({
  name: 'five-a-payment-methods-register',
  components: {
    ErrorMessagesComponent,
    VeritransCreditCardComponentIsp,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** クレジットカード登録用カードトークン */
    cardTokenForRegister: '',
    /** クレジットカード登録用カードトークン有効期限 */
    cardTokenExpireDateForRegister: '',
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    /** APIトークンキー */
    apiTokenKey: '',
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '続いて必要情報を入力の上、画面下の「設定する」をクリックしてください。',
    /** ボタン押下判定 */
    isSubmitting: false,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    /** Five.A の支払方法種別 */
    fiveAPaymentMethodType: '',
    /** Five.A から取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** Five.A に登録済のVeriTransAccountId */
    fiveAVeriTransAccountId: '',
    /** Five.A から取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** ISPから取得したカード名義人 */
    cardHolderNameFromIsp: '' as string | undefined,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Portasに支払方法が登録されている場合はマスク化されたクレジットカード番号を登録する */
    maskedCardNumberOnPortas: '',
    /** Portasに支払方法が登録されている場合のカード保有者名 */
    cardholderNameOnPortas: '' as string | undefined,
    /** Portasに支払方法が登録されている場合はカードの有効期限を登録する */
    cardExpiredOnPortas: '',
    /** ユーザーが Five.A へカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardFromPortas: false,
    /** ISPへ登録済みのクレジットカードをコピーするラジオボタン選択状態 */
    radioValueRequiringCardCopy: '',
    /** ログイン中のPortasユーザーが連携中の外部ISP名 */
    ispName: SERVICE_NAME.FIVE_A,
    /** クレジットカードコンポーネント初期化用カウント */
    resetCounter: 0,
    /** apartmentId */
    apartmentId: '',
    /** Mye に登録されている氏名 メール送信用 */
    myeMemberName: '',
    /** Mye に登録されているメールアドレス メール送信用 */
    myeMailAddress: '',
    oemsMaster: [] as Array<GetOemsMasterResponse>, // OEMマスタ
    domainName: '', // 「こちら」リンク用
    apid: '',
  }),
  async mounted(): Promise<void> {
    if (!(await MountedCheckService.canReadFiveAScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    // APIトークンキーを設定
    this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION;

    // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
    if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
      this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
      this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
      // エラーメッセージストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);
    }

    const member: Member = this.$store.getters['memberStore/member'];

    // 会員情報に物件が紐づいていない or e-mansionアカウントが紐づいていなければエラーとする
    if (!member.propertyId || !member.primaryKeyMye) {
      // データ不整合エラー
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
    }

    const property: Property | null = this.$store.getters['propertyStore/property'];

    try {
      //ドメイン名の取得 */
      if (property?.oemType) {
        this.oemsMaster = await this.$store.dispatch('oemsStore/oems');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filtered = this.oemsMaster.filter((oem: any) => oem.oemType === String(property?.oemType));
        if (filtered.length >= 1) {
          let latestOem = filtered[0];
          if (filtered.length >= 2) {
            for (let i = 1; i < filtered.length; i++) {
              if (latestOem.startAt < filtered[i].startAt) {
                latestOem = filtered[i];
              }
            }
          }
          this.domainName = latestOem.domainForOsumai;
        }
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
      }
    } catch (error: any) {
      // 共通エラーに遷移
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    // apartment_id → APID に変換
    this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));

    // 利用可能なe-mansionのサービスが紐付けられていなければエラーとする
    if (property && !property.apartmentId) {
      // データ不整合エラー
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
    }

    const paymentMethodOnPortas = await this.getPaymentMethodInfoList(member.id);

    this.apartmentId = property!.apartmentId!;

    // Portas上にクレジットカード情報が登録されている場合
    if (paymentMethodOnPortas) {
      this.maskedCardNumberOnPortas = paymentMethodOnPortas.cardNumber;
      this.cardExpiredOnPortas = paymentMethodOnPortas.cardExpire;
      this.cardholderNameOnPortas = paymentMethodOnPortas.cardholderName;
    }

    const params = {
      memberId: String(member.primaryKeyMye),
      query: { ua_type: UA_TYPE.FIVE_A, apartment_id: property!.apartmentId },
    };

    /** 契約基本情報取得API レスポンス */
    const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', params);

    if (customer instanceof EMansionCustomer) {
      // 顧客情報に物件情報が紐付けられていなければエラーとする
      if (!customer.property_id) {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
      }
    } else {
      // データ不整合エラー
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
    }

    if (customer instanceof EMansionCustomer) {
      this.myeMemberName = customer.name;
      this.myeMailAddress = customer.notify_mailaddress;

      if (customer.payment_id === '8' && member.eTncMemberId) {
        const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
        this.cardExpiredFromIsp = cardInfo.cardExpire;
        this.maskedCardNumberFromIsp = cardInfo.cardNumber;
        this.cardHolderNameFromIsp = cardInfo.cardholderName;
        this.fiveAVeriTransAccountId = member.eTncMemberId;
        this.fiveAPaymentMethodType = 'creditCard';

        // 契約基本情報取得APIのレスポンス.支払い方法種別が口座振替の場合
      } else if (customer.payment_id === '0') {
        this.fiveAPaymentMethodType = 'accountTransfer';
        // 契約基本情報取得APIのレスポンス.支払い方法種別が口振(請求書)の場合
      } else if (customer.payment_id === '4') {
        this.fiveAPaymentMethodType = 'accountTransferInvoice';
      }
    }
    if (member.veritransAccountId) {
      this.portasVeriTransId = member.veritransAccountId;
    }

    this.isLoading = false;
  },
  methods: {
    /** 「お支払い方法を保存 ➡」ボタン押下時 */
    async onApply() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;

      // エラーメッセージストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      const member: Member = this.$store.getters['memberStore/member'];

      // ISPに登録済みのクレジットカード情報をコピーすることに同意しているとき
      if (this.isAgreedCopyCardFromPortas) {
        try {
          await this.copyPaymentMethodFromPortas(member.eTncMemberId!, this.portasVeriTransId, UA_TYPE.FIVE_A);
        } catch (e: any) {
          this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
          this.errorMessages.push(
            `クレジットカード情報のコピーに失敗しました。<br>Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`
          );

          this.isAgreedPrivacyPolicy = false;
          this.isAgreedCopyCardFromPortas = false;
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.addResetCounter();
          this.isSubmitting = false;

          return;
        }

        // クレジットカード情報登録API を呼び出して Mye と同期する
        try {
          await this.registerPaymentMethodInfo(
            String(member.id),
            this.myeMemberName,
            this.myeMailAddress,
            this.apartmentId,
            member.primaryKeyMye!,
            UA_TYPE.FIVE_A,
            SERVICE_PLAN_TYPE.DEFAULT
          );
        } catch (e: any) {
          if (!e.response.data.errors?.includes('Mail Sending Error')) {
            await SpfApiService.deleteIspCard(UA_TYPE.FIVE_A, member.eTncMemberId!, SERVICE_PROVIDER.MYE);
            this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
            this.errorMessages.push(
              `クレジットカード情報のコピーに失敗しました。<br>Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`
            );

            this.isAgreedPrivacyPolicy = false;
            this.isAgreedCopyCardFromPortas = false;
            // エラーメッセージを見せるために画面最上部にスクロール
            this.addResetCounter();

            window.scrollTo(0, 0);
            this.isSubmitting = false;

            return;
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/5a/payment-methods/register-completed').catch((error: any) => {
          checkRouterError(error);
        });

        // ボタン押下解除
        this.isSubmitting = false;
        return;
      }

      if (!(this.cardTokenForRegister && this.cardTokenFor3dSecureAuthorize)) {
        // クレジットカードトークンが発行されていない場合はエラー
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        this.isSubmitting = false;
        this.isAgreedPrivacyPolicy = false;
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      }

      const uuidForTemporarySavingApplicationData = uuidv4();

      /**  申込内容一時保存API へ渡す申込データ */
      const ispPaymentMethodRegistrationData = {
        memberId: member.id,
        myeMemberName: this.myeMemberName,
        myeMailAddress: this.myeMailAddress,
        apartmentId: this.apartmentId,
        primaryKeyMye: member.primaryKeyMye,
        creditCardToken: this.cardTokenForRegister,
        tokenExpireDate: this.cardTokenExpireDateForRegister,
        veriTransMemberId: member.eTncMemberId,
        uaType: UA_TYPE.FIVE_A,
        serviceProviderIsp: SERVICE_PROVIDER.MYE,
        servicePlanType: SERVICE_PLAN_TYPE.DEFAULT,
      };

      const ispPaymentMethodRegistrationDataJson = JSON.stringify(ispPaymentMethodRegistrationData);

      const temporarySavingApplicationDataRequest = (() => {
        if (this.maskedCardNumberFromIsp) {
          return new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: ispPaymentMethodRegistrationDataJson,
            subsequentProcess: 'five-a-payment-methods-update',
          });
        } else {
          return new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: ispPaymentMethodRegistrationDataJson,
            subsequentProcess: 'five-a-payment-methods-register',
          });
        }
      })();

      try {
        await this.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
      }

      localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

      const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
        uuid: uuidForTemporarySavingApplicationData,
        creditCardToken: this.cardTokenFor3dSecureAuthorize,
        serviceProviderIsp: SERVICE_PROVIDER.MYE,
      });

      // VeriTrans_本人認証(トークン使用)API の実行
      try {
        const authStartURL = await this.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
        location.href = authStartURL;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
          // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
          this.errorMessages.push(
            `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`
          );
        } else {
          throw error;
        }
        // コンポーネントの破棄・再描画のために key値 を変更する
        this.addResetCounter();

        this.isSubmitting = false;

        // 作成されたトークン情報を削除して「お支払い方法を保存」ボタンを非活性化する
        this.cardTokenForRegister = '';
        this.cardTokenFor3dSecureAuthorize = '';
        this.isAgreedPrivacyPolicy = false;
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      }
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/5a')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /** クレジットカード登録用クレジットカードトークンを受け取る */
    getCardTokenForRegister(cardTokenForRegister: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.cardTokenForRegister = cardTokenForRegister;
    },
    /** クレジットカード登録用クレジットカードトークン有効期限を受け取る */
    getCardTokenExpireDateForRegister(cardTokenExpireDateForRegister: string) {
      this.cardTokenExpireDateForRegister = cardTokenExpireDateForRegister;
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /** 申込内容一時保存API の実行 */
    async temporarySavingApplicationData(applicationData: TemporarySavingApplicationDataRequest) {
      return await SpfApiService.temporarySavingApplicationData(applicationData);
    },
    /** VeriTransの３Dセキュア本人認証（トークン使用） API の実行 */
    async create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest: Create3dSecureAuthStartInfoWithCardTokenRequest): Promise<string> {
      return await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /** ISP支払方法コピーAPI 実行 */
    async copyPaymentMethodFromPortas(veriTransMemberId: string, originalAccountId: string, uaType: string): Promise<PaymentMethodCopyForIspResponse> {
      return await SpfApiService.copyToIspCard(uaType, veriTransMemberId, originalAccountId, SERVICE_PROVIDER.MYE);
    },
    /** クレジットカード情報登録API 実行 */
    async registerPaymentMethodInfo(
      memberId: string,
      myeMemberName: string,
      myeMailAddress: string,
      apartmentId: string,
      primaryKeyMye: string,
      uaType: string,
      servicePlanType: string
    ): Promise<RegisterPaymentMethodInfoResponse> {
      return await SpfApiService.registerPaymentMethodInfo(memberId, myeMemberName, myeMailAddress, uaType, apartmentId, primaryKeyMye, servicePlanType);
    },

    /** 支払方法一覧取得 APIを実行し、画面表示用のプロパティを追加して実行結果を取得する */
    async getPaymentMethodInfoList(memberId: number): Promise<PaymentMethodInfoForDisplay | null> {
      const paymentMethodList = await SpfApiService.getPaymentMethodList(memberId);
      let paymentMethodInfo: PaymentMethodInfoForDisplay | null = null;

      for (const eachPaymentMethod of paymentMethodList) {
        const eachPaymentMethodForDisplay: PaymentMethodInfoForDisplay = {
          ...eachPaymentMethod,
          isExpired: isCreditCardExpired(eachPaymentMethod.cardExpire.slice(0, 2), String(processYearNumber()).slice(0, 2) + eachPaymentMethod.cardExpire.slice(3, 5)),
        };
        if (!(eachPaymentMethod.deleteDate || eachPaymentMethodForDisplay.isExpired)) {
          paymentMethodInfo = eachPaymentMethodForDisplay;
          return paymentMethodInfo;
        }
      }
      return paymentMethodInfo;
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    getRadioValueRequiringCardCopy(radioValueRequiringCardCopy: string) {
      this.radioValueRequiringCardCopy = radioValueRequiringCardCopy;
    },
    isRequiredDisplayCreditCardNotice(): boolean {
      if (this.fiveAPaymentMethodType === 'creditCard') {
        // Five.Aの支払方法がクレジットカードの場合、Portasの支払方法に寄らず、コピー不可（チェックボックス・注意事項は表示が必要）
        return true;
      } else if (this.portasVeriTransId) {
        if (this.radioValueRequiringCardCopy === 'yes') {
          return false;
        } else if (this.maskedCardNumberOnPortas && this.radioValueRequiringCardCopy === '') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },

  computed: {
    isDisabledButton(): boolean {
      // ユーザーがカード情報を画面に入力し、支払方法の登録を実施する場合
      if (this.cardTokenForRegister && this.cardTokenFor3dSecureAuthorize && this.isAgreedPrivacyPolicy) {
        return false;
        // ユーザーがカード情報をコピーする場合
      } else if (this.isAgreedCopyCardFromPortas) {
        return false;
      } else {
        return true;
      }
    },
  },
});
</script>
