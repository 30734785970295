<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<template>
  <div class="e-mansion-connectix-confirm">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>Connectix お申し込み 確認</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>e-mansion Connectixお申し込み</li>
        <li>お申し込み内容の確認</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix お申し込み" />Connectix&emsp;お申し込み内容の確認</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <h3 class="service-h3">お申し込み対象のお部屋番号</h3>
          <p class="red">ご指定のお部屋番号に本サービスを適用します。</p>
          <table class="table-type2">
            <tr>
              <th>マンション名</th>
              <td>{{ mansionName }}</td>
            </tr>
            <!-- 顧客の物件に棟名が存在する場合、棟名のプルダウンを表示する -->
            <tr v-if="buildingName">
              <th>棟名</th>
              <td>{{ buildingName }}</td>
            </tr>
            <tr>
              <th>部屋番号</th>
              <td>{{ roomNumber }}</td>
            </tr>
          </table>
        </div>
        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th>料金（月額）</th>
              <td>
                <div v-if="connectixUsageFeeCp">
                  <div style="color: red">キャンペーン中</div>
                  <div>{{ connectixUsageFee }} 円</div>
                  <div style="color: red">→ {{ connectixUsageFeeCp }} 円</div>
                </div>
                <div v-else>
                  <div>{{ connectixUsageFee }} 円</div>
                </div>
              </td>
            </tr>
          </table>
          <p class="att grey">※価格は全て新税率に基づく税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
        </div>
        <div>
          <h3 class="service-h3">お支払い方法</h3>
          <div v-if="noDisplayCreditCardForm">
            <p>ご登録のお支払い方法により、お支払いいただきます。</p>
          </div>
          <div v-else>
            <credit-card-component
              v-if="isMounted"
              :key="resetCounter"
              :reset-counter="resetCounter"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispName="ispName"
              v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
              v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-bind:cardHolderNameFromIsp="cardHolderNameFromIsp"
              v-on:onPostCreditCard="getCreditCardToken"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:getTokenExpireDate="getTokenExpireDate"
              v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
              v-on:change="isAgreedCopyCardToIsp = $event"
              v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
              v-on:portasCardHolderName="getCardHolderNameFromPortas"
              v-on:cardholderNameFormForAuthorizeIspCard="getCardholderNameFormForAuthorizeIspCard"
              v-on:cardholderNameFormForAuthorizePortasCard="getCardholderNameFormForAuthorizePortasCard"
            />
          </div>
        </div>
      </div>

      <div class="blc">
        <p v-if="isNeedDisableTrackingBlockFunction" class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
        </p>
        <p class="form-btn-txt mt40">
          ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」ボタンをクリックしてください。
        </p>
        <p class="red form-btn-txt">
          「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
        </p>
      </div>
      <div class="btn-area">
        <div>
          <button class="btn-height btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
        </div>
        <div class="has-checkbox">
          <button class="btn-height btn btn01 bs" type="button" :disabled="isDisabledButton" v-on:click="onApply()">
            お申し込みを確定する<i class="material-icons link link-icon">east</i>
          </button>
          <div class="my-checkbox-outer" v-if="!noDisplayCreditCardForm">
            <input id="checkbox-to-agree" type="checkbox" v-model="isAgreedPrivacyPolicy" :disabled="isExpiredPortasCreditCard" />
            <p class="my-checkbox-text"><label for="checkbox-to-agree">各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo-emansion.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.error-input {
  background-color: #ed7d89;
}

.btn-height {
  height: 50px;
}

.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}

div.btn-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  text-align: center;

  & div.has-checkbox {
    padding-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 16px;
      left: 0;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  div.btn-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 64px;

    & div.has-checkbox {
      padding-top: 0;

      & div.my-checkbox-outer {
        top: 0;
        left: 50%;
        text-align: left;

        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1275px) {
  div.button-area {
    padding-top: 40px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
/** クレジットカード用コンポーネント */
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
/** サービス UCOM Connectix 外部 API をコールするサービス */
import { EMansionConnectixExternalApiService } from '@/shared/services/external-api/connectix/e-mansion/e-mansion-connectix-external-api-service';
/** Entity UCOM Connectix API : エラー時のレスポンス */
import { EMansionConnectixErrorResponse } from './classes/external-api/connectix-error-response';
/** Entity e-mansion 共通利用 API : 契約基本情報取得 : レスポンス */
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { v4 as uuidv4 } from 'uuid';
import { EMansionCustomer } from '../../../shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '../../../shared/classes/external-api/e-mansion/property-response';
import { EMansionConnectixEntryInputForm } from './classes/entry-input-form';
import { EMansionConnectixNewConnectixRequest } from './classes/external-api/new-connectix-request';

/** Confirm コンポーネント */
export default defineComponent({
  name: 'e-mansion-connectix-confirm',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 物件名 */
    mansionName: '',
    /** 棟名 */
    buildingName: '',
    /** 棟ID */
    buildingId: '',
    /** 部屋番号 */
    roomNumber: '',
    /** Connectix利用金額 */
    connectixUsageFee: '' as string,
    /** Connectixキャンペーン利用金額 */
    connectixUsageFeeCp: '' as string,
    /** e-mansion 会員ID */
    eMansionMemberId: '' as string,
    /** 物件ID */
    eMansionPropertyId: '',
    /** UA種別 */
    uaType: '',
    /** 支払い方法の登録状態によってクレジットカードコンポーネントの表示/非表示を切り替える */
    noDisplayCreditCardForm: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** APIトークンキー */
    apiTokenKey: process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION,
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込みを確定する」ボタンをクリックしてください。',
    /** ボタン押下判定 */
    isSubmitting: false,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
    /** ユーザーがe-mansionへカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面ではe-mansion固定) */
    ispName: 'e-mansion',
    /** e-mansionから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** e-mansionから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** e-mansion に登録済のVeriTransAccountId */
    emansionVeriTransAccountId: '',
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: false,
    /** Portasから取得したカード名義人 */
    cardHolderNameOnPortas: '' as string | undefined,
    /** ISPから取得したカード名義人 */
    cardHolderNameFromIsp: '' as string | undefined,
    /** ISPで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizeIspCard: '' as string | undefined,
    /** Portasで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizePortasCard: '' as string | undefined,
    /** 本人認証処理を実行するため、ブラウザのトラッキング防止機能を無効化する必要があるかどうか */
    isNeedDisableTrackingBlockFunction: true,
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    resetCounter: 0,
    inputApply: {} as EMansionConnectixEntryInputForm,
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    apid: '',
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    try {
      if (!(await MountedCheckService.canReadEMansionScreen())) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      this.eMansionMemberId = member.primaryKeyMye ?? '';

      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];
      if (customer instanceof EMansionCustomer) {
        // 物件ID
        this.eMansionPropertyId = customer.property_id;
        // 登録している支払方法がクレジットカード以外の以下いずれかの場合、クレジットカード申請フォームおよびe-mansionで登録されたカード情報を表示しない
        /**
         * 0: 口座振替
         * 1: 振込
         * 4: 口振（請求書）
         */

        const paymentIdsWithoutCreditCard = ['0', '1', '4'];
        if (paymentIdsWithoutCreditCard.some((paymentIdsWithoutCreditCard) => paymentIdsWithoutCreditCard === customer.payment_id)) {
          this.noDisplayCreditCardForm = true;
          this.isNeedDisableTrackingBlockFunction = false;
        }
      } else {
        // 外部APIエラー
        await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO), '');
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        // 物件名
        this.mansionName = eMansionProperty.em_ap_name;
        // Connectix利用金額
        this.connectixUsageFee = eMansionProperty.op.sdwan.fee;
        this.connectixUsageFeeCp = eMansionProperty.op.sdwan.fee_cp;
      } else {
        // 外部APIエラー
        await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO), '');
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      if (eMansionProperty?.IN_APID) {
        this.apid = eMansionProperty?.IN_APID;
      }

      // 入力画面で入力した情報をStoreから取得
      this.inputApply = this.$store.getters['eMansionConnectixEntryStore/entryInputForm'];
      if (this.inputApply == null) {
        // データ不整合エラー
        await VueErrorHandler.handle(new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA), '');
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      // 部屋番号
      this.roomNumber = this.inputApply.roomNumber;
      // 棟名
      this.buildingName = this.inputApply.buildingName;
      // 棟ID
      this.buildingId = this.inputApply.buildingId;

      if (customer instanceof EMansionCustomer && customer.payment_id === '8' && member.eTncMemberId) {
        const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
        this.cardExpiredFromIsp = cardInfo.cardExpire;
        this.maskedCardNumberFromIsp = cardInfo.cardNumber;
        this.cardHolderNameFromIsp = cardInfo.cardholderName;
        this.emansionVeriTransAccountId = member.eTncMemberId;
      } else if (member.veritransAccountId) {
        this.portasVeriTransId = member.veritransAccountId;
      }
      // VeriTrans 本人認証失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
        this.creditCardAccessToken = '';
        this.creditTokenExpireDate = '';
      }
      this.isLoading = false;
      this.isMounted = true;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    /** 「お申し込み ➡」ボタン押下時 : Connectixお申し込みAPIを実行し お申し込み完了画面に遷移する */
    async onApply() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      // クレジットカードトークンがない かつ e-mansionに登録された支払方法が存在しない かつ Portasからカード情報をコピーして支払方法を登録しない場合はエラー
      if (this.creditCardAccessToken === '' && !(this.cardExpiredFromIsp && this.maskedCardNumberFromIsp) && !this.isAgreedCopyCardToIsp && !this.noDisplayCreditCardForm) {
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        // エラーメッセージを見せるために画面最上部にスクロール
        this.showErrorMessage();
        return;
      }

      // ISP(e-mansion)側で退会申し込み中のとき、エラーメッセージを表示させる
      if (await this.isInCancelApplication()) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/connectix/e-mansion/terms').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      if (this.noDisplayCreditCardForm) {
        // Connectix申し込みAPIを実行
        const request = new EMansionConnectixNewConnectixRequest({
          /** UA種別 */
          ua_type: UA_TYPE.E_MANSION,
          /** 会員ID */
          member_id: this.eMansionMemberId,
          /** 物件ID */
          property_id: this.eMansionPropertyId,
          /** 棟名 */
          building_id: this.buildingId,
          /** 部屋番号 */
          room_number: this.roomNumber,
          /**カード登録フラグ */
          credit_card_regist_flag: '0',
        });

        try {
          const resultCreateConnectix = await EMansionConnectixExternalApiService.createConnectix(request);
          if (resultCreateConnectix instanceof EMansionConnectixErrorResponse) {
            // エラーコードがCNTX008(業務エラー)の場合エラーメッセージ表示
            if (resultCreateConnectix.code === 'CNTX008') {
              const errorMessage = `Connectixのお申し込みを受け付けることができません。<br>恐れ入りますが、「<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>」からe-mansionサポートセンターへご連絡ください。`;
              this.errorMessages.push(errorMessage);
              this.showErrorMessage();
              return;
            } else {
              // 外部APIエラー
              await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONNECTIX), '');
              await this.$router.push('/e-mansion/error').catch((error) => {
                checkRouterError(error);
              });
              return;
            }
          }
        } catch (error: any) {
          await VueErrorHandler.handle(error, '');
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }

        // お申し込み完了画面に遷移
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/connectix/e-mansion/completed').catch((error: any) => {
          checkRouterError(error);
        });
        return;
        // クレジットカードが e-mansion に登録されている場合の申し込み処理
      } else if (this.emansionVeriTransAccountId) {
        // 申込内容一時保存処理

        // 申込内容一時保存用 UUIDを生成
        const uuidForTemporarySavingApplicationData = uuidv4();

        const paymentMethodApplicationData = {
          uaType: UA_TYPE.E_MANSION,
          memberId: this.eMansionMemberId,
          propertyId: this.eMansionPropertyId,
          buildingId: this.buildingId,
          roomNumber: this.roomNumber,
          veriTransAccountId: this.emansionVeriTransAccountId,
          entryInputForm: this.inputApply,
          isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
          creditCardRegistFlag: '0',
        };

        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);

        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'e-mansion-connectix-apply',
        });

        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA), '');
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }

        // 3dセキュア対応用 uuid localStorageに保存
        localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

        let cardHolderNameForAuthorize: string;
        if (this.cardHolderNameFromIsp) {
          cardHolderNameForAuthorize = this.cardHolderNameFromIsp;
        } else {
          cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
        }

        const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
          uuid: uuidForTemporarySavingApplicationData,
          externalVeritransAccountId: this.emansionVeriTransAccountId,
          cardholderName: cardHolderNameForAuthorize,
        });

        // VeriTrans_本人認証(VeriTrans会員ID使用) API
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
          location.href = authStartURL;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
            );
            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

            // コンポーネントの破棄・再描画のために key値 を変更する
            this.addResetCounter();
            this.isAgreedPrivacyPolicy = false;

            this.isSubmitting = false;

            // エラーメッセージを見せるために画面最上部にスクロール
            window.scrollTo(0, 0);
            return;
          } else {
            await VueErrorHandler.handle(error, '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }
        }
        return;
      } else if (
        // PortasからISPにカード情報をコピーする場合
        this.isAgreedCopyCardToIsp &&
        this.portasVeriTransId
      ) {
        // 申込内容一時保存処理

        // 申込内容一時保存用 UUIDを生成
        const uuidForTemporarySavingApplicationData = uuidv4();

        const paymentMethodApplicationData = {
          uaType: UA_TYPE.E_MANSION,
          memberId: this.eMansionMemberId,
          propertyId: this.eMansionPropertyId,
          buildingId: this.buildingId,
          roomNumber: this.roomNumber,
          veriTransAccountId: this.portasVeriTransId,
          entryInputForm: this.inputApply,
          isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
          creditCardRegistFlag: '1',
        };

        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);

        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'e-mansion-connectix-apply',
        });

        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA), '');
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }

        // 3dセキュア対応用 uuid localStorageに保存
        localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

        let cardHolderNameForAuthorize: string;
        if (this.cardHolderNameOnPortas) {
          cardHolderNameForAuthorize = this.cardHolderNameOnPortas;
        } else {
          cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizePortasCard!;
        }

        const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
          uuid: uuidForTemporarySavingApplicationData,
          externalVeritransAccountId: this.portasVeriTransId,
          cardholderName: cardHolderNameForAuthorize,
        });

        // VeriTrans_本人認証(VeriTrans会員ID使用) API
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
          location.href = authStartURL;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
            );
            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

            // コンポーネントの破棄・再描画のために key値 を変更する
            this.addResetCounter();
            this.isAgreedPrivacyPolicy = false;

            this.isSubmitting = false;

            // エラーメッセージを見せるために画面最上部にスクロール
            window.scrollTo(0, 0);
            return;
          } else {
            await VueErrorHandler.handle(error, '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }
        }
        return;
      } else {
        /**Portas,e-mansionにカードが未登録の場合 */
        // 申込内容一時保存処理

        // 申込内容一時保存用 UUIDを生成
        const uuidForTemporarySavingApplicationData = uuidv4();

        const paymentMethodApplicationData = {
          uaType: UA_TYPE.E_MANSION,
          memberId: this.eMansionMemberId,
          propertyId: this.eMansionPropertyId,
          buildingId: this.buildingId,
          roomNumber: this.roomNumber,
          creditCardToken: this.creditCardAccessToken,
          tokenExpireDate: this.creditTokenExpireDate,
          entryInputForm: this.inputApply,
          isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
          creditCardRegistFlag: '1',
        };

        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);

        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'e-mansion-connectix-apply',
        });

        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA), '');
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }

        // 3dセキュア対応用 uuid localStorageに保存
        localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

        // ページ遷移時に beforeEach で会員情報取得できるように null にする
        this.$store.commit('memberStore/member', null);
        const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
          uuid: uuidForTemporarySavingApplicationData,
          creditCardToken: this.cardTokenFor3dSecureAuthorize,
          serviceProviderIsp: SERVICE_PROVIDER.MYE,
        });

        // VeriTrans_本人認証(トークン使用) API
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
          location.href = authStartURL;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
            );
            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

            // コンポーネントの破棄・再描画のために key値 を変更する
            this.addResetCounter();
            this.isAgreedPrivacyPolicy = false;

            this.isSubmitting = false;

            // エラーメッセージを見せるために画面最上部にスクロール
            window.scrollTo(0, 0);
            return;
          } else {
            await VueErrorHandler.handle(error, '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }
        }
      }
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/connectix/e-mansion/apply')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    async isInCancelApplication() {
      const property: Property = this.$store.getters['propertyStore/property'];

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: this.eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO), '');
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      return false;
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** 3Dセキュア本人認証用カード名義人を受け取る */
    getCardHolderNameFromPortas(cardHolderName: string) {
      this.cardHolderNameOnPortas = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、ISP登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizeIspCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizeIspCard = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、Portas登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizePortasCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizePortasCard = cardHolderName;
    },
  },
  computed: {
    isDisabledButton(): boolean {
      if (this.noDisplayCreditCardForm) {
        return false;
      } else if (
        this.emansionVeriTransAccountId &&
        (this.cardHolderNameFromIsp || (this.cardholderNameFormForAuthorizeIspCard && this.cardholderNameFormForAuthorizeIspCard.length > 1)) &&
        this.isAgreedPrivacyPolicy
      ) {
        return false;
      } else if (
        !this.isExpiredPortasCreditCard &&
        (this.cardHolderNameOnPortas || this.cardholderNameFormForAuthorizePortasCard) &&
        this.isAgreedCopyCardToIsp &&
        this.isAgreedPrivacyPolicy
      ) {
        return false;
      } else {
        return this.creditCardAccessToken === '' || !this.isAgreedPrivacyPolicy;
      }
    },
  },
});
</script>
