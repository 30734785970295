import { Module } from 'vuex';
import { RootState } from '../index';

/** selectedCourseのState */
const selectedCourseState: {
  equipmentcontract: string | null;
  speed: string | null;
  charge: string | null;
  adapterCharge: string | null;
  adapterChargeCampaign: string | null;
  chargeCampaign: string | null;
  currentGrade: string | null;
  storeGrade: string | null;
  type: string | null;
  courseId: string | null;
  checkMyCurrentCourseId: boolean;
  contractDestinationKind: string | null;
  contractDestinationMailaddress: string | null;
  contractDestinationMailaddressConfirm: string | null;
  contractDestinationZipcode: string | null;
  contractDestinationAddress1: string | null;
  contractDestinationAddress2: string | null;
  paymentIssueMsg: string[] | null;
  chargeCp: string | null;
  isContainCpFee: string | null;
  emailaddress: string | null;
  zipcodeFormer: string | null;
  zipcodeLatter: string | null;
} = {
  equipmentcontract: null,
  speed: null,
  charge: null,
  adapterCharge: null,
  adapterChargeCampaign: null,
  chargeCampaign: null,
  currentGrade: null,
  storeGrade: null,
  type: null,
  courseId: null,
  checkMyCurrentCourseId: false,
  contractDestinationKind: null,
  contractDestinationMailaddress: null,
  contractDestinationMailaddressConfirm: null,
  contractDestinationZipcode: null,
  contractDestinationAddress1: null,
  contractDestinationAddress2: null,
  paymentIssueMsg: null,
  chargeCp: null,
  isContainCpFee: null,
  emailaddress: null,
  zipcodeFormer: null,
  zipcodeLatter: null,
};

/** State の型 */
type SelectedCourseState = typeof selectedCourseState;

export const selectedCourseStore: Module<SelectedCourseState, RootState> = {
  namespaced: true,
  state: selectedCourseState,
  actions: {},
  mutations: {
    setEquipmentcontract: (state, value: string) => {
      state.equipmentcontract = value;
    },
    setSpeed: (state, value: string) => {
      state.speed = value;
    },
    setCharge: (state, value: string) => {
      state.charge = value;
    },
    setChargeCp: (state, value: string) => {
      state.chargeCp = value;
    },
    adapterCharge: (state, value: string) => {
      state.adapterCharge = value;
    },
    adapterChargeCampaign: (state, value: string) => {
      state.adapterChargeCampaign = value;
    },
    chargeCampaign: (state, value: string) => {
      state.chargeCampaign = value;
    },
    currentGrade: (state, value: string) => {
      state.currentGrade = value;
    },
    storeGrade: (state, value: string) => {
      state.storeGrade = value;
    },
    type: (state, value: string) => {
      state.type = value;
    },
    courseId: (state, value: string) => {
      state.courseId = value;
    },
    checkMyCurrentCourseId: (state, value: boolean) => {
      state.checkMyCurrentCourseId = value;
    },
    contractDestinationKind: (state, value: string) => {
      state.contractDestinationKind = value;
    },
    contractDestinationMailaddress: (state, value: string) => {
      state.contractDestinationMailaddress = value;
    },
    contractDestinationMailaddressConfirm: (state, value: string) => {
      state.contractDestinationMailaddressConfirm = value;
    },
    contractDestinationZipcode: (state, value: string) => {
      state.contractDestinationZipcode = value;
    },
    contractDestinationAddress1: (state, value: string) => {
      state.contractDestinationAddress1 = value;
    },
    contractDestinationAddress2: (state, value: string) => {
      state.contractDestinationAddress2 = value;
    },
    paymentIssueMsg: (state, value: string[]) => {
      state.paymentIssueMsg = value;
    },
    isContainCpFee: (state, value: string) => {
      state.isContainCpFee = value;
    },
    emailaddress: (state, value: string) => {
      state.emailaddress = value;
    },
    zipcodeFormer: (state, value: string) => {
      state.zipcodeFormer = value;
    },
    zipcodeLatter: (state, value: string) => {
      state.zipcodeLatter = value;
    },
  },

  getters: {
    equipmentcontract: (state): string | null => state.equipmentcontract,
    speed: (state): string | null => state.speed,
    charge: (state): string | null => state.charge,
    chargeCp: (state): string | null => state.chargeCp,
    adapterCharge: (state): string | null => state.adapterCharge,
    adapterChargeCampaign: (state): string | null => state.adapterChargeCampaign,
    chargeCampaign: (state): string | null => state.chargeCampaign,
    currentGrade: (state): string | null => state.currentGrade,
    storeGrade: (state): string | null => state.storeGrade,
    type: (state): string | null => state.type,
    courseId: (state): string | null => state.courseId,
    checkMyCurrentCourseId: (state): boolean => state.checkMyCurrentCourseId,
    contractDestinationKind: (state): string | null => state.contractDestinationKind,
    contractDestinationMailaddress: (state): string | null => state.contractDestinationMailaddress,
    contractDestinationMailaddressConfirm: (state): string | null => state.contractDestinationMailaddressConfirm,
    contractDestinationZipcode: (state): string | null => state.contractDestinationZipcode,
    contractDestinationAddress1: (state): string | null => state.contractDestinationAddress1,
    contractDestinationAddress2: (state): string | null => state.contractDestinationAddress2,
    paymentIssueMsg: (state): string[] | null => state.paymentIssueMsg,
    isContainCpFee: (state): string | null => state.isContainCpFee,
    emailaddress: (state): string | null => state.emailaddress,
    zipcodeFormer: (state): string | null => state.zipcodeFormer,
    zipcodeLatter: (state): string | null => state.zipcodeLatter,
  },
};
