/** e-mansion Connectix API : エラー時のレスポンス */
export class EMansionConnectixErrorResponse {
  /** エラーメッセージ */
  public errorInfo!: Array<string>;
  public status?: string;
  public code?: string;
  public information?: string;

  constructor(partial: Partial<EMansionConnectixErrorResponse>) {
    Object.assign(this, partial);
  }
}
