export const MYE_REGISTER_STATUS = {
  IN_CONTRACT_APPLICATION: { id: 1, value: '登録申込中' },
  CONTRACTED: { id: 2, value: '登録済み' },
  IN_WITHDRAWAL_APPLICATION: { id: 3, value: '解約申請中' },
  CANCELLED: { id: 4, value: '解約済み' },
} as const;
export type MYE_REGISTER_STATUS = typeof MYE_REGISTER_STATUS[keyof typeof MYE_REGISTER_STATUS];

export const E_MANSION_ACCOUNT_SUB_DOMAIN = {
  MAIL_SUB_DOMAIN: 'ea',
  HOME_PAGE_SUBDOMAIN: 'zk',
  ACCOUNT_SUB_DOMAIN: 'ec',
} as const;
export type E_MANSION_ACCOUNT_SUB_DOMAIN = typeof E_MANSION_ACCOUNT_SUB_DOMAIN[keyof typeof E_MANSION_ACCOUNT_SUB_DOMAIN];

export const E_MANSION_ACCOUNT_AUTHORITY = {
  MAIN_ACCOUNT: 1,
  ADDITIONAL_ACCOUNT: 2,
} as const;
export type E_MANSION_ACCOUNT_AUTHORITY = typeof E_MANSION_ACCOUNT_AUTHORITY[keyof typeof E_MANSION_ACCOUNT_AUTHORITY];

export const E_MANSION_ORIGINAL_DOMAIN = {
  NOT_ORIGINAL_DOMAIN: '0',
  ORIGINAL_DOMAIN: '1',
} as const;
export type E_MANSION_ORIGINAL_DOMAIN = typeof E_MANSION_ORIGINAL_DOMAIN[keyof typeof E_MANSION_ORIGINAL_DOMAIN];
export const hasEMansionOriginalDomain = (id: string): boolean => {
  return E_MANSION_ORIGINAL_DOMAIN.ORIGINAL_DOMAIN === id ? true : false;
};

export const E_MANSION_CAN_CHANGE_CAPACITY = {
  CAN: '0', // 変更可能
  CAN_NOT: '1', // 変更不可
} as const;
export const canChangeCapacity = (id: string): boolean => {
  return E_MANSION_CAN_CHANGE_CAPACITY.CAN === id ? true : false;
};

export const E_MANSION_SAME_MONTH_CHANGE = {
  NOT: '0', // 同月内変更なし
  SAME_MONTH_CHANGE: '1', // 同月内変更あり
} as const;
export const isSameMonthChange = (id: string): boolean => {
  return E_MANSION_SAME_MONTH_CHANGE.SAME_MONTH_CHANGE === id ? true : false;
};

export const UCOM_HIKARI_PHONE_STATUS_ON_MANSION = {
  APPLY: 10, // 申込
  APPLY_IPM_LINKED: 11, // 申込IPM連携済
  IN_USE: 12, // 利用中
  CANCEL_APPLY: 20, // 解約申込
  CANCEL_IPM_LINKED: 21, // 解約IPM連携済
} as const;
export type UCOM_HIKARI_PHONE_STATUS_ON_MANSION = typeof UCOM_HIKARI_PHONE_STATUS_ON_MANSION[keyof typeof UCOM_HIKARI_PHONE_STATUS_ON_MANSION];

export const UCOM_HIKARI_PHONE_NOTIFICATION_FLAG_ON_MANSION = {
  NO: 0, // 通知しない
  NOTICE: 1, // 通知する
} as const;
export type UCOM_HIKARI_PHONE_NOTIFICATION_FLAG_ON_MANSION = typeof UCOM_HIKARI_PHONE_NOTIFICATION_FLAG_ON_MANSION[keyof typeof UCOM_HIKARI_PHONE_NOTIFICATION_FLAG_ON_MANSION];

export const UCOM_HIKARI_PHONE_OPTION_ON_EMANSION = {
  NUMBER_DISPLAY: { number: 1, name: '着信番号表示', service_name: 'number_display' },
  CATCH: { number: 2, name: 'キャッチ通話', service_name: 'catch' },
  CATCH_NUMBER_DISPLAY: { number: 3, name: 'キャッチ通話番号表示', service_name: 'catch_number_display' },
  OPTION_PACK: { number: 4, name: 'オプションサービスパック', service_name: 'option_pack' },
} as const;
export type UCOM_HIKARI_PHONE_OPTION_ON_EMANSION = typeof UCOM_HIKARI_PHONE_OPTION_ON_EMANSION[keyof typeof UCOM_HIKARI_PHONE_OPTION_ON_EMANSION];

export const GIP_STATUS_ON_MANSION = {
  IN_USE: '12', // 利用中
  CANCEL_APPLY: '20', // 解約登録中
} as const;
export type GIP_STATUS_ON_MANSION = typeof GIP_STATUS_ON_MANSION[keyof typeof GIP_STATUS_ON_MANSION];
