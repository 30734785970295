import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import ConnectixEMansionApply from '@/pages/connectix/e-mansion/apply.vue';
import ConnectixEMansionCompleted from '@/pages/connectix/e-mansion/completed.vue';
import ConnectixEMansionConfirm from '@/pages/connectix/e-mansion/confirm.vue';
import ConnectixEMansionTerms from '@/pages/connectix/e-mansion/terms.vue';

import ConnectixUcomApply from '@/pages/connectix/ucom/apply.vue';
import ConnectixUcomCompleted from '@/pages/connectix/ucom/completed.vue';
import ConnectixUcomConfirm from '@/pages/connectix/ucom/confirm.vue';
import ConnectixUcomTerms from '@/pages/connectix/ucom/terms.vue';
import { isTransitionEmansion, isTransitionUcom } from '.';

import ConnectixFiveAApply from '@/pages/connectix/five-a/apply.vue';
import ConnectixFiveACompleted from '@/pages/connectix/five-a/completed.vue';
import ConnectixFiveAConfirm from '@/pages/connectix/five-a/confirm.vue';
import ConnectixFiveATerms from '@/pages/connectix/five-a/terms.vue';

import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { Property } from '@/shared/classes/spf-api/property';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { UA_TYPE } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import store from '@/store';

/** Connectix ルーティング定義 */
const connectixRouter = [
  {
    path: '/connectix/e-mansion/terms',
    name: 'e-mansion Connectix サービス規約',
    component: ConnectixEMansionTerms,
    meta: {
      title: 'Portas | e-mansion Connectix お申し込み | ポルタス',
      description: 'Portas（ポルタス）でのe-mansion Connectixのお申し込みはこちらから行ってください。サービス規約',
      keywords: 'e-mansion Connectixお申し込みサービス規約',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await isTransitionEmansion())) {
        return next({ path: '/platform' });
      }
      return next();
    },
  },
  {
    path: '/connectix/e-mansion/apply',
    name: 'e-mansion Connectix お申し込み',
    component: ConnectixEMansionApply,
    meta: {
      title: 'Portas | e-mansion Connectix お申し込み お客様情報入力 | ポルタス',
      description: 'Portas（ポルタス）でのe-mansion Connectixのお申し込みはこちらから行ってください。お客様情報入力',
      keywords: 'e-mansion Connectixお申し込みサービスお申し込み',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 直前のURLがサービス規約画面かお申し込み確認画面でなければサービス規約画面に遷移する
      if (from.path !== '/connectix/e-mansion/terms' && from.path !== '/connectix/e-mansion/confirm') {
        return next({ path: '/connectix/e-mansion/terms' });
      }
      return next();
    },
  },
  {
    path: '/connectix/e-mansion/confirm',
    name: 'e-mansion Connectix お申し込み確認',
    component: ConnectixEMansionConfirm,
    meta: {
      title: 'Portas | e-mansion Connectix お申し込み 確認 | ポルタス',
      description: 'Portas（ポルタス）でのe-mansion Connectixのお申し込みはこちらから行ってください。確認',
      keywords: 'e-mansion Connectixお申し込み確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 直前のURLがお申し込み画面でなければサービス規約画面に遷移する
      if (from.path !== '/connectix/e-mansion/apply' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/connectix/e-mansion/terms' });
      }
      return next();
    },
  },
  {
    path: '/connectix/e-mansion/completed',
    name: 'e-mansion Connectix お申し込み完了',
    component: ConnectixEMansionCompleted,
    meta: {
      title: 'Portas | e-mansion Connectix お申し込み 完了 | ポルタス',
      description: 'Portas（ポルタス）でのe-mansion Connectixのお申し込みはこちらから行ってください。完了',
      keywords: 'e-mansion Connectixお申し込み完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 直前のURLがお申し込み確認画面でなければサービス規約画面に遷移する
      if (from.path !== '/connectix/e-mansion/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/connectix/e-mansion/terms' });
      }
      return next();
    },
  },
  {
    path: '/connectix/ucom/terms',
    name: 'UCOM光レジデンス Connectix サービス規約',
    component: ConnectixUcomTerms,
    meta: {
      title: 'Portas | UCOM光 レジデンス Connectix お申し込み サービス規約 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス Connectixのお申し込みはこちらから行ってください。サービス規約',
      keywords: 'UCOM光 レジデンス Connectixお申し込みサービス規約',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await isTransitionUcom())) {
        return next({ path: '/platform' });
      }
      return next();
    },
  },
  {
    path: '/connectix/ucom/apply',
    name: 'UCOM光レジデンス Connectix お申し込み',
    component: ConnectixUcomApply,
    meta: {
      title: 'Portas | UCOM光 レジデンス Connectix お申し込み サービスお申し込み | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス Connectixのお申し込みはこちらから行ってください。サービスお申し込み',
      keywords: 'UCOM光 レジデンス Connectixお申し込みサービスお申し込み',
    },
    // 直前のURLがサービス規約画面かお申し込み確認画面でなければサービス規約画面に遷移する
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/connectix/ucom/terms' && from.path !== '/connectix/ucom/confirm') {
        return next({ path: '/connectix/ucom/terms' });
      }
      return next();
    },
  },
  {
    path: '/connectix/ucom/confirm',
    name: 'UCOM光レジデンス Connectix お申し込み確認',
    component: ConnectixUcomConfirm,
    meta: {
      title: 'Portas | UCOM光 レジデンス Connectix お申し込み 確認 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス Connectixのお申し込みはこちらから行ってください。確認',
      keywords: 'UCOM光 レジデンス Connectixお申し込み確認',
    },
    // 直前のURLがお申し込み画面でなければサービス規約画面に遷移する
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/connectix/ucom/apply' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/connectix/ucom/terms' });
      }
      return next();
    },
  },
  {
    path: '/connectix/ucom/completed',
    name: 'UCOM光レジデンス Connectix お申し込み完了',
    component: ConnectixUcomCompleted,
    meta: {
      title: 'Portas | UCOM光 レジデンス Connectix お申し込み 完了 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス Connectixのお申し込みはこちらから行ってください。完了',
      keywords: 'UCOM光 レジデンス Connectixお申し込み完了',
    },
    // 直前のURLがお申し込み確認画面または本人認証結果取得画面でなければサービス規約画面に遷移する
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(from.path === '/connectix/ucom/confirm' || from.path === '/platform/payment-method-identity-verification-result')) {
        next({ path: '/connectix/ucom/terms' });
        return;
      }
      return next();
    },
  },
  {
    path: '/connectix/5a/terms',
    name: 'Five.A Connectix サービス規約',
    component: ConnectixFiveATerms,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み サービス規約 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。サービス規約',
      keywords: 'Five.A Connectixお申し込みサービス規約',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // Five.A会員でないときは、会員専用トップへ遷移
      if (!(await AuthService.getFiveAMemberId())) {
        return next({ path: '/platform' });
      }

      try {
        const property: Property = store.getters['propertyStore/property'];
        const propertyParams = { propertyId: property.apartmentId, uaType: UA_TYPE.FIVE_A };
        const fiveAProperty: FiveAProperty | FiveASharedErrorResponse = await store.dispatch('fiveACommonStore/property', propertyParams);

        let isAlreadyInService = false;

        if (fiveAProperty instanceof FiveAProperty) {
          // 物件基本情報を使う

          // サービスイン済みかどうか調べる
          const emSinTime = fiveAProperty.em_sin_time;
          const utc = Date.UTC(parseInt(emSinTime.substring(0, 4), 10), parseInt(emSinTime.substring(4, 6), 10) - 1, parseInt(emSinTime.substring(6, 8), 10));

          if (utc <= new Date().getTime()) {
            isAlreadyInService = true;
          }
        }

        if (fiveAProperty instanceof FiveASharedErrorResponse) {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
        } else if (isAlreadyInService) {
          return next(); // サービスイン済み のとき
        } else {
          return next({ path: '/platform' });
        }
      } catch (error) {
        throw error;
      }
    },
  },
  {
    path: '/connectix/5a/apply',
    name: 'Five.A Connectix お申し込み',
    component: ConnectixFiveAApply,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み サービスお申し込み | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。サービスお申し込み',
      keywords: 'Five.A Connectixお申し込みサービスお申し込み',
    },
    // 直前のURLがサービス規約画面かお申し込み確認画面でなければサービス規約画面に遷移する
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/connectix/5a/terms' && from.path !== '/connectix/5a/confirm') {
        return next({ path: '/connectix/5a/terms' });
      }
      return next();
    },
  },
  {
    path: '/connectix/5a/confirm',
    name: 'Five.A Connectix お申し込み確認',
    component: ConnectixFiveAConfirm,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み 確認 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。確認',
      keywords: 'Five.A Connectixお申し込み確認',
    },
    // 直前のURLがお申し込み画面でなければサービス規約画面に遷移する
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/connectix/5a/apply' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/connectix/5a/terms' });
      }
      return next();
    },
  },
  {
    path: '/connectix/5a/completed',
    name: 'Five.A Connectix お申し込み完了',
    component: ConnectixFiveACompleted,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み 完了 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。完了',
      keywords: 'Five.A Connectixお申し込み完了',
    },
    // 直前のURLがお申し込み確認画面でなければサービス規約画面に遷移する
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/connectix/5a/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/connectix/5a/terms' });
      }
      return next();
    },
  },
];

export default connectixRouter;
