<template>
  <div class="completed">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion 接続コース変更</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li class="stay">接続コース変更</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">接続コース変更</li>
        <li>確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="接続コース変更 お申し込み" />接続コース変更　お申し込み</h2>
        <p>お申し込み前に必ず以下をお読みください。</p>
        <div class="sblc">
          <h3 class="service-h3">電気通信事業法に基づく重要事項</h3>
          <b><span class="red mb10">電気通信事業法に基づく重要事項をご説明します。</span></b>
          <p>　</p>
          <p><a :href="eMansionCourseUrl" class="link" target="_blank">こちら</a>をご覧ください。</p>
        </div>
        <div class="sblc">
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        </div>
        <div class="sblc">
          <p>インターネット接続コース変更のお手続きを行います。</p>
          <p class="title-margin course-att"><li>コース変更お申し込み後、31日間は再変更できませんので予めご了承ください。</li></p>
          <p class="course-att">
            <li>
              <span class="red">変更後コースでのお支払いは、「コース変更工事完了日」が1日～20日までの場合は翌月1日から、21日～月末までの場合は翌々月1日からとなります。</span>
            </li>
          </p>
        </div>
        <div class="no-border-sides">
          <div class="sblc" v-if="isConstructionCost">
            <h3 class="service-h3">諸費用</h3>
            <div class="specific-div">
              <table class="table-type2 billing-table">
                <tbody>
                  <tr v-if="!isConstructionExpenses">
                    <th class="va-middle constructionCompletion"><b>工事費</b></th>
                    <td class="commonPaddingTd">
                      {{ formatCurrency(constructionCost) }} 円<b class="font16px red" v-if="constructionCostCp"
                        >　⇒　{{ formatCurrency(constructionCostCp) }} 円<span class="campaign-text">キャンペーン中！</span></b
                      >
                    </td>
                  </tr>
                  <tr v-if="!isUpgradeExpenses">
                    <th class="constructionCompletion"><b>アップグレード手数料</b></th>
                    <td class="commonPaddingTd">
                      {{ formatCurrency(isUpgFee) }} 円<b class="font16px red" v-if="isUpgFeeCp"
                        >　⇒　{{ formatCurrency(isUpgFeeCp) }} 円<span class="campaign-text">キャンペーン中！</span></b
                      >
                    </td>
                  </tr>
                  <tr v-if="!isDowngradeExpenses">
                    <th class="constructionCompletion"><b>ダウングレード手数料</b></th>
                    <td class="commonPaddingTd">
                      {{ formatCurrency(isDowngFee) }} 円<b class="font16px red" v-if="isDowngFeeCp"
                        >　⇒　{{ formatCurrency(isDowngFeeCp) }} 円<span class="campaign-text">キャンペーン中！</span></b
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="sblc tableForSp" v-if="currentPlan">
          <h3 class="service-h3">接続コース</h3>
          <table class="table-type2 billing-table">
            <tr>
              <td class="tableGray"><b>現在の接続コース</b></td>
              <td class="commonPaddingTd">{{ currentPlan }} ({{ currentConnectionPlan }})</td>
            </tr>
            <tr v-if="paymentIssueMsg.length >= 1">
              <td class="tableGray"><b>接続料金</b></td>
              <td class="commonPaddingTd">{{ paymentIssueMsg.join('\n') }}</td>
            </tr>
            <tr v-else>
              <td class="tableGray"><b>接続料金</b></td>
              <td v-if="paymentIssueMsg.length >= 1" class="commonPaddingTd">{{ paymentIssueMsg.join('\n') }}</td>
              <td v-else-if="currentPlanCost == '0'" class="commonPaddingTd">
                {{ '管理費とあわせてお支払いいただきます。なお、賃貸居住の方はオーナー様よりお支払いいただいております。' }}
              </td>
              <td v-else class="commonPaddingTd">月額　 {{ formatCurrency(currentPlanCost) }} 円</td>
            </tr>
          </table>
        </div>
        <div class="sblc tableForPc specific-div" v-if="currentPlan">
          <h3 class="service-h3">接続コース</h3>
          <table class="table-type2 billing-table">
            <tr>
              <td class="tableGray constructionCompletion"><b>現在の接続コース</b></td>
              <td class="tableGray constructionCompletion"><b>接続料金</b></td>
            </tr>
            <tr>
              <td class="commonPaddingTd">{{ currentPlan }} ({{ currentConnectionPlan }})</td>
              <td v-if="paymentIssueMsg.length >= 1" class="commonPaddingTd">{{ paymentIssueMsg.join('\n') }}</td>
              <td v-else-if="currentPlanCost == '0'">{{ '管理費とあわせてお支払いいただきます。なお、賃貸居住の方はオーナー様よりお支払いいただいております。' }}</td>
              <td v-else class="commonPaddingTd">月額　 {{ formatCurrency(currentPlanCost) }} 円</td>
            </tr>
          </table>
        </div>
        <d v-if="currentPlan">変更後の接続コースを選んでチェックを入れてください。</d>
      </div>

      <div class="text-margin"></div>

      <div class="no-border-sides">
        <template v-if="nonMatchingCourses.length > 0">
          <div class="specific-div">
            <table class="table-type2 billing-table" v-for="course in nonMatchingCourses" :key="course.course_id">
              <tbody>
                <tr>
                  <th class="va-middle">速度（タイプ）</th>
                  <td>
                    <label>
                      <input type="radio" :id="course.course_id" class="radiobutton" :value="course" v-model="selectedSpeed" name="speedType" />
                      　{{ course.speed }} ({{ getCourseTypeLabel(course.type) }})
                    </label>
                  </td>
                </tr>
                <tr>
                  <th class="va-middle">接続利用料（月額）</th>
                  <td v-if="paymentIssueMsg.length >= 1">{{ paymentIssueMsg.join('\n') }}</td>
                  <td v-else-if="course.charge == '0'">{{ '管理費とあわせてお支払いいただきます。なお、賃貸居住の方はオーナー様よりお支払いいただいております。' }}</td>
                  <td v-else-if="course.charge">
                    月額　{{ formatCurrency(course.charge) }} 円<b class="font16px red" v-if="course.charge_campaign"
                      >　⇒　{{ formatCurrency(course.charge_campaign) }} 円<span class="campaign-text">キャンペーン中！</span></b
                    >
                  </td>
                </tr>
                <tr v-if="shouldDisplayRow(course)">
                  <th class="va-middle">接続機品のご利用形態</th>
                  <td>
                    <div v-if="course.adapter_info">
                      <div v-if="course.type === '01' || course.type === '02' || course.type === '03' || course.type === '04'">
                        <div v-for="(adapter, index) in course.adapter_info" :key="index">
                          <span v-if="adapter.equipmentcontract == '04'">モデム　レンタル料（月額）0 円</span>
                          <span v-else-if="adapter.charge_campaign">モデム　レンタル料（月額）{{ formatCurrency(adapter.charge_campaign) }} 円</span>
                          <span v-else>モデム　レンタル料（月額）{{ formatCurrency(adapter.charge) }} 円</span>
                          <br /><br />
                          <p>※ モデムはご自身でご用意いただくことはできません。</p>
                        </div>
                      </div>
                      <div v-else-if="course.type === '06'">
                        <div v-for="(adapter, index) in course.adapter_info" :key="index">
                          <span v-if="adapter.equipmentcontract == '04'"> メディアコンバーター　レンタル料（月額）0 円 </span>
                          <span v-else-if="adapter.charge_campaign"> メディアコンバーター　レンタル料（月額）{{ formatCurrency(adapter.charge_campaign) }} 円 </span>
                          <span v-else-if="adapter.charge"> メディアコンバーター　レンタル料（月額）{{ formatCurrency(adapter.charge) }} 円 </span>
                          <br /><br />
                          <p>※ メディアコンバーターの取り付けは、宅内工事の際に行われます。</p>
                        </div>
                      </div>
                    </div>
                    <div v-if="damagechargeFlag">
                      ※ 接続機器はレンタル品のため、お客様の故意または過失による故障や破損の場合、端末機器損害金21,780円（課税対象外）を請求いたします。<br />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <div class="sblc">
        <p>
          ※
          本サービスはベストエフォート式のサービスのため、実使用速度を保証するものではありません。速度は技術規格上での最大値であり、通信速度は時間帯・状況等により異なる場合があります。
        </p>
        <p>
          ※
          住戸内に設置されている機器（スイッチングハブなど）または接続する端末、ケーブル等により技術規格上の最大通信速度が変わるため、上記の通信速度（ベストエフォート）の規格にてご利用になりたい場合、機器、端末、ケーブル等をお客様にて変更いただくケースがございます。
        </p>
        <p>※ 住戸内設置の機器（建物所有者様、区分所有者様の所有物）の仕様については建物によって異なりますので居住者様にてご確認ください。</p>
        <p>※ 価格は全て新税率に基づく税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
      </div>

      <div class="centered-text">
        <b>ご確認の上、よろしければ「申し込む」をクリックしてください。</b>
      </div>

      <div class="btn-area-course">
        <button class="btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
        <button class="btn btn01 bs" @click="onNext" :disabled="!storeCourseId || errorMessages.length > 0">
          申し込む
          <i class="material-icons link link-icon">east</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import store from '@/store';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { Member } from '@/shared/classes/spf-api/member';
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-account-accessor';
import { eMansionNotifyMailAddressCheckRequest } from '@/shared/classes/spf-api/mail/model/account/e-mansnion-notify-mailaddress-check-request';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';

export default defineComponent({
  name: 'platform-terms-i-filter',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data(): {
    isNotifyEmail: boolean;
    // e-mansion サイト URL
    eMansionUrl: string;
    // 8桁APIDE
    apid: string;
    // 工事費のだし分け
    isConstructionCost: boolean;
    constructionCost: string;
    isConstructionExpenses: boolean;
    isUpgradeExpenses: boolean;
    isDowngradeExpenses: boolean;
    /** エラーメッセージを格納する配列 */
    errorMessages: string[];
    errorMessageTitle: string;
    property: EMansionProperty;
    constructionCostCp: string;

    isUpgFee: string;
    isUpgFeeCp: string;
    isDowngFee: string;
    isDowngFeeCp: string;
    isModemFlag: boolean;
    // 端末機器損害金
    damagechargeFlag: boolean;
    // イレギュラーフラグ
    isPayirFlag: boolean;
    //支払方法がイレギュラーの際のメッセージ
    paymentIssueMsg: string[];
    monthlyFee: string;
    isCourseId: string[];
    // 現在のサービスプラン
    currentPlan: string;
    // 現在の接続プラン
    currentConnectionPlan: string;
    currentPlanCost: string;
    nonMatchingCourses: any;
    selectedSpeed: string;
    eMansionMyUrl: string;
    eMansionCourseUrl: string;
    isContainGipiUrl: string;
    rd: string;
    isIrregularPayment: boolean;
    storeSelectedSpeed: string;
    storeSelectedCharge: string;
    storeSelectedChargeCp: string;
    storeEquipmentcontract: string;
    storeCharge: string;
    storeChargeCampaign: string;
    currentGrade: string;
    storeGrade: string;
    storeType: String;
    storeCourseId: string;
    checkMyCurrentCourseId: boolean;
    isLoading: boolean;
    isGipCustomer: boolean;
    isMaxCourseGrade: number;
    currentCourseId: string;
    currentCourseType: string;
    currentCourseSpeed: string;
    chgKind: string;
  } {
    return {
      isNotifyEmail: false,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      apid: '',
      isConstructionCost: false,
      constructionCost: '',
      isConstructionExpenses: false,
      isUpgradeExpenses: false,
      isDowngradeExpenses: false,
      isUpgFee: '',
      isUpgFeeCp: '',
      isDowngFee: '',
      isDowngFeeCp: '',
      errorMessages: [] as string[],
      /** エラーメッセージ部に表示するタイトル */
      errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
      property: {} as EMansionProperty,
      constructionCostCp: '',
      isModemFlag: false,
      damagechargeFlag: false,
      isPayirFlag: false,
      paymentIssueMsg: [] as string[],
      monthlyFee: '',
      isCourseId: [] as string[],
      currentPlan: '',
      currentConnectionPlan: '',
      currentPlanCost: '',
      nonMatchingCourses: [] as any[],
      selectedSpeed: '',
      eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
      eMansionCourseUrl: '',
      isContainGipiUrl: '',
      rd: '',
      isIrregularPayment: false,
      storeSelectedSpeed: '',
      storeSelectedCharge: '',
      storeSelectedChargeCp: '',
      storeEquipmentcontract: '',
      storeCharge: '',
      storeChargeCampaign: '',
      currentGrade: '',
      storeGrade: '',
      storeType: '',
      storeCourseId: '',
      checkMyCurrentCourseId: false,
      isLoading: false,
      isGipCustomer: false,
      isMaxCourseGrade: 0,
      currentCourseId: '',
      currentCourseType: '',
      currentCourseSpeed: '',
      chgKind: '',
    };
  },
  async mounted(): Promise<void> {
    this.isLoading = true;

    if (!(await MountedCheckService.canReadEMansionScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    // 物件情報を取得する
    const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];

    /** 契約基本情報をStoreから取得 */
    const eMansionCustomer: EMansionCustomer = await store.getters['eMansionCommonStore/customer'];

    if (eMansionCustomer.cs.changeable_flag === '0') {
      this.checkCurrentCourseId(eMansionCustomer);
      if (this.currentCourseId) {
        const errorMessages = [
          `恐れ入りますが、お申し込み後、31日間は変更をお申し込みいただけません。<br>お申し込み中の接続コース：${this.currentCourseSpeed} (${this.currentCourseType})<br>ご不明な点がございましたら、お手数ですが e-mansion サポートセンターへご連絡ください。`,
        ];

        const errorMessageTitle = '接続コース変更';
        this.$store.commit('errorStore/title', errorMessageTitle);
        this.$store.commit('errorStore/messages', String(errorMessages));

        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      } else {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
    }

    // 固定グローバルIPのユーザーかどうかのチェック
    // ステータスが12で利用中の場合、「解約の文言」を表示するため
    if (eMansionCustomer.op.globalip.some((item) => item.status === '12')) {
      this.isGipCustomer = true;
    }

    // 物件基本情報API.グレードと契約基本情報.API.グレードの最大値を取得する
    if (property.cs.some((item) => item.course_id) || eMansionCustomer.cs.grade) {
      const maxGradeInProperty = Math.max(...property.cs.map((item) => parseFloat(item.grade)).filter((grade) => !isNaN(grade)));
      const maxGradeInkCustomer = Number(eMansionCustomer.cs.grade);
      this.isMaxCourseGrade = Math.max(maxGradeInProperty, maxGradeInkCustomer);
    }

    const CustomerCourseId = eMansionCustomer.cs.course_id;
    const nextCustomerCourseId = eMansionCustomer.cs.next_course_id;

    // apid追加
    this.apid = property.IN_APID;
    this.eMansionCourseUrl = ` ${this.eMansionMyUrl}/instructions/downloadPdf.php?function=instructions&apid=${this.apid}&kind=03`;
    this.property = property;
    const courseId = this.property.cs.find((course) => course.course_id === CustomerCourseId);

    // 申し込んでいる接続先コースタイプ
    if (CustomerCourseId) {
      this.currentPlan = eMansionCustomer.cs.speed;
      this.currentPlanCost = eMansionCustomer.cs.charge;
      this.currentConnectionPlan = String(this.getCourseTypeLabel(eMansionCustomer.cs.type));
    }

    //物件基本の中に、契約基本情報.コースIDと合致する商品があった場合、その商品だけ省いて表示する。
    if (courseId) {
      // 現在申し込み中のコースIDチェック
      if (courseId.course_id === '0051' || courseId.course_id === '0081') {
        this.checkMyCurrentCourseId = true;
      }

      // 損害賠償フラグがあったらメッセージを表示
      if (property.damagecharge_flag == '1') {
        this.damagechargeFlag = true;
      }

      // 支払情報に問題があったら接続利用料に文言を表示する
      if (property.em_payir_flag == '0') {
        this.isIrregularPayment == true;
      }

      // 現在申し込んでいるコースに一致しない要素をすべて取得
      this.nonMatchingCourses = property.cs.filter((course) => {
        if (nextCustomerCourseId) {
          return course.course_id !== CustomerCourseId && course.course_id !== nextCustomerCourseId;
        } else {
          return course.course_id !== CustomerCourseId;
        }
      });
    } else {
      //物件基本の中に、契約基本情報.コースIDと合致する商品がない場合、全て表示する
      this.nonMatchingCourses = property.cs;
    }

    if (this.nonMatchingCourses.length === 0 || !this.nonMatchingCourses) {
      if (courseId) {
        this.isCourseId = [courseId.speed];
      }
      const courseIds = this.isCourseId.map((id) => `${id} (${this.currentConnectionPlan})`).join(', ');
      if (courseIds && this.isCourseId) {
        const errorMessages = [
          `コース変更可能なコースはございません。<br>お申し込み中の接続コース：${courseIds}<br>ご不明な点がございましたら、お手数ですが e-mansion サポートセンターへご連絡ください。`,
        ];

        const errorMessageTitle = '接続コース変更';
        this.$store.commit('errorStore/title', errorMessageTitle);
        this.$store.commit('errorStore/messages', String(errorMessages));

        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });

        this.isLoading = false;
        return;
      } else {
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });

        this.isLoading = false;
        return;
      }
    }

    if (property.em_payir_flag == '0') {
      this.isPayirFlag = true;
      this.paymentIssueMsg.push('管理組合様にお問合せください。');
    } else {
      property.cs.forEach((course) => {
        this.monthlyFee = course.charge;
      });
    }

    //工事費用チェック
    this.checkConstructionCost(property);

    // 入会画面にて選択した接続コースをストア情報から取得
    const storedCourse = await store.getters['selectedCourseStore/courseId'];

    if (storedCourse) {
      //ストアの course_id と一致するコースを探す
      const matchingCourse = this.nonMatchingCourses.find((course: any) => course.course_id === storedCourse);
      if (matchingCourse) {
        this.selectedSpeed = matchingCourse;
      }
    }

    // 通知先メールチェックAPI
    try {
      const member: Member = await this.$store.dispatch('memberStore/member');
      const emansionProperty = this.$store.getters['propertyStore/property'];
      const primaryKeyMye = member.primaryKeyMye;
      const apartmentId = emansionProperty.apartmentId;
      const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      const uaType = emansionProperty.uaType;

      const chechNotifyAccount = await SpfApiAccountAccessor.getEMansionAccountNotifyEmail(
        new eMansionNotifyMailAddressCheckRequest(primaryKeyMye!, apartmentId, servicePlanType, uaType)
      );

      if (chechNotifyAccount) {
        this.errorMessages.push(String(chechNotifyAccount.messages));
        window.scrollTo(0, 0);
        this.isLoading = false;
      }
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });

      this.isLoading = false;
      return;
    }

    this.isLoading = false;
    return;
  },
  methods: {
    shouldDisplayRow(course: any) {
      if (this.checkMyCurrentCourseId && (course.course_id == '0051' || course.course_id == '0081')) {
        return false;
      }
      if (course.type == '05') {
        return false;
      }
      if (course.type == '06' && course.chg_kind == '01') {
        return false;
      }
      return true;
    },
    async onNext(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.errorMessages = [];

      if (this.errorMessages.length > 0) {
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      if (this.property.em_gipir_flag === '1' && this.isGipCustomer && this.isMaxCourseGrade > Number(this.storeGrade)) {
        // 9桁のランダムな数字を取得
        this.rd = this.createRd();
        this.isContainGipiUrl = ` ${this.eMansionMyUrl}/login/login.php?function=GLOBALIP&apid=${this.apid}&rd=${this.rd}`;
        this.errorMessages.push(
          '接続コースを変更する前に「固定グローバルIPアドレス」のご解約お手続きが必要となります。<br>固定グローバルIPアドレスを解約される場合は、下記のリンクからお申し込みください。<br><a href="' +
            this.isContainGipiUrl +
            '" class="link" target="_blank">固定グローバルIPアドレス解約お申し込みフォームへ</a>'
        );
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      if (!this.selectedSpeed) {
        this.errorMessages.push('ご希望の「接続コース」を選んでチェックを入れてください。');
        window.scrollTo(0, 0);
        this.storeCourseId = '';
        this.isLoading = false;
        return;
      }

      //種別が04の場合は、メディアコンバーターなどが0円
      if (this.storeEquipmentcontract == '04') {
        this.storeCharge = '0';
      }

      this.$store.commit('selectedCourseStore/setEquipmentcontract', this.storeEquipmentcontract);
      this.$store.commit('selectedCourseStore/setSpeed', this.storeSelectedSpeed);

      // 利用形態表示のフラグとしてストアに保存
      if (this.checkMyCurrentCourseId && (this.storeCourseId === '0051' || this.storeCourseId === '0081')) {
        this.$store.commit('selectedCourseStore/checkMyCurrentCourseId', true);
      } else if (this.storeType == '06' && this.chgKind == '01') {
        this.$store.commit('selectedCourseStore/checkMyCurrentCourseId', true);
      } else if (this.storeType == '05') {
        this.$store.commit('selectedCourseStore/checkMyCurrentCourseId', true);
      } else {
        this.$store.commit('selectedCourseStore/adapterCharge', this.storeCharge);
        this.$store.commit('selectedCourseStore/adapterChargeCampaign', this.storeChargeCampaign);
      }

      //ここで月額料金を取得している
      this.$store.commit('selectedCourseStore/setCharge', this.storeSelectedCharge);
      this.$store.commit('selectedCourseStore/setChargeCp', this.storeSelectedChargeCp);

      this.$store.commit('selectedCourseStore/storeGrade', this.storeGrade);
      this.$store.commit('selectedCourseStore/type', this.storeType);
      this.$store.commit('selectedCourseStore/courseId', this.storeCourseId);

      if (this.paymentIssueMsg) {
        this.$store.commit('selectedCourseStore/paymentIssueMsg', this.paymentIssueMsg.join('\n'));
      }

      await this.$router.push('/e-mansion/course/confirm').catch((e) => {
        checkRouterError(e);
      });

      this.isLoading = false;

      return;
    },
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      store.commit('selectedCourseStore/contractDestinationZipcode', null);
      store.commit('selectedCourseStore/contractDestinationAddress1', null);
      store.commit('selectedCourseStore/contractDestinationAddress2', null);
      store.commit('selectedCourseStore/courseId', undefined);
      store.commit('selectedCourseStore/contractDestinationKind', null);
      store.commit('selectedCourseStore/contractDestinationMailaddress', null);
      store.commit('selectedCourseStore/contractDestinationMailaddressConfirm', null);

      await this.$router.push('/e-mansion').catch((e) => {
        checkRouterError(e);
      });

      this.isLoading = false;
      return;
    },
    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },

    formatCurrency(value: any) {
      const num = parseInt(value.replace(/,/g, ''), 10);
      return num.toLocaleString();
    },

    async checkCurrentCourseId(eMansionCustomer: any): Promise<void> {
      // お申込途中のコースIDがある時の条件
      if (eMansionCustomer.cs.next_course_id) {
        const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
        this.currentCourseId = eMansionCustomer.cs.next_course_id;
        const targetCourse = property.cs.find((cs) => cs.course_id === this.currentCourseId);

        if (targetCourse) {
          this.currentCourseId = targetCourse.course_id;
          this.currentCourseSpeed = String(targetCourse.speed);
          this.currentCourseType = String(this.getCourseTypeLabel(targetCourse.type));
        }
        return;
        // お申し込み済みのコースIDがある条件
      } else if (eMansionCustomer.cs.course_id) {
        this.currentCourseId = eMansionCustomer.cs.course_id;
        this.currentCourseSpeed = String(eMansionCustomer.cs.speed);
        this.currentCourseType = String(this.getCourseTypeLabel(eMansionCustomer.cs.type));
        return;
      }
      return;
    },

    getCourseTypeLabel(type: any) {
      if (type === '01' || type === '02' || type === '03' || type === '04') {
        return 'VDSL';
      } else if (type === '05') {
        return 'LAN';
      } else if (type === '06') {
        return 'FTTH';
      } else if (type === '00') {
        return '対象外';
      }
    },

    async checkConstructionCost(property: any): Promise<void> {
      if (property.em_chg_construction_flag === '1' || property.em_upg_flag == '1' || property.em_downg_flag == '1') {
        this.isConstructionCost = true;
      }

      // 工事日、アップグレード料金、ダウングレード料金、だし分け処理
      if (property.em_chg_construction_flag === '0') {
        this.isConstructionExpenses = true;
      } else {
        this.constructionCost = property.em_chg_construction;
        // 工事費用キャンペーンか価格がある場合
        if (property.em_chg_construction_cp) {
          this.constructionCostCp = property.em_chg_construction_cp;
        }
      }

      //アップグレード料金だし分け
      if (property.em_upg_flag === '0') {
        this.isUpgradeExpenses = true;
      } else {
        // アップグレード手数料があれば、それを表示
        this.isUpgFee = property.em_upg_fee;
        if (property.em_upg_fee_cp) {
          this.isUpgFeeCp = property.em_upg_fee_cp;
        }
      }

      //ダウングレード料金だし分け
      if (property.em_downg_flag === '0') {
        this.isDowngradeExpenses = true;
      } else {
        this.isDowngFee = property.em_downg_fee;
        if (property.em_downg_fee_cp) {
          //ダウングレード手数料があれば表示
          this.isDowngFeeCp = property.em_downg_fee_cp;
        }
      }
    },
  },
  watch: {
    // チェックボックスで選択されたセットを変数に代入
    selectedSpeed: {
      handler(newValue) {
        if (newValue) {
          this.storeSelectedSpeed = newValue.speed;
          this.storeSelectedCharge = newValue.charge;
          this.storeEquipmentcontract = newValue.adapter_info[0]?.equipmentcontract;
          this.storeCharge = newValue.adapter_info[0]?.charge;
          this.storeChargeCampaign = newValue.adapter_info[0]?.charge_campaign;
          this.storeGrade = newValue.grade;
          this.storeType = newValue.type;
          this.chgKind = newValue.chg_kind;
          this.storeCourseId = newValue.course_id;
          if (newValue.charge_campaign) {
            this.storeSelectedChargeCp = newValue.charge_campaign;
          }
        }
      },
      deep: true,
    },
  },
});
</script>

<style scoped>
p.course-att li {
  font-size: 13px !important;
  padding-left: 1.5em !important;
  text-indent: -1.5em !important;
}
body p.course-att li {
  font-size: 13px !important;
}

/* Firefox専用のCSS */
@-moz-document url-prefix() {
  p.course-att li {
    font-size: 13px !important;
    padding-left: 1.5em !important;
    text-indent: -1.5em !important;
    margin-left: 13px;
  }
}

.centered-text {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
.centered-text b {
  display: inline-block;
  text-align: left;
  margin: 0 auto;
}
.no-border-sides .billing-table {
  border-left: none;
  border-right: none;
  margin: 0.5px;
  border-right: 0;
}

.no-border-sides .table-type2 th {
  border-right: 0;
}

.text-margin {
  margin-bottom: 20px;
}
.table {
  table-layout: fixed;
  width: 100%;
}

/* デフォルトスタイル */
.table-type2 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

/* テーブル全体と各セルに縦線を追加 */
.table-type2 th,
.table-type2 td {
  border-right: 1px solid #ddd;
}

.tableGray {
  background-color: #e6e6e6 !important;
}

.table-type2 th:last-child,
.table-type2 td:last-child {
  border-right: none;
}

.temp-css {
  min-width: 130px;
}

.btn-area-course {
  margin: 100px auto 0;
  text-align: center;
}

.btn-area-course {
  margin: 20px auto 0;
  text-align: center;
}

.constructionCompletion {
  width: 240px;
  padding: 15px 10px;
}

.otherCourseService {
  width: 240px;
  padding: 15px 10px;
}

.contractDetails {
  width: 240px;
  padding: 15px 10px;
}

.tableForSp {
  display: none !important;
}

.campaign-text {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
  margin-left: 0.5rem;
}

/* 特定の div のみ content-box を適用 */
.specific-div * {
  box-sizing: content-box !important;
}

.title-margin {
  margin-top: 10px !important;
}

.btn:disabled {
  opacity: 0.5 !important;
}

@media only screen and (max-width: 767px) {
  .no-border-sides .table-type2 th {
    border-right: none;
  }
  .otherCourseService {
    padding: 15px 10px;
    display: block;
    width: inherit;
  }
  .contractDetails {
    padding: 15px 10px;
    display: block;
    width: inherit;
  }
  .commonPaddingTd {
    padding: 15px 10px !important;
  }
  .tableForPc {
    display: none !important;
  }
  .tableForSp {
    display: block !important;
  }
}
</style>
