import { Module } from 'vuex';
import { RootState } from '../index';

/** e-mansion 固定グローバルIPアドレス State */
const eMansionGIPState: {
  from: string | undefined;
  errorCode: string | undefined;
} = {
  from: undefined,
  errorCode: undefined,
};

/** State の型 */
type EMansionGIPState = typeof eMansionGIPState;

/** e-mansion 共通系 Store */
export const eMansionGIPStore: Module<EMansionGIPState, RootState> = {
  namespaced: true,
  state: eMansionGIPState,
  actions: {},
  mutations: {
    from: (state, value: string) => (state.from = value),
    errorCode: (state, value: string) => (state.errorCode = value),
  },
  getters: {
    from: (state): string | undefined => state.from,
    errorCode: (state): string | undefined => state.errorCode,
  },
};
