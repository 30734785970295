import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { isTransitionNoLinked } from '.';

import CourseComplete from '@/pages/e-mansion/course/course-complete.vue';
import CourseConfirm from '@/pages/e-mansion/course/course-confirm.vue';
import CourseInput from '@/pages/e-mansion/course/course-input.vue';
import EMansionEntryCompleted from '@/pages/e-mansion/entry/completed.vue';
import EMansionEntryConfirm from '@/pages/e-mansion/entry/confirm.vue';
import EMansionEntryConnectionCourse from '@/pages/e-mansion/entry/connection-course.vue';
import EMansionEntryInput from '@/pages/e-mansion/entry/input.vue';
import EMansionEntryOption from '@/pages/e-mansion/entry/option.vue';
import EMansionEntryRoomNumber from '@/pages/e-mansion/entry/room-number.vue';
import EMansionEntryTerms from '@/pages/e-mansion/entry/terms.vue';
import EMansionError from '@/pages/e-mansion/error.vue';
import HikariphoneCompleted from '@/pages/e-mansion/hikari-phone/completed.vue';
import HikariphoneConfirm from '@/pages/e-mansion/hikari-phone/confirm.vue';
import HikariphoneInput from '@/pages/e-mansion/hikari-phone/input.vue';
import HikariPhoneNotificationSetting from '@/pages/e-mansion/hikari-phone/notification-setting.vue';
import HikariPhoneOptionApplicationCompleted from '@/pages/e-mansion/hikari-phone/option/application-completed.vue';
import HikariPhoneOptionApplicationConfirm from '@/pages/e-mansion/hikari-phone/option/application-confirm.vue';
import HikariPhoneOptionCancelCompleted from '@/pages/e-mansion/hikari-phone/option/cancel-completed.vue';
import HikariPhoneOptionCancelConfirm from '@/pages/e-mansion/hikari-phone/option/cancel-confirm.vue';
import HikariPhoneOptionManage from '@/pages/e-mansion/hikari-phone/option/manage.vue';
import HikariphoneServiceTerms from '@/pages/e-mansion/hikari-phone/service-terms.vue';
import HikariPhoneUsage from '@/pages/e-mansion/hikari-phone/usage.vue';
import HomePageCapacityComplete from '@/pages/e-mansion/homepage-capacity/complete.vue';
import HomePageCapacityConfirm from '@/pages/e-mansion/homepage-capacity/confirm.vue';
import HomePageCapacityInput from '@/pages/e-mansion/homepage-capacity/input.vue';
import HomePageCapacityList from '@/pages/e-mansion/homepage-capacity/list.vue';
import HomepageApplicationComplete from '@/pages/e-mansion/homepage/application-complete.vue';
import HomepageApplicationConfirm from '@/pages/e-mansion/homepage/application-confirm.vue';
import HomepageApplication from '@/pages/e-mansion/homepage/application.vue';
import HomepageCancelComplete from '@/pages/e-mansion/homepage/cancel-complete.vue';
import HomepageCancelConfirm from '@/pages/e-mansion/homepage/cancel-confirm.vue';
import HomepageUsageCancel from '@/pages/e-mansion/homepage/usage-cancel.vue';
import IfilterComplete from '@/pages/e-mansion/i-filter/i-filter-complete.vue';
import IfilterConfirm from '@/pages/e-mansion/i-filter/i-filter-confirm.vue';
import IfilterInput from '@/pages/e-mansion/i-filter/i-filter-input.vue';
import IfilterService from '@/pages/e-mansion/i-filter/i-filter-terms.vue';
import IfilterUsage from '@/pages/e-mansion/i-filter/i-filter-usage.vue';
import EMansionInArrears from '@/pages/e-mansion/in-arrears.vue';
import EMansionIndex from '@/pages/e-mansion/index.vue';
import EMansionIPPhoneApply from '@/pages/e-mansion/ip-phone/apply.vue';
import EMansionIPPhoneCompleted from '@/pages/e-mansion/ip-phone/completed.vue';
import EMansionIPPhoneConfirm from '@/pages/e-mansion/ip-phone/confirm.vue';
import EMansionIPPhoneTerms from '@/pages/e-mansion/ip-phone/terms.vue';
import Linking from '@/pages/e-mansion/linking.vue';
import MailPasswordComplete from '@/pages/e-mansion/mail-pw/complete.vue';
import MailPasswordInput from '@/pages/e-mansion/mail-pw/input.vue';
import MailUsage from '@/pages/e-mansion/mail/list/usage.vue';
import VirusCheckApplyComplete from '@/pages/e-mansion/mail/virus-check/virus-check-apply-complete.vue';
import VirusCheckApplyConfirm from '@/pages/e-mansion/mail/virus-check/virus-check-apply-confirm.vue';
import VirusCheckCancelComplete from '@/pages/e-mansion/mail/virus-check/virus-check-cancel-complete.vue';
import VirusCheckCancelConfirm from '@/pages/e-mansion/mail/virus-check/virus-check-cancel-confirm.vue';
import MainMailCanselComplete from '@/pages/e-mansion/main-mail-address/cancel-complete.vue';
import MainMailCanselConfirm from '@/pages/e-mansion/main-mail-address/cancel-confirm.vue';
import MainMailComplete from '@/pages/e-mansion/main-mail-address/complete.vue';
import MainMailConfirm from '@/pages/e-mansion/main-mail-address/confirm.vue';
import MainMailInput from '@/pages/e-mansion/main-mail-address/input.vue';
import MainMailList from '@/pages/e-mansion/main-mail-address/list.vue';
import EMansionPaymentMethodsEditCompleted from '@/pages/e-mansion/payment-methods/register-completed.vue';
import EMansionPaymentMethodsEdit from '@/pages/e-mansion/payment-methods/register.vue';
import StaticGlobalIpApplication from '@/pages/e-mansion/static-gip/application.vue';
import StaticGlobalIpCompleted from '@/pages/e-mansion/static-gip/completed.vue';
import StaticGlobalIpConfirm from '@/pages/e-mansion/static-gip/confirm.vue';
import StaticGlobalIpTerms from '@/pages/e-mansion/static-gip/terms.vue';
import StaticGlobalIpUsage from '@/pages/e-mansion/static-gip/usage.vue';
import SubAccountLinking from '@/pages/e-mansion/sub-account-linking.vue';
import SubAccountCancelComplete from '@/pages/e-mansion/sub-account/cancel-complete.vue';
import SubAccountCancelConfirm from '@/pages/e-mansion/sub-account/cancel-confirm.vue';
import SubAccountComplete from '@/pages/e-mansion/sub-account/complete.vue';
import SubAccountConfirm from '@/pages/e-mansion/sub-account/confirm.vue';
import SubAccountList from '@/pages/e-mansion/sub-account/list.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { SERVICE_ID } from '@/shared/const/service-ids';
import { UA_TYPE } from '@/shared/const/service-type';
import { PermissionControlService } from '@/shared/services/permission/permission-control-service';
import store from '@/store';

/** e-mansion ルーティング定義 */
const eMansionRouter = [
  {
    path: '/e-mansion',
    name: 'e-mansion サービストップ',
    component: EMansionIndex,
    meta: {
      title: 'Portas | e-mansion 会員専用トップページ | ポルタス',
      description: 'Portas（ポルタス）e-mansion会員専用トップページになります。',
      keywords: 'e-mansion 会員専用トップページ',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      const isLinkedToEMansion: boolean = await store.dispatch('memberStore/isLinkedToEMansion');
      if (isLinkedToEMansion) {
        return next();
      } else {
        return next({ path: '/login-forwarding' }); // トップページに遷移
      }
    },
  },
  //{
  //  path: '/e-mansion/link-service',
  //  name: 'e-mansion 同時お申し込み',
  //  component: LinkService,
  //  meta: {
  //    title: 'Portas | e-mansion 同時お申し込みページ（新規会員登録、アカウント連携） | ポルタス',
  //    description: 'Portas（ポルタス）とe-mansionのアカウント連携、およびe-mansionの新規会員登録はこちらから行えます。',
  //    keywords: 'e-mansion同時お申し込みページ（新規会員登録、アカウント連携）'
  //  },
  //  beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
  //    if(await isTransitionNoLinked()){
  //      return next();
  //    }
  //    return next({ path: '/platform' });
  //  }
  //},
  {
    path: '/e-mansion/linking',
    name: 'e-mansion アカウント連携',
    component: Linking,
    meta: {
      title: 'Portas | e-mansion アカウント連携 | ポルタス',
      description: 'Portas（ポルタス）とe-mansionのアカウント連携を行います。',
      keywords: 'e-mansion アカウント連携',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (await isTransitionNoLinked(UA_TYPE.E_MANSION)) {
        return next();
      }
      return next({ path: '/platform' });
    },
  },

  {
    path: '/e-mansion/sub-account-linking',
    name: 'e-mansion 追加アカウント連携',
    component: SubAccountLinking,
    meta: {
      title: 'Portas | e-mansion 追加アカウント連携 | ポルタス',
      description: 'Portas（ポルタス）とe-mansionの追加アカウント連携を行います。',
      keywords: 'e-mansion 追加アカウント連携',
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/mail/usage',
    name: 'e-mansion メールサービス利用状況',
    component: MailUsage,
    meta: {
      title: 'Portas | e-mansion メールサービスご利用状況 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/mail/virus-check-apply-confirm',
    name: 'e-mansion ウイルスチェックあんしんサービス plus 確認 (申込)',
    component: VirusCheckApplyConfirm,
    meta: {
      title: 'Portas | e-mansion ウイルスチェックあんしんサービス plus 確認 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/mail/usage' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/mail/usage', replace: true });
      }

      // 遷移先で必要となるデータがストアされているかチェック
      const mailAddressId = store.getters['eMansionMailStore/selectedMailAddressId'];
      const mailAddress = store.getters['eMansionMailStore/selectedMailAddress'];
      const feeInfo = store.getters['eMansionMailStore/selectedFeeInfo'];
      // mailAddressId がストアされている場合は 0 でないという想定で判定している
      if (!mailAddressId || !mailAddress || !feeInfo) {
        // ストアされていなければ e-mansion メールサービス利用状況画面に戻る
        next({ path: '/e-mansion/mail/usage', replace: true });
      } else {
        next();
      }
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/mail/virus-check-apply-complete',
    name: 'e-mansion ウイルスチェックあんしんサービス plus 完了 (申込)',
    component: VirusCheckApplyComplete,
    meta: {
      title: 'Portas | e-mansion ウイルスチェックあんしんサービス plus 完了 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },

    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      const mailAddress = store.getters['eMansionMailStore/selectedMailAddress'];
      if (from.path !== '/e-mansion/mail/virus-check-apply-confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/mail/usage', replace: true });
      }
      if (!mailAddress) {
        // ストアされていなければ e-mansion メールサービス利用状況画面に戻る
        next({ path: '/e-mansion/mail/usage', replace: true });
      } else {
        next();
      }
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/mail/virus-check-cancel-confirm',
    name: 'e-mansion ウイルスチェックあんしんサービス plus 確認 (解約)',
    component: VirusCheckCancelConfirm,
    meta: {
      title: 'Portas | e-mansion ウイルスチェックあんしんサービス plus 確認 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/mail/usage') {
        return next({ path: '/e-mansion/mail/usage', replace: true });
      }

      // 遷移先で必要となるデータがストアされているかチェック
      const mailAddressId = store.getters['eMansionMailStore/selectedMailAddressId'];
      const mailAddress = store.getters['eMansionMailStore/selectedMailAddress'];
      // mailAddressId がストアされている場合は 0 でないという想定で判定している
      if (!mailAddressId || !mailAddress) {
        // ストアされていなければ e-mansion メールサービス利用状況画面に戻る
        next({ path: '/e-mansion/mail/usage', replace: true });
      } else {
        next();
      }
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/mail/virus-check-cancel-complete',
    name: 'e-mansion ウイルスチェックあんしんサービス plus 完了 (解約)',
    component: VirusCheckCancelComplete,
    meta: {
      title: 'Portas | e-mansion ウイルスチェックあんしんサービス plus 完了 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 遷移先で必要となるデータがストアされているかチェック
      const mailAddress = store.getters['eMansionMailStore/selectedMailAddress'];
      const cancelOn = store.getters['eMansionMailStore/selectedCancelOn'];
      if (!mailAddress || !cancelOn) {
        // ストアされていなければ e-mansion メールサービス利用状況画面に戻る
        next({ path: '/e-mansion/mail/usage', replace: true });
      } else {
        next();
      }
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/homepage-capacity/list',
    name: 'e-mansion ホームページ容量 利用状況',
    component: HomePageCapacityList,
    meta: {
      title: 'Portas | e-mansion ホームページ容量 ご利用状況 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_VOLUME))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/homepage-capacity/input',
    name: 'e-mansion ホームページ容量 変更',
    component: HomePageCapacityInput,
    meta: {
      title: 'Portas | e-mansion ホームページ容量 変更 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_VOLUME))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/homepage-capacity/confirm',
    name: 'e-mansion ホームページ容量 確認',
    component: HomePageCapacityConfirm,
    meta: {
      title: 'Portas | e-mansion ホームページ容量 確認 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/homepage-capacity/input' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/homepage-capacity/list' });
      }
      if (!(await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_VOLUME))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/homepage-capacity/complete',
    name: 'e-mansion ホームページ容量 完了',
    component: HomePageCapacityComplete,
    meta: {
      title: 'Portas | e-mansion ホームページ容量 完了 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/homepage-capacity/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/homepage-capacity/list' });
      }
      if (!(await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_VOLUME))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/error',
    name: 'e-mansion エラー画面',
    component: EMansionError,
    meta: {
      title: 'Portas | e-mansion エラー | ポルタス',
      description: '申し訳ございません。エラーが発生しました。',
      keywords: 'e-mansion エラー画面',
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/no-card-in-arrears',
    name: 'e-mansion 料金滞納中画面',
    component: EMansionInArrears,
    meta: {
      title: 'Portas | e-mansion 料金滞納中 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
  },

  {
    path: '/e-mansion/entry/terms',
    name: 'e-mansion ご入会お申し込み 会員規約の確認',
    component: EMansionEntryTerms,
    meta: {
      title: 'Portas | e-mansion ご入会お申し込み | ポルタス',
      description: '',
      keywords: 'e-mansion ご入会お申し込み 会員規約の確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      return next();
    },
  },
  {
    path: '/e-mansion/entry/room-number',
    name: 'e-mansion ご入会お申し込み 部屋番号検索',
    component: EMansionEntryRoomNumber,
    meta: {
      title: 'Portas | e-mansion ご入会お申し込み | ポルタス',
      description: '',
      keywords: 'e-mansion ご入会お申し込み 部屋番号検索',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/entry/terms' && from.path !== '/e-mansion/entry/input') {
        next({ path: '/e-mansion/entry/terms' });
      }
      next();
    },
  },
  {
    path: '/e-mansion/entry/input',
    name: 'e-mansion ご入会お申し込み お客様情報入力',
    component: EMansionEntryInput,
    meta: {
      title: 'Portas | e-mansion ご入会お申し込み | ポルタス',
      description: '',
      keywords: 'e-mansion ご入会お申し込み お客様情報入力',
    },
    // 仕様書より、途中離脱したとき と URL直叩きしたときは、「規約同意して申し込む」ページからやり直し
    // ただし、登録内容の修正のために戻ったときは、inputに遷移させる
    beforeEnter: (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      // 登録内容の修正のために戻ったときは、inputに遷移させる
      if (
        from.path === '/e-mansion/entry/connection-course' ||
        from.path === '/e-mansion/entry/connection-device' ||
        from.path === '/e-mansion/entry/option' ||
        from.path === '/e-mansion/entry/confirm'
      ) {
        next();
      } else if (from.path === '/e-mansion/entry/terms' || from.path === '/e-mansion/entry/room-number') {
        //前画面を保存する（戻るボタン制御のため）
        store.commit('eMansionEntryStore/beforePathInput', from.path);
        next();
      } else if (from.path !== '/e-mansion/entry/terms') {
        next({ path: '/e-mansion/entry/terms' });
      }
      next();
    },
  },

  {
    path: '/e-mansion/entry/connection-course',
    name: 'e-mansion ご入会お申し込み 接続コース選択',
    component: EMansionEntryConnectionCourse,
    meta: {
      title: 'Portas | e-mansion ご入会お申し込み | ポルタス',
      description: '',
      keywords: 'e-mansion ご入会お申し込み 接続コース選択',
    },
    // 仕様書より、途中離脱したとき と URL直叩きしたときは、「規約同意して申し込む」ページからやり直し
    beforeEnter: (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (from.path !== '/e-mansion/entry/input' && from.path !== '/e-mansion/entry/option' && from.path !== '/e-mansion/entry/confirm') {
        next({ path: '/e-mansion/entry/terms' });
      }
      next();
    },
  },
  {
    path: '/e-mansion/entry/option',
    name: 'e-mansion ご入会お申し込み オプションサービス選択',
    component: EMansionEntryOption,
    meta: {
      title: 'Portas | e-mansion ご入会お申し込み | ポルタス',
      description: '',
      keywords: 'e-mansion ご入会お申し込み オプションサービス選択',
    },
    // 仕様書より、途中離脱したとき と URL直叩きしたときは、「規約同意して申し込む」ページからやり直し
    beforeEnter: (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (from.path !== '/e-mansion/entry/connection-course' && from.path !== '/e-mansion/entry/confirm') {
        next({ path: '/e-mansion/entry/terms' });
      }
      next();
    },
  },
  {
    path: '/e-mansion/entry/confirm',
    name: 'e-mansion ご入会お申し込み 確認',
    component: EMansionEntryConfirm,
    meta: {
      title: 'Portas | e-mansion ご入会お申し込み | ポルタス',
      description: '',
      keywords: 'e-mansion ご入会お申し込み 確認',
    },
    // 仕様書より、途中離脱したとき と URL直叩きしたときは、「規約同意して申し込む」ページからやり直し
    beforeEnter: (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (from.path !== '/e-mansion/entry/option' && from.path !== '/e-mansion/entry/connection-course' && from.path !== '/platform/payment-method-identity-verification-result') {
        next({ path: '/e-mansion/entry/terms' });
      }
      if (from.path === '/e-mansion/entry/option' || from.path === '/e-mansion/entry/connection-course') {
        store.commit('eMansionEntryStore/beforePathConfirm', from.path);
      }
      next();
    },
  },
  {
    path: '/e-mansion/entry/completed',
    name: 'e-mansion ご入会お申し込み 完了',
    component: EMansionEntryCompleted,
    meta: {
      title: 'Portas | e-mansion ご入会お申し込み | ポルタス',
      description: '',
      keywords: 'e-mansion ご入会お申し込み 完了',
    },
    beforeEnter: (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      if (from.path !== '/e-mansion/entry/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        next({ path: '/e-mansion/entry/terms' });
      }
      next();
    },
  },

  //TODO:descriptionの内容
  {
    path: '/e-mansion/main-mail-address/list',
    name: 'e-mansion メインメールアドレス登録・変更 利用状況',
    component: MainMailList,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・変更 利用状況 | ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/main-mail-address/input',
    name: 'e-mansion メインメールアドレス登録・変更 入力',
    component: MainMailInput,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・変更 入力| ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/main-mail-address/confirm',
    name: 'e-mansion メインメールアドレス登録・変更 確認',
    component: MainMailConfirm,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・変更 確認| ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/main-mail-address/complete',
    name: 'e-mansion メインメールアドレス登録・変更 完了',
    component: MainMailComplete,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・変更 完了| ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/main-mail-address/confirm') {
        return next({ path: '/e-mansion' });
      }
      if (!(await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/main-mail-address/cancel-confirm',
    name: 'e-mansion メインメールアドレス登録・変更 解約確認',
    component: MainMailCanselConfirm,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・解約確認 | ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/main-mail-address/cancel-complete',
    name: 'e-mansion メインメールアドレス登録・変更 解約完了',
    component: MainMailCanselComplete,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・解約完了 | ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/main-mail-address/cancel-confirm') {
        return next({ path: '/e-mansion' });
      }
      if (!(await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/mail-password/input',
    name: 'e-mansion メールパスワード変更　入力',
    component: MailPasswordInput,
    meta: {
      title: 'Portas | e-mansion メールパスワード変更 入力 | ポルタス',
      description: '',
      keywords: 'e-mansion メールパスワード変更 入力',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.MAIL_PASSWORD))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/mail-password/complete',
    name: 'e-mansion メールパスワード変更　完了',
    component: MailPasswordComplete,
    meta: {
      title: 'Portas | e-mansion メールパスワード変更 完了 | ポルタス',
      description: '',
      keywords: 'e-mansion メールパスワード変更 完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/mail-password/input') {
        return next({ path: '/e-mansion' });
      }
      if (!(await PermissionControlService.isReadable(SERVICE_ID.MAIL_PASSWORD))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/sub-account/list',
    name: 'e-mansion 追加アカウント　ご利用状況/お申し込み',
    component: SubAccountList,
    meta: {
      title: 'Portas | e-mansion 追加アカウント ご利用状況/お申し込み | ポルタス',
      description: '',
      keywords: 'e-mansion 追加アカウント ご利用状況/お申し込み',
    },
  },
  {
    path: '/e-mansion/sub-account/confirm',
    name: 'e-mansion 追加アカウント　確認(お申し込み)',
    component: SubAccountConfirm,
    meta: {
      title: 'Portas | e-mansion 追加アカウント 確認(お申し込み)| ポルタス',
      description: '',
      keywords: 'e-mansion 追加アカウント 確認(お申し込み)',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/sub-account/list' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/sub-account/list', replace: true });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/sub-account/complete',
    name: 'e-mansion 追加アカウント　完了(お申し込み)',
    component: SubAccountComplete,
    meta: {
      title: 'Portas | e-mansion 追加アカウント 完了(お申し込み)| ポルタス',
      description: '',
      keywords: 'e-mansion 追加アカウント 完了(お申し込み)',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/sub-account/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        next({ path: '/e-mansion/sub-account/list', replace: true });
        return;
      }
      return next();
    },
  },
  {
    path: '/e-mansion/sub-account/cancel-confirm',
    name: 'e-mansion 追加アカウント　確認(解約)',
    component: SubAccountCancelConfirm,
    meta: {
      title: 'Portas | e-mansion 追加アカウント 確認(解約)| ポルタス',
      description: '',
      keywords: 'e-mansion 追加アカウント 確認(解約)',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/sub-account/list') {
        return next({ path: '/e-mansion/sub-account/list', replace: true });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/sub-account/cancel-complete',
    name: 'e-mansion 追加アカウント　完了(解約)',
    component: SubAccountCancelComplete,
    meta: {
      title: 'Portas | e-mansion 追加アカウント 完了(解約)| ポルタス',
      description: '',
      keywords: 'e-mansion 追加アカウント 完了(解約)',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/sub-account/cancel-confirm') {
        return next({ path: '/e-mansion/sub-account/list', replace: true });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/application',
    name: 'e-mansion ホームページ申し込み',
    component: HomepageApplication,
    meta: {
      title: 'Portas | e-mansion ホームページ申し込み | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページ申し込み',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/application-confirm',
    name: 'e-mansion ホームページ申し込み確認',
    component: HomepageApplicationConfirm,
    meta: {
      title: 'Portas | e-mansion ホームページ申し込み確認 | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページ申し込み確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/application-complete',
    name: 'e-mansion ホームページ申し込み完了',
    component: HomepageApplicationComplete,
    meta: {
      title: 'Portas | e-mansion ホームページ申し込み完了 | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページ申し込み完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/usage',
    name: 'e-mansion ホームページご利用状況／ご解約',
    component: HomepageUsageCancel,
    meta: {
      title: 'Portas | e-mansion ホームページご利用状況／ご解約 | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページご利用状況／ご解約',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/cancel-confirm',
    name: 'e-mansion ホームページご解約確認',
    component: HomepageCancelConfirm,
    meta: {
      title: 'Portas | e-mansion ホームページご解約確認 | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページご解約確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/cancel-complete',
    name: 'e-mansion ホームページご解約完了',
    component: HomepageCancelComplete,
    meta: {
      title: 'Portas | e-mansion ホームページご解約完了 | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページご解約完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/i-filter/terms',
    name: 'e-mansion i-フィルターご利用規約',
    component: IfilterService,
    meta: {
      title: 'Portas | e-mansion i-フィルター | ポルタス',
      description: '',
      keywords: 'e-mansion i-フィルター 規約',
    },
  },
  {
    path: '/e-mansion/i-filter/input',
    name: 'e-mansion i-フィルター申し込み',
    component: IfilterInput,
    meta: {
      title: 'Portas | e-mansion i-フィルター | ポルタス',
      description: '',
      keywords: 'e-mansion i-フィルター',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/i-filter/terms' && from.path !== '/e-mansion/i-filter/confirm') {
        next({ path: '/e-mansion/i-filter/terms', replace: true });
        return;
      }

      return next();
    },
  },
  {
    path: '/e-mansion/i-filter/usage',
    name: 'e-mansion i-フィルター利用状況',
    component: IfilterUsage,
    meta: {
      title: 'Portas | e-mansion i-フィルター | ポルタス',
      description: '',
      keywords: 'e-mansion i-フィルター',
    },
  },
  {
    path: '/e-mansion/i-filter/complete',
    name: 'e-mansion i-フィルター申し込み完了',
    component: IfilterComplete,
    meta: {
      title: 'Portas | e-mansion i-フィルター | ポルタス',
      description: '',
      keywords: 'e-mansion i-フィルター',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/i-filter/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        next({ path: '/e-mansion/i-filter/usage', replace: true });
        return;
      }
      return next();
    },
  },
  {
    path: '/e-mansion/i-filter/confirm',
    name: 'e-mansion i-フィルター申し込み確認',
    component: IfilterConfirm,
    meta: {
      title: 'Portas | e-mansion i-フィルター | ポルタス',
      description: '',
      keywords: 'e-mansion i-フィルター',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/i-filter/input' && from.path !== '/platform/payment-method-identity-verification-result') {
        next({ path: '/e-mansion/i-filter/terms', replace: true });
        return;
      }
      return next();
    },
  },
  {
    path: '/e-mansion/static-gip/terms',
    name: '固定グローバルIP申込 規約の確認',
    component: StaticGlobalIpTerms,
    meta: {
      title: 'Portas | e-mansion 固定グローバルIPアドレス | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion' && from.path !== '/e-mansion/static-gip/application' && from.path != '/e-mansion/static-gip/usage') {
        return next({ path: '/e-mansion/static-gip/usage' });
      }
      store.commit('eMansionMacAddressStore/macAddress', null);
      store.commit('eMansionGIPStore/from', null);
      store.commit('eMansionGIPStore/errorCode', null);
      return next();
    },
  },
  {
    path: '/e-mansion/static-gip/application',
    name: '固定グローバルIP申込 お申し込み',
    component: StaticGlobalIpApplication,
    meta: {
      title: 'Portas | e-mansion 固定グローバルIPアドレス | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/static-gip/terms' && from.path !== '/e-mansion/static-gip/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/static-gip/usage' });
      }
      /**
       * 前画面が確認画面か本人認証結果確認画面ではない場合は、ストアの値をnullにする
       * 前画面が確認画面か本人認証結果確認画面の場合の処理は、サービス登録APIを呼び出してエラーだった場合に入れる
       * ※ 非エラーかつ戻るボタン・ブラウザバックで戻った場合を考慮
       */
      if (from.path !== '/e-mansion/static-gip/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        store.commit('eMansionGIPStore/from', null);
        store.commit('eMansionGIPStore/errorCode', null);
      }

      return next();
    },
  },
  {
    path: '/e-mansion/static-gip/confirm',
    name: '固定グローバルIP申込 確認',
    component: StaticGlobalIpConfirm,
    meta: {
      title: 'Portas | e-mansion 固定グローバルIPアドレス | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/static-gip/application' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/static-gip/usage' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/static-gip/completed',
    name: '固定グローバルIP申込 完了',
    component: StaticGlobalIpCompleted,
    meta: {
      title: 'Portas | e-mansion 固定グローバルIPアドレス | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/static-gip/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/static-gip/usage' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/static-gip/usage',
    name: '固定グローバルIP 利用状況',
    component: StaticGlobalIpUsage,
    meta: {
      title: 'Portas | e-mansion 固定グローバルIPアドレス | ポルタス',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/e-mansion/ip-phone/terms',
    name: 'e-mansion IPフォン サービス規約',
    component: EMansionIPPhoneTerms,
    meta: {
      title: 'Portas | e-mansion IPフォン | ポルタス',
      description: 'e-mansion IPフォン サービス規約',
      keywords: 'e-mansion IPフォン サービス規約',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // storeは初期化（確認画面に遷移していたとしてもクリアする）
      store.commit('eMansionIPPhoneStore/from', null);
      store.commit('eMansionIPPhoneStore/inputApplyForm', null);
      store.commit('eMansionIPPhoneStore/inputDestinationForm', null);

      return next();
    },
  },
  {
    path: '/e-mansion/ip-phone/apply',
    name: 'e-mansion IPフォン お申し込み',
    component: EMansionIPPhoneApply,
    meta: {
      title: 'Portas | e-mansion IPフォン | ポルタス',
      description: 'e-mansion IPフォン お申し込み',
      keywords: 'e-mansion IPフォン お申し込み',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/ip-phone/terms' && from.path !== '/e-mansion/ip-phone/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/ip-phone/terms' });
      }

      if (from.path !== '/e-mansion/ip-phone/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        store.commit('eMansionIPPhoneStore/from', null);
      }

      return next();
    },
  },
  {
    path: '/e-mansion/ip-phone/confirm',
    name: 'e-mansion IPフォン お申し込み確認',
    component: EMansionIPPhoneConfirm,
    meta: {
      title: 'Portas | e-mansion IPフォン | ポルタス',
      description: 'e-mansion IPフォン お申し込み確認',
      keywords: 'e-mansion IPフォン お申し込み確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/ip-phone/apply' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/ip-phone/terms' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/ip-phone/completed',
    name: 'e-mansion IPフォン お申し込み完了',
    component: EMansionIPPhoneCompleted,
    meta: {
      title: 'Portas | e-mansion IPフォン | ポルタス',
      description: '',
      keywords: 'e-mansion IPフォン お申し込み完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/ip-phone/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/ip-phone/terms' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/payment-methods/register',
    name: 'e-mansion クレジットカード情報登録変更 設定',
    component: EMansionPaymentMethodsEdit,
    meta: {
      title: 'Portas | e-mansion お支払い方法 | ポルタス',
      description: '',
      keywords: 'e-mansion クレジットカード情報登録変更 設定',
    },
  },
  {
    path: '/e-mansion/payment-methods/register-completed',
    name: 'e-mansion クレジットカード情報登録変更 完了',
    component: EMansionPaymentMethodsEditCompleted,
    meta: {
      title: 'Portas | e-mansion お支払い方法 | ポルタス',
      description: '',
      keywords: 'e-mansion クレジットカード情報登録変更 完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/payment-methods/register' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/payment-methods/register' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/hikari-phone/usage',
    name: 'UCOM光電話 利用状況',
    component: HikariPhoneUsage,
    meta: {
      title: 'Portas | e-mansion UCOM光電話 | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
      /** 会員取得 */
      const member: Member = store.getters['memberStore/member'];
      // 会員情報に物件が紐づいていない or e-mansionアカウントが紐づいていなければ総合トップページへ遷移
      if (!member.propertyId || !member.primaryKeyMye) {
        return next({ path: '/' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/hikari-phone/notification-settings',
    name: 'UCOM光電話 番号通知設定変更',
    component: HikariPhoneNotificationSetting,
    meta: {
      title: 'Portas | e-mansion UCOM光電話 | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/hikari-phone/usage' && from.path !== '/e-mansion/hikari-phone/notification-settings') {
        return next({ path: '/e-mansion/hikari-phone/usage' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/hikari-phone/option/manage',
    name: 'UCOM光電話 オプション申込/解約',
    component: HikariPhoneOptionManage,
    meta: {
      title: 'Portas | e-mansion UCOM光電話 | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (
        from.path !== '/e-mansion/hikari-phone/usage' &&
        from.path !== '/e-mansion/hikari-phone/option/cancel-confirm' &&
        from.path !== '/e-mansion/hikari-phone/option/application-confirm'
      ) {
        return next({ path: '/e-mansion/hikari-phone/usage' });
      }
      if (from.path !== '/e-mansion/hikari-phone/option/cancel-confirm') {
        // 解約するオプションをストアからリセットする
        store.commit('eMansionUcomHikariPhoneOptionCancelStore/optionNumber', null);
      }
      if (from.path !== '/e-mansion/hikari-phone/option/application-confirm') {
        // 登録するオプションをストアからリセットする
        store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_1', null);
        store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_2', null);
        store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_3', null);
        store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_4', null);
      }
      return next();
    },
  },
  {
    path: '/e-mansion/hikari-phone/option/cancel-confirm',
    name: 'UCOM光電話 オプション解約確認',
    component: HikariPhoneOptionCancelConfirm,
    meta: {
      title: 'Portas | e-mansion UCOM光電話 | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/hikari-phone/option/manage') {
        return next({ path: '/e-mansion/hikari-phone/usage' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/hikari-phone/option/cancel-completed',
    name: 'UCOM光電話 オプション解約完了',
    component: HikariPhoneOptionCancelCompleted,
    meta: {
      title: 'Portas | e-mansion UCOM光電話 | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/hikari-phone/option/cancel-confirm') {
        return next({ path: '/e-mansion/hikari-phone/usage' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/hikari-phone/option/application-confirm',
    name: 'UCOM光電話 オプション申込確認',
    component: HikariPhoneOptionApplicationConfirm,
    meta: {
      title: 'Portas | e-mansion UCOM光電話 | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/hikari-phone/option/manage' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/hikari-phone/usage' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/hikari-phone/option/application-completed',
    name: 'UCOM光電話 オプション申込完了',
    component: HikariPhoneOptionApplicationCompleted,
    meta: {
      title: 'Portas | e-mansion UCOM光電話 | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/hikari-phone/option/application-confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/hikari-phone/usage' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/hikari-phone/service-terms',
    name: 'UCOM光電話 事前説明',
    component: HikariphoneServiceTerms,
    meta: {
      title: 'Portas | e-mansion UCOM光電話  | ポルタス',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/e-mansion/hikari-phone/input',
    name: 'UCOM光電話 お申し込み',
    component: HikariphoneInput,
    meta: {
      title: 'Portas | e-mansion UCOM光電話  | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/hikari-phone/service-terms' && from.path !== '/e-mansion/hikari-phone/confirm') {
        return next({ path: '/e-mansion/hikari-phone/service-terms' });
      }
      // 前の画面が事前説明画面の場合はtrueにする
      if (from.path === '/e-mansion/hikari-phone/service-terms') {
        store.commit('eMansionHikariphoneStore/from', true);
      } else {
        store.commit('eMansionHikariphoneStore/from', false);
      }
      return next();
    },
  },
  {
    path: '/e-mansion/hikari-phone/confirm',
    name: 'UCOM光電話 確認',
    component: HikariphoneConfirm,
    meta: {
      title: 'Portas | e-mansion UCOM光電話  | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/hikari-phone/input' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/hikari-phone/service-terms' });
      }
      if (from.path === '/e-mansion/hikari-phone/input') {
        store.commit('errorMessageStore/errorMessages', null);
        store.commit('errorMessageStore/errorMessageTitle', null);
      }
      return next();
    },
  },
  {
    path: '/e-mansion/hikari-phone/completed',
    name: 'UCOM光電話 完了',
    component: HikariphoneCompleted,
    meta: {
      title: 'Portas | e-mansion UCOM光電話  | ポルタス',
      description: '',
      keywords: '',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/hikari-phone/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/hikari-phone/service-terms' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/course/input',
    name: 'e-mansion 接続先コース変更 お申し込み',
    component: CourseInput,
    meta: {
      title: 'Portas | e-mansion 接続先コース変更 | ポルタス',
      description: '',
      keywords: 'e-mansion 接続先コース変更 お申し込み',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      store.commit('selectedCourseStore/equipmentcontract', undefined);
      store.commit('selectedCourseStore/speed', undefined);
      store.commit('selectedCourseStore/charge', undefined);
      store.commit('selectedCourseStore/chargeCp', undefined);
      store.commit('selectedCourseStore/adapterCharge', undefined);
      store.commit('selectedCourseStore/adapterChargeCampaign', undefined);
      store.commit('selectedCourseStore/type', undefined);
      store.commit('selectedCourseStore/checkMyCurrentCourseId', undefined);
      store.commit('selectedCourseStore/paymentIssueMsg', undefined);
      next();
    },
  },
  {
    path: '/e-mansion/course/confirm',
    name: 'e-mansion 接続先コース変更 確認',
    component: CourseConfirm,
    meta: {
      title: 'Portas | e-mansion 接続先コース変更 | ポルタス',
      description: '',
      keywords: 'e-mansion 接続先コース変更 確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/course/input' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/course/input' });
      }
      return next();
    },
  },
  {
    path: '/e-mansion/course/complete',
    name: 'e-mansion 接続先コース変更 完了',
    component: CourseComplete,
    meta: {
      title: 'Portas | e-mansion 接続先コース変更 | ポルタス',
      description: '',
      keywords: 'e-mansion 接続先コース変更 完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/course/confirm' && from.path !== '/platform/payment-method-identity-verification-result') {
        return next({ path: '/e-mansion/course/input' });
      }
      return next();
    },
  },
];

export default eMansionRouter;
