<template>
  <div class="e-mansion-hikari-phone-option-cancel-completed">
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li class="stay">UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- stepnavi -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>オプションお申し込み・解約</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- stepnavi -->

      <!-- 本文 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="付加サービス（有料）ご解約受付完了" />付加サービス（有料）ご解約受付完了</h2>
        <p>付加サービスのご解約を受け付けました。</p>

        <table class="table-type2">
          <tbody>
            <tr>
              <th>付加サービス</th>
              <td>{{ serviceName }}</td>
            </tr>
          </tbody>
        </table>

        <div class="sblc">
          <ul>
            <li class="bold">付加サービスのサービス終了日について</li>
            <p>
              1日から20日迄(21日午前0時より翌月扱い)にご解約のお手続きをされた場合、当月の末日をもって付加サービスが無効となります。21日から月末にご解約のお手続きをされた場合、翌月の末日をもって付加サービスが無効となります。
            </p>
          </ul>
          <p><span class="red">「UCOM光電話」サービスを解約される場合は、別途「UCOM光電話」サービスの解約お手続きが必要です。</span></p>
          <ul>
            <li>付加サービスの変更お手続き完了後、「UCOM 光電話オプション契約変更通知書」をお送りいたします。</li>
          </ul>
        </div>

        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goTop()">UCOM光電話 ご利用状況 <i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.table-type2 th {
  border-bottom: 1px solid #d3d3d3;
  width: 100%;
  display: block; /* セルを縦に */
}
.table-type2 td {
  font-size: 15px; /* フォントは15px */
  width: 100%;
  display: block; /* セルを縦に */
}
li.bold {
  font-weight: bold;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UCOM_HIKARI_PHONE_OPTION_ON_EMANSION } from '@/shared/const/e-mansion';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** Completed コンポーネント */
export default defineComponent({
  name: 'hikari-phone-option-cancel-confirm',
  components: {
    LoadingComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** 解約対象のオプション番号 */
    optionalNumber: null as null | number,
    /** 解約対象のオプション名 */
    serviceName: '' as string | undefined,
  }),
  async mounted() {
    try {
      if (!(await MountedCheckService.canReadEMansionScreen())) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 確認画面から保持している解約オプション番号を利用してサービス名を表示
      this.optionalNumber = this.$store.getters['eMansionUcomHikariPhoneOptionCancelStore/optionNumber'];
      if (!this.optionalNumber) {
        // データ不整合エラー(前画面からの情報がない)
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      this.serviceName = this.getDisplayServiceName(this.optionalNumber);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
    this.isLoading = false;
  },
  methods: {
    getDisplayServiceName(id: number): string {
      /** オプション解約：OptionNumberからサービス名称（表示用）を取得 */
      for (const v of Object.values(UCOM_HIKARI_PHONE_OPTION_ON_EMANSION)) {
        if (v.number == id) {
          return v.name;
        }
      }
      // fail safe: program error
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
    },
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/hikari-phone/usage').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
