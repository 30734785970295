<template>
  <div>
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/e-mansion">e-mansion 会員専用トップ</a></li>
        <li>UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>オプションお申し込み・解約</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="付加サービス（有料）お申し込み受付完了" />付加サービス（有料）お申し込み受付完了</h2>
        <p>付加サービスのお申し込みありがとうございます。</p>
      </div>
      <div>
        <table class="table-type2">
          <tbody>
            <tr>
              <th>付加サービス</th>
              <th>料金</th>
            </tr>
            <tr v-for="(option, index) in optionArrayForDisplay" :key="index">
              <td>{{ option?.name }}</td>
              <td>月額 {{ option?.fee }}円</td>
            </tr>
          </tbody>
        </table>
        <ul>
          <li>お申し込み直後からご利用状況が「ご利用中」と表示されますが、付加サービスが有効となるのはお申し込み日の5営業日後となります。</li>
          <li>付加サービスの変更お手続き完了後、「UCOM 光電話オプション契約変更通知書」をお送りいたします。</li>
        </ul>
      </div>
      <!-- ご契約内容の交付方法 -->
      <ContractDetailsConfirmMethod />

      <div class="btn-area">
        <button class="btn btn01 bs" v-on:click="goTop()">UCOM光電話 ご利用状況 <i class="material-icons link link-icon">east</i></button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../../images/main.png');
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-option-dto';
import { UCOM_HIKARI_PHONE_OPTION_ON_EMANSION } from '@/shared/const/e-mansion';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import ContractDetailsConfirmMethod from '@/shared/components/e-mansion/contract-details-confirm-method.vue';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { VueErrorHandler } from '@/handler/error/vue-error-handler';

/** Completed コンポーネント */
export default defineComponent({
  name: 'hikari-phone-option-application-completed',
  components: {
    LoadingComponent,
    ContractDetailsConfirmMethod,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** 着信番号表示 */
    option_1: null as null | boolean,
    /** キャッチ通話 */
    option_2: null as null | boolean,
    /** キャッチ通話番号表示 */
    option_3: null as null | boolean,
    /** オプションパック */
    option_4: null as null | boolean,
    /** e-mansion物件情報 */
    eMansionProperty: null as EMansionProperty | null,
    /** オプションサービス 表示用 */
    optionArrayForDisplay: [] as UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO[],
  }),
  async mounted() {
    this.isLoading = true;

    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    try {
      /** 物件基本情報をStoreから取得 */
      await StoreExternalApiResponse.main();
      this.eMansionProperty = this.$store.getters['eMansionCommonStore/property'];

      // 各オプションの情報取得
      await this.getCheckedOptionsInfo();

      this.isLoading = false;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }
  },
  methods: {
    /**
     * @param billing 表示する金額
     * @return フォーマットされた金額文字列（例：xx,xxx）
     */
    formatMoney(billing: number): string {
      const formatter = new Intl.NumberFormat('en-US');
      const formattedValue = formatter.format(billing);
      return formattedValue;
    },
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/hikari-phone/usage').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /** 各オプションの情報取得 */
    async getCheckedOptionsInfo() {
      // 前画面で選択した申し込みオプションのストアの値を取得
      this.option_1 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_1'];
      this.option_2 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_2'];
      this.option_3 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_3'];
      this.option_4 = this.$store.getters['eMansionUcomHikariPhoneOptionEntryStore/option_4'];

      const updateHikariPhoneInfo = this.$store.getters['eMansionUcomHikariPhoneOptionEntriedInfoStore/info'];

      if (this.option_1) {
        // 着信番号表示
        this.optionArrayForDisplay.push(
          new UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO({
            name: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.NUMBER_DISPLAY.name,
            fee: this.formatMoney(Number(updateHikariPhoneInfo.optional_data.Hikariphone.billing_data.numberdisplay_fee.replace(/,/, ''))),
          })
        );
      }

      if (this.option_2) {
        // キャッチ通話
        this.optionArrayForDisplay?.push(
          new UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO({
            name: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH.name,
            fee: this.formatMoney(Number(updateHikariPhoneInfo.optional_data.Hikariphone.billing_data.catch_fee.replace(/,/, ''))),
          })
        );
      }

      if (this.option_3) {
        // 着信番号表示
        this.optionArrayForDisplay?.push(
          new UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO({
            name: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH_NUMBER_DISPLAY.name,
            fee: this.formatMoney(Number(updateHikariPhoneInfo.optional_data.Hikariphone.billing_data.catch_display_fee.replace(/,/, ''))),
          })
        );
      }

      if (this.option_4) {
        // キャッチ通話
        this.optionArrayForDisplay?.push(
          new UcomHikariPhoneOptionForDisplayOnApplicationConfirmDTO({
            name: UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.OPTION_PACK.name,
            fee: this.formatMoney(Number(updateHikariPhoneInfo.optional_data.Hikariphone.billing_data.servicepack_fee.replace(/,/, ''))),
          })
        );
      }
      this.$store.commit('eMansionUcomHikariPhoneOptionEntriedInfoStore/info', null);
    },
  },
});
</script>
