/** 営業日マスタ取得API */
export class BusinessdaysUtilDTO {
  /** 年月日 */
  public businessDate!: Date;
  /** 日付表示文字列 */
  public displayBusinessday!: string;
  public week?: string;

  constructor(partial: Partial<BusinessdaysUtilDTO>) {
    Object.assign(this, partial);
  }
}

export type BusinessdaysUtilDTOArray = BusinessdaysUtilDTO[];
