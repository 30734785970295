<template>
  <div>
    <h2 class="underlayer-h2" v-if="isDisplayHeadline">ご契約内容のご案内について</h2>
    <p>
      ご契約内容のご案内について、交付方法を選択いただけます。 書面での確認を必要とされない場合、「電子交付を希望」を選択してください。<br />
      「電子交付」にはPDF形式で閲覧可能な環境が必要です。
    </p>
    <table class="table-type2">
      <tbody>
        <tr>
          <th rowspan="3">ご契約内容の<br />交付方法の選択<span class="req">必須</span></th>
          <!--新規登録以外で、SIN前orスリムプランorTTP物件の場合は非表示-->
          <td v-if="isDispSendRegistedMailAddress">
            <label
              ><input type="radio" value="1" name="how-documents-send" v-model="inputData.selectedRadio" @input="sendToParent" />電子交付を希望（登録いただいたメールアドレス「{{
                emailAddress
              }}」へ送信）</label
            >
          </td>
        </tr>
        <tr v-if="!isTtp">
          <td>
            <label
              ><input
                type="radio"
                value="2"
                name="how-documents-send"
                v-model="inputData.selectedRadio"
                @input="sendToParent"
              />電子交付を希望（ご指定のメールアドレスへ送信）</label
            >
            <p class="red form-description">※ご希望のメールアドレスを入力してください</p>
            <input type="text" class="text middle" v-model="inputData.inputMailAddress" @input="sendToParent" />
            <p class="red form-description">※確認のため、もう一度入力してください</p>
            <input type="text" class="text middle" v-model="inputData.inputMailAddressConfirm" @input="sendToParent" />
          </td>
        </tr>
        <tr>
          <td>
            <label><input type="radio" value="3" name="how-documents-send" v-model="inputData.selectedRadio" @input="sendToParent" />電子交付を希望しない</label>
            <div v-if="!isSlimPlan && isEntryEMansion && inputData.deliveryZipcodeFormer && inputData.deliveryZipcodeLatter && inputData.destinationAddress1">
              <p class="red">※登録いただいたご希望送付先に契約書面を郵送いたします。</p>
              <p>〒{{ inputData.deliveryZipcodeFormer }}-{{ inputData.deliveryZipcodeLatter }}</p>
              <p>{{ inputData.destinationAddress1 }} {{ inputData.destinationAddress2 }}</p>
            </div>
            <div v-else>
              <p class="form-description">※契約書面を郵送いたします。送付先住所を入力してください。</p>
              〒<input type="text" class="text short" placeholder="000" maxlength="3" v-model="inputData.deliveryZipcodeFormer" @input="sendToParent" />
              <span> - </span>
              <input type="text" class="text short" placeholder="0000" maxlength="4" v-model="inputData.deliveryZipcodeLatter" @input="sendToParent" />
              <button @click="searchAddressByZipcode()" class="apartment-search-button btn btn01">住所検索</button>
              <br />

              <p class="form-description">ご住所（市町村名や番地まで入力してください）</p>

              <input type="text" class="text middle" v-model="inputData.destinationAddress1" placeholder="例)東京都千代田区大手町2-2-1（全角30文字以内）" @input="sendToParent" />
              <br />

              <p class="form-description">ご住所（マンション名お部屋番号などがあれば入力してください）</p>

              <input type="text" class="text middle" v-model="inputData.destinationAddress2" placeholder="例)つなぐマンション620号室（全角60文字以内）" @input="sendToParent" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.form-description {
  margin: 1rem 0;
}
input.text.middle {
  width: 500px;
}

@media only screen and (max-width: 767px) {
  input.text.middle {
    width: 100%;
  }
}
</style>

<script lang="ts">
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { Member } from '@/shared/classes/spf-api/member';
import { E_MANSION_MAIN_ACCOUNT_PLAN_TYPE } from '@/shared/const/e-mansion/e-mansion-main-account-plan-type';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { defineComponent } from 'vue';

/** エラーメッセージ表示エリアコンポーネント */
export default defineComponent({
  name: 'e-mansion-entry-send-documents-component',
  // 親コンポーネントから受け取るデータ
  props: {
    // 見出し（ご契約内容のご案内について）を表示するかどうか
    // 未設定なら表示
    isDisplayHeadline: {
      type: Boolean,
      default: true,
    },
    //e-mansion入会処理かどうか
    isEntryEMansion: {
      type: Boolean,
      default: false,
    },
    inputDeliveryData: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    isLoading: false,
    emailAddress: '',
    inputData: {
      selectedRadio: '',
      emailAddress: '',
      inputMailAddress: '',
      inputMailAddressConfirm: '',
      deliveryZipcodeFormer: '',
      deliveryZipcodeLatter: '',
      destinationAddress1: '',
      destinationAddress2: '',
    },
    errorMessages: [] as string[],
    isBeforeSin: false,
    isSlimPlan: false,
    isTtp: false,
    isDispSendRegistedMailAddress: true,
  }),
  emits: ['inputData', 'errorMessages'],
  mounted() {
    const member: Member = this.$store.getters['memberStore/member'];
    const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
    //SIN前か否か
    this.isBeforeSin = this.isPrevServiceInDay(property.em_sin_time);
    const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
    // スリムプラン会員か否か
    this.isSlimPlan = this.checkSlimPlan(property);
    //TTP会員か否か
    this.isTtp = servicePlanType === SERVICE_PLAN_TYPE.EM_TTP;

    //登録いただいたメールアドレスの枠を表示するか否か
    if (!this.isEntryEMansion && (this.isBeforeSin || this.isSlimPlan || this.isTtp)) {
      this.isDispSendRegistedMailAddress = false;
    }
    //ラジオボタンの初期値
    //前画面からの値があればその値
    /**
     * 入会→登録メールアドレス
     * SIN前 or スリムプラン→メールアドレス入力
     * TTP物件→住所
     * それ以外→登録メールアドレス
     **/
    if (this.inputDeliveryData.selectedRadio) {
      this.inputData.selectedRadio = this.inputDeliveryData.selectedRadio;
    } else {
      if (this.isEntryEMansion) {
        this.inputData.selectedRadio = '1';
      } else if (this.isBeforeSin || this.isSlimPlan) {
        this.inputData.selectedRadio = '2';
      } else if (this.isTtp) {
        this.inputData.selectedRadio = '3';
      } else {
        this.inputData.selectedRadio = '1';
      }
    }
    //他初期値
    if (this.inputDeliveryData.deliveryZipcodeFormer && this.inputDeliveryData.deliveryZipcodeLatter) {
      this.inputData.deliveryZipcodeFormer = this.inputDeliveryData.deliveryZipcodeFormer.slice(0, 3);
      this.inputData.deliveryZipcodeLatter = this.inputDeliveryData.deliveryZipcodeLatter.slice(-4);
    }
    this.inputData.inputMailAddress = this.inputDeliveryData.inputMailAddress;
    this.inputData.inputMailAddressConfirm = this.inputDeliveryData.inputMailAddressConfirm;
    this.inputData.destinationAddress1 = this.inputDeliveryData.destinationAddress1;
    this.inputData.destinationAddress2 = this.inputDeliveryData.destinationAddress2;

    if (this.isEntryEMansion) {
      this.emailAddress = member.emailAddress;
      this.inputData.emailAddress = this.emailAddress;
    } else {
      this.emailAddress = this.selectDispEmailAddress();
      this.inputData.emailAddress = this.emailAddress;
    }

    // 1が選択状態で、メールアドレスがない場合、デフォルトチェックを電子交付を希望側に設定しなおす
    if (this.inputData.selectedRadio == '1' && !this.isDispSendRegistedMailAddress) {
      this.inputData.selectedRadio = '2';
    }
    // 初期値を上位画面に戻す
    this.sendToParent();
  },

  methods: {
    isPrevServiceInDay(serviceInDate: string) {
      const today = new Date();
      const year = parseInt(serviceInDate.substring(0, 4), 10);
      const month = parseInt(serviceInDate.substring(4, 6), 10) - 1; // 月は0から始まるので-1
      const day = parseInt(serviceInDate.substring(6, 8), 10);
      const serviceInDay = new Date(year, month, day);
      console.log(`現在日時:${today}`);
      console.log(`SIN日:${serviceInDay}`);
      return today < serviceInDay;
    },
    // スリムプラン会員か否かのチェック
    checkSlimPlan(property: EMansionProperty): boolean {
      const flags = property.apartment_special_type_flag[0];
      if (flags[E_MANSION_MAIN_ACCOUNT_PLAN_TYPE.SLIM_PLAN] === '1') {
        return true;
      }
      return false;
    },
    //画面に表示するメールアドレスを決める
    selectDispEmailAddress(): string {
      const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      const customer: EMansionCustomer = this.$store.getters['eMansionCommonStore/customer'];
      //メインメールアドレス利用あり
      if (accountInfo.hasMainMailAddressAsMember) {
        if (customer.notify_mailaddress[1]) {
          return customer.notify_mailaddress[Number(customer.notify_mail_type)];
        } else {
          return accountInfo.eMansionMailAddressInfo?.mailAddressOriginal || accountInfo.eMansionMailAddressInfo?.mailAddress!;
        }
      } else {
        if (customer.notify_mailaddress[1]) {
          return customer.notify_mailaddress[1];
        } else {
          this.isDispSendRegistedMailAddress = false;
          return '';
        }
      }
    },
    async searchAddressByZipcode() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const zipCode = `${this.inputData.deliveryZipcodeFormer}-${this.inputData.deliveryZipcodeLatter}`;
      this.errorMessages = [];
      const ZIP_CODE_REGEX = /^[0-9]{3}-[0-9]{4}$/;
      if (!this.inputData.deliveryZipcodeFormer || !this.inputData.deliveryZipcodeLatter) {
        this.errorMessages.push('「「ご契約内容の交付方法」郵便番号」が正しくありません。');
      } else if (!ZIP_CODE_REGEX.test(zipCode)) {
        this.errorMessages.push('「「ご契約内容の交付方法」郵便番号」が正しくありません。');
      }
      if (this.errorMessages.length > 0) {
        this.$emit('errorMessages', this.errorMessages);
        this.isLoading = false;
        return;
      }
      try {
        const resultList = await SpfApiService.searchAddresses(zipCode);
        if (resultList.length > 0) {
          const result = resultList[0];
          this.inputData.destinationAddress1 = `${result.address1}${result.address2}${result.address3}`;
        } else {
          this.errorMessages.push('「「ご契約内容の交付方法」郵便番号」が正しくありません。');
          this.$emit('errorMessages', this.errorMessages);
          this.isLoading = false;
          return;
        }
      } catch (e) {
        // エラーのときは何もしない
      }
      this.isLoading = false;
    },
    sendToParent() {
      this.$emit('inputData', this.inputData);
    },
  },
});
</script>
