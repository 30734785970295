import { Module } from 'vuex';
import { RootState } from '../index';

import { LinkServiceWithEMansionResponse } from '@/shared/classes/spf-api/link-service-with-e-mansion';
import { EMansionEntryInputForm } from '../../pages/e-mansion/entry/classes/entry-input-form';

/** e-mansion  お申し込み State */
const eMansionEntryState: {
  entryInputForm: EMansionEntryInputForm | null;
  result: LinkServiceWithEMansionResponse | null;
  beforePathInput: string | null;
  beforePathConfirm: string | null;
} = {
  /** e-mansion  お申し込み 入力フォーム */
  entryInputForm: null,
  /** e-mansion同時申し込みAPI　レスポンス */
  result: null,
  /** お客様情報入力画面に遷移する前の画面 */
  beforePathInput: '',
  /**確認画面に遷移する前の画面 */
  beforePathConfirm: '',
};

/** State の型 */
type EMansionEntryState = typeof eMansionEntryState;

/** e-mansion  お申し込み Store */
export const eMansionEntryStore: Module<EMansionEntryState, RootState> = {
  namespaced: true,
  state: eMansionEntryState,
  actions: {},
  mutations: {
    entryInputForm: (state, value: EMansionEntryInputForm) => (state.entryInputForm = value),
    result: (state, value: LinkServiceWithEMansionResponse) => (state.result = value),
    beforePathInput: (state, value: string) => (state.beforePathInput = value),
    beforePathConfirm: (state, value: string) => (state.beforePathConfirm = value),
  },
  getters: {
    entryInputForm: (state): EMansionEntryInputForm | null => state.entryInputForm,
    result: (state): LinkServiceWithEMansionResponse | null => state.result,
    beforePathInput: (state): string | null => state.beforePathInput,
    beforePathConfirm: (state): string | null => state.beforePathConfirm,
  },
};
