<template>
  <div>
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion UCOM光電話</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/e-mansion">e-mansion 会員専用トップ</a></li>
        <li>UCOM光電話</li>
      </ul>
      <!-- /breadcrumb -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="UCOM光電話 ご利用状況" />UCOM光電話 ご利用状況</h2>

        <div class="blc">
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>ご利用状況</b></th>
                <td>
                  <p class="pre">{{ statusForDisplay }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="blc">
          <p>通話料明細は下記サイトよりご確認いただけます。</p>
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>通話料明細WEB</b></th>
                <td>
                  <p>
                    <a class="billing-link" :href="`${hikariPhoneBillingDetailsURL}`" target="_blank"> {{ hikariPhoneBillingDetailsURL }}</a>
                  </p>
                </td>
              </tr>
              <tr>
                <th><b>ログインID</b></th>
                <td>{{ loginId }}</td>
              </tr>
              <tr>
                <th><b>パスワード</b></th>
                <td>{{ password }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 付加サービス（有料） -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="付加サービス（有料）" />付加サービス（有料）</h2>

        <table class="table-type2">
          <tbody>
            <tr v-for="(elem, index) in optionArrayForDisplay" :key="index">
              <th>{{ elem.name }}</th>
              <td>{{ elem.status }}</td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn01 bs" v-if="isInUseHikariPhone" type="button" @click="onOptionManage()">付加サービスのお申し込み・解約はこちら</button>
      </div>

      <!-- 番号通知 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="番号通知（無料）" />番号通知（無料）</h2>

        <table class="table-type2">
          <tbody>
            <tr>
              <th><b>番号通知（無料）</b></th>
              <td v-if="isNoticeNumber">相手に通知する</td>
              <td v-else>相手に通知しない</td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn01 bs" v-if="isInUseHikariPhone" type="button" @click="onNotioficationSettings()">番号通知（無料）の設定変更はこちら</button>
      </div>

      <!-- ボタン -->
      <div class="btn-area">
        <button class="btn btn05 bs" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
        <button class="btn btn01 bs" type="button" v-if="isInUseHikariPhone">
          <a :href="`${eMansionMyUrl}/login/login.php?function=HIKARIPHONE&apid=${apid}&rd=${rd}`" class="cancel" target="_blank">解約はこちら</a>
          <i class="material-icons link link-icon">east</i>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
div.btn-area {
  position: relative;
  padding-top: 72px;
  text-align: center;
}

a.billing-link {
  color: #cf1225;
  text-decoration: underline;
}
a.billing-link:hover {
  color: #0060ff;
}
a,
a.cancel:hover {
  text-decoration: none;
  color: #ffffff;
}
.pre {
  white-space: pre-line;
}
button.btn {
  margin-top: 10px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import dayjs from 'dayjs';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { Member } from '@/shared/classes/spf-api/member';
import { EMansionCustomer, Hikariphone, HikariphoneOption } from '@/shared/classes/external-api/e-mansion/customer-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { UCOM_HIKARI_PHONE_NOTIFICATION_FLAG_ON_MANSION, UCOM_HIKARI_PHONE_OPTION_ON_EMANSION, UCOM_HIKARI_PHONE_STATUS_ON_MANSION } from '@/shared/const/e-mansion';
import { UcomHikariPhoneOptionOnEmansionForDisplayDTO } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-option-dto';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
/** Completed コンポーネント */
export default defineComponent({
  name: 'hikari-phone-usage',
  components: {
    LoadingComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** e-mantion My の URL */
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    /** 追加申し込みボタンの表示
     * 表示:ture,非表示:false */
    isDisplayAdditionalApply: false,
    /** UCOM の 通話料明細WEB URL */
    hikariPhoneBillingDetailsURL: process.env.VUE_APP_UCOM_HIKARIPHONE_BILLING_DETAILS_WEB_URL,
    /** 物件情報 */
    property: null as EMansionProperty | null,
    // e-mansion契約基本情報
    customer: null as EMansionCustomer | null,
    /**ランダムで設定する9桁の数字を当てはめる */
    rd: '',
    /**UCOM光電話 利用状況が「利用中」の場合
     * true:利用中、false:それ以外
     */
    isInUseHikariPhone: false,
    /**番号通知するか
     * true:する、false:しない
     */
    isNoticeNumber: false,
    /** 契約基本情報取得API.オプション利用状況.UCOM光電話.付加サービス */
    optionArray: [] as HikariphoneOption[],
    /** 契約基本情報取得API.オプション利用状況.UCOM光電話.付加サービス 表示用*/
    optionArrayForDisplay: [] as UcomHikariPhoneOptionOnEmansionForDisplayDTO[],
    /** 契約基本情報取得API.オプション利用状況.UCOM光電話.利用状況　表示用 */
    statusForDisplay: null as string | null,
    loginId: null as string | null,
    password: null as string | null,
  }),
  computed: {
    apid() {
      if (this.$data.property) {
        return this.$data.property.IN_APID;
      } else {
        return '';
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    try {
      /** 会員取得 */
      const member: Member = this.$store.getters['memberStore/member'];
      // 会員情報に物件が紐づいていない or e-mansionアカウントが紐づいていなければ総合トップページへ遷移
      if (!member.propertyId || !member.primaryKeyMye) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
      }

      await StoreExternalApiResponse.main();
      /** 物件基本情報をStoreから取得 */
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      this.property = property;

      /** 契約基本情報をStoreから取得 */
      await StoreExternalApiResponse.storeCustomerDecryptedApiResponseEmansion(this.property.apartment_id, member.primaryKeyMye);
      const customer = this.$store.getters['eMansionCommonStore/customerDecrypted'];
      this.customer = new EMansionCustomer(customer);

      if (this.customer instanceof EMansionCustomer) {
        // 顧客情報に物件情報が紐付けられていなければエラーとする
        if (!this.customer.property_id) {
          // データ不整合エラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
        }

        // 契約基本情報取得API.オプション利用状況.UCOM光電話 の要素が存在しない（=契約なし）の場合はUCOM光電話 サービス規約画面 を表示
        if (this.customer.op.hikariphone.length === 0) {
          await this.$router
            .push('/e-mansion/hikari-phone/service-terms')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              checkRouterError(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
          return;
        }

        let hikariPhoneInfo: Hikariphone = this.customer!.op.hikariphone[0];

        // 契約基本情報取得API.オプション利用状況.UCOM光電話.利用状況 が　利用中か確認する
        const status = Number(hikariPhoneInfo.status);
        if (status) {
          if (status === UCOM_HIKARI_PHONE_STATUS_ON_MANSION.IN_USE) {
            this.isInUseHikariPhone = true;
          }
        }

        // 契約基本情報取得API.オプション利用状況.UCOM光電話.番号通知 の値を確認
        const notificationFlag = Number(hikariPhoneInfo.notification_flag);
        if (notificationFlag) {
          if (notificationFlag === UCOM_HIKARI_PHONE_NOTIFICATION_FLAG_ON_MANSION.NOTICE) {
            this.isNoticeNumber = true;
          }
        }

        // 契約基本情報取得API.オプション利用状況.UCOM光電話.付加サービス.利用状況 の各値を確認して表示用に詰めなおす
        await this.createOptionArrayForDisplay(hikariPhoneInfo);

        // 契約基本情報取得API.オプション利用状況.UCOM光電話.利用状況/利用開始日/解約日  の各値を確認して表示用の文言作成
        await this.createStatusForDisplay(hikariPhoneInfo);

        // ログインId/パスワード取得
        await this.getLoginIdAndPassword(hikariPhoneInfo);
      }

      // 9桁のランダムな数字を取得
      this.rd = this.createRd();

      this.isLoading = false;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }
  },
  methods: {
    async onOptionManage() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion/hikari-phone/option/manage')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onNotioficationSettings() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion/hikari-phone/notification-settings')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    /** 付加サービス 表示用 の配列作成*/
    async createOptionArrayForDisplay(customer: Hikariphone): Promise<void> {
      if (customer) {
        const hikariPhoneOption = customer.hikariphone_option;
        if (hikariPhoneOption.length !== 0) {
          let nameOnDisplay = undefined;
          for (const v of hikariPhoneOption) {
            let orderNumber = 0;
            const statusOnDisplay = String(v.status) == '00' ? 'ご利用なし' : 'ご利用中';
            if (v.name === 'number_display') {
              nameOnDisplay = UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.NUMBER_DISPLAY.name;
              orderNumber = UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.NUMBER_DISPLAY.number;
            } else if (v.name === 'catch') {
              nameOnDisplay = UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH.name;
              orderNumber = UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH.number;
            } else if (v.name === 'catch_number_display') {
              nameOnDisplay = UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH_NUMBER_DISPLAY.name;
              orderNumber = UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.CATCH_NUMBER_DISPLAY.number;
            } else if (v.name === 'option_pack') {
              nameOnDisplay = UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.OPTION_PACK.name;
              orderNumber = UCOM_HIKARI_PHONE_OPTION_ON_EMANSION.OPTION_PACK.number;
            }

            const formattedArray = new UcomHikariPhoneOptionOnEmansionForDisplayDTO({
              name: nameOnDisplay,
              status: statusOnDisplay,
              order_display: orderNumber,
            });

            this.optionArrayForDisplay.push(formattedArray);
          }
          // 表示順に並べ替え
          this.optionArrayForDisplay.sort((a, b) => a.order_display - b.order_display);
        }
      }
    },
    /** 付加サービス 表示用 の配列作成*/
    async createStatusForDisplay(customer: Hikariphone): Promise<void> {
      if (customer) {
        const status = Number(customer.status);
        if (status) {
          // 申込/申込IPM連携済の場合
          if (status === UCOM_HIKARI_PHONE_STATUS_ON_MANSION.APPLY || status === UCOM_HIKARI_PHONE_STATUS_ON_MANSION.APPLY_IPM_LINKED) {
            this.statusForDisplay = `お申し込みを受け付けました。
            「開通通知書」がお手元に届きましたら、別途お送りする専用アダプタを接続してご利用開始ください。`;
            // 利用中の場合
          } else if (status === UCOM_HIKARI_PHONE_STATUS_ON_MANSION.IN_USE) {
            const formattedDate = dayjs(customer.start_time).format('YYYY/MM/DD');
            this.statusForDisplay = `ご利用中（サービス開始日：${formattedDate}）`;
          } else if (status === UCOM_HIKARI_PHONE_STATUS_ON_MANSION.CANCEL_APPLY || status === UCOM_HIKARI_PHONE_STATUS_ON_MANSION.CANCEL_IPM_LINKED) {
            const formattedDate = dayjs(customer.end_time).format('YYYY/MM/DD');
            this.statusForDisplay = `ご解約を受け付けました。
            「解約完了通知書」がお手元に届きましたら、専用アダプタをご返却ください。
            （サービス終了日：${formattedDate}）`;
          }
        }
      }
    },
    /**
     * 表示用ログインIDとPWを取得
     * PWは難読化されているため復号
     */
    async getLoginIdAndPassword(customer: Hikariphone): Promise<void> {
      if (customer) {
        if (customer.web_login_id) {
          this.loginId = customer.web_login_id;
        } else {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
        }
        if (customer.web_login_password_decrypted) {
          // backendで復号＆難読化されたパスワードが返るので難読化解読する
          const decoredWebLoginPass = ObfuscationService.decode(customer.web_login_password_decrypted);
          this.password = decoredWebLoginPass;
        } else {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
        }
      }
    },
  },
});
</script>
