import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionCourseRegisterResponseDto } from '@/shared/classes/spf-api/e-mansion-course-dto';
import { EMansionIfilterRegisterResponseDto } from '@/shared/classes/spf-api/e-mansion-ifilter-dto';
import { BaseApiService } from '@/shared/services/api/base-api-service';

const api = BaseApiService.api();

/** 内部 API をコールするサービス */
export class SpfApiCourseAccessor {
  /**
   * e-mansion 接続コース変更　サービス登録API
   * @return
   */
  public static async registerAnotherCourse(
    courseId: string,
    contractDestinationKind: string,
    contractDestinationMailaddress: string | '',
    contractDestinationZipcode: string | '',
    contractDestinationAddress1: string | '',
    contractDestinationAddress2: string | '',
    creditCardRegistFlag: string,
    apartmentId: string,
    memberId: string
  ): Promise<EMansionCourseRegisterResponseDto> {
    const response = await api
      .patch<EMansionCourseRegisterResponseDto>(`/course/e-mansion/register`, {
        courseId,
        contractDestinationKind,
        contractDestinationMailaddress,
        contractDestinationZipcode,
        contractDestinationAddress1,
        contractDestinationAddress2,
        creditCardRegistFlag,
        apartmentId,
        memberId,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return response.data;
  }
}
