<template>
  <div class="five-a-index">
    <LoadingComponent v-if="!isMounted || isExecutingNavigateToLocation" />
    <main class="underlayer-main">
      <h1>会員専用トップページ</h1>
    </main>

    <!-- ISP(Five.A)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書 -->
    <template v-if="isDisplayOnlyBillingDetails">
      <div class="contents">
        <ul class="breadcrumb">
          <li><router-link to="/">トップページ</router-link></li>
          <li>Five.A 会員専用トップ</li>
        </ul>

        <div class="billing-details-container">
          <p>ご請求明細はこちらからご確認ください</p>
          <p class="description"><a class="link" :href="`${billingDetailsURL}`" target="My Five.A">ご請求明細・販売証明書表示</a></p>
          <p class="description">※ Five.A退会後、13か月間はPortasにログインし、ご請求明細をご確認いただけます。</p>
        </div>

        <div class="blc">
          <p>Five.Aインターネットサービスについて、サービス内容やご利用方法、お問い合わせ先は、お住まいの建物専用ホームページをご覧ください。</p>
          <p v-if="domainName" class="ttl"><a :href="`${domainName}/${apartmentId8Digit}`" class="link" target="My Five.A">お住まいの建物専用ホームページはこちら</a></p>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="contents">
        <div class="contents">
          <ul class="breadcrumb">
            <li><router-link to="/">トップページ</router-link></li>
            <li>Five.A 会員専用トップ</li>
          </ul>

          <!-- Five.Aからのお知らせが存在する場合 -->
          <div v-if="emInfoMessages.length" class="blc">
            <h2 class="portal-h2 cf">
              <img src="../../images/service-h2.svg" alt="Five.Aからのお知らせ" />Five.Aからのお知らせ
              <a href="https://help.ucom.ne.jp/trouble/index.html" target="My Five.A" class="link"
                >広域での障害はこちら<i class="material-icons link link-icon">arrow_right_alt</i></a
              >
            </h2>
            <div class="service-news border-grey">
              <ul>
                <li class="list-none" v-for="(message, index) in emInfoMessages" :key="index">
                  <span class="noticelist">{{ message.message_kind }}</span>
                  <b>{{ message.message_date }} </b>
                  <span v-if="message.message_link">
                    <a :href="message.message_link" target="My Five.A" v-html="message.message_label"></a>
                  </span>
                  <span v-if="!message.message_link" v-html="message.message_label"></span>
                </li>
              </ul>
            </div>
          </div>
          <!-- Five.Aからのお知らせが存在しない場合 -->
          <div v-else class="blc">
            <h2>
              <a href="https://help.ucom.ne.jp/trouble/index.html" target="My Five.A" class="link"
                >広域での障害はこちら<i class="material-icons link link-icon">arrow_right_alt</i></a
              >
            </h2>
          </div>
          <!-- Connectix提供物件 かつ Connectix未契約 の場合は表示する-->
          <div v-if="isAvailableConnectix === '1' && isContractConnectix === '0'" class="blc">
            <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="インターネット通信速度にお困りの方へ" />インターネット通信速度にお困りの方へ</h2>
            <ul class="service-col3 grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
              <li class="border-grey">
                <a href="https://speedtest.tsunagunet.com/f/" target="My Five.A">
                  <img src="../../images/ico01.png" alt="スピードテスト" href="https://speedtest.tsunagunet.com/f/" class="mt20" />
                </a>
                <div class="inner">
                  <p class="ttl">自宅の通信環境をチェック</p>
                  <p>現在のインターネット回線の通信速度を自社グループネットワーク内の速度測定サーバでワンクリックで簡単計測‼　</p>
                  <p class="txt-right">
                    <a href="https://speedtest.tsunagunet.com/f/" target="My Five.A" class="link">スピードテストはこちら<i class="material-icons link-icon">arrow_right_alt</i></a>
                  </p>
                </div>
              </li>
              <li class="border-grey">
                <a href="https://www.tsunagunet.com/internet/kaizen.html" target="My Five.A">
                  <img src="../../images/ico02.png" alt="通信の改善" class="mt20" />
                </a>
                <div class="inner">
                  <p class="ttl">接続速度が遅いと感じた場合</p>
                  <p>
                    モジュラージャックから接続しているLANケーブルやWi-Fiルーターなどの接続機器や、パソコンなど端末に原因がある場合があります。まずはお使いの機器を確認してみましょう！
                  </p>
                  <p class="txt-right">
                    <a href="https://www.tsunagunet.com/internet/kaizen.html" target="My Five.A" class="link"
                      >改善方法はこちら<i class="material-icons link-icon">arrow_right_alt</i></a
                    >
                  </p>
                </div>
              </li>
              <li class="border-grey">
                <a :href="connectixLpUrl" target="My Five.A"><img src="../../images/ico03.png" alt="オプション" class="mt20" /></a>
                <div class="inner">
                  <p class="ttl">オンライン会議や動画などもっと快適なインターネット環境を試したい方は…</p>
                  <p>インターネットオプションサービス「Connectix」お申し込みのお客様専用の優先ルートで快適インターネット</p>
                  <p class="txt-right">
                    <a :href="connectixLpUrl" target="My Five.A" class="link">詳しくはこちら<i class="material-icons link-icon">arrow_right_alt</i></a>
                  </p>
                </div>
              </li>
            </ul>
            <p>※Connectix は、契約建物へのご入居後、インターネットご利用開始後にお申し込みいただけます。</p>
          </div>

          <!-- 各種登録情報の確認・変更 -->
          <div class="blc">
            <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="Five.A 各種登録情報の確認・変更" />Five.A　各種登録情報の確認・変更</h2>
            <div class="border-grey">
              <ul>
                <li v-if="!isIspStatusInCancelApplication">
                  <a v-if="!isDefaulter" :href="`${fiveADomain}/login/login.php?function=SDWAN&apid=${apid}&rd=${rd}`" target="My Five.A" class="link">ご利用状況確認</a>
                  <p v-else class="disabled link">ご利用状況確認</p>
                </li>
                <li>
                  <router-link class="link red" to="/5a/payment-methods/register">クレジットカード情報登録変更</router-link>
                </li>
                <li v-if="!isIspStatusInCancelApplication">
                  <a v-if="!isDefaulter" :href="`${fiveADomain}/login/login.php?function=CHANGE_ALL&apid=${apid}&rd=${rd}`" target="My Five.A" class="link">会員情報変更</a>
                  <p v-else class="disabled link">会員情報変更</p>
                </li>
                <li>
                  <a v-if="!isDefaulter" :href="`${fiveADomain}/login/login.php?function=INVOICE_DETAIL_INFO&apid=${apid}&rd=${rd}`" target="My Five.A" class="link"
                    >ご請求明細・販売証明書表示</a
                  >
                  <p v-else class="disabled link">ご請求明細・販売証明書表示</p>
                </li>
                <li>
                  <a v-if="!isDefaulter" :href="`${fiveADomain}/login/login.php?function=INVOICE_NOTICE&apid=${apid}&rd=${rd}`" target="My Five.A" class="link"
                    >ご請求確定お知らせメール設定</a
                  >
                  <p v-else class="disabled link">ご請求確定お知らせメール設定</p>
                </li>
                <li v-if="!isIspStatusInCancelApplication">
                  <a v-if="!isDefaulter" :href="`${fiveADomain}/login/login.php?function=DISSOLUTION&apid=${apid}&rd=${rd}`" target="My Five.A" class="link">ISP会員契約の解除</a>
                  <p v-else class="disabled link">ISP会員契約の解除</p>
                </li>
              </ul>
            </div>
          </div>

          <!-- お申し込み・解約 -->
          <!-- Connectix提供物件の場合は表示する-->
          <div v-if="isAvailableConnectix === '1' && !isIspStatusInCancelApplication">
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="Connectix　お申し込み・解約" />Connectix　お申し込み・解約</h2>
              <div class="table-scroll js-scrollable">
                <table class="table">
                  <tr>
                    <th colspan="2" class="fivea-table-left">サービス</th>
                    <th class="fivea-table-middle">料金</th>
                    <th class="fivea-table-right"></th>
                  </tr>
                  <tr>
                    <td colspan="2">Connectix</td>
                    <td>
                      <div v-if="connectixUsageFeeCp">
                        <div style="color: red">キャンペーン中</div>
                        <div>{{ connectixUsageFee }} 円/月</div>
                        <div style="color: red">→ {{ connectixUsageFeeCp }} 円/月</div>
                      </div>
                      <div v-else>
                        <div>{{ connectixUsageFee }} 円/月</div>
                      </div>
                    </td>
                    <td>
                      <div class="service-btn grid gap10">
                        <a v-if="isContractConnectix === '0'" @click="navigateToLocation('/connectix/5a/terms')">お申し込み</a>
                        <a v-else-if="isContractConnectix === '1'" :href="`${fiveADomain}/login/login.php?function=SDWAN&apid=${apid}&rd=${rd}`" target="My Five.A"
                          >ご利用状況確認</a
                        >
                      </div>
                    </td>
                  </tr>
                </table>
                <p class="att grey">※価格は全て税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
                <p class="att grey">※本サービスはベストエフォートの為、時間帯や状況などによって異なることがあり、保証されるものではございません。</p>
              </div>
            </div>
          </div>

          <div class="blc">
            <p>Five.Aインターネットサービスについて、サービス内容やご利用方法、お問い合わせ先は、お住まいの建物専用ホームページをご覧ください。</p>
            <p v-if="domainName" class="ttl"><a :href="`${domainName}/${apartmentId8Digit}`" class="link" target="My Five.A">お住まいの建物専用ホームページはこちら</a></p>
          </div>
        </div>
      </div>
    </template>

    <!-- footer -->
    <footer class="emansion-footer">
      <div class="copy">
        <img src="../../images/tunagunet_logo.png" alt="" class="emansion-footer-logo" />
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main h1 {
  padding: 10px 0 10px 160px;
  display: inline;
  background-image: url('../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 140px auto;
}
.disabled {
  color: #bdbdbd;
  text-decoration-color: #bdbdbd;
}
.service-col3 {
  padding: 0;
  margin: 25px 0 0;
}

.service-col3 li {
  list-style: none;
}

.service-col3 li .inner {
  padding: 10px 30px 20px;
}

.service-col3 li img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.service-col3 li .ttl {
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 10px;
}

.service-news {
  background-color: #fcfcfc;
  padding: 0 30px 20px;
  margin: 0;
}

.service-news ul {
  padding: 0;
}

.service-news ul li {
  padding: 15px 0 15px 20px;
  border-bottom: 1px solid #cf1225;
  position: relative;
}

.service-news ul li::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border: 6px solid transparent;
  border-left: 10px solid #cf1225;
}

.noticelist {
  justify-content: center;
  text-align: center;
  display: inline-block;
  min-width: 100px;
  padding: 5px;
  margin: 0 10px 0 0;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  color: #cf1225;
  background: #fdeaec;
  font-weight: 500;
}

.table {
  margin: 20px 0 10px;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #dee2e6;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table th {
  background-color: #fbfbfb;
  text-align: left;
}

.table td .service-btn {
  text-align: right;
  float: right;
  width: 100%;
  font-size: 13px;
}

.table td .service-not-purchased {
  color: #cf1225;
}

.fivea-table-left {
  width: 33%;
}

.fivea-table-middle {
  width: 33%;
}

.fivea-table-right {
  width: 34%;
}

/* emansion-footer */
.emansion-footer {
  padding: 10px 0 10px;
  border-top: 1px solid #e6e6e6;
}

.emansion-footer ul {
  text-align: center;
  padding: 0;
}

.emansion-footer ul li {
  margin: 0 5px;
  display: inline-block;
}

.emansion-footer ul li a {
  color: #292929;
  text-decoration: none;
}

.emansion-footer ul li a img {
  width: 100%;
  height: 150px;
  padding: 10px 0 20px;
}

.emansion-footer ul li a .material-icons {
  position: relative;
  top: 0.2em;
  font-size: 1.2em;
}

.emansion-footer .copy {
  text-align: center;
  font-size: 10px;
  margin-top: 20px;
  color: #707070;
}

.emansion-footer .copy .emansion-footer-logo {
  width: 250px;
  padding: 10px 0 20px;
}

.service-btn {
  a {
    cursor: pointer;
  }
}

@media only screen and (max-width: 767px) {
  .table-scroll table {
    width: 780px;
  }
}

.billing-details-container {
  margin-top: 24px;
  & p.description {
    text-indent: 1em;
  }
}
</style>

<script lang="ts">
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { EmInfoMessage, FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { Property } from '@/shared/classes/spf-api/property';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { E_MANSION_DEFAULTER_STATUS } from '@/shared/const/e-mansion/e-mansion-defaulter-status';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';
import { leaveFontRedAndBrTag } from '@/shared/util/func-leave-font-red-and-br-tag';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { FiveACustomerQueryDto } from '../../shared/classes/external-api/five-a/customer-dto';

type DataType = {
  /**建物ID */
  apartmentId: string | null;
  /**建物ID_8桁 */
  apartmentId8Digit: string | null;
  /**物件 */
  property: Property | null;
  /**物件基本情報 */
  propertyInfo: FiveAProperty | null;
  /**ドメイン名 */
  domainName: string;
  /**Connectixサービス利用料金 */
  connectixUsageFee: string;
  /**Connectixサービス利用料金 */
  connectixUsageFeeCp: string;
  /** お知らせメッセージ*/
  emInfoMessages: Array<EmInfoMessage>;
  /**Connectix契約/未契約 */
  isContractConnectix: string;
  /**ランダムで設定する9桁の数字 */
  rd: string;
  /**接続先サービスURL（現在不使用） */
  connectServiceUrl: string;
  /** ConnectixLPのURL */
  connectixLpUrl: string;
  /** Five.A サービス ドメイン */
  fiveADomain: string;
  /** スピナー表示制御 */
  isMounted: boolean;
  /** connectix対象物件フラグ */
  isAvailableConnectix: string;
  /**滞納状況 */
  isDefaulter: boolean;
  // navigateToLocation() を実行中かどうか
  isExecutingNavigateToLocation: boolean;
  // ScrollHint用
  ScrollHint: any;
  // oemsMaster用
  oemsMaster: Array<GetOemsMasterResponse>;
  // 会員ステータス
  memberStatus: MemberStatus | null;
  // ISP(Five.A)会員ステータス
  ispMemberStatusFiveA: string;
  // ISP(Five.A)会員ステータスが「退会申し込み」かどうか(表示制御に使う)
  isIspStatusInCancelApplication: boolean;
  // ISP(Five.A)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書を表示するかどうか
  isDisplayOnlyBillingDetails: boolean;
  // ISP(Five.A)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書表示URL
  billingDetailsURL: string;
};

/** 「Five.A サービストップ」画面 */
export default defineComponent({
  name: 'five-a-index',
  components: {
    LoadingComponent,
  },
  data(): DataType {
    return {
      apartmentId: null,
      apartmentId8Digit: null,
      property: null,
      propertyInfo: null,
      domainName: '',
      connectixUsageFee: '',
      connectixUsageFeeCp: '',
      emInfoMessages: [],
      isContractConnectix: '',
      rd: '',
      connectServiceUrl: process.env.VUE_APP_FIVE_A_SERVICE_URL,
      connectixLpUrl: process.env.VUE_APP_CONNECTIX_LP_URL,
      fiveADomain: process.env.VUE_APP_FIVE_A_SERVICE_DOMAIN,
      isMounted: false,
      isAvailableConnectix: '',
      isDefaulter: false,
      isExecutingNavigateToLocation: false,
      ScrollHint: require('../../shared/libraries/scroll-hint/scroll-hint'),
      oemsMaster: [],
      memberStatus: null,
      ispMemberStatusFiveA: '',
      isIspStatusInCancelApplication: false,
      billingDetailsURL: process.env.VUE_APP_FIVE_A_BILLING_DETAILS_URL,
      isDisplayOnlyBillingDetails: false,
    };
  },
  async mounted() {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      /** 会員情報を取得 */
      const member: Member = this.$store.getters['memberStore/member'];
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];

      // 顧客情報に物件情報が紐づけられていない場合
      if (!member || !member.propertyId || !member.primaryKeyMye || !this.memberStatus) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // 物件情報を取り出す
      const property: Property = this.$store.getters['propertyStore/property'];

      /** 物件IDを取得 */
      if (property.apartmentId) {
        this.apartmentId = property.apartmentId;
        this.apartmentId8Digit = convert6DigitIdTo8DigitId(property.apartmentId);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // Five.Aのサービス提供物件にのユーザーでない場合「総合トップ」画面にリダイレクトする
      if (property.uaType !== UA_TYPE.FIVE_A) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      //外部API取得用クエリパラメーター
      const params = { propertyId: this.apartmentId, uaType: UA_TYPE.FIVE_A };
      // 物件基本情報を取得
      const propertyInfo = await this.$store.dispatch('fiveACommonStore/property', params);
      if (propertyInfo instanceof FiveAProperty) {
        this.propertyInfo = propertyInfo;
        this.isAvailableConnectix = propertyInfo.op.sdwan.flag;
        if (propertyInfo.em_info_message !== null) {
          this.emInfoMessages = propertyInfo.em_info_message.map((message) => {
            message.message_kind = this.getKindString(message.message_kind);
            message.message_label = leaveFontRedAndBrTag(message.message_label);
            return message;
          });
        }
        // Connectix利用金額
        this.connectixUsageFee = propertyInfo.op.sdwan.fee;
        this.connectixUsageFeeCp = propertyInfo.op.sdwan.fee_cp;
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
      }
      // 契約基本情報を取得
      const customerParams: {
        memberId: string;
        query: FiveACustomerQueryDto;
      } = {
        memberId: member.primaryKeyMye,
        query: { apartment_id: this.apartmentId, ua_type: UA_TYPE.FIVE_A },
      };
      const customer = await this.$store.dispatch('fiveACommonStore/customer', customerParams);
      if (customer instanceof FiveACustomer) {
        this.isContractConnectix = customer.op_connectix_contract_status;
        this.ispMemberStatusFiveA = getIspMemberStatusFiveA(this.memberStatus, customer);
        //滞納中→エラー画面
        if (customer.defaulter_status == E_MANSION_DEFAULTER_STATUS.DEFAULTER) {
          this.$store.commit('errorStore/messages', '大変申し訳ございません。現在Five.Aメニューをご利用いただけません。');
          await this.$router.push('/5a/error').catch((error: any) => {
            checkRouterError(error);
          });
        }
        this.isDefaulter = customer.defaulter_status == E_MANSION_DEFAULTER_STATUS.DEFAULTER_CREDIT_CARD_REGISTERED;

        this.isIspStatusInCancelApplication = this.getFlagIspStatusInCancelApplication();
        this.isDisplayOnlyBillingDetails = this.getFlagDisplayOnlyBillingDetails();
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONTRACTOR_INFO);
      }

      //ドメイン名の取得 */
      if (property.oemType) {
        this.oemsMaster = await this.$store.dispatch('oemsStore/oems');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filtered = this.oemsMaster.filter((oem: any) => oem.oemType === String(property.oemType));
        if (filtered.length >= 1) {
          let latestOem = filtered[0];
          if (filtered.length >= 2) {
            for (let i = 1; i < filtered.length; i++) {
              if (latestOem.startAt < filtered[i].startAt) {
                latestOem = filtered[i];
              }
            }
          }
          this.domainName = latestOem.domainForOsumai;
        }
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
      }
    } catch (error) {
      throw error;
    }

    // 9桁のランダムな数字を取得
    this.rd = this.createRd();
    this.$nextTick(() => {
      this.jsScrollAble();
    });
    this.isMounted = true;
  },
  methods: {
    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    getKindString(kind: string): string {
      let kindStr = '';
      switch (kind) {
        case '01':
          kindStr = 'お知らせ';
          break;
        case '02':
          kindStr = 'キャンペーン';
          break;
        case '03':
          kindStr = 'メンテナンス';
          break;
        case '04':
          kindStr = '障害';
          break;
        case '05':
          kindStr = '障害「マンション全体」';
          break;
        case '06':
          kindStr = '障害「マンション一部」';
          break;
        default:
          kindStr = 'お知らせ';
          break;
      }
      return kindStr;
    },
    navigateToLocation(path: string) {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      this.$router
        .push(path)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isExecutingNavigateToLocation = false;
        });
    },
    jsScrollAble() {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },
    /**
     * ISP(Five.A)会員ステータスが「退会申し込み」かどうか(表示制御に使う)
     *
     * | isIspStatusInCancelApplication | isp-member-status   | portas-member-status |
     * | true                           | inCancelApplication | 5                    |
     * | true                           | inCancelApplication | 7                    |
     */
    getFlagIspStatusInCancelApplication() {
      const isExpectedPortasMemberStatus = [MEMBER_STATUS.PROPERTY_LINKED, MEMBER_STATUS.IN_CANCEL_APPLICATION].some((value) => value === this.memberStatus?.status);

      if (isExpectedPortasMemberStatus && this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION) {
        return true;
      }
      return false;
    },
    /**
     * ISP(Five.A)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書を表示するかどうか
     *
     * | isDisplayOnlyBillingDetails | isp-member-status | portas-member-status |
     * | true                        | cancelAndLoginOK  | 5                    |
     * | true                        | cancelAndLoginOK  | 7                    |
     */
    getFlagDisplayOnlyBillingDetails() {
      const isExpectedPortasMemberStatus = [MEMBER_STATUS.PROPERTY_LINKED, MEMBER_STATUS.IN_CANCEL_APPLICATION].some((value) => value === this.memberStatus?.status);

      if (isExpectedPortasMemberStatus && this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },
  },
  computed: {
    apid() {
      if (this.$data.propertyInfo) {
        return this.$data.propertyInfo.IN_APID;
      } else {
        return '';
      }
    },
  },
});
</script>
