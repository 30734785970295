<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メールサービス購入 確認</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><a href="/">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>メールサービス購入</li>
      </ul>

      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:メールサービス購入 確認 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="メールサービス購入" />メールサービス購入 確認</h2>
        <p>お申し込み内容をご確認いただき、問題ない場合は「申し込む」ボタンを押してください。</p>
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th class="va-middle">商品名</th>
              <td>{{ mailPackInfo.productName }}</td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み内容</th>
              <td><b>購入する</b></td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>
                <span class="o">{{ Number(mailPackInfo.monthlyFee).toLocaleString() }} 円 （税込）</span>
              </td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>{{ formattedProcessDate }}</td>
            </tr>

            <tr>
              <th class="va-middle">サービス説明</th>
              <td v-html="mailPackInfo.serviceDescription"></td>
            </tr>
          </table>
        </div>
      </div>

      <h3 v-if="is3dsecure" class="service-h3">UCOM光 レジデンスサービス お支払い方法</h3>
      <div>
        <div v-if="is3dsecure" class="pd10 ml10">
          <p>UCOM光 レジデンスにご登録されている以下のお支払い方法により、お支払いいただきます。</p>
          <h3>クレジットカード</h3>
          <table class="table-type2">
            <tbody>
              <tr>
                <th class="va-middle">カード番号</th>
                <td>{{ maskedCardNumberFromIsp }}</td>
              </tr>
              <tr>
                <th class="va-middle">カード期限</th>
                <td>{{ cardExpiredMonthOnPortas }}月 ／ {{ cardExpiredYearOnPortas }}年</td>
              </tr>
              <tr v-if="!isIspCardHolderName">
                <td>
                  <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
                </td>
                <td>
                  <div class="mt10">
                    <input type="text" class="text card-name" v-model.trim="cardholderName" required minlength="2" maxlength="45" @change="inputCardHolderNameAtCopy" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="blc">
        <p v-if="is3dsecure" class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
        </p>
        <div v-if="is3dsecure && !hasError" class="my-button-area">
          <div class="has-checkbox">
            <div class="my-checkbox-outer">
              <input id="isAgreedPrivacyPolicy" type="checkbox" v-model="isAgreedPrivacyPolicy" />
              <p class="my-checkbox-text"><label for="isAgreedPrivacyPolicy">各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label></p>
            </div>
          </div>
        </div>
        <!-- /blc -->

        <!-- blc -->
        <div class="blc">
          <p class="form-btn-txt" v-if="!hasError">「申し込む」を押してください。完了画面へ進みます。</p>
          <div class="btn-area">
            <button class="btn btn04 bs" @click="goBack()" v-if="!hasError"><i class="material-icons link link-icon">west</i>戻る</button>
            <button class="btn btn01 bs" @click="goNext()" v-if="!hasError && !is3dsecure">申し込む<i class="material-icons link link-icon">east</i></button>
            <button
              type="button"
              class="btn btn01 bs"
              v-if="!hasError && is3dsecure"
              :class="[isDisabledButton ? 'btn05' : 'btn01']"
              v-on:click="goNext()"
              :disabled="isDisabledButton"
            >
              申し込む<i class="material-icons link link-icon">east</i>
            </button>
            <button class="btn btn01 bs" @click="goTop()" v-if="hasError">UCOM光 レジデンス会員専用トップ<i class="material-icons link link-icon">east</i></button>
          </div>
        </div>
        <!-- /blc -->
      </div>
      <!-- /contents -->
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { UcomErrorResponse } from '../classes/external-api/error-response';
import { UcomPaymentStatusResponse } from '../classes/external-api/payment-status-response';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiMailPackAccessor } from '@/infra/accessor/spf/ucom/spf-api-mail-pack-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { FORMAT_DATE } from '@/shared/const/format';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UCOM_PAYMENT_METHOD_ID } from '@/shared/const/ucom/ucom-payment-method-id';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomExternalApiService } from '@/shared/services/external-api/ucom-external-api-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { inputEventConvertToUpper } from '@/shared/util/inputEventConvertToUpper';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { Property } from '@/shared/classes/spf-api/property';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';

export default defineComponent({
  name: 'confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    mailPackInfo: {
      productName: '',
      monthlyFee: '',
      serviceDescription: '',
    },
    check: false,
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    hasError: false,
    processDate: null as Date | null,
    formattedProcessDate: '',
    isActiveButton: undefined,
    /** クレジットカードの判定 */
    is3dsecure: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],

    /** ISPにカード名義人名が登録されているか */
    isIspCardHolderName: false,
    /** カード名義人名 */
    cardholderName: '',
    /** 連携先ISPで登録されたクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** Portasで登録されたクレジットカードの有効期限(月) */
    cardExpiredMonthOnPortas: '',
    /** Portasで登録されたクレジットカードの有効期限(年) */
    cardExpiredYearOnPortas: '',
    /** カード会員ID */
    paymentCardAccountId: '',
    isAgreedPrivacyPolicy: false,
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    apid: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 物件基本情報の物件IDを取得してapidへ変換
    const property: Property | null = this.$store.getters['propertyStore/property'];
    if (property?.apartmentId) {
      this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
    }

    // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
    if (this.$store.getters['errorMessageStore/errorMessages'] && this.$store.getters['errorMessageStore/errorMessages'].length > 0) {
      this.hasError = true;
      this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
      this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
      //ストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);
    }

    try {
      // 3dセキュアの対象となるかの判定
      await this.checkPaymentInfo();

      //不正な遷移
      this.isActiveButton = this.$store.getters['ucomMailPackStore/isActiveButton'];
      if (!this.isActiveButton) {
        await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      //リロードでデータが消えたとき
      this.mailPackInfo.productName = this.$store.getters['ucomMailPackStore/productName'];
      if (!this.mailPackInfo.productName) {
        await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 戻るボタン押下時にstoreのproductNameに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
      this.$store.commit('ucomMailPackStore/productName', undefined);

      this.mailPackInfo.monthlyFee = this.$store.getters['ucomMailPackStore/monthlyFee'];
      if (!this.mailPackInfo.monthlyFee) {
        await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.processDate = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
      if (!this.processDate) {
        await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.formattedProcessDate = this.formatDate(this.processDate);
      this.mailPackInfo.serviceDescription = this.$store.getters['ucomMailPackStore/serviceDescription'];

      if (!this.mailPackInfo.serviceDescription) {
        await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      if (this.errorMessages.length > 0) {
        this.hasError = true;
      }

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    //戻るボタン
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$store.commit('ucomMailPackStore/productName', this.mailPackInfo.productName);
      await this.$router.push('/ucom/mail-pack/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    //申し込むボタン
    async goNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.errorMessages = [];
      const memberId = this.$store.getters['memberStore/member'].id;
      const ucomAccountId = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.ucomAccountId;

      if (this.is3dsecure) {
        const member: Member = this.$store.getters['memberStore/member'];
        if (!this.isIspCardHolderName && (!this.cardholderName || this.cardholderName?.length < 2 || this.cardholderName?.length > 45)) {
          this.errorMessages.push('カード名義人を正しく入力してください。');
          this.isLoading = false;
          this.isAgreedPrivacyPolicy = false;
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }

        // 申込内容一時保存用 UUIDを生成
        const uuidForTemporarySavingApplicationData = uuidv4();
        const paymentMethodApplicationData = {
          memberId: memberId,
          ucomAccountId: ucomAccountId,
          processDate: this.processDate,
          subscriptionOn: dayjs(this.processDate!).format(FORMAT_DATE.YYYYMMDD_WITH_SLASH),
          monthlyFee: this.mailPackInfo.monthlyFee,
          productName: this.mailPackInfo.productName,
          serviceDescription: this.mailPackInfo.serviceDescription,
          isActiveButton: this.isActiveButton,
        };

        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'ucom-mail-service-apply',
        });

        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
        }

        // 3dセキュア対応用 uuid localStorageに保存
        localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

        if (member.primaryKeyUcom) {
          if (uuidForTemporarySavingApplicationData !== null && this.paymentCardAccountId) {
            // VeriTrans_本人認証用のデータ格納
            const dddSecureAuthStartWithVeriTransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
              uuid: uuidForTemporarySavingApplicationData,
              externalVeritransAccountId: this.paymentCardAccountId,
              cardholderName: this.cardholderName || undefined,
            });
            // VeriTrans_本人認証(VeriTrans会員ID使用) API
            try {
              const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeriTransAccountIdRequest);
              location.href = authStartURL;
            } catch (error: any) {
              if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                this.errorMessages.push(
                  `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.ucomUrl}/${this.apid}/" target="_blank">こちら</a>から、サポートセンターまでお問い合わせください。`
                );
                this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
                this.isLoading = false;
                this.isAgreedPrivacyPolicy = false;
                this.hasError = true;

                // エラーメッセージを見せるために画面最上部にスクロール
                window.scrollTo(0, 0);
                return;
              } else {
                throw error;
              }
            }
          }
        }
      } else {
        try {
          //AU08-003により購入情報を更新
          const updateUcomMailPack = await SpfApiMailPackAccessor.updateUcomMailPackInfo(
            memberId,
            ucomAccountId,
            dayjs(this.processDate!).format(FORMAT_DATE.YYYYMMDD_WITH_SLASH),
            this.mailPackInfo.monthlyFee,
            this.mailPackInfo.productName,
            this.mailPackInfo.serviceDescription
          );

          if (updateUcomMailPack.message) {
            this.hasError = true;
            this.errorMessages.push(updateUcomMailPack.message);
            window.scrollTo(0, 0);
            this.isLoading = false;
            return;
          }
          this.$store.commit('ucomMailPackStore/contactMailAddress', updateUcomMailPack.mailAddress);
          this.$store.commit('ucomMailPackStore/productName', this.mailPackInfo.productName);
          this.$store.commit('ucomMailPackStore/monthlyFee', this.mailPackInfo.monthlyFee);
          this.$store.commit('ucomMailPackStore/processDate', this.processDate);
          this.$store.commit('ucomMailPackStore/serviceDescription', this.mailPackInfo.serviceDescription);
          await this.$router.push('/ucom/mail-pack/complete');
          this.isLoading = false;
        } catch (error) {
          await this.$router.push('/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      }
    },
    //Topへボタン
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      const member: Member = this.$store.getters['memberStore/member'];
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

      if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },

    async checkPaymentInfo() {
      const member: Member = this.$store.getters['memberStore/member'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);

      // 契約基本情報取得APIよりクレカの登録があった場合
      if (customer.is_register_credit_card && member.primaryKeyUcom) {
        // (外部 UCOM) お支払い情報登録状態取得 API
        const paymentStatus: UcomPaymentStatusResponse | UcomErrorResponse = await UcomExternalApiService.getPaymentStatus(member.primaryKeyUcom);
        // お支払方法がクレカの場合
        if (paymentStatus instanceof UcomPaymentStatusResponse && paymentStatus.payment_method_id === UCOM_PAYMENT_METHOD_ID.CREDIT_CARD) {
          this.paymentCardAccountId = paymentStatus.card_account_id;
          this.is3dsecure = true;
          if (paymentStatus.card_account_id) {
            const cardInfo = await this.getPaymentMethodFromExternalIsp(paymentStatus.card_account_id);
            this.maskedCardNumberFromIsp = this.addHyphenToCardNumber(cardInfo.cardNumber);
            // クレジットカードの年月取得
            this.cardExpiredMonthOnPortas = cardInfo.cardExpire.slice(0, 2);
            this.cardExpiredYearOnPortas = cardInfo.cardExpire.slice(-2);
            // カード名義人判定
            if (cardInfo.cardholderName) {
              this.isIspCardHolderName = true;
            }
          }
        }
      }
    },

    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },

    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },

    // カード名義人が入力されて申込が押下された時発動
    inputCardHolderNameAtCopy(event: Event) {
      this.cardholderName = inputEventConvertToUpper(event);
    },
  },

  computed: {
    // チェックボックスによる「申し込む」ボタンの判定
    isDisabledButton(): boolean {
      if (this.isAgreedPrivacyPolicy) {
        return false;
      }
      return true;
    },
  },
  watch: {
    cardholderName: {
      handler: function (newVal, oldVal) {
        if (newVal && !newVal.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.cardholderName = oldVal;
        }
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

.credit-card-text {
  width: 100%;
  height: 38px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 10px 0 10px;
  border: #cacaca solid 1px;
  margin-top: 0;
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  font-family: 'notosansjp';
  border-radius: 2px;
  max-width: 360px;
}

div.my-button-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  text-align: center;
  padding-top: 35px;

  & div.has-checkbox {
    padding-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 10px;
      left: 0;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  div.my-button-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    margin-top: 16px;

    & div.has-checkbox {
      padding-top: 0;

      & div.my-checkbox-outer {
        top: 0;
        left: 50%;
        text-align: left;

        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1275px) {
  div.my-button-area {
    padding-top: 16px;
  }
}

.card-name {
  width: 100%;
  max-width: 360px;
}
</style>
