/**
 * e-mansion 共通利用 API : 契約基本情報取得 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class EMansionCustomerResponse {
  public result!: EMansionCustomer;

  constructor(partial: Partial<EMansionCustomerResponse>) {
    Object.assign(this, partial);
  }
}
/**
 * e-mansion 共通利用 API : 契約基本情報取得 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class EMansionCustomer {
  /** 部屋番号 */
  public readonly room_number!: string;
  /** 氏名 */
  public readonly name!: string;
  /** カナ */
  public readonly kana!: string;
  /** 性別 0：不明 1：男性 2：女性 */
  public readonly sex!: string;
  /** 生年月日 yyyy/mm/dd形式 */
  public readonly birthday!: string;
  /** 電話番号 */
  public readonly phone_number!: string;
  /** 入居予定日 yyyy/mm/dd形式 */
  public readonly scheduled_move_in_on!: string;
  /** 物件名称 */
  public readonly property_name!: string;
  /** 棟名称 */
  public readonly building_name!: string;
  /** 郵便番号 */
  public readonly postal_code!: string;
  /**住所1 */
  public readonly address1!: string;
  /** 物件ID */
  public readonly property_id!: string;
  /** e-mansionログイン後TOPの「メール認証キー設定」表示有無 0:未申請,1:申請済 */
  public readonly email_authentication_key_setting_display!: string;
  /** ウィルスチェックあんしんサービスplusの契約状態 */
  public readonly op_virus_check_service_contract_status!: string;
  /** IPフォンの契約状態 */
  public readonly op_ipp_contract_status!: string;
  /** Norton360の契約状態 */
  public readonly op_360_contract_status!: string;
  /** マルチデバイスN360の契約状態 */
  public readonly op_md360_contract_status!: string;
  /** Internet Sagi Wallの契約状態 */
  public readonly op_isw_contract_status!: string;
  /** 有料メールの契約状態 */
  public readonly op_mailplus_contract_status!: string;
  /** Norton Internet Securityの契約状態 */
  public readonly op_nis_contract_status!: string;
  /** i-フィルターの契約状態 */
  public readonly op_ift_contract_status!: string;
  /** ホームページの契約状態 */
  public readonly op_hp_contract_status!: string;
  /** GIPの契約状態 */
  public readonly op_gip_contract_status!: string;
  /** Connectixの契約状態 */
  public readonly op_connectix_contract_status!: string;
  /** UCOM光電話の契約状態 */
  public readonly op_hphone_contract_status!: string;
  /** 支払い方法 */
  public readonly payment_id!: string;
  /** ログインアカウント情報 */
  public readonly em_login!: string;
  /** 契約書面フラグ */
  public readonly contract_document_flag!: string;
  /** 滞納状況 */
  public readonly defaulter_status!: string;
  /** 通知先メールアドレス種別 */
  public readonly notify_mail_type!: string;
  /** 通知先メールアドレス */
  public readonly notify_mailaddress!: string;
  /** 退会日 */
  public readonly quit_date!: string;
  /** メール会員フラグ */
  public readonly email_member_flag!: string;
  /** ISPで管理している会員のステータス */
  public readonly status!: string;
  /** 接続コース情報 */
  public readonly cs!: Course;
  /**オプション */
  public readonly op!: Option;

  constructor(partial: Partial<EMansionCustomer>) {
    Object.assign(this, partial);
  }
}

/** 接続コース情報 */
export class Course {
  /**現在の接続コースID */
  public readonly course_id!: string;
  /**開通予定の接続コースID */
  public readonly next_course_id?: string;
  /**コース変更フラグ */
  public readonly changeable_flag!: string;
  /** グレード */
  public readonly grade!: string;
  /** 接続速度 */
  public readonly speed!: string;
  /** 接続方式 */
  public readonly type!: string;
  /** 接続料金 */
  public readonly charge!: string;

  constructor(partial: Partial<Course>) {
    Object.assign(this, partial);
  }
}

/** オプション */
export class Option {
  /**Connectix */
  public readonly connectix!: Connectix[];
  /**UCOM光電話 */
  public readonly hikariphone!: Hikariphone[];
  /**IPフォン */
  public readonly ipphone!: Ipphone[];
  /** i-フィルター */
  public readonly ifilter!: Ifilter[];
  /** GIP */
  public readonly globalip!: Gip[];

  constructor(partial: Partial<Option>) {
    Object.assign(this, partial);
  }
}

/** Connectix */
export class Connectix {
  /**利用状況 */
  public readonly status!: string;
  /**申込日 */
  public readonly request_time!: string;
  /**利用開始日 */
  public readonly start_time?: string;
  /**解約申込日 */
  public readonly cancel_time?: string;
  /**解約日 */
  public readonly end_time?: string;

  constructor(partial: Partial<Connectix>) {
    Object.assign(this, partial);
  }
}

/** UCOM光電話 */
export class Hikariphone {
  /**利用状況 */
  public readonly status!: string;
  /**申込日 */
  public readonly request_time!: string;
  /**利用開始日 */
  public readonly start_time?: string;
  /**解約申込日 */
  public readonly cancel_time?: string;
  /**解約日 */
  public readonly end_time?: string;
  /**ログインID */
  public readonly web_login_id!: string;
  /**パスワード */
  public readonly web_login_password!: string;
  /**パスワード（復号化＆難読化された値） */
  public readonly web_login_password_decrypted?: string;
  /**番号通知 */
  public readonly notification_flag!: number;
  /**付加サービス */
  public readonly hikariphone_option!: HikariphoneOption[];

  constructor(partial: Partial<Hikariphone>) {
    Object.assign(this, partial);
  }
}

/** UCOM光電話 付加サービス */
export class HikariphoneOption {
  /**付加サービス名 */
  public readonly name!: string;
  /**利用状況 */
  public readonly status!: string;
  /**申込日 */
  public readonly request_time!: string;
  /**利用開始日 */
  public readonly start_time?: string;
  /**解約申込日 */
  public readonly cancel_time?: string;
  /**解約日 */
  public readonly end_time?: string;

  constructor(partial: Partial<HikariphoneOption>) {
    Object.assign(this, partial);
  }
}

/** IPフォン */
export class Ipphone {
  /**利用状況 */
  public readonly status!: string;
  /**申込日 */
  public readonly request_time!: string;
  /**利用開始日 */
  public readonly start_time?: string;
  /**解約申込日 */
  public readonly cancel_time?: string;
  /**解約日 */
  public readonly end_time?: string;
  /**着荷指定日 */
  public readonly delivery_time!: string;
  /**指定時間
   * 0:午前,2:14～16時,3:16～18時,4:18～20時,5:19～21時
   */
  public readonly delivery_time_zone!: number;

  constructor(partial: Partial<Ipphone>) {
    Object.assign(this, partial);
  }
}

/** i-フィルター */
export class Ifilter {
  /**利用状況 */
  public readonly status!: string;
  /**申込日 */
  public readonly request_time!: string;
  /**利用開始日 */
  public readonly start_time?: string;
  /**解約申込日 */
  public readonly cancel_time?: string;
  /**解約日 */
  public readonly end_time?: string;
  /**契約番号 */
  public readonly subscription_id?: string;
  /**シリアルID */
  public readonly ticket?: string;
  /**ダウンロードURL */
  public readonly download_url?: string;
  /**インストール先コンピューター名 */
  public readonly service_memo!: string[];

  constructor(partial: Partial<Ifilter>) {
    Object.assign(this, partial);
  }
}

/** 固定GIP */
export class Gip {
  /**利用状況 */
  public readonly status!: string;
  /**申込日 */
  public readonly request_time!: string;
  /**利用開始日 */
  public readonly start_time?: string;
  /**解約申込日 */
  public readonly cancel_time?: string;
  /**解約日 */
  public readonly end_time?: string;
  /**MACアドレス */
  public readonly macaddress?: string | null;

  constructor(partial: Partial<Gip>) {
    Object.assign(this, partial);
  }
}

/**
 * e-mansion 共通利用 API : 新規会員登録 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class EMansionCustomerCreateResponseResult {
  /**結果値 */
  public result!: EMansionCustomerCreateResponse;

  constructor(partial: Partial<EMansionCustomerCreateResponse>) {
    Object.assign(this, partial);
  }
}

/**
 * e-mansion 共通利用 API : 新規会員登録 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class EMansionCustomerCreateResponse {
  /**処理結果の成否 */
  public readonly payment_flag!: boolean;
  /**物件名称 */
  public readonly apartment_name!: string;
  /**棟名表示フラグ */
  public readonly block_show_flag!: boolean;
  /**棟名 */
  public readonly block_name!: string;
  /**部屋番号 */
  public readonly roomno!: string;
  /**氏名(姓) */
  public readonly name_surname!: string;
  /**氏名(名) */
  public readonly name_first_name!: string;
  /**カナ氏名(姓) */
  public readonly kana_surname!: string;
  /**カナ氏名(名) */
  public readonly kana_first_name!: string;
  /**性別 */
  public readonly distinction_of_sex!: string;
  /**生年月日 */
  public readonly birthday!: string;
  /**電話番号 */
  public readonly telephone!: string;
  /**連絡先電話番号 */
  public readonly contact_telephone!: string;
  /**FAX */
  public readonly fax!: string;
  /**連絡先メールアドレス */
  public readonly contact_mailaddress!: string;
  /**入居予定日 */
  public readonly move_schedule_date!: string;
  /**ログインID(ID部分のみ) */
  public readonly login_id!: string;
  /**サブドメイン */
  public readonly subdomain!: string;
  /**メールアドレス */
  public readonly mailaddress!: string;
  /**パスワード */
  public readonly password!: string;
  /**e-mansion会員番号 */
  public readonly e_tnc_member_id!: string;
  /**書類送付先 種別 */
  public readonly destination_kind!: string;
  /**郵便番号 */
  public readonly destination_zipcode!: string;
  /**住所1 */
  public readonly destination_address1!: string;
  /**住所2 */
  public readonly destination_address2!: string;
  /**電話番号 */
  public readonly destination_telephone!: string;
  /**紹介者表示フラグ */
  public readonly introducer_show_flag!: boolean;
  /**氏名(姓) */
  public readonly introducer_surname!: string;
  /**氏名(名) */
  public readonly introducer_first_name!: string;
  /**物件名 */
  public readonly introducer_apartment_name!: string;
  /**棟名 */
  public readonly introducer_block_name!: string;
  /**部屋番号 */
  public readonly introducer_roomno!: string;
  /**メディアコンバータ・モデムの料金表示フラグ*/
  public readonly equipmentcontract_amount_show_flag!: boolean;
  /**総額表示フラグ */
  public readonly amount_flag!: boolean;
  /**初回金額 */
  public readonly amount!: string;
  /**次回以降金額 */
  public readonly amount_next!: string;
  /**総額でのキャンペーン有無フラグ */
  public readonly campaign_zero_flag!: boolean;
  /**コース情報 */
  public readonly course_info!: {
    bandspeed_name: string;
    access_network_name: string;
    price: string;
    price_zero_flag: boolean;
    campaign_flag: boolean;
    campaign_price: string;
    payment_flag: boolean;
    bandspeed_message_1G_flag: boolean;
  };
  /**接続機器情報表示フラグ */
  public readonly equipmentcontract_show_flag!: boolean;
  /**VDSLフラグ(モデム) */
  public readonly vdsl_flag!: boolean;
  /**FTTHフラグ(メディアコンバーター) */
  public readonly ftth_flag!: boolean;
  /**接続機器情報 */
  public readonly equipmentcontract_info!: {
    price: string;
    campaign_flag: boolean;
    campaign_price: string;
  };
  /**事務手数料表示フラグ */
  public readonly nyukaikin_show_flag!: boolean;
  /**事務手数料 */
  public readonly nyukai_info!: {
    price: string;
    campaign_flag: boolean;
    campaign_price: string;
  };
  /**工事費用表示フラグ */
  public readonly koujihi_show_flag!: boolean;
  /**工事費用情報 */
  public readonly koujihi_info!: {
    price: string;
    campaign_flag: boolean;
    campaign_price: string;
  };
  /**オプションお申し込み有無 */
  public readonly show_option_path_flag!: boolean;
  /**ウィルスチェックサービスの表示有無 */
  public readonly show_viruscheck_flag!: boolean;
  /**ウィルスチェックサービスお申し込み有無 */
  public readonly viruscheck_flag!: string;
  /**ウィルスチェックサービス情報 */
  public readonly viruscheck_info!: {
    price: string;
    campaign_flag: boolean;
    campaign_price: string;
  };
  /**マルチデバイスN360サービスの表示有無 */
  public readonly show_multidevice_n360_flag!: string;
  /**マルチデバイスN360サービスお申し込み有無 */
  public readonly multidevice_n360_flag!: boolean;
  /**マルチデバイスN360サービス情報 */
  public readonly multidevice_n360_info!: {
    price: string;
    campaign_flag: boolean;
    campaign_price: string;
  };
  /**i-Filterサービスの表示有無 */
  public readonly show_ifilter_flag!: boolean;
  /**i-Filterサービスお申し込み表示有無 */
  public readonly ifilter_flag!: string;
  /**i-Filterサービス情報 */
  public readonly ifilter_info!: {
    price: string;
    campaign_flag: boolean;
    campaign_price: string;
    ifilter_download_full_url: string;
    ifilter_serial_id: string;
  };
  /**IPフォンお申し込み有無 */
  public readonly ipphone_flag!: string;
  /**TAお届け日(年) */
  public readonly delivery_year!: string;
  /**TAお届け日(月) */
  public readonly delivery_month!: string;
  /**TAお届け日(日) */
  public readonly delivery_day!: string;
  /**TAお届け時間帯 */
  public readonly delivery_time_zone!: string;
  /**TAお届け時間帯名称 */
  public readonly delivery_time_zone_name!: string;
  /**IPフォンサービス情報 */
  public readonly ipphone_info!: {
    ipphone_disp_flag: boolean;
    ipphone_fee_price: string;
    ipphone_fee_campaign_flag: boolean;
    ipphone_fee_campaign_price: string;
    ipphone_monthly_price: string;
    ipphone_monthly_campaign_flag: boolean;
    ipphone_monthly_campaign_price: string;
    ipphone_tarental_price: string;
    ipphone_tarental_campaign_flag: boolean;
    ipphone_tarental_campaign_price: string;
    ipphone_universalservice_campaign_flag: boolean;
    ipphone_universalservice_campaign_price: string;
  };
  /**接続サービス0円でIPフォンお申し込みフラグ(契約書面サブタイトル出し分け) */
  public readonly price_zero_ipphone_flag!: boolean;
  /**UCOM光電話お申し込み有無*/
  public readonly hikariphone_flag!: string;
  /**UCOM光電話サービス情報 */
  public readonly hikariphone_info!: {
    hikariphone_disp_flag: boolean;
    hikariphone_fee_price: string;
    hikariphone_fee_campaign_flag: boolean;
    hikariphone_fee_campaign_price: string;
    hikariphone_monthly_price: string;
    hikariphone_monthly_campaign_flag: boolean;
    hikariphone_monthly_campaign_price: string;
    hikariphone_adapter_price: string;
    hikariphone_adapter_campaign_flag: boolean;
    hikariphone_adapter_campaign_price: string;
    hikariphone_universalservice_price: string;
    hikariphone_universalservice_campaign_flag: boolean;
    hikariphone_universalservice_campaign_price: string;
    hikariphone_number_portability_price: string;
    hikariphone_number_portability_campaign_flag: boolean;
    hikariphone_number_portability_campaign_price: string;
    number_portability_flag: string;
    notification_flag: string;
    option_price_1: string;
    option_campaign_flag_1: boolean;
    option_campaign_price_1: string;
    option_flag_1: string;
    option_price_2: string;
    option_campaign_flag_2: boolean;
    option_campaign_price_2: string;
    option_flag_2: string;
    option_price_3: string;
    option_campaign_flag_3: boolean;
    option_campaign_price_3: string;
    option_flag_3: string;
    option_price_4: string;
    option_campaign_flag_4: boolean;
    option_campaign_price_4: string;
    option_flag_4: string;
  };
  /**契約書面エリア表示フラグ */
  public readonly contract_doc_show_flag!: boolean;
  /**サービス開始日 */
  public readonly disp_service_start_date!: string;
  /**請求開始日 */
  public readonly disp_charge_start_date!: string;
  /**鍵の引き渡し日のご案内文1表示フラグ */
  public readonly show_internet_start_tpl_01!: boolean;
  /**鍵の引き渡し日のご案内文2表示フラグ */
  public readonly show_internet_start_tpl_02!: boolean;
  /**鍵の引き渡し日のご案内文3表示フラグ */
  public readonly show_internet_start_tpl_03!: boolean;
  /**鍵の引き渡し日のご案内文4表示フラグ */
  public readonly show_internet_start_tpl_04!: boolean;
  /**鍵の引き渡し日のご案内文5表示フラグ */
  public readonly show_internet_start_tpl_05!: boolean;
  /**お知らせメッセージ */
  public readonly message_info_ary!: {
    title: string;
    message: string;
  };
  /**サービスイン前フラグ */
  public readonly before_service_in_flag!: boolean;
  /**レスポンス項目No.149の"show_internet_start_tpl_05がtrueの時に表示する鍵の引き渡し日(サービスイン日)"*/
  public readonly switchapartment_indicator!: string;
  /**機器損害金メッセージ表示フラグ */
  public readonly damagecharge_flag!: string;
  /**契約書面情報 */
  public readonly contract!: {
    mail_flag: boolean;
    contract_destination_zipcode: string;
    contract_destination_address1: string;
    contract_destination_address2: string;
    contract_destination_mailaddress: string;
  };
  /**メンバーID */
  public readonly member_id!: string;

  constructor(partial: Partial<EMansionCustomerCreateResponse>) {
    Object.assign(this, partial);
  }
}

/**e-mansion 共通利用 API : 会員情報変更 : レスポンス */
export class EMansionCustomerUpdateResponse {
  public result!: {
    processing_result: boolean;
  };

  constructor(partial: Partial<EMansionCustomerUpdateResponse>) {
    Object.assign(this, partial);
  }
}

/**e-mansion 共通利用 API : メール認証キーチェック : レスポンス */
export class EMansionEmailAuthenticationKeyResponse {
  public result!: {
    processing_result: boolean;
  };

  constructor(partial: Partial<EMansionEmailAuthenticationKeyResponse>) {
    Object.assign(this, partial);
  }
}

/**e-mansion 共通利用 API : ご希望アカウントバリデーションチェック : レスポンス */
export class FindAccountValidationByIdResponseResult {
  public result!: FindAccountValidationByIdResponse;

  constructor(partial: Partial<FindAccountValidationByIdResponse>) {
    Object.assign(this, partial);
  }
}

/**e-mansion 共通利用 API : ご希望アカウントバリデーションチェック : レスポンス本体 */
export class FindAccountValidationByIdResponse {
  public desired_account_validation_success!: string;

  constructor(partial: Partial<FindAccountValidationByIdResponse>) {
    Object.assign(this, partial);
  }
}
