import { UaTypeValue } from '@/shared/const/service-type';

/** 同時お申し込み（EMansion）API：リクエスト */
export class LinkServiceWithEMansionRequest {
  public uaType!: UaTypeValue;
  public propertyId!: string;
  public buildingId!: string;
  public roomNumber!: string;
  public nameSurname!: string;
  public nameFirstName!: string;
  public kanaSurname!: string;
  public kanaFirstName!: string;
  public birthday!: string;
  public phoneNumber!: string;
  public emailAddress!: string;
  public scheduledMoveInOn?: string;
  public creditCardRegistFlag?: string;
  public eTncMemberId?: string;
  public courseId!: string;
  public destinationKind!: string;
  public destinationZipcode!: string;
  public destinationAddress1!: string;
  public destinationAddress2?: string;
  public contractDestinationKind?: string;
  public contractDestinationMailaddress?: string;
  public contractDestinationZipcode?: string;
  public contractDestinationAddress1?: string;
  public contractDestinationAddress2?: string;
  public ipphoneFlag?: string;
  public ipphone?: IpPhone;
  public ifilterFlag?: string;

  constructor(partial: Partial<LinkServiceWithEMansionRequest>) {
    Object.assign(this, partial);
  }
}
export class IpPhone {
  public deliveryTime?: string;
  public deliveryTimeZone?: string;
  constructor(partial: Partial<IpPhone>) {
    Object.assign(this, partial);
  }
}
/** 同時お申し込み（EMansion）API：レスポンス */
export class LinkServiceWithEMansionResponse {
  readonly status!: string;
  readonly ua_type?: string;
  readonly aprtment_id?: string;
  readonly result?: Result;
  readonly code?: string;
  readonly message?: string;

  constructor(partial: Partial<LinkServiceWithEMansionResponse>) {
    Object.assign(this, partial);
  }
}

export class Result {
  /**棟ID */
  readonly building_id?: string;
  /**部屋番号 */
  readonly room_number?: string;
  /**物件ID(APID) */
  readonly property_id?: string;
  readonly name_surname?: string;
  readonly name_first_name?: string;
  readonly kana_surname?: string;
  readonly kana_first_name?: string;
  readonly birthday?: string;
  readonly phone_number?: string;
  readonly emergency_phone_number?: string;
  readonly email_address?: string;
  readonly scheduled_move_in_on?: string;
  /**ログインID(サブドメインなし) */
  readonly login_id?: string;
  readonly subdomain?: string;
  readonly e_tnc_member_id?: string;
  readonly member_id?: string;
  /**Connectix申し込み有無 */
  readonly sdwan_flag?: string;
  /**テンプレート表示
   * 00:表示なし
   * 01:show_internet_start_tpl_01
   * 02:show_internet_start_tpl_02
   * 03:show_internet_start_tpl_03
   * 04:show_internet_start_tpl_04
   * 05:show_internet_start_tpl_05 */
  readonly show_tpl!: string;
  readonly service_start_date?: string;
  /**課金情報_インターネット */
  readonly billing_data_internet!: BillingDataInternet;
  /**課金情報_IPフォン */
  readonly billing_data_ipphone?: BillingDataIpphone;
  /**課金情報_i-フィルター */
  readonly billing_data_ifilter?: BillingDataIfilter;
}
export class BillingDataInternet {
  /**基本料 */
  readonly basic_fee!: string;
  readonly basic_fee_cp?: string;
  /**入会金 */
  readonly em_fee?: string;
  readonly em_fee_cp?: string;
  /**入会時工事費 */
  readonly em_adm_construction?: string;
  readonly em_adm_construction_cp?: string;
  /**アダプタ料金 */
  readonly adapter_charge?: string;
  readonly adapter_charge_cp?: string;
}
export class BillingDataIpphone {
  /**基本料 */
  readonly basic_fee?: string;
  readonly basic_fee_cp?: string;
  /**TAレンタル料 */
  readonly rental_charge?: string;
  readonly rental_charge_cp?: string;
  /**ユニバーサルサービス料 */
  readonly uni_charge_tax_inc?: string;
  readonly uni_charge_cp?: string;
  /**事務手数料 */
  readonly jimu_fee?: string;
  readonly jimu_fee_cp?: string;
}

export class BillingDataIfilter {
  /**基本料 */
  readonly basic_fee?: string;
  readonly basic_fee_cp?: string;
}
