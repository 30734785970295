import { Module } from 'vuex';

import { RootState } from '../index';

/** UCOM メールパック State */
const UcomMailPackState: {
  contactMailAddress: string | undefined;
  productName: string | undefined;
  monthlyFee: string | undefined;
  processDate: Date | undefined;
  serviceDescription: string | undefined;
  isActiveButton: boolean | undefined;
} = {
  contactMailAddress: undefined,
  productName: undefined,
  monthlyFee: undefined,
  processDate: undefined,
  serviceDescription: undefined,
  isActiveButton: undefined,
};

type UcomMailPackStore = typeof UcomMailPackState;

/** UCOM メールパック State */
export const ucomMailPackStore: Module<UcomMailPackStore, RootState> = {
  namespaced: true,
  state: UcomMailPackState,
  actions: {},
  mutations: {
    contactMailAddress: (state, value: string) => (state.contactMailAddress = value),
    productName: (state, value: string) => (state.productName = value),
    monthlyFee: (state, value: string) => (state.monthlyFee = value),
    processDate: (state, value: Date) => (state.processDate = value),
    serviceDescription: (state, value: string) => (state.serviceDescription = value),
    isActiveButton: (state, value: boolean) => (state.isActiveButton = value),
  },
  getters: {
    contactMailAddress: (state): string | undefined => state.contactMailAddress,
    productName: (state): string | undefined => state.productName,
    monthlyFee: (state): string | undefined => state.monthlyFee,
    processDate: (state): Date | undefined => state.processDate,
    serviceDescription: (state): string | undefined => state.serviceDescription,
    isActiveButton: (state): boolean | undefined => state.isActiveButton,
  },
};
