<template>
  <div class="five-a-connectix-apply">
    <LoadingComponent v-if="!isMounted || isSubmitting" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Connectix お客様情報入力</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A Connectix お申し込み</li>
        <li>お客様情報入力</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">お客様情報入力</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:お客様情報入力 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お客様情報入力" />お客様情報入力</h2>
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <h3 class="service-h3">お客様情報</h3>
        <button class="sbtn fix-btn" v-on:click="onEdit()"><i class="material-icons link">west</i>修正</button>
        <p>お客様情報に修正がありましたら「修正」ボタンをクリックして、Portasの会員情報を修正してください。</p>
        <div v-if="customer" class="course-list">
          <div class="grid pc-grid2 sp-grid1">
            <dl>
              <dt>建物名</dt>
              <dd>{{ propertyName }}</dd>
            </dl>
            <dl>
              <dt>お名前</dt>
              <dd>{{ customer.nameSurname }}&emsp;{{ customer.nameFirstName }}</dd>
            </dl>
            <dl>
              <dt>フリガナ</dt>
              <dd>{{ customer.kanaSurname }}&emsp;{{ customer.kanaFirstName }}</dd>
            </dl>
            <dl>
              <dt>生年月日</dt>
              <dd>{{ inputData.birthdayYear }}年{{ inputData.birthdayMonth }}月{{ inputData.birthdayDate }}日</dd>
            </dl>
          </div>
          <div class="grid pc-grid1 sp-grid1">
            <dl>
              <dt>ご住所</dt>
              <dd>〒{{ customer.destinationZipcode }} {{ customer.destinationAddress1 }}</dd>
            </dl>
            <dl>
              <dt>ご連絡先メールアドレス</dt>
              <dd>{{ customer.emailAddress }}</dd>
            </dl>
          </div>
        </div>
        <table v-if="customer" class="table-type2">
          <tr>
            <th class="va-middle">お部屋番号（半角英数）<span class="required">必須</span></th>
            <td><input type="text" class="text short" placeholder="101" v-model="inputData.roomNumber" /></td>
          </tr>
          <tr>
            <th class="va-middle">ご連絡先お電話番号１（半角数字）<span class="required">必須</span></th>
            <td><input type="text" class="text middle" placeholder="03-1234-5678" v-model="inputData.phoneNumber" /></td>
          </tr>
          <tr>
            <th class="va-middle">ご連絡先お電話番号２（半角数字）</th>
            <td><input type="text" class="text middle" placeholder="03-1234-5678" v-model="inputData.emergencyPhoneNumber" /></td>
          </tr>
          <tr>
            <th class="va-middle">ご入居予定日（半角数字）</th>
            <td>
              <input type="text" class="text short" placeholder="西暦" v-model="inputData.scheduledMoveInOnYear" />
              年&emsp;
              <select name="month" class="form-select" v-model="inputData.scheduledMoveInOnMonth">
                <option value="">--</option>
                <option v-for="n in 12" :key="n" v-bind:value="n">{{ n }}</option>
              </select>
              月&emsp;
              <select name="date" class="form-select" v-model="inputData.scheduledMoveInOnDate">
                <option value="">--</option>
                <option v-for="n in 31" :key="n" v-bind:value="n">{{ n }}</option>
              </select>
              日
            </td>
          </tr>
        </table>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="sblc">
        <p class="form-btn-txt">入力内容をご確認の上、よろしければ「次へ」ボタンをクリックしてください。</p>
        <div class="btn-area">
          <button class="btn btn01 bs" @click="onNext()" :disabled="!isInputed">次へ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
/* course-list
---------------------------------------------------- */
.course-list {
  margin: 10px 0;
  border-top: 1px solid #d3d3d3;
}

.course-list dl {
  padding: 10px 20px;
  margin: 0;
  border-bottom: 1px solid #d3d3d3;
  word-break: break-all;
}

.course-list dl.line {
  background-color: #fbfbfb;
}

.course-list dl dt {
  font-weight: bold;
  float: left;
}

.course-list dl dd {
  padding-left: 180px;
}

.course-list .pc-grid2 dl:nth-child(odd) {
  border-right: 1px solid #d3d3d3;
}

/* Required
---------------------------------------------------- */
.required {
  padding: 0 4px;
  margin-left: 2px;
  border-radius: 3px;
  background: #e42727;
  color: #ffffff;
  font-size: 10px;
}

/* sbtn
---------------------------------------------------- */
.sbtn {
  min-width: 80px;
  line-height: 26px;
  text-align: center;
  font-size: 13px;
  color: #cf1225;
  border: 1px solid #cf1225;
  border-radius: 2px;
  margin-top: -40px;
  padding: 0 10px;
  background-color: #ffffff;
}

.sbtn:hover {
  opacity: 0.7;
}

.fix-btn {
  float: right;
  color: #939393;
  border: 1px solid #939393;
  margin-top: -40px;
}

.btn01:disabled {
  opacity: 0.5;
}

/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}

@media only screen and (max-width: 767px) {
  /* course-list
  ---------------------------------------------------- */
  .course-list dl {
    padding: 10px 10px;
  }

  .course-list dl dt {
    width: 100px;
  }

  .course-list dl dd {
    padding-left: 120px;
    margin: 0;
  }

  .course-list .pc-grid2 dl:nth-child(odd) {
    border-right: none;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
/** 使用するバリデーションの定義 */
import { AuthService } from '@/shared/services/auth/auth-service';
import { required, numeric, minLength, maxLength } from '@vuelidate/validators';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FiveASharedExternalApiService } from '@/shared/services/external-api/five-a-shared-external-api-service';
import { FiveARoomsResponseResult } from '@/shared/classes/external-api/five-a/rooms-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { FiveAEntryInputForm } from './classes/entry-input-form';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';
import { Member } from '@/shared/classes/spf-api/member';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { isValidMonthAndDatePairs } from '@/shared/util/func-is-valid-month-and-date-pairs';
import { parsePhoneNumber } from 'libphonenumber-js';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import useVuelidate from '@vuelidate/core';
import { MountedCheckService } from '@/shared/services/mounted-check-service';

/** Input コンポーネント */
export default defineComponent({
  name: 'five-a-entry-input',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    customer: null as FiveAEntryInputForm | null,
    member: null as Member | null,
    property: {},
    /** 画面入力項目 */
    inputData: {
      /** 生年月日 */
      birthdayYear: null as number | null,
      birthdayMonth: null as number | null,
      birthdayDate: null as number | null,
      /** お部屋番号 */
      roomNumber: null as string | null,
      /** ご連絡先お電話番号１ */
      phoneNumber: null as string | null,
      /** ご連絡先お電話番号２ */
      emergencyPhoneNumber: null as string | null,
      /** ご入居予定日 */
      scheduledMoveInOnYear: null as string | null,
      scheduledMoveInOnMonth: '',
      scheduledMoveInOnDate: '',
    },
    isAbleToConnectixContract: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** 建物名 */
    propertyName: '',
    // ボタン押下時対応
    isSubmitting: false,
    // mounted() 実行中かどうか
    isMounted: false,
  }),
  /** バリデーション定義 */
  validations: {
    inputData: {
      // 生年月日
      birthdayYear: { required, numeric, minLength: minLength(4), maxLength: maxLength(4) },
      birthdayMonth: { required, numeric, minLength: minLength(1), maxLength: maxLength(2) },
      birthdayDate: { required, numeric, minLength: minLength(1), maxLength: maxLength(2) },
      /** ご入居予定日 */
      scheduledMoveInOnYear: { numeric, minLength: minLength(4), maxLength: maxLength(4) },
      scheduledMoveInOnMonth: { numeric, minLength: minLength(1), maxLength: maxLength(2) },
      scheduledMoveInOnDate: { numeric, minLength: minLength(1), maxLength: maxLength(2) },
      /** お部屋番号 */
      roomNumber: { required },
    },
    customer: {
      /** お名前 姓 */
      nameSurname: { required },
      /** お名前 名 */
      nameFirstName: { required },
      /** フリガナ 姓カナ */
      kanaSurname: { required },
      /** フリガナ 名カナ */
      kanaFirstName: { required },
      /** ご連絡先お電話番号１ */
      phoneNumber: { required },
      /** 郵便番号 */
      zipcode: { required },
      /** 住所 */
      address: { required },
      /** 連絡先メールアドレス */
      emailAddress: { required },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted(): Promise<void> {
    try {
      if (!(await MountedCheckService.canReadFiveAEntryScreen())) {
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      // Five.A会員情報取得処理
      const customer: FiveAEntryInputForm = this.$store.getters['fiveAEntryStore/entryInputForm'];
      // customer情報が無い場合、規約同意していないためエラーとする
      if (!customer) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      this.customer = customer;

      // 既に入力済みの情報があれば表示する
      this.inputData.roomNumber = this.customer.roomNumber ?? null;
      this.inputData.phoneNumber = this.customer.phoneNumber ?? null;
      this.inputData.emergencyPhoneNumber = this.customer.emergencyPhoneNumber ?? null;
      if (customer.scheduledMoveInOn) {
        const buf: string[] = customer.scheduledMoveInOn.split('/');
        this.inputData.scheduledMoveInOnYear = buf[0];
        this.inputData.scheduledMoveInOnMonth = Number(buf[1]).toString();
        this.inputData.scheduledMoveInOnDate = Number(buf[2]).toString();
      }

      // 会員情報取得処理
      this.member = this.$store.getters['memberStore/member'];

      // 後者の !this.member は、ほぼ生じえない
      // マイページ編集後に限定して処理した方がより良いが、煩瑣になるため一旦はこの形としておく
      if (this.$store.getters['platformEditStore/beforePath'] === '/5a/entry/input' || !this.member) {
        this.$store.commit('platformEditStore/beforePath', null);
        this.member = this.$store.getters['memberStore/member']; // beforeEach により、変更後の会員情報が取得される
        if (!this.member || !this.member.propertyId) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_MEMBER);
        }
        // 修正後の member データで更新（紐づけ物件の修正は生じえない）
        this.customer.buildingId = this.member.buildingNumber ?? '';
        this.customer.nameSurname = this.member.firstName;
        this.customer.nameFirstName = this.member.givenName;
        this.customer.kanaSurname = this.member.firstNameKatakana;
        this.customer.kanaFirstName = this.member.givenNameKatakana;
        this.customer.birthday = this.member.birthdate!.replace(/-/g, '/');
        this.customer.emailAddress = this.member.emailAddress;
        if (this.member.phoneNumber) {
          this.inputData.phoneNumber = this.member.phoneNumber;
        }
      }

      if (this.member.phoneNumber) {
        this.inputData.phoneNumber = this.member.phoneNumber;
      } else {
        this.inputData.phoneNumber = this.customer.phoneNumber;
      }

      // 物件情報の取得
      const property: Property | null = this.$store.getters['propertyStore/property'];
      if (property) {
        this.propertyName = property.dispApartmentName;
      }

      // 会員情報の必須チェック
      if (this.v$.customer.$invalid) {
        if (this.v$.customer.nameSurname?.$invalid || this.v$.customer.nameFirstName?.$invalid) {
          if (this.v$.customer.nameSurname?.required.$invalid && this.v$.customer.nameFirstName?.required.$invalid) {
            this.errorMessages.push('お名前を入力してください。');
          }
        }
        if (this.v$.customer.kanaSurname?.$invalid || this.v$.customer.kanaFirstName?.$invalid) {
          if (this.v$.customer.kanaSurname?.required.$invalid && this.v$.customer.kanaFirstName?.required.$invalid) {
            this.errorMessages.push('フリガナを入力してください。');
          }
        }
        // if (this.v$.customer.phoneNumber?.$invalid) {
        //   if (this.v$.customer.phoneNumber?.required.$invalid) {
        //     this.errorMessages.push('ご連絡先お電話番号１を入力してください。');
        //   }
        // }
        if (this.v$.customer.emailAddress?.$invalid) {
          if (this.v$.customer.emailAddress?.required.$invalid) {
            this.errorMessages.push('ご連絡先メールアドレスを入力してください。');
          }
        }
        if (this.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          this.errorMessageTitle = '未入力の項目があります。マイページで登録をお願いいたします。';
          return;
        }
      }

      // ストアのデータをセットする
      if (this.customer) {
        this.setBirthday(this.customer.birthday);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounted = true;
    }
  },
  computed: {
    /** 生年月日の取得 */
    getBirthday() {
      if (typeof this.inputData.birthdayYear === 'number' && typeof this.inputData.birthdayMonth === 'number' && typeof this.inputData.birthdayDate === 'number') {
        const [birthdayYear, birthdayMonth, birthdayDate] = [
          String(this.inputData.birthdayYear),
          String(this.inputData.birthdayMonth).padStart(2, '0'),
          String(this.inputData.birthdayDate).padStart(2, '0'),
        ];
        /**0字詰め&「/」結合 */
        return `${birthdayYear}/${birthdayMonth}/${birthdayDate}`;
      } else {
        return null;
      }
    },
    /** ご入居予定日の取得 */
    getScheduledMoveInOn() {
      if (!this.inputData.scheduledMoveInOnYear || !this.inputData.scheduledMoveInOnMonth || !this.inputData.scheduledMoveInOnDate) {
        return null;
      }
      return (
        String(this.inputData.scheduledMoveInOnYear) +
        '/' +
        ('00' + String(this.inputData.scheduledMoveInOnMonth)).slice(-2) +
        '/' +
        ('00' + String(this.inputData.scheduledMoveInOnDate)).slice(-2)
      );
    },
    isInputed(): boolean {
      if (this.inputData.roomNumber && this.inputData.phoneNumber && this.inputData.birthdayYear && this.inputData.birthdayMonth && this.inputData.birthdayDate) {
        return true;
      }
      return false;
    },
  },
  methods: {
    /** 生年月日の設定 */
    setBirthday(birthday: string) {
      if (!birthday) {
        return;
      }
      const date = birthday.split('/');
      this.inputData.birthdayYear = Number(date[0]);
      this.inputData.birthdayMonth = Number(date[1]);
      this.inputData.birthdayDate = Number(date[2]);
    },
    async onEdit() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/platform/my-page/member-edit')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /** フォームのバリデーション */
    async validate(): Promise<boolean> {
      this.errorMessages = [];
      this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
      // お部屋番号必須チェック
      if (this.v$.inputData.roomNumber?.required.$invalid) {
        this.errorMessages.push('お部屋番号を入力してください。');
      }
      // 生年月日必須チェック
      if (this.v$.inputData.birthdayYear?.required.$invalid || this.v$.inputData.birthdayMonth?.required.$invalid || this.v$.inputData.birthdayDate?.required.$invalid) {
        this.errorMessages.push('生年月日を入力してください。');
      }
      // 生年月日形式チェック
      else if (this.v$.inputData.birthdayYear?.$invalid || this.v$.inputData.birthdayMonth?.$invalid || this.v$.inputData.birthdayDate?.$invalid) {
        this.errorMessages.push('正しい生年月日を入力してください。');
      }

      // ご連絡先お電話番号１とご連絡先お電話番号２の入力欄に入力されて問題ない文字集合
      const REGEX_INPUT_PHONE_NUMBER = /^[0-9]([0-9]|-)*[0-9]$/;

      // ご連絡先お電話番号１の入力がある場合のみ形式チェック
      if (this.inputData.phoneNumber) {
        const phoneNumber: string = this.inputData.phoneNumber;

        if (!REGEX_INPUT_PHONE_NUMBER.test(phoneNumber)) {
          this.errorMessages.push('正しいご連絡先お電話番号１を入力してください。');
        } else {
          try {
            if (/^0120-[0-9]{1,4}-[0-9]{3,4}$/.test(phoneNumber)) {
              // 問題ないためスルー
            } else if (!parsePhoneNumber(phoneNumber, 'JP')?.isValid()) {
              this.errorMessages.push('正しいご連絡先お電話番号１を入力してください。');
            } else if (!/^[0-9]{1,4}-[0-9]{1,4}-[0-9]{4}$/.test(phoneNumber)) {
              this.errorMessages.push('正しいご連絡先お電話番号１を入力してください。');
            }
          } catch {
            // parsePhoneNumberがparse出来なくてエラーを出すとき用
            this.errorMessages.push('正しいご連絡先お電話番号１を入力してください。');
          }
        }
      }

      // ご連絡先お電話番号２の入力がある場合のみ形式チェック
      if (this.inputData.emergencyPhoneNumber) {
        const phoneNumber: string = this.inputData.emergencyPhoneNumber;

        if (!REGEX_INPUT_PHONE_NUMBER.test(phoneNumber)) {
          this.errorMessages.push('正しいご連絡先お電話番号２を入力してください。');
        } else {
          try {
            if (/^0120-[0-9]{1,4}-[0-9]{3,4}$/.test(phoneNumber)) {
              // 問題ないためスルー
            } else if (!parsePhoneNumber(phoneNumber, 'JP')?.isValid()) {
              this.errorMessages.push('正しいご連絡先お電話番号２を入力してください。');
            } else if (!/^[0-9]{1,4}-[0-9]{1,4}-[0-9]{4}$/.test(phoneNumber)) {
              this.errorMessages.push('正しいご連絡先お電話番号２を入力してください。');
            }
          } catch {
            // parsePhoneNumberがparse出来なくてエラーを出すとき用
            this.errorMessages.push('正しいご連絡先お電話番号２を入力してください。');
          }
        }
      } else {
        //入力がない場合は一律nullを入れる
        this.inputData.emergencyPhoneNumber = null;
      }
      // ご入居予定日欄について、少なくとも1つ入力があるとき
      if (this.inputData.scheduledMoveInOnYear || this.inputData.scheduledMoveInOnMonth || this.inputData.scheduledMoveInOnDate) {
        // 未入力欄が存在するときはエラーに
        if (!this.inputData.scheduledMoveInOnYear || !this.inputData.scheduledMoveInOnMonth || !this.inputData.scheduledMoveInOnDate) {
          this.errorMessages.push('正しいご入居予定日を入力してください。');

          // Five.A 外部APIのためのご入居予定日バリデーション
          // 年: 4桁であり かつ 0001-9999 の範囲
          // 月: 2桁であり かつ 01-12の範囲
          // 日: 2桁であり かつ うるう年を考慮した日付の範囲
          // 月と日について、getScheduledMoveInOn()にてゼロパディングしているため、1桁も許容している

          // vuelidateで指定した条件を満たさないときはエラーに (半角数字と桁数を指定)
        } else if (this.v$.inputData.scheduledMoveInOnYear?.$invalid || this.v$.inputData.scheduledMoveInOnMonth?.$invalid || this.v$.inputData.scheduledMoveInOnDate?.$invalid) {
          this.errorMessages.push('正しいご入居予定日を入力してください。');

          // 年について、0000のときはエラーに
        } else if (/^0000$/.test(this.inputData.scheduledMoveInOnYear)) {
          this.errorMessages.push('正しいご入居予定日を入力してください。');

          // 月について、1-12 以外のときはエラーに
        } else if (!/^[1-9]$|1[0-2]$/.test(String(this.inputData.scheduledMoveInOnMonth))) {
          this.errorMessages.push('正しいご入居予定日を入力してください。');

          // 日について、月との組み合わせで有効でないときはエラーに
        } else if (
          !isValidMonthAndDatePairs(Number(this.inputData.scheduledMoveInOnYear), Number(this.inputData.scheduledMoveInOnMonth), Number(this.inputData.scheduledMoveInOnDate))
        ) {
          this.errorMessages.push('正しいご入居予定日を入力してください。');
        }
      }

      // 部屋番号の入力チェック
      const resultFindPropertyRooms: FiveARoomsResponseResult | FiveASharedErrorResponse = await FiveASharedExternalApiService.findRooms(
        /** UA種別 (2:Five.A）*/
        UA_TYPE.FIVE_A,
        /** 物件ID */
        this.customer?.propertyId ? this.customer?.propertyId : ''
      );
      if (resultFindPropertyRooms instanceof FiveASharedErrorResponse) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.ROOM_INFO);
      } else if (resultFindPropertyRooms instanceof FiveARoomsResponseResult) {
        const roomNumbers = resultFindPropertyRooms.rooms;
        if (roomNumbers !== null && roomNumbers.length) {
          // お申し込み可能な部屋の中に自部屋があるかチェック
          const applyRooms = roomNumbers.find((room) => room.room_number === this.inputData.roomNumber);
          if (applyRooms === undefined) {
            // お申し込み可能
            this.errorMessages.push('正しいお部屋番号を入力してください。');
            window.scrollTo(0, 0);
          } else {
            if (applyRooms.apply_connectix_id === '1') {
              // 「Connectixお申し込みの可/不可（applyConnectixId）」のレスポンスが不可のためお申し込み不可
              this.errorMessages.push(
                'インターネット開通工事完了後にConnectixをお申し込みいただけます。大変恐れ入りますが、Five.A建物専用ホームページ「ご利用方法」をご確認の上、インターネット開通工事をお申し込みください'
              );
              window.scrollTo(0, 0);
            }
          }
        } else {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.ROOM_INFO);
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.ROOM_INFO);
      }
      if (this.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        window.scrollTo(0, 0);

        // ボタン押下解除
        this.isSubmitting = false;
        return false;
      }

      return true;
    },
    /** 修正ボタン押下時：マイページ(入力)に遷移する */
    async modCustomerInfo() {
      // 入力情報をストアに保存
      if (this.customer) {
        this.customer.roomNumber = this.inputData.roomNumber ?? '';
        this.customer.birthday = this.getBirthday ?? '';
        this.customer.phoneNumber = this.inputData.phoneNumber ?? '';
        this.customer.emergencyPhoneNumber = this.inputData.emergencyPhoneNumber ?? '';
        this.customer.scheduledMoveInOn = this.getScheduledMoveInOn ?? '';
        this.$store.commit('fiveAEntryStore/entryInputForm', this.customer);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/my-page/member-edit').catch((error: any) => {
        checkRouterError(error);
      });
    },
    /** 次へボタン押下時：確認に遷移する */
    async onNext() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中とする
      this.isSubmitting = true;

      if (!(await this.validate())) {
        return;
      }

      if (!this.customer) {
        throw new Error(FRONT_ERROR_INFO_DATA_INCONSISTENCT.FIVE_A.NO_PROPERTY_INFO);
      }

      // 入力情報をストアに保存
      this.customer.roomNumber = this.inputData.roomNumber ?? '';
      this.customer.birthday = this.getBirthday ?? '';
      this.customer.phoneNumber = this.inputData.phoneNumber ?? '';
      this.customer.emergencyPhoneNumber = this.inputData.emergencyPhoneNumber ?? undefined;
      this.customer.scheduledMoveInOn = this.getScheduledMoveInOn ?? undefined;
      this.$store.commit('fiveAEntryStore/entryInputForm', this.customer);

      // 確認画面に遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/5a/entry/confirm').catch((error: any) => {
        checkRouterError(error);
      });
    },
  },
});
</script>
