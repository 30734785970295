<template>
  <div class="e-mansion-entry-terms">
    <LoadingComponent v-if="isLoading || isOnNextExecuting" />
    <main class="underlayer-main">
      <h1>e-mansion ご入会お申し込み</h1>
    </main>

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion ご入会お申し込み</li>
      </ul>
      <!-- /breadcrumb -->
      <div class="blc"><error-messages-component :errorMessages="errorMessages" :errorMessageTitle="errorMessageTitle" /></div>
      <!-- 電気通信事業法に基づく重要事項 -->
      <div class="blc" v-if="displayTelecommunicationsBusinessLaw">
        <p>お申し込み前に必ず以下をお読みください。</p>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="電気通信事業法に基づく重要事項" />電気通信事業法に基づく重要事項</h2>
        <p class="mb15">
          <b><span class="red mb10">電気通信事業法に基づく重要事項をご説明します。</span></b
          ><br />
          <p>　</p>
          <a :href="`${eMansionMyUrl}/instructions/downloadPdf.php?function=instructions&apid=${apid}&kind=01`" class="link" target="_blank">こちら</a>をご確認ください。
        </p>
      </div>

      <!-- blc:e-mansion 会員規約 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="e-mansion 会員規約" />e-mansion 会員規約</h2>
        <a :href="`${eMansionUrl}/pdf/service/internetkiyaku.pdf`" class="link" target="_blank">e-mansion　インターネットサービス会員規約</a>
      </div>
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="個人情報取得に係る通知" />個人情報取得に係る通知</h2>
        <a :href="`${eMansionUrl}/pdf/service/privacypolicy.pdf`" class="link" target="_blank">個人情報取得に係る通知</a>
      </div>

      <!-- blc -->
      <div class="blc">
        <div class="my-checkbox-outer">
          <label
            ><input type="checkbox" v-model="isAgreeTermsAndNotice" />
            <span class="checkbox-text" v-if="displayTelecommunicationsBusinessLaw">電気通信事業法に基づく重要事項、e-mansion 会員規約、個人情報取得に係る通知に同意します</span>
            <span class="checkbox-text" v-else>e-mansion 会員規約、個人情報取得に係る通知に同意します</span>
          </label>
        </div>
        <div class="btn-area">
          <button type="button" class="btn btn05 bs" v-on:click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>

          <button type="button" class="btn btn01 bs mt15" :disabled="!isAllChecked || isLoading || errorMessages.length > 0" v-on:click="onNext()">
            規約と個人情報取得通知に同意して申し込む<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.btn-height {
  height: 50px;
}

/* お申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
.btn {
  cursor: pointer;
}
.customer-center-info {
  padding-left: 25px;
}
.my-checkbox-outer > label > input {
  margin-right: 10px;
}
.checkbox-text {
  padding-left: 10px;
}
</style>

<script lang="ts">
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { EMansionRoomsResponseResult } from '@/shared/classes/external-api/e-mansion/rooms-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UA_TYPE } from '@/shared/const/service-type';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { isUnder18 } from '@/shared/util/func-is-under-18';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { EMansionProperty } from '../../../shared/classes/external-api/e-mansion/property-response';
import { EMansionEntryInputForm } from './classes/entry-input-form';

/** Terms コンポーネント */
export default defineComponent({
  name: 'terms',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 会員情報 */
    member: null as Member | null,
    /** 物件情報 */
    property: null as Property | null,
    /** e-mansion物件情報 */
    eMansionProperty: null as EMansionProperty | null,
    /** 通知に同意する */
    isAgreeTermsAndNotice: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
    /** e-mansion のサイト URL */
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,

    /** 画面読み込み中か否か */
    isLoading: true,
    // onNextメソッドが実行中かどうか
    isOnNextExecuting: false,
  }),
  computed: {
    isAllChecked(): boolean {
      return this.isAgreeTermsAndNotice;
    },
    // 「電気通信事業法に基づく重要事項」を表示するか否か
    displayTelecommunicationsBusinessLaw() {
      if (this.eMansionProperty?.em_actype === '2' || this.eMansionProperty?.em_actype === '3' || this.eMansionProperty?.em_actype === '4') {
        return true;
      }
      return false;
    },
    /**9桁のランダムな数字を生成 */
    apid(): string {
      if (this.eMansionProperty) {
        return this.eMansionProperty.IN_APID;
      } else {
        return '';
      }
    },
  },
  async mounted() {
    try {
      // 初期表示チェック
      if (!(await MountedCheckService.canReadEMansionEntryScreen())) {
        return await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
      }

      const member: Member = await this.$store.getters['memberStore/member'];
      this.member = member;
      const property: Property = await this.$store.getters['propertyStore/property'];
      this.property = property;
      this.$store.commit('platformEditStore/beforePath', null); // 修正から戻ってきた場合は空にする

      //会員が18歳未満の場合
      if (!this.member.birthdate) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
      if (isUnder18(this.member.birthdate)) {
        this.errorMessages.push(
          `18歳未満の方のe-mansionへのご入会には、別途「親権同意書」のご提出が必要となります。
           誠に恐れ入りますが、e-mansionサポートセンターへご連絡いただき、必要書類をお取り寄せください。
           WEBからのお申し込みができないときは、e-mansionサポートセンターへご連絡ください。`
        );
        this.$store.commit('errorStore/messages', this.errorMessages);
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
          return;
        });
        this.isLoading = false;
        return;
      }
      //既に紐づけ済みの場合
      if (this.member.primaryKeyMye && this.member.eTncMemberId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.errorMessages.push(
          `e-mansionご入会のお申し込みは受け付けております。
          <a class="link" href ="/">Portasトップページへ</a>`
        );
        this.isLoading = false;
        return;
      }
      // 物件情報取得処理
      const propertyParams = { apartmentId: this.property.apartmentId, uaType: UA_TYPE.E_MANSION };
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', propertyParams);

      if (eMansionProperty instanceof EMansionSharedErrorResponse) {
        throw eMansionProperty;
      }

      this.eMansionProperty = eMansionProperty;
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      // エラー画面に遷移
      return await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
    this.isLoading = false;
  },
  methods: {
    /** 次へボタン押下時：お客様情報入力画面に遷移する */
    async onNext() {
      if (this.isOnNextExecuting) {
        return;
      }
      this.isOnNextExecuting = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      if (!this.isAgreeTermsAndNotice) {
        this.isOnNextExecuting = false;
        return;
      }
      try {
        const customerStore: EMansionEntryInputForm = this.$store.getters['eMansionEntryStore/entryInputForm'];
        const customer = new EMansionEntryInputForm({
          /** 氏名 */
          name_surname: this.member!.firstName,
          /** 名 */
          name_first_name: this.member!.givenName,
          /** 姓カナ */
          kana_surname: this.member!.firstNameKatakana,
          /** 名カナ */
          kana_first_name: this.member!.givenNameKatakana,
          /** 生年月日 yyyy/mm/dd形式 */
          birthday: this.member!.birthdate!.replace(/-/g, '/'),
          /** 連絡先メールアドレス */
          email_address: this.member!.emailAddress,
          /** 物件ID */
          property_id: this.property!.apartmentId,
          /** 棟ID */
          building_id: customerStore?.building_id ? customerStore?.building_id : '0000',
          /** 物件名 */
          property_name: this.property!.apartmentName,
          /** 郵便番号 */
          zipcode: this.property!.zipcode,
          /** 住所 */
          address: this.property!.address1,
          /** 部屋番号 */
          room_number: customerStore?.room_number ? customerStore?.room_number : '',
          /**電話番号 */
          phone_number: customerStore?.phone_number ? customerStore?.phone_number : '',
          /**ご入居予定日 */
          scheduled_move_in_on: customerStore?.scheduled_move_in_on ? customerStore?.scheduled_move_in_on : '',
          /** 書類送付先種別*/
          destination_kind: customerStore?.destination_kind ? customerStore?.destination_kind : '',
          /** 書類送付先郵便番号 */
          destination_zipcode: customerStore?.destination_zipcode ? customerStore?.destination_zipcode : '',
          /** 書類送付先郵便番号（前半） */
          destination_zipcode_former: customerStore?.destination_zipcode_former ? customerStore?.destination_zipcode_former : '',
          /** 書類送付先郵便番号（後半） */
          destination_zipcode_latter: customerStore?.destination_zipcode_latter ? customerStore?.destination_zipcode_latter : '',
          /** 書類送付先住所１ */
          destination_address1: customerStore?.destination_address1 ? customerStore?.destination_address1 : '',
          /** 書類送付先住所２ */
          destination_address2: customerStore?.destination_address2 ? customerStore?.destination_address2 : '',
        });
        this.$store.commit('eMansionEntryStore/entryInputForm', customer);
        //部屋番号マスタ
        if (!this.property?.apartmentId) {
          await this.$router.push('/platform').catch((error: any) => {
            checkRouterError(error);
          });
          this.isOnNextExecuting = false;
          return;
        }
        const propertyRoomParams = { uaType: UA_TYPE.E_MANSION, propertyId: this.property?.apartmentId };
        const propertyRooms: EMansionRoomsResponseResult | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/rooms', propertyRoomParams);

        if (propertyRooms instanceof EMansionRoomsResponseResult) {
          if (propertyRooms.rooms.length > 0) {
            return await this.$router.push('/e-mansion/entry/room-number').catch((error) => {
              checkRouterError(error);
            });
          } else {
            return await this.$router.push('/e-mansion/entry/input').catch((error) => {
              checkRouterError(error);
            });
          }
        }
      } catch (error: any) {
        await VueErrorHandler.handle(error, '');
        // エラー画面に遷移
        return await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
      this.isOnNextExecuting = false;
    },
    /**会員専用トップに戻る */
    async onBack() {
      if (this.isOnNextExecuting) {
        return;
      }
      this.isOnNextExecuting = true;
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      this.isOnNextExecuting = false;
      return;
    },
  },
});
</script>
