<template>
  <div class="five-a-entry-completed">
    <LoadingComponent v-if="!isMounted || isExecutingNavigateToLocation" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>Connectix お申し込み完了</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A Connectix お申し込み</li>
        <li>お申し込み完了</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お客様情報入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お申し込みありがとうございます。" />お申し込みありがとうございます。</h2>
        <p>お申し込みいただいた内容は以下のとおりです。</p>
        <div class="sblc">
          <h3 class="service-h3">お客様情報</h3>
          <div v-if="customer" class="course-list">
            <div class="grid pc-grid2 sp-grid1">
              <dl>
                <dt>建物名</dt>
                <dd>{{ propertyName }}</dd>
              </dl>
              <dl>
                <dt>お部屋番号</dt>
                <dd>{{ customer.roomNumber }}</dd>
              </dl>
              <dl>
                <dt>お名前</dt>
                <dd>{{ customer.nameSurname }}&nbsp;&nbsp;{{ customer.nameFirstName }}</dd>
              </dl>
              <dl>
                <dt>フリガナ</dt>
                <dd>{{ customer.kanaSurname }}&nbsp;&nbsp;{{ customer.kanaFirstName }}</dd>
              </dl>
              <dl>
                <dt>生年月日</dt>
                <dd>{{ dispBirthday }}</dd>
              </dl>
              <dl>
                <dt>ご連絡先お電話番号１</dt>
                <dd>{{ customer.phoneNumber }}</dd>
              </dl>
              <dl>
                <dt>ご連絡先お電話番号２</dt>
                <dd>{{ customer.emergencyPhoneNumber }}</dd>
              </dl>
              <dl>
                <dt>ご連絡先メールアドレス</dt>
                <dd>{{ customer.emailAddress }}</dd>
              </dl>
            </div>
            <div class="grid pc-grid1 sp-grid1">
              <dl>
                <dt>ご住所</dt>
                <dd>〒{{ customer.destinationZipcode }} {{ customer.destinationAddress1 }}</dd>
              </dl>
              <dl>
                <dt>ご入居予定日</dt>
                <dd>{{ customer.scheduledMoveInOn }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <!-- /blc -->
        <!-- blc -->
        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th>サービス名</th>
              <td>Connectix</td>
            </tr>
            <tr v-if="fiveAConnectixFee">
              <th>料金（月額）</th>
              <td>
                <div v-if="fiveAConnectixFee.connectixUsageFeeCp">
                  <div style="color: red">キャンペーン中</div>
                  <div>{{ fiveAConnectixFee.connectixUsageFee }} 円</div>
                  <div style="color: red">→ {{ fiveAConnectixFee.connectixUsageFeeCp }} 円</div>
                </div>
                <div v-else>
                  <div>{{ fiveAConnectixFee.connectixUsageFee }} 円</div>
                </div>
              </td>
            </tr>
          </table>
          <div>
            <p class="att grey">※価格は全て税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
          </div>
          <div>
            <p class="mt20">
              お申し込みありがとうございます。<br /><span class="att red">最大10分以内に本サービスが適用されます。</span
              ><br />サービス運用状況については、「Five.A会員専用トップ＞ご利用状況確認」からご確認ください。
            </p>
            <p class="mt20">Connectixでより快適なインターネットライフをお楽しみください。</p>
            <div v-if="canApplyUnextFlag">
              <router-link to="/platform/my-page/products/entry-input?product1=4&utm_source=Portas&utm_medium=entry&utm_campaign=fa&utm_content=connectix_completed_u-next_banner">
                <img class="img-pc" src="../../../images/connectixToUnext_pc.png" />
                <img class="img-sp" src="../../../images/connectixToUnext_sp.png" />
              </router-link>
            </div>
          </div>
        </div>
        <!-- /blc -->
        <!-- blc -->
        <div class="sblc">
          <div class="btn-area">
            <button type="button" class="btn-height btn btn01 bs" @click="navigateToLocation('/5a')">
              Five.A 会員専用トップへ<i class="material-icons link link-icon">east</i>
            </button>
          </div>
          <p class="att form-btn-txt"><br />「Five.A会員専用トップへ」をクリックしていただくと、ログイン済みのFive.A会員専用トップへ進みます。</p>
        </div>
        <!-- /blc -->
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.form-btn-txt {
  text-align: center;
}

/* course-list
---------------------------------------------------- */
.course-list {
  margin: 10px 0;
  border-top: 1px solid #d3d3d3;
}

.course-list dl {
  padding: 10px 20px;
  margin: 0;
  border-bottom: 1px solid #d3d3d3;
  word-break: break-all;
}

.course-list dl.line {
  background-color: #fbfbfb;
}

.course-list dl dt {
  font-weight: bold;
  float: left;
}

.course-list dl dd {
  padding-left: 180px;
}

.course-list .pc-grid2 dl:nth-child(odd) {
  border-right: 1px solid #d3d3d3;
}

/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}

.connectix-campaign-title {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
}

.connectix-campaign {
  padding: 15px 0px 20px 0px;
  margin-bottom: 20px;
}
.img-pc {
  display: block;
  width: 100%;
}
.img-sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  /* course-list
  ---------------------------------------------------- */
  .course-list dl {
    padding: 10px 10px;
  }

  .course-list dl dt {
    width: 100px;
  }

  .course-list dl dd {
    padding-left: 120px;
    margin: 0;
  }

  .course-list .pc-grid2 dl:nth-child(odd) {
    border-right: none;
  }
  .img-pc {
    display: none;
  }
  .img-sp {
    display: block;
    width: 100%;
  }
}
</style>

<script lang="ts">
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { FiveAConnectixFee, FiveAEntryInputForm } from '../entry/classes/entry-input-form';

/** Completed コンポーネント */
export default defineComponent({
  name: 'completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    customer: null as FiveAEntryInputForm | null,
    member: null as Member | null,
    /** Connectix 料金 */
    fiveAConnectixFee: null as FiveAConnectixFee | null,
    /** 建物名 */
    propertyName: '' as string,
    /** 生年月日（表示用） */
    dispBirthday: '',
    /** 物件ID */
    apartmentId: '',
    // mounted()実行中かどうか
    isMounted: false as boolean,
    // navigateToLocation()実行中かどうか
    isExecutingNavigateToLocation: false as boolean,
    canApplyUnextFlag: false,
  }),
  async mounted() {
    try {
      if (!(await MountedCheckService.canReadFiveAScreen())) {
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      // 会員情報取得処理
      this.member = this.$store.getters['memberStore/member'];

      // 会員情報取得処理
      const customer: FiveAEntryInputForm = this.$store.getters['fiveAEntryStore/entryInputForm'];
      // customer情報が無い場合、会員規約の同意なしとしてエラー画面に遷移する
      if (!customer) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      this.customer = customer;
      this.dispBirthday = this.getBirthday(customer.birthday);

      // 物件情報の取得
      const property: Property | null = this.$store.getters['propertyStore/property'];
      if (property) {
        this.propertyName = property.dispApartmentName;
        this.apartmentId = property.apartmentId ?? '';
      }
      //U-NEXTの商品契約可否を確認
      this.canApplyUnextFlag = await SpfApiService.isProductsContractable(this.member!.id.toString(), [4]);
      // Store から Connectix 料金を取得
      this.fiveAConnectixFee = this.$store.getters['fiveAEntryStore/fiveAConnectixFee'];
    } catch (e) {
      throw e;
    } finally {
      this.isMounted = true;
    }
  },
  methods: {
    /** 表示用生年月日を取得する */
    getBirthday(birthday: string) {
      const date = birthday.split('/');
      const birthdayYear = Number(date[0]);
      const birthdayMonth = Number(date[1]);
      const birthdayDate = Number(date[2]);
      return String(birthdayYear) + '年 ' + String(birthdayMonth) + '月 ' + String(birthdayDate) + '日';
    },
    async navigateToLocation(path: string) {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      await this.$router
        .push(path)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isExecutingNavigateToLocation = false;
        });
    },
  },
});
</script>
