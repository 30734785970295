<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />

    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>追加アカウント</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/e-mansion">e-mansion会員専用トップ</router-link>
        </li>
        <li>追加アカウント</li>
      </ul>
      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>ご利用状況</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:追加アカウント 確認(お申し込み) -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="追加アカウント" />お申し込み確認</h2>
        <p>お申し込み内容をご確認ください。</p>

        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <div class="sblc">
            <h3 class="service-h3">お申し込み内容</h3>

            <table class="table-type1">
              <tbody>
                <tr>
                  <th><b>ご希望メールアドレス</b></th>
                  <td class="red">
                    <b>{{ mailAddress }}</b>
                  </td>
                </tr>
                <tr v-if="slimPlan">
                  <th><b>ご利用料金</b></th>
                  <td>
                    <b>{{ usageFee }}円</b><br />
                    ※価格は全て税込み表示(消費税{{ tax }}%)です。今後消費税率が改正された場合は、改正後の税率による価格に変更となります。<br />
                    ご登録のお支払い方法により、お支払いいただきます。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /blc -->

      <h3 class="service-h3" v-if="is3dsecure">e-mansionサービス お支払い方法</h3>
      <div v-if="is3dsecure">
        <div class="pd10">
          <p>e-mansionにご登録されている以下のお支払い方法により、お支払いいただきます。</p>
          <h3>クレジットカード</h3>
          <table class="table-type2">
            <tbody>
              <tr>
                <th class="va-middle">カード番号</th>
                <td>{{ maskedCardNumberFromIsp }}</td>
              </tr>
              <tr>
                <th class="va-middle">カード期限</th>
                <td>{{ cardExpiredMonthOnPortas }}月 ／ {{ cardExpiredYearOnPortas }}年</td>
              </tr>
              <tr v-if="!isIspCardHolderName">
                <td>
                  <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
                </td>
                <td>
                  <div class="mt10">
                    <input
                      type="text"
                      class="credit-card-text"
                      v-model.trim="cardholderNameFormForAuthorizeIspCard"
                      required
                      minlength="2"
                      maxlength="45"
                      @change="inputCardholderNameFormForAuthorizeIspCard"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="blc">
        <div class="btn-area" v-if="is3dsecure">
          <p class="border-grey bg-grey pd20 mt40 ml40">
            ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
            ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
          </p>
        </div>
        <p class="form-btn-txt">修正がある場合は、「戻る」をクリックしてください。</p>
        <p class="form-btn-txt"><b>ご確認の上、よろしければ「申し込む」をクリックしてください。</b></p>
        <div v-if="is3dsecure" class="my-button-area">
          <div class="has-checkbox">
            <div class="my-checkbox-outer">
              <input id="checkbox-to-agree" type="checkbox" v-model="isAgreedPrivacyPolicy" />
              <p class="my-checkbox-text"><label for="checkbox-to-agree">各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label></p>
            </div>
          </div>
        </div>
        <div class="btn-area">
          <button class="btn btn04 bs" v-on:click="goBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button type="button" class="btn btn01 bs" v-if="is3dsecure" :class="{ 'disabled-button': isDisabledButton }" v-on:click="onApply" :disabled="isDisabledButton">
            申し込む<i class="material-icons link link-icon">east</i>
          </button>
          <button type="button" v-if="!is3dsecure && slimPlan" class="btn btn01 bs" :class="{ emansionoffersubaccount: !canApply }" :disabled="!canApply" v-on:click="onApply">
            申し込む<i class="material-icons link link-icon">east</i>
          </button>
          <button class="btn btn01 bs" v-if="!slimPlan" v-on:click="onApply()">申し込む<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
  <!-- /contents -->
</template>

<style lang="scss" scoped>
.p-margin-top {
  margin-top: 10px;
}

.card-name {
  width: 100%;
  max-width: 360px;
}

div.my-button-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  text-align: center;
  padding-top: 35px;

  & div.has-checkbox {
    padding-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 10px;
      left: 0;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  div.my-button-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    margin-top: 16px;

    & div.has-checkbox {
      padding-top: 0;

      & div.my-checkbox-outer {
        top: 0;
        left: 50%;
        text-align: left;

        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1275px) {
  div.my-button-area {
    padding-top: 16px;
  }
}

.emansionoffersubaccount {
  background-color: #ed7d89;
}

.disabled-button {
  background-color: #ed7d89;
}

.btn01:disabled {
  background-color: #ed7d89;
}

.credit-card-text {
  width: 100%;
  height: 38px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 10px 0 10px;
  border: #cacaca solid 1px;
  margin-top: 0;
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  font-family: 'notosansjp';
  border-radius: 2px;
  max-width: 360px;
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiPaymentStatusAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-payment-status-accessor';
import { SpfApiSubAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-sub-account-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { EMansionPaymentStatusInfo } from '@/shared/classes/spf-api/mail/model/common/e-mansion-payment-status';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';
import { E_MANSION_PAYMENT_STATUS } from '@/shared/const/e-mansion/e-mansion-payment-status';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { inputEventConvertToUpper } from '@/shared/util/inputEventConvertToUpper';
import { checkRouterError } from '@/shared/util/router-navigation-func';

class SubAccountAddInfo {
  public mailAddress?: string;
  public mailPassword?: string;
  public popServer?: string;
  public smtpServer?: string;
  public imapServer?: string;
  public mailSubdomain?: string;
  public resultMessage?: Array<string>;
  public isAgreedPrivacyPolicy?: boolean;
  /** 連携先ISPで登録されたクレジットカードのマスク済カード番号 */
  public maskedCardNumberFromIsp?: string;
  /** Portasで登録されたクレジットカードの有効期限(月) */
  public cardExpiredMonthOnPortas?: string;
  /** Portasで登録されたクレジットカードの有効期限(年) */
  public cardExpiredYearOnPortas?: string;
  public creditCardFlag?: boolean;

  constructor(partial: Partial<SubAccountAddInfo>) {
    Object.assign(this, partial);
  }
}

export default defineComponent({
  name: 'confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    desiredAccount: '',
    usageFee: '',
    subAccountAddInfo: null as SubAccountAddInfo | null,
    slimPlan: false,
    tax: '',
    mailAddress: '',
    mailAddressDomain: '',
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
    isAgreedPrivacyPolicy: false,
    maskedCardNumberFromIsp: '',
    cardExpiredMonthOnPortas: '',
    cardExpiredYearOnPortas: '',
    creditCardFlag: false,
    cardholderName: '',
    errorMessages: new Array<string>(),
    member: null as Member | null,
    paymentInfo: null as EMansionPaymentStatusInfo | null,
    CANT_APPLY_MESSAGE: 'お支払情報のご登録が必要です',
    canApplyErrorCheck: false, // エラー出力するかの判断

    emOpMailFlag: '',
    domainFlag: '',
    originalDomainName: '',
    servicePlanType: '',
    processDate: new Date(),
    is3dsecure: false, // クレジットカードの判定
    canApply: false, //お支払情報登録有無
    isIspCardHolderName: false, // ISPにカード名義人名が登録されているか
    cardholderNameFormForAuthorizeIspCard: '',
    /** e-mantion の URL */
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    /** apid */
    apid: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // e-mansionを退会・退会申請していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
    if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
      this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
      this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
      //ストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);
    }

    try {
      this.tax = this.$store.getters['taxStore/tax'];

      //不正な遷移
      if (!this.$store.getters['eMansionSubAccountStore/canApply']) {
        await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      this.desiredAccount = this.$store.getters['eMansionSubAccountStore/desiredAccount'];
      //リロードで表示する値がなくなったときは利用状況・入力画面に戻す
      if (!this.desiredAccount) {
        await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      if (this.$store.getters['servicePlanTypeStore/servicePlanType'] == SERVICE_PLAN_TYPE.EM_SLIM) {
        this.slimPlan = true;
      }
      if (this.$store.getters['servicePlanTypeStore/servicePlanType'] == SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL) {
        this.slimPlan = true;
      }
      const emOpMailFlag = this.$store.getters['eMansionCommonStore/property'].em_op_mail_flag;
      this.mailAddress = this.desiredAccount + '@' + process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN;
      if (hasEMansionOriginalDomain(emOpMailFlag)) {
        this.mailAddressDomain = this.$store.getters['eMansionCommonStore/property'].original_domain_name;
        this.mailAddress = this.desiredAccount + '@' + this.mailAddressDomain;
      }

      if (this.slimPlan) {
        // 会員情報をStoreから取得
        this.member = this.$store.getters['memberStore/member'];

        // お支払い情報登録状態を取得
        this.paymentInfo = await SpfApiPaymentStatusAccessor.getPaymentStatus(this.member?.id ?? -1);

        this.usageFee = this.$store.getters['eMansionSubAccountStore/usageFee'];
        // usageFeeは0円が有り得る為、未設定であることはundefinedで判定する
        if (this.usageFee === undefined) {
          await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
            checkRouterError(error);
          });
        }

        if (!this.paymentInfo) {
          await this.$router.push('/e-mansion/error').catch((error: any) => {
            checkRouterError(error);
          });
          this.isLoading = false;
          return;
        }
        // お支払い情報登録状態チェック
        await this.checkPaymentInfo(this.paymentInfo.status);

        // お支払い情報登録状態が未登録の場合のエラーメッセージ
        if (!this.canApplyErrorCheck) {
          this.errorMessages.push(this.CANT_APPLY_MESSAGE);
          this.isLoading = false;
          return;
        }
      }

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    /** 戻るボタン押下時： 追加アカウント利用状況画面に遷移する */
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      //Storeにご希望アカウントを保存
      this.$store.commit('eMansionSubAccountStore/desiredAccount', this.desiredAccount);
      await this.$router.push('/e-mansion/sub-account/list').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },

    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },

    /** 申し込むボタン押下時：追加アカウント完了(お申し込み)画面に遷移する */
    async onApply() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      const emOpMailFlag = this.$store.getters['eMansionCommonStore/property'].em_op_mail_flag;
      const originalDomainName = this.$store.getters['eMansionCommonStore/property'].original_domain_name;
      const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      const processDate = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      this.servicePlanType = servicePlanType;
      this.emOpMailFlag = emOpMailFlag;
      this.originalDomainName = originalDomainName;
      this.processDate = processDate;

      if (this.is3dsecure) {
        if (
          !this.isIspCardHolderName &&
          (!this.cardholderNameFormForAuthorizeIspCard || this.cardholderNameFormForAuthorizeIspCard?.length < 2 || this.cardholderNameFormForAuthorizeIspCard?.length > 45)
        ) {
          this.errorMessages.push('カード名義人を正しく入力してください。');
          this.isLoading = false;
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }

        // 申込内容一時保存用 UUIDを生成
        const uuidForTemporarySavingApplicationData = uuidv4();

        const memberId = this.$store.getters['memberStore/member'].id;

        const paymentMethodApplicationData = {
          memberId: Number(memberId),
          choiceAccount: this.desiredAccount,
          memberType: this.servicePlanType,
          domainFlag: this.emOpMailFlag,
          domainName: this.originalDomainName,
          date: this.processDate,
          desiredAccount: this.desiredAccount,
          usageFee: this.usageFee,
        };

        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'e-mansion-sub-account-apply',
        });

        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
        }
        // 3dセキュア対応用 uuid localStorageに保存
        localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

        const member: Member = this.$store.getters['memberStore/member'];
        if (uuidForTemporarySavingApplicationData !== null) {
          let cardHolderNameForAuthorize: string;
          if (this.cardholderName) {
            cardHolderNameForAuthorize = this.cardholderName;
          } else {
            cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
          }

          // VeriTrans_本人認証用のデータ格納
          const dddSecureAuthStartWithVeriTransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
            uuid: uuidForTemporarySavingApplicationData,
            externalVeritransAccountId: member.eTncMemberId,
            cardholderName: cardHolderNameForAuthorize,
          });

          // VeriTrans_本人認証(VeriTrans会員ID使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeriTransAccountIdRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );

              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

              this.isAgreedPrivacyPolicy = false;
              this.isLoading = false;

              // エラーメッセージを見せるために画面最上部にスクロール
              window.scrollTo(0, 0);
              return;
            } else {
              await this.$router.push('/e-mansion/error').catch((error: any) => {
                checkRouterError(error);
              });
              return;
            }
          }
        }
      } else {
        try {
          const memberId = this.$store.getters['memberStore/member'].id;
          const subAccountAddInfo = await SpfApiSubAccountAccessor.addSubAccount(
            memberId,
            this.desiredAccount,
            process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN,
            servicePlanType,
            emOpMailFlag,
            originalDomainName,
            processDate
          );

          this.subAccountAddInfo = new SubAccountAddInfo({
            mailAddress: subAccountAddInfo.mailAddress,
            mailPassword: subAccountAddInfo.mailPassword,
            popServer: subAccountAddInfo.popServer,
            smtpServer: subAccountAddInfo.smtpServer,
            imapServer: subAccountAddInfo.imapServer,
            mailSubdomain: subAccountAddInfo.mailSubdomain,
            resultMessage: subAccountAddInfo.resultMessage,
          });
        } catch (error) {
          // エラー画面に遷移
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }

        if (this.subAccountAddInfo && this.subAccountAddInfo.resultMessage && this.subAccountAddInfo.resultMessage.length > 0) {
          this.errorMessages = this.subAccountAddInfo.resultMessage;
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }

        /**次画面に渡すために保存*/
        //ご希望アカウント
        this.$store.commit('eMansionSubAccountStore/desiredAccount', this.desiredAccount);
        //追加メールアドレス
        this.$store.commit('eMansionSubAccountStore/mailAddress', this.subAccountAddInfo.mailAddress);
        //メールパスワード
        this.$store.commit('eMansionSubAccountStore/mailPassword', this.subAccountAddInfo.mailPassword);
        //POPサーバー
        this.$store.commit('eMansionSubAccountStore/popServer', this.subAccountAddInfo.popServer);
        //SMTPサーバー
        this.$store.commit('eMansionSubAccountStore/smtpServer', this.subAccountAddInfo.smtpServer);
        //IMAPサーバー
        this.$store.commit('eMansionSubAccountStore/imapServer', this.subAccountAddInfo.imapServer);
        //メールサブドメイン
        this.$store.commit('eMansionSubAccountStore/mailSubdomain', this.subAccountAddInfo.mailSubdomain);
        await this.$router.push('/e-mansion/sub-account/complete').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
      }
    },

    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];

      if (!member || !member.primaryKeyMye) {
        return true;
      }

      if (property?.apartmentId) {
        this.apid = convert6DigitIdTo8DigitId(property?.apartmentId);
      }

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      return false;
    },

    async checkPaymentInfo(paymentInfoStatus: E_MANSION_PAYMENT_STATUS): Promise<boolean> {
      // お支払情報があった場合の処理
      const property: Property = this.$store.getters['propertyStore/property'];
      const member: Member | null = this.$store.getters['memberStore/member'];
      if (!member || !member.primaryKeyMye) {
        return true;
      }
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });
      const eMansionMemberId = member.primaryKeyMye;
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      // 支払方法がクレジットカードの場合
      if (eMansionCustomer instanceof EMansionCustomer && eMansionCustomer.payment_id === '8') {
        this.canApplyErrorCheck = true;
        this.is3dsecure = true;
        if (member.eTncMemberId) {
          const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
          this.maskedCardNumberFromIsp = this.addHyphenToCardNumber(cardInfo.cardNumber);
          this.cardholderName = cardInfo.cardholderName ?? '';
          // クレジットカードの年月取得
          this.cardExpiredMonthOnPortas = cardInfo.cardExpire.slice(0, 2);
          this.cardExpiredYearOnPortas = cardInfo.cardExpire.slice(-2);
          // カード名義人判定
          if (cardInfo.cardholderName) {
            this.isIspCardHolderName = true;
          }
        }
      } else {
        //支払方法がクレジットカード以外の場合
        this.canApply = paymentInfoStatus == E_MANSION_PAYMENT_STATUS.REGISTERED;
        if (this.canApply) {
          this.canApplyErrorCheck = true;
        }
      }

      return false;
    },

    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },
    inputCardholderNameFormForAuthorizeIspCard(event: Event) {
      this.cardholderNameFormForAuthorizeIspCard = inputEventConvertToUpper(event);
    },
  },

  computed: {
    isDisabledButton() {
      return !this.isAgreedPrivacyPolicy;
    },
  },
  watch: {
    cardholderNameFormForAuthorizeIspCard: {
      handler: function (newVal, oldVal) {
        if (newVal && !newVal.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.cardholderNameFormForAuthorizeIspCard = oldVal;
        }
      },
    },
  },
});
</script>
