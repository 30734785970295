<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <main class="underlayer-main">
      <h1>Webスペース容量変更 確認</h1>
    </main>
    <!-- /underlayer-main -->

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link></li>
        <li>Webスペース容量変更</li>
      </ul>
      <!-- /breadcrumb -->

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Webスペース容量変更" />Webスペース容量変更&emsp;確認</h2>
        <p>お申し込み内容をご確認いただき、問題ない場合は「申し込む」ボタンを押してください。</p>
      </div>
      <!-- /blc -->

      <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

      <h3 class="service-h3">お申し込み内容</h3>
      <div class="sblc">
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">アカウントID</th>
              <td>{{ accountName }}</td>
            </tr>
            <tr>
              <th class="va-middle">メールアドレス</th>
              <td>{{ mailAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">ホームページアドレス</th>
              <td>{{ homepageAddress }}</td>
            </tr>
            <tr>
              <th class="va-middle">現在の容量</th>
              <td>{{ webSpaceCapacity }}.0MB</td>
            </tr>
            <tr>
              <th class="va-middle">追加／縮小容量</th>
              <td>
                <b>{{ displayAfterChangeWebSpaceCapacityLabel }}</b>
              </td>
            </tr>
            <tr>
              <th class="va-middle">月額利用料</th>
              <td>10.0MB 毎に月額{{ unitPrice }}円（税込）<br />最大{{ webSpaceMaxCapacity }}.0MBまでご利用いただけます。</td>
            </tr>
            <tr>
              <th class="va-middle">お申し込み日</th>
              <td>{{ applicationDate }}</td>
            </tr>
            <tr>
              <th class="va-middle">サービス説明</th>
              <td v-html="serviceDescription"></td>
            </tr>
          </tbody>
        </table>
        <!-- blc -->
        <h3 v-if="is3dsecure" class="service-h3">UCOM光 レジデンスサービス お支払い方法</h3>
        <div v-if="is3dsecure">
          <div class="pd10 ml10">
            <p>UCOM光 レジデンスにご登録されている以下のお支払い方法により、お支払いいただきます。</p>
            <h3>クレジットカード</h3>
            <table v-if="is3dsecure" class="table-type2">
              <tbody>
                <tr>
                  <th class="va-middle">カード番号</th>
                  <td>{{ maskedCardNumberFromIsp }}</td>
                </tr>
                <tr>
                  <th class="va-middle">カード期限</th>
                  <td>{{ cardExpiredMonthOnPortas }}月 ／ {{ cardExpiredYearOnPortas }}年</td>
                </tr>
                <tr v-if="!isIspCardHolderName">
                  <td>
                    <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
                  </td>
                  <td>
                    <div class="mt10">
                      <input
                        type="text"
                        class="text card-name"
                        v-model.trim="cardholderNameFormForAuthorizeIspCard"
                        required
                        minlength="2"
                        maxlength="45"
                        @change="inputCardholderNameFormForAuthorizeIspCard"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /sblc -->

      <div class="blc">
        <p v-if="is3dsecure" class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
        </p>
        <p class="form-btn-txt mt40">「申し込む」を押してください。完了画面へ進みます。</p>
        <div>
          <div class="btn-area">
            <div>
              <button class="btn btn04 bs" v-on:click="onBack()">
                <i class="material-icons link link-icon">west</i>
                戻る
              </button>
            </div>
            <div class="has-checkbox">
              <button class="btn btn01 bs" type="button" :class="[isDisabledButton ? 'btn05' : 'btn01']" :disabled="isDisabledButton" v-on:click="onApply()">
                申し込む
                <i class="material-icons link link-icon">east</i>
              </button>
              <div v-if="is3dsecure" class="my-checkbox-outer">
                <input id="checkbox-to-agree" type="checkbox" v-model="isAgreeTerms" />
                <p class="my-checkbox-text"><label for="checkbox-to-agree">各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /blc -->
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<style lang="scss" scoped>
.card-name {
  width: 100%;
  max-width: 360px;
}

div.btn-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  text-align: center;
  padding-top: 72px;

  & div.has-checkbox {
    padding-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 10px;
      left: 0;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  div.btn-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 64px;
    margin-top: 16px;

    & div.has-checkbox {
      padding-top: 0;

      & div.my-checkbox-outer {
        top: 0;
        left: 50%;
        text-align: left;

        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1275px) {
  div.my-button-area {
    padding-top: 16px;
  }
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { UcomErrorResponse } from '../classes/external-api/error-response';
import { UcomPaymentStatusResponse } from '../classes/external-api/payment-status-response';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiWebSpaceAccessor } from '@/infra/accessor/spf/ucom/spf-api-web-space-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { UcomWebSpaceCapacityUpdate } from '@/shared/classes/spf-api/mail/model/ucom/web-space/ucom-web-space-capacity-update';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { UCOM_PAYMENT_METHOD_ID } from '@/shared/const/ucom/ucom-payment-method-id';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomExternalApiService } from '@/shared/services/external-api/ucom-external-api-service';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { inputEventConvertToUpper } from '@/shared/util/inputEventConvertToUpper';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { Property } from '@/shared/classes/spf-api/property';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';

export default defineComponent({
  name: 'web-space-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },

  data: () => ({
    /** 会員情報 */
    member: null as Member | null,
    /** COM_アカウントメールID */
    ucomAccountMailId: '',
    /** アカウントID (=アカウントID(表示用)) */
    accountName: '',
    /** メールアドレス */
    mailAddress: '',
    /** ホームページアドレス */
    homepageAddress: '',
    /** 現在のWebスペース容量 */
    webSpaceCapacity: '',
    /** 追加/縮小するWebスペース容量リスト */
    changeCapacityList: [],
    /** Webスペース最大容量 */
    webSpaceMaxCapacity: '',
    /** 追加/縮小するWebスペース容量のLabel */
    afterChangeWebSpaceCapacityLabel: '',
    /** 表示用追加/縮小するWebスペース容量のLabel */
    displayAfterChangeWebSpaceCapacityLabel: '',
    /** 追加/縮小するWebスペース容量のValue Hidden */
    afterChangeWebSpaceCapacityValue: '',
    /** 月額利用料 */
    unitPrice: 0,
    /** お支払情報登録有無 */
    paymentStatus: true,
    /** お申し込み日 */
    applicationDate: '',
    /** サービス説明 */
    serviceDescription: '',
    /** VeriTrans会員ID */
    portasVeriTransId: '',
    /** ISPに登録された支払い方法 */
    paymentId: '',
    /** クレジットカードの判定 */
    is3dsecure: false,
    /** ISPにカード名義人名が登録されているか */
    isIspCardHolderName: false,
    /** カード名義人名 */
    cardholderName: '' as string | undefined,
    /** エラーメッセージ */
    errorMessages: new Array<string>(),
    /** ローディング */
    isLoading: false,
    /** エラーメッセージ部タイトル */
    errorMessageTitle: '',
    /** 規約に同意する */
    isAgreeTerms: false,
    /** 連携先ISPで登録されたクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** Portasで登録されたクレジットカードの有効期限(月) */
    cardExpiredMonthOnPortas: '',
    /** Portasで登録されたクレジットカードの有効期限(年) */
    cardExpiredYearOnPortas: '',
    ucomVeriTransAccountId: '',
    cardholderNameFormForAuthorizeIspCard: '',
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    apid: '',
  }),
  async mounted() {
    this.isLoading = true;

    // ログイン確認
    const isLoggedIn = await AuthService.isLoggedIn();

    if (!isLoggedIn) {
      // ログインしていなければPortas総合トップ画面へ遷移する
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // UCOMを退会していないか確認
    if (await this.isAccountDeactivated()) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // 物件基本情報の物件IDを取得してapidへ変換
    const property: Property | null = this.$store.getters['propertyStore/property'];
    if (property?.apartmentId) {
      this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
    }

    // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
    if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
      this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
      this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
      //ストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);
    }

    await this.checkPaymentInfo();

    // Storeからデータを取得しセット
    this.paymentStatus = this.$store.getters['ucomWebSpaceStore/paymentStatus'];
    this.accountName = this.$store.getters['ucomWebSpaceStore/accountName'];
    this.mailAddress = this.$store.getters['ucomWebSpaceStore/mailAddress'];
    this.homepageAddress = this.$store.getters['ucomWebSpaceStore/homepageAddress'];
    this.webSpaceCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceCapacity'];
    this.changeCapacityList = this.$store.getters['ucomWebSpaceStore/changeCapacityList'];
    this.afterChangeWebSpaceCapacityLabel = this.$store.getters['ucomWebSpaceStore/changeCapacityLabel'];
    this.afterChangeWebSpaceCapacityValue = this.$store.getters['ucomWebSpaceStore/changeCapacityValue'];
    this.unitPrice = this.$store.getters['ucomWebSpaceStore/unitPrice'];
    this.webSpaceMaxCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceMaxCapacity'];
    this.applicationDate = this.$store.getters['ucomWebSpaceStore/applicationDate'];
    this.serviceDescription = this.$store.getters['ucomWebSpaceStore/serviceDescription'];

    // UCOM_アカウントメールIDをStoreから取得
    this.ucomAccountMailId = this.$store.getters['ucomWebSpaceStore/ucomAccountMailId'];

    // 戻るボタン押下時にstoreのucomAccountMailIdに値を再代入する(戻るボタンかブラウザバックによる画面遷移かを判別する為にundefinedを入れる)
    this.$store.commit('ucomWebSpaceStore/ucomAccountMailId', undefined);

    // UCOM_アカウントメールID(Store)を取得出来ない場合、前画面へ戻る
    if (!this.ucomAccountMailId) {
      await this.$router.push('/ucom/account-option-change/account-list').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    }

    // 追加/縮小容量を表示用に加工
    if (String(this.afterChangeWebSpaceCapacityValue.toString().charAt(0)) === '-') {
      this.displayAfterChangeWebSpaceCapacityLabel = this.afterChangeWebSpaceCapacityValue + 'MB';
      this.is3dsecure = false;
    }
    if (String(this.afterChangeWebSpaceCapacityValue.toString().charAt(0)) !== '-') {
      this.displayAfterChangeWebSpaceCapacityLabel = '+' + this.afterChangeWebSpaceCapacityValue + 'MB';
    }

    this.isLoading = false;
  },
  methods: {
    /**
     *  UCOMの状態が退会ではないか確認する
     *  退会状態だった場合、Portas会員専用トップ画面へ遷移する
     */
    async isAccountDeactivated() {
      this.member = this.$store.getters['memberStore/member'];
      if (this.member !== null) {
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        const customer = await this.$store.dispatch('ucomCommonStore/customer', this.member.primaryKeyUcom);
        const ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

        if (ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
          return true;
        }
      }
      return false;
    },

    // 「戻る」ボタン押下時の処理
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      this.$store.commit('ucomWebSpaceStore/paymentStatus', this.paymentStatus);
      this.$store.commit('ucomWebSpaceStore/ucomAccountMailId', this.ucomAccountMailId);
      this.$store.commit('ucomWebSpaceStore/accountName', this.accountName);
      this.$store.commit('ucomWebSpaceStore/mailAddress', this.mailAddress);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', this.homepageAddress);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', this.webSpaceCapacity);
      this.$store.commit('ucomWebSpaceStore/changeCapacityList', this.changeCapacityList);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', this.afterChangeWebSpaceCapacityLabel);
      this.$store.commit('ucomWebSpaceStore/changeCapacityValue', this.afterChangeWebSpaceCapacityValue);
      this.$store.commit('ucomWebSpaceStore/unitPrice', this.unitPrice);
      this.$store.commit('ucomWebSpaceStore/webSpaceMaxCapacity', this.webSpaceMaxCapacity);
      this.$store.commit('ucomWebSpaceStore/applicationDate', this.applicationDate);
      this.$store.commit('ucomWebSpaceStore/serviceDescription', this.serviceDescription);

      await this.$router.push('/ucom/web-space/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },

    // 「申し込む」ボタン押下時の処理
    async onApply(): Promise<void> {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      // エラーメッセージ初期化
      this.errorMessages = [];
      this.errorMessageTitle = '';

      // ログイン確認
      const isLoggedIn = await AuthService.isLoggedIn();

      if (!isLoggedIn) {
        // ログインしていなければPortas総合トップ画面へ遷移する
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // UCOMを退会していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 会員IDの取得
      const memberId = this.$store.getters['memberStore/member'].id;

      // 現在日付の取得
      const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
      const targetDate = this.formatDate(now);

      // UCOM にクレジットカード以外の支払い方法が登録済の場合、または容量増加の申し込みではない場合
      if (!this.is3dsecure) {
        try {
          const response = await SpfApiWebSpaceAccessor.updateUcomWebSpaceCapacity(
            memberId,
            this.ucomAccountMailId,
            this.afterChangeWebSpaceCapacityLabel,
            this.afterChangeWebSpaceCapacityValue,
            targetDate
          );

          if (response.errorMessages) {
            this.errorMessages = [];
            this.errorMessages.push(response.errorMessages);
            this.errorMessageTitle = '恐れ入りますが、内容をもう一度ご確認ください。';
            // エラーメッセージを見せるため画面最上部にスクロール
            await this.scrollPageTop();
            this.isLoading = false;
            return;
          }

          const accountName = response.accountName;
          const unitPrice = response.price;
          const processingDate = response.subscriptionDate;

          // 確認更新API レスポンスをstoreに保存
          this.saveStore(response, accountName, unitPrice, processingDate);

          // 完了画面へ遷移
          await this.$router.push('/ucom/web-space/complete').catch((error: any) => {
            checkRouterError(error);
          });
          this.isLoading = false;
        } catch (e) {
          await this.$router.push('/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
        // UCOM にクレジットカードが登録済の場合
      } else {
        if (
          !this.isIspCardHolderName &&
          (!this.cardholderNameFormForAuthorizeIspCard || this.cardholderNameFormForAuthorizeIspCard?.length < 2 || this.cardholderNameFormForAuthorizeIspCard?.length > 45)
        ) {
          this.errorMessages.push('カード名義人を正しく入力してください。');
          this.isLoading = false;
          this.isAgreeTerms = false;
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }
        // uuidを発行
        const uuidForTemporarySavingApplicationData = uuidv4();

        const request = {
          memberId: memberId,
          ucomAccountMailId: this.ucomAccountMailId,
          afterChangeWebSpaceCapacityLabel: this.afterChangeWebSpaceCapacityLabel,
          afterChangeWebSpaceCapacityValue: this.afterChangeWebSpaceCapacityValue,
          targetDate: targetDate,
          paymentStatus: this.paymentStatus,
          accountName: this.accountName,
          mailAddress: this.mailAddress,
          homepageAddress: this.homepageAddress,
          webSpaceCapacity: this.webSpaceCapacity,
          changeCapacityList: this.changeCapacityList,
          unitPrice: this.unitPrice,
          webSpaceMaxCapacity: this.webSpaceMaxCapacity,
          applicationDate: this.applicationDate,
          serviceDescription: this.serviceDescription,
        };

        const paymentMethodRegisterApplicationDataJson = JSON.stringify(request);

        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          subsequentProcess: 'ucom-web-space-apply',
          applicationDataJson: paymentMethodRegisterApplicationDataJson,
        });

        try {
          await this.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
        } catch {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
        }

        // uuidをローカルストレージへ保存
        localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

        let cardHolderNameForAuthorize: string;
        if (this.cardholderName) {
          cardHolderNameForAuthorize = this.cardholderName;
        } else {
          cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
        }

        // VeriTrans_本人認証(VeriTrans会員ID使用)APIへ渡すデータ
        const dddSecureAuthStartInfoWithVeritransAccountId = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
          uuid: uuidForTemporarySavingApplicationData,
          externalVeritransAccountId: this.ucomVeriTransAccountId,
          cardholderName: cardHolderNameForAuthorize,
        });

        // VeriTrans_本人認証(VeriTrans会員ID使用)API の実行
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartInfoWithVeritransAccountId);
          // VeriTrans会員IDの登録状況を更新するため、会員ストアの内容を初期化
          this.$store.commit('memberStore/member', null);

          location.href = authStartURL;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(VeriTrans会員ID使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.ucomUrl}/${this.apid}/" target="_blank">こちら</a>から、サポートセンターまでお問い合わせください。`
            );
            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
            this.isLoading = false;

            //チェックボックス非表示＆チェックを外す
            this.isAgreeTerms = false;

            window.scrollTo(0, 0);

            return;
          } else {
            throw error;
          }
        }
      }
    },
    /** 申込内容一時保存API の実行 */
    async temporarySavingApplicationData(applicationData: TemporarySavingApplicationDataRequest) {
      return await SpfApiService.temporarySavingApplicationData(applicationData);
    },
    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },
    // storeにデータを保存
    saveStore(response: UcomWebSpaceCapacityUpdate, accountName: string, unitPrice: string, processingDate: string): void {
      this.$store.commit('ucomWebSpaceStore/mailAddress', this.mailAddress);
      this.$store.commit('ucomWebSpaceStore/notifyMailAddress', response.notifyMailAddress);
      this.$store.commit('ucomWebSpaceStore/accountName', accountName);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', response.homepageAddress);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', response.webSpaceCapacity);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', response.changeCapacityLabel);
      this.$store.commit('ucomWebSpaceStore/unitPrice', unitPrice);
      this.$store.commit('ucomWebSpaceStore/applicationDate', processingDate);
      this.$store.commit('ucomWebSpaceStore/serviceDescription', response.serviceDescription);
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
    // ページトップへスクロール
    async scrollPageTop() {
      window.scrollTo(0, 0);
    },
    async checkPaymentInfo() {
      const member: Member = this.$store.getters['memberStore/member'];
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);

      if (customer.is_register_credit_card && member.primaryKeyUcom) {
        // (外部 UCOM) お支払い情報登録状態取得 API
        const paymentStatus: UcomPaymentStatusResponse | UcomErrorResponse = await UcomExternalApiService.getPaymentStatus(member.primaryKeyUcom);

        if (paymentStatus instanceof UcomPaymentStatusResponse && paymentStatus.payment_method_id === UCOM_PAYMENT_METHOD_ID.CREDIT_CARD) {
          this.ucomVeriTransAccountId = paymentStatus.card_account_id;
          this.is3dsecure = true;
          if (paymentStatus.card_account_id) {
            const cardInfo = await this.getPaymentMethodFromExternalIsp(paymentStatus.card_account_id);
            this.maskedCardNumberFromIsp = this.addHyphenToCardNumber(cardInfo.cardNumber);
            this.cardholderName = cardInfo.cardholderName;
            // クレジットカードの年月取得
            this.cardExpiredMonthOnPortas = cardInfo.cardExpire.slice(0, 2);
            this.cardExpiredYearOnPortas = cardInfo.cardExpire.slice(-2);
            // カード名義人判定
            if (cardInfo.cardholderName) {
              this.isIspCardHolderName = true;
            }
          }
        }
      }
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    inputCardholderNameFormForAuthorizeIspCard(event: Event) {
      this.cardholderNameFormForAuthorizeIspCard = inputEventConvertToUpper(event);
    },
  },
  computed: {
    // チェックボックスによる「申し込む」ボタンの判定
    isDisabledButton(): boolean {
      if (!this.is3dsecure) {
        return false;
      } else if (this.isAgreeTerms) {
        return false;
      }
      return true;
    },
  },
  watch: {
    cardholderNameFormForAuthorizeIspCard: {
      handler: function (newVal, oldVal) {
        if (newVal && !newVal.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.cardholderNameFormForAuthorizeIspCard = oldVal;
        }
      },
    },
  },
});
</script>
