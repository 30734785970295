import { Module } from 'vuex';
import { RootState } from '../index';
import { EMansionHikariPhoneUpdateResponseDto } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-option-dto';

/** e-mansion UCOM光電話 サービス変更API レスポンス State */
const eMansionUcomHikariPhoneOptionEntriedInfoState: {
  info: EMansionHikariPhoneUpdateResponseDto | undefined;
} = {
  info: undefined,
};

/** State の型 */
type eMansionUcomHikariPhoneOptionEntriedInfoState = typeof eMansionUcomHikariPhoneOptionEntriedInfoState;

/** e-mansion UCOM光電話 サービス変更API レスポンス  Store */
export const eMansionUcomHikariPhoneOptionEntriedInfoStore: Module<eMansionUcomHikariPhoneOptionEntriedInfoState, RootState> = {
  namespaced: true,
  state: eMansionUcomHikariPhoneOptionEntriedInfoState,
  actions: {},
  mutations: {
    /** UCOM光電話 サービス変更API レスポンス  */
    info: (state, value: EMansionHikariPhoneUpdateResponseDto) => (state.info = value),
  },
  getters: {
    /** UCOM光電話 サービス変更API レスポンス  */
    info: (state): EMansionHikariPhoneUpdateResponseDto | undefined => state.info,
  },
};
