<template>
  <div class="e-mansion-entry-input">
    <LoadingComponent v-if="isOnNextExecuting && isLoading" />
    <main class="underlayer-main">
      <h1>e-mansion ご入会お申し込み</h1>
    </main>

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>e-mansion ご入会お申し込み</li>
      </ul>
      <!-- /breadcrumb -->
      <!-- application-flow -->
      <ul class="application-flow grid pc-grid4 sp-grid3 gap10">
        <li class="stay">お客様情報入力</li>
        <li>接続コース選択</li>
        <li>確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:お客様情報入力 -->
      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <p>e-mansionご入会のお手続きを行います。</p>
        <p>必要な項目をご入力の上、画面下の「次へ」ボタンをクリックしてください。</p>
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お客様情報入力" />お客様情報入力</h2>
        <p>e-mansionをお申し込みいただくマンションのお部屋番号など、お客様情報をご入力ください。</p>

        <table class="table-type2">
          <tbody>
            <tr>
              <th>マンション名</th>
              <td>
                マンション名をご確認ください。<br /><b>{{ customer?.property_name }}</b>
              </td>
            </tr>
            <!--棟名-->
            <tr v-if="isFromRoomNumberInput && customer?.building_name && !isLoading">
              <th class="va-middle">棟名</th>
              <td>{{ customer?.building_name }}</td>
            </tr>
            <tr v-else-if="!isFromRoomNumberInput && buildingNameList.length > 0 && !isLoading">
              <th class="va-middle">棟名<span class="req">必須</span></th>
              <td>
                <select v-model="inputData.buildingId" class="form-select">
                  <option disabled value=""></option>
                  <option v-for="buildingNameItem in buildingNameList" :key="buildingNameItem.key" :value="buildingNameItem.key">
                    {{ buildingNameItem.value }}
                  </option>
                </select>
              </td>
            </tr>
            <tr v-if="isFromRoomNumberInput && !isLoading">
              <th>お部屋番号</th>
              <td>{{ customer?.room_number }}</td>
            </tr>
            <tr v-else-if="!isLoading">
              <th>お部屋番号 <span class="req">必須</span></th>
              <td>
                <p>半角数字でご入力ください。</p>
                <br /><input type="text" class="text short" placeholder="101" maxlength="8" v-model="inputData.roomNumber" />
              </td>
            </tr>
            <tr>
              <th>お名前</th>
              <td>{{ customer?.name_surname }} {{ customer?.name_first_name }}</td>
            </tr>
            <tr>
              <th>フリガナ</th>
              <td>{{ customer?.kana_surname }} {{ customer?.kana_first_name }}</td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td>
                {{ formatDateJapanese(customer?.birthday) }}
              </td>
            </tr>
            <tr>
              <th class="red">ご連絡先お電話番号<span class="req">必須</span></th>
              <td>
                <input type="text" class="text short" placeholder="090" v-model="inputData.phoneNumber1st" maxlength="4" />　-　<input
                  type="text"
                  class="text short"
                  placeholder="1234"
                  v-model="inputData.phoneNumber2nd"
                  maxlength="4"
                />　-　<input type="text" class="text short" placeholder="5678" maxlength="4" v-model="inputData.phoneNumber3rd" />
              </td>
            </tr>
            <tr>
              <th>e-mansionからの<br />ご連絡先メールアドレス</th>
              <td>
                <p>お申し込み内容やサービス情報等、弊社からメールでご連絡いたします。</p>

                {{ customer?.email_address }}<br />
              </td>
            </tr>
            <tr>
              <th>ご入居予定日</th>
              <!--カレンダーで選択-->
              <td>
                <input type="date" class="text middle display-date" placeholder="西暦" max="9999-12-31" v-model="inputData.scheduledMoveInOnDate" @blur="validateDateInput" />
              </td>
            </tr>
            <tr v-if="!isSlimPlan">
              <th rowspan="4">書類のご希望送付先　<span class="req">必須</span></th>
              <td v-if="!isBeforeSin">
                <label><input type="radio" v-model="destination" value="1" />ご契約マンション住所</label
                ><label><input type="radio" v-model="destination" value="2" />その他の住所</label>
              </td>
            </tr>
            <tr v-if="(destination === '2' || isBeforeSin) && !isSlimPlan">
              <td>
                〒<input type="text" class="text short" placeholder="000" maxlength="3" v-model="inputData.deliveryZipcodeFormer" />
                <span> - </span>
                <input type="text" class="text short" placeholder="0000" maxlength="4" v-model="inputData.deliveryZipcodeLatter" />
                <button @click="searchAddressByZipcode()" class="apartment-search-button btn btn01">住所検索</button>
              </td>
            </tr>
            <tr v-if="(destination === '2' || isBeforeSin) && !isSlimPlan">
              <td>
                <p class="form-description">ご住所（市町村名や番地まで入力してください）</p>

                <input type="text" class="text middle" v-model="inputData.destinationAddress1" placeholder="例）東京都千代田区大手町２－２－１（全角３０文字以内）" />
              </td>
            </tr>
            <tr v-if="(destination === '2' || isBeforeSin) && !isSlimPlan">
              <td>
                <p class="form-description">ご住所（マンション名お部屋番号などがあれば入力してください）</p>

                <input type="text" class="text middle" v-model="inputData.destinationAddress2" placeholder="例）つなぐマンション６２０号室（全角６０文字以内）" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- /blc -->

      <div class="sblc">
        <p class="form-btn-txt">ご確認の上、よろしければ「次へ」ボタンをクリックしてください。</p>
        <div class="btn-area">
          <button type="button" class="btn btn05 bs" v-on:click="onBack()">戻る<i class="material-icons link link-icon">west</i></button>
          <button type="button" class="btn btn01 bs" v-on:click="onNext()" :disabled="isDisabled">次へ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>

    <!-- /blc -->
  </div>
  <!-- /contents -->
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}

.fix-btn {
  float: right;
  color: #939393;
  border: 1px solid #939393;
  margin-top: -40px;
}

.sbtn {
  min-width: 80px;
  line-height: 26px;
  text-align: center;
  font-size: 13px;
  border-radius: 2px;
  margin-top: -40px;
  padding: 0 10px;
  background-color: #ffffff;
}

.sbtn:hover {
  opacity: 0.7;
}

.course-list {
  margin: 10px 0;
  border-top: 1px solid #d3d3d3;
}

.course-list .pc-grid2 dl:nth-child(odd) {
  border-right: 1px solid #d3d3d3;
}

.course-list dl {
  padding: 10px 20px;
  margin: 0;
  border-bottom: 1px solid #d3d3d3;
  word-break: break-all;
}

.course-list dl dt {
  font-weight: bold;
  float: left;
}

.course-list dl dd {
  padding-left: 180px;
}

.option-btn {
  color: #939393;
  border: 1px solid #939393;
  margin-top: 10px;
}

.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}
input.display-date {
  width: 150px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #ffffff;
}
dd .barth-date {
  margin-right: 2%;
}
input.text.middle {
  width: 500px;
}
/* お申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
@media only screen and (max-width: 767px) {
  input.text.middle {
    width: 100%;
  }
  .apartment-search-button {
    min-width: unset;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
/** 使用するバリデーションの定義 */
import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { EMansionConnectixPropertyRooms } from '@/pages/connectix/e-mansion/classes/external-api/property-rooms-response';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import EMansionEntrySendDocumentsComponent from '@/shared/components/e-mansion/e-mansion-entry-send-documents-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { E_MANSION_MAIN_ACCOUNT_PLAN_TYPE } from '@/shared/const/e-mansion/e-mansion-main-account-plan-type';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { convertFullToHalf } from '@/shared/util/change-to-half-width-characters';
import { isValidMonthAndDatePairs } from '@/shared/util/func-is-valid-month-and-date-pairs';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { fullWidth, halfWidth } from '@/shared/util/validators';
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import { EMansionEntryInputForm } from './classes/entry-input-form';

/** Input コンポーネント */
export default defineComponent({
  name: 'e-mansion-entry-input',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
    EMansionEntrySendDocumentsComponent,
  },
  data: () => ({
    customer: null as EMansionEntryInputForm | null,
    property: null as EMansionProperty | null, // {} as EMansionProperty,
    /** 画面入力項目 */
    inputData: {
      /**棟ID */
      buildingId: '',
      /**部屋番号 */
      roomNumber: '',
      scheduledMoveInOnDate: '',
      phoneNumber1st: '',
      phoneNumber2nd: '',
      phoneNumber3rd: '',
      selectedRadio: '',
      inputMailAddress: '',
      inputMailAddressConfirm: '',
      deliveryZipcodeFormer: '',
      deliveryZipcodeLatter: '',
      destinationAddress1: '',
      destinationAddress2: '',
    },
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    isInvalidDate: false,
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    // onNextメソッドが実行中かどうか
    isOnNextExecuting: false,
    /** 画面読み込み中か否か */
    isLoading: true,
    /**部屋番号一覧 */
    propertyRooms: new Array<EMansionConnectixPropertyRooms>(),
    /** 棟のリスト */
    buildingNameList: new Array<{
      key: string;
      value: string;
      zipcode: string | undefined;
      address: string | undefined;
    }>(),
    /**前画面のパス */
    beforePath: '',
    /**部屋番号入力画面からの遷移か */
    isFromRoomNumberInput: false,
    /**書類のご希望送付先 */
    destination: '1',
    hasRoomMaster: false,
    isBeforeSin: false,
    isSlimPlan: false,
  }),
  /** バリデーション定義 */
  validations: {
    inputData: {
      buildingId: {
        required,
      },
      roomNumber: {
        required,
        halfWidth, // 半角英数字
        maxLength: maxLength(8), // 8文字以内
      },
      phoneNumber1st: {
        required,
      },
      phoneNumber2nd: {
        required,
      },
      phoneNumber3rd: {
        required,
      },
      inputMailAddress: {
        required,
        maxLength: maxLength(48),
      },
      inputMailAddressConfirm: {
        required,
      },
      deliveryZipcodeFormer: {
        required,
        maxLength: maxLength(3),
      },
      deliveryZipcodeLatter: {
        required,
        maxLength: maxLength(4),
      },
      destinationAddress1: {
        required,
        maxLength: maxLength(30),
        fullWidth,
      },
      destinationAddress2: {
        required,
        maxLength: maxLength(60),
        fullWidth,
      },
    },

    customer: {
      room_number: {
        required,
      },
      property_name: {
        required,
      },
      name_surname: {
        required,
      },
      name_first_name: {
        required,
      },
      kana_surname: {
        required,
      },
      kana_first_name: {
        required,
      },
      phone_number: {
        required,
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  async mounted() {
    // ログイン中のお客様情報が表示される
    try {
      // 会員情報取得処理
      const customer: EMansionEntryInputForm = this.$store.getters['eMansionEntryStore/entryInputForm'];
      this.customer = customer;
      // 物件情報の取得
      const params = {
        apartmentId: this.customer.property_id!,
        uaType: UA_TYPE.E_MANSION,
      };
      const property: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', params);
      if (property instanceof EMansionProperty) {
        this.property = property;
      } else {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.E_MANSION_API_PROPERTY_INFO);
      }
      // customer情報が無い場合、会員規約の同意をしてないことになる
      if (!(await MountedCheckService.canReadEMansionEntryScreen()) || !this.customer || !this.property) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      //SIN前か否か
      this.isBeforeSin = this.isPrevServiceInDay(property.em_sin_time);

      // スリムプラン会員か否か
      this.isSlimPlan = this.checkSlimPlan(this.property);

      // 会員情報の入力チェック
      if (this.v$.customer.$invalid) {
        // お客様情報の入力チェック
        if (this.v$.customer.property_name?.$invalid) {
          // 必須チェック
          if (this.v$.customer.property_name?.required.$invalid) {
            this.errorMessages.push(`マンション名の入力は必須です。`);
          }
        }
        if (this.v$.customer.name_surname?.$invalid || this.v$.customer.name_first_name?.$invalid) {
          // 必須チェック
          if (this.v$.customer.name_surname?.required.$invalid && this.v$.customer.name_first_name?.required.$invalid) {
            this.errorMessages.push(`お名前の入力は必須です。`);
          }
        }
        if (this.v$.customer.kana_surname?.$invalid || this.v$.customer.kana_first_name?.$invalid) {
          // 必須チェック
          if (this.v$.customer.kana_surname?.required.$invalid && this.v$.customer.kana_first_name?.required.$invalid) {
            this.errorMessages.push(`フリガナの入力は必須です。`);
          }
        }

        if (this.v$.customer.email_address?.$invalid) {
          // 必須チェック
          if (this.v$.customer.email_address?.required.$invalid) {
            this.errorMessages.push(`ご連絡先メールアドレスの入力は必須です。`);
          }
        }
        if (this.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          this.errorMessageTitle = '未入力の項目があります。マイページで登録をお願いいたします。';
          return;
        }
      }
      this.beforePath = this.$store.getters['eMansionEntryStore/beforePathInput'];
      this.isFromRoomNumberInput = this.beforePath === '/e-mansion/entry/room-number';

      const buildingList = await SpfApiService.searchBuildings(this.customer.property_id);
      for (const building of buildingList) {
        if (building.buildingName && building.buildingId !== '0000') {
          this.buildingNameList.push({ key: building.buildingId, value: building.buildingName, zipcode: building.zipCode, address: building.address });
        }
      }
      this.buildingNameList.sort((a, b) => a.key.localeCompare(b.key));

      // ストアに入っているデータをセットする
      if (this.customer) {
        if (!this.isFromRoomNumberInput) {
          this.inputData.roomNumber = this.customer?.room_number ?? '';
          if (this.buildingNameList.find((item) => item.key === this.customer?.building_id)?.key) {
            this.inputData.buildingId = this.customer?.building_id!;
          } else {
            //棟名のリストに無い場合はリセットする
            this.inputData.buildingId = '';
            this.customer.building_id = '';
          }
        }
        this.inputData.phoneNumber1st = this.customer?.phone_number?.split('-')[0] || '';
        this.inputData.phoneNumber2nd = this.customer?.phone_number?.split('-')[1] || '';
        this.inputData.phoneNumber3rd = this.customer?.phone_number?.split('-')[2] || '';
        this.setScheduledMoveInOn(this.customer?.scheduled_move_in_on ?? '');
        this.inputData.deliveryZipcodeFormer = this.customer?.destination_zipcode_former || '';
        this.inputData.deliveryZipcodeLatter = this.customer?.destination_zipcode_latter || '';
        this.destination = this.setDestinationKind(this.customer?.destination_kind);
        this.inputData.destinationAddress1 = this.customer?.destination_address1 || '';
        this.inputData.destinationAddress2 = this.customer?.destination_address2 || '';
      }
      this.isLoading = false;
    } catch (e: any) {
      await VueErrorHandler.handle(e, '');
      await this.$router.push('/e-mansion/error').catch((error) => {
        checkRouterError(error);
      });
    }
  },
  computed: {
    isDisabled() {
      // 部屋番号・棟
      if (!this.isFromRoomNumberInput) {
        this.inputData.roomNumber = convertFullToHalf(this.inputData.roomNumber);
        if (this.v$.inputData.roomNumber?.required.$invalid || this.v$.inputData.roomNumber.maxLength.$invalid || this.v$.inputData.roomNumber.halfWidth.$invalid) {
          return true;
        }
        if (this.buildingNameList.length > 0 && this.v$.inputData.buildingId?.required.$invalid) {
          return true;
        }
      }

      // 連絡先お電話番号
      const phoneNumber = this.getDestinationTelephone;
      if (
        phoneNumber === null ||
        this.v$.inputData.phoneNumber1st?.required.$invalid ||
        this.v$.inputData.phoneNumber2nd?.required.$invalid ||
        this.v$.inputData.phoneNumber3rd?.required.$invalid
      ) {
        return true;
      }

      //ご希望送付先
      if (this.destination == '2') {
        //郵便番号
        if (this.v$.inputData.deliveryZipcodeFormer?.required.$invalid || this.v$.inputData.deliveryZipcodeLatter?.required.$invalid) {
          return true;
        }

        //住所（市町村・番地）
        if (this.v$.inputData.destinationAddress1?.required.$invalid) {
          return true;
        }
      }
      return false;
    },
    getScheduledMoveInOn() {
      if (!this.inputData.scheduledMoveInOnDate) {
        return null;
      }
      return this.inputData.scheduledMoveInOnDate;
    },
    getDeliveryZipcode() {
      if (!this.inputData.deliveryZipcodeFormer || !this.inputData.deliveryZipcodeLatter) {
        return null;
      }

      const zipcode = String(this.inputData.deliveryZipcodeFormer) + '-' + String(this.inputData.deliveryZipcodeLatter);
      if (!zipcode.match(/^\d{3}-\d{4}$/)) {
        return null;
      }

      return zipcode;
    },
    /**
     * 書類送付先種別
     * 0:契約マンション 9:その他
     */
    getDestinationKind() {
      if (this.isBeforeSin) {
        return '9';
      }
      if (this.destination === '1') {
        return '0';
      }
      if (this.destination === '2') {
        return '9';
      }
      return undefined;
    },
    /**契約書面送付先種別
     * 1:郵送　3:電子交付（登録メールアドレス） 4:電子交付（指定）
     */
    getContractDestinationKind() {
      if (this.inputData.selectedRadio === '1') {
        return '1';
      }
      if (this.inputData.selectedRadio === '2') {
        return '3';
      }
      if (this.inputData.selectedRadio === '3') {
        return '4';
      }
      return undefined;
    },
    getDestinationTelephone() {
      if (!this.inputData.phoneNumber1st || !this.inputData.phoneNumber2nd || !this.inputData.phoneNumber3rd) {
        return null;
      }
      return String(this.inputData.phoneNumber1st) + '-' + String(this.inputData.phoneNumber2nd) + '-' + String(this.inputData.phoneNumber3rd);
    },
    apid() {
      if (this.property) {
        return this.property.IN_APID;
      } else {
        return '';
      }
    },
  },
  methods: {
    formatDateJapanese(birthday: string | undefined): string {
      if (!birthday) {
        return '';
      }
      const date = birthday.split('/');
      return `${date[0]}年${date[1]}月${date[2]}日`;
    },

    setScheduledMoveInOn(scheduledMoveInOn: string) {
      if (!scheduledMoveInOn) {
        return;
      }
      this.inputData.scheduledMoveInOnDate = scheduledMoveInOn;
    },

    setDestinationKind(destinationKind: string | undefined) {
      if (destinationKind == '0') {
        return '1';
      }
      if (destinationKind == '9') {
        return '2';
      }
      return '1';
    },
    setContractDestinationKind(selectedRadio: string | undefined) {
      if (selectedRadio === '1') {
        return '1';
      }
      if (selectedRadio === '3') {
        return '2';
      }
      if (selectedRadio === '4') {
        return '3';
      }
      return '';
    },

    isPrevServiceInDay(serviceInDate: string) {
      const today = new Date();
      const year = parseInt(serviceInDate.substring(0, 4), 10);
      const month = parseInt(serviceInDate.substring(4, 6), 10) - 1; // 月は0から始まるので-1
      const day = parseInt(serviceInDate.substring(6, 8), 10);
      const serviceInDay = new Date(year, month, day);
      return today < serviceInDay;
    },
    // スリムプラン会員か否かのチェック
    checkSlimPlan(property: EMansionProperty): boolean {
      const flags = property.apartment_special_type_flag[0];
      if (flags[E_MANSION_MAIN_ACCOUNT_PLAN_TYPE.SLIM_PLAN] === '1') {
        return true;
      }
      return false;
    },
    //コンポーネントから入力値を受け取る
    getInputDeliveryData(data: any) {
      this.inputData = data;
    },
    getErrorMessage(data: any) {
      this.errorMessages = [];
      this.errorMessages.push(data);
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      return;
    },
    /**
     * 入力値が無効な日付かチェック
     * @param event 入力イベント
     */
    validateDateInput(event: Event): void {
      //date型の入力の場合、不正な日付が入力されるとv-modelに値が入らないため
      //eventを使用してバリデーションチェックする
      const validationMessage = (event.target as HTMLInputElement).validationMessage;

      // エラーメッセージがある場合、不正な入力
      if (validationMessage) {
        this.isInvalidDate = true;
        return;
      } else {
        this.isInvalidDate = false;
        return;
      }
    },
    async searchAddressByZipcode() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const zipCode = `${this.inputData.deliveryZipcodeFormer}-${this.inputData.deliveryZipcodeLatter}`;
      this.errorMessages = [];
      const ZIP_CODE_REGEX = /^[0-9]{3}-[0-9]{4}$/;
      if (!this.inputData.deliveryZipcodeFormer || !this.inputData.deliveryZipcodeLatter) {
        this.errorMessages.push('「ご希望送付先郵便番号」が正しくありません。');
      } else if (!ZIP_CODE_REGEX.test(zipCode)) {
        this.errorMessages.push('「ご希望送付先郵便番号」が正しくありません。');
      }
      if (this.errorMessages.length > 0) {
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
      try {
        const resultList = await SpfApiService.searchAddresses(zipCode);
        if (resultList.length > 0) {
          const result = resultList[0];
          this.inputData.destinationAddress1 = `${result.address1}${result.address2}${result.address3}`;
        } else {
          this.errorMessages.push('「ご希望送付先郵便番号」が正しくありません。');
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      } catch (e) {
        // エラーのときは何もしない
      }
      this.isLoading = false;
    },
    /**
     * 入力内容をthis.customerに格納してストアに保存する
     */
    async setCustomerStore() {
      if (!this.customer) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      //棟名・棟ID・部屋番号
      //部屋番号入力画面を経由していない場合
      if (!this.isFromRoomNumberInput) {
        this.customer.room_number = this.inputData.roomNumber;
        //棟の入力がある場合
        if (this.inputData.buildingId) {
          this.customer.building_name = this.buildingNameList.find((item) => item.key === this.inputData.buildingId)?.value!;
          this.customer.building_id = this.inputData.buildingId;
        }
      }
      //電話番号
      this.customer.phone_number = this.getDestinationTelephone;

      //入居予定日
      this.customer.scheduled_move_in_on = this.getScheduledMoveInOn;
      if (!this.isSlimPlan) {
        // 書類送付先種別
        this.customer.destination_kind = this.getDestinationKind;
        //郵送の場合（入力値）
        if (this.customer.destination_kind === '9') {
          this.customer.destination_zipcode = this.getDeliveryZipcode;
          this.customer.destination_zipcode_former = this.inputData.deliveryZipcodeFormer;
          this.customer.destination_zipcode_latter = this.inputData.deliveryZipcodeLatter;
          this.customer.destination_address1 = this.inputData.destinationAddress1;
          this.customer.destination_address2 = this.inputData.destinationAddress2;
        } else if (!this.customer.building_id || this.customer.building_id === '0000') {
          //棟無しの場合→物件の住所
          this.customer.destination_zipcode = this.customer.zipcode;
          this.customer.destination_zipcode_former = this.customer.zipcode.split('-')[0];
          this.customer.destination_zipcode_latter = this.customer.zipcode.split('-')[1];
          this.customer.destination_address1 = this.customer.address;
        } else {
          //棟ありの場合→棟の住所
          const building = this.buildingNameList.find((item) => item.key === this.customer?.building_id);
          if (building?.zipcode && building?.address) {
            this.customer.destination_zipcode = building.zipcode;
            this.customer.destination_zipcode_former = building.zipcode.split('-')[0];
            this.customer.destination_zipcode_latter = building.zipcode.split('-')[1];
            this.customer.destination_address1 = building.address;
          } else {
            //棟の住所が無ければ物件の住所
            this.customer.destination_zipcode = this.customer.zipcode;
            this.customer.destination_zipcode_former = this.customer.zipcode.split('-')[0];
            this.customer.destination_zipcode_latter = this.customer.zipcode.split('-')[1];
            this.customer.destination_address1 = this.customer.address;
          }
        }
      }
      this.$store.commit('eMansionEntryStore/entryInputForm', this.customer);
    },
    /** 次へボタン押下時： 接続コース選択画面に遷移する */
    async onNext() {
      if (this.isOnNextExecuting) {
        return;
      }
      this.isOnNextExecuting = true;

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      // 入力チェック

      // 部屋番号・棟
      if (!this.isFromRoomNumberInput) {
        this.inputData.roomNumber = convertFullToHalf(this.inputData.roomNumber);
        if (this.v$.inputData.roomNumber?.required.$invalid || this.v$.inputData.roomNumber.maxLength.$invalid || this.v$.inputData.roomNumber.halfWidth.$invalid) {
          this.errorMessages.push('「お部屋番号」を半角英数字で入力してください。');
        }
        if (this.buildingNameList.length > 0 && this.v$.inputData.buildingId?.required.$invalid) {
          this.errorMessages.push('「棟名」を選択してください。');
        }
      }

      // 連絡先お電話番号
      const phoneNumber = this.getDestinationTelephone;
      if (
        phoneNumber === null ||
        this.v$.inputData.phoneNumber1st?.required.$invalid ||
        this.v$.inputData.phoneNumber2nd?.required.$invalid ||
        this.v$.inputData.phoneNumber3rd?.required.$invalid
      ) {
        this.errorMessages.push(`「ご連絡先お電話番号」を半角数字で入力してください。`);
      } else if (
        phoneNumber.length < 12 ||
        13 < phoneNumber.length ||
        !(phoneNumber.match(/^0120-[0-9]{1,4}-[0-9]{3,4}$/) || phoneNumber.match(/^[0-9]{1,4}-[0-9]{1,4}-[0-9]{4}$/))
      ) {
        this.errorMessages.push(`「ご連絡先お電話番号」を正しく入力してください。`);
      }

      //ご入居予定日
      if (this.inputData.scheduledMoveInOnDate) {
        const year = Number(this.inputData.scheduledMoveInOnDate.split('-')[0]);
        const month = Number(this.inputData.scheduledMoveInOnDate.split('-')[1]);
        const date = Number(this.inputData.scheduledMoveInOnDate.split('-')[2]);
        if (!isValidMonthAndDatePairs(year, month, date)) {
          this.errorMessages.push(`「ご入居予定日」を正しく入力してください。`);
        }
      }
      if (this.isInvalidDate) {
        this.errorMessages.push(`「ご入居予定日」を正しく入力してください。`);
      }
      //ご希望送付先
      if (this.destination == '2' || this.isBeforeSin) {
        //郵便番号
        if (this.v$.inputData.deliveryZipcodeFormer?.required.$invalid || this.v$.inputData.deliveryZipcodeLatter?.required.$invalid) {
          this.errorMessages.push(`「ご希望送付先　郵便番号」を半角数字で入力してください。`);
        } else if (this.getDeliveryZipcode === null) {
          this.errorMessages.push(`「ご希望送付先　郵便番号」を半角数字7文字で入力してください。`);
        }

        //住所（市町村・番地）
        if (this.v$.inputData.destinationAddress1?.required.$invalid) {
          this.errorMessages.push(`「ご希望送付先　ご住所(市町村、番地)」を全角で入力してください。`);
        } else if (this.v$.inputData.destinationAddress1.maxLength.$invalid || this.v$.inputData.destinationAddress1.fullWidth.$invalid) {
          this.errorMessages.push(`「ご希望送付先　ご住所(市町村、番地)」を全角30文字以内で入力してください。`);
        }
        //住所（マンション名など）
        if (this.v$.inputData.destinationAddress2.maxLength.$invalid || this.v$.inputData.destinationAddress2.fullWidth.$invalid) {
          this.errorMessages.push(`「ご希望送付先　ご住所(マンション名等)」を全角60文字以内で入力してください。`);
        }
      }

      if (this.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isOnNextExecuting = false;
        return;
      }

      try {
        this.setCustomerStore();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return await this.$router.push('/e-mansion/entry/connection-course').catch((error: any) => {
          checkRouterError(error);
        });
      } catch (error: any) {
        await VueErrorHandler.handle(error, '');
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
      }
      this.isOnNextExecuting = false;
    },
    /**前の画面（規約画面or部屋番号検索画面）に戻る */
    async onBack() {
      this.setCustomerStore();
      await this.$router.push(this.beforePath).catch((error: any) => {
        checkRouterError(error);
      });
    },
  },
});
</script>
