import { Module } from 'vuex';
import { RootState } from '../index';

/** e-mansion アカウント連携 State */
const eMansionMacAddressState: {
  eMansionMacAddressInput: string | null;
} = {
  eMansionMacAddressInput: null,
};

/** State の型 */
type EMansionMacAddressState = typeof eMansionMacAddressState;

/** e-mansion 共通系 Store */
export const eMansionMacAddressStore: Module<EMansionMacAddressState, RootState> = {
  namespaced: true,
  state: eMansionMacAddressState,
  actions: {},
  mutations: {
    macAddress: (state, value: string) => (state.eMansionMacAddressInput = value),
  },
  getters: {
    macAddress: (state): string | null => state.eMansionMacAddressInput,
  },
};
