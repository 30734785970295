<template>
  <!-- main-contents -->
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>ウイルスチェック</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><a href="/platform">トップページ</a></li>
        <li><a href="/ucom">UCOM光レジデンス会員専用トップ</a></li>
        <li>ウイルスチェック</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:ウイルスチェック-お支払方法未登録 -->
      <div class="blc" v-if="!isPaymentRegistered && !isLoading">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="お支払い方法未登録" />お支払い方法未登録</h2>
        <p>
          お支払方法が未登録のため、サービスの購入ができません。<br />
          <u>
            <a :href="paymentUrl" class="payment-url" target="_blank" rel="noopener noreferrer"> こちら </a>
          </u>
          からお支払方法の登録を行ってください。
        </p>
        <p>
          お支払い方法登録後、ブラウザの戻るボタンでUCOM光レジデンス会員専用トップに戻り<br />
          再度購入ご希望のサービスをご選択ください。
        </p>
      </div>
      <!-- blc:ウイルスチェック-お支払方法未登録 -->

      <template v-if="isPaymentRegistered">
        <!-- application-flow -->
        <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
          <li class="stay">入力</li>
          <li>確認</li>
          <li>完了</li>
        </ul>
        <!-- /application-flow -->

        <template v-if="isAdminLoggedIn">
          <!-- blc:ウイルスチェック購入／解約 -->
          <div class="blc">
            <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ウイルスチェック" />ウイルスチェック購入／解約</h2>
            <p>サービス内容をご確認いただき、「購入」または「解約」ボタンを押してください。</p>

            <ErrorMessagesComponent errorMessageTitle="恐れ入りますが、内容をもう一度ご確認ください。" :errorMessages="purchaseErrors" />

            <div v-if="virusCheck" class="sblc">
              <table class="table-type2">
                <tr>
                  <th class="va-middle">アカウントID</th>
                  <td>{{ virusCheck.accountName }}</td>
                </tr>
                <tr>
                  <th class="va-middle">メールアドレス</th>
                  <td>{{ virusCheck.mailAddress }}</td>
                </tr>
                <tr>
                  <th class="va-middle">現在のご契約状態</th>
                  <td>{{ registerStatus }}</td>
                </tr>
                <tr>
                  <th class="va-middle">月額利用料</th>
                  <td>
                    <span class="o">月額 {{ myNumberWithDelimiter(virusCheck.monthlyFee) }} 円 （税込）</span>
                  </td>
                </tr>
                <tr>
                  <th class="va-middle">サービス説明</th>
                  <td>
                    <span v-html="virusCheck.serviceDescriptionBeforePurchase"></span><br />UCOM 光 レジデンス ISP 会員付加サービス利用規約については<a
                      :href="termsUrl"
                      target="_blank"
                      class="link"
                      >こちら</a
                    >からご確認ください。
                  </td>
                </tr>
                <tr>
                  <th class="va-middle">サービス説明への同意<span class="req">必須</span></th>
                  <td>
                    <p class="o">お申し込みの前にサービス説明を必ずお読みになり、「上記の内容に同意する」にチェックしてください。</p>
                    <label><input type="checkbox" v-model="isAgreedOnPurchaseTerms" />上記の内容に同意する</label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- /blc -->

          <!-- blc -->
          <div class="blc">
            <div class="btn-area">
              <button class="btn bs" :class="[canPurchase ? 'btn01' : 'btn05']" :disabled="!canPurchase" @click="goToPurchase">
                購入<i class="material-icons link link-icon">east</i>
              </button>
              <button class="btn bs" :class="[canCancel ? 'btn01' : 'btn05']" :disabled="!canCancel" @click="goToCancel">
                解約<i class="material-icons link link-icon">east</i>
              </button>
            </div>
          </div>
          <!-- /blc -->
        </template>

        <!-- blc:ウイルスチェック一時停止／開始 -->
        <div class="blc" v-if="!virusCheck?.canChangePauseSetting()">
          <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ウイルスチェック一時停止開始" />ウイルスチェック一時停止／開始</h2>
          <p>ウイルスチェックサービスをご購入されていないため、サービスをご利用になれません。</p>
        </div>
        <!-- /blc -->

        <template v-if="virusCheck?.canChangePauseSetting()">
          <!-- blc -->
          <div class="blc">
            <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="ウイルスチェック一時停止開始" />ウイルスチェック一時停止／開始</h2>
            <p>ウイルスチェック設定を選択し、「次へ進む」ボタンを押してください。</p>

            <ErrorMessagesComponent errorMessageTitle="恐れ入りますが、内容をもう一度ご確認ください。" :errorMessages="pauseErrors" />

            <div class="sblc">
              <!-- <h3 class="service-h3">サービス内容</h3> -->
              <table class="table-type2">
                <tr>
                  <th class="va-middle">アカウントID</th>
                  <td>{{ virusCheck.accountName }}</td>
                </tr>
                <tr>
                  <th class="va-middle">メールアドレス</th>
                  <td>{{ virusCheck.mailAddress }}</td>
                </tr>
                <tr>
                  <th class="va-middle">ウイルスチェック設定</th>
                  <td>
                    <label><input type="radio" name="virusCheck" v-model="pauseAction" :value="2" checked />実施する</label>
                    <label><input type="radio" name="virusCheck" v-model="pauseAction" :value="1" />一時停止する</label>
                  </td>
                </tr>
                <tr>
                  <th class="va-middle">注意事項</th>
                  <td>
                    <ul class="ul-basic01">
                      <li>{{ pauseTerms }}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th class="va-middle">注意事項への同意<span class="req">必須</span></th>
                  <td>
                    <p class="o">お申し込みの前に注意事項を必ずお読みになり、「上記の内容に同意する」にチェックしてください。</p>
                    <label><input type="checkbox" v-model="isAgreedOnPauseTerms" />上記の内容に同意する</label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- /blc -->

          <!-- blc -->
          <div class="blc">
            <div class="btn-area">
              <button class="btn bs" :class="[isAgreedOnPauseTerms ? 'btn01' : 'btn05']" :disabled="!isAgreedOnPauseTerms" @click="goToPauseSetting">
                次へ進む<i class="material-icons link link-icon">east</i>
              </button>
            </div>
          </div>
          <!-- /blc -->
        </template>
      </template>
    </div>
    <!-- /contents -->
  </div>
  <!-- /main-contents -->
</template>

<script lang="ts">
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UCOM_ACCOUNT_AUTHORITY } from '@/shared/const/ucom/ucom-account-authority';
import { UCOM_PROPERTY_SERVICE_PROVID_FORMER } from '@/shared/const/ucom/ucom-property-service-provid-former';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import { SpfApiUcomVirusCheckAccessor } from '../../../infra/accessor/spf/ucom/spf-api-virus-check-accessor';
import { UcomVirusCheckInfo } from '../../../shared/classes/spf-api/model/ucom/ucom-virus-check-info';
import { UcomVirusCheckPauseState } from '../../../shared/classes/ucom/ucom-virus-check-pause-state';
import { UcomVirusCheckPurchaseState } from '../../../shared/classes/ucom/ucom-virus-check-purchase-state';
import { convertUcomPurchaseStatusById } from '../../../shared/const/ucom/ucom-purchase-status';
import { UCOM_REGISTERED_PAYMENT } from '../../../shared/const/ucom/ucom-registered-payment';
import { UcomVirusCheckPauseAction } from '../../../shared/const/ucom/ucom-virus-check-pause-action';
import { UCOM_VIRUS_CHECK_PAUSE_SETTING } from '../../../shared/const/ucom/ucom-virus-check-pause-setting';
import { UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE, UcomVirusCheckPurchaseSubscriptionType } from '../../../shared/const/ucom/ucom-virus-check-purchase-subscription-type';
import { numberWithDelimiter } from '../../../shared/util/vue-filters';

export default defineComponent({
  name: 'virus-check-input',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data(): {
    isMounted: boolean;
    isBusy: boolean;
    virusCheck: UcomVirusCheckInfo | undefined;
    purchaseErrors: string[];
    pauseErrors: string[];
    isAgreedOnPurchaseTerms: boolean;
    isAgreedOnPauseTerms: boolean;
    pauseAction: UcomVirusCheckPauseAction | undefined;
    pauseTerms: string;
    paymentUrl: string;
    isAdminLoggedIn: boolean;
    termsUrl: string;
  } {
    return {
      isMounted: false,
      isBusy: false,
      virusCheck: undefined,
      purchaseErrors: [],
      pauseErrors: [],
      isAgreedOnPurchaseTerms: false,
      isAgreedOnPauseTerms: false,
      pauseAction: undefined,
      pauseTerms: 'ウイルスチェックを一時停止している間も課金は通常通り発生しますのでご注意ください。',
      paymentUrl: process.env.VUE_APP_UCOM_MY_URL + process.env.VUE_APP_UCOM_PAYMENT_REGISTER_URL,
      isAdminLoggedIn: false,
      termsUrl: '',
    };
  },
  async mounted() {
    const ucomAccountId: string = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
    if (!ucomAccountId) {
      await this.$router.push('/ucom/account-option-change/account-list').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
    // ウイルスチェック初期表示情報を取得する
    try {
      this.virusCheck = await SpfApiUcomVirusCheckAccessor.findUcomVirusCheck(Number(ucomAccountId));
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
    // 「ウイルスチェック設定」ラジオボタンの初期値を設定する
    this.$data.pauseAction = this.convertPauseSetting(this.virusCheck?.virusCheckPauseSetting);
    //規約URLセット
    this.termsUrl = this.setTermsUrl();
    this.isAdminLoggedIn = this.getIsAdminLoggedIn();

    this.isMounted = true;
  },
  methods: {
    /**
     * サービス規約のURLをセット
     */
    setTermsUrl(): string {
      //物件基本情報
      const ucomProperty: UcomPropertyResponse | null = this.$store.getters['ucomCommonStore/property'];
      if (ucomProperty?.service_provid_former.service_provid_former_id == UCOM_PROPERTY_SERVICE_PROVID_FORMER.TNC.id) {
        return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_TNC;
      }
      return process.env.VUE_APP_UCOM_ISP_SERVICE_TERMS_URL_FOR_ARTERIA;
    },
    /** 「購入」ボタンのクリックハンドラ */
    async goToPurchase(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // バリデーション
      const error = this.validatePurchaseAgreement();
      if (error) {
        this.purchaseErrors = [error];
        this.isBusy = false;
        return;
      }

      // 購入/解約確認 画面に渡す情報を Store に保存する
      this.storePurchaseState(UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.PURCHASE);
      // 購入/解約確認 画面に遷移する
      await this.$router.push('/ucom/virus-check/purchase-confirm').catch((error) => {
        checkRouterError(error);
      });
      this.isBusy = false;
    },
    convertPauseSetting(pauseAction: number | undefined): UcomVirusCheckPauseAction | undefined {
      if (pauseAction == UCOM_VIRUS_CHECK_PAUSE_SETTING.PAUSED) {
        return UCOM_VIRUS_CHECK_PAUSE_SETTING.PAUSED;
      }
      if (pauseAction == UCOM_VIRUS_CHECK_PAUSE_SETTING.APPLY_PAUSE) {
        return UCOM_VIRUS_CHECK_PAUSE_SETTING.PAUSED;
      }
      if (pauseAction == UCOM_VIRUS_CHECK_PAUSE_SETTING.UNPAUSED) {
        return UCOM_VIRUS_CHECK_PAUSE_SETTING.UNPAUSED;
      }
      if (pauseAction == UCOM_VIRUS_CHECK_PAUSE_SETTING.APPLY_UNPAUSE) {
        return UCOM_VIRUS_CHECK_PAUSE_SETTING.UNPAUSED;
      }
    },
    /** 「解約」ボタンのクリックハンドラ */
    async goToCancel(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // バリデーション
      const error = this.validatePurchaseAgreement();
      if (error) {
        this.purchaseErrors = [error];
        this.isBusy = false;
        return;
      }

      // 購入/解約確認 画面に渡す情報を Store に保存する
      this.storePurchaseState(UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.CANCEL);
      // 購入/解約確認 画面に遷移する
      await this.$router.push('/ucom/virus-check/purchase-confirm').catch((error) => {
        checkRouterError(error);
      });
      this.isBusy = false;
    },

    /** 「次へ進む」ボタンのクリックハンドラ */
    async goToPauseSetting(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // バリデーション
      const error = this.validatePause();
      if (error) {
        this.pauseErrors = [error];
        this.isBusy = false;
        return;
      }

      // 一時停止/開始確認 画面に渡す情報を Store に保存する
      if (this.pauseAction === undefined) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        this.isBusy = false;
        return;
      }
      this.storePauseState(this.pauseAction);

      // 一時停止/開始確認 画面に遷移する
      await this.$router.push('/ucom/virus-check/pause-confirm').catch((error) => {
        checkRouterError(error);
      });
      this.isBusy = false;
    },

    /**
     * 購入/解約の説明に同意しているかを検証する。
     *
     * @return エラーならメッセージを返す。
     */
    validatePurchaseAgreement(): string | undefined {
      const ERROR_MESSAGE = '「サービス説明への同意」の「上記の内容に同意する」をチェックしてください。';
      if (!this.isAgreedOnPurchaseTerms) {
        return ERROR_MESSAGE;
      }
    },

    /**
     * 一時停止/開始のバリデーション
     *
     * @return エラーならメッセージを返す。
     */
    validatePause(): string | undefined {
      const AGREEMENT_ERROR_MESSAGE = '「注意事項への同意」の「上記の内容に同意する」をチェックしてください。';
      const RADIO_ERROR_MESSAGE = 'ウイルスチェック設定を選択してください';
      if (!this.isAgreedOnPauseTerms) {
        return AGREEMENT_ERROR_MESSAGE;
      }
      if (!this.pauseAction) {
        return RADIO_ERROR_MESSAGE;
      }
    },

    /**
     * 購入/解約関連の情報を Store に保存する
     *
     * @param subscriptionType 1:購入, 2:解約
     */
    async storePurchaseState(subscriptionType: UcomVirusCheckPurchaseSubscriptionType): Promise<void> {
      if (!this.virusCheck) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      const today = dayjs(new Date()).format('YYYY/MM/DD');
      const purchaseState: UcomVirusCheckPurchaseState = {
        accountName: this.virusCheck.accountName,
        mailAddress: this.virusCheck.mailAddress,
        virusCheckRegisterStatus: this.virusCheck.virusCheckRegisterStatus,
        monthlyFee: this.virusCheck.monthlyFee,
        subscriptionDate: today,
        serviceDescriptionBeforePurchase: this.virusCheck.serviceDescriptionBeforePurchase,
        serviceDescriptionAfterPurchase: this.virusCheck.serviceDescriptionAfterPurchase,
        subscriptionType: subscriptionType,
      };
      this.$store.commit('ucomVirusCheckStore/setPurchaseState', purchaseState);
    },

    /**
     * 一時停止/開始関連の情報を Store に保存する
     *
     * @param pauseAction 0:一時停止する, 1:実施する
     */
    async storePauseState(pauseAction: UcomVirusCheckPauseAction): Promise<void> {
      if (!this.virusCheck) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      const today = dayjs(new Date()).format('YYYY/MM/DD');
      const pauseState: UcomVirusCheckPauseState = {
        accountName: this.virusCheck.accountName,
        mailAddress: this.virusCheck.mailAddress,
        virusCheckPauseAction: pauseAction,
        subscriptionDate: today,
        terms: this.pauseTerms,
      };
      this.$store.commit('ucomVirusCheckStore/setPauseState', pauseState);
    },
    /**
     * ログインユーザが管理者権限を持つかどうかを返す
     */
    getIsAdminLoggedIn(): boolean {
      const loginUserAuthority: UCOM_ACCOUNT_AUTHORITY = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.accountAuthorization;
      if (loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.ADMIN_ACCOUNT) {
        return true;
      }
      if (loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.CUSTOMER_NO_USER) {
        return true;
      }
      return false;
    },
    myNumberWithDelimiter(value: number) {
      return numberWithDelimiter(value);
    },
  },
  computed: {
    /** ローダーを表示するかどうか */
    isLoading(): boolean {
      if (!this.isMounted) {
        return true;
      }
      return this.isBusy;
    },
    /** お支払い方法が登録済みかどうか */
    isPaymentRegistered(): boolean {
      return this.virusCheck?.paymentRegisterStatus === UCOM_REGISTERED_PAYMENT.REGISTERED.id.toString();
    },
    /** お支払情報登録未登録時メッセージ */
    paymentUnregisteredMessage(): string {
      return this.virusCheck?.paymentUnregisteredMessage ?? '';
    },
    /** ご契約状態の文字列 */
    registerStatus(): string | undefined {
      if (!this.virusCheck) {
        return undefined;
      }
      const registerStatus = convertUcomPurchaseStatusById(parseInt(this.virusCheck.virusCheckRegisterStatus));
      return registerStatus?.value;
    },
    /** 「購入」ボタンを有効にするかどうか */
    canPurchase(): boolean {
      if (!this.isAgreedOnPurchaseTerms) {
        return false;
      }
      return this.virusCheck?.canPurchase() ?? false;
    },
    /** 「解約」ボタンを有効にするかどうか */
    canCancel(): boolean {
      if (!this.isAgreedOnPurchaseTerms) {
        return false;
      }
      return this.virusCheck?.canCancel() ?? false;
    },
  },
});
</script>

<style scoped>
/* 非活性のボタンがマウスオーバーに反応しないように */
button.btn05:hover {
  opacity: 1;
}
.payment-url {
  color: red;
  text-decoration: underline;
}
</style>
