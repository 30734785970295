import { Module } from 'vuex';
import { RootState } from '../index';
import { EMansionHikariPhoneRegisterResponseDto } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-register-dto';

/** e-mansion UCOM光電話 サービス変更API レスポンス State */
const eMansionUcomHikariPhoneRegisterAPIResponseState: {
  info: EMansionHikariPhoneRegisterResponseDto | undefined;
} = {
  info: undefined,
};

/** State の型 */
type eMansionUcomHikariPhoneRegisterAPIResponseState = typeof eMansionUcomHikariPhoneRegisterAPIResponseState;

/** e-mansion UCOM光電話 サービス変更API レスポンス  Store */
export const eMansionUcomHikariPhoneRegisterAPIResponseStore: Module<eMansionUcomHikariPhoneRegisterAPIResponseState, RootState> = {
  namespaced: true,
  state: eMansionUcomHikariPhoneRegisterAPIResponseState,
  actions: {},
  mutations: {
    /** UCOM光電話 サービス変更API レスポンス  */
    info: (state, value: EMansionHikariPhoneRegisterResponseDto) => (state.info = value),
  },
  getters: {
    /** UCOM光電話 サービス変更API レスポンス  */
    info: (state): EMansionHikariPhoneRegisterResponseDto | undefined => state.info,
  },
};
