<template>
  <div class="payment-method-identity-verification-result">
    <LoadingComponent v-if="isLoading" />
  </div>
</template>

<style>
.payment-method-identity-verification-result {
  opacity: 0;
  animation: fade-in 0.5s ease 3s 1 normal;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>

<script lang="ts">
import axios from 'axios';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import { EMansionConnectixErrorResponse } from './connectix/e-mansion/classes/external-api/connectix-error-response';
import { EMansionConnectixNewConnectixRequest } from './connectix/e-mansion/classes/external-api/new-connectix-request';
import { FiveAConnectixErrorResponse } from './connectix/five-a/classes/external-api/connectix-error-response';
import { FiveAConnectixNewConnectixRequest } from './connectix/five-a/classes/external-api/new-connectix-request';
import { UcomConnectixErrorResponse } from './connectix/ucom/classes/external-api/connectix-error-response';
import { UcomConnectixNewConnectixRequest } from './connectix/ucom/classes/external-api/new-connectix-request';
import { FiveAConnectixFee } from './five-a/entry/classes/entry-input-form';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiCourseAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-course-accessor';
import { SpfApiGipAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-gip-accessor';
import { SpfApiHikariPhoneAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-hikari-phone-accessor';
import { SpfApiHomepageCapacityAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-capacity-accessor';
import { SpfApiIfilterAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-ifilter-accessor';
import { SpfApiIPPoneAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-ip-phone-accessor';
import { SpfApiSubAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-sub-account-accessor';
import { SpfApiVirusCheckAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-virus-check-accessor';
import { SpfApiMailPackAccessor } from '@/infra/accessor/spf/ucom/spf-api-mail-pack-accessor';
import { SpfApiMailboxCapacityAccessor } from '@/infra/accessor/spf/ucom/spf-api-mailbox-capacity-accessor';
import { SpfApiUcomVirusCheckAccessor } from '@/infra/accessor/spf/ucom/spf-api-virus-check-accessor';
import { SpfApiWebSpaceAccessor } from '@/infra/accessor/spf/ucom/spf-api-web-space-accessor';
import { UpdateUcomMbCapacityRequest } from '@/infra/request/spf/ucom/mailbox-capacity/update-ucom-mb-capacity-request';
import { UpdateUcomVirusCheckRequest } from '@/infra/request/spf/ucom/virus-check/update-ucom-virus-check-request';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { ApplicationContentTemporaryStorageResponse } from '@/shared/classes/platform/application-content-temporary-storage-response';
import { ContractFromCopyPaymentMethodRequest } from '@/shared/classes/platform/contract-from-copy-payment-method-request';
import { ContractFromCreatePaymentMethodRequest } from '@/shared/classes/platform/contract-from-create-payment-method-request';
import { ContractFromEntryPortasRequest } from '@/shared/classes/platform/contract-from-entry-portas-request';
import { PlatformPaymentMethodEditRequest } from '@/shared/classes/platform/edit-payment-method-request';
import { PaymentMethodListResponse } from '@/shared/classes/platform/payment-method-list-response';
import { PlatformPaymentMethodRegisterRequest } from '@/shared/classes/platform/register-payment-method-request';
import { EMansionHikariPhoneUpdateRequestOptionalDataDto } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-option-dto';
import { EMansionHikariPhoneRegisterRequestOptionalDataDto } from '@/shared/classes/spf-api/e-mansion/e-mansion-ucom-hikari-phone-register-dto';
import { LinkServiceWithUcomRequest } from '@/shared/classes/spf-api/link-service-with-ucom';
import { Member } from '@/shared/classes/spf-api/member';
import { Property, SearchPropertyDetail, SearchResultOver } from '@/shared/classes/spf-api/property';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { PROPERTY_SEARCH_TYPE, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionConnectixExternalApiService } from '@/shared/services/external-api/connectix/e-mansion/e-mansion-connectix-external-api-service';
import { FiveAConnectixExternalApiService } from '@/shared/services/external-api/connectix/five-a/five-a-connectix-external-api-service';
import { UcomConnectixExternalApiService } from '@/shared/services/external-api/connectix/ucom/ucom-connectix-external-api-service';
import { UcomConnectixErrorService } from '@/shared/services/ucom/ucom-connectix-error-service';
import { isCreditCardExpired } from '@/shared/util/func-is-credit-card-expired';
import { formatDate, processYearNumber } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';

/** 支払方法 */
interface PaymentMethodInfoForDisplay extends PaymentMethodListResponse {
  isExpired: boolean;
}
/** お支払方法本人認証結果取得画面 */
export default defineComponent({
  name: 'payment-method-identity-verification-result',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true,
    isLoggedIn: false,
    tempStorage: '' as any,
    process: '',
    tempStorages: null as ApplicationContentTemporaryStorageResponse | null,
    errorMessageTitle: '',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** ISPクレジットカード情報登録APIの結果 */
    creditCardApiResult: null as any, //APIの修正終わり次第型変更
    apid: '',
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    oemsMaster: [] as Array<GetOemsMasterResponse>, // OEMマスタ
    domainName: '', // 「こちら」リンク用
    isPortasServicePayment: false,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async created() {
    //ログイン状態取得
    this.$data.isLoggedIn = await AuthService.isLoggedIn();
    //ログインしていない場合統合トップへ遷移
    if (!this.$data.isLoggedIn) {
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    const uuid = localStorage.getItem('uuidForTemporarySavingApplicationData');
    //LocalStorageからUUID取得
    if (!uuid) {
      throw new Error('ローカルストレージにUUIDがありません');
    }

    //申込内容一時保存データ取得API呼出（リクエストパラメータ:UUID）
    try {
      this.tempStorages = await SpfApiService.getApplicationContentTemporaryStorage(uuid);
      this.tempStorage = JSON.parse(this.tempStorages.applicationDataJson);
      this.process = this.tempStorages.subsequentProcess;
    } catch (e: any) {
      if (e.response.status == 404) {
        //URL直打ちやブラウザバッグの場合は404になるのでトップページに遷移する
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
      await this.$router.push('/error').catch((error: any) => {
        checkRouterError(error);
      });
    }

    const property: Property | null = this.$store.getters['propertyStore/property'];

    if (property) {
      if (property?.uaType == '2') {
        try {
          //ドメイン名の取得 */
          if (property.oemType) {
            this.oemsMaster = await this.$store.dispatch('oemsStore/oems');
            const filtered = this.oemsMaster.filter((oem: any) => oem.oemType === String(property.oemType));
            if (filtered.length >= 1) {
              let latestOem = filtered[0];
              if (filtered.length >= 2) {
                for (let i = 1; i < filtered.length; i++) {
                  if (latestOem.startAt < filtered[i].startAt) {
                    latestOem = filtered[i];
                  }
                }
              }
              this.domainName = latestOem.domainForOsumai;
            }
          } else {
            // データ不整合エラー
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
          }
        } catch (error: any) {
          // 共通エラーに遷移
          await this.$router.push('/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      }
      this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
    }

    //Portasサービスの確認画面から来たかどうかの判定
    if (this.$route.query.result !== 'success') {
      const isPortasServicePaymentList = [
        'service-entry-regist-member',
        'payment_methods_edit',
        'payment_methods_input',
        'service-entry-copy',
        'service-entry-regist',
        'service-entry-registed',
      ];

      if (isPortasServicePaymentList.includes(this.process)) {
        this.isPortasServicePayment = true;
      }

      if (this.$route.query.result === 'FailureByUser') {
        this.errorMessages.push(
          `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて本人認証（3Dセキュア）の設定はお済みでしょうか<br/>・ご利用のカードは本人認証（3Dセキュア）に対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お手数ですがクレジットカード会社へ本人認証が失敗する旨のお問い合わせをお願いいたします。<br/>※当サイトでは失敗の理由はわかりかねます。`
        );
      }

      if (this.$route.query.result === 'FailureByError' && property?.uaType == '1' && !this.isPortasServicePayment) {
        this.errorMessages.push(
          `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
        );
      } else if (this.$route.query.result === 'FailureByError' && property?.uaType == '3' && !this.isPortasServicePayment) {
        this.errorMessages.push(
          `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.ucomUrl}/${this.apid}/" target="_blank">こちら</a>から、サポートセンターまでお問い合わせください。`
        );
      } else if (this.$route.query.result === 'FailureByError' && property?.uaType == '2' && !this.isPortasServicePayment) {
        this.errorMessages.push(
          `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`
        );
      } else if (this.$route.query.result === 'FailureByError') {
        this.errorMessages.push(
          `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="https://support.ucom.ne.jp/contact/portas/" target="_blank">こちら</a>(Portas問合せフォーム）からお問い合わせをお願いいたします。`
        );
      }

      if (this.$route.query.result === 'FailureByFalsification') {
        // 本人認証結果取得API データの改ざんが見られた場合、共通エラー画面に遷移する
        throw new ApiFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.VERIFICATION_RESULT_FALSIFICATION_CONFIRMED);
      }
      this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

      this.backToScreen();
      return;
    }

    if (this.process === 'payment_methods_input') {
      /**支払方法登録処理*/
      const memberId = this.$store.getters['memberStore/member'].id;

      try {
        const paymentMethodRegisterRequest = new PlatformPaymentMethodRegisterRequest({
          creditCardToken: this.tempStorage.creditCardAccessToken,
          tokenExpireDate: this.tempStorage.creditTokenExpireDate,
        });

        await SpfApiService.createPaymentMethod(memberId, paymentMethodRegisterRequest);
      } catch {
        this.errorMessages.push(
          `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
        );
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        //前の画面に戻る
        this.backToScreen();
        return;
      }
      // VeriTrans会員IDの登録状況を更新するため、会員ストアの内容を初期化
      this.$store.commit('memberStore/member', null);
      //支払方法登録完了画面遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/my-page/payment-method/input-completed').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    } else if (this.process === 'payment_methods_edit') {
      /**支払方法編集*/
      const memberId = this.$store.getters['memberStore/member'].id;
      const paymentMethodEditRequest = new PlatformPaymentMethodEditRequest({
        // 会員IDは会員ストアから取得する
        memberId: memberId,
        paymentMethodNo: this.tempStorage.editRequestedPaymentMethodNo,
        creditCardToken: this.tempStorage.creditCardAccessToken,
        tokenExpireDate: this.tempStorage.creditTokenExpireDate,
      });

      try {
        await SpfApiService.updatePaymentMethod(paymentMethodEditRequest);
      } catch {
        this.errorMessages.push(
          `お支払い方法の編集が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
        );
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        //前の画面に戻る
        this.backToScreen();
        return;
      }
      //支払方法登録完了画面遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform/my-page/payment-method/input-completed').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    } else if (this.process === 'service-entry-regist') {
      /**サービス申し込み処理(カード新規登録)*/
      try {
        const contractFromCreatePaymentMethodRequest = new ContractFromCreatePaymentMethodRequest({
          creditCardToken: this.tempStorage.creditCardAccessToken,
          tokenExpireDate: this.tempStorage.creditTokenExpireDate,
          productIdArray: this.tempStorage.productIdArray,
          productNameArray: this.tempStorage.notExistIdProductNameArray,
          contractAppliedAt: this.tempStorage.contractAppliedAt,
        });

        await SpfApiService.contractFromCreatePaymentMethod(this.tempStorage.memberId, contractFromCreatePaymentMethodRequest);
        //サービス申し込み完了画面遷移
        await this.transitEntryCompletedScreen(this.tempStorage.productNameArray, this.tempStorage.productIdArray);
        return;
      } catch (error: any) {
        if (error.response.data.errors.includes('cannot create payment method')) {
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages.push(`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`);
          //前の画面に戻る
          this.backToScreen();
          return;
          // サービス申し込み手続き自体には成功したが、手続完了メールの送信処理に失敗した場合は申し込み完了画面に遷移する
        } else if (error.response.status === 500 && error.response.data.errors.includes('Sending Contract Completion Mail is Failed')) {
          await this.transitEntryCompletedScreen(this.tempStorage.productNameArray, this.tempStorage.productIdArray);
          return;
        } else {
          throw error;
        }
      }
    } else if (this.process === 'service-entry-copy') {
      /**サービス申し込み(カードコピー)*/
      try {
        let uaTypeValue = '';
        if (this.tempStorage.ispName === 'e-mansion') {
          uaTypeValue = UA_TYPE.E_MANSION;
        } else if (this.tempStorage.ispName === 'Five.A') {
          uaTypeValue = UA_TYPE.FIVE_A;
        } else if (this.tempStorage.ispName === 'UCOM光 レジデンス') {
          uaTypeValue = UA_TYPE.UCOM;
        }

        const contractFromCopyPaymentMethodRequest = new ContractFromCopyPaymentMethodRequest({
          creditCardToken: this.tempStorage.creditCardAccessToken,
          tokenExpireDate: this.tempStorage.creditTokenExpireDate,
          uaType: uaTypeValue,
          originalAccountId: this.tempStorage.externalVeriTransId,
          productIdArray: this.tempStorage.productIdArray,
          productNameArray: this.tempStorage.notExistIdProductNameArray,
          contractAppliedAt: this.tempStorage.contractAppliedAt,
        });

        await SpfApiService.contractFromCopyPaymentMethod(this.tempStorage.memberId, contractFromCopyPaymentMethodRequest);
        await this.transitEntryCompletedScreen(this.tempStorage.productNameArray, this.tempStorage.productIdArray);
        return;
      } catch (error: any) {
        // 支払方法のコピーに失敗した場合は支払方法コンポーネントの状態を初期化する
        // お客様の要望により、支払方法のコピーに失敗時は支払方法コンポーネントの初期化を行わない
        if (error.response.status === 500 && error.response.data.errors.includes('cannot copied payment method')) {
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages.push(
            `クレジットカード情報のコピーに失敗しました。<br>
                インターネットサービスにご登録されているお支払情報をご確認いただくか、画面下の「戻る」ボタンを押して新規にクレジットカード情報をご登録ください。`
          );
          this.backToScreen();
          return;
          // サービス申し込み手続き自体には成功したが、手続完了メールの送信処理に失敗した場合は申し込み完了画面に遷移する
        } else if (error.response.status === 500 && error.response.data.errors.includes('Sending Contract Completion Mail is Failed')) {
          await this.transitEntryCompletedScreen(this.tempStorage.productNameArray, this.tempStorage.productIdArray);
          return;
        } else {
          throw error;
        }
      }
    } else if (this.process === 'service-entry-registed') {
      /**サービス申し込み（カード登録済み)*/
      // 支払方法取得
      let paymentMethodNo = '';
      const paymentMethodOnPortas = await this.getPaymentMethodInfoList(this.tempStorage.memberId);
      if (paymentMethodOnPortas) {
        paymentMethodNo = paymentMethodOnPortas.paymentMethodNo.toString();
      }

      // サブスクリプション契約申込API実行処理
      await SpfApiService.subscriptionCreateRequest(
        this.tempStorage.memberId,
        paymentMethodNo,
        this.tempStorage.productIdArray,
        this.tempStorage.notExistIdProductNameArray,
        this.tempStorage.contractAppliedAt
      );

      await this.transitEntryCompletedScreen(this.tempStorage.productNameArray, this.tempStorage.productIdArray);
      return;
    } else if (this.process === 'service-entry-regist-member') {
      /**新規会員登録でサービス申し込み*/
      const createdContract = await this.contractFromEntryPortas();

      this.tempStorage.form.contractedProductIdList = createdContract;
      this.$store.commit('platformEntryStore/entryInputForm', this.tempStorage.form);
      this.$store.commit('platformEntryStore/portasServiceKind', this.tempStorage.selectedServiceOption);

      if (createdContract) {
        await this.$router
          .push('/platform/entry/completed')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
        return;
      }
      return;
    } else if (this.process === 'five-a-apply') {
      const member: Member = this.tempStorage.member;
      const property: Property = this.tempStorage.property;
      //情報が取れない場合不正な遷移
      if (!member || !property || !property.apartmentId) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      let cardRegistProcess;
      let eTncMemberId;
      try {
        //ID発番API実行
        eTncMemberId = await SpfApiService.createMyeId(property.apartmentId, UA_TYPE.FIVE_A, '1');
        if (!eTncMemberId) {
          throw new Error();
        }
      } catch (e: any) {
        await VueErrorHandler.handle(e, 'created hook');
        await this.$router.push('/5a/error').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
      this.tempStorage.request.eTncMemberId = eTncMemberId;
      try {
        if (this.tempStorage.request.creditCardToken && this.tempStorage.request.tokenExpireDate) {
          //カード新規登録
          const cardRegitsResult = await SpfApiService.registerIspCard(
            UA_TYPE.FIVE_A,
            eTncMemberId,
            this.tempStorage.request.creditCardToken,
            this.tempStorage.request.tokenExpireDate,
            SERVICE_PROVIDER.MYE
          );
          cardRegistProcess = cardRegitsResult.process;
        } else if (this.tempStorage.request.cardAccountId) {
          //カードコピー
          await SpfApiService.copyToIspCard(UA_TYPE.FIVE_A, eTncMemberId!, this.tempStorage.request.cardAccountId, SERVICE_PROVIDER.MYE);
          cardRegistProcess = 'AccountCopy';
        }
      } catch {
        if (this.tempStorage.request.creditCardToken && this.tempStorage.request.tokenExpireDate) {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
        } else if (this.tempStorage.request.cardAccountId) {
          this.errorMessages.push(`クレジットカード情報のコピーに失敗しました。<br>
                Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`);
        }
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        //前の画面に戻る
        this.backToScreen();
        return;
      }

      /** Five.A新規登録 */
      this.$store.commit('fiveAEntryStore/entryInputForm', this.tempStorage.request);

      try {
        // Store から Connectix 料金を取得
        // 物件基本情報を取得
        const propertyInfo: FiveAProperty = this.tempStorage.propertyInfo;

        // Connectix利用金額
        const fiveAConnectixFee: FiveAConnectixFee = {
          connectixUsageFee: propertyInfo.op.sdwan.fee,
          connectixUsageFeeCp: propertyInfo.op.sdwan.fee_cp,
        };
        this.$store.commit('fiveAEntryStore/fiveAConnectixFee', fiveAConnectixFee);
      } catch (error: any) {
        if (cardRegistProcess && cardRegistProcess != 'CardInfoUpdate') {
          //クレジットカード削除
          await SpfApiService.deleteIspCard(UA_TYPE.FIVE_A, eTncMemberId!, SERVICE_PROVIDER.MYE);
        }
        await VueErrorHandler.handle(error, 'created hook');
        await this.$router.push('/5a/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      try {
        // request内のクレカの情報を削除する
        this.tempStorage.request.cardAccountId = undefined;
        this.tempStorage.request.creditCardToken = undefined;
        this.tempStorage.request.tokenExpireDate = undefined;

        const resultEntry = await SpfApiService.linkServiceWithFiveA(member.id, this.tempStorage.request, property.apartmentId);

        // Five.A新規会員登録で「APIN001　既に入会済みエラー」が返ってきたら確認画面にエラーメッセージを表示する
        if (resultEntry.status === 'success') {
          await AuthService.refresh();
          // SpfApiService.linkServiceWithFiveA() に成功するとバックエンドで会員ステータスが更新される
          // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
          this.$store.commit('memberStore/memberStatus', null);
          this.$store.commit('memberStore/member', null);

          // 完了画面へ遷移
          await this.$router.push('/5a/entry/completed').catch((error: any) => {
            checkRouterError(error);
          });
          this.isLoading = false;
          return;
        } else {
          //失敗の場合カード登録をロールバック
          if (cardRegistProcess && cardRegistProcess != 'CardInfoUpdate') {
            //クレジットカード削除
            await SpfApiService.deleteIspCard(UA_TYPE.FIVE_A, eTncMemberId!, SERVICE_PROVIDER.MYE);
          }
          // エラーコードがAPIN001,CNTX008の場合エラーメッセージ表示
          if (resultEntry.code === 'APIN001') {
            const errorMessage = `既にお申し込みは受け付けております。<br>WEBからのお申し込みができないときは、建物専用ホームページ「<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">よくあるご質問</a>」からサポートセンターへご連絡ください。`;
            this.errorMessages.push(errorMessage);
            this.backToScreen();
            return;
          } else if (resultEntry.code === 'CNTX008') {
            const errorMessage = `Connectixのお申し込みを受け付けることができません。<br>恐れ入りますが、建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`;
            this.errorMessages.push(errorMessage);
            this.backToScreen();
            return;
          } else {
            //それ以外の場合は共通エラー
            await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.FAILED_ENTRY_FIVE_A), 'created hook');
            await this.$router.push('/5a/error').catch((error) => {
              checkRouterError(error);
            });
            this.isLoading = false;
            return;
          }
        }
      } catch (error: any) {
        if (cardRegistProcess && cardRegistProcess != 'CardInfoUpdate') {
          //クレジットカード削除
          await SpfApiService.deleteIspCard(UA_TYPE.FIVE_A, eTncMemberId!, SERVICE_PROVIDER.MYE);
        }
        // エラーコードがAPIN001,CNTX008の場合エラーメッセージ表示
        if (error.response.data.code === 'APIN001') {
          const errorMessage = `既にお申し込みは受け付けております。<br>WEBからのお申し込みができないときは、建物専用ホームページ「<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">よくあるご質問</a>」からサポートセンターへご連絡ください。`;
          this.errorMessages.push(errorMessage);
          this.backToScreen();
          return;
        } else if (error.response.data.code === 'CNTX008') {
          const errorMessage = `Connectixのお申し込みを受け付けることができません。<br>恐れ入りますが、建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`;
          this.errorMessages.push(errorMessage);
          this.backToScreen();
          return;
        } else {
          //それ以外の場合は共通エラー
          await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.FAILED_ENTRY_FIVE_A), 'created hook');
          await this.$router.push('/5a/error').catch((error) => {
            checkRouterError(error);
          });
          this.isLoading = false;
          return;
        }
      }
    } else if (this.process === 'five-a-connectix-apply') {
      const member: Member = await this.$store.dispatch('memberStore/member');
      let cardRegistProcess;
      try {
        if (this.tempStorage.credit_card_token && this.tempStorage.token_expire_date) {
          //カード新規登録
          const cardRegitsResult = await SpfApiService.registerIspCard(
            UA_TYPE.FIVE_A,
            member.eTncMemberId!,
            this.tempStorage.credit_card_token,
            this.tempStorage.token_expire_date,
            SERVICE_PROVIDER.MYE
          );
          cardRegistProcess = cardRegitsResult.process;
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.card_account_id) {
          //カードコピー
          await SpfApiService.copyToIspCard(UA_TYPE.FIVE_A, member.eTncMemberId!, this.tempStorage.card_account_id, SERVICE_PROVIDER.MYE);
          cardRegistProcess = 'AccountCopy';
        }
      } catch {
        if (this.tempStorage.credit_card_token && this.tempStorage.token_expire_date) {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
        } else if (this.tempStorage.card_account_id) {
          this.errorMessages.push(`クレジットカード情報のコピーに失敗しました。<br>
                Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`);
        }
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        //前の画面に戻る
        this.backToScreen();
        return;
      }

      /**Five.A Connectix申込 */
      //リクエストを準備
      const request = new FiveAConnectixNewConnectixRequest({
        ua_type: UA_TYPE.FIVE_A,
        member_id: this.tempStorage.member_id,
        property_id: this.tempStorage.property_id,
        room_number: this.tempStorage.room_number,
        credit_card_regist_flag: this.tempStorage.credit_card_regist_flag,
        service_plan_type: SERVICE_PLAN_TYPE.DEFAULT,
      });

      if (this.tempStorage.building_id) request.building_id = this.tempStorage.building_id;

      const requestBody: FiveAConnectixNewConnectixRequest = request;
      // Connectix申し込みAPIを実行
      try {
        const resultCreateConnectix = await FiveAConnectixExternalApiService.createConnectix(requestBody);
        if (resultCreateConnectix instanceof FiveAConnectixErrorResponse) {
          // エラーコードがCNTX008(業務エラー)の場合エラーメッセージ表示
          if (resultCreateConnectix.code === 'CNTX008') {
            if (this.tempStorage.creditCardRegistFlag === '1' && cardRegistProcess) {
              //クレジットカード削除
              await this.deleteIspCard(UA_TYPE.FIVE_A, cardRegistProcess, member);
            }
            const errorMessage = `Connectixのお申し込みを受け付けることができません。<br>恐れ入りますが、建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`;
            this.errorMessages.push(errorMessage);
            this.backToScreen();
            return;
          } else {
            // データ不整合エラー
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONNECTIX);
          }
        }
      } catch (error: any) {
        if (this.tempStorage.creditCardRegistFlag === '1' && cardRegistProcess) {
          //クレジットカード削除
          await this.deleteIspCard(UA_TYPE.FIVE_A, cardRegistProcess, member);
        }
        await VueErrorHandler.handle(error, '');
        await this.$router.push('/5a/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      // 完了画面へ遷移
      await this.$router.push('/connectix/5a/completed').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    } else if (this.process === 'e-mansion-entry') {
      const member: Member = this.tempStorage.member;
      const property: Property = this.tempStorage.property;
      const eMansionproperty: EMansionProperty = this.tempStorage.eMansionproperty;
      //情報が取れない場合不正な遷移
      if (!member || !property || !property.apartmentId || !eMansionproperty) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      let cardRegistProcess;
      let eTncMemberId;
      try {
        //ID発番API実行

        eTncMemberId = await SpfApiService.createMyeId(property.apartmentId, UA_TYPE.E_MANSION, '1');
        this.tempStorage.request.eTncMemberId = eTncMemberId;
      } catch (e: any) {
        await VueErrorHandler.handle(e, '');
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
      try {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          //カード新規登録
          const cardRegistResult = await SpfApiService.registerIspCard(
            UA_TYPE.E_MANSION,
            eTncMemberId!,
            this.tempStorage.creditCardToken,
            this.tempStorage.tokenExpireDate,
            SERVICE_PROVIDER.MYE
          );
          cardRegistProcess = cardRegistResult.process;
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          //カードコピー
          await SpfApiService.copyToIspCard(UA_TYPE.E_MANSION, eTncMemberId!, this.tempStorage.veriTransAccountId, SERVICE_PROVIDER.MYE);
          cardRegistProcess = 'AccountCopy';
        }
      } catch {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          this.errorMessages.push(`クレジットカード情報のコピーに失敗しました。<br>
                Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`);
        }
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        //前の画面に戻る
        this.backToScreen();
        return;
      }
      try {
        //入会申し込みAPI実行
        const resultEntry = await SpfApiService.linkServiceWithEMansion(member.id, this.tempStorage.request);
        if (resultEntry.status === 'success') {
          // 完了画面で必要な情報
          this.$store.commit('eMansionEntryStore/result', resultEntry);
          this.$store.commit('eMansionEntryStore/entryInputForm', this.tempStorage.customer);
          this.$store.commit('eMansionCommonStore/property', eMansionproperty);
          //画面表示更新のため会員情報を取り直す
          await AuthService.refresh();
          await this.$store.dispatch('memberStore/member');
          //完了画面へ遷移
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/e-mansion/entry/completed').catch((error: any) => {
            checkRouterError(error);
          });
          this.isLoading = false;
          return;
        } else {
          //失敗の場合カード登録をロールバック
          if (cardRegistProcess && cardRegistProcess != 'CardInfoUpdate') {
            //クレジットカード削除
            await SpfApiService.deleteIspCard(UA_TYPE.E_MANSION, eTncMemberId!, SERVICE_PROVIDER.MYE);
          }
          // エラーコードがAPIN001の場合エラーメッセージ表示
          if (resultEntry.code === 'APIN001') {
            this.errorMessages.push('e-mansionご入会のお申し込みは受け付けております。<br><a href="/platform" class="link">Portasトップページへ</a>');
            this.backToScreen();
            return;
          } else {
            //それ以外の場合は共通エラー
            await VueErrorHandler.handle(new Error(`status:${resultEntry.status}  message:${resultEntry.message}`), '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }
        }
      } catch (e: any) {
        //その他予期せぬエラー
        if (cardRegistProcess && cardRegistProcess != 'CardInfoUpdate') {
          //クレジットカード削除
          await SpfApiService.deleteIspCard(UA_TYPE.E_MANSION, eTncMemberId!, SERVICE_PROVIDER.MYE);
        }
        await VueErrorHandler.handle(e, '');
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
    } else if (this.process === 'e-mansion-connectix-apply') {
      const member: Member = await this.$store.dispatch('memberStore/member');
      let cardRegistProcess;
      try {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          //カード新規登録
          const cardRegistResult = await SpfApiService.registerIspCard(
            UA_TYPE.E_MANSION,
            member.eTncMemberId!,
            this.tempStorage.creditCardToken,
            this.tempStorage.tokenExpireDate,
            SERVICE_PROVIDER.MYE
          );
          cardRegistProcess = cardRegistResult.process;
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          //カードコピー
          await SpfApiService.copyToIspCard(UA_TYPE.E_MANSION, member.eTncMemberId!, this.tempStorage.veriTransAccountId, SERVICE_PROVIDER.MYE);
          cardRegistProcess = 'AccountCopy';
        }
      } catch {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          this.errorMessages.push(`クレジットカード情報のコピーに失敗しました。<br>
                Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`);
        }
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        //前の画面に戻る
        this.backToScreen();
        return;
      }

      /** e-mansion Connectix申込 */
      const servicePlanType = await this.$store.dispatch('servicePlanTypeStore/servicePlanType');
      //リクエストを準備
      const request = new EMansionConnectixNewConnectixRequest({
        ua_type: UA_TYPE.E_MANSION,
        member_id: this.tempStorage.memberId,
        property_id: this.tempStorage.propertyId,
        building_id: this.tempStorage.buildingId,
        room_number: this.tempStorage.roomNumber,
        credit_card_regist_flag: this.tempStorage.creditCardRegistFlag,
        service_plan_type: servicePlanType,
      });

      // Connectix申し込みAPIを実行
      try {
        const resultCreateConnectix = await EMansionConnectixExternalApiService.createConnectix(request);

        if (resultCreateConnectix instanceof EMansionConnectixErrorResponse) {
          // エラーコードがCNTX008(業務エラー)の場合エラーメッセージ表示
          if (resultCreateConnectix.code === 'CNTX008') {
            if (this.tempStorage.creditCardRegistFlag === '1' && cardRegistProcess) {
              //クレジットカード削除
              await this.deleteIspCard(UA_TYPE.E_MANSION, cardRegistProcess, member);
            }
            const errorMessage = `Connectixのお申し込みを受け付けることができません。<br>恐れ入りますが、「<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>」からe-mansionサポートセンターへご連絡ください。`;
            this.errorMessages.push(errorMessage);
            this.backToScreen();
            return;
          } else {
            // 外部APIエラー
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONNECTIX);
          }
        }
      } catch (error: any) {
        if (this.tempStorage.creditCardRegistFlag === '1' && cardRegistProcess) {
          //クレジットカード削除
          await this.deleteIspCard(UA_TYPE.E_MANSION, cardRegistProcess, member);
        }

        await VueErrorHandler.handle(error, '');
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }
      // 完了画面へ遷移
      await this.$router.push('/connectix/e-mansion/completed').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    } else if (this.process === 'e-mansion-gip-apply') {
      const member: Member = await this.$store.dispatch('memberStore/member');

      /** カード新規登録またはコピーの結果*/
      let creditCardApiResultProcess = null;
      try {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          //カード新規登録
          // Portas,e-mansionにカードが未登録の場合
          this.creditCardApiResult = await SpfApiService.registerIspCard(
            UA_TYPE.E_MANSION,
            member.eTncMemberId!,
            this.tempStorage.creditCardToken,
            this.tempStorage.tokenExpireDate,
            SERVICE_PROVIDER.MYE
          );
          creditCardApiResultProcess = this.creditCardApiResult.process;
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          //カードコピー
          // PortasからISPにカード情報をコピーする場合
          await SpfApiService.copyToIspCard(UA_TYPE.E_MANSION, member.eTncMemberId!, this.tempStorage.veriTransAccountId, SERVICE_PROVIDER.MYE);
          creditCardApiResultProcess = 'AccountCopy';
        }
      } catch {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          this.errorMessages.push(`クレジットカード情報のコピーに失敗しました。<br>
                Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`);
        }
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

        //前の画面に戻る
        this.backToScreen();
        return;
      }

      /** e-mansion  サービス登録API(固定グローバルIP)*/
      try {
        // サービス登録API(固定グローバルIP) を呼び出す

        //リクエストを準備
        const eMansionMemberId = this.tempStorage.memberId;
        const eMansionPropertyId = this.tempStorage.propertyId;
        const requestMacAddress = this.tempStorage.requestMacAddress;
        const creditCardRegistFlag = this.tempStorage.creditCardRegistFlag;
        const eMansionGipRegisterResponseDto = await SpfApiGipAccessor.registerGip(eMansionMemberId, eMansionPropertyId, requestMacAddress, creditCardRegistFlag);

        // レスポンスの message に値があればエラーを表示して終了する
        if (eMansionGipRegisterResponseDto.code) {
          const errorCode = eMansionGipRegisterResponseDto.code;

          if (errorCode === 'APIS010' || errorCode === 'APIS011') {
            if (errorCode === 'APIS010') {
              this.$store.commit('eMansionGIPStore/from', 'confirm');
              this.$store.commit('eMansionGIPStore/errorCode', errorCode);
            }
            if (errorCode === 'APIS011') {
              this.$store.commit('eMansionGIPStore/from', 'confirm');
              this.$store.commit('eMansionGIPStore/errorCode', errorCode);
            }
            /**ロールバック処理としてカード削除 */
            if (creditCardApiResultProcess) {
              await this.deleteIspCard(UA_TYPE.E_MANSION, creditCardApiResultProcess, member);
            }

            // ストアにMACアドレスをコミットする
            this.$store.commit('eMansionMacAddressStore/macAddress', this.tempStorage.requestMacAddress);

            // 申し込み画面に遷移する
            await this.$router.push('/e-mansion/static-gip/application').catch((error) => {
              checkRouterError(error);
            });
            this.isLoading = false;
            return;
          }
        }
      } catch (error) {
        /**ロールバック処理としてカード削除 */
        if (creditCardApiResultProcess) {
          await this.deleteIspCard(UA_TYPE.E_MANSION, creditCardApiResultProcess, member);
        }
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      await this.$router
        .push('/e-mansion/static-gip/completed')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {});
      this.isLoading = false;
      return;
    } else if (this.process === 'e-mansion-hikari-phone-register') {
      const member: Member = await this.$store.dispatch('memberStore/member');

      /** カード新規登録またはコピーの結果*/
      let creditCardApiResultProcess = null;
      try {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          //カード新規登録
          // Portas,e-mansionにカードが未登録の場合
          this.creditCardApiResult = await SpfApiService.registerIspCard(
            UA_TYPE.E_MANSION,
            member.eTncMemberId!,
            this.tempStorage.creditCardToken,
            this.tempStorage.tokenExpireDate,
            SERVICE_PROVIDER.MYE
          );
          creditCardApiResultProcess = this.creditCardApiResult.process;
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          //カードコピー
          // PortasからISPにカード情報をコピーする場合
          await SpfApiService.copyToIspCard(UA_TYPE.E_MANSION, member.eTncMemberId!, this.tempStorage.veriTransAccountId, SERVICE_PROVIDER.MYE);
          creditCardApiResultProcess = 'AccountCopy';
        }
      } catch {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          this.errorMessages.push(`クレジットカード情報のコピーに失敗しました。<br>
                Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`);
        }
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

        //前の画面に戻る
        this.backToScreen();
        return;
      }

      /** e-mansion  サービス登録API（UCOM光電話）*/
      try {
        // サービス登録API（UCOM光電話）を呼び出す

        //リクエストを準備
        const eMansionMemberId = this.tempStorage.memberId;
        const eMansionPropertyId = this.tempStorage.propertyId;
        const creditCardRegistFlag = this.tempStorage.creditCardRegistFlag;
        const servicePlanType = await this.$store.dispatch('servicePlanTypeStore/servicePlanType');
        const inputData = this.tempStorage.inputData;
        this.$store.commit('eMansionHikariphoneStore/inputData', inputData);

        const hikariPhoneInfo = this.tempStorage.optionalData.Hikariphone;
        // サービス更新APIに進む前のデータ取得
        const optionalData = new EMansionHikariPhoneRegisterRequestOptionalDataDto({
          Hikariphone: {
            preferredDate: formatDate(hikariPhoneInfo.preferredDate, 'YYYY/MM/DD'),
            numberPortabilityFlag: hikariPhoneInfo.numberPortabilityFlag,
            portabilityNumber: hikariPhoneInfo.portabilityNumber,
            relationshipType: hikariPhoneInfo.relationshipType,
            name: hikariPhoneInfo.name,
            kana: hikariPhoneInfo.kana,
            telephoneCompanyType: hikariPhoneInfo.telephoneCompanyType,
            telephoneCompanyOther: hikariPhoneInfo.telephoneCompanyOther,
            destinationZipcode: hikariPhoneInfo.destinationZipcode,
            destinationAddress1: hikariPhoneInfo.destinationAddress1,
            destinationAddress2: hikariPhoneInfo.destinationAddress2,
            notificationFlag: hikariPhoneInfo.notificationFlag,
            numberDisplay: hikariPhoneInfo.numberDisplay,
            catch: hikariPhoneInfo.catch,
            catchNumberDisplay: hikariPhoneInfo.catchNumberDisplay,
            optionPack: hikariPhoneInfo.optionPack,
          },
        });
        this.$store.commit('eMansionHikariphoneStore/optionalData', optionalData);

        const registerHikariPhoneInfo = await SpfApiHikariPhoneAccessor.registerHikariPhone(
          eMansionMemberId,
          eMansionPropertyId,
          servicePlanType,
          optionalData,
          creditCardRegistFlag
        );
        this.$store.commit('eMansionUcomHikariPhoneRegisterAPIResponseStore/info', registerHikariPhoneInfo);
      } catch (error) {
        /**ロールバック処理としてカード削除 */
        if (Number(this.tempStorage.creditCardRegistFlag) === 1 && creditCardApiResultProcess) {
          await this.deleteIspCard(UA_TYPE.E_MANSION, creditCardApiResultProcess, member);
        }
        this.$store.commit('eMansionUcomHikariPhoneRegisterAPIResponseStore/info', null);
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      await this.$router
        .push('/e-mansion/hikari-phone/completed')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {});
      this.isLoading = false;
      return;
    } else if (this.process === 'e-mansion-hikari-phone-update') {
      const member: Member = await this.$store.dispatch('memberStore/member');

      /** カード新規登録またはコピーの結果*/
      let creditCardApiResultProcess = null;
      try {
        // 画面遷移前に申込んだオプションをストアに格納しなおす
        const hikariPhoneInfo = this.tempStorage.optionalData.Hikariphone;
        Number(hikariPhoneInfo.numberDisplay) === 1
          ? this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_1', true)
          : this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_1', false);
        Number(hikariPhoneInfo.catch) === 1
          ? this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_2', true)
          : this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_2', false);
        Number(hikariPhoneInfo.catchNumberDisplay) === 1
          ? this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_3', true)
          : this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_3', false);
        Number(hikariPhoneInfo.optionPack) === 1
          ? this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_4', true)
          : this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_4', false);

        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          //カード新規登録
          // Portas,e-mansionにカードが未登録の場合
          this.creditCardApiResult = await SpfApiService.registerIspCard(
            UA_TYPE.E_MANSION,
            member.eTncMemberId!,
            this.tempStorage.creditCardToken,
            this.tempStorage.tokenExpireDate,
            SERVICE_PROVIDER.MYE
          );
          creditCardApiResultProcess = this.creditCardApiResult.process;
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          //カードコピー
          // PortasからISPにカード情報をコピーする場合
          await SpfApiService.copyToIspCard(UA_TYPE.E_MANSION, member.eTncMemberId!, this.tempStorage.veriTransAccountId, SERVICE_PROVIDER.MYE);
          creditCardApiResultProcess = 'AccountCopy';
        }
      } catch {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          this.errorMessages.push(`クレジットカード情報のコピーに失敗しました。<br>
                Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`);
        }
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

        //前の画面に戻る
        this.backToScreen();
        return;
      }

      /** e-mansion  サービス更新API（UCOM光電話）*/
      try {
        // サービス更新API（UCOM光電話）を呼び出す

        //リクエストを準備
        const eMansionMemberId = this.tempStorage.memberId;
        const eMansionPropertyId = this.tempStorage.propertyId;
        const creditCardRegistFlag = this.tempStorage.creditCardRegistFlag;
        const hikariPhoneInfo = this.tempStorage.optionalData.Hikariphone;
        const servicePlanType = await this.$store.dispatch('servicePlanTypeStore/servicePlanType');
        // サービス更新APIに進む前のデータ取得
        const optionalData = new EMansionHikariPhoneUpdateRequestOptionalDataDto({
          Hikariphone: {
            notificationFlag: '',
            numberDisplay: hikariPhoneInfo.numberDisplay ? '1' : '',
            catch: hikariPhoneInfo.catch ? '1' : '',
            catchNumberDisplay: hikariPhoneInfo.catchNumberDisplay ? '1' : '',
            optionPack: hikariPhoneInfo.optionPack ? '1' : '',
          },
        });

        const updateHikariPhoneInfo = await SpfApiHikariPhoneAccessor.updateHikariPhone(eMansionMemberId, eMansionPropertyId, servicePlanType, optionalData, creditCardRegistFlag);
        this.$store.commit('eMansionUcomHikariPhoneOptionEntriedInfoStore/info', updateHikariPhoneInfo);
      } catch (error) {
        /**ロールバック処理としてカード削除 */
        if (Number(this.tempStorage.creditCardRegistFlag) === 1 && creditCardApiResultProcess) {
          await this.deleteIspCard(UA_TYPE.E_MANSION, creditCardApiResultProcess, member);
        }
        this.$store.commit('eMansionUcomHikariPhoneOptionEntriedInfoStore/info', null);
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      await this.$router
        .push('/e-mansion/hikari-phone/option/application-completed')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {});
      this.isLoading = false;
      return;
    } else if (this.process === 'e-mansion-virus-apply') {
      /** e-mansionウイルスチェック購入 */
      const eMansionVirusCheckUpdateResponseDto = await SpfApiVirusCheckAccessor.updateVirusCheck(this.tempStorage.emailAddressId, this.tempStorage.id, this.tempStorage.applyDate);

      // エラーメッセージがあれば画面に表示する
      if (eMansionVirusCheckUpdateResponseDto.message) {
        this.errorMessages.push(eMansionVirusCheckUpdateResponseDto.message);
        this.backToScreen();
        return;
      }
      //次画面に渡すために保存
      this.$store.commit('eMansionMailStore/setSelectedMailAddress', eMansionVirusCheckUpdateResponseDto.emailAddress);
      // 完了画面へ遷移
      await this.$router.push('/e-mansion/mail/virus-check-apply-complete').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    } else if (this.process === 'e-mansion-sub-account-apply') {
      /** e-mansion追加アカウント申込 */
      const subAccountAddInfo = await SpfApiSubAccountAccessor.addSubAccount(
        this.tempStorage.memberId,
        this.tempStorage.choiceAccount,
        process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN,
        this.tempStorage.memberType,
        this.tempStorage.domainFlag,
        this.tempStorage.domainName,
        this.tempStorage.date
      );
      // エラーメッセージがあれば画面に表示する
      if (subAccountAddInfo && subAccountAddInfo.resultMessage && subAccountAddInfo.resultMessage.length > 0) {
        this.errorMessages = subAccountAddInfo.resultMessage;
        this.backToScreen();
        return;
      }
      //次画面に渡すために保存
      this.$store.commit('eMansionSubAccountStore/desiredAccount', this.tempStorage.desiredAccount);
      this.$store.commit('eMansionSubAccountStore/mailAddress', subAccountAddInfo.mailAddress);
      this.$store.commit('eMansionSubAccountStore/mailPassword', subAccountAddInfo.mailPassword);
      this.$store.commit('eMansionSubAccountStore/popServer', subAccountAddInfo.popServer);
      this.$store.commit('eMansionSubAccountStore/smtpServer', subAccountAddInfo.smtpServer);
      this.$store.commit('eMansionSubAccountStore/imapServer', subAccountAddInfo.imapServer);
      this.$store.commit('eMansionSubAccountStore/mailSubdomain', subAccountAddInfo.mailSubdomain);
      // 完了画面へ遷移
      await this.$router.push('/e-mansion/sub-account/complete').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    } else if (this.process === 'e-mansion-homepage-capacity-change') {
      /** e-mansionホームページ容量変更 */
      const response = await SpfApiHomepageCapacityAccessor.updateEMansionHomepageCapacity(
        this.tempStorage.eMansionAccountId,
        this.tempStorage.eMansionHomepageId,
        this.tempStorage.eMansionHomepageCapacitySelected?.key ?? '',
        this.tempStorage.processOn
      );
      // エラーメッセージがあれば画面に表示する
      if (response.errorMessages.length > 0) {
        this.errorMessages = response.errorMessages;
        this.backToScreen();
        return;
      }
      //次画面に渡すために保存
      this.$store.commit('eMansionHomepageCapacityStore/eMansionAccountId', this.tempStorage.eMansionAccountId);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.tempStorage.eMansionHomepageId);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacitySelected', this.tempStorage.eMansionHomepageCapacitySelected?.key);
      //完了画面へ遷移
      await this.$router.push('/e-mansion/homepage-capacity/complete').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    } else if (this.process === 'ucom-apply') {
      /** UCOM 新規登録 */
      // UCOM同時お申し込みAPIに渡すリクエストボディを作成
      const linkServiceWithUcomRequest = new LinkServiceWithUcomRequest({
        applyDate: this.tempStorage.applyDate,
        firstName: this.tempStorage.firstName,
        givenName: this.tempStorage.givenName,
        firstNameKana: this.tempStorage.firstNameKana,
        givenNameKana: this.tempStorage.givenNameKana,
        postalCode: this.tempStorage.postalCode,
        addressPrefectures: this.tempStorage.addressPrefectures,
        addressMunicipalities: this.tempStorage.addressMunicipalities,
        addressTown: this.tempStorage.addressTown,
        addressStreet: this.tempStorage.addressStreet,
        addressNumber: this.tempStorage.addressNumber,
        sendBuildingName: this.tempStorage.sendBuildingName,
        roomNumber: this.tempStorage.roomNumber,
        eMail: this.tempStorage.eMail,
        phoneNumber: this.tempStorage.phoneNumber,
        birthday: this.tempStorage.birthday,
        tenancyStatus: this.tempStorage.tenancyStatus,
        expectedMoveInOn: this.tempStorage.expectedMoveInOn,
        paymentMethodId: this.tempStorage.paymentMethodId,
        creditCardToken: this.tempStorage.creditCardToken,
        cardAccountId: this.tempStorage.cardAccountId,
        isApplyOpticalPhone: this.tempStorage.isApplyOpticalPhone,
        opticalPhoneNumberGetMethodId: this.tempStorage.opticalPhoneNumberGetMethodId,
        opticalPhonePostalCode: this.tempStorage.opticalPhonePostalCode,
        opticalPhoneAddress: this.tempStorage.opticalPhoneAddress,
        opticalPhoneBuildingName: this.tempStorage.opticalPhoneBuildingName,
        opticalPhoneTakeoverNumber: this.tempStorage.opticalPhoneTakeoverNumber,
        opticalPhoneHolderCategory: this.tempStorage.opticalPhoneHolderCategory,
        opticalPhoneHolderName: this.tempStorage.opticalPhoneHolderName,
        opticalPhoneHolderNameKana: this.tempStorage.opticalPhoneHolderNameKana,
        opticalPhoneUseCompany: this.tempStorage.opticalPhoneUseCompany,
        opticalPhoneUseCompanyOther: this.tempStorage.opticalPhoneUseCompanyOther,
        opticalPhoneImpossibleServices: this.tempStorage.opticalPhoneImpossibleServices,
        opticalPhoneNumberNotificationId: this.tempStorage.opticalPhoneNumberNotificationId,
        isApplyGateCall_050: this.tempStorage.isApplyGateCall_050,
        isSendRegistrationCard: this.tempStorage.isSendRegistrationCard,
        propertyId: this.tempStorage.propertyId,
        importFormId: this.tempStorage.importFormId,
      });

      // リクエストをストアに保存
      this.$store.commit('ucomEntryStore/linkServiceWithUcomRequest', linkServiceWithUcomRequest);
      this.$store.commit('importFormIdStore/importFormId', this.tempStorage.importFormId);
      this.$store.commit('ucomEntryStore/entryInputForm', this.tempStorage.entryInput);
      this.$store.commit('ucomEntryStore/externalApiProperty', this.tempStorage.entryProperty);

      //登録中画面へ遷移
      await this.$router.push('/ucom/entry/during-registration-process').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    } else if (this.process === 'ucom-connectix-apply') {
      /** UCOM Connectix申込 */
      // リクエストを準備
      const request = new UcomConnectixNewConnectixRequest({
        property_id: this.tempStorage.propertyId,
        customer_id: this.tempStorage.customerId,
        room_number: this.tempStorage.roomNumber,
        is_register_credit_card: this.tempStorage.isRegisterCreditCard,
        credit_card_token: this.tempStorage.creditCardToken,
      });

      if (this.tempStorage.isAgreedCopyCardToIsp) {
        //カードコピーに同意している場合のみ、カード会員IDを渡す
        request.card_account_id = this.tempStorage.cardAccountId;
      }
      //Connectixお申し込みAPIを実行
      const resultCreateConnectix: undefined | UcomConnectixErrorResponse = await UcomConnectixExternalApiService.createConnectix(request);
      if (resultCreateConnectix instanceof UcomConnectixErrorResponse) {
        // UCOM Connectix申込API の エラーについて、業務エラーにしたいエラーだったとき
        if (UcomConnectixErrorService.isBusinessError(resultCreateConnectix)) {
          // 未開通エラー
          if (UcomConnectixErrorService.isNotYesOpenError(resultCreateConnectix)) {
            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
            this.errorMessages.push(
              '只今、UCOM光 レジデンス会員登録のお手続き中のため、お申し込みいただけません。<br>\
              お客様情報の反映まで1時間～半日かかる可能性がございます。恐れ入りますがお時間を空けて再度お試しいただきますようお願いいたします。'
            );

            // クレジットカード起因のエラー
          } else if (UcomConnectixErrorService.isCreditCardError(resultCreateConnectix)) {
            this.errorMessageTitle = 'お支払い方法登録で問題が発生しました。';
            // クレカコピーのとき
            if (this.tempStorage.isAgreedCopyCardToIsp) {
              this.errorMessages.push('恐れ入りますがクレジットカードのご利用状況を確認の上、再度お試しいただきますようお願いいたします。');
            } else {
              this.errorMessages.push(
                '恐れ入りますが、入力されたクレジットカード詳細情報（クレジットカード番号・有効期限・カード名義人・セキュリティコード）をお確かめのうえ、再度お試しいただくか、別のクレジットカードでお試しください。'
              );
            }
          }
          this.backToScreen();
          return;
        } else {
          //それ以外のエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONNECTIX);
        }
      } else {
        // 正常ケースの場合
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/connectix/ucom/completed').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
    } else if (this.process === 'ucom-mail-box-capacity-change') {
      /**UCOM メールボックス容量変更 */
      const requestDto = new UpdateUcomMbCapacityRequest(
        this.tempStorage.memberId,
        this.tempStorage.ucomAccountMailId!,
        this.tempStorage.changeCapacityLabel,
        this.tempStorage.changeCapacityValue,
        this.tempStorage.effectiveDate
      );
      const response = await SpfApiMailboxCapacityAccessor.updateUcomMbCapacity(requestDto);
      // エラーメッセージがあれば画面に表示する
      if (response.errorMessages && response.errorMessages.length > 0) {
        this.errorMessages = response.errorMessages;
        this.backToScreen();
        return;
      }
      //次画面に渡すために保存
      this.$store.commit('ucomMailboxCapacityStore/notifyMailAddress', response.notifyMailAddress);
      this.$store.commit('ucomMailboxCapacityStore/mailAddress', this.tempStorage.mailAddress);
      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', this.tempStorage.ucomAccountMailId);
      this.$store.commit('ucomMailboxCapacityStore/accountName', this.tempStorage.accountName);
      this.$store.commit('ucomMailboxCapacityStore/mailBoxCapacity', response.mailCapacity);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityLabel', response.changeCapacityLabel);
      this.$store.commit('ucomMailboxCapacityStore/price', response.price);
      this.$store.commit('ucomMailboxCapacityStore/subscriptionDate', response.subscriptionDate);
      this.$store.commit('ucomMailboxCapacityStore/effectiveDate', this.tempStorage.effectiveDate);
      this.$store.commit('ucomMailboxCapacityStore/serviceDescription', this.tempStorage.serviceDescription);
      // 完了画面へ遷移
      await this.$router.push('/ucom/mailbox-capacity/completed').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    } else if (this.process === 'ucom-web-space-apply') {
      /**UCOM Webスペース容量変更 */
      const response = await SpfApiWebSpaceAccessor.updateUcomWebSpaceCapacity(
        this.tempStorage.memberId,
        this.tempStorage.ucomAccountMailId,
        this.tempStorage.afterChangeWebSpaceCapacityLabel,
        this.tempStorage.afterChangeWebSpaceCapacityValue,
        this.tempStorage.targetDate
      );
      // エラーメッセージがあれば画面に表示する
      if (response.errorMessages) {
        this.errorMessages.push(response.errorMessages);
        this.backToScreen();
        return;
      }
      //次画面に渡すために保存
      this.$store.commit('ucomWebSpaceStore/mailAddress', this.tempStorage.mailAddress);
      this.$store.commit('ucomWebSpaceStore/notifyMailAddress', response.notifyMailAddress);
      this.$store.commit('ucomWebSpaceStore/accountName', response.accountName);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', response.homepageAddress);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', response.webSpaceCapacity);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', response.changeCapacityLabel);
      this.$store.commit('ucomWebSpaceStore/unitPrice', response.price);
      this.$store.commit('ucomWebSpaceStore/applicationDate', response.subscriptionDate);
      this.$store.commit('ucomWebSpaceStore/serviceDescription', response.serviceDescription);
      // 完了画面へ遷移
      await this.$router.push('/ucom/web-space/complete').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    } else if (this.process === 'ucom-virus-check-apply') {
      /**UCOM ウイルスチェック購入 */
      // ウイルスチェック情報更新 (購入/解約) API 呼出し
      const virusCheckUpdateResult = await SpfApiUcomVirusCheckAccessor.updateUcomVirusCheckPurchase(
        new UpdateUcomVirusCheckRequest(this.tempStorage.memberId, this.tempStorage.accountId, this.tempStorage.purchaseState.subscriptionDate, this.tempStorage.action)
      );
      // エラーメッセージがあれば画面に表示する
      if (virusCheckUpdateResult.errorMessage) {
        this.errorMessages.push(virusCheckUpdateResult.errorMessage);
        this.backToScreen();
        return;
      }
      //次画面に渡すために保存
      this.$store.commit('ucomVirusCheckStore/setPurchaseState', this.tempStorage.purchaseState);
      this.$store.commit('ucomVirusCheckStore/setNotifyMailAddress', virusCheckUpdateResult.mailAddress);

      // 完了画面へ遷移
      await this.$router.push('/ucom/virus-check/purchase-complete').catch((e: any) => {
        checkRouterError(e);
      });
      this.isLoading = false;
      return;
    } else if (this.process === 'ucom-mail-service-apply') {
      const updateUcomMailPack = await SpfApiMailPackAccessor.updateUcomMailPackInfo(
        this.tempStorage.memberId,
        this.tempStorage.ucomAccountId,
        this.tempStorage.subscriptionOn,
        this.tempStorage.monthlyFee,
        this.tempStorage.productName,
        this.tempStorage.serviceDescription
      );

      if (updateUcomMailPack.message) {
        this.errorMessages.push(updateUcomMailPack.message);
        this.backToScreen();
        return;
      }
      this.$store.commit('ucomMailPackStore/contactMailAddress', updateUcomMailPack.mailAddress);
      this.$store.commit('ucomMailPackStore/productName', this.tempStorage.productName);
      this.$store.commit('ucomMailPackStore/monthlyFee', this.tempStorage.monthlyFee);
      this.$store.commit('ucomMailPackStore/processDate', this.tempStorage.subscriptionOn);
      this.$store.commit('ucomMailPackStore/serviceDescription', this.tempStorage.serviceDescription);

      await this.$router.push('/ucom/mail-pack/complete').catch((e: any) => {
        checkRouterError(e);
      });
      this.isLoading = false;

      return;
    } else if (this.process === 'e-mansion-payment-methods-register') {
      const uaType = this.tempStorage.uaType;
      const veriTransMemberId = this.tempStorage.veriTransMemberId;
      const creditCardToken = this.tempStorage.creditCardToken;
      const tokenExpireDate = this.tempStorage.tokenExpireDate;
      const serviceProviderIsp = this.tempStorage.serviceProviderIsp;
      const memberId = this.tempStorage.memberId;
      const myeMemberName = this.tempStorage.myeMemberName;
      const myeMailAddress = this.tempStorage.myeMailAddress;
      const primaryKeyMye = this.tempStorage.primaryKeyMye;
      const apartmentId = this.tempStorage.apartmentId;
      const servicePlanType = this.tempStorage.servicePlanType;

      const result = await (async () => {
        try {
          return await SpfApiService.registerIspCard(uaType, veriTransMemberId, creditCardToken, tokenExpireDate, serviceProviderIsp);
        } catch (error: any) {
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages = [`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`];
          this.backToScreen();
          return;
        }
      })();

      if (!result) {
        return;
      }

      try {
        await SpfApiService.registerPaymentMethodInfo(memberId, myeMemberName, myeMailAddress, uaType, apartmentId, primaryKeyMye, servicePlanType);
      } catch (e: any) {
        if (!e.response.data.errors?.includes('Mail Sending Error')) {
          if (result?.process !== 'CardInfoUpdate') {
            await SpfApiService.deleteIspCard(uaType, veriTransMemberId, SERVICE_PROVIDER.MYE);
          }
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages = [`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`];
          this.backToScreen();
          return;
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/payment-methods/register-completed').catch((error: any) => {
        checkRouterError(error);
      });

      return;
    } else if (this.process === 'e-mansion-payment-methods-update') {
      const uaType = this.tempStorage.uaType;
      const veriTransMemberId = this.tempStorage.veriTransMemberId;
      const creditCardToken = this.tempStorage.creditCardToken;
      const tokenExpireDate = this.tempStorage.tokenExpireDate;
      const serviceProviderIsp = this.tempStorage.serviceProviderIsp;
      const memberId = this.tempStorage.memberId;
      const myeMemberName = this.tempStorage.myeMemberName;
      const myeMailAddress = this.tempStorage.myeMailAddress;
      const primaryKeyMye = this.tempStorage.primaryKeyMye;
      const apartmentId = this.tempStorage.apartmentId;
      const servicePlanType = this.tempStorage.servicePlanType;

      const result = await (async () => {
        try {
          return await SpfApiService.registerIspCard(uaType, veriTransMemberId, creditCardToken, tokenExpireDate, serviceProviderIsp);
        } catch (error: any) {
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages = [`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`];
          this.backToScreen();
          return;
        }
      })();

      if (!result) {
        return;
      }

      try {
        await SpfApiService.registerPaymentMethodInfo(memberId, myeMemberName, myeMailAddress, uaType, apartmentId, primaryKeyMye, servicePlanType);
      } catch (e: any) {
        if (!e.response.data.errors?.includes('Mail Sending Error')) {
          if (result.process !== 'CardInfoUpdate') {
            await SpfApiService.deleteIspCard(uaType, veriTransMemberId, SERVICE_PROVIDER.MYE);
          }
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages = [`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`];
          this.backToScreen();
          return;
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/e-mansion/payment-methods/register-completed').catch((error: any) => {
        checkRouterError(error);
      });

      return;
    } else if (this.process === 'five-a-payment-methods-register') {
      const uaType = this.tempStorage.uaType;
      const veriTransMemberId = this.tempStorage.veriTransMemberId;
      const creditCardToken = this.tempStorage.creditCardToken;
      const tokenExpireDate = this.tempStorage.tokenExpireDate;
      const serviceProviderIsp = this.tempStorage.serviceProviderIsp;
      const memberId = this.tempStorage.memberId;
      const myeMemberName = this.tempStorage.myeMemberName;
      const myeMailAddress = this.tempStorage.myeMailAddress;
      const primaryKeyMye = this.tempStorage.primaryKeyMye;
      const apartmentId = this.tempStorage.apartmentId;
      const servicePlanType = this.tempStorage.servicePlanType;

      const result = await (async () => {
        try {
          return await SpfApiService.registerIspCard(uaType, veriTransMemberId, creditCardToken, tokenExpireDate, serviceProviderIsp);
        } catch (error: any) {
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages = [`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`];
          this.backToScreen();
          return;
        }
      })();

      if (!result) {
        return;
      }

      try {
        await SpfApiService.registerPaymentMethodInfo(memberId, myeMemberName, myeMailAddress, uaType, apartmentId, primaryKeyMye, servicePlanType);
      } catch (e: any) {
        if (!e.response.data.errors?.includes('Mail Sending Error')) {
          if (result?.process !== 'CardInfoUpdate') {
            await SpfApiService.deleteIspCard(uaType, veriTransMemberId, SERVICE_PROVIDER.MYE);
          }
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages = [`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`];
          this.backToScreen();
          return;
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/5a/payment-methods/register-completed').catch((error: any) => {
        checkRouterError(error);
      });

      return;
    } else if (this.process === 'five-a-payment-methods-update') {
      const uaType = this.tempStorage.uaType;
      const veriTransMemberId = this.tempStorage.veriTransMemberId;
      const creditCardToken = this.tempStorage.creditCardToken;
      const tokenExpireDate = this.tempStorage.tokenExpireDate;
      const serviceProviderIsp = this.tempStorage.serviceProviderIsp;
      const memberId = this.tempStorage.memberId;
      const myeMemberName = this.tempStorage.myeMemberName;
      const myeMailAddress = this.tempStorage.myeMailAddress;
      const primaryKeyMye = this.tempStorage.primaryKeyMye;
      const apartmentId = this.tempStorage.apartmentId;
      const servicePlanType = this.tempStorage.servicePlanType;

      const result = await (async () => {
        try {
          return await SpfApiService.registerIspCard(uaType, veriTransMemberId, creditCardToken, tokenExpireDate, serviceProviderIsp);
        } catch (error: any) {
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages = [`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`];
          this.backToScreen();
          return;
        }
      })();

      if (!result) {
        return;
      }

      try {
        await SpfApiService.registerPaymentMethodInfo(memberId, myeMemberName, myeMailAddress, uaType, apartmentId, primaryKeyMye, servicePlanType);
      } catch (e: any) {
        if (!e.response.data.errors?.includes('Mail Sending Error')) {
          if (result.process !== 'CardInfoUpdate') {
            await SpfApiService.deleteIspCard(uaType, veriTransMemberId, SERVICE_PROVIDER.MYE);
          }
          this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
          this.errorMessages = [`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`];
          this.backToScreen();
          return;
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/5a/payment-methods/register-completed').catch((error: any) => {
        checkRouterError(error);
      });

      return;
    } else if (this.process === 'e-mansion-i-filter-apply') {
      const member: Member = await this.$store.dispatch('memberStore/member');
      const property: Property = await this.$store.dispatch('propertyStore/property', member.propertyId);
      let cardRegistProcess;
      try {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          //カード新規登録
          const cardRegistResult = await SpfApiService.registerIspCard(
            UA_TYPE.E_MANSION,
            member.eTncMemberId!,
            this.tempStorage.creditCardToken,
            this.tempStorage.tokenExpireDate,
            SERVICE_PROVIDER.MYE
          );
          cardRegistProcess = cardRegistResult.process;
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          //カードコピー
          await SpfApiService.copyToIspCard(UA_TYPE.E_MANSION, member.eTncMemberId!, this.tempStorage.veriTransAccountId, SERVICE_PROVIDER.MYE);
          cardRegistProcess = 'AccountCopy';
        }
      } catch {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          this.errorMessages.push(`クレジットカード情報のコピーに失敗しました。<br>
            Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`);
        }
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        //前の画面に戻る
        this.backToScreen();
        return;
      }

      try {
        // サービス登録API(i-フィルター) を呼び出す
        //リクエストを準備
        const apartmentId = property.apartmentId!;
        const primaryKeyMye = member.primaryKeyMye!;
        const creditCardRegistFlag = this.tempStorage.creditCardRegistFlag;
        const quantity = this.tempStorage.quantity;

        await SpfApiIfilterAccessor.registerIfilter(primaryKeyMye, apartmentId, creditCardRegistFlag, quantity);
      } catch (error: any) {
        /**ロールバック処理としてカード削除 */
        if (cardRegistProcess !== 'CardInfoUpdate') {
          //クレジットカード削除
          await SpfApiService.deleteIspCard(UA_TYPE.E_MANSION, member.eTncMemberId!, SERVICE_PROVIDER.MYE);
        }
        await VueErrorHandler.handle(error, '');
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });

        this.isLoading = false;
        return;
      }

      //完了画面へ遷移
      await this.$router.push('/e-mansion/i-filter/complete').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
      return;
    } else if (this.process === 'e-mansion-ip-phone-apply') {
      const member: Member = await this.$store.dispatch('memberStore/member');
      /** カード新規登録またはコピーの結果*/
      let creditCardApiResultProcess = null;
      try {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          //カード新規登録
          // Portas,e-mansionにカードが未登録の場合
          this.creditCardApiResult = await SpfApiService.registerIspCard(
            UA_TYPE.E_MANSION,
            member.eTncMemberId!,
            this.tempStorage.creditCardToken,
            this.tempStorage.tokenExpireDate,
            SERVICE_PROVIDER.MYE
          );
          creditCardApiResultProcess = this.creditCardApiResult.process;
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          //カードコピー
          // PortasからISPにカード情報をコピーする場合
          await SpfApiService.copyToIspCard(UA_TYPE.E_MANSION, member.eTncMemberId!, this.tempStorage.veriTransAccountId, SERVICE_PROVIDER.MYE);
          creditCardApiResultProcess = 'AccountCopy';
        }
      } catch {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          this.errorMessages.push(`クレジットカード情報のコピーに失敗しました。<br>
                Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`);
        }
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

        //前の画面に戻る
        this.backToScreen();
        return;
      }

      /** e-mansion サービス登録API(IPフォン)*/
      try {
        // サービス登録API(IPフォン) を呼び出す
        const ippResponse = await SpfApiIPPoneAccessor.registerIPPhone(
          this.tempStorage.memberId,
          this.tempStorage.propertyId,
          this.tempStorage.inputApplyForm.deliveryDay,
          this.tempStorage.inputApplyForm.deliveryTimeZone.value,
          this.tempStorage.inputDestinationForm.contractDestinationKind,
          this.tempStorage.inputDestinationForm.contractDestinationKind === '4' ? this.tempStorage.inputDestinationForm.contractDestinationMailAddress : '',
          this.tempStorage.inputDestinationForm.contractDestinationKind === '1' ? this.tempStorage.inputDestinationForm.contractDestinationZipCode : '',
          this.tempStorage.inputDestinationForm.contractDestinationKind === '1' ? this.tempStorage.inputDestinationForm.contractDestinationAddress1 : '',
          this.tempStorage.inputDestinationForm.contractDestinationKind === '1' ? this.tempStorage.inputDestinationForm.contractDestinationAddress2 : '',
          this.tempStorage.creditCardRegistFlag
        );

        //次画面に渡すために保存
        this.$store.commit('eMansionIPPhoneStore/registerResult', ippResponse);
        this.$store.commit('eMansionIPPhoneStore/inputDestinationForm', this.tempStorage.inputDestinationForm);
      } catch (error: any) {
        /**ロールバック処理としてカード削除 */
        if (creditCardApiResultProcess !== 'CardInfoUpdate') {
          await this.deleteIspCard(UA_TYPE.E_MANSION, creditCardApiResultProcess, member);
        }
        await VueErrorHandler.handle(error, 'created hook');
        await this.$router.push('/e-mansion/error').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
        return;
      }

      await this.$router
        .push('/e-mansion/ip-phone/completed')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        });
      //.finally(() => {});
      this.isLoading = false;
      return;
    } else if (this.process === 'e-mansion-course-apply') {
      const member: Member = await this.$store.dispatch('memberStore/member');
      const property: Property = await this.$store.dispatch('propertyStore/property', member.propertyId);
      let cardRegistProcess;
      try {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          //カード新規登録
          const cardRegistResult = await SpfApiService.registerIspCard(
            UA_TYPE.E_MANSION,
            member.eTncMemberId!,
            this.tempStorage.creditCardToken,
            this.tempStorage.tokenExpireDate,
            SERVICE_PROVIDER.MYE
          );
          cardRegistProcess = cardRegistResult.process;
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          //カードコピー
          await SpfApiService.copyToIspCard(UA_TYPE.E_MANSION, member.eTncMemberId!, this.tempStorage.veriTransAccountId, SERVICE_PROVIDER.MYE);
          cardRegistProcess = 'AccountCopy';
        }
      } catch {
        if (this.tempStorage.creditCardToken && this.tempStorage.tokenExpireDate) {
          this.errorMessages.push(
            `お支払い方法の登録が完了できませんでした。<br>恐れ入りますが画面の更新を行って頂き、もう一度カード番号、カード期限、カード名義人、セキュリティコードをご入力ください。`
          );
        } else if (this.tempStorage.isAgreedCopyCardToIsp && this.tempStorage.veriTransAccountId) {
          this.errorMessages.push(`クレジットカード情報のコピーに失敗しました。<br>
            Portasに登録されているお支払い情報をご確認いただくか、新規にクレジットカード情報をご登録ください。`);
        }
        this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        //前の画面に戻る
        this.backToScreen();
        return;
      }

      try {
        // 接続先コース変更API を呼び出す
        //リクエストを準備
        const apartmentId = property.apartmentId!;
        const memberId = member.primaryKeyMye!;

        const courseId = this.tempStorage.courseId!;
        const contractDestinationKind = this.tempStorage.contractDestinationKind;
        const contractDestinationMailaddress = this.tempStorage.contractDestinationMailaddress;
        const contractDestinationZipcode = this.tempStorage.contractDestinationZipcode;
        const contractDestinationAddress1 = this.tempStorage.contractDestinationAddress1;
        const contractDestinationAddress2 = this.tempStorage.contractDestinationAddress2;
        const creditCardRegistFlag = this.tempStorage.creditCardRegistFlag;
        const emailaddress = this.tempStorage.emailaddress;

        const response = await SpfApiCourseAccessor.registerAnotherCourse(
          courseId,
          contractDestinationKind,
          contractDestinationMailaddress,
          contractDestinationZipcode,
          contractDestinationAddress1,
          contractDestinationAddress2,
          creditCardRegistFlag,
          apartmentId,
          memberId
        );

        this.$store.commit('selectedCourseConfirmStore/status', response.status);
        this.$store.commit('selectedCourseConfirmStore/memberId', response.member_id);
        this.$store.commit('selectedCourseConfirmStore/courseId', response.course_id);
        this.$store.commit('selectedCourseConfirmStore/receiveTime', response.receive_time);
        this.$store.commit('selectedCourseConfirmStore/showTpl', response.show_tpl);
        this.$store.commit('selectedCourseConfirmStore/dispConstructionCompleteDate', response.disp_construction_complete_date);
        this.$store.commit('selectedCourseConfirmStore/basicFee', response.billing_data.basic_fee);
        this.$store.commit('selectedCourseConfirmStore/basicFeeCp', response.billing_data.basic_fee_cp);
        this.$store.commit('selectedCourseConfirmStore/tesuryoFee', response.billing_data.tesuryo_fee);
        this.$store.commit('selectedCourseConfirmStore/tesuryoFeeCp', response.billing_data.tesuryo_fee_cp);
        this.$store.commit('selectedCourseConfirmStore/chgConstruction', response.billing_data.em_chg_construction);
        this.$store.commit('selectedCourseConfirmStore/chgConstructionCp', response.billing_data.em_chg_construction_cp);
        this.$store.commit('selectedCourseConfirmStore/adapterCharge', response.billing_data.adapter_charge);
        this.$store.commit('selectedCourseConfirmStore/adapterChargeCp', response.billing_data.adapter_charge_cp);
        this.$store.commit('selectedCourseStore/setEquipmentcontract', this.tempStorage.storeEquipmentcontract);
        this.$store.commit('selectedCourseStore/type', this.tempStorage.storetype);
        this.$store.commit('selectedCourseStore/setSpeed', this.tempStorage.storeSelectedSpeed);
        this.$store.commit('selectedCourseStore/checkMyCurrentCourseId', this.tempStorage.checkMyCurrentCourseId);
        this.$store.commit('selectedCourseStore/contractDestinationKind', contractDestinationKind);
        this.$store.commit('selectedCourseStore/contractDestinationMailaddress', contractDestinationMailaddress);
        this.$store.commit('selectedCourseStore/contractDestinationZipcode', contractDestinationZipcode);
        this.$store.commit('selectedCourseStore/contractDestinationAddress1', contractDestinationAddress1);
        this.$store.commit('selectedCourseStore/contractDestinationAddress2', contractDestinationAddress2);

        if (emailaddress) {
          this.$store.commit('selectedCourseStore/emailaddress', emailaddress);
        }

        if (this.tempStorage.isContainCpFee) {
          this.$store.commit('selectedCourseStore/isContainCpFee', true);
        }
      } catch (error: any) {
        /**ロールバック処理としてカード削除 */
        if (cardRegistProcess !== 'CardInfoUpdate') {
          //クレジットカード削除
          await SpfApiService.deleteIspCard(UA_TYPE.E_MANSION, member.eTncMemberId!, SERVICE_PROVIDER.MYE);
        }

        await VueErrorHandler.handle(error, 'created hook');
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });

        this.isLoading = false;
        return;
      }

      //完了画面へ遷移
      await this.$router.push('/e-mansion/course/complete').catch((error: any) => {
        checkRouterError(error);
      });

      this.isLoading = false;
      return;
    }
    await this.$router.push('/error').catch((error: any) => {
      checkRouterError(error);
    });
    this.isLoading = false;
  },
  methods: {
    /** 支払方法一覧取得 APIを実行し、画面表示用のプロパティを追加して実行結果を取得する */
    async getPaymentMethodInfoList(memberId: number): Promise<PaymentMethodInfoForDisplay | null> {
      try {
        const paymentMethodList = await SpfApiService.getPaymentMethodList(memberId);
        let paymentMethodInfo: PaymentMethodInfoForDisplay | null = null;

        for (const eachPaymentMethod of paymentMethodList) {
          const eachPaymentMethodForDisplay: PaymentMethodInfoForDisplay = {
            ...eachPaymentMethod,
            isExpired: isCreditCardExpired(eachPaymentMethod.cardExpire.slice(0, 2), String(processYearNumber()).slice(0, 2) + eachPaymentMethod.cardExpire.slice(3, 5)),
          };
          if (!eachPaymentMethod.deleteDate) {
            paymentMethodInfo = eachPaymentMethodForDisplay;
            return paymentMethodInfo;
          }
        }
        return paymentMethodInfo;
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          // 404 エラーの場合、空を返す
          return null;
        } else {
          throw error; // エラーを再スローするか、適切な処理を行う
        }
      }
    },
    /** サービスお申し込み完了 画面への遷移処理 */
    async transitEntryCompletedScreen(productNameArray: Array<string | undefined>, productIdArray: Array<string | undefined>): Promise<void> {
      // 支払方法登録に成功すると、会員.VeriTrans 会員ID に値が登録される
      // ページ遷移時に beforeEach で会員情報取得できるように null にする
      this.$store.commit('memberStore/member', null);

      // サービス契約申込ストアにConnectixの契約要否を保存
      this.$store.commit('platformProductEntryStore/setIsConnectix', this.tempStorage.isConnectix);
      // サービス契約申込ストアに商品名配列を保存
      this.$store.commit('platformProductEntryStore/setProductNameArray', productNameArray);
      // サービス契約申込ストアに商品ID配列を保存
      this.$store.commit('platformProductEntryStore/setProductIdArray', productIdArray);
      // サービス契約申込ストアにUA種別を保存
      this.$store.commit('platformProductEntryStore/setIspName', this.tempStorage.ispName);

      // 終了処理
      await this.$router.push('/platform/my-page/products/entry-completed').catch((error: any) => {
        checkRouterError(error);
      });
    },
    /**
     * 新規会員登録・サービス同時申し込みAPIを実行する
     */
    async contractFromEntryPortas(): Promise<number[] | undefined> {
      // 申込日時
      const serverDateTimeNow = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
      const contractAppliedAt: string = dayjs(serverDateTimeNow).format('YYYY-MM-DDTHH:mm:ssZ');

      if (this.tempStorage.form) {
        try {
          if (this.tempStorage.form.wishToContractProductIdList) {
            // 新規会員登録・サービス同時お申し込み処理

            // 商品 ID 配列
            const productIdArray = this.tempStorage.form.wishToContractProductIdList.map((product: { toString: () => any }) => product.toString());

            const contractFromEntryPortasRequest = new ContractFromEntryPortasRequest({
              member: this.tempStorage.member,
              creditCardToken: this.tempStorage.cardTokenForRegister.token,
              tokenExpireDate: this.tempStorage.cardTokenForRegister.tokenExpireDate,
              productIdArray: productIdArray,
              contractAppliedAt: contractAppliedAt,
            });

            // 新規会員登録・サービス同時お申し込みAPI実行
            const createdContract = await SpfApiService.contractFromEntryPortas(contractFromEntryPortasRequest);
            await AuthService.refresh();
            // 正常ケースの場合
            // バックエンドで会員ステータスが更新される
            // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
            this.$store.commit('memberStore/memberStatus', null);

            return createdContract;
          } else {
            throw new Error('お申し込み対象のサービスが存在しません');
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (axios.isAxiosError(e)) {
            // メールアドレスが重複した場合、 /platform/entry/input に遷移してエラーメッセージ表示
            if (e.response?.status === 409) {
              this.errorMessages = ['ご入力いただいたメールアドレスは既に登録されています。'];
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              this.$store.commit('errorMessageStore/errorMessages', this.errorMessages);
              this.$store.commit('errorMessageStore/errorMessageTitle', this.errorMessageTitle);
              await this.$router.push('/platform/entry/input').catch((error: any) => {
                checkRouterError(error);
              });
              return;
            } else if (e.response?.status === 500 && e.response?.data.errors.includes('cannot create payment method')) {
              this.errorMessageTitle = 'お支払い方法の登録中にエラーが発生したため、お申し込みを完了できませんでした。';
              this.errorMessages = [`恐れ入りますが、いま一度、カード番号、カード期限、カード名義人、セキュリティコードをご入力のうえ、お申し込みください。`];

              this.$store.commit('platformEntryStore/entryInputForm', this.tempStorage.form);
              this.backToScreen();
              return;
              // サービス申し込み手続き自体には成功したが、手続完了メールの送信処理に失敗した場合は申し込み完了画面に遷移する
            } else if (e.response?.status === 500 && e.response?.data.errors.includes('Sending Contract Completion Mail is Failed')) {
              await AuthService.refresh();

              // 正常ケースの場合
              // バックエンドで会員ステータスが更新される
              // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
              this.$store.commit('memberStore/memberStatus', null);

              return this.tempStorage.form.wishToContractProductIdList;
            } else {
              throw e;
            }
          }
        }
      }
    },
    /**
     * エラーメッセージを保存して
     * 前の画面に戻る
     * */
    async backToScreen(): Promise<void> {
      this.$store.commit('errorMessageStore/errorMessages', this.errorMessages);
      this.$store.commit('errorMessageStore/errorMessageTitle', this.errorMessageTitle);
      // サービスお申し込み画面へ遷移する場合 クエリパラメータを作成する
      const connectingProducts = (): string => {
        // notExistIdProductNameArray(connectix等)があれば、配列を連結する
        if (this.tempStorage.notExistIdProductNameArray) {
          const combinedArray = [...this.tempStorage.notExistIdProductNameArray, ...this.tempStorage.productIdArray];
          return combinedArray.map((productId, productIndex) => `product${productIndex + 1}=${productId}`).join('&');
        }
        return this.tempStorage.productIdArray.map((productId: string, productIndex: string) => `product${productIndex + 1}=${productId}`).join('&');
      };
      if (this.process === 'payment_methods_input') {
        await this.$router.push('/platform/my-page/payment-method/input').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'payment_methods_edit') {
        await this.$router.push('/platform/my-page/payment-method/list').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'service-entry-regist') {
        await this.$router.push(`/platform/my-page/products/entry-input?${connectingProducts()}`).catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'service-entry-copy') {
        await this.$router.push(`/platform/my-page/products/entry-input?${connectingProducts()}`).catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'service-entry-registed') {
        await this.$router.push(`/platform/my-page/products/entry-input?${connectingProducts()}`).catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'service-entry-regist-member') {
        this.$store.commit('platformEntryStore/entryInputForm', this.tempStorage.form);
        await this.$router.push('/platform/entry/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'five-a-apply') {
        delete this.tempStorage.hikariphone;
        this.$store.commit('fiveAEntryStore/entryInputForm', this.tempStorage.request);
        await this.$router.push('/5a/entry/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'five-a-connectix-apply') {
        this.$store.commit('fiveAConnectixEntryStore/entryInputForm', { roomNumber: this.tempStorage.room_number });
        await this.$router.push('/connectix/5a/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'e-mansion-entry') {
        this.$store.commit('eMansionCommonStore/property', this.tempStorage.eMansionproperty);
        this.$store.commit('eMansionEntryStore/entryInputForm', this.tempStorage.customer);
        this.$store.commit('eMansionEntryStore/beforePathConfirm', this.tempStorage.beforePath);
        await this.$router.push('/e-mansion/entry/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'e-mansion-connectix-apply') {
        this.$store.commit('eMansionConnectixEntryStore/entryInputForm', this.tempStorage.entryInputForm);
        await this.$router.push('/connectix/e-mansion/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'e-mansion-virus-apply') {
        const member = await this.$store.dispatch('memberStore/member');
        await this.$store.dispatch('propertyStore/property', member.propertyId);
        await this.$store.dispatch('memberStore/memberStatus');
        this.$store.commit('eMansionMailStore/setSelectedMailAddressId', this.tempStorage.emailAddressId);
        this.$store.commit('eMansionMailStore/setSelectedMailAddress', this.tempStorage.mailAddress);
        this.$store.commit('eMansionMailStore/setSelectedFeeInfo', this.tempStorage.feeInfo);
        this.$store.commit('taxStore/tax', this.tempStorage.tax);
        await this.$router.push('/e-mansion/mail/virus-check-apply-confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'e-mansion-sub-account-apply') {
        this.$store.commit('eMansionSubAccountStore/desiredAccount', this.tempStorage.desiredAccount);
        this.$store.commit('eMansionSubAccountStore/usageFee', this.tempStorage.usageFee);
        await this.$router.push('/e-mansion/sub-account/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'e-mansion-homepage-capacity-change') {
        this.$store.commit('eMansionHomepageCapacityStore/eMansionAccountId', this.tempStorage.eMansionAccountId);
        this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.tempStorage.eMansionHomepageId);
        this.$store.commit('eMansionHomepageCapacityStore/ftpAccount', this.tempStorage.ftpAccount);
        this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacity', this.tempStorage.eMansionHomepageCapacity);
        this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacitySelected', this.tempStorage.eMansionHomepageCapacitySelected);
        this.$store.commit('eMansionHomepageCapacityStore/fee', this.tempStorage.fee);
        this.$store.commit('eMansionHomepageCapacityStore/jimFee', this.tempStorage.jimFee);
        this.$store.commit('eMansionHomepageCapacityStore/maxQuota', this.tempStorage.maxQuota);
        this.$store.commit('eMansionHomepageCapacityStore/hpQuota', this.tempStorage.hpQuota);
        this.$store.commit('eMansionHomepageCapacityStore/isCampaign', this.tempStorage.isCampaign);
        this.$store.commit('taxStore/tax', this.tempStorage.tax);
        await this.$router.push('/e-mansion/homepage-capacity/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'e-mansion-gip-apply') {
        // ストアにMACアドレスをコミットする
        this.$store.commit('eMansionMacAddressStore/macAddress', this.tempStorage.requestMacAddress);

        await this.$router.push('/e-mansion/static-gip/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'e-mansion-hikari-phone-register') {
        const inputData = this.tempStorage.inputData;
        this.$store.commit('eMansionHikariphoneStore/inputData', inputData);
        await this.$router.push('/e-mansion/hikari-phone/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'e-mansion-hikari-phone-update') {
        // 画面遷移前に申込んだオプションをストアに格納しなおす(カード認証失敗時用)
        const hikariPhoneInfo = this.tempStorage.optionalData.Hikariphone;
        Number(hikariPhoneInfo.numberDisplay) === 1
          ? this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_1', true)
          : this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_1', false);
        Number(hikariPhoneInfo.catch) === 1
          ? this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_2', true)
          : this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_2', false);
        Number(hikariPhoneInfo.catchNumberDisplay) === 1
          ? this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_3', true)
          : this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_3', false);
        Number(hikariPhoneInfo.optionPack) === 1
          ? this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_4', true)
          : this.$store.commit('eMansionUcomHikariPhoneOptionEntryStore/option_4', false);

        await this.$router.push('/e-mansion/hikari-phone/option/application-confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'ucom-apply') {
        this.$store.commit('importFormIdStore/importFormId', this.tempStorage.importFormId);
        this.$store.commit('ucomEntryStore/entryInputForm', this.tempStorage.entryInput);
        this.$store.commit('ucomEntryStore/externalApiProperty', this.tempStorage.entryProperty);
        this.$store.commit('memberStore/member', this.tempStorage.member);

        await this.$router.push('/ucom/entry/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'ucom-connectix-apply') {
        this.$store.commit('ucomConnectixEntryStore/entryInputForm', { roomNumber: this.tempStorage.roomNumber });
        await this.$router.push('/connectix/ucom/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'ucom-mail-box-capacity-change') {
        this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', this.tempStorage.ucomAccountMailId);
        this.$store.commit('ucomMailboxCapacityStore/accountName', this.tempStorage.accountName);
        this.$store.commit('ucomMailboxCapacityStore/mailAddress', this.tempStorage.mailAddress);
        this.$store.commit('ucomMailboxCapacityStore/mailBoxCapacity', this.tempStorage.mailBoxCapacity);
        this.$store.commit('ucomMailboxCapacityStore/changeCapacityLabel', this.tempStorage.changeCapacityLabel);
        this.$store.commit('ucomMailboxCapacityStore/changeCapacityValue', this.tempStorage.changeCapacityValue);
        this.$store.commit('ucomMailboxCapacityStore/price', this.tempStorage.price);
        this.$store.commit('ucomMailboxCapacityStore/effectiveDate', this.tempStorage.effectiveDate);
        this.$store.commit('ucomMailboxCapacityStore/serviceDescription', this.tempStorage.serviceDescription);
        await this.$router.push('/ucom/mailbox-capacity/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'ucom-web-space-apply') {
        this.$store.commit('ucomWebSpaceStore/paymentStatus', this.tempStorage.paymentStatus);
        this.$store.commit('ucomWebSpaceStore/accountName', this.tempStorage.accountName);
        this.$store.commit('ucomWebSpaceStore/mailAddress', this.tempStorage.mailAddress);
        this.$store.commit('ucomWebSpaceStore/homepageAddress', this.tempStorage.homepageAddress);
        this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', this.tempStorage.webSpaceCapacity);
        this.$store.commit('ucomWebSpaceStore/changeCapacityList', this.tempStorage.changeCapacityList);
        this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', this.tempStorage.afterChangeWebSpaceCapacityLabel);
        this.$store.commit('ucomWebSpaceStore/changeCapacityValue', this.tempStorage.afterChangeWebSpaceCapacityValue);
        this.$store.commit('ucomWebSpaceStore/unitPrice', this.tempStorage.unitPrice);
        this.$store.commit('ucomWebSpaceStore/webSpaceMaxCapacity', this.tempStorage.webSpaceMaxCapacity);
        this.$store.commit('ucomWebSpaceStore/applicationDate', this.tempStorage.applicationDate);
        this.$store.commit('ucomWebSpaceStore/serviceDescription', this.tempStorage.serviceDescription);
        this.$store.commit('ucomWebSpaceStore/ucomAccountMailId', this.tempStorage.ucomAccountMailId);
        await this.$router.push('/ucom/web-space/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'ucom-virus-check-apply') {
        this.$store.commit('ucomVirusCheckStore/setPurchaseState', this.tempStorage.purchaseState);
        await this.$router.push('/ucom/virus-check/purchase-confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'ucom-mail-service-apply') {
        this.$store.commit('ucomMailPackStore/productName', this.tempStorage.productName);
        this.$store.commit('ucomMailPackStore/monthlyFee', this.tempStorage.monthlyFee);
        this.$store.commit('ucomMailPackStore/processDate', this.tempStorage.processDate);
        this.$store.commit('ucomMailPackStore/serviceDescription', this.tempStorage.serviceDescription);
        this.$store.commit('ucomMailPackStore/isActiveButton', this.tempStorage.isActiveButton);
        await this.$router.push('/ucom/mail-pack/confirm').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'e-mansion-payment-methods-register' || this.process === 'e-mansion-payment-methods-update') {
        await this.$router.push('/e-mansion/payment-methods/register').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'five-a-payment-methods-register' || this.process === 'five-a-payment-methods-update') {
        await this.$router.push('/5a/payment-methods/register').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      } else if (this.process === 'e-mansion-i-filter-apply') {
        await this.$router.push('/e-mansion/i-filter/confirm').catch((error: any) => {
          checkRouterError(error);
        });
      } else if (this.process === 'e-mansion-ip-phone-apply') {
        this.$store.commit('eMansionIPPhoneStore/inputApplyForm', this.tempStorage.inputApplyForm);
        this.$store.commit('eMansionIPPhoneStore/inputDestinationForm', this.tempStorage.inputDestinationForm);
        await this.$router.push('/e-mansion/ip-phone/confirm').catch((error: any) => {
          checkRouterError(error);
        });
      } else if (this.process === 'e-mansion-course-apply') {
        this.$store.commit('selectedCourseStore/setEquipmentcontract', this.tempStorage.storeEquipmentcontract);
        this.$store.commit('selectedCourseStore/setSpeed', this.tempStorage.storeSelectedSpeed);
        this.$store.commit('selectedCourseStore/setCharge', this.tempStorage.storeSelectedCharge);
        this.$store.commit('selectedCourseStore/adapterCharge', this.tempStorage.storeCharge);
        this.$store.commit('selectedCourseStore/storeGrade', this.tempStorage.storeGrade);
        this.$store.commit('selectedCourseStore/type', this.tempStorage.storetype);
        this.$store.commit('selectedCourseStore/courseId', this.tempStorage.courseId);
        const contractDestinationKind = this.tempStorage.contractDestinationKind;
        const contractDestinationMailaddress = this.tempStorage.contractDestinationMailaddress;
        const contractDestinationZipcode = this.tempStorage.contractDestinationZipcode;
        const contractDestinationAddress1 = this.tempStorage.contractDestinationAddress1;
        const contractDestinationAddress2 = this.tempStorage.contractDestinationAddress2;
        const inputMailAddressConfirm = this.tempStorage.inputMailAddressConfirm;
        const zipcodeFormer = this.tempStorage.zipcodeFormer;
        const zipcodeLatter = this.tempStorage.zipcodeLatter;
        this.$store.commit('selectedCourseStore/checkMyCurrentCourseId', this.tempStorage.checkMyCurrentCourseId);
        this.$store.commit('selectedCourseStore/contractDestinationKind', contractDestinationKind);
        this.$store.commit('selectedCourseStore/contractDestinationMailaddress', contractDestinationMailaddress);
        this.$store.commit('selectedCourseStore/contractDestinationMailaddressConfirm', inputMailAddressConfirm);
        this.$store.commit('selectedCourseStore/contractDestinationZipcode', contractDestinationZipcode);
        this.$store.commit('selectedCourseStore/contractDestinationAddress1', contractDestinationAddress1);
        this.$store.commit('selectedCourseStore/contractDestinationAddress2', contractDestinationAddress2);

        //本人認証に失敗した場合のみ、郵便番号の入力値をストア情報に保存する
        this.$store.commit('selectedCourseStore/zipcodeFormer', zipcodeFormer);
        this.$store.commit('selectedCourseStore/zipcodeLatter', zipcodeLatter);

        await this.$router.push('/e-mansion/course/confirm').catch((error: any) => {
          checkRouterError(error);
        });
      }
    },
    async deleteIspCard(uaType: string, creditCardApiResultProcess: string, member: Member): Promise<void> {
      /**ロールバック処理として,CardInfoUpdate(カード更新)以外の場合はベリトランスからカード削除する
       * AccountAdd : アカウント＆カード登録
       * CardInfoAdd : カード登録
       * CardInfoUpdate : カード更新
       * AccountCopy : アカウントコピー
       */
      if (creditCardApiResultProcess) {
        if (creditCardApiResultProcess != 'CardInfoUpdate') {
          //クレジットカード削除
          await SpfApiService.deleteIspCard(uaType, member.eTncMemberId!, SERVICE_PROVIDER.MYE);
        }
      }
    },
  },
});
</script>
