<template>
  <div class="gip-terms">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion 固定グローバルIPアドレス</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li>
          <router-link to="/e-mansion">e-mansion 会員専用トップ</router-link>
        </li>
        <li>固定グローバルIPアドレス</li>
      </ul>
      <!-- /breadcrumb -->

      <div class="blc">
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="固定グローバルIP　アドレスサービス利用規約" />固定グローバルIPアドレスサービス利用規約</h2>
        <div v-if="isTtp"><a :href="`${eMansionUrl}/pdf/ttp/kiyaku.pdf`" class="link" target="_blank">固定グローバルIPアドレス　サービス利用規約 </a></div>
        <div v-else><a :href="`${eMansionUrl}/pdf/service/gipkiyaku.pdf`" class="link" target="_blank">固定グローバルIPアドレス　サービス利用規約 </a></div>
      </div>
      <div class="btn-area">
        <div class="has-checkbox">
          <button class="btn btn05 bs" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs sp-margin" type="button" :disabled="!isDisabledButton" @click="onApply()">
            規約に同意して申し込む <i class="material-icons link link-icon">east</i>
          </button>
          <div class="my-checkbox-outer">
            <label>
              <input id="checkbox-to-agree" type="checkbox" v-model="isAgreeTerms" />
              <span for="checkbox-to-agree">固定グローバルIPアドレス サービス利用規約に同意します</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
div.btn-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  text-align: center;
  & div.has-checkbox {
    padding-top: 16px;
    & div.my-checkbox-outer {
      position: absolute;
      top: 16px;
      left: 0;
      & input[type='checkbox'] {
        margin-right: 10px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
      }
    }
  }
}
/* 検索ボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}

@media (max-width: 767px) {
  .sp-margin {
    margin-top: 10px;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import store from '@/store';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { E_MANSION_MAIN_ACCOUNT_PLAN_TYPE } from '@/shared/const/e-mansion/e-mansion-main-account-plan-type';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
/** Completed コンポーネント */
export default defineComponent({
  name: 'static-global-ip-terms',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** サービス利用規約に同意する */
    isAgreeTerms: false,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** e-mansion物件情報 */
    eMansionProperty: null as EMansionProperty | null,
    /** TTP物件 */
    isTtp: true as boolean,
    isMaxCourseGrade: 0,
    /** e-mansion サイト URL */
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
  }),
  computed: {
    isDisabledButton(): boolean {
      if (!this.isAgreeTerms || this.errorMessages.length > 0 || this.isLoading) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    /* 物件基本情報取得API.固定グローバルIP提供個数 の値 <=
    契約基本情報取得API.オプション利用状況.固定GIP の要素数（＝契約中件数）の場合、
    固定GIP ご利用状況画面へリダイレクトさせる
     */
    try {
      await StoreExternalApiResponse.main();
      this.eMansionProperty = store.getters['eMansionCommonStore/property'];
      // TTP物件情報取得
      this.isTtp = this.isPropertyTypeTtp();
      const eMansionCustomer = store.getters['eMansionCommonStore/customer'];

      const optionStatusMacAddressLength = eMansionCustomer.op.globalip.length;
      if (this.eMansionProperty) {
        if (Number(this.eMansionProperty.op.gip.max) <= optionStatusMacAddressLength) {
          await this.$router
            .push('/e-mansion/static-gip/usage')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              checkRouterError(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
      }

      /**
       * 最上位コース判定処理修正
       * 1,利用中接続コースのグレードを 契約基本情報取得API.接続コース情報 から取得
       * 2,物件基本情報取得API.接続コース情報 の配列の内、最大のグレード値をもつ要素を取得
       * 3, 1 < 2.グレード && GIPイレギュラーフラグ の値が「1:なし」の場合、帯域エラーを表示
       */

      const connectionCorseOnCustomer = eMansionCustomer.cs.grade;

      // 物件基本情報API.グレードの最大値を取得する
      if (this.eMansionProperty.cs.some((item) => item.course_id)) {
        const maxGrade = Math.max(...this.eMansionProperty.cs.map((item) => parseFloat(item.grade)).filter((grade) => !isNaN(grade)));
        // 最大値に一致(複数あるケースも想定)
        this.isMaxCourseGrade = maxGrade;
      }

      const gipIrregularFlag = this.eMansionProperty.em_gipir_flag;

      if (connectionCorseOnCustomer < this.isMaxCourseGrade && Number(gipIrregularFlag) === 1) {
        this.errorMessages.push(
          '大変申し訳ございません。<br/>「固定グローバルIPアドレス」をご利用いただくには、最上位メニューをご契約いただく必要がございます。<br/>最上位メニューへのコース変更をお申し込みいただいた上で、「固定グローバルIPアドレス」のお申し込みお手続きをお願いいたします。<br/><a href="/e-mansion/course/input"  class="link no-icon">接続コース変更お申し込みフォームへ</a>'
        );
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }
    } catch (error: any) {
      checkRouterError(error);
    }

    this.isLoading = false;
  },
  methods: {
    isPropertyTypeTtp() {
      // TTP物件の場合にTrueと判断
      if (this.eMansionProperty instanceof EMansionProperty) {
        const apartmentSpecialTypeFlag = this.eMansionProperty.apartment_special_type_flag;
        if (!apartmentSpecialTypeFlag) {
          return false;
        }
        if (!apartmentSpecialTypeFlag[0]) {
          return false;
        }
        const flags = apartmentSpecialTypeFlag[0];
        const FLAG_TRUE = '1';
        return flags[E_MANSION_MAIN_ACCOUNT_PLAN_TYPE.TTP] === FLAG_TRUE;
      }
      return false;
    },
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.$store.commit('eMansionGIPStore/from', null);
          this.isSubmitting = false;
        });
    },
    async onApply() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;

      if (this.isAgreeTerms) {
        await this.$router
          .push('/e-mansion/static-gip/application')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.$store.commit('eMansionGIPStore/from', null);
            this.isSubmitting = false;
          });
      }
    },
  },
});
</script>
