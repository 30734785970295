import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';
import store from '@/store';
import { AuthService } from './auth/auth-service';

/**e-mansion画面初期表示でのチェック処理 */
export class MountedCheckService {
  public static async canReadEMansionScreen(): Promise<boolean> {
    const isLoggedIn = await AuthService.isLoggedIn();
    const member: Member = await store.dispatch('memberStore/member');
    const property: Property = await store.dispatch('propertyStore/property', member.propertyId);
    //未ログイン、会員・物件が無い、UA種別がe-mansionでない、primaryKeyMyeがない　場合はNG
    if (!isLoggedIn || !member || !property || property.uaType !== UA_TYPE.E_MANSION || !member.primaryKeyMye) {
      return false;
    }
    return true;
  }
  public static async canReadEMansionEntryScreen(): Promise<boolean> {
    const isLoggedIn = await AuthService.isLoggedIn();
    const member: Member = await store.dispatch('memberStore/member');
    const property: Property = await store.dispatch('propertyStore/property', member.propertyId);
    //未ログイン、会員・物件が無い、UA種別がe-mansionでない、primaryKeyMyeがある　場合はNG
    if (!isLoggedIn || !member || !property || property.uaType !== UA_TYPE.E_MANSION || member.primaryKeyMye) {
      return false;
    }
    return true;
  }
  public static async canReadFiveAScreen(): Promise<boolean> {
    const isLoggedIn = await AuthService.isLoggedIn();
    const member: Member = await store.dispatch('memberStore/member');
    const property: Property = await store.dispatch('propertyStore/property', member.propertyId);
    //未ログイン、会員・物件が無い、UA種別がFive.Aでない、Five.A未連携　の場合はNG
    if (!isLoggedIn || !member || !property || property.uaType !== UA_TYPE.FIVE_A || !member.primaryKeyMye) {
      return false;
    }
    return true;
  }
  public static async canReadFiveAEntryScreen(): Promise<boolean> {
    const isLoggedIn = await AuthService.isLoggedIn();
    const member: Member = await store.dispatch('memberStore/member');
    const property: Property = await store.dispatch('propertyStore/property', member.propertyId);
    //未ログイン、会員・物件が無い、UA種別がFive.Aでない、Five.A連携済み　の場合はNG
    if (!isLoggedIn || !member || !property || property.uaType !== UA_TYPE.FIVE_A || member.primaryKeyMye) {
      return false;
    }
    return true;
  }
}
