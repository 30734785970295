<template>
  <LoadingComponent v-if="isLoading" />
  <div class="completed">
    <main class="underlayer-main">
      <h1>e-mansion i-フィルター</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li class="stay">i-フィルター</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="i-フィルター お申し込み" />i-フィルター　お申し込み確認</h2>

        <p>お申し込み内容をご確認ください。</p>
        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <p>
            <span class="red">i-フィルターを申し込む</span>
          </p>
        </div>
      </div>

      <div v-show="!isIftFree">
        <div class="sblc">
          <h3 class="service-h3">お支払い方法</h3>
        </div>

        <div v-if="isNeedDisableTrackingBlockFunction">
          <credit-card-component
            v-if="isMounted"
            :key="resetCounter"
            :reset-counter="resetCounter"
            v-bind:apiTokenKey="apiTokenKey"
            v-bind:successMessage="successMessage"
            v-bind:ispName="ispName"
            v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
            v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
            v-bind:portasVeriTransId="portasVeriTransId"
            v-bind:cardHolderNameFromIsp="cardHolderNameFromIsp"
            v-on:onPostCreditCard="getCreditCardToken"
            v-on:onPostCreditCardError="executeCreditCardError"
            v-on:getTokenExpireDate="getTokenExpireDate"
            v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
            v-on:change="isAgreedCopyCardToIsp = $event"
            v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
            v-on:portasCardHolderName="getCardHolderNameFromPortas"
            v-on:cardholderNameFormForAuthorizeIspCard="getCardholderNameFormForAuthorizeIspCard"
            v-on:cardholderNameFormForAuthorizePortasCard="getCardholderNameFormForAuthorizePortasCard"
          />

          <div class="blc">
            <p class="border-grey bg-grey pd20 mt40 ml40">
              ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
              ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
            </p>
            <p class="form-btn-txt mt40">
              ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」ボタンをクリックしてください。
            </p>
            <p class="red form-btn-txt">
              「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
            </p>
          </div>
        </div>
        <div v-else>
          <div>ご登録のお支払い方法により、お支払いいただきます。</div>
        </div>
      </div>

      <div class="btn-area">
        <div class="has-checkbox">
          <div class="my-checkbox-outer" v-show="!noDisplayCreditCardForm && !isIftFree">
            <input id="checkbox-to-agree" type="checkbox" v-model="isAgreedPrivacyPolicy" :disabled="isExpiredPortasCreditCard" />
            <label for="checkbox-to-agree">クレジットカード会社での本人認証のための情報提供に同意してお申し込みを確定する</label>
          </div>
          <button class="btn-height btn btn05 bs sp-margin" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn-height btn btn01 bs sp-margin" type="button" :disabled="isDisabledButton" v-on:click="onNext()">
            お申し込みを確定する<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { SpfApiIfilterAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-ifilter-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'platform-terms-i-filter',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
    CreditCardComponent,
  },
  data(): {
    // 会員情報
    member: Member | null;
    // 会員ステータス
    memberStatus: MemberStatus | null;
    isNotifyEmail: boolean;
    // e-mansion サイト URL
    eMansionUrl: string;
    // 8桁APIDE
    apid: string;
    getFlagDebug: string;
    /** エラーメッセージを格納する配列 */
    errorMessages: string[];
    errorMessageTitle: string;
    /** APIトークンキー */
    apiTokenKey: string;
    successMessage: string;
    /** クレジットカード編集用カードトークン */
    cardTokenForEdit: string;
    /** クレジットカード編集用カードトークン有効期限 */
    cardTokenExpireDateForEdit: string;
    /** 支払い方法の登録状態によってクレジットカードコンポーネントの表示/非表示を切り替える */
    noDisplayCreditCardForm: boolean;
    isIftFree: boolean;
    portasVeriTransId: string;
    /** e-mansion に登録済のVeriTransAccountId */
    emansionVeriTransAccountId: string;
    /** ISPから取得したカード名義人 */
    cardHolderNameFromIsp: string | undefined;
    /** e-mansionから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: string;
    /** e-mansionから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: string;
    /** クレジットカードアクセストークン */
    creditCardAccessToken: string;
    /** ユーザーがe-mansionへカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: boolean;

    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: boolean;
    creditTokenExpireDate: string;
    cardHolderNameOnPortas: string;
    cardTokenFor3dSecureAuthorize: string;
    cardholderNameFormForAuthorizeIspCard: string;
    cardholderNameFormForAuthorizePortasCard: string;

    /** リセット回数 */
    resetCounter: number;
    ispName: string;

    isLoading: boolean;
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: boolean;

    isAgreedPrivacyPolicy: boolean;
    isSubmitting: boolean;
    eMansionMemberId: string;
    eMansionPropertyId: string;
    ispMemberStatusEMansion: string;
    /** 本人認証処理を実行するため、ブラウザのトラッキング防止機能を無効化する必要があるかどうか */
    isNeedDisableTrackingBlockFunction: boolean;
    isPaymantCreditCard: boolean;
    // 現在の契約件数
    quantity: string;
  } {
    return {
      member: null,
      memberStatus: null,
      isNotifyEmail: false,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      apid: '',
      getFlagDebug: '',
      errorMessages: [] as string[],
      /** エラーメッセージ部に表示するタイトル */
      errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
      /** APIトークンキー */
      apiTokenKey: '',
      /** トークン取得成功後に表示するメッセージ */
      successMessage: '画面下の「お支払い方法を保存」ボタンをクリックしてください',
      /** クレジットカード編集用カードトークン */
      cardTokenForEdit: '',
      /** クレジットカード編集用カードトークン有効期限 */
      cardTokenExpireDateForEdit: '',
      /** 支払い方法の登録状態によってクレジットカードコンポーネントの表示/非表示を切り替える */
      noDisplayCreditCardForm: false,
      isIftFree: false,

      portasVeriTransId: '',
      /** e-mansion に登録済のVeriTransAccountId */
      emansionVeriTransAccountId: '',
      /** ISPから取得したカード名義人 */
      cardHolderNameFromIsp: '' as string | undefined,
      /** e-mansionから取得したクレジットカードのマスク済カード番号 */
      maskedCardNumberFromIsp: '',
      /** e-mansionから取得したクレジットカードの有効期限 */
      cardExpiredFromIsp: '',
      /** クレジットカードアクセストークン */
      creditCardAccessToken: '',
      /** ユーザーがe-mansionへカード情報をコピーすることに同意しているかどうか */
      isAgreedCopyCardToIsp: false,
      /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
      isExpiredPortasCreditCard: false,

      creditTokenExpireDate: '',
      cardHolderNameOnPortas: '',
      cardTokenFor3dSecureAuthorize: '',
      cardholderNameFormForAuthorizeIspCard: '',
      cardholderNameFormForAuthorizePortasCard: '',
      /** リセット回数 */
      resetCounter: 0,
      /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面ではe-mansion固定) */
      ispName: 'e-mansion',
      isLoading: true,

      /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
      isMounted: false,
      /** 利用規約への同意 */
      isAgreedPrivacyPolicy: false,
      /** ボタン押下判定 */
      isSubmitting: false,
      /** e-mansion 会員ID */
      eMansionMemberId: '' as string,
      /** 物件ID */
      eMansionPropertyId: '',
      /** ISP(e-mansion)会員ステータス */
      ispMemberStatusEMansion: '',
      /** 本人認証処理を実行するため、ブラウザのトラッキング防止機能を無効化する必要があるかどうか */
      isNeedDisableTrackingBlockFunction: true,
      isPaymantCreditCard: false,
      quantity: '',
    };
  },
  async mounted(): Promise<void> {
    this.isLoading = true;

    if (!(await MountedCheckService.canReadEMansionScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    const member: Member = await this.$store.dispatch('memberStore/member');
    this.eMansionMemberId = member.primaryKeyMye ?? '';

    if (!member.primaryKeyMye) {
      this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // APIトークンキーを設定
    this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION;

    // 物件情報の取得APIからフィルター価格を取得
    const emansionProperty: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
    if (emansionProperty?.IN_APID) {
      this.apid = emansionProperty?.IN_APID;
    }

    const ift_fee: string = emansionProperty?.op.ift.fee;
    const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];

    if (customer instanceof EMansionCustomer) {
      if (customer.op.ifilter && Array.isArray(customer.op.ifilter)) {
        this.quantity = String(customer.op.ifilter.length);
      } else {
        this.quantity = '0';
      }
    }

    if (ift_fee === '0') {
      this.isIftFree = true;
    }

    // i-フィルターの値段が0円ではない場合、本人認証処理及び、登録処理を行う
    if (ift_fee) {
      //契約基本情報取得API.支払い方法種別 の値が「0:口座振替, 1:振込, 4:口振(請求書), 8:クレジットカード」いずれかのチェック

      if (customer instanceof EMansionCustomer) {
        /**
         * 0: 口座振替
         * 1: 振込
         * 4: 口振（請求書）
         * 8: クレジットカード
         */
        const paymentIdsWithoutCreditCard = ['0', '1', '4'];
        const paymentIdsWithCreditCard = ['8'];

        if (paymentIdsWithoutCreditCard.some((paymentIdsWithoutCreditCard) => paymentIdsWithoutCreditCard === customer.payment_id)) {
          this.noDisplayCreditCardForm = true;
          this.isNeedDisableTrackingBlockFunction = false;
        } else if (paymentIdsWithCreditCard.some((paymentIdsWithCreditCard) => paymentIdsWithCreditCard === customer.payment_id)) {
          this.isPaymantCreditCard = true;
        } else {
          this.noDisplayCreditCardForm = false;
        }
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      this.eMansionPropertyId = customer.property_id;

      if (customer instanceof EMansionCustomer && customer.payment_id === '8' && member.eTncMemberId) {
        const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
        this.cardExpiredFromIsp = cardInfo.cardExpire;
        this.maskedCardNumberFromIsp = cardInfo.cardNumber;
        this.cardHolderNameFromIsp = cardInfo.cardholderName;
        this.emansionVeriTransAccountId = member.eTncMemberId;
      } else if (member.veritransAccountId) {
        this.portasVeriTransId = member.veritransAccountId;
      }

      // VeriTrans 本人認証失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
        this.creditCardAccessToken = '';
        this.creditTokenExpireDate = '';
      }
      this.isLoading = false;
      this.isMounted = true;
    }
    this.isLoading = false;
  },

  methods: {
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      await this.$router.push('/e-mansion/i-filter/input').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
      return;
    },

    async onNext(): Promise<void> {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      //i-フィルターの値段が無料でない場合に実行
      if (!this.isIftFree) {
        try {
          // 支払い方法種別 の値が「0:口座振替, 1:振込, 4:口振(請求書)のばあいの登録処理（本人認証を行わない。）
          if (this.noDisplayCreditCardForm) {
            try {
              await SpfApiIfilterAccessor.registerIfilter(this.eMansionMemberId, this.eMansionPropertyId, '0', this.quantity);
            } catch (error: any) {
              await VueErrorHandler.handle(error, '');
              await this.$router.push('/e-mansion/error').catch((error) => {
                checkRouterError(error);
              });
            }
            await this.$router.push('/e-mansion/i-filter/complete').catch((error: any) => {
              checkRouterError(error);
            });
            return;
          } else {
            if (this.creditCardAccessToken === '' && !(this.cardExpiredFromIsp && this.maskedCardNumberFromIsp) && !this.isAgreedCopyCardToIsp && !this.noDisplayCreditCardForm) {
              this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
              // エラーメッセージを見せるために画面最上部にスクロール
              this.showErrorMessage();
              return;
            }

            // 支払方法が登録されている場合
            if (this.emansionVeriTransAccountId && this.isPaymantCreditCard) {
              // 申込内容一時保存処理
              // 申込内容一時保存用 UUIDを生成
              const uuidForTemporarySavingApplicationData = uuidv4();
              const paymentMethodApplicationData = {
                quantity: this.quantity,
                veriTransAccountId: this.emansionVeriTransAccountId,
                creditCardRegistFlag: '0',
              };

              // 申込内容をjsonに変換
              const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
              const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
                uuid: uuidForTemporarySavingApplicationData,
                applicationDataJson: paymentMethodApplicationDataJson,
                subsequentProcess: 'e-mansion-i-filter-apply',
              });

              // 申込内容一時保存を実行するAPI
              try {
                await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
              } catch (error: any) {
                // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
                throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
              }

              // 3dセキュア対応用 uuid localStorageに保存
              localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
              let cardHolderNameForAuthorize: string;
              if (this.cardHolderNameFromIsp) {
                cardHolderNameForAuthorize = this.cardHolderNameFromIsp;
              } else {
                cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
              }

              const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
                uuid: uuidForTemporarySavingApplicationData,
                externalVeritransAccountId: this.emansionVeriTransAccountId,
                cardholderName: cardHolderNameForAuthorize,
              });

              // VeriTrans_本人認証(VeriTrans会員ID使用) API
              try {
                const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
                location.href = authStartURL;
              } catch (error: any) {
                if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                  // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                  this.errorMessages.push(
                    `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
                  );
                  this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

                  // コンポーネントの破棄・再描画のために key値 を変更する
                  this.addResetCounter();
                  this.isAgreedPrivacyPolicy = false;
                  this.isSubmitting = false;
                  this.isLoading = false;

                  // エラーメッセージを見せるために画面最上部にスクロール
                  window.scrollTo(0, 0);
                  return;
                } else {
                  throw error;
                }
              }
              return;
            } else if (
              // PortasからISPにカード情報をコピーする場合
              this.isAgreedCopyCardToIsp &&
              this.portasVeriTransId
            ) {
              // 申込内容一時保存処理
              // 申込内容一時保存用 UUIDを生成
              const uuidForTemporarySavingApplicationData = uuidv4();
              const paymentMethodApplicationData = {
                quantity: this.quantity,
                veriTransAccountId: this.portasVeriTransId,
                isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
                creditCardRegistFlag: '1',
              };

              // 申込内容をjsonに変換
              const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
              const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
                uuid: uuidForTemporarySavingApplicationData,
                applicationDataJson: paymentMethodApplicationDataJson,
                subsequentProcess: 'e-mansion-i-filter-apply',
              });

              // 申込内容一時保存を実行するAPI
              try {
                await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
              } catch (error: any) {
                // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
                throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
              }

              // 3dセキュア対応用 uuid localStorageに保存
              localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
              let cardHolderNameForAuthorize: string;
              if (this.cardHolderNameOnPortas) {
                cardHolderNameForAuthorize = this.cardHolderNameOnPortas;
              } else {
                cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizePortasCard!;
              }

              const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
                uuid: uuidForTemporarySavingApplicationData,
                externalVeritransAccountId: this.portasVeriTransId,
                cardholderName: cardHolderNameForAuthorize,
              });

              // VeriTrans_本人認証(VeriTrans会員ID使用) API
              try {
                const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
                location.href = authStartURL;
              } catch (error: any) {
                if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                  // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                  this.errorMessages.push(
                    `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
                  );
                  this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
                  // コンポーネントの破棄・再描画のために key値 を変更する
                  this.addResetCounter();
                  this.isAgreedPrivacyPolicy = false;
                  this.isSubmitting = false;

                  // エラーメッセージを見せるために画面最上部にスクロール
                  window.scrollTo(0, 0);
                  this.isLoading = false;
                  return;
                } else {
                  throw error;
                }
              }
              return;
            } else {
              /**Portas,e-mansionにカードが未登録の場合 */
              // 申込内容一時保存処理
              // 申込内容一時保存用 UUIDを生成
              const uuidForTemporarySavingApplicationData = uuidv4();
              const paymentMethodApplicationData = {
                quantity: this.quantity,
                creditCardToken: this.creditCardAccessToken,
                tokenExpireDate: this.creditTokenExpireDate,
                creditCardRegistFlag: '1',
              };

              // 申込内容をjsonに変換
              const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
              const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
                uuid: uuidForTemporarySavingApplicationData,
                applicationDataJson: paymentMethodApplicationDataJson,
                subsequentProcess: 'e-mansion-i-filter-apply',
              });
              // 申込内容一時保存を実行するAPI
              try {
                await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
              } catch (error: any) {
                // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
                throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
              }

              // 3dセキュア対応用 uuid localStorageに保存
              localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
              // ページ遷移時に beforeEach で会員情報取得できるように null にする
              this.$store.commit('memberStore/member', null);
              const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
                uuid: uuidForTemporarySavingApplicationData,
                creditCardToken: this.cardTokenFor3dSecureAuthorize,
                serviceProviderIsp: SERVICE_PROVIDER.MYE,
              });

              // VeriTrans_本人認証(トークン使用) API
              try {
                const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
                location.href = authStartURL;
              } catch (error: any) {
                if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                  // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                  this.errorMessages.push(
                    `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
                  );
                  this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';

                  // コンポーネントの破棄・再描画のために key値 を変更する
                  this.addResetCounter();
                  this.isAgreedPrivacyPolicy = false;
                  this.isSubmitting = false;

                  // エラーメッセージを見せるために画面最上部にスクロール
                  window.scrollTo(0, 0);
                  this.isLoading = false;
                  return;
                } else {
                  throw error;
                }
              }
            }
          }
        } catch (error: any) {
          if (error.response?.status !== 503) {
            await VueErrorHandler.handle(error, '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }
        }
      } else {
        // i-フィルターの値段が0円の場合、本人認証を行わずお申し込み処理へ
        try {
          await SpfApiIfilterAccessor.registerIfilter(this.eMansionMemberId, this.eMansionPropertyId, '0', this.quantity);
        } catch (error: any) {
          await VueErrorHandler.handle(error, '');
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
        }
        await this.$router.push('/e-mansion/i-filter/complete').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.isLoading = false;
      return;
    },

    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: this.eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });
      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },

    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      this.isLoading = false;
      this.isSubmitting = false;
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      return;
    },

    /** クレジットカードコンポーネントが再描画されるように、 key値 を更新する */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },

    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** クレジットカード編集用クレジットカードトークンを受け取る */
    getCardTokenForEdit(cardTokenForEdit: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.cardTokenForEdit = cardTokenForEdit;
    },
    /** クレジットカード編集用クレジットカードトークン有効期限を受け取る */
    getCardTokenExpireDateForEdit(cardTokenExpireDateForEdit: string) {
      this.cardTokenExpireDateForEdit = cardTokenExpireDateForEdit;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** 3Dセキュア本人認証用カード名義人を受け取る */
    getCardHolderNameFromPortas(cardHolderName: string) {
      this.cardHolderNameOnPortas = cardHolderName;
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** クレジットカードコンポーネントのフォームに入力された、ISP登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizeIspCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizeIspCard = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、Portas登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizePortasCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizePortasCard = cardHolderName;
    },
  },
  computed: {
    isDisabledButton(): boolean {
      if (this.isIftFree) {
        return false;
      } else if (this.noDisplayCreditCardForm) {
        return false;
      } else if (
        this.emansionVeriTransAccountId &&
        ((this.cardHolderNameFromIsp && this.isAgreedPrivacyPolicy) ||
          (this.cardholderNameFormForAuthorizeIspCard && this.cardholderNameFormForAuthorizeIspCard.length > 1 && this.isAgreedPrivacyPolicy))
      ) {
        return false;
      } else if (
        !this.isExpiredPortasCreditCard &&
        (this.cardHolderNameOnPortas || this.cardholderNameFormForAuthorizePortasCard) &&
        this.isAgreedCopyCardToIsp &&
        this.isAgreedPrivacyPolicy
      ) {
        return false;
      } else {
        return this.creditCardAccessToken === '' || !this.isAgreedPrivacyPolicy;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}
.text-align-center {
  text-align: center;
}
.condition {
  margin: 0 0 5px;
  border-left: 3px solid #cf1225;
  padding-left: 15px;
  margin-left: 10px;
}
div.template {
  margin-top: 30px;
}
.btn:disabled {
  opacity: 0.5;
}

div.my-checkbox-outer {
  margin: 16px;
}
div.my-checkbox-outer > input {
  margin-right: 10px;
}
.sp-margin {
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  div.btn-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & div.has-checkbox {
      padding-top: 0;
      & div.my-checkbox-outer {
        top: 0px;
        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}
</style>
