<template>
  <div class="products-entry-input">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>Portasサービス</h1>
    </main>

    <div v-if="!isPurchasable">
      <div class="contents">
        <div class="blc">
          <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="該当の商品はお申し込みできません" />該当の商品はお申し込みできません</h2>
          <p>お客様はこちらの商品をお申し込みすることはできません。</p>
        </div>
        <div class="blc">
          <div class="btn-area">
            <button class="btn-height btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="contents">
        <ul class="breadcrumb">
          <li><router-link to="/platform">トップページ</router-link></li>
          <li>Portasサービス</li>
          <li>お申し込み内容の最終確認</li>
        </ul>
        <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
          <li class="stay">お申し込み内容のご確認</li>
          <li>お申し込み完了</li>
        </ul>
        <div class="blc">
          <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="サービスお申し込み" />Portasサービス お申し込み内容の最終確認</h2>
          <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
          <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
          <div class="sblc">
            <!-- 単品商品のとき -->
            <div v-if="!isProductSet">
              <h3 class="service-h3 my-entry-input"><span v-html="productSetName"></span></h3>

              <div class="my-entry-input-list-outer">
                <ul class="my-entry-input-list">
                  <li class="my-entry-input-list__item">
                    <div class="title">
                      <h4 class="my-title">お申し込み方法</h4>
                    </div>

                    <template v-if="isUnext">
                      <div class="description">
                        <p><span v-html="productSetNameForApplyMethod"></span>のサービス内容、規約、お支払い方法をご確認のうえ、お申し込みください。</p>
                        <p>U-NEXTのサービスは株式会社U-NEXTが提供事業者となりますが、お申し込み・ご解約・ご請求はPortasでのお手続きとなります。</p>
                      </div>
                    </template>

                    <template v-else>
                      <div class="description">
                        <p>「<span v-html="productSetNameForApplyMethod"></span>」のサービス内容、規約、お支払い方法をご確認のうえ、お申し込みください。</p>
                      </div>
                    </template>
                  </li>
                </ul>
              </div>

              <hr class="my-entry-input" />

              <div v-for="product in productList" :key="product.productsId">
                <h3 class="service-h3 my-entry-input"><span v-html="product.productsName"></span></h3>
                <div v-html="product.terms"></div>
                <hr class="my-entry-input" />
              </div>

              <PortasServicePriceListAndNotes v-if="isMounted" v-bind:productsDtoList="[...productList]" />

              <hr class="my-entry-input" />
            </div>

            <!-- 複数商品のとき -->
            <div v-else>
              <h3 class="service-h3 my-entry-input"><span v-html="productSetName"></span>セット</h3>

              <div class="my-entry-input-list-outer">
                <ul class="my-entry-input-list">
                  <li class="my-entry-input-list__item">
                    <div class="title">
                      <h4 class="my-title">お申し込み方法</h4>
                    </div>
                    <div class="description">
                      <template v-if="isUnext">
                        <p><span v-html="productSetNameForApplyMethod"></span>のサービス内容、規約、お支払い方法をご確認のうえ、お申し込みください。</p>
                      </template>

                      <template v-else>
                        <p>「<span v-html="productSetNameForApplyMethod"></span>」のサービス内容、規約、お支払い方法をご確認のうえ、お申し込みください。</p>
                      </template>
                      <p v-if="isUnext && isConnectix">U-NEXTのサービスは株式会社U-NEXTが提供事業者となりますが、お申し込み・ご解約・ご請求はPortasでのお手続きとなります。</p>
                      <p v-if="isConnectix">Connectixは株式会社つなぐネットコミュニケーションズが提供事業者となります。</p>
                      <p v-if="isConnectix">
                        本画面からのお申し込み完了後、「Connectixお申し込み」という案内に従い、引き続き株式会社つなぐネットコミュニケーションズへのお申し込みにお進みください。
                      </p>
                    </div>
                  </li>
                </ul>
              </div>

              <hr class="my-entry-input" />

              <div v-for="product in productList" :key="product.productsId">
                <h3 class="service-h3 my-entry-input"><span v-html="product.productsName"></span></h3>
                <div v-html="product.terms"></div>
                <hr class="my-entry-input" />
              </div>

              <PortasServicePriceListAndNotes v-if="isMounted" v-bind:productsDtoList="[...productList]" v-bind:tryToSignUpForConnectix="isConnectix" />

              <hr class="my-entry-input" />
            </div>
          </div>
        </div>

        <div class="sblc">
          <h3 class="service-h3">Portasサービス お支払い方法</h3>
          <credit-card-component
            :key="resetCounter"
            :reset-counter="resetCounter"
            v-if="isMounted"
            v-bind:apiTokenKey="apiTokenKey"
            v-bind:successMessage="successMessage"
            v-bind:maskedCardNumberOnPortas="maskedCardNumberOnPortas"
            v-bind:cardExpiredOnPortas="cardExpiredOnPortas"
            v-bind:cardholderNameOnPortas="cardholderNameOnPortas"
            v-bind:externalVeriTransId="externalVeriTransId"
            v-bind:ispName="ispName"
            v-bind:ispProviderCompanyName="ispProviderCompanyName"
            v-on:cardTokenForRegister="getCardTokenForRegister"
            v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
            v-on:cardholderNameAtCopy="getCardHolderNameAtCopy"
            v-on:isNeedCardholderName="getIsNeedCardholderName"
            v-on:onPostCreditCardError="executeCreditCardError"
            v-on:cardTokenExpireDateForRegister="getCardTokenExpireDateForRegister"
            v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
            v-on:change="isAgreedCopyCardFromIsp = $event"
          />
        </div>

        <div v-if="campaignFlag" id="specific-page" class="mt15">
          <div class="my-service-image-outer">
            <div class="my-service-image">
              <a :href="portasServiceImageSetplusLoggedIn.url" target="portasServiceImageSetplusLoggedIn">
                <img :src="portasServiceImageSetplusLoggedIn.pc" class="sp" />
                <img :src="portasServiceImageSetplusLoggedIn.sp" class="pc" />
              </a>
            </div>
          </div>
        </div>

        <div class="blc">
          <p v-if="paymentInfoExists" class="border-grey bg-grey pd20 mt40 ml40">
            ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
            ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
          </p>
          <p class="my-cautionary-statement">「お申し込みの確定」ボタンを押すと、お申し込みを確定します。</p>
          <p v-if="isExpiredPortasCreditCard" class="is-not-able-to-use-credit-card">
            <b class="red">ご利用になれないクレジットカードが登録されているため、お申し込みできません。</b>
          </p>
          <p v-if="isExpiredPortasCreditCard" class="is-not-able-to-use-credit-card margin-bottom-16">
            <b class="red">マイページから、Portasにご登録されているクレジットカード情報を変更していただくようお願いいたします。</b>
          </p>
          <div class="my-button-area">
            <div>
              <button class="btn-height btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
            </div>
            <div class="has-checkbox">
              <button class="btn-height btn btn01 bs" type="button" :class="buttonColorSet" :disabled="isDisabledButton" v-on:click="onApply()">
                お申し込みの確定<i class="material-icons link link-icon">east</i>
              </button>
              <div class="my-checkbox-outer">
                <input id="checkbox-to-agree" type="checkbox" v-model="isAgreeTerms" :disabled="isExpiredPortasCreditCard" />
                <p class="my-checkbox-text">
                  <label for="checkbox-to-agree" v-if="campaignFlag">各サービスのご利用規約、キャンペーン応募規約、その他利用条件、情報提供に同意してお申し込みを確定する</label>
                  <label for="checkbox-to-agree" v-else>各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- モーダル表示に関係する領域 -->
  <template v-if="showModal">
    <ModalDisplayEntryUnextProductConfirm ref="ModalDisplayEntryUnextProductConfirm" @catchResult="onApplyUnext" />
  </template>
</template>

<style lang="scss" scoped>
.my-service-image {
  position: relative;
}

/* campaignFlagリンクのスタイル */
#specific-page .campaignFlag {
  color: #fff;
  position: absolute;
  left: 50%; /* 横方向の中央 */
  bottom: 20%; /* 下方向の位置を調整  */
  transform: translateX(-50%); /* 横方向の中央揃え */
  text-decoration: underline;
}

/* campaignFlag内のspanの擬似要素を非表示 */
#specific-page .campaignFlag span::before,
#specific-page .campaignFlag span::after {
  content: none !important;
  display: none !important;
}

#specific-page .my-service-image-outer {
  width: 100%;
  text-align: center;
}

#specific-page .my-service-image {
  width: 100%;
  height: auto;
}

#specific-page .my-service-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

#specific-page .sp {
  display: block;
}

#specific-page .pc {
  display: none;
}

/* モバイル用画像を表示するレスポンシブ対応 */
@media screen and (max-width: 768px) {
  #specific-page .sp {
    display: none;
  }

  #specific-page .pc {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.underlayer-main {
  background-image: url('@/images/main.png');
}

.toBold {
  font-weight: bold;
}

.connectix-btn-css {
  background-color: white;
  border: 1px solid red;
  color: black;
}

.margin-left {
  margin-left: 1em;
}

.table-adjustment-css {
  border-collapse: separate;
  border: none;
  font-weight: normal;
}

.table-type2 th {
  font-weight: normal;
}

button.campaignFlag:disabled {
  opacity: 0.5;
}

.service-h4 {
  font-size: 16px;
  padding-left: 15px;
  border-left: 3px solid #cf1225;
}

.is-not-able-to-use-credit-card {
  padding-left: 15px;

  &.margin-bottom-16 {
    margin-bottom: 16px;
  }
}

.table-type3 {
  width: 65%;
  border-collapse: collapse;
}

.table-type3 th,
.table-type3 td {
  border: 1px solid #000;
  padding: 8px;
}

.table-type3 td:empty {
  border-collapse: none;
}

.table-type3 th {
  background-color: #cf1225;
  color: #ffffff;
}

.table-type3 tr {
  border-bottom: none;
}

.light-gray-background {
  background-color: #d3d3d3;
}

.my-cautionary-statement {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
}

.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

div.my-button-area {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  text-align: center;

  & div.has-checkbox {
    padding-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 16px;
      left: 0;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .my-service-image-outer {
    padding-top: 16px;
  }

  .my-service-image {
    & img {
      &.pc {
        display: none;
      }

      &.sp {
        display: block;
        height: auto;
      }
    }
  }

  div.my-button-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 80px;

    & div.has-checkbox {
      padding-top: 0;

      & div.my-checkbox-outer {
        top: 0;
        left: 20%;
        text-align: left;

        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 1275px) {
  div.my-button-area {
    padding-top: 50px;
  }
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';

import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import PortasServicePriceListAndNotes from '@/pages/platform/components/portas-subscription/portas-service-price-list-and-notes.vue';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodListResponse } from '@/shared/classes/platform/payment-method-list-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { Member } from '@/shared/classes/spf-api/member';
import { ProductsDtoDetail } from '@/shared/classes/spf-api/portas-subscription/products-1-dto';
import { ProductsDto } from '@/shared/classes/spf-api/portas-subscription/products-dto';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ModalDisplayEntryUnextProductConfirm from '@/shared/components/subscription/modal-display-entry-unext-product-confirm.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-can-copy-from-isp.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { CONTENT_PROVIDER_ID } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionVeriTransService } from '@/shared/services/e-mansion/e-mansion-veritrans-service';
import { FiveAVeriTransService } from '@/shared/services/five-a/five-a-veritrans-service';
import { UcomVeriTransService } from '@/shared/services/ucom/ucom-veritrans-service';
import { isCreditCardExpired } from '@/shared/util/func-is-credit-card-expired';
import { processYearNumber } from '@/shared/util/func-process-date';
import { checkRouterError } from '@/shared/util/router-navigation-func';

import '@/styles/platform/my-style-for-entry-input-retrieved-by-sql.scss';
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { PORTAS_CAMPAIGN_CONFIRM_PURCHASE } from '@/shared/const/platform-image';

/** 支払方法 */
interface PaymentMethodInfoForDisplay extends PaymentMethodListResponse {
  isExpired: boolean;
}

/** URLからクエリパラメータを取得する関数 */
function getQueryParam(name: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.getAll(name);
}

export default defineComponent({
  name: 'products-entry-input',
  components: {
    ErrorMessagesComponent,
    CreditCardComponent,
    LoadingComponent,
    ModalDisplayEntryUnextProductConfirm,
    PortasServicePriceListAndNotes,
  },
  data: () => ({
    /** 商品購入可否 */
    isPurchasable: false,
    /** Connectix商品フラグ */
    isConnectix: false,
    /** 商品情報配列 */
    productList: [] as ProductsDto[],
    /** 商品IDが存在しないサービス名の配列 */
    notExistIdProductNameArray: [] as string[],
    /** 支払方法情報一覧 */
    paymentMethodInfoList: [] as PaymentMethodListResponse[],
    /** 商品がセット商品かどうか */
    isProductSet: false,
    /** セット名 **/
    productSetName: '',
    /**商品ID配列 */
    productIds: [] as number[],
    /** セット名(お申し込み方法表示用) **/
    productSetNameForApplyMethod: '',
    /** 規約に同意する */
    isAgreeTerms: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
    /** サービスお申込み用クレジットカードトークン */
    creditCardForApplication: '',
    /** サービスお申込み用クレジットカードトークン有効期限 */
    creditTokenExpireDateForApplication: '',
    /** 本人認証用カード保有者名 */
    cardholderNameAtCopy: '',
    /**カード保有者名の入力が必要かどうか */
    isNeedCardholderName: false,
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    /** APIトークンキー */
    apiTokenKey: process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_PORTAS,
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込みの確定」ボタンをクリックしてください。',
    /** 連携先ISPからVeriTrans会員IDが取得できた場合は値を登録する */
    externalVeriTransId: '',
    /** Portasに支払方法が登録されている場合はマスク化されたクレジットカード番号を登録する */
    maskedCardNumberOnPortas: '',
    /** Portasに支払方法が登録されている場合はカードの有効期限を登録する */
    cardExpiredOnPortas: '',
    /** Portasに支払方法が登録されている場合のカード保有者名 */
    cardholderNameOnPortas: ('' as string) || undefined,
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** 連携先ISPの提供元会社名を登録する。空文字の場合は"株式会社つなぐネットコミュニケーションズ"が表示される */
    ispProviderCompanyName: '',
    /** ログイン中のPortasユーザーが連携中の外部ISP名を登録する */
    ispName: 'Portas',
    /** ボタン押下判定 */
    isSubmitting: false,
    /** サービス利用規約に同意するチェック、お申し込みボタン 表示/非表示 */
    availableApply: true,
    /** 読み込み状態,最初はロード中 */
    isLoading: true,
    /** ユーザーが連携先ISPからカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardFromIsp: false,
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: false,
    productResult: new Array<number>(),
    resetCounter: 0,
    /** U-NEXTの商品申込時の確認用モーダルを表示するか */
    showModal: false,
    /** ログイン中のPortasユーザーの支払方法No */
    paymentMethodNo: '',
    /** ログイン中のPortasユーザーの会員ID */
    memberId: undefined as number | undefined,
    /** 既に支払情報が登録されているかどうか */
    paymentInfoExists: false,

    productsdetailList: [] as ProductsDtoDetail[],
    taxRate: 0.1,
    /** U-NEXTが選択されているかどうか */
    isUnext: false,

    /**ノートン、詐欺ウォール、Wi-Fi DeviceSupport、AdGuard」または「U-NEXT、ノートン」の場合*/
    campaignFlag: false,

    // Portasキャンペーン
    portasServiceImageSetplusLoggedIn: PORTAS_CAMPAIGN_CONFIRM_PURCHASE,
  }),

  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    // コンポーネント内で platformProductsEntryStore のデータをクリアする
    this.$store.getters['platformProductEntryStore/clearPlatformProductsEntryStore'];

    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      const member: Member = this.$store.getters['memberStore/member'];
      const property: Property | null = this.$store.getters['propertyStore/property'];

      // クエリパラメータから商品IDを取得
      const productParams = [];
      let i = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const paramName = `product${(i + 1).toString()}`;
        const paramValues = getQueryParam(paramName);
        if (paramValues.length === 0) {
          break; // クエリパラメータが存在しない場合はループを終了
        }
        if (!paramValues.includes('connectix')) {
          // "connectix"以外の商品IDを配列に追加
          productParams.push(...paramValues[0]);
        } else {
          // "connectix"の場合はconnectixフラグを立てる
          this.isConnectix = true;
        }
        // paramValues が 数字以外の時は、商品ID以外だとみなす
        paramValues.forEach((value) => {
          if (isNaN(Number(value))) {
            this.notExistIdProductNameArray.push(value);
          }
        });
        i++;
      }
      if (i > 1) {
        this.isProductSet = true;
      }

      if (property != null) {
        // ISP名をセットする
        this.setIspName(member, property);
      }

      // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
      }

      // 商品IDを取得
      if (productParams.length > 0) {
        this.productIds = productParams.map((param) => parseInt(param)); // 商品IDを整数に変換

        // U-NEXTとノートンセキュリティのセットの場合、1,4の順に並べ替える
        if (this.productIds.includes(1) && this.productIds.includes(4)) {
          const specificOrder = [1, 4];
          this.productIds.sort((a, b) => specificOrder.indexOf(a) - specificOrder.indexOf(b));
          this.campaignFlag = true;
        }

        if (this.productIds.includes(1) && this.productIds.includes(2) && this.productIds.includes(3) && this.productIds.includes(5)) {
          this.campaignFlag = true;
        }

        // 商品契約可否確認
        this.isPurchasable = await SpfApiService.isProductsContractable(member.id.toString(), this.productIds);
        // 商品IDを使用して商品詳細情報を取得し配列に追加する
        let setProductItems = [];
        for (const productId of this.productIds) {
          const product = await this.getProductDetail(productId);
          this.productList.push(product);
          setProductItems.push(product.productsName);
        }

        // セット商品名
        this.productSetName = setProductItems.join(' ＋ ');

        this.productSetNameForApplyMethod = this.productSetName;

        if (this.isConnectix) {
          this.productSetName += ' ＋ Connectix';
        }

        if (this.ispName === 'UCOM光 レジデンス') {
          this.processPropertyApiResponse();
        }

        this.isUnext = this.isExistUnextProduct();

        // 支払方法取得
        const paymentMethodOnPortas = await this.getPaymentMethodInfoList(member.id);
        if (paymentMethodOnPortas) {
          // クレジットカード情報が登録されている場合
          this.maskedCardNumberOnPortas = paymentMethodOnPortas.cardNumber;
          this.cardExpiredOnPortas = paymentMethodOnPortas.cardExpire;
          this.cardholderNameOnPortas = paymentMethodOnPortas.cardholderName;

          this.paymentInfoExists = true;
          // e-mansionに登録されたVeriTransカード情報をコピー可能にする
        } else if (this.ispName === 'e-mansion') {
          this.externalVeriTransId = EMansionVeriTransService.getVeriTransCardIdOnEMansion();

          // fiveAに登録されたVeriTransカード情報をコピー可能にする
        } else if (this.ispName === 'Five.A') {
          this.externalVeriTransId = await FiveAVeriTransService.getVeriTransCardIdOnFiveA();

          // UCOMに登録されたVeriTransカード情報をコピー可能にする
        } else if (this.ispName === 'UCOM光 レジデンス') {
          this.externalVeriTransId = await UcomVeriTransService.getVeriTransCardIdOnUcom();
        }
        this.isLoading = false;
        this.isMounted = true;
        return;
        // 商品選択なし かつ Connectixのみが選択されている場合
      } else if (this.isConnectix) {
        if (this.ispName === 'Five.A') {
          await this.$router.push('/connectix/5a/terms').catch((error) => {
            checkRouterError(error);
          });
        }
        if (this.ispName === 'e-mansion') {
          await this.$router.push('/connectix/e-mansion/terms').catch((error) => {
            checkRouterError(error);
          });
        }
        if (this.ispName === 'UCOM光 レジデンス') {
          await this.$router.push('/connectix/ucom/terms').catch((error) => {
            checkRouterError(error);
          });
        }
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PRODUCT);
      }
    } catch (error) {
      throw error;
    }
  },
  methods: {
    /** 「お申し込み」ボタン押下時 */
    async onApply() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      if (this.isNeedCardholderName) {
        if (!this.cardholderNameAtCopy || this.cardholderNameAtCopy?.length < 2 || this.cardholderNameAtCopy?.length > 45) {
          this.errorMessages.push('カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');

          this.isSubmitting = false;

          // 作成されたトークン情報を削除して「お支払い方法を保存」ボタンを非活性化する
          this.creditCardForApplication = '';
          this.cardTokenFor3dSecureAuthorize = '';

          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }
      }
      // U-NEXTの商品があるか確認し、あればダイアログを表示する
      const isExistUnextProducts = this.isExistUnextProduct();
      if (isExistUnextProducts) {
        this.showModal = true;
        // モーダル表示するコンポーネントのdom操作できるようになったら、モーダル表示ONにする
        this.$nextTick(() => {
          const modalDisplayEntryUnextProductConfirm = this.$refs.ModalDisplayEntryUnextProductConfirm as InstanceType<typeof ModalDisplayEntryUnextProductConfirm>;
          if (modalDisplayEntryUnextProductConfirm) {
            modalDisplayEntryUnextProductConfirm.openModal();
          }
        });
        this.isSubmitting = false;
        return; //処理中断
      }

      await this.apply();
    },
    /** お申し込み商品にU-NEXTが含まれるとき、ダイアログで「お申し込みに進む」を押下した際の後続処理 */
    async onApplyUnext(result: boolean) {
      this.showModal = false;
      // モーダルの戻るボタンが押下されたときは、処理を中断
      if (!result) {
        return;
      }

      // ローディング画面が表示されるように
      this.isSubmitting = true;
      await this.apply();
    },
    /**
     * 次の2つの場合に呼び出される
     *
     * 1. U-NEXT商品 用の 確認ダイアログ で同意したあとの処理
     * 2. U-NEXT商品 を含まず 確認ダイアログ を表示しないときの処理
     *
     * 次の3つの場合に合わせて、申込内容一時保存を実施する
     *
     * 1. 支払方法コピー & サービス同時申し込み
     * 2. 支払方法登録 & サービス同時申し込み
     * 3. 既に登録済みの支払方法を使用したサービス申し込み
     *
     * 最後に、ストアの処理と画面遷移処理を実施する
     */
    async apply(): Promise<void> {
      //エラーで同画面に戻ってきた場合、ストアがリセットされているためdispatch
      const member: Member = await this.$store.dispatch('memberStore/member');
      const serverDateTimeNow = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
      const contractAppliedAt: string = dayjs(serverDateTimeNow).format('YYYY-MM-DDTHH:mm:ssZ');
      const productNameArray = this.productList.map((product) => product.productsName);
      const productIdArray = this.productList.map((product) => product.productsId.toString());
      // 申込内容一時保存用 UUIDを生成
      const uuidForTemporarySavingApplicationData = uuidv4();
      // 契約可否確認APIを実行
      if (!(await SpfApiService.isProductsContractable(member.id.toString(), this.productIds))) {
        throw new Error('契約可否確認APIにより契約不可判定');
      }

      // 支払方法コピー & サービス同時申し込み時 の申込内容一時保存処理
      if (!this.maskedCardNumberOnPortas && this.isAgreedCopyCardFromIsp) {
        const paymentMethodApplicationData = {
          uuid: uuidForTemporarySavingApplicationData,
          memberId: member.id.toString(),
          notExistIdProductNameArray: this.notExistIdProductNameArray,
          contractAppliedAt: contractAppliedAt,
          productNameArray: productNameArray,
          productIdArray: productIdArray,
          creditCardAccessToken: this.creditCardForApplication,
          creditTokenExpireDate: this.creditTokenExpireDateForApplication,
          ispName: this.ispName,
          externalVeriTransId: this.externalVeriTransId,
          isConnectix: this.isConnectix,
        };
        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'service-entry-copy',
        });
        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
        }

        // 支払方法登録 & サービス同時申し込み時 の申込内容一時保存処理
      } else if (!this.maskedCardNumberOnPortas && !this.isAgreedCopyCardFromIsp) {
        const paymentMethodApplicationData = {
          uuid: uuidForTemporarySavingApplicationData,
          memberId: member.id.toString(),
          notExistIdProductNameArray: this.notExistIdProductNameArray,
          contractAppliedAt: contractAppliedAt,
          productNameArray: productNameArray,
          productIdArray: productIdArray,
          creditCardAccessToken: this.creditCardForApplication,
          creditTokenExpireDate: this.creditTokenExpireDateForApplication,
          isConnectix: this.isConnectix,
          ispName: this.ispName,
        };
        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'service-entry-regist',
        });
        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
        }
        // 既に登録済みの支払方法を使用したサービス申し込み時 の申込内容一時保存処理
      } else {
        // 支払方法取得
        let paymentMethodNo = '';
        const paymentMethodOnPortas = await this.getPaymentMethodInfoList(member.id);
        if (paymentMethodOnPortas) {
          paymentMethodNo = paymentMethodOnPortas.paymentMethodNo.toString();
        }
        const paymentMethodApplicationData = {
          uuid: uuidForTemporarySavingApplicationData,
          memberId: member.id.toString(),
          notExistIdProductNameArray: this.notExistIdProductNameArray,
          contractAppliedAt: contractAppliedAt,
          productNameArray: productNameArray,
          productIdArray: productIdArray,
          isConnectix: this.isConnectix,
          ispName: this.ispName,
        };
        // 申込内容をjsonに変換
        const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
        const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
          uuid: uuidForTemporarySavingApplicationData,
          applicationDataJson: paymentMethodApplicationDataJson,
          subsequentProcess: 'service-entry-registed',
        });
        // 申込内容一時保存を実行するAPI
        try {
          await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
        }
      }
      // 3dセキュア対応用 uuid localStorageに保存
      localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
      await this.transitEntryCompletedScreen(productNameArray, productIdArray);
    },
    /** VeriTrans_本人認証 画面への遷移処理 */
    async transitEntryCompletedScreen(productNameArray: Array<string | undefined>, productIdArray: Array<string | undefined>): Promise<void> {
      // 支払方法登録に成功すると、会員.VeriTrans 会員ID に値が登録される
      // ページ遷移時に beforeEach で会員情報取得できるように null にする
      this.$store.commit('memberStore/member', null);

      // サービス契約申込ストアにConnectixの契約要否を保存
      this.$store.commit('platformProductEntryStore/setIsConnectix', this.isConnectix);
      // サービス契約申込ストアに商品名配列を保存
      this.$store.commit('platformProductEntryStore/setProductNameArray', productNameArray);
      // サービス契約申込ストアに商品ID配列を保存
      this.$store.commit('platformProductEntryStore/setProductIdArray', productIdArray);
      // サービス契約申込ストアにUA種別を保存
      this.$store.commit('platformProductEntryStore/setIspName', this.ispName);

      // 終了処理
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // 申込内容一時保存用uuidを取得
      const getUuidForTemporarySavingApplicationData = localStorage.getItem('uuidForTemporarySavingApplicationData');
      if (
        (this.cardTokenFor3dSecureAuthorize === '' || this.cardTokenFor3dSecureAuthorize === undefined || this.cardTokenFor3dSecureAuthorize === null) &&
        getUuidForTemporarySavingApplicationData !== null
      ) {
        const dddSecureAuthStartWithVeriTransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
          uuid: getUuidForTemporarySavingApplicationData,
          externalVeritransAccountId: this.externalVeriTransId || undefined,
          cardholderName: this.cardholderNameAtCopy || undefined,
        });
        // VeriTrans_本人認証(VeriTrans会員ID使用) API
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeriTransAccountIdRequest);
          location.href = authStartURL;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="https://support.ucom.ne.jp/contact/portas/" target="_blank">こちら</a>(Portas問合せフォーム）からお問い合わせをお願いいたします。`
            );
            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
            this.isSubmitting = false;
            this.addResetCounter();
            this.isAgreeTerms = false;
            // 作成されたトークン情報を削除して「お支払い方法を保存」ボタンを非活性化する
            this.creditCardForApplication = '';
            this.cardTokenFor3dSecureAuthorize = '';

            // エラーメッセージを見せるために画面最上部にスクロール
            window.scrollTo(0, 0);
            return;
          } else {
            throw error;
          }
        }
      } else if (this.cardTokenFor3dSecureAuthorize && getUuidForTemporarySavingApplicationData !== null) {
        const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
          uuid: getUuidForTemporarySavingApplicationData,
          creditCardToken: this.cardTokenFor3dSecureAuthorize,
        });
        // VeriTrans_本人認証(本人認証用トークン使用) API
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
          location.href = authStartURL;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="https://support.ucom.ne.jp/contact/portas/" target="_blank">こちら</a>(Portas問合せフォーム）からお問い合わせをお願いいたします。`
            );
            this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
            this.isSubmitting = false;
            this.addResetCounter();
            this.isAgreeTerms = false;
            // 作成されたトークン情報を削除して「お支払い方法を保存」ボタンを非活性化する
            this.creditCardForApplication = '';
            this.cardTokenFor3dSecureAuthorize = '';

            // エラーメッセージを見せるために画面最上部にスクロール
            window.scrollTo(0, 0);
            return;
          } else {
            throw error;
          }
        }
      }
      // ボタン押下解除
      this.isSubmitting = false;
    },
    /** 戻るボタン */
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      this.isSubmitting = true;
      await this.$router
        .push('/platform')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    /** 支払方法登録 & サービス同時申し込み用クレジットカードトークンを受け取る */
    getCardTokenForRegister(cardTokenForApplication: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardForApplication = cardTokenForApplication;
    },

    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },

    /**カードコピー時、カード保有者名が無かった場合新規に入力した値を受け取る */
    getCardHolderNameAtCopy(cardholderNameAtCopy: string) {
      this.cardholderNameAtCopy = cardholderNameAtCopy;
    },

    /**カード保有者名の入力が必要かどうか */
    getIsNeedCardholderName(isNeedCardholderName: boolean) {
      this.isNeedCardholderName = isNeedCardholderName;
    },

    /** 支払方法登録 & サービス同時申し込み用クレジットカードトークン有効期限を受け取る */
    getCardTokenExpireDateForRegister(creditTokenExpireDateForApplication: string) {
      this.creditTokenExpireDateForApplication = creditTokenExpireDateForApplication;
    },

    /** Portasに登録されている支払方法登録 & サービス同時申し込み用クレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },

    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },

    /**
     * ISP提供元企業名を判別するため、UCOMの物件基本情報を取得する処理
     */
    processPropertyApiResponse(): void {
      const ucomProperty: UcomPropertyResponse | null = this.$store.getters['ucomCommonStore/property'];
      if (ucomProperty instanceof UcomPropertyResponse) {
        this.ispProviderCompanyName = ucomProperty.service_provid_former.service_provid_former_name;
      }
    },

    /** 商品詳細取得APIを実行し、商品情報を取得 */
    async getProductDetail(productId: number): Promise<ProductsDto> {
      return await SpfApiService.getProductDetail(productId);
    },

    /** 支払方法一覧取得 APIを実行し、画面表示用のプロパティを追加して実行結果を取得する */
    async getPaymentMethodInfoList(memberId: number): Promise<PaymentMethodInfoForDisplay | null> {
      try {
        const paymentMethodList = await SpfApiService.getPaymentMethodList(memberId);
        let paymentMethodInfo: PaymentMethodInfoForDisplay | null = null;

        for (const eachPaymentMethod of paymentMethodList) {
          const eachPaymentMethodForDisplay: PaymentMethodInfoForDisplay = {
            ...eachPaymentMethod,
            isExpired: isCreditCardExpired(eachPaymentMethod.cardExpire.slice(0, 2), String(processYearNumber()).slice(0, 2) + eachPaymentMethod.cardExpire.slice(3, 5)),
          };
          if (!eachPaymentMethod.deleteDate) {
            paymentMethodInfo = eachPaymentMethodForDisplay;
            return paymentMethodInfo;
          }
        }
        return paymentMethodInfo;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          // 404 エラーの場合、空を返す
          return null;
        } else {
          throw error; // エラーを再スローするか、適切な処理を行う
        }
      }
    },
    /**
     * ISP名セット
     * @param member
     * @param property
     */
    setIspName(member: Member, property: Property) {
      if (member.primaryKeyMye && property.uaType === '1') {
        this.ispName = 'e-mansion';
      } else if (member.primaryKeyMye && property.uaType === '2') {
        this.ispName = 'Five.A';
      } else if (member.primaryKeyUcom) {
        this.ispName = 'UCOM光 レジデンス';
      }
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    /**
     * U-NEXTの商品があるかチェックし、あればダイアログ表示
     */
    isExistUnextProduct(): boolean {
      for (const eachProduct of this.productList) {
        if (eachProduct.contents.contentProviderId === CONTENT_PROVIDER_ID.UNEXT) {
          return true;
        }
      }
      return false;
    },
    calculateTaxIncludedPrice(price: number) {
      return Math.round(price * (1 + this.taxRate));
    },

    formatMoney(billing: number): string {
      const formatter = new Intl.NumberFormat('en-US');
      const formattedValue = formatter.format(billing);
      return formattedValue;
    },
  },
  computed: {
    buttonColorSet() {
      if (this.isDisabledButton) {
        return 'btn campaignFlag bs';
      }
      return 'btn btn01 bs';
    },
    isDisabledButton(): boolean {
      if (!this.isExpiredPortasCreditCard && this.maskedCardNumberOnPortas) {
        // クレジットカード情報が登録されている場合
        if (this.isAgreeTerms) {
          return false;
        }
      } else {
        // クレジットカード情報が登録されていない場合
        if (
          this.isAgreeTerms &&
          ((this.creditCardForApplication !== '' && this.cardTokenFor3dSecureAuthorize !== '' && this.creditTokenExpireDateForApplication !== '') || this.isAgreedCopyCardFromIsp)
        ) {
          return false;
        }
      }
      return true;
    },
    totalSetPrice() {
      return this.productList.reduce((total, product) => {
        return total + (product.productPrice[1] ? this.calculateTaxIncludedPrice(product.productPrice[1].priceTaxExcluded) : 0);
      }, 0);
    },
    totalSetPriceSingle() {
      return this.productList.reduce((total, product) => {
        return total + (product.productPrice[0] ? this.calculateTaxIncludedPrice(product.productPrice[0].priceTaxExcluded) : 0);
      }, 0);
    },
  },
});
</script>
