<template>
  <div class="gip-confirm">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>e-mansion 固定グローバルIPアドレス</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li>
          <router-link to="/platform">トップページ</router-link>
        </li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li>固定グローバルIPアドレス</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>お申し込み</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>
      <!-- /application-flow -->

      <div class="sblc">
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="固定グローバルIPアドレス お申し込み確認" />固定グローバルIPアドレス お申し込み確認</h2>
        <p>お申し込み内容をご確認ください。</p>
        <h3 class="service-h3">お申し込み内容</h3>
        <p class="red">固定グローバルIPアドレスを申し込む</p>
        <div v-if="!isApplyConnectix">
          <p>固定グローバルIPアドレスを割り当てる通信機器のMACアドレスをご確認ください。</p>
          <table class="table-type2">
            <tbody>
              <tr>
                <th><b>MACアドレス</b></th>
                <td>
                  <b class="red">{{ requestMacAddress }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="sblc">
        <div v-if="displayPaymentForm">
          <h3 class="service-h3">お支払い方法</h3>
          <div v-if="noDisplayCreditCardForm">
            <p>ご登録のお支払い方法により、お支払いいただきます。</p>
          </div>
          <div v-else>
            <credit-card-component
              v-if="isMounted"
              :key="resetCounter"
              :reset-counter="resetCounter"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispName="ispName"
              v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
              v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-bind:cardHolderNameFromIsp="cardHolderNameFromIsp"
              v-on:onPostCreditCard="getCreditCardToken"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:getTokenExpireDate="getTokenExpireDate"
              v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
              v-on:change="isAgreedCopyCardToIsp = $event"
              v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
              v-on:portasCardHolderName="getCardHolderNameFromPortas"
              v-on:cardholderNameFormForAuthorizeIspCard="getCardholderNameFormForAuthorizeIspCard"
              v-on:cardholderNameFormForAuthorizePortasCard="getCardholderNameFormForAuthorizePortasCard"
            />
          </div>
        </div>
      </div>

      <div class="blc">
        <p v-if="isNeedDisableTrackingBlockFunction" class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせください。
        </p>
        <p class="form-btn-txt mt40">
          ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」ボタンをクリックしてください。
        </p>
        <p class="red form-btn-txt">
          「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
        </p>
      </div>

      <div class="btn-area">
        <div class="has-checkbox">
          <div class="my-checkbox-outer" v-if="!noDisplayCreditCardForm && displayPaymentForm">
            <input id="checkbox-to-agree" type="checkbox" v-model="isAgreedPrivacyPolicy" :disabled="isExpiredPortasCreditCard" />
            <label for="checkbox-to-agree">クレジットカード会社での本人認証のための情報提供に同意してお申し込みを確定する</label>
          </div>
          <button class="btn-height btn btn05 bs sp-margin" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn-height btn btn01 bs sp-margin" type="button" :disabled="isDisabledButton" v-on:click="onApply()">
            お申し込みを確定する<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
div.my-checkbox-outer {
  margin: 16px;
}
div.my-checkbox-outer > input {
  margin-right: 10px;
}
.sp-margin {
  margin-top: 10px;
}
.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}
/* 申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
p.red {
  color: #cf1225;
}
b.red {
  color: #cf1225;
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { SpfApiGipAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-gip-accessor';
import { EMansionConnectixEntryInputForm } from '@/pages/connectix/e-mansion/classes/entry-input-form';
import { StoreExternalApiResponse } from '@/router/before/store-external-api-response';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { ISP_MEMBER_STATUS, UA_TYPE, UaTypeValue } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
/** Completed コンポーネント */
export default defineComponent({
  name: 'static-global-ip-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
    CreditCardComponent,
  },
  data: () => ({
    /** 最初はロード中 */
    isLoading: true,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** MACアドレス */
    requestMacAddress: '' as string | undefined,
    /** Connectix対象物件
     * 対象:true, 非対象:false
     */
    isApplyConnectix: false,
    /** 支払い方法の登録状態によってクレジットカードコンポーネントの表示/非表示を切り替える */
    noDisplayCreditCardForm: false,
    /** GIPの価格・事務手数料が0の場合、お支払い方法の表示/非表示を切り替える */
    displayPaymentForm: true,
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** APIトークンキー */
    apiTokenKey: process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION,
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込みを確定する」ボタンをクリックしてください。',
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: '',
    /** ユーザーがe-mansionへカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面ではe-mansion固定) */
    ispName: 'e-mansion',
    /** e-mansionから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** e-mansionから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** e-mansion に登録済のVeriTransAccountId */
    emansionVeriTransAccountId: '',
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: false,
    /** Portasから取得したカード名義人 */
    cardHolderNameOnPortas: '' as string | undefined,
    /** ISPから取得したカード名義人 */
    cardHolderNameFromIsp: '' as string | undefined,
    /** ISPで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizeIspCard: '' as string | undefined,
    /** Portasで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizePortasCard: '' as string | undefined,
    /** 本人認証処理を実行するため、ブラウザのトラッキング防止機能を無効化する必要があるかどうか */
    isNeedDisableTrackingBlockFunction: true,
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    resetCounter: 0,
    inputApply: {} as EMansionConnectixEntryInputForm,
    /** e-mansion 会員ID */
    eMansionMemberId: '' as string,
    /** 物件ID */
    eMansionPropertyId: '' as string,
    /** サービス登録APIの返却エラーコード */
    errorCode: '' as string | undefined,
    /** e-mansion 物件ID */
    apartmentId: '' as string | undefined,
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    apid: '',
  }),
  computed: {
    isDisabledButton(): boolean {
      if (this.noDisplayCreditCardForm) {
        return false;
      } else if (!this.displayPaymentForm) {
        return false;
      } else if (
        this.emansionVeriTransAccountId &&
        ((this.cardHolderNameFromIsp && this.isAgreedPrivacyPolicy) ||
          (this.cardholderNameFormForAuthorizeIspCard && this.cardholderNameFormForAuthorizeIspCard.length > 1 && this.isAgreedPrivacyPolicy))
      ) {
        return false;
      } else if (
        !this.isExpiredPortasCreditCard &&
        (this.cardHolderNameOnPortas || this.cardholderNameFormForAuthorizePortasCard) &&
        this.isAgreedCopyCardToIsp &&
        this.isAgreedPrivacyPolicy
      ) {
        return false;
      } else {
        return this.creditCardAccessToken === '' || !this.isAgreedPrivacyPolicy;
      }
    },
  },
  /** 画面初期表示時の処理 */
  async mounted() {
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      await this.$router.push('/').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }
    try {
      // Connectix対象物件か確認する
      /** 物件基本情報をStoreから取得 */
      await StoreExternalApiResponse.main();
      const eMansionProperty: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        this.isApplyConnectix = this.checkApplyConnectix(eMansionProperty);
        //非Connectix対象物件の場合はストアから申し込み画面で入力したMACアドレスを取得する
        if (!this.isApplyConnectix) {
          try {
            this.requestMacAddress = await this.$store.getters['eMansionMacAddressStore/macAddress'];
          } catch (error) {
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }
        }
        // 物件基本情報取得API.オプションサービス情報.固定グローバルIP.価格/事務手数料　がどちらも0円の場合、クレジットカード申請フォームを非表示
        if (eMansionProperty.op.gip.fee === '0' && eMansionProperty.op.gip.jimu_fee === '0') {
          this.displayPaymentForm = false;
          this.isNeedDisableTrackingBlockFunction = false;
        }
      }
      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property.uaType;
      const apartmentId: string | undefined = property.apartmentId;

      if (property?.apartmentId) {
        this.apid = convert6DigitIdTo8DigitId(property?.apartmentId);
      }

      // 以降の処理で使うMye会員Idを取得
      this.eMansionMemberId = member.primaryKeyMye ?? '';

      // 必要な物件情報を取得できなかった場合はエラー画面に遷移
      if (!uaType || !apartmentId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      /** 契約基本情報をStoreから取得 */
      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];
      if (customer instanceof EMansionCustomer) {
        // 物件ID
        this.eMansionPropertyId = customer.property_id;
        // 登録している支払方法が以下いずれかの場合、クレジットカード申請フォームを表示しない
        /**
         * 0: 口座振替
         * 1: 振込
         * 4: 口振（請求書）
         */
        const paymentIdsWithoutCreditCard = ['0', '1', '4'];
        if (paymentIdsWithoutCreditCard.some((paymentIdsWithoutCreditCard) => paymentIdsWithoutCreditCard === customer.payment_id)) {
          this.noDisplayCreditCardForm = true;
          this.isNeedDisableTrackingBlockFunction = false;
        }
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      if (customer instanceof EMansionCustomer && customer.payment_id === '8' && member.eTncMemberId) {
        const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
        this.cardExpiredFromIsp = cardInfo.cardExpire;
        this.maskedCardNumberFromIsp = cardInfo.cardNumber;
        this.cardHolderNameFromIsp = cardInfo.cardholderName;
        this.emansionVeriTransAccountId = member.eTncMemberId;
      } else if (member.veritransAccountId) {
        this.portasVeriTransId = member.veritransAccountId;
      }
      // VeriTrans 本人認証失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
        this.creditCardAccessToken = '';
        this.creditTokenExpireDate = '';
      }
      this.isLoading = false;
      this.isMounted = true;
    } catch (error) {
      throw error;
    }
    return;
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      this.isLoading = false;
      this.isSubmitting = false;
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      return;
    },
    async onApply() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      /**クレカ処理-------------------------------ここから */

      // クレジットカードトークンがない かつ e-mansionに登録された支払方法が存在しない かつ Portasからカード情報をコピーして支払方法を登録しない場合はエラー
      if (
        this.creditCardAccessToken === '' &&
        !(this.cardExpiredFromIsp && this.maskedCardNumberFromIsp) &&
        !this.isAgreedCopyCardToIsp &&
        !this.noDisplayCreditCardForm &&
        this.displayPaymentForm
      ) {
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        // エラーメッセージを見せるために画面最上部にスクロール
        this.showErrorMessage();
        return;
      }
      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      /** 契約基本情報をStoreから取得 */
      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];

      if (customer instanceof EMansionCustomer) {
        // 支払方法が登録されている場合
        if (this.noDisplayCreditCardForm || !this.displayPaymentForm) {
          // サービス登録APIに進む
          try {
            // サービス登録API(固定グローバルIP) を呼び出す
            const eMansionGipRegisterResponseDto = await SpfApiGipAccessor.registerGip(this.eMansionMemberId, this.eMansionPropertyId, this.requestMacAddress, '0');

            // レスポンスの code に値があれば申し込み画面にエラーを表示する
            if (eMansionGipRegisterResponseDto.code) {
              this.errorCode = eMansionGipRegisterResponseDto.code;

              if (this.errorCode === 'APIS010') {
                this.$store.commit('eMansionGIPStore/from', 'confirm');
                this.$store.commit('eMansionGIPStore/errorCode', this.errorCode);
                // 申し込み画面に遷移する
                await this.$router.push('/e-mansion/static-gip/application').catch((error) => {
                  checkRouterError(error);
                });
                return;
              }
              if (this.errorCode === 'APIS011') {
                this.$store.commit('eMansionGIPStore/from', 'confirm');
                this.$store.commit('eMansionGIPStore/errorCode', this.errorCode);
                // 申し込み画面に遷移する
                await this.$router.push('/e-mansion/static-gip/application').catch((error) => {
                  checkRouterError(error);
                });
                return;
              }
            }
          } catch (error) {
            // 上記以外のエラーの場合はe-mansion共通エラー画面に遷移
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }

          await this.$router
            .push('/e-mansion/static-gip/completed')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
              checkRouterError(error);
            })
            .finally(() => {
              this.isSubmitting = false;
            });
          return;
          // クレジットカードが e-mansion に登録されている場合の申し込み処理
        } else if (this.emansionVeriTransAccountId) {
          // 申込内容一時保存処理
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            requestMacAddress: this.requestMacAddress,
            veriTransAccountId: this.emansionVeriTransAccountId,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            creditCardRegistFlag: '0',
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-gip-apply',
          });
          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          let cardHolderNameForAuthorize: string;
          if (this.cardHolderNameFromIsp) {
            cardHolderNameForAuthorize = this.cardHolderNameFromIsp;
          } else {
            cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
          }
          const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
            uuid: uuidForTemporarySavingApplicationData,
            externalVeritransAccountId: this.emansionVeriTransAccountId,
            cardholderName: cardHolderNameForAuthorize,
          });
          // VeriTrans_本人認証(VeriTrans会員ID使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              this.isSubmitting = false;
              // エラーメッセージを見せるために画面最上部にスクロール
              window.scrollTo(0, 0);
              return;
            } else {
              throw error;
            }
          }
        } else if (
          // PortasからISPにカード情報をコピーする場合
          this.isAgreedCopyCardToIsp &&
          this.portasVeriTransId
        ) {
          // 申込内容一時保存処理
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            requestMacAddress: this.requestMacAddress,
            veriTransAccountId: this.portasVeriTransId,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            creditCardRegistFlag: '1',
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-gip-apply',
          });
          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          let cardHolderNameForAuthorize: string;
          if (this.cardHolderNameOnPortas) {
            cardHolderNameForAuthorize = this.cardHolderNameOnPortas;
          } else {
            cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizePortasCard!;
          }
          const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
            uuid: uuidForTemporarySavingApplicationData,
            externalVeritransAccountId: this.portasVeriTransId,
            cardholderName: cardHolderNameForAuthorize,
          });
          // VeriTrans_本人認証(VeriTrans会員ID使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              this.isSubmitting = false;
              // エラーメッセージを見せるために画面最上部にスクロール
              window.scrollTo(0, 0);
              return;
            } else {
              throw error;
            }
          }
          return;
        } else {
          /**Portas,e-mansionにカードが未登録の場合 */
          // 申込内容一時保存処理
          // 申込内容一時保存用 UUIDを生成
          const uuidForTemporarySavingApplicationData = uuidv4();
          const paymentMethodApplicationData = {
            uaType: UA_TYPE.E_MANSION,
            memberId: this.eMansionMemberId,
            propertyId: this.eMansionPropertyId,
            requestMacAddress: this.requestMacAddress,
            creditCardToken: this.creditCardAccessToken,
            tokenExpireDate: this.creditTokenExpireDate,
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
            creditCardRegistFlag: '1',
          };
          // 申込内容をjsonに変換
          const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
          const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
            uuid: uuidForTemporarySavingApplicationData,
            applicationDataJson: paymentMethodApplicationDataJson,
            subsequentProcess: 'e-mansion-gip-apply',
          });
          // 申込内容一時保存を実行するAPI
          try {
            await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } catch (error: any) {
            // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
          }
          // 3dセキュア対応用 uuid localStorageに保存
          localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
          // ページ遷移時に beforeEach で会員情報取得できるように null にする
          this.$store.commit('memberStore/member', null);
          const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
            uuid: uuidForTemporarySavingApplicationData,
            creditCardToken: this.cardTokenFor3dSecureAuthorize,
            serviceProviderIsp: SERVICE_PROVIDER.MYE,
          });
          // VeriTrans_本人認証(トークン使用) API
          try {
            const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
            location.href = authStartURL;
          } catch (error: any) {
            if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
              // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
              this.errorMessages.push(
                `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
              );
              this.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
              // コンポーネントの破棄・再描画のために key値 を変更する
              this.addResetCounter();
              this.isAgreedPrivacyPolicy = false;
              this.isSubmitting = false;
              // エラーメッセージを見せるために画面最上部にスクロール
              window.scrollTo(0, 0);
              return;
            } else {
              throw error;
            }
          }
        }
      }
    },
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/e-mansion/static-gip/application')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /**Connectix対象物件かを判定 */
    checkApplyConnectix(property: EMansionProperty): boolean {
      /**
       * ・Connectix対象物件であること：
       * 物件情報取得APIの「SD-WANフラグ（op_sdwan_flag）」が"1"
       */
      if (property.op.sdwan.flag === '1') {
        return true;
      }
      return false;
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: this.eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });
      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** 3Dセキュア本人認証用カード名義人を受け取る */
    getCardHolderNameFromPortas(cardHolderName: string) {
      this.cardHolderNameOnPortas = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、ISP登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizeIspCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizeIspCard = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、Portas登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizePortasCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizePortasCard = cardHolderName;
    },
  },
});
</script>
