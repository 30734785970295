<template>
  <div class="five-a-connectix-confirm">
    <LoadingComponent v-if="!isMounted || isExecutingOnApply" />
    <main class="underlayer-main">
      <h1>Connectix お申し込み内容の確認</h1>
    </main>
    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A Connectix お申し込み</li>
        <li>お申し込み内容の確認</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>サービスお申し込み</li>
        <li class="stay">お申し込み内容の確認</li>
        <li>お申し込み完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix お申し込み" />Connectix お申し込み内容の確認</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <h3 class="service-h3">お申し込み対象のお部屋番号</h3>
          <p class="red">ご指定のお部屋番号に本サービスを適用します。</p>
          <table class="table-type2">
            <tr>
              <th>建物名</th>
              <td>{{ propertyName }}</td>
            </tr>
            <tr>
              <th>部屋番号</th>
              <td>{{ roomNumber }}</td>
            </tr>
          </table>
        </div>
        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
          <table class="table-type2">
            <tr>
              <th>料金（月額）</th>
              <td>
                <div v-if="connectixUsageFeeCp">
                  <div style="color: red">キャンペーン中</div>
                  <div>{{ connectixUsageFee }} 円</div>
                  <div style="color: red">→ {{ connectixUsageFeeCp }} 円</div>
                </div>
                <div v-else>
                  <div>{{ connectixUsageFee }} 円</div>
                </div>
              </td>
            </tr>
          </table>
          <p class="att grey">※価格は全て税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
          <p class="att grey">※本サービスはベストエフォートの為、時間帯や状況などによって異なることがあり、保証されるものではございません。</p>
        </div>
        <div>
          <h3 class="service-h3">お支払い方法</h3>
          <div v-if="noDisplayCreditCardForm">
            <p>ご登録のお支払い方法により、お支払いいただきます。</p>
          </div>
          <div v-else>
            <credit-card-component
              v-if="isMounted"
              :key="resetCounter"
              :reset-counter="resetCounter"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispName="ispName"
              v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
              v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
              v-bind:cardHolderNameFromIsp="cardHolderNameFromIsp"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-on:portasCardHolderName="getCardHolderNameFromPortas"
              v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
              v-on:onPostCreditCard="getCreditCardToken"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:getTokenExpireDate="getTokenExpireDate"
              v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
              v-on:cardholderNameFormForAuthorizeIspCard="getCardholderNameFormForAuthorizeIspCard"
              v-on:cardholderNameFormForAuthorizePortasCard="getCardholderNameFormForAuthorizePortasCard"
              v-on:change="isAgreedCopyCardToIsp = $event"
            />
          </div>
        </div>
      </div>

      <div class="blc">
        <p v-if="isNeedDisableTrackingBlockFunction" class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
        </p>
        <p class="form-btn-txt mt40">
          ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」をクリックしてください。
        </p>
        <p class="red form-btn-txt">
          「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
        </p>
        <div class="btn-area">
          <div>
            <button class="btn btn05 bs" type="button" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          </div>
          <div class="has-checkbox">
            <button class="btn btn01 bs" type="button" :disabled="isDisabledButton" @click="onApply()">
              お申し込みを確定する<i class="material-icons link link-icon">east</i>
            </button>
            <div class="my-checkbox-outer" v-if="!noDisplayCreditCardForm">
              <input id="checkbox-to-agree" type="checkbox" v-model="isAgreedPrivacyPolicy" :disabled="isExpiredPortasCreditCard" />
              <p><label for="checkbox-to-agree">各サービスのご利用規約その他利用条件、情報提供に同意してお申し込みを確定する</label></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}
.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}

.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}
div.btn-area {
  margin: 30px auto 0;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  text-align: center;
  & div.has-checkbox {
    padding-top: 16px;

    & div.my-checkbox-outer {
      position: absolute;
      top: 16px;
      left: 0;

      & input[type='checkbox'] {
        position: absolute;
        top: 4px;
        left: 8px;
      }

      & p {
        padding-left: 24px;
        font-weight: 700;
        color: #cf1225;
      }
    }
  }
}

.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}
@media only screen and (min-width: 768px) {
  div.btn-area {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 64px;
    & div.has-checkbox {
      padding-top: 0;
      & div.my-checkbox-outer {
        top: 0;
        left: 50%;
        text-align: left;
        & input[type='checkbox'] {
          left: 0;
        }
      }
    }
  }
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { FiveACustomer } from '../../../shared/classes/external-api/five-a/customer-response';

import { FiveAConnectixErrorResponse } from './classes/external-api/connectix-error-response';
import { FiveAConnectixNewConnectixRequest } from './classes/external-api/new-connectix-request';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FiveACustomerQueryDto } from '@/shared/classes/external-api/five-a/customer-dto';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { FiveAConnectixExternalApiService } from '@/shared/services/external-api/connectix/five-a/five-a-connectix-external-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** Confirm コンポーネント */
export default defineComponent({
  name: 'five-a-connectix-confirm',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 物件名 */
    propertyName: '',
    /** 部屋番号 */
    roomNumber: '',
    /** Connectix利用金額 */
    connectixUsageFee: '',
    /** Connectixキャンペーン利用金額 */
    connectixUsageFeeCp: '',
    /** 会員ID */
    memberId: '' as string,
    /** roomsで取得したbuilding_id(棟ID) */
    buildingId: '' as string,
    /** 物件ID */
    propertyId: '',
    /** 支払い方法の登録状態によってクレジットカードコンポーネントの表示/非表示を切り替える */
    noDisplayCreditCardForm: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    /** APIトークンキー */
    apiTokenKey: process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION,
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込みを確定する」ボタンをクリックしてください。',
    /** onApply() 実行中かどうか */
    isExecutingOnApply: false,
    /** ISP(Five.A)会員ステータス */
    ispMemberStatusFiveA: '',
    /** ユーザーがPortasからカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: false,
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: '',
    /** Five.Aに紐づくVeriTrans会員 ID */
    FiveAVeriTransId: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** ログイン中のPortasユーザーが連携中の外部ISP名(この画面ではFive.A固定) */
    ispName: 'Five.A',
    /** Five.Aから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: '',
    /** Five.Aから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: '',
    /** Portasから取得したカード名義人 */
    cardHolderNameOnPortas: '' as string | undefined,
    /** ISPから取得したカード名義人 */
    cardHolderNameFromIsp: '' as string | undefined,
    /** ISPで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizeIspCard: '' as string | undefined,
    /** Portasで登録されたクレジットカードで本人認証するためのカード名義人フォーム */
    cardholderNameFormForAuthorizePortasCard: '' as string | undefined,
    /** 本人認証処理を実行するため、ブラウザのトラッキング防止機能を無効化する必要があるかどうか */
    isNeedDisableTrackingBlockFunction: false,
    /** 本画面の描画が完了したかどうかの状態を保持する。VeriTrans子コンポーネントの描画タイミングを遅らせるために使用する */
    isMounted: false,
    /** ボタン押下中かどうか */
    isSubmitting: false,
    /** ISPへ登録済みのクレジットカードをコピーするラジオボタン選択状態 */
    radioValueRequiringCardCopy: '',
    /** 支払い方法の登録に失敗したかどうか */
    isFailedExecuteRegisterPaymentMethod: false,
    resetCounter: 0,
    /** 既に支払情報が登録されているかどうか */
    paymentInfoExists: false,
    oemsMaster: [] as Array<GetOemsMasterResponse>, // OEMマスタ
    domainName: '', // 「こちら」リンク用
    apid: '',
  }),
  /** ログインしていない状態で遷移した場合は「総合トップ」画面にリダイレクトする */
  async mounted(): Promise<void> {
    try {
      if (!(await MountedCheckService.canReadFiveAScreen())) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 会員情報をStoreから取得
      const member: Member = await this.$store.dispatch('memberStore/member');
      // five.A のメンバー ID
      this.memberId = member.primaryKeyMye ?? '';
      this.buildingId = member.buildingNumber ?? '';

      // 物件情報をStoreから取得
      const property: Property = await this.$store.dispatch('propertyStore/property', member.propertyId);

      try {
        //ドメイン名の取得 */
        if (property.oemType) {
          this.oemsMaster = await this.$store.dispatch('oemsStore/oems');
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const filtered = this.oemsMaster.filter((oem: any) => oem.oemType === String(property.oemType));
          if (filtered.length >= 1) {
            let latestOem = filtered[0];
            if (filtered.length >= 2) {
              for (let i = 1; i < filtered.length; i++) {
                if (latestOem.startAt < filtered[i].startAt) {
                  latestOem = filtered[i];
                }
              }
            }
            this.domainName = latestOem.domainForOsumai;
          }
        } else {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
        }
      } catch (error: any) {
        // 共通エラーに遷移
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // apartment_id → APID に変換
      this.apid = convert6DigitIdTo8DigitId(String(property.apartmentId));

      const fiveACustomerQueryDto = new FiveACustomerQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const fiveAMemberId = member.primaryKeyMye ?? '';

      // 契約基本情報をStoreから取得
      const customer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
        memberId: fiveAMemberId,
        query: fiveACustomerQueryDto,
      });

      if (customer instanceof FiveACustomer) {
        // 物件ID
        this.propertyId = customer.property_id;

        // 登録している支払方法が以下のいずれかの場合、クレジットカードコンポーネントを表示しない
        /**
         * 0: 口座振替
         * 1: 振込
         * 4: 口振（請求書）
         */
        const paymentIdsWithoutCreditCard = ['0', '1', '4'];
        if (paymentIdsWithoutCreditCard.some((paymentIdsWithoutCreditCard) => paymentIdsWithoutCreditCard === customer.payment_id)) {
          this.noDisplayCreditCardForm = true;
        }
      } else {
        // データ不整合エラー
        await VueErrorHandler.handle(new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.FIVE_A.NO_CUSTOMER_INFO), '');
        await this.$router.push('/5a/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      const params = {
        propertyId: property.apartmentId,
        uaType: UA_TYPE.FIVE_A,
      };

      // 物件基本情報を取得
      const propertyInfo: FiveAProperty | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', params);
      if (propertyInfo instanceof FiveAProperty) {
        // 物件名
        this.propertyName = propertyInfo.em_ap_name;
        // Connectix利用金額
        this.connectixUsageFee = propertyInfo.op.sdwan.fee;
        this.connectixUsageFeeCp = propertyInfo.op.sdwan.fee_cp;
      } else {
        // データ不整合エラー
        await VueErrorHandler.handle(new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.FIVE_A.NO_CUSTOMER_INFO), '');
        await this.$router.push('/5a/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      // 入力画面で入力した情報をStoreから取得
      const inputApply = this.$store.getters['fiveAConnectixEntryStore/entryInputForm'];
      if (!inputApply) {
        // データ不整合エラー
        await VueErrorHandler.handle(new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA), '');
        await this.$router.push('/5a/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      // 部屋番号
      this.roomNumber = inputApply.roomNumber;

      // VeriTrans 3Dセキュア本人認証 検証処理失敗時のエラーメッセージ
      if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
        this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
        this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
        //ストアをリセット
        this.$store.commit('errorMessageStore/errorMessages', null);
        this.$store.commit('errorMessageStore/errorMessageTitle', null);
      }

      // Five.Aで登録しているカード情報がVeriTransに存在するか判定
      if (customer instanceof FiveACustomer && customer.payment_id === '8' && member.eTncMemberId) {
        const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
        this.cardExpiredFromIsp = cardInfo.cardExpire;
        this.maskedCardNumberFromIsp = cardInfo.cardNumber;
        this.cardHolderNameFromIsp = cardInfo.cardholderName;
        this.FiveAVeriTransId = member.eTncMemberId;
        // Five.Aには登録していないが、Portas上のカードがVeriTransに存在している
      } else if (member.veritransAccountId) {
        this.portasVeriTransId = member.veritransAccountId;
      }

      if (this.noDisplayCreditCardForm) {
        this.isNeedDisableTrackingBlockFunction = false;
      } else {
        this.isNeedDisableTrackingBlockFunction = true;
      }
    } catch (error: any) {
      await VueErrorHandler.handle(error, '');
      await this.$router.push('/5a/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    } finally {
      this.isMounted = true;
    }
  },
  methods: {
    async onApply() {
      if (this.isExecutingOnApply) {
        return;
      }
      this.isExecutingOnApply = true;

      // ISP(Five.A)側で退会申し込み中のとき、/connectix/5a/terms に遷移してエラーメッセージを表示
      if (await this.isInCancelApplication()) {
        await this.$router.push('/connectix/5a/terms').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      // 契約基本情報をStoreから取得
      const customer: FiveACustomer | FiveASharedErrorResponse = this.$store.getters['fiveACommonStore/customer'];
      if (!(customer instanceof FiveACustomer)) {
        // データ不整合エラー
        await VueErrorHandler.handle(new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.FIVE_A.NO_CUSTOMER_INFO), '');
        await this.$router.push('/5a/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 支払い方法がクレジットカードではない場合
      if (this.noDisplayCreditCardForm) {
        // eslint-disable-next-line no-useless-catch
        try {
          // Connectixお申し込みAPIを実行
          const request = new FiveAConnectixNewConnectixRequest({
            /** UA種別 */
            ua_type: UA_TYPE.FIVE_A,
            /** 会員ID */
            member_id: this.memberId,
            /** 物件ID */
            property_id: this.propertyId,
            /** 部屋番号 */
            room_number: this.roomNumber,
            /**カード登録フラグ */
            credit_card_regist_flag: '0',
            /**カードコピーフラグ */
            isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
          });

          /** roomsで取得したbuilding_id(棟ID) */
          if (this.buildingId) request.building_id = this.buildingId;

          const requestBody: FiveAConnectixNewConnectixRequest = request;
          const resultCreateConnectix = await FiveAConnectixExternalApiService.createConnectix(requestBody);

          if (resultCreateConnectix instanceof FiveAConnectixErrorResponse) {
            // エラーコードがCNTX008(業務エラー)の場合エラーメッセージ表示
            if (resultCreateConnectix.code === 'CNTX008') {
              const errorMessage = `Connectixのお申し込みを受け付けることができません。<br>恐れ入りますが、建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`;
              this.errorMessages.push(errorMessage);
              // エラーメッセージを見せるために画面最上部にスクロール
              window.scrollTo(0, 0);
              this.isExecutingOnApply = false;
              return;
            } else {
              // データ不整合エラー
              await VueErrorHandler.handle(new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONNECTIX), '');
              await this.$router.push('/5a/error').catch((error) => {
                checkRouterError(error);
              });
              return;
            }
          }

          await this.$router.push('/connectix/5a/completed').catch((error: any) => {
            checkRouterError(error);
          });
        } catch (error: any) {
          await VueErrorHandler.handle(error, '');
          await this.$router.push('/5a/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      } else {
        try {
          /** Five.Aで登録済みのクレジットカード情報を使用する場合 */
          if (this.cardExpiredFromIsp && this.maskedCardNumberFromIsp) {
            await this.getDDDSecureStartUrlWithVeritransAccountId();
            /** Portasで登録済みのクレジットカード情報をコピーして使用する場合 */
          } else if (this.portasVeriTransId && this.isAgreedCopyCardToIsp) {
            await this.getDDDSecureStartUrlWithVeritransAccountId();
            /** 画面で入力したクレジットカード情報を使用する場合 */
          } else if (this.creditTokenExpireDate && this.creditCardAccessToken) {
            await this.getDDDSecureStartUrlWithToken();
          } else {
            this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
            // エラーメッセージを見せるために画面最上部にスクロール
            window.scrollTo(0, 0);
            this.isSubmitting = false;
            this.isMounted = true;
            this.isExecutingOnApply = false;
            return;
          }
        } catch (error: any) {
          await VueErrorHandler.handle(error, '');
          await this.$router.push('/5a/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      }
    },

    /** 3dセキュア本人認証開始URL取得処理を実行 */
    async getDDDSecureStartUrlWithToken(): Promise<string | undefined> {
      if (this.creditCardAccessToken === '' || this.cardTokenFor3dSecureAuthorize === '') {
        // 登録用と一時保存用のそれぞれのトークンが存在しない場合はエラー
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        this.isSubmitting = false;
        this.isMounted = true;
        this.isExecutingOnApply = false;
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      }

      const member: Member = this.$store.getters['memberStore/member'];

      // uuidを発行
      const uuidForTemporarySavingApplicationData = uuidv4();

      /**  申込内容一時保存API へ渡す申込データ */
      const paymentMethodRegisterApplicationData = new FiveAConnectixNewConnectixRequest({
        /** クレジットカードトークン */
        credit_card_token: this.creditCardAccessToken,
        /** クレジットカードトークン有効期限 */
        token_expire_date: this.creditTokenExpireDate,
        /** UA種別 */
        ua_type: UA_TYPE.FIVE_A,
        /** 会員ID */
        member_id: this.memberId,
        /** 物件ID */
        property_id: this.propertyId,
        /** 棟番号 */
        building_id: this.buildingId,
        /** 部屋番号 */
        room_number: this.roomNumber,
        /**カード登録フラグ */
        credit_card_regist_flag: '1',
        /**カードコピーフラグ */
        isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
      });

      const paymentMethodRegisterApplicationDataJson = JSON.stringify(paymentMethodRegisterApplicationData);

      const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
        uuid: uuidForTemporarySavingApplicationData,
        subsequentProcess: 'five-a-connectix-apply',
        applicationDataJson: paymentMethodRegisterApplicationDataJson,
      });

      try {
        await this.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
      } catch {
        await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA), '');
        await this.$router.push('/5a/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // uuidをローカルストレージへ保存
      localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

      // VeriTrans_本人認証(トークン使用)APIへ渡すデータ
      const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
        uuid: uuidForTemporarySavingApplicationData,
        creditCardToken: this.cardTokenFor3dSecureAuthorize,
        serviceProviderIsp: SERVICE_PROVIDER.MYE,
      });

      // VeriTrans_本人認証(トークン使用)API の実行
      try {
        const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);

        location.href = authStartURL;
      } catch (error: any) {
        if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
          // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
          this.errorMessages.push(
            `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`
          );
          // エラーになったとき、クレジットカードコンポーネントを再描画する

          // コンポーネントの破棄・再描画のために key値 を変更する
          this.addResetCounter();
          // 「お支払い方法を保存」ボタンを非活性にする
          this.creditCardAccessToken = '';
          this.creditTokenExpireDate = '';
          this.cardTokenFor3dSecureAuthorize = '';
          //チェックボックス非表示＆チェックを外す
          this.isAgreedPrivacyPolicy = false;
          this.radioValueRequiringCardCopy = '';

          this.isFailedExecuteRegisterPaymentMethod = true;
          this.isSubmitting = false;
          this.isMounted = true;
          this.isExecutingOnApply = false;

          window.scrollTo(0, 0);

          return;
        } else {
          await VueErrorHandler.handle(error, '');
          await this.$router.push('/5a/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      }
    },
    async getDDDSecureStartUrlWithVeritransAccountId() {
      // uuidを発行
      const uuidForTemporarySavingApplicationData = uuidv4();

      /**  申込内容一時保存API へ渡す申込データ */
      const paymentMethodRegisterApplicationData = new FiveAConnectixNewConnectixRequest({
        /** UA種別 */
        ua_type: UA_TYPE.FIVE_A,
        /** 会員ID */
        member_id: this.memberId,
        /** 物件ID */
        property_id: this.propertyId,
        /** 棟番号 */
        building_id: this.buildingId,
        /** 部屋番号 */
        room_number: this.roomNumber,
        /**カードコピーフラグ */
        isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
      });
      if (this.portasVeriTransId) {
        paymentMethodRegisterApplicationData.card_account_id = this.portasVeriTransId;
        paymentMethodRegisterApplicationData.credit_card_regist_flag = '1';
      } else if (this.FiveAVeriTransId) {
        paymentMethodRegisterApplicationData.card_account_id = this.FiveAVeriTransId;
        paymentMethodRegisterApplicationData.credit_card_regist_flag = '0';
      }

      const paymentMethodRegisterApplicationDataJson = JSON.stringify(paymentMethodRegisterApplicationData);

      const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
        uuid: uuidForTemporarySavingApplicationData,
        subsequentProcess: 'five-a-connectix-apply',
        applicationDataJson: paymentMethodRegisterApplicationDataJson,
      });

      try {
        await this.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
      } catch {
        await VueErrorHandler.handle(new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA), '');
        await this.$router.push('/5a/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      // uuidをローカルストレージへ保存
      localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

      let cardHolderNameForAuthorize: string;
      if (this.cardHolderNameOnPortas) {
        cardHolderNameForAuthorize = this.cardHolderNameOnPortas;
      } else {
        cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizePortasCard!;
      }

      // VeriTrans_本人認証(VeriTrans会員ID使用)APIへ渡すデータ
      if (this.isAgreedCopyCardToIsp && this.portasVeriTransId) {
        const dddSecureAuthStartInfoWithVeritransAccountId = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
          uuid: uuidForTemporarySavingApplicationData,
          externalVeritransAccountId: this.portasVeriTransId,
          cardholderName: cardHolderNameForAuthorize,
        });

        // VeriTrans_本人認証(VeriTrans会員ID使用)API の実行
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartInfoWithVeritransAccountId);

          location.href = authStartURL;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(VeriTrans会員ID使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`
            );
            // コンポーネントの破棄・再描画のために key値 を変更する
            this.addResetCounter();
            //チェックボックス非表示＆チェックを外す
            this.isAgreedCopyCardToIsp = false;
            this.radioValueRequiringCardCopy = '';

            this.isFailedExecuteRegisterPaymentMethod = true;
            this.isSubmitting = false;
            this.isMounted = true;
            this.isExecutingOnApply = false;

            window.scrollTo(0, 0);

            return;
          } else {
            await VueErrorHandler.handle(error, '');
            await this.$router.push('/5a/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }
        }
      } else if (this.FiveAVeriTransId) {
        // 会員情報をStoreから取得
        const member: Member = this.$store.getters['memberStore/member'];

        let cardHolderNameForAuthorize: string;
        if (this.cardHolderNameFromIsp) {
          cardHolderNameForAuthorize = this.cardHolderNameFromIsp;
        } else {
          cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
        }

        const dddSecureAuthStartInfoWithVeritransAccountId = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
          uuid: uuidForTemporarySavingApplicationData,
          externalVeritransAccountId: member.eTncMemberId,
          cardholderName: cardHolderNameForAuthorize,
        });

        // VeriTrans_本人認証(VeriTrans会員ID使用)API の実行
        try {
          const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartInfoWithVeritransAccountId);

          location.href = authStartURL;
        } catch (error: any) {
          if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
            // VeriTrans_本人認証(VeriTrans会員ID使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
            this.errorMessages.push(
              `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが建物専用ホームページ<a class="link" href="${this.domainName}/${this.apid}/ft5/" target="_blank">「よくあるご質問」</a>からサポートセンターへご連絡ください。`
            );
            // エラーになったとき、クレジットカードコンポーネントを再描画する

            // コンポーネントの破棄・再描画のために key値 を変更する
            this.addResetCounter();

            this.isFailedExecuteRegisterPaymentMethod = true;
            this.isSubmitting = false;
            this.isMounted = true;
            this.isExecutingOnApply = false;

            window.scrollTo(0, 0);
            return;
          } else {
            await VueErrorHandler.handle(error, '');
            await this.$router.push('/5a/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }
        }
      }
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** 3Dセキュア本人認証用カード名義人を受け取る */
    getCardHolderNameFromPortas(cardHolderName: string) {
      this.cardHolderNameOnPortas = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、ISP登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizeIspCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizeIspCard = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、Portas登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizePortasCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizePortasCard = cardHolderName;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /**
     * クレジットカードコンポーネントが再描画されるように、 key値 を更新する
     */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },
    async onBack() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;
      await this.$router
        .push('/connectix/5a/apply')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    async isInCancelApplication() {
      // eslint-disable-next-line no-useless-catch
      try {
        const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = this.$store.getters['fiveACommonStore/customer'];

        if (fiveACustomer instanceof FiveACustomer) {
          // ISP会員ステータス取得
          const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
          this.ispMemberStatusFiveA = getIspMemberStatusFiveA(memberStatus, fiveACustomer);

          /**
           * ISP(Five.A)会員ステータスが次の2つの場合、ISP退会申し込み中であると判定する
           *
           * 1. ISP(Five.A)会員ステータス: 退会申し込み
           * 2. ISP(Five.A)会員ステータス: 退会済みログイン可能
           */
          if (this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
            return true;
          }
        } else {
          // データ不整合エラー
          await VueErrorHandler.handle(new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.FIVE_A.NO_CUSTOMER_INFO), '');
          await this.$router.push('/5a/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      } catch (e: any) {
        await VueErrorHandler.handle(e, '');
        await this.$router.push('/5a/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      return false;
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /** 申込内容一時保存API の実行 */
    async temporarySavingApplicationData(applicationData: TemporarySavingApplicationDataRequest) {
      return await SpfApiService.temporarySavingApplicationData(applicationData);
    },
    /** VeriTransの３Dセキュア本人認証（トークン使用） API の実行 */
    async create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest: Create3dSecureAuthStartInfoWithCardTokenRequest): Promise<string> {
      return await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
    },
  },
  computed: {
    isDisabledButton(): boolean {
      if (this.noDisplayCreditCardForm) {
        return false;
      } else if (
        this.FiveAVeriTransId &&
        (this.cardHolderNameFromIsp || (this.cardholderNameFormForAuthorizeIspCard && this.cardholderNameFormForAuthorizeIspCard.length > 1)) &&
        this.isAgreedPrivacyPolicy
      ) {
        return false;
      } else if (
        !this.isExpiredPortasCreditCard &&
        (this.cardHolderNameOnPortas || this.cardholderNameFormForAuthorizePortasCard) &&
        this.isAgreedCopyCardToIsp &&
        this.isAgreedPrivacyPolicy
      ) {
        return false;
      } else {
        return this.creditCardAccessToken === '' || !this.isAgreedPrivacyPolicy;
      }
    },
  },
});
</script>
