<template>
  <div class="e-mansion-connectix-terms">
    <LoadingComponent v-if="isLoading || isOnApplicationExecuting" />
    <main class="underlayer-main">
      <h1>Connectix お申し込み（規約）</h1>
    </main>

    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>e-mansion Connectixお申し込み</li>
        <li>規約</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:Connectix サービス規約 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix  サービス規約" />Connectix&nbsp;サービス規約</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component
          v-bind:errorMessages="errorMessages"
          v-bind:errorMessageTitle="errorMessageTitle"
          v-bind:isOneSentence="isErrorMessageOneSentence"
          v-bind:style="tainoErrorMessageStyles"
        />
        <div class="title-margin">
          <h3 class="">&emsp;<a :href="`${serviceTermsUrl}/e_kiyaku_cx.pdf`" class="link" target="_blank">Connectix サービス規約</a></h3>
        </div>
        <label><input type="checkbox" v-model="isAgreeTerms" /> <b>Connectixサービス規約に同意する</b></label>
      </div>
      <!-- /blc -->

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn-height btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn btn01 bs" type="button" v-on:click="onApplication()" :class="buttonColorSet" :disabled="!activeApplyButton">
            規約に同意してお申し込み<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../images/main.png');
}
.title-margin {
  margin-bottom: 50px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo-emansion.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}

.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

button.btn01:disabled {
  opacity: 0.5;
}

div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}

.connectix-campaign-title {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
}

.connectix-campaign {
  padding: 15px 0px 20px 0px;
  margin-bottom: 20px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
/** Entity e-mansion 共通利用 API : 契約基本情報取得 : レスポンス */
import { EMansionCustomer } from '../../../shared/classes/external-api/e-mansion/customer-response';
import { EMansionConnectixErrorResponse } from './classes/external-api/connectix-error-response';
import { EMansionConnectixPropertyRoomsResponseResult } from './classes/external-api/property-rooms-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionConnectixRoomsRequest } from '@/shared/classes/external-api/e-mansion/connectix-room-request';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { AuthService } from '@/shared/services/auth/auth-service';
import { ISP_MEMBER_STATUS, UaTypeValue, UA_TYPE } from '@/shared/const/service-type';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import DisplayConnectixTermsOfService from '@/shared/components/connectix/display-terms-of-service.vue';
import { CONNECTIX_TERMS_OF_SERVICE_E_MANSION_DATA } from '@/shared/terms-of-service/connectix-terms-of-service-e-mansion-data';

export default defineComponent({
  name: 'e-mansion-connectix-terms',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
    DisplayConnectixTermsOfService,
  },
  data: () => ({
    /** e-mansion 物件ID */
    eMansionPropertyId: '',
    /** サービス利用規約に同意するチェック、お申し込みボタン 表示/非表示 */
    availableApply: true,
    /** サービス利用規約に同意する */
    isAgreeTerms: false,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、お申し込みお手続きを進めることができません。',
    /** エラーメッセージを格納する配列 */
    errorMessages: new Array<string>(),
    isLoading: true, // 読み込み状態,最初はロード中
    isOnApplicationExecuting: false, // onApplication()実行中かどうか
    // Connectixサービスイン前エラー用に1文エラーにするかどうか
    isErrorMessageOneSentence: false,
    // キャンペーンメッセージ用
    service: 'Connectix',
    /** サービス規約URL */
    serviceTermsUrl: process.env.VUE_APP_CONNECTIX_SERVICE_TERMS_URL,
    /** e-mantion My の URL */
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    /** e-mantion の URL */
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    /* 滞納中のエラーメッセージのstyle */
    tainoErrorMessageStyles: {
      color: '',
    },
    // ISP(e-mansion)会員ステータス
    ispMemberStatusEMansion: '',
    // 利用規約のデータ
    termsOfServiceEMansionData: CONNECTIX_TERMS_OF_SERVICE_E_MANSION_DATA,
    /** ボタン押下判定 */
    isSubmitting: false,
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 入力値を保持しているストアの削除
      this.$store.commit('eMansionConnectixEntryStore/entryInputForm', null);

      /** e-mansionの会員であるかの条件を確認 */
      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];

      // // 会員情報（member テーブル）の primary_key_mye が null の場合は e-mansion 会員ではないと判断
      if (!member.primaryKeyMye) {
        this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
        this.errorMessages.push('本サービスは e-mansion 会員の方のみが対象になります。');
        return;
      }

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | null = property.uaType ?? null;
      const propertyId: string | null = property.apartmentId ?? null;

      // 物件テーブルに「Mye物件IDが空文字」OR「UA種別が Null」の場合は e-mansion の外部 API の情報が取得できないためエラー表示
      if (!uaType || !propertyId) {
        this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
        this.errorMessages.push('Connectix のお申し込みに必要な情報が取得できませんでした。');
        return;
      }

      /** お客様がConnectix未契約であることの条件を確認
       * 外部 API e-mansion 契約基本情報を取得して確認する
       */
      // 外部 API 用のクエリ
      // const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
      //   ua_type: uaType,
      //   apartment_id: propertyId,
      // });

      // // e-mansion のメンバー ID をStoreから取得する
      // const eMansionMemberId: string = await this.$store.dispatch('memberStore/eMansionMemberId');

      // e-mansion 契約基本情報を Store から取得
      // const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
      //   memberId: eMansionMemberId,
      //   query: findCustomerByIdQueryDto,
      // });
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/customer'];
      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、エラーメッセージを表示する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          this.isErrorMessageOneSentence = true;
          this.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }

        if (eMansionCustomer.op_connectix_contract_status === '1') {
          this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          // 既にConnectix契約済みであれば、エラーメッセージ表示
          this.errorMessages.push('お客様は既にConnectixを契約しています。');
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
        // 正常ケースの場合
        this.eMansionPropertyId = eMansionCustomer.property_id;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      // 料金滞納チェック
      if (eMansionCustomer instanceof EMansionCustomer) {
        if (eMansionCustomer.defaulter_status === '1' || eMansionCustomer.defaulter_status === '2') {
          // 料金滞納中であれば、エラーメッセージ表示
          let apid = '';
          if (property) {
            apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
          }
          this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          const url = this.eMansionMyUrl + '/login/login.php?em_redirect=' + this.eMansionUrl + '/' + apid + '/';
          this.errorMessages.push(
            'ご登録済みのお支払い方法ではお申し込みを承ることができません。大変恐れ入りますが、<a href="' +
              url +
              '"class="link" target="My e-mansion">お住まいの建物専用ホームページ</a>からお問い合わせください。'
          );
          // エラーメッセージタイトルを非表示
          this.errorMessageTitle = '';
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          // エラーメッセージを赤色に変更
          this.tainoErrorMessageStyles = {
            color: 'red',
          };
          this.isLoading = false;
          return;
        } else if (!eMansionCustomer.defaulter_status || eMansionCustomer.defaulter_status !== '0') {
          // 外部APIエラーのデータ不整合
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
        }
        // 正常ケースの場合
        this.eMansionPropertyId = eMansionCustomer.property_id;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      // e-mansion 物件基本情報を Store から取得
      // const params = {
      //   // propertyId: this.eMansionPropertyId,
      //   apartmentId: property.apartmentId,
      //   uaType: UA_TYPE.E_MANSION
      // };
      // const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', params);
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        const emSinTime = eMansionProperty.em_sin_time;
        if (new Date(parseInt(emSinTime.slice(0, 4), 10), parseInt(emSinTime.slice(4, 6), 10) - 1, parseInt(emSinTime.slice(6, 8), 10)) > new Date()) {
          // 物件のサービスイン日が未来日時の場合はエラー
          this.isErrorMessageOneSentence = true;
          this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          this.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
      }
    } catch (error) {
      throw error;
    }

    if (this.isOnApplicationExecuting) {
      return;
    }
    this.isOnApplicationExecuting = true;

    const eMansionConnectixRoomsRequest: EMansionConnectixRoomsRequest = {
      propertyId: this.eMansionPropertyId,
      uaType: UA_TYPE.E_MANSION,
    };
    try {
      // Connectix お申し込みチェック API から部屋番号一覧をStoreから取得
      const propertyRooms: EMansionConnectixPropertyRoomsResponseResult | EMansionConnectixErrorResponse = await this.$store.dispatch(
        'eMansionConnectixEntryStore/propertyRooms',
        eMansionConnectixRoomsRequest
      );
      if (propertyRooms instanceof EMansionConnectixPropertyRoomsResponseResult) {
        // Connectixお申し込み可能物件が存在しない場合は、エラーメッセージを表示する（apply_connectix_id = 1: お申し込み不可, 0: お申し込み可能）
        const isApplyConnectix = propertyRooms.result.rooms.some((room) => room.apply_connectix_id === '0');
        if (!isApplyConnectix) {
          this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          this.errorMessages.push(`該当の物件にはConnectixのお申し込みが出来る部屋がありません。`);
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }
      } else if (propertyRooms instanceof EMansionConnectixErrorResponse) {
        // API側から返されたエラーメッセージを表示
        this.errorMessages = propertyRooms.errorInfo;
        // エラーメッセージを見せる為に画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.ROOM_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isOnApplicationExecuting = false;
      // 読み込み完了
      this.isLoading = false;
    }
  },
  methods: {
    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      window.scrollTo(0, 0);
      this.isOnApplicationExecuting = false;
      this.isLoading = false;
    },
    /** 「規約に同意してお申し込み ➡」ボタン押下時 : お申し込み画面に遷移する */
    async onApplication() {
      // 読み込み完了
      this.isLoading = true;
      if (this.isOnApplicationExecuting) {
        return;
      }

      this.isOnApplicationExecuting = true;
      try {
        this.errorMessages = [];
        this.isErrorMessageOneSentence = false;

        // ISP(e-mansion)側で退会申し込み中のとき、エラーメッセージを表示させる
        if (await this.isInCancelApplication()) {
          this.isErrorMessageOneSentence = true;
          this.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
          this.showErrorMessage();
          return;
        }

        // 「サービス利用規約に同意する」がチェックされていて、かつエラーがない場合はお申し込み画面に遷移する。
        if (this.isAgreeTerms && !this.errorMessages.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/connectix/e-mansion/apply').catch((error: any) => {
            checkRouterError(error);
          });
        }
      } catch (error) {
        throw error;
      }
    },
    async isInCancelApplication() {
      const property: Property = this.$store.getters['propertyStore/property'];

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });

      const member: Member = this.$store.getters['memberStore/member'];
      const eMansionMemberId = member.primaryKeyMye ?? '';
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });

      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/e-mansion')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
  computed: {
    buttonColorSet() {
      if (this.availableApply && this.isAgreeTerms) {
        return 'btn btn01 bs';
      }
      return 'btn btn04 bs';
    },
    activeApplyButton() {
      if (this.availableApply && this.isAgreeTerms) {
        return true;
      }
      return false;
    },
  },
});
</script>
