<template>
  <div class="five-a-connectix-apply">
    <LoadingComponent v-if="!isMounted || isExecutingOnApply" />
    <main class="underlayer-main">
      <h1>Connectix サービスお申し込み</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>Five.A Connectix お申し込み</li>
        <li>サービスお申し込み</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li class="stay">サービスお申し込み</li>
        <li>お申し込み内容の確認</li>
        <li>お申し込み完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="Connectix　お申し込み" />Connectix お申し込み</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />
        <div class="sblc">
          <h3 class="service-h3">お申し込み対象のお部屋番号</h3>
          <p>お客様のお部屋番号をご入力ください。</p>
          <table class="table-type2">
            <tr>
              <th>建物名</th>
              <td>{{ propertyName }}</td>
            </tr>
            <tr>
              <th class="va-middle">部屋番号（半角英数字）</th>
              <td>
                <input
                  type="text"
                  class="text short"
                  v-model="inputData.roomNumber"
                  :class="{ 'error-input': isInvalidRoomNumber }"
                  @input="v$.inputData.roomNumber.$touch()"
                  placeholder="例）101"
                />
              </td>
            </tr>
          </table>
        </div>
        <div class="sblc">
          <h3 class="service-h3">お申し込みサービス内容</h3>
          <table class="table-type2">
            <tr>
              <th>サービス名</th>
              <td>Connectix</td>
            </tr>
            <tr>
              <th>料金（月額）</th>
              <!-- 外部APIレスポンスから取得出来ないためベタ書き -->
              <td>
                <div v-if="connectixUsageFeeCp">
                  <div style="color: red">キャンペーン中</div>
                  <div>{{ connectixUsageFee }} 円</div>
                  <div style="color: red">→ {{ connectixUsageFeeCp }} 円</div>
                </div>
                <div v-else>
                  <div>{{ connectixUsageFee }} 円</div>
                </div>
              </td>
            </tr>
          </table>
          <p class="att grey">※価格は全て税込表示（消費税10％）です。<br />今後消費税率が改正された場合は、改正後の税率による価格に変更となります。</p>
          <p class="att grey">※本サービスはベストエフォートの為、時間帯や状況などによって異なることがあり、保証されるものではございません。</p>
        </div>
      </div>

      <div class="blc">
        <p class="form-btn-txt">「お申し込み」ボタンをクリックしてください。確認ページへ進みます。</p>
        <div class="btn-area">
          <button class="btn btn01 bs" type="button" v-on:click="onApply()">お申し込み<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 185px;
  display: inline;
  background-image: url('../../../images/logo_fivea.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 177px auto;
}
.underlayer-main h1 img {
  width: 35%;
  position: relative;
  top: 2px;
}

.underlayer-main h1 {
  padding: 10px 0 10px 130px;
  background-size: 120px auto;
}

.error-input {
  background-color: #ed7d89;
}

/* お申し込みボタン 非活性時の定義 */
button.btn01:disabled {
  opacity: 0.5;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

/** 使用するバリデーションの定義 */
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FiveAConnectixRoomsRequest } from '@/shared/classes/external-api/five-a/connectix-room-request';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ISP_MEMBER_STATUS, UA_TYPE, UaTypeValue } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { halfWidth } from '@/shared/util/validators';
import useVuelidate from '@vuelidate/core';
import { maxLength, required } from '@vuelidate/validators';
import { FiveACustomerQueryDto } from '../../../shared/classes/external-api/five-a/customer-dto';
import { FiveAConnectixErrorResponse } from './classes/external-api/connectix-error-response';
import { FiveAConnectixPropertyRooms, FiveAConnectixPropertyRoomsResponse } from './classes/external-api/property-rooms-response';

/** Apply コンポーネント */
export default defineComponent({
  name: 'five-a-connectix-apply',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 建物名 */
    propertyName: '',
    /** Connectix利用金額 */
    connectixUsageFee: '',
    /** Connectix利用金額 */
    connectixUsageFeeCp: '',
    /** 画面入力項目 */
    inputData: {
      /** 部屋番号 */
      roomNumber: '',
    },
    /** エラーの発生有無 部屋番号 */
    isInvalidRoomNumber: false,
    /** 物件基本情報 */
    property: {},
    /** UCOM 物件 ID */
    propertyId: '',
    /** 部屋番号一覧 */
    propertyRooms: null as FiveAConnectixPropertyRoomsResponse | null,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    // mounted()実行中かどうか
    isMounted: false as boolean,
    // onApply()実行中かどうか
    isExecutingOnApply: false as boolean,
    // ISP(Five.A)会員ステータス
    ispMemberStatusFiveA: '',
  }),
  /** バリデーション定義 */
  validations: {
    inputData: {
      /** 部屋番号 */
      roomNumber: {
        required,
        halfWidth,
        maxLength: maxLength(8),
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしていない状態で遷移した場合は「総合トップ」画面にリダイレクトする */
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        await this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      // エラー判定の初期化
      this.isInvalidRoomNumber = false;

      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      const fiveAMemberId = member.primaryKeyMye ?? '';

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property.uaType;
      const propertyId: string | undefined = property.apartmentId;

      // 必要な物件情報を取得できなかった場合はエラー画面に遷移
      if (!uaType) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      // 契約基本情報取得 API 用のクエリ
      const fiveACustomerQueryDto = new FiveACustomerQueryDto({
        ua_type: uaType,
        apartment_id: propertyId,
      });

      const customer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
        memberId: fiveAMemberId,
        query: fiveACustomerQueryDto,
      });
      if (customer instanceof FiveACustomer) {
        //物件IDの取得
        this.propertyId = customer.property_id;
        // 部屋番号
        this.inputData.roomNumber = customer.room_number ?? '';
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      const params = {
        propertyId: this.propertyId,
        uaType: UA_TYPE.FIVE_A,
      };

      // 物件基本情報を取得
      const propertyInfo: FiveAProperty | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', params);
      if (propertyInfo) {
        this.property = propertyInfo;
        if (this.property instanceof FiveAProperty) {
          this.propertyName = this.property.em_ap_name;
          // Connectix利用金額
          this.connectixUsageFee = this.property.op.sdwan.fee;
          this.connectixUsageFeeCp = this.property.op.sdwan.fee_cp;
        } else {
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
        }
      }
      // 部屋番号一覧をStoreから取得
      const fiveAConnectixRoomsRequest: FiveAConnectixRoomsRequest = {
        ua_type: UA_TYPE.FIVE_A,
      };
      const roomParams = { propertyId: propertyId, fiveAConnectixRoomsRequest: fiveAConnectixRoomsRequest };
      const propertyRooms: FiveAConnectixPropertyRoomsResponse | FiveAConnectixErrorResponse = await this.$store.dispatch('fiveAConnectixEntryStore/propertyRooms', roomParams);

      if (propertyRooms instanceof FiveAConnectixPropertyRoomsResponse) {
        if (!propertyRooms.rooms.length) {
          this.errorMessages.push('物件の部屋番号情報が取得できませんでした。');
          window.scrollTo(0, 0);
          return;
        } else {
          this.propertyRooms = propertyRooms;
        }
      } else if (propertyRooms instanceof FiveAConnectixErrorResponse) {
        // エラーケースの場合
        // API側から返されたエラーメッセージを表示
        this.$data.errorMessages = propertyRooms.errorInfo;
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.ROOM_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      // 確認画面から戻って来た場合は、事前に入力されていた情報を再現する
      const inputApply = this.$store.getters['fiveAConnectixEntryStore/entryInputForm'];
      if (inputApply != null) {
        this.inputData.roomNumber = inputApply.roomNumber;
      }

      this.isMounted = true;
    }
  },
  methods: {
    async onApply() {
      if (this.isExecutingOnApply) {
        return;
      }
      this.isExecutingOnApply = true;

      try {
        // エラーメッセージ格納配列初期化
        this.errorMessages = [];
        this.errorMessageTitle = `恐れ入りますが、入力内容をもう一度ご確認ください。`;

        // ISP(Five.A)側で退会申し込み中のとき、/connectix/5a/terms に遷移してエラーメッセージを表示
        if (await this.isInCancelApplication()) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          await this.$router.push('/connectix/5a/terms').catch((error: any) => {
            checkRouterError(error);
          });
          return;
        }

        // 部屋番号
        if (this.v$.inputData.roomNumber?.$invalid) {
          this.isInvalidRoomNumber = true;
          // 必須チェック
          if (this.v$.inputData.roomNumber.required.$invalid) {
            this.errorMessages.push(`部屋番号を入力してください。`);
          }
          // 形式チェック
          if (this.v$.inputData.roomNumber.halfWidth.$invalid) {
            this.errorMessages.push(`正しい部屋番号を入力してください。`);
          }
          // 桁数チェック
          if (this.v$.inputData.roomNumber.maxLength.$invalid) {
            this.errorMessages.push(`正しい部屋番号を入力してください。`);
          }
        }

        if (this.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isExecutingOnApply = false;
          return;
        }

        if (!this.propertyRooms) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_ROOM);
        }

        const propertyRooms = this.propertyRooms;
        this.inputData.roomNumber = this.inputData.roomNumber.trim();
        // お申し込み可能な部屋の中に自部屋があるかチェック
        const applyRooms = propertyRooms.rooms.find(
          (propertyRoom: FiveAConnectixPropertyRooms) => propertyRoom.apply_connectix_id === '0' && propertyRoom.room_number === this.inputData.roomNumber
        );
        if (applyRooms === undefined) {
          // 入力した部屋がお申し込み可能な部屋ではなかった場合はエラー
          this.errorMessages.push(`正しい部屋番号を入力してください。`);
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isExecutingOnApply = false;
          return;
        }
      } catch (error) {
        throw error;
      }

      // 入力情報をストアに保存
      const inputData = this.inputData;

      this.$store.commit('fiveAConnectixEntryStore/entryInputForm', inputData);

      if (!this.errorMessages.length) {
        await this.$router
          .push('/connectix/5a/confirm')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            checkRouterError(error);
          })
          .finally(() => {
            this.isExecutingOnApply = false;
          });
      }
    },
    async isInCancelApplication() {
      try {
        const property: Property = this.$store.getters['propertyStore/property'];

        const fiveACustomerQueryDto = new FiveACustomerQueryDto({
          ua_type: property.uaType,
          apartment_id: property.apartmentId,
        });

        const member: Member = this.$store.getters['memberStore/member'];
        const fiveAMemberId = member.primaryKeyMye ?? '';

        const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
          memberId: fiveAMemberId,
          query: fiveACustomerQueryDto,
        });

        if (fiveACustomer instanceof FiveACustomer) {
          // ISP会員ステータス取得
          const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
          this.ispMemberStatusFiveA = getIspMemberStatusFiveA(memberStatus, fiveACustomer);

          /**
           * ISP(Five.A)会員ステータスが次の2つの場合、ISP退会申し込み中であると判定する
           *
           * 1. ISP(Five.A)会員ステータス: 退会申し込み
           * 2. ISP(Five.A)会員ステータス: 退会済みログイン可能
           */
          if (this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
            return true;
          }
        } else {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
        }
      } catch (e) {
        throw e;
      }
      return false;
    },
  },
});
</script>
