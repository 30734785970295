<template>
  <div class="veritrans-credit-card-component-for-isp">
    <div>
      <h3 class="service-h3">現在のお支払い方法</h3>
      <div class="pd10 ml10" v-if="maskedCardNumberFromIsp && cardExpiredFromIsp">
        <table class="table-type2">
          <tbody>
            <tr>
              <th class="va-middle">カード番号</th>
              <td>{{ maskedCardNumberFromIspWithHyphen }}</td>
            </tr>
            <tr>
              <th class="va-middle">カード期限</th>
              <td>{{ cardExpiredMonthFromIsp }}月 ／ {{ cardExpiredYearFromIsp }}年</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="ispPaymentMethodType === 'accountTransfer' || ispPaymentMethodType === 'accountTransferInvoice'">
        <p>口座振替をご利用中です。クレジットカード払いに変更されたい場合は、以下でご登録ください。</p>
      </div>
      <div v-else>
        <p>クレジットカードのご登録はありません。</p>
      </div>

      <div class="blc">
        <h3 class="service-h3">新規お支払い方法のご入力</h3>
        <div v-if="portasVeriTransId && !isDeletedCreditCardOnPortas && ispPaymentMethodType !== 'creditCard'">
          <div class="pd10 ml10">
            <p>
              アルテリア・ネットワークス株式会社が提供するPortas（ポルタス）にご登録されているお支払い方法を、<br />{{
                ispProviderCompanyName === 'アルテリア・ネットワークス株式会社' ? '' : '株式会社つなぐネットコミュニケーションズが提供する'
              }}{{ ispName }}にコピーして、お支払いいただくことができます。
            </p>
          </div>

          <div class="pd10 ml10 mt10">
            <p>
              <b>
                Portasでご登録いただいているクレジットカード情報を{{
                  ispProviderCompanyName === 'アルテリア・ネットワークス株式会社' ? '' : '株式会社つなぐネットコミュニケーションズが提供する'
                }}{{ ispName }}に登録しますか。
              </b>
            </p>
          </div>
          <div class="ml10 mt20 mb30">
            <input id="cardCopyYes" :disabled="isCreatedToken" v-model="isSelectedCopy" type="radio" name="isRequiredCopyCard" value="yes" v-on:change="isRequiredCardCopy" /><label
              for="cardCopyYes"
              >はい</label
            >
            <span v-if="isCreatedToken"
              >Portasに登録されているカード情報をコピーしたい場合は「{{ backButtonName }}」ボタンを押してもう一度登録手続きをして頂くようお願いいたします。
            </span>
            <div class="mt20">
              <input id="cardCopyNo" :disabled="isAgreedCardInfoCopy" v-model="isSelectedCopy" type="radio" name="isRequiredCopyCard" value="no" v-on:change="isRequiredCardCopy" />
              <label for="cardCopyNo">いいえ</label>
              <span v-if="isAgreedCardInfoCopy">
                クレジットカード情報の新規登録を行いたい場合は「クレジットカード情報をコピーすることに同意する」チェックボックスのチェックを外してください。
              </span>
            </div>
          </div>

          <div v-if="isSelectedCopy === 'yes'" class="pd10 ml20">
            <h3>Portasからクレジットカード情報をコピーする</h3>

            <button
              v-bind:class="{ ' btn01': !isRequestedDisplayCardInfo, ' btn04': isRequestedDisplayCardInfo }"
              :disabled="isRequestedDisplayCardInfo"
              v-on:click="onRequiredDisplayCardInfo"
              class="display-portas-card-info-btn btn bs"
            >
              Portasに登録されているクレジットカード情報を表示する
            </button>
          </div>

          <div v-if="isRequestedDisplayCardInfo && isSelectedCopy === 'yes'" class="ml40">
            <table class="table-type2">
              <tbody>
                <tr>
                  <th class="va-middle">カード番号</th>
                  <td>{{ maskedCardNumberOnPortas }}</td>
                </tr>
                <tr>
                  <th class="va-middle">カード期限</th>
                  <td>{{ cardExpiredMonthOnPortas }}月 ／ {{ cardExpiredYearOnPortas }}年</td>
                  <td v-if="isExpiredPortasCreditCard"><b class="red">ご利用になれないクレジットカードです。</b></td>
                </tr>
                <tr v-if="!cardHolderNameOnPortas">
                  <td>
                    <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
                  </td>
                  <td>
                    <div class="mt10">
                      <input
                        type="text"
                        class="text card-name"
                        required
                        minlength="2"
                        maxlength="45"
                        @change="inputCardHolderNameForAuthorizePortasCard"
                        v-model.trim="form.cardholderNameFormForAuthorizePortasCard"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="mt20">
              <p>※クレジットカード情報は、サービス料金決済の目的で利用します。</p>
              <p>
                ※ご登録いただいたクレジットカード情報は、アルテリア・ネットワークス株式会社{{
                  ispProviderCompanyName === 'アルテリア・ネットワークス株式会社' ? '' : '、株式会社つなぐネットコミュニケーションズ'
                }}で保持せず直接、株式会社DGフィナンシャルテクノロジーへ送信され、管理されます。
              </p>
              <p v-if="ispProviderCompanyName !== 'アルテリア・ネットワークス株式会社'">
                ※なお、原則として6か月を超えて利用されていないクレジットカード情報については、株式会社つなぐネットコミュニケーションズの責任において削除させていただきます。
              </p>
              <p>※お申し込み完了までの間にPortasのお支払い方法を変更された場合、変更後のクレジットカードが登録される場合がございます。</p>
              <p>
                ※クレジットカードの会員番号、有効期限の変更、更新がカード会社より当社に通知された場合、引き続き更新されたクレジットカードへ、ご利用料金の請求を継続させていただきます。
              </p>
            </div>

            <div class="pd20">
              <label>
                <input v-model="isAgreedCardInfoCopy" v-on:change="sendIsAgreeCardInfoCopy" :disabled="isExpiredPortasCreditCard" type="checkbox" />
                クレジットカード情報をコピーすることに同意する
                <p v-if="isExpiredPortasCreditCard" class="is-not-able-to-use-credit-card">
                  <b class="red">ご利用になれないクレジットカードが登録されているため、コピーを実施できません。</b>
                </p>
                <p v-if="isExpiredPortasCreditCard" class="is-not-able-to-use-credit-card">
                  <b class="red">「いいえ」ラジオボタンを選択し、{{ ispName }}へ新規にクレジットカード情報を登録していただくようお願いいたします。</b>
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isRequiredDisplayCreditCardInputForm()">
      <p class="mb15"><b>ご利用可能なクレジットカードの種類</b></p>
      <img src="../../images/ucom/crecitcard.png" alt="ご利用いただけるカードの種類" class="crecitcard" />
      <p>Visa（ビザ）、Mastercard（マスターカード）、JCB（ジェーシービー）、American Express（アメリカン・エキスプレス）、Diners Club（ダイナースクラブ）</p>
      <h4>クレジットカード情報の入力</h4>
      <p>登録したいクレジットカード情報を入力してください。</p>
      <p>複数回入力エラーを繰り返した場合、一定期間ご入力を受け付けられなくなります。</p>
      <p>お手元にクレジットカードをご用意のうえ、正確にご入力ください。</p>
      <table class="table-type2">
        <tr>
          <td><b>カード番号（半角数字）</b><span class="req">必須</span></td>
          <td>
            <p class="mb10">半角数字で1マスに4桁ずつ、左詰めでご入力ください</p>
            <input type="text" v-model.trim="form.number1" @input="v$.form.number1.$touch()" class="text short" pattern="\d{4}" />&emsp;-&emsp;
            <input type="text" v-model.trim="form.number2" @input="v$.form.number2.$touch()" class="text short" pattern="\d{4}" />&emsp;-&emsp;
            <input type="text" v-model.trim="form.number3" @input="v$.form.number3.$touch()" class="text short" pattern="\d{4}" />&emsp;-&emsp;
            <input type="text" v-model.trim="form.number4" @input="v$.form.number4.$touch()" class="text short" pattern="\d{2,4}" />
          </td>
        </tr>
        <tr>
          <td>
            <b>セキュリティコード<br />（半角数字）</b><span class="req">必須</span>
          </td>
          <td>
            <p class="mb10"></p>
            <div class="mt10 security-code">
              <input type="text" class="text short" v-model.trim="form.securityCode" />
              <span class="tooltip">
                ?<span class="description-right">
                  セキュリティコードとはクレジットカード番号とは別にカード裏面署名欄に記載された3桁の番号です。American Expressは表面に4桁で記載されていることもあります。</span
                >
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td><b>カード期限</b><span class="req">必須</span></td>
          <td>
            <p class="mb10">クレジットカードに記載の年月をご入力ください</p>
            <select v-model="form.expiryMonth" @change="v$.form.expiryMonth.$touch()" class="form-select">
              <option value="">--</option>
              <!-- NOTE : 整数値を取る v-for は 1 から始まる https://jp.vuejs.org/v2/guide/list.html#%E7%AF%84%E5%9B%B2%E4%BB%98%E3%81%8D-v-for -->
              <option v-for="n in 12" :key="n" :value="String(n).padStart(2, '0')">
                {{ String(n).padStart(2, '0') }}
              </option>
            </select>
            月&emsp;
            <select v-model="form.expiryYear" @change="v$.form.expiryYear.$touch()" class="form-select">
              <option value="">--</option>
              <option :value="String(currentYear).slice(-2)">{{ String(currentYear).slice(-2) }}</option>
              <option v-for="n in 9" :key="n" :value="String(n + currentYear).slice(-2)">
                {{ String(n + currentYear).slice(-2) }}
              </option>
            </select>
            年（西暦下2桁）
          </td>
        </tr>
        <tr>
          <td>
            <b>カード名義人<br />（半角英数字 記号）</b><span class="req">必須</span>
          </td>
          <td>
            <div class="mt10">
              <input type="text" class="text card-name" @change="inputEventConvertToUpper" v-model.trim="form.cardholderName" />
            </div>
          </td>
        </tr>
      </table>
      <div class="btn-area">
        <button class="btn-height btn btn01 bs" v-on:click="onPostCreditCard">クレジットカード情報を送信<i class="material-icons link link-icon">east</i></button>
      </div>
    </div>

    <div v-if="isCreatedToken" class="mt20 ml40 red">
      <p><b>ご入力いただいたクレジットカード情報が株式会社DGフィナンシャルテクノロジーに送信されました。</b></p>
      <p>
        <b>{{ successMessage }}</b>
      </p>
    </div>

    <div v-if="isRequiredDisplayCreditCardNotice()" class="border-grey bg-grey pd20 mt40 ml40">
      <div v-if="isCreatedToken">
        <p class="att red"><b>※「クレジットカード情報を送信」ボタンをクリック後、15分以内にお申し込みを完了させてください。</b></p>
      </div>
      <p>※クレジットカード情報は、サービス料金決済の目的で利用します。</p>
      <p>
        ※ご登録いただいたクレジットカード情報は、アルテリア・ネットワークス株式会社{{
          ispProviderCompanyName === 'アルテリア・ネットワークス株式会社' ? '' : '、株式会社つなぐネットコミュニケーションズ'
        }}で保持せず直接、株式会社DGフィナンシャルテクノロジーへ送信され、管理されます。
      </p>
      <p v-if="ispProviderCompanyName !== 'アルテリア・ネットワークス株式会社'">
        ※なお、原則として6か月を超えて利用されていないクレジットカード情報については、株式会社つなぐネットコミュニケーションズの責任において削除させていただきます。
      </p>
      <p>※クレジットカードの会員番号、有効期限の変更、更新がカード会社より当社に通知された場合、引き続き更新されたクレジットカードへ、ご利用料金の請求を継続させていただきます。</p>
    </div>

    <div class="border-grey bg-grey pd20 mt40 ml40">
      <p>※お申込みのタイミングによっては、前月分のご請求から変更後のクレジットカードでのお支払いとなることがございます。予めご了承ください。</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-height {
  height: 50px;
}

.req {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  float: right;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
}

img.crecitcard {
  max-width: 640px;
  width: 100%;
}

button[disabled] {
  pointer-events: none;
}

.tooltip {
  background: #666666;
  margin: 5px;
  font-size: 12px;
  border-radius: 100%;
  color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  text-align: center;
  left: 155px;
}

.security-code {
  display: flex;
  position: relative;
  align-items: center;
}

.description-right {
  width: 200px;
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translateY(-50%);
  padding: 8px;
  border-radius: 10px;
  background-color: #666;
  font-size: 12px;
  color: #fff;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  z-index: 1;
  transition: 0.5s all;
}

.tooltip:hover .description-right {
  left: 100%;
  visibility: visible;
  opacity: 0.8;
}
.card-name {
  width: 100%;
  max-width: 360px;
}
@media screen and (max-width: 767px) {
  .tooltip {
    left: 85px;
  }
}

.display-portas-card-info-btn {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-left: 1em;
  padding-right: 1em;
  min-height: 50px;
}

.is-not-able-to-use-credit-card {
  padding-left: 15px;
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, numeric, required } from '@vuelidate/validators';
import axios from 'axios';
import { defineComponent } from 'vue';

import { VeritransCreditCardTokenRequest } from '../classes/veritrans-credit-card-token-request';
import { VeritransApiService } from '../services/api/veritrans-api-service';
import { VeritransErrorService } from '../services/veritrans/veritrans-error-service';
import { VeritransRequestStatusService } from '../services/veritrans/veritrans-request-status-service';
import { isCreditCardExpired } from '../util/func-is-credit-card-expired';
import { processYearNumber } from '../util/func-process-date';
import { inputEventConvertToUpper } from '../util/inputEventConvertToUpper';

import { PaymentMethodListResponse } from '@/shared/classes/platform/payment-method-list-response';
import { Member } from '@/shared/classes/spf-api/member';
import { SpfApiService } from '@/shared/services/api/spf-api-service';

interface PaymentMethodInfoForDisplay extends PaymentMethodListResponse {
  isExpired: boolean;
}
type SplittedCardExpireDate = {
  cardExpireMonth: string;
  cardExpireYear: string;
};
/** (UCOM以外共通) Veritrans クレジットカード情報入力コンポーネント */
export default defineComponent({
  name: 'veritrans-credit-card-component-isp',
  // 親コンポーネントから受け取るデータ
  props: {
    /** API トークンキー */
    apiTokenKey: {
      type: String,
      default: '',
    },
    /** トークン取得成功後に表示するメッセージ */
    successMessage: {
      type: String,
      default: '',
    },
    /** 連携先ISPに登録されている支払方法のマスク化済カード番号 */
    maskedCardNumberFromIsp: {
      type: String,
      default: '',
    },
    /** 連携先ISPに登録されている支払方法の有効期限 */
    cardExpiredFromIsp: {
      type: String,
      default: '',
    },
    /** 連携先ISPに登録されている支払方法のカード名義人 */
    cardHolderNameFromIsp: {
      type: String,
      default: '',
    },
    /** ログイン中のPortasユーザーが連携中のISP名 */
    ispName: {
      type: String,
      default: '',
    },
    /** ログイン中のPortasユーザーが連携しているISPのサービス提供元企業名 */
    ispProviderCompanyName: {
      type: String,
      default: '',
    },
    /** ログイン中のPortasユーザーに紐づくVeriTrans会員 ID */
    portasVeriTransId: {
      type: String,
      default: '',
    },
    /** 親コンポーネントにおいて前画面へ遷移するボタンの名称 */
    backButtonName: {
      type: String,
      default: '戻る',
    },
    /** ISPに登録済の支払い方法種別 */
    ispPaymentMethodType: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    /** 入力値 */
    form: {
      /** クレジットカード番号1 */
      number1: '',
      /** クレジットカード番号2 */
      number2: '',
      /** クレジットカード番号3 */
      number3: '',
      /** クレジットカード番号4 */
      number4: '',
      /** クレジットカード有効期限月 (ゼロパディング2桁) */
      expiryMonth: '',
      /** クレジットカード有効期限年 (西暦下2桁) */
      expiryYear: '',
      /** クレジットカード名義人 */
      cardholderName: '',
      /** クレジットカード名義人(ISPで登録済のカードにカード名義人が存在しない場合) */
      cardholderNameFormForAuthorizeIspCard: '',
      /** クレジットカード名義人(Portasで登録済のカードにカード名義人が存在しない場合) */
      cardholderNameFormForAuthorizePortasCard: '',
      /** セキュリティコード */
      securityCode: '',
    },
    /** 入力フォームを表示するか否か */
    isInput: true,
    /** 西暦の入力欄を組み立てるための現在年 */
    currentYear: 0,
    /** onPostCreditCard()が実行中かどうか */
    isOnPostCreditCardExecuting: false,
    /** コンポーネントが支払方法登録画面に埋め込まれているか、編集画面に埋め込まれているかの判定結果 */
    processType: '',
    /** カードコピーしますか「はい」ラジオボタンが選択されているかどうか */
    isSelectedCopy: '',
    /** 連携先ISPに登録されているカード情報を表示するボタン押下ステータス */
    isRequestedDisplayCardInfo: false,
    /** クレジットカード情報送信用トークンが作成されているか */
    isCreatedToken: false,
    /** ユーザーがカード情報をコピーすることに同意しているかどうか */
    isAgreedCardInfoCopy: false,
    /** 支払方法をコピーするか確認する部品の表示が必要かどうか */
    isRequiredDisplayCardCopyFunction: false,
    /** 連携先ISPで登録されたマスク化済クレジットカード番号(ハイフン付き) */
    maskedCardNumberFromIspWithHyphen: '',
    /** 連携先ISPで登録されたクレジットカードの有効期限(月) */
    cardExpiredMonthFromIsp: '',
    /** 連携先ISPで登録されたクレジットカードの有効期限(年) */
    cardExpiredYearFromIsp: '',
    /** Portasに登録されていたクレジットカードが全て削除済かどうか */
    isDeletedCreditCardOnPortas: false,
    /** Portasで登録されたクレジットカードの有効期限(月) */
    cardExpiredMonthOnPortas: '',
    /** Portasで登録されたクレジットカードの有効期限(年) */
    cardExpiredYearOnPortas: '',
    /** Portasで登録されたクレジットカードのマスク済カード番号 */
    maskedCardNumberOnPortas: '',
    /** Portasで登録されたクレジットカードの有効期限 */
    cardExpiredOnPortas: '',
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: false,
    /** Portasに登録されているクレジットカードに登録されているカード名義人 */
    cardHolderNameOnPortas: '' as string | undefined,
  }),
  validations: {
    form: {
      number1: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      number2: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      number3: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      number4: {
        required,
        numeric,
        minLength: minLength(2),
        maxLength: maxLength(4),
      },
      expiryMonth: {
        required,
      },
      expiryYear: {
        required,
      },
      cardholderName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(45),
      },
      securityCode: {
        required,
        numeric,
        minLength: minLength(3),
        maxLength: maxLength(4),
      },
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  emits: [
    'onPostCreditCardError',
    'cardTokenForRegister',
    'cardTokenExpireDateForRegister',
    'getCreditEndNumber',
    'cardTokenFor3dSecureAuthorize',
    'isExpiredPortasCreditCard',
    'portasCardHolderName',
    'cardholderNameFormForAuthorizeIspCard',
    'cardholderNameFormForAuthorizePortasCard',
    'change',
    'radioValueRequiringCardCopy',
  ],
  async mounted(): Promise<void> {
    this.currentYear = processYearNumber();

    if (this.cardExpiredFromIsp) {
      const splittedCardExpireDate = this.splitCardExpireDate(this.cardExpiredFromIsp);
      this.cardExpiredMonthFromIsp = splittedCardExpireDate.cardExpireMonth;
      this.cardExpiredYearFromIsp = String(processYearNumber()).slice(0, 2) + splittedCardExpireDate.cardExpireYear;
    }

    if (this.maskedCardNumberFromIsp) {
      this.maskedCardNumberFromIspWithHyphen = this.addHyphenToCardNumber(this.maskedCardNumberFromIsp);
    }

    if (this.portasVeriTransId) {
      const member: Member = this.$store.getters['memberStore/member'];

      const paymentMethodOnPortas = await this.getPaymentMethodInfoList(member.id);
      if (!paymentMethodOnPortas) {
        this.isDeletedCreditCardOnPortas = true;
      } else {
        this.isDeletedCreditCardOnPortas = false;
        this.cardHolderNameOnPortas = paymentMethodOnPortas.cardholderName ?? '';
        this.$emit('portasCardHolderName', this.cardHolderNameOnPortas);
      }
    }
  },
  methods: {
    // /**カード番号バリデーション */
    validateCardNumberInput(): boolean {
      if (this.v$.form.number1?.maxLength.$invalid || this.v$.form.number1?.minLength.$invalid) {
        return false;
      }
      if (this.v$.form.number2?.maxLength.$invalid || this.v$.form.number2?.minLength.$invalid) {
        return false;
      }
      if (this.v$.form.number3?.maxLength.$invalid || this.v$.form.number3?.minLength.$invalid) {
        return false;
      }
      if (this.v$.form.number4?.maxLength.$invalid || this.v$.form.number4?.minLength.$invalid) {
        return false;
      }
      return true;
    },
    /** 「クレジットカード情報を送信」ボタン押下時 */
    async onPostCreditCard(): Promise<void> {
      // 回数制限に引っかかっていないか確認
      if (!VeritransRequestStatusService.canSendPostRequest()) {
        this.$emit(
          'onPostCreditCardError',
          [
            '入力上限回数に達しましたのでロックされました。しばらくしてから再度ご登録ください。',
            'なお、弊社ではセキュリティ上、ロックを解除することはできませんので、ご了承ください。',
          ].join('<br>')
        );
        return;
      }

      if (this.isOnPostCreditCardExecuting) {
        return;
      }

      // 3Dセキュアサービス未契約の場合、VeriTransのトークン作成APIはカード名義人が未指定でもエラーを返さない。
      // このため、カード名義人が空欄の場合はVeriTransへのカード情報送信を行わず、Portasフロント側でエラーを返して回数制限をカウントする。
      if (!this.form.cardholderName) {
        VeritransRequestStatusService.addFailureCount();
        this.$emit('onPostCreditCardError', 'カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
        return;
      }
      if (!this.validateCardNumberInput()) {
        VeritransRequestStatusService.addFailureCount();
        this.$emit('onPostCreditCardError', 'カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
        return;
      }
      if (this.form.cardholderName.length < 2 || this.form.cardholderName.length > 45) {
        VeritransRequestStatusService.addFailureCount();
        this.$emit('onPostCreditCardError', 'カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
        return;
      }

      this.isOnPostCreditCardExecuting = true;

      try {
        /** API をコールしてトークンを取得する
         * テスト環境ではセキュリティコードが必要なのでテスト用セキュリティコードを送信している
         */
        const tokenRequest = new VeritransCreditCardTokenRequest({
          card_number: `${this.form.number1}${this.form.number2}${this.form.number3}${this.form.number4}`,
          card_expire: `${this.form.expiryMonth}/${this.form.expiryYear}`,
          token_api_key: this.apiTokenKey,
          security_code: this.form.securityCode,
          cardholder_name: this.form.cardholderName,
          lang: 'ja',
        });

        const response = await VeritransApiService.fetchCreditCardToken(tokenRequest);
        const token = response.token;
        const token_expire_date = response.token_expire_date;
        const cardTokenFor3dSecureAuthorizeResponse = await VeritransApiService.fetchCreditCardToken(tokenRequest);
        const cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorizeResponse.token;
        // 入力エリアを非表示にする
        this.isInput = false;
        // 親コンポーネントにトークンを渡す
        this.$emit('cardTokenForRegister', token);
        // 親コンポーネントにトークン有効期限を渡す
        this.$emit('cardTokenExpireDateForRegister', token_expire_date);
        // 親コンポーネントに3Dセキュア本人認証用カードトークンを渡す
        this.$emit('cardTokenFor3dSecureAuthorize', cardTokenFor3dSecureAuthorize);
        // 親コンポーネントにクレジットカード番号の下4桁を渡す
        this.$emit('getCreditEndNumber', this.form.number4);
        // 失敗回数カウントを0回にする
        VeritransRequestStatusService.setFailureCount0();

        this.isCreatedToken = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // エラーレスポンスに応じた、表示するエラーメッセージの場合分け
        if (axios.isAxiosError(error)) {
          const status: number = error.response?.status ? error.response?.status : -1;
          const errorCode: string = error.response?.data.code;

          if (VeritransErrorService.isErrorDueToInputValue(errorCode)) {
            VeritransRequestStatusService.addFailureCount();
            this.$emit('onPostCreditCardError', 'カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。');
          } else if (VeritransErrorService.isErrorDueToConfig(errorCode)) {
            const errorCodeInNumericFormat = VeritransErrorService.getErrorCodeInNumericFormat(errorCode);

            this.$emit(
              'onPostCreditCardError',
              [
                '処理が正常に完了できませんでした。しばらく時間をおいてから再度お試しください。',
                '再度お試しいただいても正常に完了しない場合、大変お手数ですがサポートセンターまでエラー番号をお知らせください。',
                '<エラー番号: ' + errorCodeInNumericFormat + '>',
              ].join('</br>')
            );

            // 通信エラーのとき
          } else if (status >= 500) {
            this.$emit('onPostCreditCardError', 'クレジットカード情報の送信に失敗しました。');
          }
        } else {
          throw error;
        }
      } finally {
        this.isOnPostCreditCardExecuting = false;
      }
      this.isOnPostCreditCardExecuting = false;
    },
    /** Portasに登録されたカード情報を取得する
     * 「クレジットカード情報を表示する」ボタン押下時、取得したカード情報を表示する
     */
    async onRequiredDisplayCardInfo() {
      const member: Member = this.$store.getters['memberStore/member'];

      const paymentMethodOnPortas = await this.getPaymentMethodInfoList(member.id);

      if (paymentMethodOnPortas) {
        this.maskedCardNumberOnPortas = this.addHyphenToCardNumber(paymentMethodOnPortas.cardNumber);
        this.cardExpiredOnPortas = paymentMethodOnPortas.cardExpire;
        const splittedCardExpireDate = this.splitCardExpireDate(this.cardExpiredOnPortas);
        this.cardExpiredMonthOnPortas = splittedCardExpireDate.cardExpireMonth;
        this.cardExpiredYearOnPortas = String(processYearNumber()).slice(0, 2) + splittedCardExpireDate.cardExpireYear;

        if (paymentMethodOnPortas.isExpired) {
          this.isExpiredPortasCreditCard = true;
          this.$emit('isExpiredPortasCreditCard', true);
        }
      }

      this.isRequestedDisplayCardInfo = true;
    },
    /*
     * MM/YY 形式の文字列で渡されたカード有効期限を、月と年の情報に分割して返却する
     */
    splitCardExpireDate(cardExpire: string): SplittedCardExpireDate {
      const splittedCardExpireDateArray = cardExpire.split('/');
      const splittedCardExpireDate = {
        cardExpireMonth: splittedCardExpireDateArray[0],
        cardExpireYear: splittedCardExpireDateArray[1],
      };
      return splittedCardExpireDate;
    },
    /*
     * クレジットカード情報入力フォームの表示要否を判定する
     */
    isRequiredDisplayCreditCardInputForm(): boolean {
      if (this.portasVeriTransId) {
        // 「支払方法をコピーしない」ラジオボタンが選択されている、かつカード情報送信用トークンの生成が完了していない場合は表示
        if (this.isSelectedCopy === 'no' && !this.isCreatedToken) {
          return true;
          // Portasに登録された支払方法が削除済、かつカード情報送信用トークンの生成が完了していない場合は表示
        } else if (this.isDeletedCreditCardOnPortas && !this.isCreatedToken) {
          return true;
        } else if (this.maskedCardNumberFromIsp && !this.isCreatedToken) {
          return true;
        } else {
          return false;
        }
        // Portasにカードが無く、カード情報送信用トークンの生成が完了していない場合は表示
      } else if (!this.isCreatedToken) {
        return true;
      } else {
        return false;
      }
    },
    /*
     * クレジットカード情報の扱いについての説明ブロック表示要否を判定する
     */
    isRequiredDisplayCreditCardNotice(): boolean {
      if (this.ispPaymentMethodType === 'creditCard') {
        // ISPの支払方法がクレジットカードの場合、Portasの支払方法に寄らず、コピー不可（チェックボックス・注意事項は表示が必要）
        return true;
      } else if (this.portasVeriTransId) {
        if (this.isSelectedCopy === 'yes') {
          // Portasからカードコピーを実施する場合は3Dセキュアを実施しないため非表示
          return false;
        } else if (!this.isDeletedCreditCardOnPortas && this.isSelectedCopy === '') {
          // Portasに登録された支払方法が削除されていない、かつ、はい/いいえ未選択の場合
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    /*
     * 支払方法一覧取得 APIを実行し、画面表示用のプロパティを追加して実行結果を取得する
     */
    async getPaymentMethodInfoList(memberId: number): Promise<PaymentMethodInfoForDisplay | null> {
      const paymentMethodList = await SpfApiService.getPaymentMethodList(memberId);
      let paymentMethodInfo: PaymentMethodInfoForDisplay | null = null;

      for (const eachPaymentMethod of paymentMethodList) {
        const eachPaymentMethodForDisplay: PaymentMethodInfoForDisplay = {
          ...eachPaymentMethod,
          isExpired: isCreditCardExpired(eachPaymentMethod.cardExpire.slice(0, 2), String(processYearNumber()).slice(0, 2) + eachPaymentMethod.cardExpire.slice(3, 5)),
        };
        if (!eachPaymentMethod.deleteDate) {
          paymentMethodInfo = eachPaymentMethodForDisplay;
          return paymentMethodInfo;
        }
      }
      return paymentMethodInfo;
    },
    /*
     * マスク化済のカード番号を受け取り、4桁ごとにハイフンを付与して返却する
     */
    addHyphenToCardNumber(cardNumber: string): string {
      const cardNumberWithHyphen = `${cardNumber.slice(0, 4)}-${cardNumber.slice(4, 8)}-${cardNumber.slice(8, 12)}-${cardNumber.slice(12)}`;
      return cardNumberWithHyphen;
    },
    sendIsAgreeCardInfoCopy(event: Event) {
      const target = event.target as HTMLInputElement | null;
      this.$emit('change', target?.checked ?? false);
    },
    inputEventConvertToUpper(event: Event) {
      this.form.cardholderName = inputEventConvertToUpper(event);
    },
    inputCardHolderNameForAuthorizeIspCard(event: Event) {
      this.form.cardholderNameFormForAuthorizeIspCard = inputEventConvertToUpper(event);
      this.$emit('cardholderNameFormForAuthorizeIspCard', this.form.cardholderNameFormForAuthorizeIspCard);
    },
    inputCardHolderNameForAuthorizePortasCard(event: Event) {
      this.form.cardholderNameFormForAuthorizePortasCard = inputEventConvertToUpper(event);
      this.$emit('cardholderNameFormForAuthorizePortasCard', this.form.cardholderNameFormForAuthorizePortasCard);
    },
    /** ISPで登録済のクレジットカードコピーするかどうか「はい」「いいえ」ラジオボタン選択状態 */
    isRequiredCardCopy(event: Event) {
      const target = event.target as HTMLInputElement | null;
      this.$emit('radioValueRequiringCardCopy', target?.value ?? '');
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    computedForm(): any {
      return JSON.parse(JSON.stringify(this.form));
    },
  },
  watch: {
    computedForm: {
      handler: function (newVal, oldVal) {
        if (newVal.number1 && !newVal.number1.match(/^\d{1,4}$/)) {
          this.form.number1 = oldVal.number1;
        }
        if (newVal.number2 && !newVal.number2.match(/^\d{1,4}$/)) {
          this.form.number2 = oldVal.number2;
        }
        if (newVal.number3 && !newVal.number3.match(/^\d{1,4}$/)) {
          this.form.number3 = oldVal.number3;
        }
        if (newVal.number4 && !newVal.number4.match(/^\d{1,4}$/)) {
          this.form.number4 = oldVal.number4;
        }
        if (newVal.cardholderName && !newVal.cardholderName.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.form.cardholderName = oldVal.cardholderName;
        }
        if (newVal.cardholderNameFormForAuthorizeIspCard && !newVal.cardholderNameFormForAuthorizeIspCard.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.form.cardholderNameFormForAuthorizeIspCard = oldVal.cardholderNameFormForAuthorizeIspCard;
        }
        if (newVal.cardholderNameFormForAuthorizePortasCard && !newVal.cardholderNameFormForAuthorizePortasCard.match(/^[0-9a-zA-Z ,-./]*$/)) {
          this.form.cardholderNameFormForAuthorizePortasCard = oldVal.cardholderNameFormForAuthorizePortasCard;
        }
        // セキュリティコードは3桁・4桁の半角英数字に制限
        if (newVal.securityCode && !newVal.securityCode.match(/^\d{1,4}$/)) {
          this.form.securityCode = oldVal.securityCode;
        }
      },
      deep: true,
    },
  },
});
</script>
