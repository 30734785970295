import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionIfilterRegisterResponseDto } from '@/shared/classes/spf-api/e-mansion-ifilter-dto';
import { BaseApiService } from '@/shared/services/api/base-api-service';

const api = BaseApiService.api();

/** 内部 API をコールするサービス */
export class SpfApiIfilterAccessor {
  /**
   * e-mansion i-フィルター　サービス登録API
   * @return
   */
  public static async registerIfilter(primaryKeyMye: string, apartmentId: string, creditCardRegistFlag: string, quantity: string): Promise<EMansionIfilterRegisterResponseDto> {
    const response = await api
      .post<EMansionIfilterRegisterResponseDto>(`/ifilter/e-mansion/register`, {
        primaryKeyMye,
        apartmentId,
        creditCardRegistFlag,
        quantity,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return response.data;
  }
}
