import { Module } from 'vuex';

import { RootState } from '../index';

import { EMansionIPPhoneRegisterResponse } from '@/shared/classes/spf-api/e-mansion/e-mansion-ip-phone';

/** e-mansion IPフォンサービス申込 State */
const eMansionIPPhoneState: {
  // 遷移元画面
  from: string | undefined;
  // 入力情報
  inputApplyForm: EMansionIPPhoneInputApplyForm | undefined;
  // 確認画面での入力情報（契約内容の交付方法）
  inputDestinationForm: EMansionIPPhoneInputDestinationForm | undefined;
  // API実行結果（完了画面に表示する必要がある）
  registerResult: EMansionIPPhoneRegisterResponse | undefined;
} = {
  from: undefined,
  inputApplyForm: undefined,
  inputDestinationForm: undefined,
  registerResult: undefined,
};

/** State の型 */
type EMansionIPPhoneState = typeof eMansionIPPhoneState;

/** e-mansion IPフォン入力情報 Store */
export const eMansionIPPhoneStore: Module<EMansionIPPhoneState, RootState> = {
  namespaced: true,
  state: eMansionIPPhoneState,
  actions: {},
  mutations: {
    from: (state, value: string) => (state.from = value),
    inputApplyForm: (state, value: EMansionIPPhoneInputApplyForm) => (state.inputApplyForm = value),
    inputDestinationForm: (state, value: EMansionIPPhoneInputDestinationForm) => (state.inputDestinationForm = value),
    registerResult: (state, value: EMansionIPPhoneRegisterResponse) => (state.registerResult = value),
  },
  getters: {
    from: (state): string | undefined => state.from,
    inputApplyForm: (state): EMansionIPPhoneInputApplyForm | undefined => state.inputApplyForm,
    inputDestinationForm: (state): EMansionIPPhoneInputDestinationForm | undefined => state.inputDestinationForm,
    registerResult: (state): EMansionIPPhoneRegisterResponse | undefined => state.registerResult,
  },
};

/**
 * 入力画面で設定する情報（入力画面<>確認画面間でのデータ保持）
 * 配達希望日付、時間帯
 */
export class EMansionIPPhoneInputApplyForm {
  public deliveryDay!: string;
  public deliveryTimeZone!: { name: string; value: string; key: string };

  constructor(partial: Partial<EMansionIPPhoneInputApplyForm>) {
    Object.assign(this, partial);
  }
}

/**
 * 確認画面で設定する情報（入力画面<>確認画面>完了画面間でのデータ保持）
 * 契約内容の交付方法（共通テンプレート）
 */
export class EMansionIPPhoneInputDestinationForm {
  public contractDestinationKind!: string;
  public SettingEMailAddress?: string | undefined;
  public contractDestinationMailAddress?: string | undefined;
  public contractDestinationMailAddressConfirm?: string | undefined;
  public contractDestinationZipCode?: string | undefined;
  public contractDestinationZipCodeFormer?: string | undefined;
  public contractDestinationZipCodeLatter?: string | undefined;
  public contractDestinationAddress1?: string | undefined;
  public contractDestinationAddress2?: string | undefined;

  constructor(partial: Partial<EMansionIPPhoneInputDestinationForm>) {
    Object.assign(this, partial);
  }
}
