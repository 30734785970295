<template>
  <div class="payment-method-edit">
    <LoadingComponent v-if="isLoading || isSubmitting" />
    <main class="underlayer-main">
      <h1>Portasサービス お支払い方法</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li><router-link to="/platform/my-page">マイページ</router-link></li>
        <li><router-link to="/platform/my-page/payment-method/list">Portasサービス お支払い方法</router-link></li>
        <li>お支払い方法の変更</li>
      </ul>

      <ul class="application-flow grid pc-grid2 sp-grid2 gap10">
        <li class="stay">お支払い方法の変更</li>
        <li>登録完了</li>
      </ul>

      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="お支払い方法" />お支払い方法の変更</h2>
        <!-- エラーメッセージはエラーメッセージコンポーネント側で表示する -->
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <div class="sblc">
          <credit-card-component
            v-bind:apiTokenKey="apiTokenKey"
            v-bind:successMessage="successMessage"
            v-on:cardTokenForRegister="getCardTokenForEdit"
            v-on:cardTokenExpireDateForRegister="getCardTokenExpireDateForEdit"
            v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
            v-on:onPostCreditCardError="executeCreditCardError"
          />
        </div>
      </div>

      <div class="blc">
        <p class="form-btn-txt">
          ご確認の上、よろしければ「お支払い方法を保存」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」ボタンをクリックしてください。
        </p>
        <p class="red form-btn-txt">
          「お支払い方法を保存」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
        </p>
        <div class="btn-area">
          <input id="isAgreedPrivacyPolicy" v-model="isAgreedPrivacyPolicy" type="checkbox" name="isAgreedPrivacyPolicy" />
          <label for="isAgreedPrivacyPolicy"> クレジットカード番号および本人認証に必要な情報の提供に同意します。</label>
        </div>
        <div class="btn-area">
          <button class="btn-height btn btn05 bs" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn-height btn btn01 bs" type="button" :disabled="isDisabledButton" v-on:click="onApply()">
            お支払い方法を保存<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.underlayer-main {
  background-image: url('../../../../images/main.png');
}

.form-btn-txt {
  text-align: center;
}

.terms-box {
  height: 240px;
  overflow: auto;
}

.error-input {
  background-color: #ed7d89;
}

.btn-height {
  height: 50px;
}

.btn.btn01.bs {
  &:disabled {
    opacity: 0.5;
  }
}

div.btn-area {
  & button:last-child {
    margin-top: 16px;
  }
}
</style>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-can-copy-from-isp.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'payment-methods-edit',
  components: {
    ErrorMessagesComponent,
    CreditCardComponent,
    LoadingComponent,
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** クレジットカード編集用カードトークン */
    cardTokenForEdit: '',
    /** クレジットカード編集用カードトークン有効期限 */
    cardTokenExpireDateForEdit: '',
    /** 3Dセキュア本人認証用クレジットカードトークン */
    cardTokenFor3dSecureAuthorize: '',
    /** 個人情報の取り扱いについて同意しているか */
    isAgreedPrivacyPolicy: false,
    /** APIトークンキー */
    apiTokenKey: '',
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お支払い方法を保存」ボタンをクリックしてください。',
    /** ボタン押下判定 */
    isSubmitting: false,
    isLoading: true, // 読み込み状態,最初はロード中
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/platform').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // APIトークンキーを設定
    this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_PORTAS;
    this.isLoading = false;
  },
  methods: {
    /** 「お支払い方法を保存 ➡」ボタン押下時 */
    async onApply() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;

      // エラーメッセージストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);

      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      if (!(this.cardTokenForEdit && this.cardTokenFor3dSecureAuthorize)) {
        // クレジットカードトークンが発行されていない場合はエラー
        this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
        this.isSubmitting = false;
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      }

      const editRequestedPaymentMethodNo = this.$store.getters['paymentMethodStore/editRequestedPaymentMethodNo'];

      const uuidForTemporarySavingApplicationData = uuidv4();

      /**  申込内容一時保存API へ渡す申込データ */
      const paymentMethodEditApplicationData = {
        creditCardAccessToken: this.cardTokenForEdit,
        creditTokenExpireDate: this.cardTokenExpireDateForEdit,
        editRequestedPaymentMethodNo: editRequestedPaymentMethodNo,
      };

      const paymentMethodEditApplicationDataJson = JSON.stringify(paymentMethodEditApplicationData);

      const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
        uuid: uuidForTemporarySavingApplicationData,
        applicationDataJson: paymentMethodEditApplicationDataJson,
        subsequentProcess: 'payment_methods_edit',
      });

      try {
        await this.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
      }

      localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);

      const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
        uuid: uuidForTemporarySavingApplicationData,
        creditCardToken: this.cardTokenFor3dSecureAuthorize,
      });

      // VeriTrans_本人認証(トークン使用)API の実行
      try {
        const authStartURL = await this.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
        location.href = authStartURL;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
          // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
          this.errorMessages.push(
            `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="https://support.ucom.ne.jp/contact/portas/" target="_blank">こちら</a>(Portas問合せフォーム）からお問い合わせをお願いいたします。`
          );

          this.$store.commit('errorMessageStore/errorMessages', this.errorMessages);
          this.$store.commit('errorMessageStore/errorMessageTitle', this.errorMessageTitle);
          await this.$router.push('/platform/my-page/payment-method/list').catch((error: any) => {
            checkRouterError(error);
          });
        } else {
          throw error;
        }

        this.isSubmitting = false;

        // 作成されたトークン情報を削除して「お支払い方法を保存」ボタンを非活性化する
        this.cardTokenForEdit = '';
        this.cardTokenFor3dSecureAuthorize = '';

        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      }
    },
    async onBack(): Promise<void> {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      await this.$router
        .push('/platform/my-page/payment-method/list')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    /** クレジットカード編集用クレジットカードトークンを受け取る */
    getCardTokenForEdit(cardTokenForEdit: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.cardTokenForEdit = cardTokenForEdit;
    },
    /** クレジットカード編集用クレジットカードトークン有効期限を受け取る */
    getCardTokenExpireDateForEdit(cardTokenExpireDateForEdit: string) {
      this.cardTokenExpireDateForEdit = cardTokenExpireDateForEdit;
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /** 申込内容一時保存API の実行 */
    async temporarySavingApplicationData(applicationData: TemporarySavingApplicationDataRequest) {
      return await SpfApiService.temporarySavingApplicationData(applicationData);
    },
    /** VeriTransの３Dセキュア本人認証（トークン使用） API の実行 */
    async create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest: Create3dSecureAuthStartInfoWithCardTokenRequest): Promise<string> {
      return await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
    },
  },
  computed: {
    isDisabledButton(): boolean {
      return !(this.isAgreedPrivacyPolicy && this.cardTokenForEdit && this.cardTokenFor3dSecureAuthorize);
    },
  },
});
</script>
