<template>
  <LoadingComponent v-if="isLoading" />
  <div class="completed">
    <main class="underlayer-main">
      <h1>e-mansion 接続コース変更</h1>
    </main>

    <div class="contents">
      <ul class="breadcrumb">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link to="/e-mansion">e-mansion 会員専用トップ</router-link></li>
        <li class="stay">接続コース変更</li>
      </ul>

      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>接続コース変更</li>
        <li class="stay">確認</li>
        <li>完了</li>
      </ul>

      <div class="blc">
        <error-messages-component v-bind:errorMessages="errorMessages" v-bind:errorMessageTitle="errorMessageTitle" />

        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="接続コース変更 確認" />接続コース変更　お申し込み確認</h2>

        <div class="sblc">
          <h3 class="service-h3">お申し込み内容</h3>
        </div>

        <div class="sblc">
          <table class="table-type2 billing-table">
            <tr>
              <td class="tableGray otherCourseService"><b>速度（タイプ）</b></td>
              <td class="commonPaddingTd">{{ storeSelectedSpeed }} ({{ speedName }})</td>
            </tr>
            <tr v-if="!checkMyCurrentCourseId">
              <td class="tableGray otherCourseService"><b>接続機器のご利用形態</b></td>
              <td class="commonPaddingTd">レンタル</td>
            </tr>
          </table>
        </div>

        <div class="sblc">
          <p>
            ※
            本サービスはベストエフォート式のサービスのため、実使用速度を保証するものではありません。速度は技術規格上での最大値であり、通信速度は時間帯・状況等により異なる場合があります。
          </p>
          <p>
            ※
            住戸内に設置されている機器（スイッチングハブなど）または接続する端末、ケーブル等により技術規格上の最大通信速度が変わるため、上記の通信速度（ベストエフォート）の規格にてご利用になりたい場合、機器、端末、ケーブル等をお客様にて変更いただくケースがございます。
          </p>
          <p>※ 住戸内設置の機器（建物所有者様、区分所有者様の所有物）の仕様については建物によって異なりますので居住者様にてご確認ください。</p>
        </div>

        <div v-if="isFreeServiceCost == false">
          <div class="sblc">
            <h3 class="service-h3">お支払い方法</h3>
          </div>

          <div v-if="!noDisplayCreditCardForm">
            <credit-card-component
              v-if="isMounted"
              :key="resetCounter"
              :reset-counter="resetCounter"
              v-bind:apiTokenKey="apiTokenKey"
              v-bind:successMessage="successMessage"
              v-bind:ispName="ispName"
              v-bind:maskedCardNumberFromIsp="maskedCardNumberFromIsp"
              v-bind:cardExpiredFromIsp="cardExpiredFromIsp"
              v-bind:portasVeriTransId="portasVeriTransId"
              v-bind:cardHolderNameFromIsp="cardHolderNameFromIsp"
              v-on:onPostCreditCard="getCreditCardToken"
              v-on:onPostCreditCardError="executeCreditCardError"
              v-on:getTokenExpireDate="getTokenExpireDate"
              v-on:isExpiredPortasCreditCard="checkExpiredPortasCreditCard"
              v-on:change="isAgreedCopyCardToIsp = $event"
              v-on:cardTokenFor3dSecureAuthorize="getCardTokenFor3dSecureAuthorize"
              v-on:portasCardHolderName="getCardHolderNameFromPortas"
              v-on:cardholderNameFormForAuthorizeIspCard="getCardholderNameFormForAuthorizeIspCard"
              v-on:cardholderNameFormForAuthorizePortasCard="getCardholderNameFormForAuthorizePortasCard"
            />
          </div>
          <div v-else>
            <div>ご登録のお支払い方法により、お支払いいただきます。</div>
          </div>
        </div>

        <div class="sblc">
          <h3 class="service-h3">ご契約内容のご案内について</h3>
        </div>
      </div>
      <div class="text-margin"></div>
      <table class="table-type2 billing-table">
        <tbody>
          <tr>
            <td v-if="campaignCoursechgCourse" colspan="2" class="switch_apartment_indicator" v-text="campaignCoursechgCourse"></td>
          </tr>
          <tr>
            <td v-if="campaignCoursechgOther" colspan="2" class="switch_apartment_indicator" v-text="campaignCoursechgOther"></td>
          </tr>
          <tr>
            <td class="tableGray otherCourseService"><b>月額基本料</b></td>
            <td v-if="paymentIssueMsg" class="commonPaddingTd">{{ paymentIssueMsg }}</td>
            <td v-else-if="storeSelectedCharge == '0'">{{ '管理費とあわせてお支払いいただきます。なお、賃貸居住の方はオーナー様よりお支払いいただいております。' }}</td>
            <td v-else-if="storeSelectedCharge" class="commonPaddingTd">
              {{ formatCurrency(storeSelectedCharge) }} 円
              <span v-if="storeSelectedChargeCp">
                （キャンペーン適用時： <b class="red">{{ formatCurrency(storeSelectedChargeCp) }} 円</b>）
              </span>
            </td>
          </tr>
          <tr v-if="isUpgFlag && upgFee">
            <td class="tableGray otherCourseService"><b>コース変更手数料（初回のみ）</b></td>
            <td class="commonPaddingTd">
              {{ formatCurrency(upgFee) }} 円
              <span v-if="upgFeeCp">
                （キャンペーン適用時： <b class="red">{{ formatCurrency(upgFeeCp) }} 円</b>）
              </span>
            </td>
          </tr>
          <tr v-if="!isUpgFlag && downgFee">
            <td class="tableGray otherCourseService"><b>コース変更手数料（初回のみ）</b></td>
            <td class="commonPaddingTd">
              {{ formatCurrency(downgFee) }} 円
              <span v-if="downgFeeCp">
                （キャンペーン適用時： <b class="red">{{ formatCurrency(downgFeeCp) }} 円</b>）
              </span>
            </td>
          </tr>
          <tr v-if="constructionFee">
            <td class="tableGray otherCourseService"><b>工事費（初回のみ）</b></td>
            <td class="commonPaddingTd">
              {{ formatCurrency(constructionFee) }} 円<span v-if="constructionFeeCp"
                >（キャンペーン適用時： <b class="red">{{ formatCurrency(constructionFeeCp) }} 円</b>）</span
              >
            </td>
          </tr>
          <tr v-if="isModem && (storeCharge || adapterChargeCampaign) && speedName == 'VDSL' && !checkMyCurrentCourseId">
            <td class="tableGray otherCourseService"><b>モデムレンタル料（月額）</b></td>
            <td class="commonPaddingTd">
              <span v-if="adapterChargeCampaign"
                ><b class="red">{{ formatCurrency(storeCharge) }} 円</b></span
              >
              <span v-else>{{ formatCurrency(storeCharge) }} 円</span>
            </td>
          </tr>
          <tr v-else-if="!isModem && (storeCharge || adapterChargeCampaign) && speedName == 'FTTH' && !checkMyCurrentCourseId">
            <td class="tableGray otherCourseService"><b>メディアコンバーターレンタル料（月額）</b></td>
            <td class="commonPaddingTd">
              <span v-if="adapterChargeCampaign"
                ><b class="red">{{ formatCurrency(storeCharge) }} 円</b></span
              >
              <span v-else>{{ formatCurrency(storeCharge) }} 円</span>
            </td>
          </tr>
          <tr v-if="!isFreeServiceCost">
            <td class="tableGray otherCourseService"><b>総額</b></td>
            <td class="commonPaddingTd">
              初回： {{ campaignFee }} 円 / 次回以降： {{ RegularFee }} 円<br />
              {{ isContainCpFee }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!isFreeServiceCost">
        <p>価格は全て税込表示（消費税10%）です。今後消費税が改訂された場合は、改定後の税率による価格に変更となります。</p>
      </div>
      <div class="blc">
        <e-mansion-entry-send-documents-component
          @error-messages="getErrorMessage"
          @input-data="getInputDeliveryData"
          :isDisplayHeadline="false"
          :isEntryEmansion="false"
          :inputDeliveryData="inputDeliveryData"
        >
        </e-mansion-entry-send-documents-component>
      </div>
      <div class="blc" v-if="isNeedDisableTrackingBlockFunction && !isFreeServiceCost">
        <p class="border-grey bg-grey pd20 mt40 ml40">
          ※クレジットカード会社での本人認証のため、お客様の接続元IPアドレス・Portasにご登録いただいているメールアドレスを株式会社DGフィナンシャルテクノロジーおよびクレジットカード会社へ提供いたします。<br />
          ※ご利用のブラウザのCookieを許可していただきトラッキング防止が有効になっている場合には、無効に変更をお願いいたします。設定方法については、各ブラウザの製造元や提供元へお問い合わせ下さい。
        </p>
        <p class="form-btn-txt mt40">
          ご確認の上、よろしければ「お申し込みを確定する」ボタンをクリックしてください。完了ページに進みます。修正がある場合は、「戻る」ボタンをクリックしてください。
        </p>
        <p class="red form-btn-txt">
          「お申し込みを確定する」ボタンクリック後、完了までに数秒かかる場合があります。自動で完了ページに表示が切り替わりますので、操作を行わずにそのままお待ちください。
        </p>
      </div>
      <div class="btn-area">
        <div class="has-checkbox">
          <div class="my-checkbox-outer" v-if="isNeedDisableTrackingBlockFunction && !isFreeServiceCost">
            <input id="checkbox-to-agree" type="checkbox" v-model="isAgreedPrivacyPolicy" />
            <label for="checkbox-to-agree">クレジットカード会社での本人認証のための情報提供に同意してお申し込みを確定する</label>
          </div>
          <button class="btn-height btn btn05 bs sp-margin" @click="onBack()"><i class="material-icons link link-icon">west</i>戻る</button>
          <button class="btn-height btn btn01 bs sp-margin" type="button" :disabled="isDisabledButton" v-on:click="onNext()">
            お申し込みを確定する<i class="material-icons link link-icon">east</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid';
import { defineComponent } from 'vue';

import { VueErrorHandler } from '@/handler/error/vue-error-handler';
import { SpfApiCourseAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-course-accessor';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Create3dSecureAuthStartInfoWithCardTokenRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-card-token-request';
import { Create3dSecureAuthStartInfoWithVeritransAccountIdRequest } from '@/shared/classes/platform/create-3d-secure-auth-start-info-with-veritrans-account-id';
import { PaymentMethodDetailResponse } from '@/shared/classes/platform/payment-method-detail-response';
import { TemporarySavingApplicationDataRequest } from '@/shared/classes/platform/temporary-saving-application-data-request';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import EMansionEntrySendDocumentsComponent from '@/shared/components/e-mansion/e-mansion-entry-send-documents-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component-for-apply-connectix.vue';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { SERVICE_PROVIDER } from '@/shared/const/service-provider';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { MountedCheckService } from '@/shared/services/mounted-check-service';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default defineComponent({
  name: 'platform-terms-i-filter',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
    CreditCardComponent,
    EMansionEntrySendDocumentsComponent,
  },
  data(): {
    isNotifyEmail: boolean;
    // e-mansion サイト URL
    eMansionUrl: string;
    // 8桁APIDE
    apid: string;
    /** エラーメッセージを格納する配列 */
    errorMessages: string[];
    errorMessageTitle: string;
    eMansionMyUrl: string;
    eMansionCourseUrl: string;
    rd: string;
    // クレカ情報での追加事項
    isMounted: boolean;
    /** APIトークンキー */
    apiTokenKey: string;
    successMessage: string;
    /** クレジットカード編集用カードトークン */
    cardTokenForEdit: string;
    /** クレジットカード編集用カードトークン有効期限 */
    cardTokenExpireDateForEdit: string;
    /** 支払い方法の登録状態によってクレジットカードコンポーネントの表示/非表示を切り替える */
    noDisplayCreditCardForm: boolean;
    isCourseFree: boolean;
    portasVeriTransId: string;
    /** e-mansion に登録済のVeriTransAccountId */
    emansionVeriTransAccountId: string;
    /** ISPから取得したカード名義人 */
    cardHolderNameFromIsp: string | undefined;
    /** e-mansionから取得したクレジットカードのマスク済カード番号 */
    maskedCardNumberFromIsp: string;
    /** e-mansionから取得したクレジットカードの有効期限 */
    cardExpiredFromIsp: string;
    /** クレジットカードアクセストークン */
    creditCardAccessToken: string;
    /** ユーザーがe-mansionへカード情報をコピーすることに同意しているかどうか */
    isAgreedCopyCardToIsp: boolean;
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
    isExpiredPortasCreditCard: boolean;
    creditTokenExpireDate: string;
    cardHolderNameOnPortas: string;
    cardTokenFor3dSecureAuthorize: string;
    cardholderNameFormForAuthorizeIspCard: string;
    cardholderNameFormForAuthorizePortasCard: string;
    /** リセット回数 */
    resetCounter: number;
    ispName: string;
    upgFee: string;
    downgFee: string;
    chgConstructionCp: string;
    storeSelectedSpeed: string;
    storeSelectedCharge: string;
    storeSelectedChargeCp: string;
    storeEquipmentcontract: string;
    storeCharge: string;
    constructionFee: string;
    constructionFeeCp: string;
    campaignCoursechgCourse: string;
    campaignCoursechgOther: string;
    //契約基本情報のグレード
    currentGrade: string;
    //申し込むサービスのグレード
    storeGrade: string;
    // アップグレード料金を表示させるかどうか
    isUpgFlag: boolean;
    upgFeeCp: string;
    downgFeeCp: string;
    RegularFee: string;
    // 支払情報の中にキャンペーン価格を含んでいるかどうかのフラグ
    isContainCpFee: string;
    storetype: string;
    adapterChargeCampaign: string;
    isModem: boolean;
    /** 本人認証処理を実行するため、ブラウザのトラッキング防止機能を無効化する必要があるかどうか */
    isNeedDisableTrackingBlockFunction: boolean;
    isPaymantCreditCard: boolean;
    isLoading: boolean;
    isFreePlan: boolean;
    /** ISP(e-mansion)会員ステータス */
    ispMemberStatusEMansion: string;
    eMansionMemberId: string;
    inputDeliveryData: any;
    courseId: string;
    contractDestinationKind: string;
    contractDestinationMailaddress: string;
    contractDestinationZipcode: string;
    contractDestinationAddress1: string;
    contractDestinationAddress2: string;
    selectedRadio: string;
    inputMailAddress: string;
    inputMailAddressConfirm: string;
    checkMyCurrentCourseId: boolean;
    /** 利用規約への同意 */
    isAgreedPrivacyPolicy: boolean;
    isSubmitting: boolean;
    campaignFee: string;
    speedName: string;
    adapterCharge: string;
    paymentIssueMsg: string;
    upgFeeToUse: number;
    downgFeeToUse: number;
    rentalFee: number;
    isFreeServiceCost: boolean;
  } {
    return {
      isNotifyEmail: false,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      apid: '',
      errorMessages: [] as string[],
      /** エラーメッセージ部に表示するタイトル */
      errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
      // e-manson URL 部分
      eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
      eMansionCourseUrl: '',
      rd: '',
      // クレカでの追加事項
      isMounted: false,
      /** APIトークンキー */
      apiTokenKey: '',
      /** トークン取得成功後に表示するメッセージ */
      successMessage: '画面下の「お支払い方法を保存」ボタンをクリックしてください',
      /** クレジットカード編集用カードトークン */
      cardTokenForEdit: '',
      /** クレジットカード編集用カードトークン有効期限 */
      cardTokenExpireDateForEdit: '',
      /** 支払い方法の登録状態によってクレジットカードコンポーネントの表示/非表示を切り替える */
      noDisplayCreditCardForm: false,
      isCourseFree: false,
      portasVeriTransId: '',
      /** e-mansion に登録済のVeriTransAccountId */
      emansionVeriTransAccountId: '',
      /** ISPから取得したカード名義人 */
      cardHolderNameFromIsp: '' as string | undefined,
      /** e-mansionから取得したクレジットカードのマスク済カード番号 */
      maskedCardNumberFromIsp: '',
      /** e-mansionから取得したクレジットカードの有効期限 */
      cardExpiredFromIsp: '',
      /** クレジットカードアクセストークン */
      creditCardAccessToken: '',
      /** ユーザーがe-mansionへカード情報をコピーすることに同意しているかどうか */
      isAgreedCopyCardToIsp: false,
      /** Portasに登録されているクレジットカードの有効期限が切れているかどうか */
      isExpiredPortasCreditCard: false,
      creditTokenExpireDate: '',
      cardHolderNameOnPortas: '',
      cardTokenFor3dSecureAuthorize: '',
      cardholderNameFormForAuthorizeIspCard: '',
      cardholderNameFormForAuthorizePortasCard: '',
      /** リセット回数 */
      resetCounter: 0,
      ispName: 'e-mansion',
      upgFee: '',
      downgFee: '',
      chgConstructionCp: '',
      storeSelectedSpeed: '',
      storeSelectedCharge: '',
      storeSelectedChargeCp: '',
      storeEquipmentcontract: '',
      storeCharge: '',
      constructionFee: '',
      constructionFeeCp: '',
      campaignCoursechgCourse: '',
      campaignCoursechgOther: '',
      currentGrade: '',
      storeGrade: '',
      isUpgFlag: false,
      upgFeeCp: '',
      downgFeeCp: '',
      RegularFee: '',
      isContainCpFee: '',
      storetype: '',
      adapterChargeCampaign: '',
      isModem: false,
      isNeedDisableTrackingBlockFunction: false,
      isPaymantCreditCard: false,
      isLoading: true,
      isFreePlan: false,
      ispMemberStatusEMansion: '',
      eMansionMemberId: '',
      inputDeliveryData: '',
      courseId: '',
      contractDestinationKind: '',
      contractDestinationMailaddress: '',
      contractDestinationZipcode: '',
      contractDestinationAddress1: '',
      contractDestinationAddress2: '',
      selectedRadio: '',
      inputMailAddress: '',
      inputMailAddressConfirm: '',
      checkMyCurrentCourseId: false,
      isAgreedPrivacyPolicy: false,
      isSubmitting: false,
      campaignFee: '',
      speedName: '',
      adapterCharge: '',
      paymentIssueMsg: '',
      upgFeeToUse: 0,
      downgFeeToUse: 0,
      rentalFee: 0,
      isFreeServiceCost: false,
    };
  },

  async mounted(): Promise<void> {
    this.isLoading = true;
    /** ログインしているか否かの情報を取得 */
    if (!(await MountedCheckService.canReadEMansionScreen())) {
      await this.$router.push('/').catch((error) => {
        checkRouterError(error);
      });
      return;
    }

    const member: Member = await this.$store.dispatch('memberStore/member');
    this.eMansionMemberId = member.primaryKeyMye ?? '';

    // APIトークンキーを設定
    this.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION;
    const eMansionProperty: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];

    //ベリトランスAPIの初期表示の内容
    //契約基本情報取得API.支払い方法種別 の値が「0:口座振替, 1:振込, 4:口振(請求書), 8:クレジットカード」いずれかのチェック
    const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];

    if (customer instanceof EMansionCustomer && customer.payment_id === '8' && member.eTncMemberId) {
      const cardInfo = await this.getPaymentMethodFromExternalIsp(member.eTncMemberId);
      this.cardExpiredFromIsp = cardInfo.cardExpire;
      this.maskedCardNumberFromIsp = cardInfo.cardNumber;
      this.cardHolderNameFromIsp = cardInfo.cardholderName;
      this.emansionVeriTransAccountId = member.eTncMemberId;
    } else if (member.veritransAccountId) {
      this.portasVeriTransId = member.veritransAccountId;
    }

    // VeriTrans 本人認証失敗時のエラーメッセージ
    if (this.$store.getters['errorMessageStore/errorMessages'] != null) {
      this.errorMessages = this.$store.getters['errorMessageStore/errorMessages'];
      this.errorMessageTitle = this.$store.getters['errorMessageStore/errorMessageTitle'];
      //ストアをリセット
      this.$store.commit('errorMessageStore/errorMessages', null);
      this.$store.commit('errorMessageStore/errorMessageTitle', null);
      this.creditCardAccessToken = '';
      this.creditTokenExpireDate = '';
    }

    this.isMounted = true;

    if (eMansionProperty.em_chg_construction) {
      this.constructionFee = eMansionProperty.em_chg_construction;
      if (eMansionProperty.em_chg_construction_cp) {
        this.constructionFeeCp = eMansionProperty.em_chg_construction_cp;
      }
    } else {
      this.constructionFee = '';
    }

    if (eMansionProperty.cp_info_message && eMansionProperty.cp_info_message[0] && eMansionProperty.cp_info_message[0].campaign_coursechg_course) {
      this.campaignCoursechgCourse = this.formatNewLines(eMansionProperty.cp_info_message[0].campaign_coursechg_course);
    }

    if (eMansionProperty.cp_info_message && eMansionProperty.cp_info_message[0] && eMansionProperty.cp_info_message[0].campaign_coursechg_other) {
      this.campaignCoursechgOther = this.formatNewLines(eMansionProperty.cp_info_message[0].campaign_coursechg_other);
    }

    this.storeEquipmentcontract = await this.$store.getters['selectedCourseStore/equipmentcontract'];
    this.storeSelectedSpeed = await this.$store.getters['selectedCourseStore/speed'];
    this.storeSelectedCharge = await this.$store.getters['selectedCourseStore/charge'];
    this.storeSelectedChargeCp = await this.$store.getters['selectedCourseStore/chargeCp'];
    this.storetype = await this.$store.getters['selectedCourseStore/type'];
    this.adapterCharge = await this.$store.getters['selectedCourseStore/adapterCharge'];
    this.adapterChargeCampaign = await this.$store.getters['selectedCourseStore/adapterChargeCampaign'];
    this.checkMyCurrentCourseId = await this.$store.getters['selectedCourseStore/checkMyCurrentCourseId'];
    this.paymentIssueMsg = await this.$store.getters['selectedCourseStore/paymentIssueMsg'];
    const contractDestinationKind = await this.$store.getters['selectedCourseStore/contractDestinationKind'];

    if (contractDestinationKind == '4') {
      this.inputDeliveryData.selectedRadio = '2';
      this.inputDeliveryData.inputMailAddress = await this.$store.getters['selectedCourseStore/contractDestinationMailaddress'];
      this.inputDeliveryData.inputMailAddressConfirm = await this.$store.getters['selectedCourseStore/contractDestinationMailaddressConfirm'];
    } else if (contractDestinationKind == '1') {
      this.inputDeliveryData.selectedRadio = '3';
      this.inputDeliveryData.deliveryZipcodeFormer = await this.$store.getters['selectedCourseStore/zipcodeFormer'];
      this.inputDeliveryData.deliveryZipcodeLatter = await this.$store.getters['selectedCourseStore/zipcodeLatter'];

      this.inputDeliveryData.destinationAddress1 = await this.$store.getters['selectedCourseStore/contractDestinationAddress1'];
      const destinationAddress2 = await this.$store.getters['selectedCourseStore/contractDestinationAddress2'];

      if (destinationAddress2) {
        this.inputDeliveryData.destinationAddress2 = destinationAddress2;
      }
    }

    if (this.adapterChargeCampaign) {
      this.storeCharge = this.adapterChargeCampaign;
    } else if (this.adapterCharge) {
      this.storeCharge = this.adapterCharge;
    }

    if (this.storetype == '01' || this.storetype == '02' || this.storetype == '03' || this.storetype == '04') {
      this.speedName = 'VDSL';
    } else if (this.storetype == '05') {
      this.speedName = 'LAN';
    } else if (this.storetype == '06') {
      this.speedName = 'FTTH';
    }
    // apid追加
    this.apid = eMansionProperty.IN_APID;
    this.eMansionCourseUrl = ` ${this.eMansionMyUrl}/instructions/downloadPdf.php?function=instructions&apid=${this.apid}&kind=03`;
    //工事費、キャンペーン価格適応の内容

    if (customer instanceof EMansionCustomer) {
      try {
        this.currentGrade = customer.cs.grade;
        this.storeGrade = await this.$store.getters['selectedCourseStore/storeGrade'];

        // グレードのだし分け部分
        if (this.currentGrade < this.storeGrade) {
          this.isUpgFlag = true;
        }

        //アップグレード料金だし分け
        if (eMansionProperty.em_upg_flag == '1' && this.isUpgFlag) {
          if (eMansionProperty.em_upg_fee_cp) {
            this.upgFeeCp = eMansionProperty.em_upg_fee_cp;
          }
          if (eMansionProperty.em_upg_fee) {
            this.upgFee = eMansionProperty.em_upg_fee;
          }
        }

        if (eMansionProperty.em_downg_flag == '1' && !this.isUpgFlag) {
          //ダウングレード料金だし分け
          if (eMansionProperty.em_downg_fee_cp) {
            this.downgFeeCp = eMansionProperty.em_downg_fee_cp;
          }
          if (eMansionProperty.em_downg_fee) {
            this.downgFee = eMansionProperty.em_downg_fee;
          }
        }

        if (this.storetype === '01' || this.storetype === '02' || this.storetype === '03' || this.storetype === '04') {
          this.isModem = true;
        }

        if (this.storeSelectedChargeCp || this.upgFeeCp || this.downgFeeCp || this.constructionFeeCp) {
          this.isContainCpFee = '※「次回以降」の料金は、キャンペーンが適用されない通常時における総額を表示しています。';
        }
      } catch {
        await this.$router.push('/e-mansion/error').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
    } else {
      // 外部APIエラー
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
    }

    this.calculationRegularFee();
    this.calculationCampaignFee();

    const paymentIdsWithoutCreditCard = ['0', '1', '4'];
    const paymentIdsWithCreditCard = ['8'];

    if (this.RegularFee == '0' && this.campaignFee == '0') {
      this.isFreeServiceCost = true;
      this.isNeedDisableTrackingBlockFunction = true;
    } else if (paymentIdsWithoutCreditCard.some((paymentIdsWithoutCreditCard) => paymentIdsWithoutCreditCard === customer.payment_id)) {
      this.noDisplayCreditCardForm = true;
      this.isNeedDisableTrackingBlockFunction = false;
    } else if (paymentIdsWithCreditCard.some((paymentIdsWithCreditCard) => paymentIdsWithCreditCard === customer.payment_id)) {
      this.isPaymantCreditCard = true;
      this.isNeedDisableTrackingBlockFunction = true;
    } else {
      this.noDisplayCreditCardForm = false;
      this.isNeedDisableTrackingBlockFunction = true;
    }

    this.isLoading = false;
    return;
  },
  methods: {
    async onNext(): Promise<void> {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.errorMessages = [];

      // e-mansionを退会・退会申請していないか確認
      if (await this.isAccountDeactivated()) {
        await this.$router.push('/platform').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      this.courseId = await this.$store.getters['selectedCourseStore/courseId'];
      const member: Member = await this.$store.dispatch('memberStore/member');
      const property: Property = await this.$store.dispatch('propertyStore/property', member.propertyId);

      const apartmentId = property.apartmentId!;
      const memberId = member.primaryKeyMye!;

      // 全角チェック
      const regexAddress1 = /^[^\x01-\x7E\uFF61-\uFF9F]{1,30}$/i;
      const regexAddress2 = /^[^\x01-\x7E\uFF61-\uFF9F]{1,60}$/i;
      const regexZipCode = /^[0-9]{7}$/i;

      // 交付方法の中身をチェックする
      if (!this.contractDestinationKind) {
        this.errorMessages.push(`ご契約内容の交付方法を選択してください。`);
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      if (this.contractDestinationKind == '1') {
        this.contractDestinationZipcode = `${this.inputDeliveryData.deliveryZipcodeFormer}${this.inputDeliveryData.deliveryZipcodeLatter}`;
        if (!regexZipCode.test(this.contractDestinationZipcode)) {
          this.errorMessages.push('「「ご契約内容の交付方法」郵便番号」は半角数字7文字で入力してください。');
        }
        if (!this.contractDestinationAddress1) {
          this.errorMessages.push(`「「ご契約内容の交付方法」ご住所(1)」を全角で入力してください。`);
        }
        if (!regexAddress1.test(this.contractDestinationAddress1)) {
          this.errorMessages.push(`「「ご契約内容の交付方法」ご住所(1)」を全角30文字以内で入力してください。`);
        }

        if (this.contractDestinationAddress2) {
          if (!regexAddress2.test(this.contractDestinationAddress2)) {
            this.errorMessages.push('「「ご契約内容の交付方法」ご住所(2)」を全角60文字以内で入力してください。');
          }
        }

        if (this.errorMessages.length > 0) {
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      }

      if (this.contractDestinationKind == '4') {
        const emailAddressMaxLength = /^\S{1,48}$/;
        const emailAddressIllegalChar = /^.+@.+$/;
        const emailAddressIllegalCharMark = /^[\w\.!"#$%&'\(\)\*\+-\.\/:;<=>\?\[\]\^_`\{\|\}~]+@[^@\s,\\]+$/i;

        if (!this.inputMailAddress) {
          this.errorMessages.push(`「「ご契約内容の交付方法」ご希望メールアドレス」を半角英数字・記号で入力してください。`);
        } else {
          if (!this.inputMailAddress.match(emailAddressMaxLength)) {
            this.errorMessages.push(`「「ご契約内容の交付方法」ご希望メールアドレス」を半角英数字・記号48文字以内で入力してください。`);
          }
          if (!this.inputMailAddress.match(emailAddressIllegalChar) || !this.inputMailAddress.match(emailAddressIllegalCharMark)) {
            this.errorMessages.push(`「「ご契約内容の交付方法」ご希望メールアドレス」を半角英数字・記号で正しく入力してください。`);
          }
          if (this.inputMailAddress === this.inputMailAddressConfirm) {
            this.contractDestinationMailaddress = this.inputMailAddress;
          } else {
            this.errorMessages.push(`「「ご契約内容の交付方法」ご希望メールアドレス」の入力と、確認用に入力された内容が一致しません。もう一度新たに入力しなおしてください。`);
          }
        }

        this.contractDestinationZipcode = '';
        this.contractDestinationAddress1 = '';
        this.contractDestinationAddress2 = '';

        if (this.errorMessages.length > 0) {
          window.scrollTo(0, 0);
          this.isLoading = false;
          return;
        }
      }

      if (this.contractDestinationKind == '3') {
        this.$store.commit('selectedCourseStore/emailaddress', String(this.inputDeliveryData.emailAddress));
      }

      // 支払い方法種別 の値が「0:口座振替, 1:振込, 4:口振(請求書)の場合の登録処理（本人認証を行わない）
      // 総額が０円の場合は本人認証を行わない。
      if (!this.isNeedDisableTrackingBlockFunction || this.noDisplayCreditCardForm || this.isFreeServiceCost) {
        try {
          // APIへのリクエストをここで呼び出す。
          const response = await SpfApiCourseAccessor.registerAnotherCourse(
            this.courseId,
            this.contractDestinationKind,
            this.contractDestinationMailaddress ?? '',
            this.contractDestinationZipcode ?? '',
            this.contractDestinationAddress1 ?? '',
            this.contractDestinationAddress2 ?? '',
            '0',
            apartmentId,
            memberId
          );

          this.$store.commit('selectedCourseStore/contractDestinationKind', this.contractDestinationKind);
          this.$store.commit('selectedCourseStore/contractDestinationMailaddress', this.contractDestinationMailaddress);
          this.$store.commit('selectedCourseStore/contractDestinationZipcode', this.contractDestinationZipcode);
          this.$store.commit('selectedCourseStore/contractDestinationAddress1', this.contractDestinationAddress1);
          this.$store.commit('selectedCourseStore/contractDestinationAddress2', this.contractDestinationAddress2);
          this.$store.commit('selectedCourseConfirmStore/status', response.status);
          this.$store.commit('selectedCourseConfirmStore/memberId', response.member_id);
          this.$store.commit('selectedCourseConfirmStore/courseId', response.course_id);
          this.$store.commit('selectedCourseConfirmStore/receiveTime', response.receive_time);
          this.$store.commit('selectedCourseConfirmStore/showTpl', response.show_tpl);
          this.$store.commit('selectedCourseConfirmStore/dispConstructionCompleteDate', response.disp_construction_complete_date);
          this.$store.commit('selectedCourseConfirmStore/basicFee', response.billing_data.basic_fee);
          this.$store.commit('selectedCourseConfirmStore/basicFeeCp', response.billing_data.basic_fee_cp);
          this.$store.commit('selectedCourseConfirmStore/tesuryoFee', response.billing_data.tesuryo_fee);
          this.$store.commit('selectedCourseConfirmStore/tesuryoFeeCp', response.billing_data.tesuryo_fee_cp);
          this.$store.commit('selectedCourseConfirmStore/chgConstruction', response.billing_data.em_chg_construction);
          this.$store.commit('selectedCourseConfirmStore/chgConstructionCp', response.billing_data.em_chg_construction_cp);
          this.$store.commit('selectedCourseConfirmStore/adapterCharge', response.billing_data.adapter_charge);
          this.$store.commit('selectedCourseConfirmStore/adapterChargeCp', response.billing_data.adapter_charge_cp);
          if (this.isContainCpFee) {
            this.$store.commit('selectedCourseStore/isContainCpFee', true);
          }
        } catch (error: any) {
          await VueErrorHandler.handle(error, '');
          await this.$router.push('/e-mansion/error').catch((error) => {
            checkRouterError(error);
          });
          return;
        }

        await this.$router.push('/e-mansion/course/complete').catch((e) => {
          checkRouterError(e);
        });
        return;
      }

      //初回、次回の金額が無料でない場合、本認証を行う。
      else if (this.isNeedDisableTrackingBlockFunction) {
        if (
          this.creditCardAccessToken === '' &&
          !(this.cardExpiredFromIsp && this.maskedCardNumberFromIsp) &&
          !this.isAgreedCopyCardToIsp &&
          !this.noDisplayCreditCardForm &&
          !this.isFreeServiceCost
        ) {
          this.errorMessages.push(`カード番号、カード期限、カード名義人、セキュリティコードを正しく入力してください。`);
          this.showErrorMessage();
          this.isLoading = false;
          return;
        }

        try {
          // 支払方法が登録されている場合
          if (this.emansionVeriTransAccountId && this.isPaymantCreditCard) {
            // 申込内容一時保存処理
            // 申込内容一時保存用 UUIDを生成
            const uuidForTemporarySavingApplicationData = uuidv4();
            const paymentMethodApplicationData = {
              courseId: this.courseId,
              contractDestinationKind: this.contractDestinationKind,
              contractDestinationMailaddress: this.contractDestinationMailaddress ?? '',
              contractDestinationZipcode: this.contractDestinationZipcode ?? '',
              contractDestinationAddress1: this.contractDestinationAddress1 ?? '',
              contractDestinationAddress2: this.contractDestinationAddress2 ?? '',
              veriTransAccountId: this.emansionVeriTransAccountId,
              storeSelectedSpeed: this.storeSelectedSpeed,
              storeSelectedCharge: this.storeSelectedChargeCp || this.storeSelectedCharge,
              storeEquipmentcontract: this.storeEquipmentcontract,
              storeCharge: this.storeCharge,
              storetype: this.storetype,
              checkMyCurrentCourseId: this.checkMyCurrentCourseId,
              creditCardRegistFlag: '0',
              storeGrade: this.storeGrade,
              isContainCpFee: this.isContainCpFee,
              emailaddress: this.inputDeliveryData.emailAddress ?? '',
              zipcodeFormer: this.inputDeliveryData.deliveryZipcodeFormer ?? '',
              zipcodeLatter: this.inputDeliveryData.deliveryZipcodeLatter ?? '',
              inputMailAddressConfirm: this.inputDeliveryData.inputMailAddressConfirm ?? '',
            };

            // 申込内容をjsonに変換
            const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
            const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
              uuid: uuidForTemporarySavingApplicationData,
              applicationDataJson: paymentMethodApplicationDataJson,
              subsequentProcess: 'e-mansion-course-apply',
            });

            // 申込内容一時保存を実行するAPI
            try {
              await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            } catch (error: any) {
              // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
            }

            // 3dセキュア対応用 uuid localStorageに保存
            localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
            let cardHolderNameForAuthorize: string;
            if (this.cardHolderNameFromIsp) {
              cardHolderNameForAuthorize = this.cardHolderNameFromIsp;
            } else {
              cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizeIspCard!;
            }

            const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
              uuid: uuidForTemporarySavingApplicationData,
              externalVeritransAccountId: this.emansionVeriTransAccountId,
              cardholderName: cardHolderNameForAuthorize,
            });

            // VeriTrans_本人認証(VeriTrans会員ID使用) API
            try {
              const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
              location.href = authStartURL;
            } catch (error: any) {
              if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                this.errorMessages.push(
                  `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
                );

                // コンポーネントの破棄・再描画のために key値 を変更する
                this.addResetCounter();
                this.isAgreedPrivacyPolicy = false;
                this.isSubmitting = false;
                this.isLoading = false;

                // エラーメッセージを見せるために画面最上部にスクロール
                window.scrollTo(0, 0);
                return;
              } else {
                throw error;
              }
            }
            return;
          }
          // PortasからISPにカード情報をコピーする場合
          else if (this.isAgreedCopyCardToIsp && this.portasVeriTransId) {
            // 申込内容一時保存処理
            // 申込内容一時保存用 UUIDを生成
            const uuidForTemporarySavingApplicationData = uuidv4();
            const paymentMethodApplicationData = {
              courseId: this.courseId,
              contractDestinationKind: this.contractDestinationKind,
              contractDestinationMailaddress: this.contractDestinationMailaddress ?? '',
              contractDestinationZipcode: this.contractDestinationZipcode ?? '',
              contractDestinationAddress1: this.contractDestinationAddress1 ?? '',
              contractDestinationAddress2: this.contractDestinationAddress2 ?? '',
              veriTransAccountId: this.portasVeriTransId,
              isAgreedCopyCardToIsp: this.isAgreedCopyCardToIsp,
              storeSelectedSpeed: this.storeSelectedSpeed,
              storeSelectedCharge: this.storeSelectedChargeCp || this.storeSelectedCharge,
              storeEquipmentcontract: this.storeEquipmentcontract,
              storeCharge: this.storeCharge,
              storetype: this.storetype,
              checkMyCurrentCourseId: this.checkMyCurrentCourseId,
              creditCardRegistFlag: '1',
              storeGrade: this.storeGrade,
              isContainCpFee: this.isContainCpFee,
              emailaddress: this.inputDeliveryData.emailAddress ?? '',
              zipcodeFormer: this.inputDeliveryData.deliveryZipcodeFormer ?? '',
              zipcodeLatter: this.inputDeliveryData.deliveryZipcodeLatter ?? '',
              inputMailAddressConfirm: this.inputDeliveryData.inputMailAddressConfirm ?? '',
            };

            // 申込内容をjsonに変換
            const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
            const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
              uuid: uuidForTemporarySavingApplicationData,
              applicationDataJson: paymentMethodApplicationDataJson,
              subsequentProcess: 'e-mansion-course-apply',
            });

            // 申込内容一時保存を実行するAPI
            try {
              await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            } catch (error: any) {
              // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
            }

            // 3dセキュア対応用 uuid localStorageに保存
            localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
            let cardHolderNameForAuthorize: string;
            if (this.cardHolderNameOnPortas) {
              cardHolderNameForAuthorize = this.cardHolderNameOnPortas;
            } else {
              cardHolderNameForAuthorize = this.cardholderNameFormForAuthorizePortasCard!;
            }

            const dddSecureAuthStartWithVeritransAccountIdRequest = new Create3dSecureAuthStartInfoWithVeritransAccountIdRequest({
              uuid: uuidForTemporarySavingApplicationData,
              externalVeritransAccountId: this.portasVeriTransId,
              cardholderName: cardHolderNameForAuthorize,
            });

            // VeriTrans_本人認証(VeriTrans会員ID使用) API
            try {
              const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithVeritransAccountId(dddSecureAuthStartWithVeritransAccountIdRequest);
              location.href = authStartURL;
            } catch (error: any) {
              if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                this.errorMessages.push(
                  `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
                );
                // コンポーネントの破棄・再描画のために key値 を変更する
                this.addResetCounter();
                this.isAgreedPrivacyPolicy = false;
                this.isSubmitting = false;

                // エラーメッセージを見せるために画面最上部にスクロール
                window.scrollTo(0, 0);
                this.isLoading = false;
                return;
              } else {
                throw error;
              }
            }
            return;
          } else {
            /**Portas,e-mansionにカードが未登録の場合 */
            // 申込内容一時保存処理
            // 申込内容一時保存用 UUIDを生成
            const uuidForTemporarySavingApplicationData = uuidv4();
            const paymentMethodApplicationData = {
              courseId: this.courseId,
              contractDestinationKind: this.contractDestinationKind,
              contractDestinationMailaddress: this.contractDestinationMailaddress ?? '',
              contractDestinationZipcode: this.contractDestinationZipcode ?? '',
              contractDestinationAddress1: this.contractDestinationAddress1 ?? '',
              contractDestinationAddress2: this.contractDestinationAddress2 ?? '',
              creditCardToken: this.creditCardAccessToken,
              tokenExpireDate: this.creditTokenExpireDate,
              creditCardRegistFlag: '1',
              storeSelectedSpeed: this.storeSelectedSpeed,
              storeSelectedCharge: this.storeSelectedChargeCp || this.storeSelectedCharge,
              storeEquipmentcontract: this.storeEquipmentcontract,
              storeCharge: this.storeCharge,
              storetype: this.storetype,
              checkMyCurrentCourseId: this.checkMyCurrentCourseId,
              storeGrade: this.storeGrade,
              isContainCpFee: this.isContainCpFee,
              emailaddress: this.inputDeliveryData.emailAddress ?? '',
              zipcodeFormer: this.inputDeliveryData.deliveryZipcodeFormer ?? '',
              zipcodeLatter: this.inputDeliveryData.deliveryZipcodeLatter ?? '',
              inputMailAddressConfirm: this.inputDeliveryData.inputMailAddressConfirm ?? '',
            };

            // 申込内容をjsonに変換
            const paymentMethodApplicationDataJson = JSON.stringify(paymentMethodApplicationData);
            const temporarySavingApplicationDataRequest = new TemporarySavingApplicationDataRequest({
              uuid: uuidForTemporarySavingApplicationData,
              applicationDataJson: paymentMethodApplicationDataJson,
              subsequentProcess: 'e-mansion-course-apply',
            });
            // 申込内容一時保存を実行するAPI
            try {
              await SpfApiService.temporarySavingApplicationData(temporarySavingApplicationDataRequest);
            } catch (error: any) {
              // 申込内容一時保存API 例外発生時はユーザー側で対処できないと思われるため、共通エラー画面に遷移する
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_TEMPORARY_SAVING_APPLICATION_DATA);
            }

            // 3dセキュア対応用 uuid localStorageに保存
            localStorage.setItem('uuidForTemporarySavingApplicationData', uuidForTemporarySavingApplicationData);
            // ページ遷移時に beforeEach で会員情報取得できるように null にする
            this.$store.commit('memberStore/member', null);
            const dddSecureAuthStartWithCardTokenRequest = new Create3dSecureAuthStartInfoWithCardTokenRequest({
              uuid: uuidForTemporarySavingApplicationData,
              creditCardToken: this.cardTokenFor3dSecureAuthorize,
              serviceProviderIsp: SERVICE_PROVIDER.MYE,
            });

            // VeriTrans_本人認証(トークン使用) API
            try {
              const authStartURL = await SpfApiService.create3dSecureAuthStartInfoWithCardToken(dddSecureAuthStartWithCardTokenRequest);
              location.href = authStartURL;
            } catch (error: any) {
              if (error.response.data.errors.includes('VeriTrans 3d-secure Authentication Failed')) {
                // VeriTrans_本人認証(トークン使用)API 認可処理でエラーが発生し、本人認証が実施不可である際に表示するエラーメッセージ
                this.errorMessages.push(
                  `クレジットカード本人認証（3Dセキュア）に失敗しました。以下の点をご確認いただき再度ご登録ください。<br/>・入力情報に誤りはございませんでしょうか<br/>・クレジットカード発行会社にて3Dセキュアの設定はお済みでしょうか<br/>・ご利用のカードは3Dセキュアに対応していますでしょうか<br/><br/>当サイトでは本人認証（3Dセキュア）が設定されていないクレジットカードはご利用いただけません。<br/>設定についてはご利用のクレジットカード会社にお問い合わせください。<br/><br/>上記に該当がない場合、お時間をおいて再度お試し下さい。本エラーを繰り返す場合にはお手数ですが<a class="link" href="${this.eMansionUrl}/${this.apid}/" target="_blank">こちら</a>からe-mansionサポートセンターへご連絡ください。`
                );

                // コンポーネントの破棄・再描画のために key値 を変更する
                this.addResetCounter();
                this.isAgreedPrivacyPolicy = false;
                this.isSubmitting = false;

                // エラーメッセージを見せるために画面最上部にスクロール
                window.scrollTo(0, 0);
                this.isLoading = false;
                return;
              } else {
                throw error;
              }
            }
          }
        } catch (error: any) {
          if (error.response?.status !== 503) {
            await VueErrorHandler.handle(error, '');
            await this.$router.push('/e-mansion/error').catch((error) => {
              checkRouterError(error);
            });
            return;
          }
        }
      }
    },

    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      if (this.contractDestinationKind == '4') {
        if (this.inputDeliveryData.inputMailAddress) {
          this.$store.commit('selectedCourseStore/contractDestinationMailaddress', this.inputDeliveryData.inputMailAddress);
        }
        if (this.inputDeliveryData.inputMailAddressConfirm) {
          this.$store.commit('selectedCourseStore/contractDestinationMailaddressConfirm', this.inputDeliveryData.inputMailAddressConfirm);
        }
        //ラジオボタンと対応していない入力内容をリセットする
        this.$store.commit('selectedCourseStore/contractDestinationZipcode', null);
        this.$store.commit('selectedCourseStore/zipcodeFormer', null);
        this.$store.commit('selectedCourseStore/zipcodeLatter', null);
        this.$store.commit('selectedCourseStore/contractDestinationAddress1', null);
        this.$store.commit('selectedCourseStore/contractDestinationAddress2', null);
      }

      if (this.contractDestinationKind == '1') {
        this.$store.commit('selectedCourseStore/zipcodeFormer', this.inputDeliveryData.deliveryZipcodeFormer);
        this.$store.commit('selectedCourseStore/zipcodeLatter', this.inputDeliveryData.deliveryZipcodeLatter);
        this.$store.commit('selectedCourseStore/contractDestinationAddress1', this.contractDestinationAddress1);
        this.$store.commit('selectedCourseStore/contractDestinationAddress2', this.contractDestinationAddress2);

        //ラジオボタンと対応していない入力内容をリセットする
        this.$store.commit('selectedCourseStore/contractDestinationMailaddress', null);
        this.$store.commit('selectedCourseStore/contractDestinationMailaddressConfirm', null);
      }

      if (this.contractDestinationKind == '3') {
        //ラジオボタンと対応していない入力内容をリセットする
        this.$store.commit('selectedCourseStore/contractDestinationMailaddress', null);
        this.$store.commit('selectedCourseStore/contractDestinationMailaddressConfirm', null);
        this.$store.commit('selectedCourseStore/contractDestinationZipcode', null);
        this.$store.commit('selectedCourseStore/zipcodeFormer', null);
        this.$store.commit('selectedCourseStore/zipcodeLatter', null);
        this.$store.commit('selectedCourseStore/contractDestinationAddress1', null);
        this.$store.commit('selectedCourseStore/contractDestinationAddress2', null);
      }

      this.$store.commit('selectedCourseStore/contractDestinationKind', this.contractDestinationKind);

      await this.$router.push('/e-mansion/course/input').catch((e) => {
        checkRouterError(e);
      });
      this.isLoading = false;
      return;
    },

    formatCurrency(value: any) {
      try {
        const num = parseInt(value.replace(/,/g, ''), 10);
        if (isNaN(num)) {
          throw new Error('Invalid number format');
        }
        return num.toLocaleString();
      } catch (error) {
        return value;
      }
    },

    /**
     *  e-mansionを退会、もしくは退会申請中の状態ではないか確認する
     */
    async isAccountDeactivated() {
      const property: Property = this.$store.getters['propertyStore/property'];
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId,
      });
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: this.eMansionMemberId,
        query: findCustomerByIdQueryDto,
      });
      if (eMansionCustomer instanceof EMansionCustomer) {
        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);
        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL ||
          this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK
        ) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },

    getInputDeliveryData(date: any) {
      this.inputDeliveryData = date;
    },

    getErrorMessage(data: any) {
      this.errorMessages = [];
      this.errorMessages.push(data);
      window.scrollTo(0, 0);
      return;
    },

    calculationRegularFee(): void {
      let selectedCharge = parseFloat(this.storeSelectedCharge?.replace(',', '') || '0');
      if (this.paymentIssueMsg) {
        selectedCharge = 0;
      }
      if (this.speedName == 'VDSL' || this.speedName == 'FTTH') {
        if (this.storeCharge) {
          this.rentalFee = parseFloat(this.storeCharge?.replace(',', '') || '0');
        }
      }

      const totalFee = selectedCharge + this.rentalFee;
      this.RegularFee = totalFee.toLocaleString();
    },

    calculationCampaignFee(): void {
      const basicFee = parseFloat(this.storeSelectedCharge?.replace(',', '') || '0');
      const basicFeeCp = parseFloat(this.storeSelectedChargeCp?.replace(',', '') || '0');
      const upgFeeCp = parseFloat(this.upgFeeCp?.replace(',', '') || '0');
      const upgFee = parseFloat(this.upgFee?.replace(',', '') || '0');
      const downgFeeCp = parseFloat(this.downgFeeCp?.replace(',', '') || '0');
      const downgFee = parseFloat(this.downgFee?.replace(',', '') || '0');
      const constructionFeeCp = parseFloat(this.constructionFeeCp?.replace(',', '') || '0');
      const constructionFee = parseFloat(this.constructionFee?.replace(',', '') || '0');

      if (this.speedName == 'VDSL' || this.speedName == 'FTTH') {
        if (this.storeCharge) {
          this.rentalFee = parseFloat(this.storeCharge?.replace(',', '') || '0');
        }
      }

      let monthlyRegularFee = basicFeeCp ? basicFeeCp : basicFee;

      if (this.paymentIssueMsg) {
        monthlyRegularFee = 0;
      }

      if (this.isUpgFlag && this.upgFee) {
        this.upgFeeToUse = upgFeeCp ? upgFeeCp : upgFee;
      }

      if (!this.isUpgFlag && this.downgFee) {
        this.downgFeeToUse = downgFeeCp ? downgFeeCp : downgFee;
      }

      const constructionFeeToUse = constructionFeeCp ? constructionFeeCp : constructionFee;
      const totalFee = monthlyRegularFee + this.upgFeeToUse + this.downgFeeToUse + this.rentalFee + (constructionFeeToUse || 0);
      this.campaignFee = totalFee.toLocaleString();
    },

    /** Windowスクロール＆処理中フラグ解除 */
    showErrorMessage(): void {
      this.isLoading = false;
      this.isSubmitting = false;
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
      return;
    },

    //物件基本情報取得API.htmlフォーマット
    formatNewLines(text: string) {
      if (!text) return '';
      return text.replace(/<br \/?>/g, '');
    },

    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },

    /** クレジットカードコンポーネントが再描画されるように、 key値 を更新する */
    addResetCounter(): void {
      this.resetCounter = this.resetCounter + 1;
    },

    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** Portasに登録されているクレジットカードの有効期限が切れているかどうかを受け取る */
    checkExpiredPortasCreditCard(isExpired: boolean) {
      this.isExpiredPortasCreditCard = isExpired;
    },
    /** クレジットカード編集用クレジットカードトークンを受け取る */
    getCardTokenForEdit(cardTokenForEdit: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.cardTokenForEdit = cardTokenForEdit;
    },
    /** クレジットカード編集用クレジットカードトークン有効期限を受け取る */
    getCardTokenExpireDateForEdit(cardTokenExpireDateForEdit: string) {
      this.cardTokenExpireDateForEdit = cardTokenExpireDateForEdit;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /** VeriTransカード情報取得 API実行 */
    async getPaymentMethodFromExternalIsp(vid: string): Promise<PaymentMethodDetailResponse> {
      return await SpfApiService.getDefaultPaymentMethod(vid);
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** 3Dセキュア本人認証用カード名義人を受け取る */
    getCardHolderNameFromPortas(cardHolderName: string) {
      this.cardHolderNameOnPortas = cardHolderName;
    },
    /** 3Dセキュア本人認証用カードトークンを受け取る */
    getCardTokenFor3dSecureAuthorize(cardTokenFor3dSecureAuthorize: string) {
      this.cardTokenFor3dSecureAuthorize = cardTokenFor3dSecureAuthorize;
    },
    /** クレジットカードコンポーネントのフォームに入力された、ISP登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizeIspCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizeIspCard = cardHolderName;
    },
    /** クレジットカードコンポーネントのフォームに入力された、Portas登録済カードの3Dセキュア本人認証用カード名義人フォームを受け取る */
    getCardholderNameFormForAuthorizePortasCard(cardHolderName: string) {
      this.cardholderNameFormForAuthorizePortasCard = cardHolderName;
    },
  },
  computed: {
    isDisabledButton(): boolean {
      if (this.isFreeServiceCost) {
        return false;
      } else if (this.noDisplayCreditCardForm) {
        return false;
      } else if (
        this.emansionVeriTransAccountId &&
        ((this.cardHolderNameFromIsp && this.isAgreedPrivacyPolicy) ||
          (this.cardholderNameFormForAuthorizeIspCard && this.cardholderNameFormForAuthorizeIspCard.length > 1 && this.isAgreedPrivacyPolicy))
      ) {
        return false;
      } else if (
        !this.isExpiredPortasCreditCard &&
        (this.cardHolderNameOnPortas || this.cardholderNameFormForAuthorizePortasCard) &&
        this.isAgreedCopyCardToIsp &&
        this.isAgreedPrivacyPolicy
      ) {
        return false;
      } else {
        return this.creditCardAccessToken === '' || !this.isAgreedPrivacyPolicy;
      }
    },
  },
  watch: {
    inputDeliveryData: {
      handler(newVal) {
        this.selectedRadio = newVal.selectedRadio;
        this.contractDestinationAddress1 = newVal.destinationAddress1;
        this.contractDestinationAddress2 = newVal.destinationAddress2;
        this.inputMailAddress = newVal.inputMailAddress;
        this.inputMailAddressConfirm = newVal.inputMailAddressConfirm;

        if (newVal.selectedRadio == '1') {
          this.contractDestinationKind = '3';
        } else if (newVal.selectedRadio == '2') {
          this.contractDestinationKind = '4';
        } else if (newVal.selectedRadio == '3') {
          this.contractDestinationKind = '1';
        }
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.switch_apartment_indicator {
  white-space: pre-wrap;
}
.my-cautionary-statement-checkbox {
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.6;
  color: #cf1225;
  font-weight: 700;
}

.centered-text {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
.centered-text b {
  display: inline-block;
  text-align: left;
  margin: 0 auto;
}

.text-margin {
  margin-bottom: 20px;
}
.table {
  table-layout: fixed;
  width: 100%;
}

.table-type2 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.table-type2 th,
.table-type2 td {
  border-right: 1px solid #ddd;
}

.campaign-text {
  background-color: #ef3333;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
  margin-left: 0.5rem;
}

.tableGray {
  background-color: #e6e6e6 !important;
}

.temp-css {
  min-width: 130px;
}

.btn-area-course {
  margin: 100px auto 0;
  text-align: center;
}

.btn-area-course {
  margin: 20px auto 0;
  text-align: center;
}

.otherCourseService {
  width: 240px;
}

.btn:disabled {
  opacity: 0.5;
}
.bottton-margin {
  margin-top: 20px;
}
.sp-margin {
  margin-top: 10px;
}

div.btn-area {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & div.has-checkbox {
    padding-top: 0;
    & div.my-checkbox-outer {
      top: 0px;
      & input[type='checkbox'] {
        left: 0;
      }
    }
  }
}

div.my-checkbox-outer {
  margin: 16px;
}
div.my-checkbox-outer > input {
  margin-right: 10px;
}
.sp-margin {
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .constructionCompletion {
    padding: 15px 10px;
    display: block;
    width: inherit;
  }
  .otherCourseService {
    padding: 15px 10px;
    display: block;
    width: inherit;
  }
  .contractDetails {
    padding: 15px 10px;
    display: block;
    width: inherit;
  }
  .commonPaddingTd {
    padding: 15px 10px !important;
  }
}
</style>
