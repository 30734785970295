import { UaTypeValue } from '@/shared/const/service-type';

/**
 * e-mansion 共通利用 API : 新規会員登録 : リクエスト
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class EMansionCustomerCreateRequestResult {
  /**結果値 */
  public request!: EMansionCustomerCreateRequest;

  constructor(partial: Partial<EMansionCustomerCreateRequest>) {
    Object.assign(this, partial);
  }
}

/**
 * e-mansion 共通利用 API : 新規会員登録 : リクエスト
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class EMansionCustomerCreateRequest {
  /**UA種別 */
  public readonly ua_type!: UaTypeValue;
  /**物件ID */
  public readonly property_id!: string;
  /**棟ID */
  public readonly building_ID!: string;
  /**部屋番号 */
  public readonly room_number!: string;
  /**姓 */
  public readonly name_surname!: string;
  /**名 */
  public readonly name_first_name!: string;
  /**姓カナ */
  public readonly kana_surname!: string;
  /**名カナ */
  public readonly kana_first_name!: string;
  /**誕生日 */
  public readonly birthday!: string;
  /**電話番号 */
  public readonly phone_number!: string;
  /**緊急連絡先 */
  public readonly emergency_phone_number?: string;
  /**連絡先メールアドレス */
  public readonly email_address!: string;
  /**入居予定日 */
  public readonly scheduled_move_in_on?: string;
  /**SDWAN 1:申し込む、0:申し込まない */
  public readonly sdwan?: string;
  /**カード登録フラグ */
  public readonly credit_card_regist_flag!: string;
  /**e-TNC用会員ID */
  public readonly e_tnc_member_id!: string;
  /**書類送付先種別 */
  public readonly destination_kind!: string;
  /**郵便番号(ハイフンなし) */
  public readonly destination_zipcode!: string;
  /**住所1 */
  public readonly destination_address1!: string;
  /**住所2 */
  public readonly destination_address2?: string;
  /**契約書面送付先種別 1:郵送 3:電子交付(登録メールアドレス) 4:電子交付(指定) */
  public readonly contract_destination_kind?: string;
  /**契約書面送付先メールアドレス */
  public readonly contract_destination_mailaddress?: string;
  /**契約書面送付先郵便番号 */
  public readonly contract_destination_zipcode?: string;
  /**契約書面送付先住所１ */
  public readonly contract_destination_address1?: string;
  /**契約書面送付先住所２*/
  public readonly contract_destination_address2?: string;
  /**i-フィルター申込フラグ 0:申し込まない 1:申し込む */
  public readonly ifilter_flag?: string;
  /**IPフォン申込フラグ */
  public readonly ipphone_flag?: string;
  /**Ipphone申込情報 */
  public readonly Ipphone?: IPPhoneInfo;

  constructor(partial: Partial<EMansionCustomerCreateRequest>) {
    Object.assign(this, partial);
  }
}

export class IPPhoneInfo {
  /**着荷指定日 */
  delivery_time!: string;
  /**指定時間 */
  delivery_time_zone!: string;
}

/**
 * e-mansion 共通利用 API : 会員情報変更 : リクエスト
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class EMansionCustomerUpdateRequestResult {
  public request!: EMansionCustomerUpdateRequest;
  constructor(partial: Partial<EMansionCustomerUpdateRequest>) {
    Object.assign(this, partial);
  }
}

/**
 * e-mansion 共通利用 API : 会員情報変更 : リクエスト本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class EMansionCustomerUpdateRequest {
  /**UA種別 */
  public readonly ua_type!: UaTypeValue;
  /**物件ID */
  public readonly property_id!: string;
  /**棟ID */
  public readonly building_id?: string;
  /**お部屋番号 */
  public readonly room_number?: string;
  /**名前(姓) */
  public readonly first_name?: string;
  /**名前(名) */
  public readonly given_name?: string;
  /**名前フリガナ(姓) */
  public readonly first_name_kana?: string;
  /**名前フリガナ(名) */
  public readonly given_name_kana?: string;
  /**郵便番号 */
  public readonly postal_code?: string;
  /**住所(都道府県) */
  public readonly prefecture_id?: string;
  /**住所(市区町村) */
  public readonly city?: string;
  /**住所(番地) */
  public readonly block?: string;
  /**メールアドレス */
  public readonly email_address?: string;

  constructor(partial: Partial<EMansionCustomerUpdateRequest>) {
    Object.assign(this, partial);
  }
}

export class EMansionEmailAuthenticationKeyRequestResult {
  public request!: EMansionEmailAuthenticationKeyRequest;

  constructor(partial: Partial<EMansionCustomerUpdateRequest>) {
    Object.assign(this, partial);
  }
}

export class EMansionEmailAuthenticationKeyRequest {
  public readonly ua_type!: UaTypeValue;
  public readonly property_id!: string;
  public readonly email_authentication_key!: string;

  constructor(partial: Partial<EMansionEmailAuthenticationKeyRequest>) {
    Object.assign(this, partial);
  }
}

export interface FindAccountValidationByIdQueryDto {
  ua_type: string;
  property_id: string;
  account_id: string;
}
